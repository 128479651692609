import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(inputtext: any, searchtext?: any, casesensitiveflag: boolean = false, highlight: boolean = true): any {
    // console.log("highlight : ", highlight);

    if (!searchtext || !highlight) { return inputtext; }
    if (inputtext || inputtext === 0 || inputtext === false) {

      inputtext = typeof inputtext == 'number' ? inputtext.toString() : inputtext;
      if (casesensitiveflag) {
        return inputtext.replace(new RegExp(searchtext, 'g'), "<span class='highlight-text'>$&</span>");
      }
      return inputtext.replace(new RegExp(searchtext, 'gi'), "<span class='highlight-text'>$&</span>");
    }

  }

}
