import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ValidationService {
  constructor() { }

  isValidate(formObject) {
    // let validate = true;
    let flag = true;
    Object.keys(formObject).forEach(key => {

      // let strLenMax = formObject[key]["strLength"] ? formObject[key]["strLength"] : 0;

      switch (formObject[key]["type"]) {
        case 'String':
          let isInValid: boolean = this.validateString(formObject[key]);

          flag = flag && isInValid? isInValid : false;
          console.log(isInValid,flag);
          break;
        case 'Number':
          let isInValid1 = this.validateNumber(formObject[key]);
          flag = flag && isInValid1 ? isInValid1 : false;
          console.log('Number',isInValid1,flag);
          break;
      }

      //   if (formObject[key]["err"]) {
      //     formObject[key]["err"] = formObject[key]["label"] + " " + formObject[key]["err"];
      //     validate = false;
      //   }
    })
    formObject['isInValid']=flag;
    console.log('flag',flag);

    return flag;
  }

  validateString(Obj: any) {


    if (!Obj['value'] && Obj['required']) {
      Obj['err'] = "Required";
      return true;
    } else if (Obj['value'] && Obj?.maxLength && Obj['value'].length > Obj?.maxLength) {
      Obj['err'] = `Length should not be greater than ${Obj?.maxLength} characters`;
      return true;
    } else {
      Obj['err'] = ''
      return false;
    }
  }

  validateNumber(Obj: any) {


    if (!Obj['value'] && Obj['required']) {
      Obj['err'] = "Required";
      return true;
    } else if (Obj['value'] && Obj?.maxLength && Obj['value'].length > Obj?.maxLength) {
      Obj['err'] = `Length should not be greater than ${Obj?.maxLength} characters`;
      return true;
    } else {
      Obj['err'] = ''
      return false;
    }
  }

  generateSubmitFormObject = (formbject) => {

    let returnObject = {};

    Object.keys(formbject).forEach(key => {
      returnObject[formbject[key]["apiKey"]] = formbject[key]["value"];
    })
    return returnObject;
  }
}
