import { Component, OnInit, ViewChild, NgZone, Input } from '@angular/core';
import { ConfigurationSetting } from '../../appconfig'
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfirmationPopupModel } from '../../models/models';
import { CommonServicesService } from '../../services/common-services.service';
import { NgForm } from '@angular/forms';
import { GtPlusComponent } from '../gt-plus/gt-plus.component';
import { DataSharingService } from '../gt-plus/data-sharing.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ValidationService } from '../../services/validation.service';
import { ActiveDashService } from '../../services/active-dash.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemMasterComponent } from '../item-master/item-master.component';
import { ToastService } from '../../services/toast.service';
import { HelperService } from '../../services/helper.service';
import { CommonFunctionService } from '../../services/common-function.service';
declare var $;
@Component({
  selector: 'app-spare-master',
  templateUrl: './spare-master.component.html',
  styleUrls: ['./spare-master.component.scss']
})
export class SpareMasterComponent implements OnInit {

  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('spareMasterForm') public resetSpareMasterForm: NgForm;
  @Input('emitSucccessResp') emitSucccessResp: {};
  @Input() showonlyForm: boolean = false;

  isDataChanged: boolean = false;
  dynamicformdata: any;
  dynamicTable: any = {};
  subDynamicTable: any = {}
  showloader: boolean = false;
  showconfirmpopup: boolean = false;
  displayTable: boolean = false;
  displaySubTable: boolean = false;
  errormsg: string = "";
  currentSelectedRow: number;
  headers = new HttpHeaders();
  confirmationpopupmodelobj: ConfirmationPopupModel = new ConfirmationPopupModel();
  validationmsgforQty: string = "";
  validationMsg: string = "";
  validationmsg: string = "";
  allowedToAddEditCtrl: boolean = true;
  rowIndex: any;
  colIndex: any;
  rowsperpage = 100;
  isEditable: boolean = false;
  isdatachange: boolean = false;
  emptySearchField: boolean = true;
  spareNoToSave: any;
  spareName: string = "";
  partNo: any;
  btnRow: boolean = false;
  Application: string = "";
  Location: string = "";
  Minstock: any;
  oldNumber: string = "";
  subLocation: string = "";
  currentBalance: number;
  minimumStock: number;
  WIP: number;
  supersedNumber: string = "";
  spareMasterTab: boolean = true;
  operation: string = "";
  userRole: string = "";
  deleteById;
  opeartionStageForDelete: string = "";
  Timerval: any;
  isRowClicked:boolean = false;

  disablePartNo: boolean = false;
  disableSpareName: boolean = false;
  disableApplication: boolean = false;
  disableLocation: boolean = false;
  disableSubLocation: boolean = false;
  disableOldNo: boolean = false;
  disableSupersedNo: boolean = false;
  disableMinstock: boolean = false;
  disableNewBtn: boolean = false;
  disableCancelBtn: boolean = false;
  disableSaveBtn: boolean = false;
  saveBtnVisible: boolean = false;
  highlightedObj = {
    "id": "",
    "indx": -1
  }
  oldNoSupersedNoList: any[] = [];
  eve = {}
  itemevent = {};
  subDynamicTableForItem: any[] = [];
  deletedArray:any = [];
  partnoforsubtbl: number;
  operationStage: string = "";
  oprationForRowClick: string = "";
  showsubDynTbleForItemPopup: boolean = false;
  displaySubDynamicTableForItem: boolean = false;
  tempArrayToSend: any[] = [];
  eventDataArr: any[] = [];
  doubleClickStage: string = "";
  eventRowIndex;
  deleteMsg = "";
  indexForEdit: number;
  editOperation: string = "";
  visibleSaveBtn: boolean = true;
  visibleNewBtn: boolean = false;
  visibleCancelBtn: boolean = false;
  editButton: boolean = false;
  tabledataprocess: any[] = []
  tableheaders = [];
  tabledata = [];
  tableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "controlconfig": { "displaycontrol": false, "controlsfor": {} },
    "buttonspriority": "row",
    "checkDeletedFlag": true,
    "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  };

  subtableheaders = [
    {
      "key": "ItemNo",
      "display": "Item No",
      "sorting": true,
      "enablehide": true,
      "datatype": "string",
      "thstyle": { "text-align": "center ", "width": "15%" }
    },
    {
      "key": "Description",
      "display": "Description",
      "sorting": true,
      "enablehide": true,
      "datatype": "string",
      "thstyle": { "text-align": "center ", "width": "50%" }
    },
    {
      "key": "Qty",
      "display": "Qty",
      "sorting": true,
      "editable": true,
      "enablehide": true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
    }
  ];
  subtabledata = [];
  subtableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "enablefirstbtn": false,
    "enablesecondbtn": false,
    "firstBtnText": "Add Spares",
    "secondBtnText": "Spare List",
    "firstBtnStyle": {},
    "secondBtnStyle": {},
    "firstBtnClass": "",
    "secondBtnClass": "",
    "Qty": { "text-align": "center", "background-color": "#b4f9df" },
    "ItemNo": { "text-align": "center" },
    "controlconfig": {
      "displaycontrol": true,
      "controlsfor": { "Qty": { "pattern": "^\\d+$" } }
    }
  };

  subTax;
  disableAddBtn;
  subTaxRate;
  addItemButton;
  editDisableBtn: boolean = false;
  CancelBtn: boolean = false;
  addBtn: boolean = false;
  delEve = {}
  deleteBtn: boolean = false;
  showOnlyForSpareInward: boolean = true;

  @ViewChild('showsubDynTbleForItemModal', { static: false }) public showsubDynTbleForItemModal: ModalDirective;

  constructor(private commonFunction:CommonFunctionService, private ngbActiveModal: NgbActiveModal, public commonservices: CommonServicesService, private dataSharingSrvc: DataSharingService, private configurationsetting: ConfigurationSetting, private zone: NgZone, private validator: ValidationService, private activeDash: ActiveDashService, private ngbModal: NgbModal, private toastService: ToastService, private helpers: HelperService) { }

  ngOnInit() {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    this.getMasterTable();

    /*
     below settimeout is used to disable form for the forst time when user enters
     */
    setTimeout(() => {
      if (!this.showonlyForm)
        this.disableForm();
    }, 1000)
    if (this.showonlyForm) {
      setTimeout(() => {
        this.showSpareMasterForm();
      }, 200)
    }
    this.getOldNoSopersedNoList();

    this.commonservices.currentMsg.subscribe(message =>
      console.log("Message in sp master::-", message));
  }

  updatedtabledata(data: any) {
    this.tabledataprocess = []
    this.tabledataprocess = data;
    console.log("updatedtabledata", this.tabledataprocess);
  }

  // taxformdata
  saveSpareMaster(spareFormData) {
    console.log(spareFormData);

    if (navigator.onLine) {
      this.errormsg = "";
      for (let i = 0; i < this.tabledataprocess.length; i++) {
        if (this.tabledataprocess[i]['Qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
      }
      // //  this.showloader = true;
      this.deleteBtn = false;
      this.CancelBtn = false;
      this.loaderTimeOut();

      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveSpareMaster";
      let savesparemasterformobj = {}
      savesparemasterformobj["partno"] = spareFormData.form.value['partno'];
      savesparemasterformobj["sparename"] = spareFormData.form.value['sparename'];
      savesparemasterformobj["sparename"] = this.commonFunction.manipulateString(savesparemasterformobj["sparename"]);
      savesparemasterformobj["application"] = spareFormData.form.value['application'];
      savesparemasterformobj["location"] = spareFormData.form.value['location'] ? spareFormData.form.value['location'] : '';
      savesparemasterformobj["sublocation"] = spareFormData.form.value['sublocation'] ? spareFormData.form.value['sublocation'] : '';
      savesparemasterformobj["oldnumber"] = parseInt(spareFormData.form.value['oldnumber']) ? parseInt(spareFormData.form.value['oldnumber']) : 0;
      savesparemasterformobj["supersednumber"] = parseInt(spareFormData.form.value['supersednumber']) ? parseInt(spareFormData.form.value['supersednumber']) : 0;
      savesparemasterformobj["minimumstock"] = parseInt(spareFormData.form.value['minimumstock']) ? parseInt(spareFormData.form.value['minimumstock']) : 0;
      // savesparemasterform["spareno"] = parseInt(spareFormData.form.value['spareno']) ? parseInt(spareFormData.form.value['spareno']) : 0;
      this.tempArrayToSend = [];
      if (this.isEditable) {
        console.log(this.tabledataprocess);

        this.tempArrayToSend = JSON.parse(JSON.stringify(this.tabledataprocess));
        console.log(this.tempArrayToSend);

        for (let ind = 0; ind < this.tempArrayToSend.length; ind++) {
          delete this.tempArrayToSend[ind]['ItemNo'];
          delete this.tempArrayToSend[ind]['SpareNo'];
          delete this.tempArrayToSend[ind]['itemnumber'];

          this.tempArrayToSend[ind]['Qty'] = JSON.parse(this.tempArrayToSend[ind]['Qty']);
        }
        savesparemasterformobj['spareno'] = this.spareNoToSave;
        savesparemasterformobj['spareservice'] = "edit";

      } else {
        //send spareno=0 when it is a new entry i.e while saving
        savesparemasterformobj['spareno'] = 0;
        savesparemasterformobj['spareservice'] = "new";

      }




      let body = {};
      if (this.isEditable) {

        body = {
          "sparemaster": savesparemasterformobj,
          "itemspare": this.tabledataprocess,
          "delarry": this.deletedArray
        };
      } else {
        body = {
          "sparemaster": savesparemasterformobj,
          "itemspare": this.tabledataprocess,
          "delarry": this.deletedArray
        };
      }

      this.commonservices.postMethod(apiUrl, body).subscribe(successResp => {
        // //  this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          // debugger;
          this.partnoforsubtbl = successResp['Data'][0]['SpareData'][0]['spareno'];
          console.log(this.partnoforsubtbl);


          this.getMasterTable();//change it

          // if (this.eve) {
          //   this.rowDoubleClickSpareMaster(this.eve, false);
          //   // this.getFormDataTableData(this.eve)
          // }
          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);

          this.emitSucccessResp = {};
          this.emitSucccessResp = successResp;


          this.disablePartNo = true;
          this.disableSpareName = true;
          this.disableApplication = true;
          this.disableLocation = true;
          this.disableSubLocation = true;
          this.disableOldNo = true;
          this.disableSupersedNo = true;
          this.disableOldNo = true;
          this.disableMinstock = true;
          this.btnRow = false;
          this.disableNewBtn = false;
          this.addBtn = false;
          this.visibleNewBtn = true;
          this.saveBtnVisible = false;
          this.visibleCancelBtn = true;
          setTimeout(() => {
            this.editButton = true;
          }, 300);

          this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;

          // this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
          this.btnRow = true;
          this.editButton = true;
          if (this.showonlyForm) {
            this.closeModel({ status: "save" });
          }
        } else {

          this.errormsg = successResp['errorDescription']

        }

      }, errorResp => {
        // //  this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {

      alert("Internet Connection is Not Available.");

    }

  }


  showSpareMasterForm() {
    this.operation = "NEW";
    this.spareNoToSave = 0;
    this.partnoforsubtbl = 0;
    this.emptySearchField = false;
    this.editButton = false;
    this.addBtn = true;
    this.disablePartNo = false;
    this.disableSpareName = false;
    this.disableApplication = false;
    this.disableLocation = false;
    this.disableSubLocation = false;
    this.disableOldNo = false;
    this.disableSupersedNo = true;
    this.disableOldNo = false;
    this.disableMinstock = false;
    this.resetSpareMasterForm.reset();

    this.disableCancelBtn = false;
    this.disableSaveBtn = false;
    this.saveBtnVisible = true;
    this.disableNewBtn = false;//editing from "true" to false ask to client
    this.displaySubTable = false;
    this.allowedToAddEditCtrl = true;
    this.visibleNewBtn = false;
    this.visibleSaveBtn = true;
    this.CancelBtn = true;
    this.visibleCancelBtn = true;
    this.deleteMsg = "";
    this.btnRow = true;
    this.subtabledata = [];
    this.subtableheaders = this.commonservices.enableEditForGt(this.helpers.removeRefrence(this.subtableheaders), "Qty", true);

    this.subtableconfig['actioncolmconfig']['displaycolumn'] = true;
    if (this.oprationForRowClick == "clickedOnRow") {
      setTimeout(() => {
        this.subtabledata = [];
      }, 500);
      // this.resetSpareMasterForm.reset();
      this.oprationForRowClick = "";
      this.isEditable = false;
      const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
      if (ele) ele.style.backgroundColor = '';

    } else if (this.oprationForRowClick = "editSpareMasterForm") {
      this.isEditable = false;
      this.oprationForRowClick = "";
    }
    const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';


    this.operationStage = "comingFromNew";
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }
  disableInputs() {
    this.disablePartNo = true;
    this.disableSpareName = true;
    this.disableApplication = true;
    this.disableLocation = true;
    this.disableSubLocation = true;
    this.disableOldNo = true;
    this.disableSupersedNo = true;
    this.disableOldNo = true;
    this.disableMinstock = true;
  }
  disableForm() {
    this.disableInputs();
    this.disableCancelBtn = true;
    this.disableSaveBtn = true;
    this.saveBtnVisible = false;
    this.disableNewBtn = false;
    this.visibleNewBtn = true;
    this.visibleSaveBtn = false;
    this.visibleCancelBtn = false;
  }

  checkUniqueSpPartCode(event) {
    if (navigator.onLine) {
      if (event['currentTarget']['id'] == "PartNo") {
        this.errormsg = "";
        //  this.showloader = true;
        this.loaderTimeOut();
        let apiurl = this.configurationsetting.getBaseUrl() + "Master/CheckUniqueSpareNumber";
        let params = { "sparenumber": event.currentTarget.value };


        this.commonservices.getWithParams(apiurl, params)
          .subscribe(successResp => {
            //  this.showloader = false;
            if (successResp['errorCode'] == "000000") {

              this.disablePartNo = false;
              this.disableSpareName = true;
              this.disableApplication = true;
              // this.disableLocation = true;
              // this.disableSubLocation = true;
              // this.disableOldNo = true;
              // this.disableSupersedNo = true;
              // this.disableMinstock = true;
              this.disableCancelBtn = false;
              this.disableSaveBtn = true;
              this.saveBtnVisible = false;
              this.disableNewBtn = false;

              this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
            } else if (successResp['errorCode'] != "000000") {
              if (this.operationStage == "comingFromNew") {
                this.disableSupersedNo = true;
                this.operationStage = "";
              } else if (this.operationStage == "editSpareMasterForm") {
                this.disableSupersedNo = false;
                this.operationStage = "";
              }
              this.disablePartNo = false;
              this.disableSpareName = false;
              this.disableApplication = false;
              // this.disableLocation = false;
              // this.disableSubLocation = false;
              // this.disableOldNo = false;
              // this.disableMinstock = false;
              this.disableCancelBtn = false;
              this.disableSaveBtn = false;
              this.saveBtnVisible = true;
              this.disableNewBtn = true;
            }
          }, errorResp => {
            //  this.showloader = false;
            this.errormsg = errorResp['message']
          });
      }

    } else {
      //  this.showloader = false;
      alert("Internet Connection is Not Available.");
    }
  }

  openNewItemMaster(item) {
    console.log(item);
    let SrNo = item?.row?.itemnumber;
    // this.popupModelService.openModel(ItemMasterComponentComponent, "allocate-modal-pop");
    const ngbModalRef = this.ngbModal.open(ItemMasterComponent, { windowClass: "item-master-modal-pop", backdrop: 'static' });
    ngbModalRef.componentInstance.leftsidetbl = false;
    ngbModalRef.componentInstance.leftsiderowitem = item;
    ngbModalRef.componentInstance.ItemSrNo = SrNo;

    // console.log(event);

    ngbModalRef.result.then((result) => {
      console.log(result);

      if (result && result?.status == "save") {
        // this.getItemListForPopup();
        this.displayMessagePopup("Data Updated Successfully", "Ok", true, "", false);
      }
    });
  }
  deleteItem(event) {
    const tempdata = this.helpers.removeRefrence(this.tabledataprocess)
    tempdata.splice(event['rowindex'], 1);
    console.log(event);

    this.subtabledata = []
    this.subtabledata = tempdata;
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }

  viewItemList(event) {
    this.itemevent = event;
    console.log(event);
  }

  clickToGetItemMaster(event) {
    const item = {
      "row": {
        "SrNo": 238,
        "itemcode": "RC110472",
        "Remarks": "",
        "RoutineDC": "N",
        "subassemblyname": "Cross Heads",
        "modelname": "120 T6",
        "ModelNo": 24,
        "subAssemblySrNo": 11,
      }
    };
    // debugger;
    this.openNewItemMaster(event);
  }

  closePopUpEvent(whichPopup) {
    switch (whichPopup) {
      case 'closeSubItemTableForm':
        this.showsubDynTbleForItemPopup = false;
        this.showsubDynTbleForItemModal.hide();
        this.displaySubDynamicTableForItem = false;
        // this.operationStage = "";
        break;
      case 'closesubDynTbleForItemPopup':
        this.showsubDynTbleForItemPopup = false;
        this.showsubDynTbleForItemModal.hide();
        this.displaySubDynamicTableForItem = false;
        break;
      case 'deletepopup':
        this.showsubDynTbleForItemPopup = false;
        this.showDeleteModal.hide();
        this.displaySubDynamicTableForItem = false;
    }
  }

  // updatingData(event){
  //   debugger;
  // }


  rowDoubleClickSpareMaster(event, flag = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 5000);
    this.isRowClicked = true;
      return;
    }
    this.isRowClicked = true;
    if (this.disablePartNo == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    };
    setTimeout(() => {
      this.currentSelectedRow = event.rowindex;
      this.commonservices.identifySelectedRow(this.tabledata, event.rowindex, this.currentSelectedRow, "table1");
    }, 200);
    this.eve = event
    console.log(this.eve);
    this.editButton = true;
    this.subtableheaders = this.commonservices.enableEditForGt(this.helpers.removeRefrence(this.subtableheaders), "Qty", false);

    this.editDisableBtn = false;
    if (navigator.onLine) {
      this.errormsg = "";
      this.oprationForRowClick = "clickedOnRow";
      this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;
      // this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
      // this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = false;
      this.loaderTimeOut();
      if (flag) {
        this.getFormDataTableData(event)
      }
      //  this.showloader = false;

    } else {
      //  this.showloader = false;
      alert("Internet Connection is Not Available.");
    }

  }

  getFormDataTableData(event) {
    let apiUrl = this.configurationsetting.getBaseUrl() + "Master/GetListofSpareforItem";
    let params = { "partvalue": event['row']['SpareNo'], "requestfor": "Spare" };


    this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
      //  this.showloader = false;

      if (successResp['errorCode'] == "000000") {

        this.subtabledata = [];
        // console.log("prev data  : ", this.subtabledata);
        this.subtabledata = JSON.parse(JSON.stringify(successResp['Data']));

        this.spareName = event['row']['sparename'];
        this.partNo = event['row']['partno'];
        this.Application = event['row']['Application'];
        this.Location = event['row']['Location'];
        this.minimumStock = Number.isNaN(event['row']['MinStock']) ? "" : event['row']['MinStock'];
        this.oldNumber = event['row']['OldPartNumber'];
        this.subLocation = event['row']['SubLocation'];
        this.supersedNumber = Number.isNaN(event['row']['SupersededNo']) ? "" : event['row']['SupersededNo'];
        this.currentBalance = event['row']['CurrentBalance'];
        this.WIP = event['row']['WIP'];
        this.disablePartNo = true;
        this.disableSpareName = true;
        this.disableApplication = true;
        this.disableLocation = true;
        this.disableSubLocation = true;
        this.disableOldNo = true;
        this.disableSupersedNo = true;
        this.disableMinstock = true;
        this.disableCancelBtn = false;
        this.disableSaveBtn = true;
        this.saveBtnVisible = false;
        this.disableNewBtn = false;
        this.visibleNewBtn = true;
        this.visibleSaveBtn = true;
        this.visibleCancelBtn = true;
        this.displaySubTable = true;
        this.doubleClickStage = "rowDoubleClickSpareMaster";

        let row = event['row']
        let userdeleted = event['username']
        let deletedDate = event['deldate']
        if (row["isDeleted"] === "N") {
          this.btnRow = false;
          this.spareName = "";
          this.partNo = "";
          this.Application = "";
          this.Location = "";
          this.minimumStock = 0;
          this.oldNumber = "";
          this.subLocation = "";
          this.supersedNumber = "";
          this.currentBalance = 0;
          this.WIP = 0;
          this.subtabledata = [];
          // console.log(true);
          // this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        } else {
          this.btnRow = true;
          // this.deleteMsg = ""
        }
        this.editButton = true;

        this.isRowClicked = false;

      }else{
        this.isRowClicked = false;

      }
    }, errorResp => {
      //  this.showloader = false;
      this.isRowClicked = false;
      this.errormsg = errorResp['message']
    });
  }

  getOldNoSopersedNoList() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.oldNoSupersedNoList = [];
      //  this.showloader = true;
      this.loaderTimeOut();
      this.commonservices.getDynamicDropDownList("spareddl", this.configurationsetting.getBaseUrl())
        .subscribe(successResp => {
          //  this.showloader = false;
          if (successResp['errorCode'] == "000000") {
            this.oldNoSupersedNoList = successResp['Data'];
          } else {
            this.errormsg = successResp['errorDescription']

          }


        }, errorResp => {
          //  this.showloader = false;
          this.errormsg = errorResp['message']
        });
    } else {
      alert("Internet Connection is Not Available.");
    }
  }



  getMasterTable() {
    this.errormsg = "";
    //  this.showloader = true;
    this.commonservices.getGenericTable("SpareMaster", this.configurationsetting.getBaseUrl())
      .subscribe(successResp => {
        //  this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.dynamicTable = successResp['Data'][0]['@oritable'];
          // this.dynamicTable['tabledata'] = successResp['Data'][0]['@tbldata'];

          this.tableheaders = this.dynamicTable["tableheaders"];
          this.tabledata = successResp['Data'][0]['@tbldata'];
          // console.log(this.tabledata);

          // console.log("this.partnoforsubtbl", this.partnoforsubtbl);
          // console.log(this.subtableheaders);
          // 1095


          for (let i = 0; i < this.tabledata.length; i++) {
            if (this.tabledata[i]['SpareNo'] == this.partnoforsubtbl) {
              // console.log("Index", this.tabledata[i], i);
              // console.log(this.tabledata[i]['SpareNo'] , this.partnoforsubtbl);
              this.rowDoubleClickSpareMaster({
                "row": this.tabledata[i],
                "rowindex": i
              })
              setTimeout(() => {
                $(`tr[tabindex=${i}]`).focus();
                // let rowselect = document.getElementById('trtable1' + i)
                // console.log(rowselect);

                // document.getElementById('trtable1' + i).style.backgroundColor = "#7de4f0";
                // if(!this.showonlyForm){
                // }
              }, 900);
              break;
            }
          }


          /*****************************hide qty  column  starts here****************************************/

          for (let i = 0; i < this.dynamicTable['tableheaders'].length; i++) {
            if (this.dynamicTable['tableheaders'][i]['key'] == "Qty") {
              this.dynamicTable['tableheaders'][i]['visiblecolm'] = false;
            }
          }
          // this.subtabledata = this.subtabledata;
          /*****************************hide qty column  ends  here****************************************/

          // this.tableconfig = this.dynamicTable["tableconfig"];
          // this.tabledata = this.dynamicTable["tabledata"];
          this.tableheaders.forEach((item) => {
            if (item['key'] == "CurrentBalance" || item['key'] == "WIP") {
              item['visiblecolm'] = true;
            }
            // console.log();

          })

          /*********changes starts here********/
          if (this.userRole == "Admin") {
            this.dynamicTable['tableconfig']['actioncolmconfig']['delete'] = true;
          } else {
            this.dynamicTable['tableconfig']['actioncolmconfig']['delete'] = false;
          }
          /********chnages ends here*********/
          this.displayTable = true;

        }
        else {
          this.errormsg = successResp["errorDescription"];
        }
      }, errorResp => {
        this.errormsg = errorResp['message']

      });
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show();
  }

  deleteSpareMasterdataForm(event) {
    this.deleteSpareMaster(event)
    this.showDeleteModal.hide();
    this.deleteBtn = false;
    this.CancelBtn = false;
  }

  deleteSpareMaster(event) {
    console.log(event);
    this.delEve = event;

    if (navigator.onLine) {
      if (this.operation == "editSpareMasterForm" || this.doubleClickStage == "rowDoubleClickSpareMaster") {

        const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';

        this.operation = "";
        this.doubleClickStage = "";
        // this.resetSpareMasterForm.reset();
        // this.subtabledata = [];
        this.displaySubTable = false;
      }
      this.deleteById = event['row']['SpareNo'];
      this.opeartionStageForDelete = "delete";
      this.confirmDeleteSpareMaster();
      // this.displayMessagePopup("Are you sure want to delete this record?", "Yes", true, "No", true);
    }
    else {
      this.displayMessagePopup("Internet Connection is Not Available.", "Ok", true, false, "");
    }
  }
  confirmDeleteSpareMaster() {
    if (navigator.onLine) {
      this.errormsg = "";
      //  this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/CheckDeleteSpare";
      let obj = {};
      // obj['deletefor'] = "Spare"
      obj['SpareNo'] = this.deleteById;

      this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
        //  this.showloader = false;
        if (successResp['errorCode'] != "000000") {
          console.log(successResp['errorDescription']);

          this.disableForm();
          this.showconfirmpopup = false;
          // this.resetSpareMasterForm.reset();
          // this.subtabledata = [];
          this.displaySubTable = false;
          this.opeartionStageForDelete = "deleted";

          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);

        } else if (successResp['errorCode'] == "000000") {

          this.showconfirmpopup = false;
          this.resetSpareMasterForm.reset();
          this.subtabledata = [];
          this.displaySubTable = false;
          this.btnRow = false;
          this.opeartionStageForDelete = "deleted";
          this.disableForm();

          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
        }
      }, errorResp => {
        //  this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      //  this.showloader = false;

      alert("Internet Connection is Not Available.");

    }
  }

  cancelConfirmationPopup() {
    this.showconfirmpopup = false;
    this.opeartionStageForDelete = "";
  }

  confirmPopup(event) {
    if (this.opeartionStageForDelete == "delete") {
      this.opeartionStageForDelete = "";
      this.confirmDeleteSpareMaster();
    }
    else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
      this.getMasterTable();

    } else if (this.editOperation == "saveSpareMaster") {

      // const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
      // if (ele) ele.style.backgroundColor = '';

      this.editOperation = "";
      this.getMasterTable();
    }

    this.showconfirmpopup = false;
  }

  setBootstrapClasses() {
    let colName = 0;
    for (let i = 0; i < this.dynamicformdata['css']["mappingarray"].length; i++) {
      for (let j = 0; j < this.dynamicformdata['css']["mappingarray"][i].length; j++) {
        if (this.dynamicformdata['css']["mappingarray"][i][j]["colspan"] != undefined)
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]) * +this.dynamicformdata["mappingarray"][i][j]["colspan"];
          colName = +this.dynamicformdata['css']["gridinfo"]["gridcols"] * +this.dynamicformdata['css']["mappingarray"][i][j]["colspan"];
        else
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]);
          colName = +this.dynamicformdata['css']["gridinfo"]["gridcols"];
        this.dynamicformdata['css']["mappingarray"][i][j]["ColClassName"] = "col-xs-12 col-sm-12 col-md-" + colName + " col-lg-" + colName + " col-xl-" + colName;
      }
    }
  }


  editForm(event) {
    console.log(event);
    this.editButton = false;
    this.disablePartNo = false;
    this.disableSpareName = false;
    this.disableApplication = false;
    this.disableLocation = false;
    this.disableSubLocation = false;
    this.disableOldNo = false;
    this.disableSupersedNo = false;
    this.disableMinstock = false;
    this.editDisableBtn = true;
    this.disableSaveBtn = false;
    this.saveBtnVisible = true;
    this.disableCancelBtn = false;
    this.visibleSaveBtn = true;
    this.disableNewBtn = true;
    this.CancelBtn = true;
    this.deleteBtn = true;
    this.subtableheaders = this.commonservices.enableEditForGt(this.helpers.removeRefrence(this.subtableheaders), "Qty", true);
    this.subtableconfig['actioncolmconfig']['displaycolumn'] = true;


    this.editSpareMasterForm(event)

  }


  editSpareMasterForm(event) {
    console.log(event);


    if (navigator.onLine) {
      this.errormsg = "";
      this.eventRowIndex = "";
      this.operation = "";
      //  this.showloader = true;
      this.loaderTimeOut();
      this.indexForEdit = -1;
      this.indexForEdit = event['row']['actualindex'];
      this.spareName = "";
      this.Location = "";
      this.subLocation = "";
      this.partNo = 0;
      this.Application = "";
      // this.minimumStock = "";
      // this.oldNumber = 0;
      this.supersedNumber = "";
      this.operation = "";
      this.disablePartNo = false;
      this.disableSpareName = false;
      this.disableApplication = false;
      this.disableLocation = false;
      this.disableSubLocation = false;
      this.disableOldNo = false;
      this.disableSupersedNo = false;
      this.disableMinstock = false;
      this.disableSaveBtn = false;
      this.saveBtnVisible = true;
      this.disableCancelBtn = false;
      this.disableNewBtn = true;
      this.spareNoToSave = event['row']['SpareNo'];
      this.spareName = event['row']['sparename'];
      this.partNo = event['row']['partno'];
      this.Application = event['row']['Application'];
      this.Location = event['row']['Location'];
      this.minimumStock = Number.isNaN(event['row']['MinStock']) || event['row']['MinStock'] == 0 ? "" : event['row']['MinStock'];
      this.oldNumber = event['row']['OldPartNumber'];
      this.subLocation = event['row']['SubLocation'];
      this.currentBalance = event['row']['CurrentBalance'];
      this.WIP = event['row']['WIP'];
      this.supersedNumber = Number.isNaN(event['row']['SupersededNo']) ? "" : event['row']['SupersededNo'];

      if (event['row']['ItemSpareData'] == null || event['row']['ItemSpareData'] == undefined) {
        this.subtabledata = [];
        // this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));

      }
      else if (typeof (event['row']['ItemSpareData']) == 'string') {
        this.subtabledata = [];
        let tempArray = []
        tempArray = JSON.parse(event['row']['ItemSpareData']);
        this.subtabledata = JSON.parse(JSON.stringify(tempArray))
        // this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
      } else {
        this.subtabledata = [];

        this.subtabledata = JSON.parse(JSON.stringify(event['row']['ItemSpareData']));
        // this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
      }

      if (this.oprationForRowClick == "clickedOnRow") {
        this.oprationForRowClick = "";
        // this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
        // this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = true;

      }

      //  this.showloader = false;
      this.visibleNewBtn = false;
      this.visibleSaveBtn = true;
      this.visibleCancelBtn = true;
      this.displaySubTable = true;
      this.isEditable = true;
      this.operationStage = "editSpareMasterForm";

    }
    else {
      alert("Internet Connection is Not Available.");
    }
  }



  checkRate(e) { }
  addSubTax(e) { }
  closeSubTaxPopUpEvent() { }

  cancelSpareMasterForm(event) {
    this.addBtn = false;
    if (confirm("Do you want to cancel!")) {
      if (this.operation == "NEW") {
        this.resetSpareMasterForm.reset();
        this.subtabledata = [];
        this.editButton = false;
        this.btnRow = false;
      } else {
        this.editButton = true;
        this.btnRow = true;
      }

      this.deleteMsg = "";
      this.deleteBtn = false;
      this.isEditable = false;
      this.visibleNewBtn = true;
      this.visibleSaveBtn = false;
      this.visibleCancelBtn = false;
      this.disableNewBtn = false;
      this.editDisableBtn = false;
      this.CancelBtn = false;
      this.saveBtnVisible = false;
      this.deleteBtn = false;
      this.subtableheaders = this.commonservices.enableEditForGt(this.helpers.removeRefrence(this.subtableheaders), "Qty", false);
      this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;
      this.disableInputs();
    }

  }


  closeModel(data = null) {
    this.ngbActiveModal.close(data);
  }








  //////if http not gave the response after spend 30 seconds the we will show the error.
  loaderTimeOut() {
    setTimeout(() => {
      if (this.showloader) {
        //  this.showloader = false;
        this.errormsg = "Error while login your account.";
      }
    }, 50000)
  }

  //displayMessagePopup() is used to call and maintain confirmation popup modal
  displayMessagePopup(popupmessage, submittext, displaysubmit, canceltext, displaycancel) {
    this.confirmationpopupmodelobj.inputmsg = popupmessage;
    this.confirmationpopupmodelobj.submitbtntext = submittext
    this.confirmationpopupmodelobj.displaysubmit = displaysubmit;
    this.confirmationpopupmodelobj.cancelbtntext = canceltext;
    this.confirmationpopupmodelobj.displaycancel = displaycancel;
    this.showconfirmpopup = true;
  }

  addItemBtn() { }
}
