// import { Injectable } from '@angular/core';

// @Injectable()
// export class AddHeadersService {

//   constructor() { }

// }
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { CommonServicesService } from './common-services.service';
import { Router } from '@angular/router';

// @Injectable()
@Injectable()
export class AddHeadersService implements HttpInterceptor {
  constructor(private commonService:CommonServicesService,private route:Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
  // console.log('kkkk');
  if (!this.commonService.apiToken || this.commonService.apiErrorDesc == 'Invalid token.') {
    this.route.navigateByUrl("/login");
    return next.handle(req);
  }
    const urlindx = req.url.indexOf("LoginValidation");
    if (urlindx != -1) {
      return next.handle(req);
    }
    var clientkey = sessionStorage.getItem('clientkey');
    clientkey = clientkey.replace (/(^")|("$)/g, '');
    const authReq = req.clone({
      headers: req.headers.set('clientkey', clientkey)
    });
    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
