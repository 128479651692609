import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../appconfig';
import { CommonServicesService } from '../../services/common-services.service';
import { DataSharingService } from '../gt-plus/data-sharing.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfirmationPopupModel } from '../../models/models';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-re-repairs',
  templateUrl: './re-repairs.component.html',
  styleUrls: ['./re-repairs.component.scss']
})
export class ReRepairsComponent implements OnInit {
  @ViewChild('reRepairsOutwardForm') reRepairsOutwardForm: NgForm;
  @ViewChild('reRepairsInwardForm') reRepairsInwardForm: NgForm;

  subscription: Subscription;
  saveFor: string = '';
  deleteFor: string = '';
  errormsg: string = '';
  operation: string = '';
  modellabel: string = '';
  headerName: string = '';
  reRepairRemarks: string = '';
  deleteDetailsOpr: string = '';
  doubleClickStage: string = '';
  opeartionStageForDelete: string = '';
  formNameForsubDeleteOpr: string = '';

  userId: number;
  rowIndex: number;
  challanNo: number;
  repInwardNo: number;
  indexForEdit: number;
  customerName: any;
  repOutwardNo: number;
  subassemblyno: number;
  totalQtyIn: number = 0;
  repInwDetSrNo: number;
  deleteDetailById: number;
  deleteDetailByIDLocally: number;
  rowsperpage = 100;

  grrdate: any;
  challanDate: any;
  // used to show show btn
  // showReRepairs: boolean = true;
  custmerList: any[] = [];
  sparedeatils: any[] = [];
  inwardTable: any = {};
  subAssbDynTable: any = {};
  reRepairInwardTable: any = {};
  reRepairOutwardTable: any = {};
  subdynamictableRerepairInward: any = {};
  subdynamictableRerepairOutward: any = {};
  isEditable: boolean = false; // is used for save inward outward
  showloader: boolean = false;
  displayInwardTable: boolean = false;
  showconfirmpopup: boolean = false;
  displayRerepairInwardTable: boolean = false;
  displayRerepairOutwardTable: boolean = false;
  displayRerepairInwardSubTable: boolean = false;
  displayRerepairOutwardSubTable: boolean = false;
  showPOSubAssbTablePopup: boolean = false;
  displaySubAssTable: boolean = false;
  visibleFirstSave: boolean = false;
  visibleSecondSave: boolean = false;
  visibleNewBtn: boolean = false;
  visibleSaveBtn: boolean = false;
  visibleCancelBtn: boolean = false;
  disableRepairRemarks: boolean = false;
  disableChallanNo: boolean = false;
  disableChallanDate: boolean = false;
  disableGrrDate: boolean = false;
  disableCustomerList: boolean = false;
  disableRepairPrintRemarks: boolean = false;
  headers = new HttpHeaders();
  confirmationpopupmodelobj: ConfirmationPopupModel = new ConfirmationPopupModel();
  highlightedObj = {
    'id': '',
    'indx': -1
  };
  repInwardtableheaders = [];
  repInwardtabledata = [];
  repInwardtableconfig = {};
  inwardsubtableheaders = [];
  inwardsubtabledata = [];
  inwardsubtableconfig = {};
  outwardtableheaders = [];
  outwardtabledata = [];
  outwardtableconfig = {};
  outwardsubtableheaders = [];
  outwardsubtabledata = [];
  outwardsubtableconfig = {};
  subasstableheaders = [];
  subasstabledata = [];
  subasstableconfig = {};
  inwardtableheaders = [];
  inwardtabledata = [];
  inwardtableconfig = {};

  @ViewChild('showPOSubAssbTableModal', { static: false }) public showPOSubAssbTableModal: ModalDirective;

  constructor(private activatedRoute: ActivatedRoute, private datepipe: DatePipe, private dataSharingSrvc: DataSharingService, private commonservices: CommonServicesService, private configurationsetting: ConfigurationSetting) {
    /****subscribe here to published message to get menu name starts here*****/
    // this.subscription = this.commonservices.getMessage()
    //   .subscribe(menuname => {
    //     this.changeHeaderName();
    //   });
    /****subscribe here to published message to get menu name ends here*****/
  }

  ngOnInit() {
    const routeParams = this.activatedRoute.snapshot.paramMap;
    const menuname = routeParams.get('name');
    this.commonservices.sendName = menuname;



    this.configurationsetting.hideLeftMenu();
    /***important to call here again otherwise it give first click as a  blank page****/
    this.changeHeaderName();
    this.grrdate = new Date();
    this.challanDate = new Date();
    this.userId = JSON.parse(sessionStorage.getItem('logindata'))['UserID'];
  }

  /**************after subscribing show header name as per incoming sidebarMenuName starts here ***************/
  /*********this.commonservices.sendName :- is a public variable name to get menuname from starter left side component*************/
  changeHeaderName() {
    this.errormsg = '';
    this.headerName = '';
    switch (this.commonservices.sendName) {
      case 'undefined':
        this.headerName = '';
        break;
      case 'reRepairsInward':
        this.headerName = 'Re-Repairs Inward';
        this.saveFor = 'repairsinward';
        this.visibleFirstSave = true;
        this.visibleSecondSave = false;
        this.modellabel = 'Select Item To Inward';

        break;
      case 'reRepairsOutward':
        this.headerName = 'Re-Repairs Outward';
        this.saveFor = 'repairsoutward';
        this.visibleSecondSave = true;
        this.visibleFirstSave = false;
        this.modellabel = 'Select Item To Outward';
        break;
      default:
        this.headerName = '';
        break;
    }

    /*******we cant give call to this getSpareTransactionsTablesNForms()
     * function in ngOnIt coz oninit will call for only single time
     * and if user migrating from one menu to another it wont get hit again********/
    if (this.commonservices.sendName != '') {

      this.getRERepairInwardTableNForm();

      setTimeout(() => {
        this.disableForm('Yes');
      }, 800)
    }
  }

  /**************after subscribing show header name as per incoming sidebarMenuName starts here ***************/

  /**disableForm() method will disable form  menu wise - if permission is Yes then form will be disabled**/
  disableForm(permission) {
    switch (this.commonservices.sendName) {
      case 'reRepairsInward':
        if (permission == 'Yes') {
          this.disableRepairRemarks = true;
          this.disableChallanNo = true;
          this.disableChallanDate = true;
          this.disableGrrDate = true;
        }
        else {
          this.disableRepairRemarks = false;
          this.disableChallanNo = false;
          this.disableChallanDate = false;
          this.disableGrrDate = false;
        }
        this.saveFor = 'repairsinward';
        break;

      case 'reRepairsOutward':
        if (permission == 'Yes') {
          this.disableRepairRemarks = true;
          this.disableRepairPrintRemarks = true;
          this.disableGrrDate = true;
          this.disableCustomerList = true;
        }
        else {
          this.disableRepairRemarks = false;
          this.disableRepairPrintRemarks = false;
          this.disableGrrDate = false;
          this.disableCustomerList = false;
        }
        this.saveFor = 'repairsoutward';
        break;
    }
    this.visibleNewBtn = true;
    this.visibleSaveBtn = false;
    this.visibleCancelBtn = false;
  }

  /*  getDCInwardTableNForm() method to get forms and generic table starts here  */
  getRERepairInwardTableNForm() {
    if (navigator.onLine) {
      this.errormsg = '';
      this.showloader = true;
      this.loaderTimeOut();
      this.totalQtyIn = 0;

      let apiUrl = this.configurationsetting.getBaseUrl() + 'TransactionProcess/GetRepairsInwardOutwardDetails';
      let params;
      switch (this.commonservices.sendName) {
        case 'reRepairsInward':
          params = { 'formname': 'RepairsInward' };
          break;
        case 'reRepairsOutward':
          params = { 'formname': 'RepairsOutward' };
          break;
      }
      this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == 0) {
          switch (this.commonservices.sendName) {
            case 'reRepairsInward':
              this.reRepairsInwardForm.reset();
              this.reRepairInwardTable = successResp['Data'][0]['@Oritbl'];
              this.reRepairInwardTable['tableconfig']['actioncolmconfig']['delete'] = false;
              this.subdynamictableRerepairInward = successResp['Data'][0]['@subtbl'];
              /************************ hide ref no and job no column  starts here *****************************/
              for (let i = 0; i < this.subdynamictableRerepairInward['tableheaders'].length; i++) {
                if (this.subdynamictableRerepairInward['tableheaders'][i]['key'] == 'refno' || this.subdynamictableRerepairInward['tableheaders'][i]['key'] == 'jobno') {
                  this.subdynamictableRerepairInward['tableheaders'][i]['visiblecolm'] = false;
                }
              }
              this.subdynamictableRerepairInward = JSON.parse(JSON.stringify(this.subdynamictableRerepairInward));

              // this.dynamicformdata = successResp['Data'][0]['@formjson'];
              // this.setBootstrapClasses();
              if (this.reRepairInwardTable['tabledata'] == null) {
                this.reRepairInwardTable['tabledata'] = [];
              }
              else {
                /*make date format starts here*/
                for (let j = 0; j < this.reRepairInwardTable['tabledata'].length; j++) {
                  this.reRepairInwardTable['tabledata'][j]['dcissuedate'] = this.datepipe.transform(this.reRepairInwardTable['tabledata'][j]['dcissuedate'], 'dd/MMM/yyyy');
                }
                /*make date format ends here*/
              }

              if (this.subdynamictableRerepairInward['tabledata'] == null) {
                this.subdynamictableRerepairInward['tabledata'] = [];
              }

              if (successResp['Data'][0]['@modelitemtbl']) { this.inwardTable = successResp['Data'][0]['@modelitemtbl']; }

              if (successResp['Data'][0]['@SubAsmblytbl']) { this.subAssbDynTable = successResp['Data'][0]['@SubAsmblytbl']; }

              /*****************************hide checkbox and rate column  starts here****************************************/
              if (this.subAssbDynTable['tabledata'] == null) {
                this.subAssbDynTable['tabledata'] = [];
              }
              else {
                for (let i = 0; i < this.subAssbDynTable['tableheaders'].length; i++) {
                  if (this.subAssbDynTable['tableheaders'][i]['key'] == '' || this.subAssbDynTable['tableheaders'][i]['key'] == 'price') {
                    this.subAssbDynTable['tableheaders'][i]['visiblecolm'] = false;
                  }
                }
              }

              /*make date format starts here*/
              this.subAssbDynTable['tabledata'].filter(row => {
                row['DateEffectiveFrom'] = this.datepipe.transform(row['DateEffectiveFrom'], 'dd/MMM/yyyy');
              });
              this.subAssbDynTable = JSON.parse(JSON.stringify(this.subAssbDynTable));
              this.inwardsubtableconfig = this.subAssbDynTable["tableconfig"];
              this.inwardsubtabledata = this.subAssbDynTable["tabledata"];
              this.inwardsubtableheaders = this.subAssbDynTable["tableheaders"];

              this.repInwardtableconfig = this.reRepairInwardTable["tableconfig"];
              this.repInwardtabledata = this.reRepairInwardTable["tabledata"];
              this.repInwardtableheaders = this.reRepairInwardTable["tableheaders"];
              // /*make date format ends here*/

              /**********make this button enable when user clicks on confirm PO btn so thats why its false here starts here*********************/

              this.displayRerepairInwardTable = true;
              this.displayRerepairInwardSubTable = false;

              this.visibleNewBtn = true;
              this.visibleSaveBtn = false;
              this.visibleCancelBtn = false;
              break;

            case 'reRepairsOutward':
              this.reRepairsOutwardForm.reset();
              this.reRepairOutwardTable = successResp['Data'][0]['@Oritbl'];
              if (this.reRepairOutwardTable['tabledata'] == null) {
                this.reRepairOutwardTable['tabledata'] = [];
              }
              else {
                /*make date format starts here*/
                for (let j = 0; j < this.reRepairOutwardTable['tabledata'].length; j++) {
                  this.reRepairOutwardTable['tabledata'][j]['outwarddate'] = this.datepipe.transform(this.reRepairOutwardTable['tabledata'][j]['outwarddate'], 'dd/MMM/yyyy');
                }
                /*make date format ends here*/
              }
              // this.reRepairOutwardTable = { 'tableheaders': [{ 'key': 'repoutsrno', 'display': 'Outw. No', 'sorting': true, 'enablehide': true, 'datatype': 'number', 'thstyle': { 'text-align': 'center ', 'width': '25%' } }, { 'key': 'outwarddate', 'display': 'Out Date', 'sorting': true, 'enablehide': true, 'datatype': 'string', 'thstyle': { 'text-align': 'center ', 'width': '35%' } }], 'tableconfig': { 'dragdrop': { 'rows': false, 'columns': false }, 'oddrowstyle': { 'cursor': 'pointer' }, 'evenrowstyle': { 'cursor': 'pointer' }, 'headrowstyle': {}, 'resizable': true, 'columnwisefilter': false, 'contextmenu': false, 'hidecolumns': true, 'defaultsearch': true, 'menulist': [{ 'display': 'Hide Column', 'identifier': 'hidecolumn' }, { 'display': 'Display Hidden Columns', 'identifier': 'displayhiddencols' }, { 'display': 'Search', 'identifier': 'specificsearch' }], 'exporttoexcel': true, 'filenameprefix': 'test', 'repinwsrno': { 'text-align': 'right' }, 'actioncolmconfig': { 'displaycolumn': true, 'view': false, 'edit': true, 'delete': true, 'print': false }, 'controlconfig': { 'displaycontrol': false } }, 'tabledata': [{ 'repoutsrno': 1, 'outwarddate': '2020-03-13' }] };
              this.reRepairOutwardTable = JSON.parse(JSON.stringify(this.reRepairOutwardTable));
              this.subdynamictableRerepairOutward = successResp['Data'][0]['@subtbl'];

              if (this.subdynamictableRerepairOutward['tabledata'] == null) {
                this.subdynamictableRerepairOutward['tabledata'] = [];
              }
              this.subdynamictableRerepairOutward = JSON.parse(JSON.stringify(this.subdynamictableRerepairOutward));
              if (successResp['Data'][0]['@Custddl']) {
                this.custmerList = successResp['Data'][0]['@Custddl'];
              }
              else {
                this.custmerList = [];
              }
              if (successResp['Data'][0]['@modelitemtbl']) { this.inwardTable = successResp['Data'][0]['@modelitemtbl']; }
              if (successResp['Data'][0]['@SubAsmblytbl']) { this.subAssbDynTable = successResp['Data'][0]['@SubAsmblytbl']; }

              /********* hide checkbox and rate column  starts here **********/
              if (this.subAssbDynTable['tabledata'] == null) {
                this.subAssbDynTable['tabledata'] = [];
              }
              else {
                for (let i = 0; i < this.subAssbDynTable['tableheaders'].length; i++) {
                  if (this.subAssbDynTable['tableheaders'][i]['key'] == '' || this.subAssbDynTable['tableheaders'][i]['key'] == 'price' || this.subAssbDynTable['tableheaders'][i]['key'] == 'DateEffectiveFrom') {
                    this.subAssbDynTable['tableheaders'][i]['visiblecolm'] = false;
                  }
                  if (this.subAssbDynTable['tableheaders'][i]['key'] == 'item') {
                    this.subAssbDynTable['tableheaders'][i]['key'] = 'partno';
                    this.subAssbDynTable['tableheaders'][i]['display'] = 'Part No';
                  }
                }
              }

              /* make date format starts here */
              this.subAssbDynTable = JSON.parse(JSON.stringify(this.subAssbDynTable));

              this.outwardtableconfig = this.reRepairOutwardTable["tableconfig"];
              this.outwardtabledata = this.reRepairOutwardTable["tabledata"];
              this.outwardtableheaders = this.reRepairOutwardTable["tableheaders"];
              /* make date format ends here */
              this.visibleNewBtn = true;
              this.visibleSaveBtn = false;
              this.visibleCancelBtn = false;
              this.displayRerepairOutwardTable = true;
              this.displayRerepairOutwardSubTable = false;

              break;
          }
        }
      },
        errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message'];
        });

    } else {
      alert('Internet Connection is Not Available.');

    }

  }
  /*  getDCInwardTableNForm() method to get forms and generic table ends here   */

  /**showNewForm() method will enable form  menu wise**/
  showNewForm() {
    switch (this.commonservices.sendName) {
      case 'reRepairsInward':
        this.saveFor = 'repairsinward';
        if (document.getElementById(this.highlightedObj.id)) {
          document.getElementById(this.highlightedObj.id).style.backgroundColor = '';
        }
        this.totalQtyIn = 0;
        this.subdynamictableRerepairInward['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
        this.subdynamictableRerepairInward['tableconfig']['controlconfig']['displaycontrol'] = true;
        this.subdynamictableRerepairInward['tableconfig']['enablefirstbtn'] = true;
        this.reRepairsInwardForm.reset();
        this.grrdate = new Date();
        this.challanDate = new Date();
        this.disableRepairRemarks = false;
        this.disableChallanNo = false;
        this.disableChallanDate = false;
        this.disableGrrDate = false;
        this.subdynamictableRerepairInward['tabledata'] = [];
        this.subdynamictableRerepairInward = JSON.parse(JSON.stringify(this.subdynamictableRerepairInward));
        this.inwardsubtableconfig = this.subAssbDynTable["tableconfig"];
        this.inwardsubtabledata = this.subAssbDynTable["tabledata"];
        this.inwardsubtableheaders = this.subAssbDynTable["tableheaders"];
        this.displayRerepairInwardSubTable = true;

        break;
      case 'reRepairsOutward':
        if (document.getElementById(this.highlightedObj.id)) {
          document.getElementById(this.highlightedObj.id).style.backgroundColor = '';
        }
        this.totalQtyIn = 0;
        this.subdynamictableRerepairOutward['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
        this.subdynamictableRerepairOutward['tableconfig']['controlconfig']['displaycontrol'] = true;
        this.subdynamictableRerepairOutward['tableconfig']['enablefirstbtn'] = true;
        this.reRepairsOutwardForm.reset();
        this.grrdate = new Date();
        this.disableRepairRemarks = false;
        this.disableGrrDate = false;
        this.disableCustomerList = false;
        this.subdynamictableRerepairOutward['tabledata'] = [];
        this.subdynamictableRerepairOutward = JSON.parse(JSON.stringify(this.subdynamictableRerepairOutward));
        this.outwardsubtableconfig = this.subdynamictableRerepairOutward["tableconfig"];
        this.outwardsubtabledata = this.subdynamictableRerepairOutward["tabledata"];
        this.outwardsubtableheaders = this.subdynamictableRerepairOutward["tableheaders"];
        this.displayRerepairOutwardSubTable = true;

        break;

    }
    this.isEditable = false;
    this.visibleNewBtn = false;
    this.visibleSaveBtn = true;
    this.visibleCancelBtn = true;
  }

  /******* Single click for inward screeanrowSingleClickInward(singleRow) *********/
  rowSingleClickInward(singleRow) {
    if (navigator.onLine) {
      this.errormsg = '';
      this.doubleClickStage = 'rowSingleClick';
      if (document.getElementById(this.highlightedObj.id)) {
        document.getElementById(this.highlightedObj.id).style.backgroundColor = '';
      }
      this.highlightedObj.id = 'trtable1' + singleRow['rowindex'];
      this.highlightedObj.indx = singleRow['rowindex'];
      document.getElementById(this.highlightedObj.id).style.backgroundColor = 'yellow';
      this.isEditable = true;
      this.editReRepairInward(singleRow);

    }
    else {
      alert('Internet Connection is Not Available.');
    }
  }
  /* end of single click function for inward */

  /******* Single click for inward screeanrowSingleClickInward(singleRow) *********/
  rowSingleClickOutward(singleRow) {
    if (navigator.onLine) {
      this.errormsg = '';
      this.doubleClickStage = 'rowSingleClick';
      if (document.getElementById(this.highlightedObj.id)) {
        document.getElementById(this.highlightedObj.id).style.backgroundColor = '';
      }
      this.highlightedObj.id = 'trtable1' + singleRow['rowindex'];
      this.highlightedObj.indx = singleRow['rowindex'];
      document.getElementById(this.highlightedObj.id).style.backgroundColor = 'yellow';
      this.isEditable = true;
      this.editReRepairOutward(singleRow);
    }
    else {
      alert('Internet Connection is Not Available.');
    }
  }

  /******* start of edit form and table by clicking in edit btn in oritable(singleRow) for inward ********/
  editReRepairInward(singleRow) {
    if (navigator.onLine) {
      this.errormsg = '';
      this.showloader = true;

      this.isEditable = true;
      this.indexForEdit = -1;
      if (document.getElementById(this.highlightedObj.id)) {
        document.getElementById(this.highlightedObj.id).style.backgroundColor = '';
      }
      this.rowIndex = singleRow['rowindex'];
      this.highlightedObj.id = 'trtable1' + singleRow['rowindex'];
      this.highlightedObj.indx = singleRow['rowindex'];
      document.getElementById(this.highlightedObj.id).style.backgroundColor = 'yellow';
      this.loaderTimeOut();
      this.indexForEdit = singleRow['row']['actualindex'];
      this.repInwardNo = singleRow['row']['repinwsrno'];
      let apiUrl = this.configurationsetting.getBaseUrl() + 'TransactionProcess/EditRepairsInwardOutwardDetails';
      let params = { 'repinwsrno': singleRow['row']['repinwsrno'], 'formname': 'RepairsInward' };

      this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == 0) {
          this.repInwardNo = successResp['Data'][0]['@Datajson'][0]['repinwsrno'];
          this.grrdate = this.datepipe.transform(successResp['Data'][0]['@Datajson'][0]['grrdte'], 'dd/MMM/yyyy');
          this.challanNo = successResp['Data'][0]['@Datajson'][0]['challanno'];
          this.challanDate = this.datepipe.transform(successResp['Data'][0]['@Datajson'][0]['dcissuedate'], 'dd/MMM/yyyy');
          this.reRepairRemarks = successResp['Data'][0]['@Datajson'][0]['remark'];
          this.subdynamictableRerepairInward = successResp['Data'][0]['@subtbl'];

          /************************ hide ref no and job no column  starts here *****************************/
          for (let i = 0; i < this.subdynamictableRerepairInward['tableheaders'].length; i++) {
            if (this.subdynamictableRerepairInward['tableheaders'][i]['key'] == 'refno' || this.subdynamictableRerepairInward['tableheaders'][i]['key'] == 'jobno') {
              this.subdynamictableRerepairInward['tableheaders'][i]['visiblecolm'] = false;
            }
          }
          this.subdynamictableRerepairInward = JSON.parse(JSON.stringify(this.subdynamictableRerepairInward));

          /**********calculate qty total here while single click starts here***************/
          this.totalQtyIn = 0;
          let rowAddition = 0;
          if (this.subdynamictableRerepairInward['tabledata'] == null) {
            this.subdynamictableRerepairInward['tabledata'] = [];
          }
          else {
            for (let i = 0; i < this.subdynamictableRerepairInward['tabledata'].length; i++) {
              rowAddition = rowAddition + parseInt(this.subdynamictableRerepairInward['tabledata'][i]['qtyin']);
              this.totalQtyIn = rowAddition;
            }
          }
          /**********calculate qty total here while single click ends here***************/

          /*****************************hide checkbox and rate column  starts here****************************************/
          if (this.subAssbDynTable['tabledata'] == null) {
            this.subAssbDynTable['tabledata'] = [];
          }
          else {
            for (let i = 0; i < this.subAssbDynTable['tableheaders'].length; i++) {
              if (this.subAssbDynTable['tableheaders'][i]['key'] == '' || this.subAssbDynTable['tableheaders'][i]['key'] == 'price') {
                this.subAssbDynTable['tableheaders'][i]['visiblecolm'] = false;
              }
            }
          }
          /*****************************hide checkbox and rate column  ends  here****************************************/
          if (successResp['Data'][0]['@modelitemtbl'] == null) {
            this.inwardTable = [];
            this.inwardTable = JSON.parse(JSON.stringify(this.inwardTable));
          }
          else {
            this.inwardTable = successResp['Data'][0]['@modelitemtbl'];
            this.inwardTable = JSON.parse(JSON.stringify(this.inwardTable));
          }

          if (this.doubleClickStage == 'rowSingleClick') {
            this.doubleClickStage = '';
            this.subdynamictableRerepairInward['tableconfig']['enablefirstbtn'] = false;
            this.subdynamictableRerepairInward['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
            this.subdynamictableRerepairInward['tableconfig']['controlconfig']['displaycontrol'] = false;
            this.inwardsubtableconfig = this.subAssbDynTable["tableconfig"];
            this.inwardsubtabledata = this.subAssbDynTable["tabledata"];
            this.inwardsubtableheaders = this.subAssbDynTable["tableheaders"];
            this.disableForm('Yes');
            this.displayRerepairInwardSubTable = true;
            this.visibleNewBtn = true;
            this.visibleSaveBtn = false;
            this.visibleCancelBtn = true;
          }
          else {
            if (successResp['errorDescription'] == "Success") {
              this.doubleClickStage = '';
              this.subdynamictableRerepairInward['tableconfig']['enablefirstbtn'] = true;
              this.subdynamictableRerepairInward['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
              this.subdynamictableRerepairInward['tableconfig']['controlconfig']['displaycontrol'] = true;
              this.inwardsubtableconfig = this.subAssbDynTable["tableconfig"];
              this.inwardsubtabledata = this.subAssbDynTable["tabledata"];
              this.inwardsubtableheaders = this.subAssbDynTable["tableheaders"];
              this.disableForm('No');
              this.displayRerepairInwardSubTable = true;
              this.visibleSaveBtn = true;
              this.visibleNewBtn = false;
              this.visibleCancelBtn = true;
            }
            else {
              this.doubleClickStage = '';
              this.subdynamictableRerepairInward['tableconfig']['enablefirstbtn'] = false;
              this.subdynamictableRerepairInward['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
              this.subdynamictableRerepairInward['tableconfig']['controlconfig']['displaycontrol'] = false;
              this.inwardsubtableconfig = this.subAssbDynTable["tableconfig"];
              this.inwardsubtabledata = this.subAssbDynTable["tabledata"];
              this.inwardsubtableheaders = this.subAssbDynTable["tableheaders"];
              this.disableForm('Yes');
              this.displayRerepairInwardSubTable = true;
              this.visibleNewBtn = true;
              this.visibleSaveBtn = false;
              this.visibleCancelBtn = true;
              this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
              // return;
            }
          }
        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      alert('Internet Connection is Not Available.');
    }
  }
  /******* end of edit form and table by clicking in edit btn in oritable(singleRow) for inward ********/

  /******* start of edit form and table by clicking in edit btn in oritable(singleRow) for outward ********/
  editReRepairOutward(singleRow) {
    if (navigator.onLine) {
      this.errormsg = '';
      this.showloader = true;

      this.isEditable = true;
      this.indexForEdit = -1;
      if (document.getElementById(this.highlightedObj.id)) {
        document.getElementById(this.highlightedObj.id).style.backgroundColor = '';
      }
      this.rowIndex = singleRow['rowindex'];
      this.highlightedObj.id = 'trtable1' + singleRow['rowindex'];
      this.highlightedObj.indx = singleRow['rowindex'];
      document.getElementById(this.highlightedObj.id).style.backgroundColor = 'yellow';
      this.loaderTimeOut();
      this.indexForEdit = singleRow['row']['actualindex'];
      this.repOutwardNo = singleRow['row']['repoutsrno'];
      let apiUrl = this.configurationsetting.getBaseUrl() + 'TransactionProcess/EditRepairsInwardOutwardDetails';
      let params = { 'repoutsrno': singleRow['row']['repoutsrno'], 'formname': 'RepairsOutward' };


      this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == 0) {
          this.repOutwardNo = successResp['Data'][0]['@Datajson'][0]['repoutsrno'];
          this.customerName = successResp['Data'][0]['@Datajson'][0]['customerid'];
          this.grrdate = this.datepipe.transform(successResp['Data'][0]['@Datajson'][0]['grrdate'], 'dd/MMM/yyyy');
          this.reRepairRemarks = successResp['Data'][0]['@Datajson'][0]['remark'];
          this.subdynamictableRerepairOutward = successResp['Data'][0]['@subtbl'];

          /************************ hide ref no and job no column  starts here *****************************/
          for (let i = 0; i < this.subdynamictableRerepairOutward['tableheaders'].length; i++) {
            if (this.subdynamictableRerepairOutward['tableheaders'][i]['key'] == 'refno' || this.subdynamictableRerepairOutward['tableheaders'][i]['key'] == 'jobno') {
              this.subdynamictableRerepairOutward['tableheaders'][i]['visiblecolm'] = false;
            }
          }
          /**********calculate qty total here while single click starts here***************/
          this.totalQtyIn = 0;
          let rowAddition = 0;
          if (this.subdynamictableRerepairOutward['tabledata'] == null) {
            this.subdynamictableRerepairOutward['tabledata'] = [];
          }
          else {
            for (let i = 0; i < this.subdynamictableRerepairOutward['tabledata'].length; i++) {
              this.subdynamictableRerepairOutward['tabledata'][i]['challandate'] = this.datepipe.transform(this.subdynamictableRerepairOutward['tabledata'][i]['challandate'], 'dd/MMM/yyyy');
              rowAddition = rowAddition + parseInt(this.subdynamictableRerepairOutward['tabledata'][i]['qty']);
              this.totalQtyIn = rowAddition;
            }
          }
          /**********calculate qty total here while single click ends here***************/
          // for (let j = 0; j < this.subdynamictableRerepairOutward['tabledata'].length; j++) {
          //   this.subdynamictableRerepairOutward['tabledata'][j]['challandate'] = this.datepipe.transform(this.subdynamictableRerepairOutward['tabledata'][j]['challandate'], 'dd/MMM/yyyy');
          // }
          this.subdynamictableRerepairOutward = JSON.parse(JSON.stringify(this.subdynamictableRerepairOutward));

          this.subAssbDynTable = successResp['Data'][0]['@SubAsmblytbl'];
          if (successResp['Data'][0]['@SubAsmblytbl'] == null) {
            this.subAssbDynTable = [];
            this.subAssbDynTable = JSON.parse(JSON.stringify(this.subAssbDynTable));
          }
          /*make date format starts here*/
          this.subAssbDynTable['tabledata'].filter(row => {
            row['DateEffectiveFrom'] = this.datepipe.transform(row['DateEffectiveFrom'], 'dd/MMM/yyyy');
          });
          this.subAssbDynTable = JSON.parse(JSON.stringify(this.subAssbDynTable));
          // /*make date format ends here*/


          /*****************************hide checkbox and rate column  starts here****************************************/
          if (this.subAssbDynTable['tabledata'] == null) {
            this.subAssbDynTable['tabledata'] = [];
          }
          else {
            for (let i = 0; i < this.subAssbDynTable['tableheaders'].length; i++) {
              if (this.subAssbDynTable['tableheaders'][i]['key'] == '' || this.subAssbDynTable['tableheaders'][i]['key'] == 'price') {
                this.subAssbDynTable['tableheaders'][i]['visiblecolm'] = false;
              }
            }
          }
          /*****************************hide checkbox and rate column  ends  here****************************************/
          if (successResp['Data'][0]['@modelitemtbl'] == null) {
            this.inwardTable = [];
            this.inwardTable = JSON.parse(JSON.stringify(this.inwardTable));
          }
          else {
            this.inwardTable = successResp['Data'][0]['@modelitemtbl'];
            this.inwardTable = JSON.parse(JSON.stringify(this.inwardTable));
          }

          if (this.doubleClickStage == 'rowSingleClick') {
            this.doubleClickStage = '';
            this.subdynamictableRerepairOutward['tableconfig']['enablefirstbtn'] = false;
            this.subdynamictableRerepairOutward['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
            this.subdynamictableRerepairOutward['tableconfig']['controlconfig']['displaycontrol'] = false;
            this.outwardsubtableconfig = this.subdynamictableRerepairOutward["tableconfig"];
            this.outwardsubtabledata = this.subdynamictableRerepairOutward["tabledata"];
            this.outwardsubtableheaders = this.subdynamictableRerepairOutward["tableheaders"];
            this.disableForm('Yes');
            this.displayRerepairOutwardSubTable = true;
            this.visibleNewBtn = true;
            this.visibleSaveBtn = false;
            this.visibleCancelBtn = true;
          }
          else {
            this.doubleClickStage = '';
            this.subdynamictableRerepairOutward['tableconfig']['enablefirstbtn'] = true;
            this.subdynamictableRerepairOutward['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
            this.subdynamictableRerepairOutward['tableconfig']['controlconfig']['displaycontrol'] = true;
            this.outwardsubtableconfig = this.subdynamictableRerepairOutward["tableconfig"];
            this.outwardsubtabledata = this.subdynamictableRerepairOutward["tabledata"];
            this.outwardsubtableheaders = this.subdynamictableRerepairOutward["tableheaders"];
            this.disableForm('No');
            this.displayRerepairOutwardSubTable = true;
            this.visibleSaveBtn = true;
            this.visibleNewBtn = false;
            this.visibleCancelBtn = true;
          }
        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      alert('Internet Connection is Not Available.');
    }
  }
  /******* end of edit form and table by clicking in edit btn in oritable(singleRow) for outward *********/

  /******* the customized first btn event of sub table ******/
  addSubAssembly() {
    // this.isEditable = false;
    this.showPOSubAssbTableModal.show();
    this.showPOSubAssbTablePopup = true;
    this.displayInwardTable = false;
    this.subasstableconfig = this.subAssbDynTable["tableconfig"];
    this.subasstabledata = this.subAssbDynTable["tabledata"];
    this.subasstableheaders = this.subAssbDynTable["tableheaders"];
    this.displaySubAssTable = true;
  }

  /* the commit function of generic table for  */
  commitOriTableData(event) {
    this.reRepairInwardTable['tabledata'] = [];
    this.reRepairInwardTable['tabledata'] = event;
  }

  /*** commitTableData(event) this method will call when user add data by double click and enters after adding starts here **/
  commitTableData(event) {
    this.totalQtyIn = 0;
    let rowAddition = 0;
    this.subdynamictableRerepairInward['tabledata'] = [];
    this.subdynamictableRerepairInward['tabledata'] = event;
    for (let i = 0; i < this.subdynamictableRerepairInward['tabledata'].length; i++) {
      rowAddition = rowAddition + parseInt(this.subdynamictableRerepairInward['tabledata'][i]['qtyin']);
      this.totalQtyIn = rowAddition;
    }
  }
  /*** commitTableData(event) this method will call when user add data by double click and enters after adding ends here **/

  /*** commitTableData(event) this method will call when user add data by double click and enters after adding starts here **/
  commitTableDataOutward(event) {
    this.totalQtyIn = 0;
    let rowAddition = 0;
    this.subdynamictableRerepairOutward['tabledata'] = [];
    this.subdynamictableRerepairOutward['tabledata'] = event;
    for (let i = 0; i < this.subdynamictableRerepairOutward['tabledata'].length; i++) {
      rowAddition = rowAddition + parseInt(this.subdynamictableRerepairOutward['tabledata'][i]['qty']);
      this.totalQtyIn = rowAddition;
    }
  }
  /*** commitTableData(event) this method will call when user add data by double click and enters after adding ends here **/

  /******* rowDoubleClickSubAssembly(subassemblyrow)method to get model and item under sub assembly No starts here *********/
  rowDoubleClickSubAssembly(subassemblyrow) {
    if (navigator.onLine) {
      this.errormsg = '';
      this.showloader = true;
      this.loaderTimeOut();
      switch (this.commonservices.sendName) {
        case 'reRepairsInward':
          if (subassemblyrow['row']['ModelItemData'][0] != null) {
            let str = '[' + subassemblyrow['row']['ModelItemData'][0] + ']'
            let inwardModelTable = JSON.parse(str);
            this.inwardTable['tabledata'] = inwardModelTable;
          }
          else {
            this.inwardTable['tabledata'] = [];
          }
          // console.log(JSON.stringify(this.inwardTable));
          this.inwardTable = JSON.parse(JSON.stringify(this.inwardTable));
          this.inwardtableconfig = this.inwardTable["tableconfig"];
          this.inwardtabledata = this.inwardTable["tabledata"];
          this.inwardtableheaders = this.inwardTable["tableheaders"];
          this.showloader = false;
          this.displayInwardTable = true;
          break;

        case 'reRepairsOutward':
          this.subassemblyno = subassemblyrow['row']['srno'];
          let body = {
            'subassemblyno': this.subassemblyno
          };
          let apiUrl = this.configurationsetting.getBaseUrl() + 'TransactionProcess/GetInwardDetailsList';

          this.commonservices.postMethod(apiUrl, body).subscribe(successResp => {
            this.showloader = false;
            if (successResp['errorCode'] == 0) {
              if (successResp['Data'][0]['@tbl']) {
                this.inwardTable = successResp['Data'][0]['@tbl'];
                for (let i = 0; i < this.inwardTable['tableheaders'].length; i++) {
                  if (this.inwardTable['tableheaders'][i]['key'] == 'partno') {
                    this.inwardTable['tableheaders'][i]['visiblecolm'] = false;
                  }
                  if (this.inwardTable['tableheaders'][i]['key'] == 'modelname') {
                    this.inwardTable['tableheaders'][i]['visiblecolm'] = true;
                  }
                  if (this.inwardTable['tableheaders'][i]['key'] == 'description') {
                    this.inwardTable['tableheaders'][i]['display'] = 'Item No';
                    this.inwardTable['tableheaders'][i]['key'] = 'itemno';
                  }
                  // this.inwardTable['tableconfig']['qty']['background-color'] = "";
                  // this.inwardTable['tableconfig']['remarks']['background-color'] = "";
                  this.inwardTable['tableconfig']['enablefirstbtn'] = false;
                  this.inwardTable['tableconfig']['actioncolmconfig']['delete'] = false;
                  this.inwardTable['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
                }
                if (this.inwardTable['tabledata'] == null) {
                  this.inwardTable['tabledata'] = [];
                }
                else {
                  for (let j = 0; j < this.inwardTable['tabledata'].length; j++) {
                    this.inwardTable['tabledata'][j]['challandate'] = this.datepipe.transform(this.inwardTable['tabledata'][j]['challandate'], 'dd/MMM/yyyy');
                  }
                }

                // here we did not get the checkbox column so added it
                let obj = {
                  "key": "",
                  "display": "",
                  "sorting": true,
                  "enablehide": true,
                  "datatype": "checkbox",
                  "thstyle": {
                    "text-align": "center",
                    "width": "5%"
                  }
                }
                this.inwardTable['tableheaders'].unshift(obj);
              }
              else {
                this.inwardTable['tabledata'] = [];
              }
              this.inwardTable = JSON.parse(JSON.stringify(this.inwardTable));
              this.inwardtableconfig = this.inwardTable["tableconfig"];
              this.inwardtabledata = this.inwardTable["tabledata"];
              this.inwardtableheaders = this.inwardTable["tableheaders"];
              // console.log("Inward tbl" + JSON.stringify(this.inwardTable));
              this.displayInwardTable = true;
            }
          }, errorResp => {
            this.showloader = false;
            this.errormsg = errorResp['message']
          });
          break;
      }
    }
    else {
      alert('Internet Connection is Not Available.');
    }
  }

  /******* rowDoubleClickSubAssembly(subassemblyrow)method to get model and item under sub assembly No ends here ********/

  /* pushInwardList(inwardrow) this method push selected row to sub-dynamic table starts here */
  pushInwardList(inwardrow) {

    this.totalQtyIn = 0;
    let rowAddition = 0;
    switch (this.commonservices.sendName) {
      case 'reRepairsInward':
        for (let i = 0; i < inwardrow.length; i++) {
          this.subdynamictableRerepairInward['tabledata'].push(inwardrow[i]);
        }
        this.subdynamictableRerepairInward = JSON.parse(JSON.stringify(this.subdynamictableRerepairInward));

        for (let i = 0; i < this.subdynamictableRerepairInward['tabledata'].length; i++) {
          rowAddition = rowAddition + parseInt(this.subdynamictableRerepairInward['tabledata'][i]['qtyin']);
          this.totalQtyIn = rowAddition;
        }
        break;

      case 'reRepairsOutward':
        for (let i = 0; i < this.subdynamictableRerepairOutward['tableheaders'].length; i++) {
          if (this.subdynamictableRerepairOutward['tableheaders'][i]['key'] == 'modelname') {
            this.subdynamictableRerepairOutward['tableheaders'][i]['visiblecolm'] = true;
          }
        }
        for (let i = 0; i < inwardrow.length; i++) {
          inwardrow[i]['partno'] = inwardrow[i]['itemno'];
          this.subdynamictableRerepairOutward['tabledata'].push(inwardrow[i]);
        }
        this.subdynamictableRerepairOutward = JSON.parse(JSON.stringify(this.subdynamictableRerepairOutward));

        for (let i = 0; i < this.subdynamictableRerepairOutward['tabledata'].length; i++) {
          rowAddition = rowAddition + parseInt(this.subdynamictableRerepairOutward['tabledata'][i]['qty']);
          this.totalQtyIn = rowAddition;
        }
        // console.log("SubDynamicTable" + JSON.stringify(this.subdynamictableRerepairOutward));
        break;
    }
    this.displayInwardTable = false;
    this.showPOSubAssbTableModal.hide();
    this.showPOSubAssbTablePopup = false;
  }
  /* pushInwardList(inwardrow) this method push selected row to sub-dynamic table ends here */

  /* Save table data */
  saveReRepairs(event) {
    if (navigator.onLine) {
      this.errormsg = '';
      this.showloader = true;
      this.operation = 'save';
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + 'TransactionProcess/SaveRepairsInwardOutward';//   SaveDCInwardItemData
      let tempArrayToSend = []; let obj = {};
      if (this.isEditable) { this.repInwardNo; this.repInwDetSrNo; }//repairDetailSrNumber
      else { this.repInwardNo = this.repInwDetSrNo = this.repOutwardNo = 0; }
      let str = "";
      switch (this.commonservices.sendName) {
        case 'reRepairsInward':
          if (this.subdynamictableRerepairInward['tabledata'].length == 0) {
            this.displayMessagePopup("Please select atleast one item", "Ok", true, "", false);
            this.showloader = false;
            return;
          }
          else {
            for (let i = 0; i < this.subdynamictableRerepairInward['tabledata'].length; i++) {
              let tableObj = {};
              tableObj['repinwdetsrno'] = this.subdynamictableRerepairInward['tabledata'][i]['repinwdetsrno'] ? this.subdynamictableRerepairInward['tabledata'][i]['repinwdetsrno'] : 0;
              tableObj['repinwsrno'] = this.repInwardNo;
              tableObj['qtyin'] = parseInt(this.subdynamictableRerepairInward['tabledata'][i]['qtyin']);
              tableObj['itemno'] = this.subdynamictableRerepairInward['tabledata'][i]['itemno'];
              tableObj['remarks'] = this.subdynamictableRerepairInward['tabledata'][i]['remarks'];
              tableObj['esn'] = this.subdynamictableRerepairInward['tabledata'][i]['esn'];
              tableObj['custname'] = this.subdynamictableRerepairInward['tabledata'][i]['custname'];
              tableObj['engtype'] = this.subdynamictableRerepairInward['tabledata'][i]['engtype'];
              tempArrayToSend.push(tableObj);
            }
          }

          if (this.isEditable) { obj['repinwsrno'] = this.repInwardNo; }
          else { obj['repinwsrno'] = 0; }
          obj['grrdate'] = this.datepipe.transform(this.grrdate, 'yyyy-MM-dd,hh:mm:ss');
          obj['challandate'] = this.datepipe.transform(this.challanDate, 'yyyy-MM-dd,hh:mm:ss');
          obj['challanno'] = this.challanNo;
          if (this.reRepairRemarks != "null" && this.reRepairRemarks != '' && this.reRepairRemarks != null) {
            str = this.reRepairRemarks.replace(/\n/g, " ");
          }
          else {
            str = "";
          }
          obj['remark'] = str;
          obj['userid'] = this.userId;
          break;

        case 'reRepairsOutward':
          if (this.subdynamictableRerepairOutward['tabledata'].length == 0) {
            this.displayMessagePopup("Please select atleast one item", "Ok", true, "", false);
            this.showloader = false;
            return;
          }
          else {
            this.operation = 'save';
            for (let i = 0; i < this.subdynamictableRerepairOutward['tabledata'].length; i++) {
              let tableObj = {};
              tableObj['repoutdetsrno'] = this.subdynamictableRerepairOutward['tabledata'][i]['repoutdetsrno'] ? this.subdynamictableRerepairOutward['tabledata'][i]['repoutdetsrno'] : 0;
              tableObj['repoutsrno'] = this.repOutwardNo;
              tableObj['qty'] = parseInt(this.subdynamictableRerepairOutward['tabledata'][i]['qty']);
              tableObj['prevqty'] = this.subdynamictableRerepairOutward['tabledata'][i]['prevqty'] ? this.subdynamictableRerepairOutward['tabledata'][i]['prevqty'] : 0;
              tableObj['itemno'] = this.subdynamictableRerepairOutward['tabledata'][i]['srno'];
              // tableObj['itemno'] = parseInt(this.subdynamictableRerepairOutward['tabledata'][i]['itemno']);
              tableObj['remarks'] = this.subdynamictableRerepairOutward['tabledata'][i]['remarks'];
              tableObj['repinwsrno'] = this.subdynamictableRerepairOutward['tabledata'][i]['repinwsrno'];
              tableObj['repinwdetsrno'] = this.subdynamictableRerepairOutward['tabledata'][i]['repinwdetsrno'];
              tempArrayToSend.push(tableObj);
            }
          }

          if (this.isEditable) { obj['repoutsrno'] = this.repOutwardNo; }
          else { obj['repoutsrno'] = 0; }
          obj['grrdate'] = this.datepipe.transform(this.grrdate, 'yyyy-MM-dd,hh:mm:ss');
          if (this.reRepairRemarks != "null" && this.reRepairRemarks != '' && this.reRepairRemarks != null) {
            str = this.reRepairRemarks.replace(/\n/g, " ");
          }
          else {
            str = "";
          }
          obj['remark'] = str;
          obj['userid'] = this.userId;
          obj['customerid'] = parseInt(this.customerName);
          break;
      }
      let body = {
        'savefor': this.saveFor,
        'masterdetails': obj,
        'datadetails': tempArrayToSend
      };
      this.commonservices.postMethod(apiUrl, body).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == 0) {

          if (successResp['Data']) {
            switch (this.commonservices.sendName) {
              case 'reRepairsInward':
                this.subdynamictableRerepairInward['tableconfig']['enablefirstbtn'] = false;
                this.subdynamictableRerepairInward['tableconfig']['controlconfig']['displaycontrol'] = false;
                this.subdynamictableRerepairInward['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
                if (successResp['Data'][0]['datajson']) {

                  // if (successResp['Data'][0]['datajson'][0]['repinwsrno']) this.repInwardNo = successResp['Data'][0]['datajson'][0]['repinwsrno'];
                  // if (successResp['Data'][0]['datajson'][0]['remark']) this.reRepairRemarks = successResp['Data'][0]['datajson'][0]['remark'];
                  // if (successResp['Data'][0]['datajson'][0]['grrdate']) this.grrdate = successResp['Data'][0]['datajson'][0]['grrdate'];
                  // if (successResp['Data'][0]['datajson'][0]['challandate']) this.challanDate = successResp['Data'][0]['datajson'][0]['challandate'];
                  // if (successResp['Data'][0]['datajson'][0]['challanno']) this.challanNo = successResp['Data'][0]['datajson'][0]['challanno'];
                  this.repInwardNo = successResp['Data'][0]['datajson'][0]['repinwsrno'] ? successResp['Data'][0]['datajson'][0]['repinwsrno'] : '';
                  this.reRepairRemarks = successResp['Data'][0]['datajson'][0]['remark'] ? successResp['Data'][0]['datajson'][0]['remark'] : '';
                  this.grrdate = successResp['Data'][0]['datajson'][0]['grrdate'] ? successResp['Data'][0]['datajson'][0]['grrdate'] : '';
                  this.challanDate = successResp['Data'][0]['datajson'][0]['challandate'] ? successResp['Data'][0]['datajson'][0]['challandate'] : '';
                  this.challanNo = successResp['Data'][0]['datajson'][0]['challanno'] ? successResp['Data'][0]['datajson'][0]['challanno'] : '';

                  let rowObj = {};
                  rowObj['repinwsrno'] = successResp['Data'][0]['datajson'][0]['repinwsrno'];
                  rowObj['dcissuedate'] = this.datepipe.transform(successResp['Data'][0]['datajson'][0]['challandate'], 'dd/MMM/yyyy');


                  if (this.isEditable == false) {
                    this.reRepairInwardTable['tabledata'].unshift(rowObj);
                    this.reRepairInwardTable = JSON.parse(JSON.stringify(this.reRepairInwardTable));
                    let rowindex = 0;
                    this.highlightedObj.id = 'trtable1' + rowindex;
                    this.highlightedObj.indx = rowindex;
                    document.getElementById(this.highlightedObj.id).style.backgroundColor = 'yellow';
                  }
                  if (successResp['Data']) {
                    if (successResp['Data'][0]['datajson']) {
                      if (successResp['Data'][0]['datajson'][0]['detaildata']) {
                        let str = '[' + successResp['Data'][0]['datajson'][0]['detaildata'][0] + ']';
                        let subtable = JSON.parse(str);
                        this.subdynamictableRerepairInward['tabledata'] = subtable;
                      }
                    }
                  }
                  this.subdynamictableRerepairInward = JSON.parse(JSON.stringify(this.subdynamictableRerepairInward));
                }
                break;

              case 'reRepairsOutward':
                this.subdynamictableRerepairOutward['tableconfig']['enablefirstbtn'] = false;
                this.subdynamictableRerepairOutward['tableconfig']['controlconfig']['displaycontrol'] = false;
                this.subdynamictableRerepairOutward['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
                if (successResp['Data'][0]['datajson']) {
                  // if (successResp['Data'][0]['datajson'][0]['repoutsrno']) this.repOutwardNo = successResp['Data'][0]['datajson'][0]['repoutsrno'];
                  // if (successResp['Data'][0]['datajson'][0]['remark']) this.reRepairRemarks = successResp['Data'][0]['datajson'][0]['remark'];
                  // if (successResp['Data'][0]['datajson'][0]['grrdate']) this.grrdate = successResp['Data'][0]['datajson'][0]['grrdate'];
                  // if (successResp['Data'][0]['datajson'][0]['customerid']) this.customerName = successResp['Data'][0]['datajson'][0]['customerid'];
                  this.repOutwardNo = successResp['Data'][0]['datajson'][0]['repoutsrno'] ? successResp['Data'][0]['datajson'][0]['repoutsrno'] : '';
                  this.reRepairRemarks = successResp['Data'][0]['datajson'][0]['remark'] ? successResp['Data'][0]['datajson'][0]['remark'] : '';
                  this.grrdate = successResp['Data'][0]['datajson'][0]['grrdate'] ? this.datepipe.transform(successResp['Data'][0]['datajson'][0]['grrdate'], 'dd/MMM/yyyy') : '';
                  this.customerName = successResp['Data'][0]['datajson'][0]['customerid'] ? successResp['Data'][0]['datajson'][0]['customerid'] : '';

                  let rowObj = {};
                  rowObj['repoutsrno'] = successResp['Data'][0]['datajson'][0]['repoutsrno'];
                  rowObj['outwarddate'] = this.datepipe.transform(successResp['Data'][0]['datajson'][0]['grrdate'], 'dd/MMM/yyyy');

                  if (this.isEditable == false) {
                    this.reRepairOutwardTable['tabledata'].unshift(rowObj);
                    this.reRepairOutwardTable = JSON.parse(JSON.stringify(this.reRepairOutwardTable));
                    let rowindex = 0;
                    this.highlightedObj.id = 'trtable1' + rowindex;
                    this.highlightedObj.indx = rowindex;
                    document.getElementById(this.highlightedObj.id).style.backgroundColor = 'yellow';
                  }
                  if (successResp['Data']) {
                    if (successResp['Data'][0]['datajson']) {
                      if (successResp['Data'][0]['datajson'][0]['detaildata']) {
                        let str = '[' + successResp['Data'][0]['datajson'][0]['detaildata'][0] + ']';
                        let subtable = JSON.parse(str);
                        this.subdynamictableRerepairOutward['tabledata'] = subtable;
                      }
                    }
                  }
                  this.subdynamictableRerepairOutward = JSON.parse(JSON.stringify(this.subdynamictableRerepairOutward));
                }
                break;
            }
          }
          this.visibleSaveBtn = false;
          this.visibleCancelBtn = true;
          this.visibleNewBtn = true;
          this.displayMessagePopup(successResp['errorDescription'], 'Ok', true, '', false);
        }
        else {
          this.errormsg = successResp['errorDescription'];
        }
      },
        errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message'];
        });
    }
    else {
      alert('Internet Connection is Not Available.');
    }
  }

  /* delete parts from sub table */
  deleteSubtableEntry(event, whichmenu) {
    if (navigator.onLine) {
      this.operation = 'delete';
      switch (whichmenu) {
        case 'reRepairsInwardSubTable':
          this.deleteFor = "repaireinwarddetail";
          this.sparedeatils = [];
          if (this.isEditable == false || event.row.repinwdetsrno == 0 || event.row.repinwdetsrno == null || event.row.repinwdetsrno == undefined) {
            this.deleteDetailsOpr = 'New';
            this.deleteDetailById = event['rowindex'];
          }
          else if (this.isEditable == true) {
            this.deleteDetailsOpr = 'Edit';
            this.deleteDetailById = event['row']['repinwdetsrno'];
            this.deleteDetailByIDLocally = event['rowindex'];
          }
          break;

        case 'reRepairsOutwardSubTable':
          this.sparedeatils = [];
          if (this.isEditable == false || event.row.repoutdetsrno == 0 || event.row.repoutdetsrno == null || event.row.repoutdetsrno == undefined) {
            this.deleteDetailsOpr = 'New';
            this.deleteDetailById = event['rowindex'];
          }
          else if (this.isEditable == true) {
            this.deleteFor = 'repaireoutwarddetail';
            this.deleteDetailsOpr = 'Edit';
            this.deleteDetailById = event['row']['repoutdetsrno'];
            this.sparedeatils = [];
            let obj = {};
            obj['repoutdetsrno'] = event['row']['repoutdetsrno'];
            obj['qty'] = event['row']['qty'];
            obj['repinwdetsrno'] = event['row']['repinwdetsrno'];
            this.sparedeatils.push(obj);
            this.deleteDetailByIDLocally = event['rowindex'];
          }
          break;

        case 'reRepairsOutwardOriTable':
          this.deleteFor = 'repaireoutwardmaster';
          this.deleteDetailsOpr = 'Edit';
          this.deleteDetailById = event['row']['repoutsrno'];
          this.editReRepairOutward(event);
          this.sparedeatils = [];
          let tempArrayToSend: any[] = [];
          if (this.subdynamictableRerepairOutward['tabledata'].length > 0) {
            let obj = {};
            for (let i = 0; i < this.subdynamictableRerepairOutward['tabledata'].length; i++) {
              obj = {};
              obj["repinwdetsrno"] = this.subdynamictableRerepairOutward['tabledata'][i]["repinwdetsrno"];
              obj["qty"] = this.subdynamictableRerepairOutward['tabledata'][i]["qty"];
              obj["repoutdetsrno"] = this.subdynamictableRerepairOutward['tabledata'][i]["repoutdetsrno"];
              tempArrayToSend.push(obj);
            }
            this.sparedeatils = tempArrayToSend;
          }
          // if (event['row']['sparedetails'][0] != null) {
          //   let str = "[" + event['row']['sparedetails'][0] + "]"
          //   let tempArray = JSON.parse(str);
          //   let tempArrayToSend: any[] = [];
          //   if (tempArray) {
          //     let obj = {};

          //     for (let i = 0; i < tempArray.length; i++) {
          //       obj = {};
          //       obj["repinwdetsrno"] = tempArray[i]["repinwdetsrno"];
          //       obj["qty"] = tempArray[i]["qty"];
          //       obj["repoutdetsrno"] = tempArray[i]["repoutdetsrno"];
          //       tempArrayToSend.push(obj);
          //     }
          //   }
          //   this.sparedeatils = tempArrayToSend;
          // }
          else {
            this.sparedeatils = [];
          }

          // itemno
          // qty
          // repinwdetsrno
          // repinwsrno
          // repoutdetsrno
          // repoutsrno
          this.deleteDetailByIDLocally = event['rowindex'];
          break;
      }
      this.formNameForsubDeleteOpr = whichmenu;
      this.opeartionStageForDelete = "delete";
      this.displayMessagePopup('Are you sure want to delete this record?', 'Yes', true, 'No', true);
    }
    else {
      this.displayMessagePopup('Internet Connection is Not Available.', 'Ok', true, false, '');
    }
  }

  /* confirm delete function */
  confirmDeleteInwardSubTable() {
    if (navigator.onLine) {
      this.errormsg = '';

      this.showloader = true;
      this.loaderTimeOut();
      if (this.deleteDetailsOpr == 'New') {
        this.deleteDetailsOpr = '';
        this.showloader = false;
        this.subdynamictableRerepairInward['tabledata'].splice(this.deleteDetailById, 1);
        this.subdynamictableRerepairInward = JSON.parse(JSON.stringify(this.subdynamictableRerepairInward));
      }
      else if (this.deleteDetailsOpr == 'Edit') {
        this.deleteDetailsOpr = '';
        let apiUrl = this.configurationsetting.getBaseUrl() + 'TransactionProcess/DeleteTransactionRecords';
        let obj = {};
        obj['deletefor'] = this.deleteFor;
        obj['deletetransno'] = this.deleteDetailById; //repinwdetsrno or manualnotesrno
        obj['sparedetails'] = this.sparedeatils;

        this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {
            this.showconfirmpopup = false;
            this.operation = 'deleted';
            // switch (this.deleteFor) {
            //   case 'repaireinwarddetail':
            this.subdynamictableRerepairInward['tabledata'].splice(this.deleteDetailByIDLocally, 1);
            this.subdynamictableRerepairInward = JSON.parse(JSON.stringify(this.subdynamictableRerepairInward));
            //     break;

            //   case 'manualnotemaster':
            //     this.reRepairsInwardForm.reset();
            //     this.subdynamictableRerepairInward['tabledata'] = [];
            //     this.subdynamictableRerepairInward = JSON.parse(JSON.stringify(this.subdynamictableRerepairInward));
            //     this.reRepairInwardTable['tabledata'].splice(this.deleteDetailByIDLocally, 1);
            //     this.reRepairInwardTable = JSON.parse(JSON.stringify(this.reRepairInwardTable));
            //     break;
            // }
            this.displayMessagePopup(successResp['errorDescription'], 'Ok', true, '', false);
          }
          else {
            this.errormsg = successResp['errorDescription']
          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
      }
      /**********calculate qty total here while single click starts here***************/
      this.totalQtyIn = 0;
      let rowAddition = 0;
      if (this.subdynamictableRerepairInward['tabledata'] == null) {
        this.subdynamictableRerepairInward['tabledata'] = [];
      }
      else {
        for (let i = 0; i < this.subdynamictableRerepairInward['tabledata'].length; i++) {
          rowAddition = rowAddition + parseInt(this.subdynamictableRerepairInward['tabledata'][i]['qtyin']);
          this.totalQtyIn = rowAddition;
        }
      }
      /**********calculate qty total here while single click ends here***************/
    }
    else {
      this.showloader = false;
      alert('Internet Connection is Not Available.');
    }
  }

  confirmDeleteOutwardSubTable() {
    if (navigator.onLine) {
      this.errormsg = '';

      this.showloader = true;
      this.loaderTimeOut();
      if (this.deleteDetailsOpr == 'New') {
        this.deleteDetailsOpr = '';
        this.showloader = false;
        this.subdynamictableRerepairOutward['tabledata'].splice(this.deleteDetailById, 1);
        this.subdynamictableRerepairOutward = JSON.parse(JSON.stringify(this.subdynamictableRerepairOutward));
      }
      else if (this.deleteDetailsOpr == 'Edit') {
        this.deleteDetailsOpr = '';
        let apiUrl = this.configurationsetting.getBaseUrl() + 'TransactionProcess/DeleteTransactionRecords';
        let obj = {};
        obj['deletefor'] = this.deleteFor;
        obj['deletetransno'] = this.deleteDetailById; //repinwdetsrno or manualnotesrno
        obj['sparedetails'] = this.sparedeatils;

        this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {
            this.showconfirmpopup = false;
            this.operation = 'deleted';
            switch (this.deleteFor) {
              case 'repaireoutwarddetail':
                this.subdynamictableRerepairOutward['tabledata'].splice(this.deleteDetailByIDLocally, 1);
                this.subdynamictableRerepairOutward = JSON.parse(JSON.stringify(this.subdynamictableRerepairOutward));
                break;

              case 'repaireoutwardmaster':
                this.reRepairsOutwardForm.reset();
                this.subdynamictableRerepairOutward['tabledata'] = [];
                this.subdynamictableRerepairOutward = JSON.parse(JSON.stringify(this.subdynamictableRerepairOutward));
                this.reRepairOutwardTable['tabledata'].splice(this.deleteDetailByIDLocally, 1);
                this.reRepairOutwardTable = JSON.parse(JSON.stringify(this.reRepairOutwardTable));
                break;
            }
            this.displayMessagePopup(successResp['errorDescription'], 'Ok', true, '', false);
          }
          else {
            this.errormsg = successResp['errorDescription']
          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
      }
      /**********calculate qty total here while single click starts here***************/
      this.totalQtyIn = 0;
      let rowAddition = 0;
      if (this.subdynamictableRerepairOutward['tabledata'] == null) {
        this.subdynamictableRerepairOutward['tabledata'] = [];
      }
      else {
        for (let i = 0; i < this.subdynamictableRerepairOutward['tabledata'].length; i++) {
          rowAddition = rowAddition + parseInt(this.subdynamictableRerepairOutward['tabledata'][i]['qty']);
          this.totalQtyIn = rowAddition;
        }
      }
      /**********calculate qty total here while single click ends here***************/
    }
    else {
      this.showloader = false;
      alert('Internet Connection is Not Available.');
    }
  }

  confirmPopup(event) {
    // if (this.operationStage == 'highlightSave') {

    this.showconfirmpopup = false;
    if (this.operation == 'save') {
      this.operation = '';

    }
    else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
      this.getRERepairInwardTableNForm();
    }
    else if (this.opeartionStageForDelete == "delete") {
      this.opeartionStageForDelete = "";
      if (this.formNameForsubDeleteOpr == "reRepairsInwardSubTable") {
        this.confirmDeleteInwardSubTable();
      }
      else if (this.formNameForsubDeleteOpr == "reRepairsOutwardSubTable" || this.formNameForsubDeleteOpr == "reRepairsOutwardOriTable") {
        this.confirmDeleteOutwardSubTable();
      }
      else if (this.formNameForsubDeleteOpr == "subTbleSPReceipt") {
        // this.confirmSubSPIssue();
      }
      else if (this.formNameForsubDeleteOpr == "subTbleSPReceipt") {
        // this.confirmSubSPReceipt()/
      }
      this.formNameForsubDeleteOpr = "";
    }
    else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
    }
    else {
      if (document.getElementById(this.highlightedObj.id)) {
        document.getElementById(this.highlightedObj.id).style.backgroundColor = '';
      }
    }
  }

  cancelPopup(whichform) {
    if (this.operation == 'delete') {
      this.operation = '';
      this.showconfirmpopup = false;
    }
    if (whichform == 'SubAssemblyTable') {
      this.showPOSubAssbTableModal.hide();
      this.showPOSubAssbTablePopup = false;
    }
  }

  cancelReRepairSubTable() {
    switch (this.commonservices.sendName) {
      case 'reRepairsInward':
        this.getRERepairInwardTableNForm();
        // this.reRepairsInwardForm.reset();
        // this.disableRepairRemarks = false;
        // this.disableChallanNo = false;
        // this.disableChallanDate = false;
        // this.disableGrrDate = true;
        this.totalQtyIn = 0;
        this.subdynamictableRerepairInward['tabledata'] = [];
        this.subdynamictableRerepairInward = JSON.parse(JSON.stringify(this.subdynamictableRerepairInward));
        this.inwardsubtableconfig = this.subAssbDynTable["tableconfig"];
        this.inwardsubtabledata = this.subAssbDynTable["tabledata"];
        this.inwardsubtableheaders = this.subAssbDynTable["tableheaders"];
        this.displayRerepairInwardSubTable = false;
        break;
      case 'reRepairsOutward':
        this.getRERepairInwardTableNForm();
        // this.reRepairsOutwardForm.reset();
        // this.disableRepairRemarks = false;
        // this.disableChallanNo = false;
        // this.disableChallanDate = false;
        // this.disableGrrDate = true;
        this.totalQtyIn = 0;
        this.subdynamictableRerepairOutward['tabledata'] = [];
        this.subdynamictableRerepairOutward = JSON.parse(JSON.stringify(this.subdynamictableRerepairOutward));
        this.outwardsubtableconfig = this.subdynamictableRerepairOutward["tableconfig"];
        this.outwardsubtabledata = this.subdynamictableRerepairOutward["tabledata"];
        this.outwardsubtableheaders = this.subdynamictableRerepairOutward["tableheaders"];
        this.displayRerepairOutwardSubTable = false;
        break;
    }

    this.visibleNewBtn = true;
    this.visibleSaveBtn = false;
    this.visibleCancelBtn = false;
  }

  /* if http not gave the response after spend 30 seconds the we will show the error. */
  loaderTimeOut() {
    setTimeout(() => {
      if (this.showloader) {
        this.showloader = false;
        this.errormsg = 'Error while login your account.';
      }
    }, 50000)
  }

  //displayMessagePopup() is used to call and maintain confirmation popup modal
  displayMessagePopup(popupmessage, submittext, displaysubmit, canceltext, displaycancel) {
    this.confirmationpopupmodelobj.inputmsg = popupmessage;
    this.confirmationpopupmodelobj.submitbtntext = submittext
    this.confirmationpopupmodelobj.displaysubmit = displaysubmit;
    this.confirmationpopupmodelobj.cancelbtntext = canceltext;
    this.confirmationpopupmodelobj.displaycancel = displaycancel;
    this.showconfirmpopup = true;
  }
}
