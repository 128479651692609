import { Injectable, NgModule } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs'
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigurationSetting } from '../appconfig';
import { Subject } from 'rxjs';

@Injectable()
export class ObjServiceService {
  private subject = new Subject<any>();
  public sendObj:any = "";
 
  /**testing*/
  private msgSource = new BehaviorSubject('default message');
  currentMsg = this.msgSource.asObservable();
  /**testing*/

  constructor(private configurationsetting: ConfigurationSetting, private http: HttpClient, private exportAsService: ExportAsService) { }


  sendObject(message: object) {
    // console.log("before publishing next method");
    this.subject.next({ message });
    // console.log("after publishing next method");
  }

  clearObject() {
    this.subject.next();
  }
  
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  /*********ends here**********/

  /**testing*/
  changeMsg(message: any) {
    this.msgSource.next(message);
  }
  /**testing*/

}
