import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../appconfig'
import { HttpHeaders, HttpParams } from '@angular/common/http'
import { ConfirmationPopupModel } from '../../models/models';
import { CommonServicesService } from '../../services/common-services.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ToastService } from '../../services/toast.service';
declare var $;
@Component({
  selector: 'app-model-master',
  templateUrl: './model-master.component.html',
  styleUrls: ['./model-master.component.scss']
})
export class ModelMasterComponent implements OnInit {
  submitButtonEventSubject: Subject<any> = new Subject<any>();

  masterData: any[] = [];
  dynamicformdata: any;
  dynamictable: any = {};
  showloader: boolean = false;
  showconfirmpopup: boolean = false;
  displayTable: boolean = true;
  errormsg: string = "";
  headers = new HttpHeaders();
  confirmationpopupmodelobj: ConfirmationPopupModel = new ConfirmationPopupModel();
  showAddModelPopup: boolean = false;
  isEditable: boolean = false;
  modelNoForSave;
  operation: string = "";
  rowsperpage = 100;
  userRole: string = "";
  deleteById;
  tblevent = {}
  btnRow: boolean = false;
  deleteMsg = "";
  editDisableBtn: boolean = false;
  saveDisableBtn: boolean = false;
  saveBtn;
  editBtn: boolean = false;
  deleteBtn: boolean = false;
  cancelBtn: boolean = false;
  newForm: boolean = false;
  isRowClicked: boolean = false;
  emptySearchField: boolean = true;
  disSaveBtn:boolean = false;
  modelUniqueNo: number = 0;
  currentSelectedRow: number = 0;


  opeartionStageForDelete: string = "";
  tableheaders = [
    {
      "key": "modelname",
      "display": "Model Name",
      "sorting": true,
      "enablehide": true,
      "datatype": "string",
      "thstyle": { "text-align": "center ", "width": "40%" }
    },
    {
      "key": "remark",
      "display": "Remark",
      "sorting": true,
      "enablehide": true,
      "datatype": "string",
      "thstyle": { "text-align": "center ", "width": "20%" }
    }
  ];
  tabledata = [];
  tableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": true,
      "delete": true,
      "print": false
    },
    "partno": { "text-align": "left" },
    "Qty": { "text-align": "Right" },
    "CurrentBalance": { "text-align": "Right" },
    "WIP": { "text-align": "Right" },
    "controlconfig": { "displaycontrol": false },
    // "checkDeletedFlag": true,
    // "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  };
  highlightedObj = {
    "id": "",
    "indx": -1
  }
  showDynamicForm: boolean = true;
  addItemButton;

  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  constructor(public commonservices: CommonServicesService, private configurationsetting: ConfigurationSetting, private toastService: ToastService) { }

  ngOnInit() {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    this.getMasterTableNForm();
  }

  onSubmitBtnClick() {
    if (this.dynamicformdata['controls']['modelname']['value'] == "") {
      this.toastService.showToastMsg('warning', 'Warning', "Please fill required fields.");
      return;
    }
    // this.submitButtonEventSubject.emit('savebtnclick');
    this.submitButtonEventSubject.next('save');

    // this.editDisableBtn = false;
    // this.deleteBtn = false;
  }

  addItemBtn() { }
  /*
  getMasterTableNForm() method to get forms and generic table starts here
  */
  getMasterTableNForm() {
    this.errormsg = "";
    this.showloader = true;
    this.loaderTimeOut();
    this.commonservices.getGenericTableNForm("ModelMasterForm", "ModelMasterTable", this.configurationsetting.getBaseUrl()).subscribe(successResp => {
      this.showloader = false;
      if (successResp['errorCode'] == "000000") {
        this.dynamicformdata = successResp['Data'][0]['@formjson'];
        this.dynamicformdata['controls']['modelname']['pattern'] = "[a-zA-Z0-9-/ ]*";
        this.dynamicformdata['controls']['modelname']['patternmsg'] = "Allowed characters are a-z A-Z 0-9 - / Space";
        this.dynamicformdata['controls']['remark']['minlength'] = 0;
        this.setBootstrapClasses();
        this.dynamictable = successResp['Data'][0]['@tbljson'];
        this.dynamictable['tabledata'] = successResp['Data'][0]['@tbldata'];
        this.dynamictable['tableheaders'] = successResp['Data'][0]['@tbljson']['tableheaders'];
        this.dynamictable['tableconfig'] = successResp['Data'][0]['@tbljson']['tableconfig'];

        this.disabledFields()
        this.saveDisableBtn = false;
        this.cancelBtn = false;
        this.editDisableBtn = false;
        this.deleteBtn = false;
        let rowData = successResp['Data'][0]['@tbldata'];
        for (let i = 0; i < rowData.length; i++) {
          if (rowData[i]['ModelNo'] == this.modelUniqueNo) {
            // console.log("Index", this.tabledata[i], i);
            this.rowSingleClick({
              "row": rowData[i],
              "rowindex": i
            });

            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              // document.getElementById('trtable1' + i).style.backgroundColor = "#7de4f0";
              // this.dynamicformdata['controls']['modelname']['value'] = rowData[i]['modelname']
              // this.dynamicformdata['controls']['remark']['value'] = rowData[i]['remark']
            }, 900);
            break;
          }
        }

        /*********changes starts here********/
        if (this.userRole == "Admin") {
          this.dynamictable['tableconfig']['actioncolmconfig']['delete'] = true;
        } else {
          this.dynamictable['tableconfig']['actioncolmconfig']['delete'] = false;
        }
        // this.tableconfig =  this.dynamictable["tableconfig"];
        this.tabledata = this.dynamictable["tabledata"];
        // this.tableheaders =  this.dynamictable["tableheaders"];
        console.log(this.tableheaders);
        console.log(this.tableheaders[0]["thstyle"]['width'] = "60%");

        /********chnages ends here*********/
        this.showDynamicForm = true;
        this.displayTable = true;
      }
      else if (successResp['errorCode'] == '1') {
        // this.errormsg = successResp["errorDescription"];
      }
    },
      err => {
        this.showloader = false;
        this.errormsg = err['message'];
      });

  }
  /*  getMasterTableNForm() method to get forms and generic table ends here
  */

  editForm(event) {
    this.editBtn = false
    this.editModelMaster(event);
    this.editDisableBtn = true;
    this.cancelBtn = true;
    this.saveDisableBtn = true;
    this.deleteBtn = true;
    this.newForm = true
    this.isRowClicked = false;
    this.dynamicformdata['controls']['remark']['minlength'] = 0;
  }

  deleteModelMasterData(event) {
    this.deleteModelMaster(event);
  }

  deleteModelMaster(event) {
    if (navigator.onLine) {
      this.deleteById = event['row']['ModelNo'];
      this.opeartionStageForDelete = "delete";
      this.newForm = false;
      this.isRowClicked = false;
      this.displayMessagePopup("Are you sure want to delete this record?", "Yes", true, "No", true);
    }
    else {
      this.displayMessagePopup("Internet Connection is Not Available.", "Ok", true, false, "");
    }
  }
  confirmDeleteModelMaster() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/DeleteMasterRecords";
      let obj = {};
      obj['deletefor'] = "Model"
      obj['recordvalue'] = this.deleteById;
      let param = { "criteria": obj }
      this.commonservices.postMethod(apiUrl, param).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.showconfirmpopup = false;
          this.opeartionStageForDelete = "deleted";
          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
        } else if (successResp['errorCode'] == 111000) {
          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);

        }else{
          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      this.showloader = false;

      alert("Internet Connection is Not Available.");

    }
  }

  cancelConfirmationPopup() {
    this.showconfirmpopup = false;
    this.opeartionStageForDelete = "";
  }

  confirmPopup(event) {
    if (this.opeartionStageForDelete == "delete") {
      this.opeartionStageForDelete = "";
      this.confirmDeleteModelMaster();
    }
    else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
      this.getMasterTableNForm();

    }
    this.showconfirmpopup = false;
  }


  /*
  submitModelMaster()
    In success error code is '0'
    1.send "modelno"=0 if You want to save new assembly
    2.send "modelno" with coming value if you want to edit it
  */
  submitModelMaster(event) {
    if (navigator.onLine) {

      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl;
      let obj = {};
      obj['modelname'] = event['modelname'];
      obj['remark'] = event['remark'];
      apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveModelMaster";
      /* save starts here*/
      if (this.isEditable) {
        obj['modelno'] = this.modelNoForSave;
        this.isEditable = false;
      } else {
        //send modelno=0 when it is a new entry i.e while saving
        obj['modelno'] = 0;
      }
      this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.modelUniqueNo = successResp['Data'][0]['modelno']
          this.getMasterTableNForm();//change it
          // this.updateData(this.tblevent)
          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
          // if (this.isEditable) {
          //   this.rowSingleClick(this.tblevent, false)
          //   this.updateModelData(this.tblevent)
          // }
          this.newForm = false;
          this.editBtn = true;
          this.btnRow = true;
          this.showAddModelPopup = false;
          this.operation = "";
          this.isRowClicked = false;
        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });
      /* save ends here*/
      //save service

    }
    else {
      alert("Internet Connection is Not Available.");

    }
  }


  rowSingleClick(event, flag = true) {
    // console.log("this.isRowClicked", this.isRowClicked);
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 3000);
      this.isRowClicked = true;
      return;
    }
    this.isRowClicked = true;
    if (this.dynamicformdata['controls']['modelname']['disabled'] == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }

    setTimeout(() => {
      this.currentSelectedRow = event.rowindex;
      this.commonservices.identifySelectedRow(this.tabledata, event.rowindex, this.currentSelectedRow, "table1");
    }, 200);

    this.tblevent = event;
    this.editDisableBtn = false;
    this.newForm = false
    this.editBtn = true;
    this.btnRow = true;


    if (navigator.onLine) {


      this.errormsg = "";
      this.showloader = true;

      this.loaderTimeOut();
      this.updateModelData(this.tblevent)

    }
    else {

      alert("Internet Connection is Not Available.");

    }

  }

  updateModelData(event) {
    let apiUrl = this.configurationsetting.getBaseUrl() + "Master/EditModelMaster";
    let params = { "modelnumber": event['row']['ModelNo'] };

    this.modelNoForSave = event['row']['ModelNo']
    let row = event['row']
    let userdeleted = event['username']
    let deletedDate = event['deldate']
    if (row["isDeleted"] === "N") {
      // this.btnRow = false;
      event['row']['ModelNo']['value'] = "";

      // console.log(true);
      // this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
    } else {
      // this.btnRow = true;
      // this.deleteMsg = ""
    }

    this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
      this.showloader = false;
      if (successResp['errorCode'] == "000000") {
        this.dynamicformdata = {};
        this.dynamicformdata = successResp['Data'];
        this.dynamicformdata['controls']['modelname']['pattern'] = "[a-zA-Z0-9-/ ]*";
        this.dynamicformdata['controls']['modelname']['patternmsg'] = "Allowed characters are a-z A-Z 0-9 - / Space";
        this.dynamicformdata['controls']['remark']['minlength'] = 0;

        this.setBootstrapClasses();
        this.isEditable = true;
        this.operation = "Edit";
        this.showAddModelPopup = true;
        this.disabledFields();
        this.isRowClicked = false;
      } else {
        this.isRowClicked = false;
        this.errormsg = successResp['errorDescription']
      }
    }, errorResp => {
      this.showloader = false;
      this.isRowClicked = false;
      this.errormsg = errorResp['message']
    });

  }

  /**editModelMaster(event) method gives populated form for edit starts here*/

  editModelMaster(event) {
    if (navigator.onLine) {

      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      this.isRowClicked = false;
      this.dynamicformdata['controls']['remark']['minlength'] = 0;
      this.updateData(this.tblevent)

    }
    else {

      alert("Internet Connection is Not Available.");

    }
  }
  /**editModelMaster(event)ends here*/

  updateData(event) {
    let apiUrl = this.configurationsetting.getBaseUrl() + "Master/EditModelMaster";
    let params = { "modelnumber": event['row']['ModelNo'] };

    this.modelNoForSave = event['row']['ModelNo']
    this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
      this.showloader = false;
      if (successResp['errorCode'] == "000000") {
        this.dynamicformdata = {};
        this.dynamicformdata = successResp['Data'];
        this.dynamicformdata['controls']['modelname']['pattern'] = "[a-zA-Z0-9-/ ]*";
        this.dynamicformdata['controls']['modelname']['patternmsg'] = "Allowed characters are a-z A-Z 0-9 - / Space";
        this.dynamicformdata['controls']['remark']['minlength'] = 0;

        this.setBootstrapClasses();
        this.isEditable = true;
        this.operation = "Edit";
        // this.addModal.show();
        this.showAddModelPopup = true;
        this.isRowClicked = false;
      } else {
        this.isRowClicked = false;
        this.errormsg = successResp['errorDescription']

      }
    }, errorResp => {
      this.showloader = false;
      this.isRowClicked = false;
      this.errormsg = errorResp['message']
    });
  }

  // confirmPopup(event) {
  //   this.showconfirmpopup = false;
  // }

  getModelForm() {
    this.operation = "NEW";
    this.modelNoForSave = 0;
    this.modelUniqueNo = 0;
    this.isEditable = false;
    this.emptySearchField = false;
    this.editBtn = false;
    this.btnRow = true
    this.saveDisableBtn = true;
    this.cancelBtn = true;
    this.deleteMsg = "";
    this.newForm = true;
    this.dynamicformdata['controls']['modelname']['value'] = "";
    this.dynamicformdata['controls']['remark']['value'] = "";
    this.dynamicformdata['controls']['modelname']['disabled'] = false;
    this.dynamicformdata['controls']['remark']['disabled'] = false;
    this.dynamicformdata['controls']['remark']['minlength'] = 0;
    const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    // this.addModal.show();
    // this.showAddModelPopup = true;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  disabledFields() {
    // if (!this.dynamicformdata['controls']) {
    this.dynamicformdata['controls']['modelname']['disabled'] = true;
    this.dynamicformdata['controls']['remark']['disabled'] = true;
    // }
  }


  /*checkUniqueModelName(event) this method will take value which is entered in event
and this method checks whether entered model name  is duplicate or not */
  checkUniqueModelName(event) {
    if (navigator.onLine) {
      if (event['controlname'] == 'modelname') {
        this.errormsg = "";
        this.showloader = true;
        this.loaderTimeOut();
        let apiUrl = this.configurationsetting.getBaseUrl() + "Master/CheckUniqueModelName";
        let params = { "ModelName": event['value']['currentTarget']['value'] };


        this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {
            this.dynamicformdata['controls']['modelname']['disabled'] = false;
            this.dynamicformdata['controls']['remark']['disabled'] = true;
            this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
            this.dynamicformdata['css']['btnconfiguration']['disableSave'] = true;
            this.disSaveBtn = true;
            this.displayMessagePopup(successResp['Data'], "Ok", true, "", false);
          } else if (successResp['errorCode'] != 0) {
            this.dynamicformdata['controls']['modelname']['disabled'] = false;
            this.dynamicformdata['controls']['remark']['disabled'] = false;
            this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
            this.dynamicformdata['css']['btnconfiguration']['disableSave'] = false;
            this.disSaveBtn = false;
            // this.errormsg = successResp['errorDescription'];
          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
      }
    }
    else {

      alert("Internet Connection is Not Available.");

    }


  }
  /*checkUniqueModelName() ends here*/

  closePopUpEvent(whichForm) {
    if (confirm("Do you want to cancel!")) {
      if (this.operation == "NEW") {
        this.dynamicformdata['controls']['modelname']['value'] = "";
        this.dynamicformdata['controls']['remark']['value'] = "";
        this.editBtn = false;
        this.btnRow = false;
      } else {
        this.editBtn = true;
        this.btnRow = true;
      }
      this.disabledFields();
      this.editDisableBtn = false
      this.saveBtn = false;
      this.saveDisableBtn = false
      this.deleteBtn = false;
      this.cancelBtn = false;
      this.newForm = false;
      this.isRowClicked = false;
    }
  }

  //displayMessagePopup() is used to call and maintain confirmation popup modal
  displayMessagePopup(popupmessage, submittext, displaysubmit, canceltext, displaycancel) {
    this.confirmationpopupmodelobj.inputmsg = popupmessage;
    this.confirmationpopupmodelobj.submitbtntext = submittext
    this.confirmationpopupmodelobj.displaysubmit = displaysubmit;
    this.confirmationpopupmodelobj.cancelbtntext = canceltext;
    this.confirmationpopupmodelobj.displaycancel = displaycancel;
    this.showconfirmpopup = true;
  }

  setBootstrapClasses() {
    let colName = 0;
    for (let i = 0; i < this.dynamicformdata['css']["mappingarray"].length; i++) {
      for (let j = 0; j < this.dynamicformdata['css']["mappingarray"][i].length; j++) {
        if (this.dynamicformdata['css']["mappingarray"][i][j]["colspan"] != undefined)
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]) * +this.dynamicformdata["mappingarray"][i][j]["colspan"];
          colName = +this.dynamicformdata['css']["gridinfo"]["gridcols"] * +this.dynamicformdata['css']["mappingarray"][i][j]["colspan"];
        else
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]);
          colName = +this.dynamicformdata['css']["gridinfo"]["gridcols"];
        this.dynamicformdata['css']["mappingarray"][i][j]["ColClassName"] = "col-xs-12 col-sm-12 col-md-" + colName + " col-lg-" + colName + " col-xl-" + colName;
      }
    }
  }

  //////if http not gave the response after spend 30 seconds the we will show the error.
  loaderTimeOut() {
    setTimeout(() => {
      if (this.showloader) {
        this.showloader = false;
        this.errormsg = "Error while login your account.";
      }
    }, 50000)
  }


}
