import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../../appconfig';
import { ApiService } from '../../../services/api.service';
import { CommonServicesService } from '../../../services/common-services.service';
import { HelperService } from '../../../services/helper.service';
import { SubmitFormService } from '../../../services/submit-form.service';
import { SystemParamsService } from '../../../services/system-params.service';
import { ToastService } from '../../../services/toast.service';
import { LEFT_SIDE_TABLE_JSON, DETAIL_TABLE_JSON, POPUP_ITEMS_TABLE_JSON } from "./spare-outward.tsconfig";
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var $;
@Component({
  selector: 'app-spare-outward',
  templateUrl: './spare-outward.component.html',
  styleUrls: ['./spare-outward.component.scss']
})
export class SpareOutwardComponent implements OnInit {

  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('showPopupForSpareOutwardList', { static: false }) public showPopupForSpareOutwardList;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('printPopup', { static: false }) public printPopup;
  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;
  createNewBtn: boolean = false;
  editBtn: boolean = false;
  cancelBtn: boolean = false;
  saveBtn: boolean = true;
  btnRow: boolean = false;
  addItemButton: boolean = false;
  editDisableBtn: boolean = false;
  deleteBtnDisable: boolean = false;
  currentSelectedRow: number;
  isPopUpOpen: boolean = false;
  saveBtnVisible: boolean = true;
  isRowClicked:boolean = false;
  refreshDetailTable:boolean = true;

  todaysDate: any = [];
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  deleteMsg: string = "";
  processedit: any = [];
  maxDate;
  minDate = new Date();
  label: string = 'Spare Outward';
  deepCopyForSpareDetailTable: any = {}
  emptySearchField: boolean = true;
  spoutwarduniqueno: number = 0;
  private backupData: any = { bindDataToFormAndTable: {} };
  private tabledataforprocessData: any = [];
  private previousvalue:number = 0;
  tabledataforprocess: any[] = []
  deletedArray: number[] = [];
  customerNameArray = [];
  operation:string = "";

  priviousQty: number = 0;
  qtyInHand: number = 0;
  newlyEnterQty: number = 0;
  dataLocalUrl: any;
  fileName:string = "";
  printPdfFor = 'spareOutward';
  model = {
    showspareoutwardno: {
      valuType: 'String', value: '', label: "Spare Inward", isRequired: false,
      disable: true, maxLength: 16, err: "", element: { name: 'input', type: 'text' }
    },
    spareoutwardno: {
      valuType: 'String', value: '', label: "Spare Inward", isRequired: false,
      disable: true, maxLength: 16, err: "", element: { name: 'input', type: 'text' }
    },
    outwarddt: {
      valuType: 'Date', value: '', label: "Inward Date", disable: true,
      isRequired: true, err: "", element: { name: 'input', type: 'date' }
    },
    customername: {
      valuType: 'String', value: '', label: "Customer Name", disable: true,
      isRequired: true, err: "", element: { name: 'input', type: 'text' }
    },
    somremark: {
      valuType: 'String', value: '', label: "Remark", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    printremark: {
      valuType: 'String', value: '', label: "Remark", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  // Left side table
  spareoutwardtableconfig: any = LEFT_SIDE_TABLE_JSON.tableconfig;
  spareoutwardtableheaders: any[] = LEFT_SIDE_TABLE_JSON.tableheaders;
  spareoutwardtabledata: any[] = [];

  // Details below table
  spareoutwardlisttableconfig: any = DETAIL_TABLE_JSON.tableconfig;
  spareoutwardlisttableheaders: any = DETAIL_TABLE_JSON.tableheaders;
  spareoutwardlisttabledata: any[] = [];

  //  Popup Items table
  spareoutwarditemtableconfig: any = POPUP_ITEMS_TABLE_JSON.tableconfig;
  spareoutwarditemtableheader: any = POPUP_ITEMS_TABLE_JSON.tableheaders;
  spareoutwarditemdata: any[] = [];

  constructor(private ngbActiveModal: NgbActiveModal,private domSanitizer: DomSanitizer,private submit: SubmitFormService, public commonService: CommonServicesService, private apiData: ApiService, private configurationsetting: ConfigurationSetting, public systemParamsService: SystemParamsService, private helper: HelperService, private datepipe: DatePipe, private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.getCustomerName();
    this.getSearchAllSpareOutward();
    if (this.isOpenFromReport) {
      this.getByIdClickCall(this.transactionID)
    }
  }

  createNew() {
    this.emptySearchField = false;
    this.label = "Add Spare Outward";
    this.createNewBtn = true;
    this.operation = "";
    this.saveBtn = true;
    this.editBtn = false;
    this.deleteBtnDisable = false;
    this.cancelBtn = true;
    this.saveBtnVisible = true;
    this.btnRow = true;
    this.deleteMsg = "";
    this.addItemButton = true;
    this.spareoutwardlisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.todaysDate = new Date();
    this.model.outwarddt.value = this.todaysDate;
    this.model.somremark.value = " "
    this.makeColumnsEditableAndUneditable(true);
    const ele = document.getElementById(`tr${"spareoutwardtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    this.spareoutwardlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    delete this.backupData.bindDataToFormAndTable;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  saveSpareOutwardForm() {
    let data = this.submit.submitForm(this.model)

    if (data) {
      this.saveBtn = true;
      let editedRow = [];
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.tabledataforprocess[i]['qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        if (this.tabledataforprocess[i]?.rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i])
        }
      }

      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];

      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      let endpoint = 'transaction/insertSpareOutward';
      if (data["criteria"]['spareoutwardno']) {
        endpoint = 'transaction/updateSpareOutward';
        this.ErrorDescription = "Data Updated Successfully";
        data["criteria"]["outwardlist"] = this.tabledataforprocess;
      } else {
        this.ErrorDescription = "Data Inserted Successfully";
        data["criteria"]["outwardlist"] = this.tabledataforprocess;
      }
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.spoutwarduniqueno = res['Data'];
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.editBtn = true;
          this.addItemButton = false;
          this.cancelBtn = false;
          this.createNewBtn = false;
          this.label = "Spare Outward";
          this.getSearchAllSpareOutward();
          this.commonService.diasbleFormFields(this.model);
          this.spareoutwardlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.makeColumnsEditableAndUneditable(false);
          this.PopupMessageModal.show();
          // this.model.spareoutwardno.value = res['Data'];
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show()
        }
      }, (err) => { });
    }
  }


  deleteDcInwardForm() {
    let data = this.submit.submitForm(this.model);
    if (data && data["criteria"]["spareoutwardno"]) {
      data["criteria"]["outwardlist"] = this.tabledataforprocess;
      let items: any = {};
      items.spareoutwardno = data["criteria"]["spareoutwardno"];
      items.customername = data["criteria"]["customername"];
      items.delLineItems = [];
      items.SpareNo = [];
      data["criteria"]["outwardlist"].forEach((ele) => {
        if (ele?.SrNo || ele?.SpareNo) {
          items.delLineItems.push(ele?.SrNo);
          items.SpareNo.push(ele?.SpareNo);
        }
      })
      data["criteria"] = items;

      let endpoint = 'transaction/deleteSpareOutward';
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('closePopupDelete')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.clearAll(false);
          this.getSearchAllSpareOutward();
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  bindDataToFormAndTable(modal: any, flag: boolean = true) {
    if (this.model.spareoutwardno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.refreshDetailTable = false;
    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal)
    this.model.showspareoutwardno.value = modal['SpareOutwardNo'];
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.spareoutwardlisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"spareoutwardtbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }
    this.editBtn = true;
    this.spareoutwardlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.makeColumnsEditableAndUneditable(false);
    this.spareoutwardlisttabledata = modal['spareOutwardlist']?.length ? modal['spareOutwardlist'] : [];
    let newArr = this.spareoutwardlisttabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.spareoutwardlisttabledata = newArr;

    this.tabledataforprocessData = this.helper.removeRefrence(this.spareoutwardlisttabledata);
    // console.log(newArr);
    setTimeout(() => {
    this.refreshDetailTable = true;
    }, 10);
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.label = "Display Spare Outward";
    this.cancelBtn = false;
    this.editDisableBtn = false;
    this.addItemButton = false;
    this.createNewBtn = false;
    this.deleteBtnDisable = false;
    this.commonService.diasbleFormFields(this.model);
    this.isRowClicked = false;
  }


  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.spareoutwardno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    console.log("getById");

    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.spareoutwardtabledata, modal.rowindex, this.currentSelectedRow, "spareoutwardtbl1");
    let row = modal['row'];
    let spareoutwardno = row['spareoutwardno'];
    this.getByIdClickCall(spareoutwardno);
  }

  getByIdClickCall(no: any) {
    console.log("getById", no);

    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getSpareOutwardById";
    let params = { spareoutwardno: no };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']['tabledata']
        this.bindDataToFormAndTable(item);
      }
    }, (error) => {
      console.log(error);
    });
  }

  selectItemsList(itemdata) {
    this.saveBtn = false;
    this.saveBtnVisible = true;
    itemdata = this.helper.removeRefrence(itemdata);
    let selectedArray = [...this.tabledataforprocess];
    this.spareoutwardlisttabledata = [];

    for (let item of itemdata) {
      item.rowmodifiedstatus = "A";
      item.rowstatus = "A";
      let flag = true;
      this.spareoutwardlisttabledata.forEach(element => {
        if (element['partno'] == item['partno']) {
          flag = false;
          element['qty'] = item['qty'];
        }
      });
      if (flag)
        selectedArray.push(item);
    }
    this.cancelPopup('closepopupforspareoutward');
    this.spareoutwardlisttabledata = [...this.spareoutwardlisttabledata, ...selectedArray];
    console.log(this.spareoutwardlisttabledata);

  }

  updatedtabledata(data: any) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;

    data.forEach((item, i) => {
      if (this.tabledataforprocessData[i] && this.operation === 'edit') {
        console.log(this.tabledataforprocessData[i]);

        this.priviousQty = this.tabledataforprocessData[i]["qty"]
        // this.priviousQty = prevQty;
        const prevQtyInHand = this.tabledataforprocessData[i]["qtyinhand"]
        // const currQty = item['qty'];
        // const currQtyInHand = item['qtyinhand'];
        // console.log("prevQty", this.priviousQty);


        item['totalqty'] = this.priviousQty + prevQtyInHand;
        // item['qtyinhand'] = item['totalqty'] - item['qty'];

        console.log("item['totalqty']", item['totalqty']);
        if(item['qty']>item['totalqty']){
          item['qty'] = this.tabledataforprocessData[i]["qty"];
        }

      }


    })

    // console.log(this.priviousQty);

  }


  rowUpdate(data) {
    // data.row.rowmodifiedstatus = 'M';
      // console.log("data.row.qty", this.previousvalue);
      // this.previousvalue = data.row.qty;
    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.totalqty) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      // data.row.qty = 0;
      this.errorMessageModal.show();
    }
  }

  popupRowUpdate(data) {
    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.qtyinhand) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.qty = 0;
      this.errorMessageModal.show();
    }
  }

  editForm() {
    this.spareoutwardlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.makeColumnsEditableAndUneditable(true);
    this.label = "Edit Spare Outward";
    this.operation = 'edit';
    this.editDisableBtn = true;
    this.cancelBtn = true;
    this.createNewBtn = true;
    this.deleteBtnDisable = true;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.addItemButton = true;
    this.commonService.enableFormFields(this.model);
    this.editBtn = false;
  }

  addItemBtn() {
    this.getItemListForPopup();
  }

  clearAll(flag: boolean = true) {
    this.commonService.clearForm(this.model);
    this.spareoutwardlisttabledata = [];
    this.label = "Spare Outward";
    this.addItemButton = false;
    this.cancelBtn = false;
    this.createNewBtn = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.btnRow = true;
    this.deleteMsg = "";
    this.commonService.diasbleFormFields(this.model);
    this.spareoutwardlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    const ele = document.getElementById(`tr${"spareoutwardtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;
    }
  }

  cancleForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
      } else {
        this.clearAll(true);
      }
    }
  }

  cancelPopup(form) {
    this.spareoutwarditemdata = [];
    this.isPopUpOpen = false;
    switch (form) {
      case 'closepopupforspareoutward':
        this.showPopupForSpareOutwardList.hide();
        break;
      case "closePopupDelete":
        this.showDeleteModal.hide()
        break;
      case "itemalert":
        this.showItemAlert.hide();
        break;
    }
  }

  deleteRow(event) {
    let id = event['row'];

    let tabledata = this.helper.removeRefrence(this.spareoutwardlisttabledata);
    tabledata.splice(event.rowindex, 1);
    this.spareoutwardlisttabledata = []
    this.spareoutwardlisttabledata = tabledata;


    if (!this.spareoutwardlisttabledata.length) {
      this.showItemAlert.show();
      this.saveBtn = true;
      this.saveBtnVisible = false;
    } else {
      this.saveBtn = false;
      this.saveBtnVisible = true;
    }
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }


  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }

  getSearchAllSpareOutward() {
    const endpoint = 'transaction/getAllSpareOutward';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        this.spareoutwardtabledata = []
        this.spareoutwardtabledata = items;
        console.log("all");
        this.isPopUpOpen = true;

        for (let i = 0; i < items.length; i++) {
          if (items[i]['spareoutwardno'] == this.spoutwarduniqueno || items[i]['spareoutwardno'] == this.model.spareoutwardno.value) {
            // console.log("Index", items[i], i);
            this.model.spareoutwardno.value = items[i]['spareoutwardno'];
            this.getByIdClickCall(this.model.spareoutwardno.value);
            if (!this.isOpenFromReport) {
            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trspareoutwardtbl1' + i).style.backgroundColor = "#7de4f0";
            }, 900);
          }
            break;
          }
        }
      }
    }, err => { })
  }


  getItemListForPopup() {
    const endpoint = 'transaction/getSpareOutwardItemList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        items.forEach(element => {
          element.qty = '';
        });
        this.spareoutwarditemdata = []
        this.spareoutwarditemdata = items;
        this.deepCopyForSpareDetailTable = this.helper.removeRefrence(items);

        this.isPopUpOpen = true;
        this.showPopupForSpareOutwardList.show();
        this.filterSpareListTableIfExistingSubTable();
      }
    }, err => { })
  }

  filterSpareListTableIfExistingSubTable() {
    let preparedarr = [];
    preparedarr = this.deepCopyForSpareDetailTable.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < this.spareoutwardlisttabledata.length; ind++) {
        if (this.spareoutwardlisttabledata[ind]['partno'] == row['partno']) {
          recavailable = false;
        }
      }
      return recavailable;
    });
    this.spareoutwarditemdata = []
    this.spareoutwarditemdata = preparedarr;
  }

  closeErrorPopup() {
    this.backendErrorMessageModal.hide()
    this.errorMessageModal.hide();
    this.PopupMessageModal.hide();
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  makeColumnsEditableAndUneditable(flag: boolean) {
    this.spareoutwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.spareoutwardlisttableheaders), "qty", flag);
    this.spareoutwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.spareoutwardlisttableheaders), "remark", flag);
  }

  printPdf(){
    this.downloadPdf(this.printPdfFor);
  }

  downloadPdf(value) {

    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/generateoutwardpdf";
    let body = { "criteria": { "transId": this.model.spareoutwardno.value, "printPdfFor":this.printPdfFor } }

    this.commonService.postMethod(apiUrl, body).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        // console.log("success");
        this.fileName = successResp['Data']['filename'];

        this.downloadFile(this.fileName)
      }
      else {
        this.ErrorDescription = "Something went wrong, please contact admin";
      }
    },
      err => {
        this.ErrorDescription = "Something went wrong, please contact admin";
      });
  }

  downloadFile(filename: string) {
    let body = { "filename": filename }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/downloadpdf";
    this.commonService.getFile(apiUrl, body) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        // saveAs(blob, this.fileName);
        let a = document.createElement('a');

        let file = new Blob([blob], { type: 'application/pdf' });
        // console.log("file ", file);

        let fileURL = URL.createObjectURL(file);

        this.dataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
        a.download = this.fileName;
        setTimeout(() => {
          this.printPopup.show();
        }, 200);

        this.fileName = "";
      })
      .catch((err) => {
        console.log("Something went wrong");
      });

  }
    
  closeModal() {
    this.ngbActiveModal.close();
  }
}
