import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ConfigurationSetting } from "../../appconfig";
import { ApiService } from "../../services/api.service";
import { CommonServicesService } from "../../services/common-services.service";
import { HelperService } from "../../services/helper.service";
import { SubmitFormService } from "../../services/submit-form.service";
import { SystemParamsService } from "../../services/system-params.service";
import { ToastService } from "../../services/toast.service";
import { LEFT_SIDE_TABLE_JSON, DETAIL_TABLE_JSON } from "./manualinward.gtconfig";
declare var $;
@Component({
  selector: "app-manual-inward-note",
  templateUrl: "./manual-inward-note.component.html",
  styleUrls: ["./manual-inward-note.component.scss"],
})
export class ManualInwardNoteComponent implements OnInit {
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;

  selectedPoNumber: boolean = false;
  isFreezedDcOut: boolean = false;
  ManualInForm: boolean = true;
  showFormBtn: boolean = true;
  showCloseBtn: boolean = false;
  deleteMsg: string = "";
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  totalQtyIn: boolean = false;
  currentSelectedRow: number;
  deletedArray: number[] = [];
  saveBtn: boolean = true;
  editBtn: boolean = false;
  addItemButton: boolean = false;
  CreateNew: boolean = false;
  btnRow: boolean = false;
  cancelbtn: boolean = true;
  editDisableBtn: boolean = false;
  saveBtnVisible: boolean = false;
  todaysDate: any = [];
  deleteBtnDisable: boolean = false;
  visiblecanclebtn: boolean = false;
  isRowClicked:boolean = false;
  minDate:any;
  refreshDetailTable:boolean = true;

  emptyArrayToStoreJobSheetData: any[] = [];
  customerNameArray: any[] = []
  emptySearchField: boolean = true;
  manualuniqueno: number = 0;
  public text: string = 'Create New Manual';
  private backupData: any = { bindDataToManualFormAndTable: {} };
  tabledataforprocess: any[] = [];

  model = {
    uId: {
      valuType: 'Number', value: '', label: "Id", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    manualdnsrno: {
      valuType: 'String', value: '', label: "DC Outward ", isRequired: false,
      disable: true, maxLength: 4, err: "", element: { name: 'input', type: 'text' }
    },
    manualdndate: {
      valuType: 'Date', value: '', label: "Outward Date", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'date' }
    },
    customerno: {
      valuType: 'String', value: '', label: "Customer Name", isRequired: true,
      disable: true, maxLength: 100, err: "", element: { name: 'input', type: 'text' }
    },
    address: {
      valuType: 'String', value: '', label: "Address", isRequired: true,
      disable: true, maxLength: 100, err: "", element: { name: 'input', type: 'text' }
    },
    jmremark: {
      valuType: 'String', value: '', label: "Remark", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    printremark: {
      valuType: 'String', value: '', label: "Print Remark", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  // Left side table
  manualsidetableconfig: any = LEFT_SIDE_TABLE_JSON.tableconfig;
  manualsidetableheaders: any = LEFT_SIDE_TABLE_JSON.tableheaders;
  manualsidetabledata: any = [];

  // Detail side table
  manuallisttableconfig: any = DETAIL_TABLE_JSON.tableconfig;
  manuallisttableheaders: any = DETAIL_TABLE_JSON.tableheaders;
  manuallisttabledata: any[] = [];

  constructor(private helper: HelperService, private submit: SubmitFormService, private apidata: ApiService, public commonService: CommonServicesService,
    private configurationsetting: ConfigurationSetting, private datepipe: DatePipe, public systemParamsService: SystemParamsService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.getAllManualIn();
    this.getCustomerName();
  }

  CreateNewDc() {
    this.emptySearchField = false;
    if (this.text === 'Create New Manual') {
      this.text = 'Add Manual'
    } else {
      this.text = 'Create New Manual'
    }
    this.deleteMsg = ""
    this.saveBtn = true;
    this.CreateNew = true;
    this.editBtn = false;
    this.saveBtnVisible = true;
    this.cancelbtn = false;
    this.deleteBtnDisable = false;
    this.btnRow = true;
    this.addItemButton = true;
    this.visiblecanclebtn = true;
    this.manuallisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.todaysDate = new Date();
    this.model.manualdndate.value = this.todaysDate;
    this.model.jmremark.value = " "
    this.makeColumnEditableAndUneditable(true);
    const ele = document.getElementById(`tr${"manualtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    delete this.backupData.bindDataToManualFormAndTable;
    this.minDate = new Date();
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  submitManualForm() {
    let data = this.submit.submitForm(this.model);

    if (data) {
      this.saveBtn = true;
      let editedRow = [];
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.tabledataforprocess[i]['qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        if (this.tabledataforprocess[i].rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i]);
        }
      }

      // this.tabledataforprocess.forEach((ele) => {
      // });

      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];
      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      data["criteria"]["tablename"] = "manualnoteinwardmaster";
      let endpoint = 'transaction/insertManualDn';
      if (data["criteria"]['manualdnsrno']) {
        this.ErrorDescription = "Data Updated Successfully";
        endpoint = 'transaction/updateManualDn';
        data["criteria"]["manualitems"] = this.tabledataforprocess;
      } else {
        this.ErrorDescription = "Data Inserted Successfully";
        data["criteria"]["manualitems"] = this.tabledataforprocess;
      }
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.manualuniqueno = res['Data']
          this.saveBtn = true;
          this.visiblecanclebtn = false;
          this.addItemButton = false;
          this.cancelbtn = true;
          this.deleteBtnDisable = false;
          this.commonService.diasbleFormFields(this.model);
          this.manuallisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.makeColumnEditableAndUneditable(false);
          this.deleteBtnDisable = false;
          this.getAllManualIn();
          this.editBtn = true;
          this.PopupMessageModal.show();
          this.saveBtnVisible = false;
          this.CreateNew = false;
          // this.model.manualdnsrno.value = res['Data'];
          // this.toastService.showToastMsg('success', 'Success', this.ErrorDescription);
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  deleteManualInForm() {
    let data = this.submit.submitForm(this.model);

    if (data && data["criteria"]["manualdnsrno"]) {
      data["criteria"]["manualitems"] = this.tabledataforprocess;
      let items: any = {};
      items.manualdnsrno = data["criteria"]["manualdnsrno"];
      items.customerno = data["criteria"]["customerno"];

      items.delLineItems = [];
      data["criteria"]["manualitems"].forEach((ele) => {
        if (ele?.SrNo) {
          items.delLineItems.push(ele?.SrNo);
        }
      })
      data["criteria"] = items;
      data["criteria"]["tablename"] = "manualnoteinwardmaster";

      let endpoint = 'transaction/deleteManualDn';
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('deletepopup')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.clearAll(false);
          this.getAllManualIn();
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  getAllManualIn() {
    let params = { "tablename": "manualnoteinwardmaster" };
    const endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getAllManualDn';
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        this.manualsidetabledata = []
        this.manualsidetabledata = items;
        for (let i = 0; i < items.length; i++) {
          if (items[i]['manualdnsrno'] == this.manualuniqueno || items[i]['manualdnsrno'] == this.model.manualdnsrno.value) {
            this.model.manualdnsrno.value = items[i]['manualdnsrno'];
            // console.log("Index", this.tabledata[i], i);
            this.getByIdCall(this.model.manualdnsrno.value)
            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trmanualtbl1' + i).style.backgroundColor = "#7de4f0";
            }, 900);
            break;
          }
        }
      }
    }, err => { })
  }

  getCustomerName() {
    let endpoint = 'transaction/getCustomerwithAdress';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }

  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.manualdndate.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.manualsidetabledata, modal.rowindex, this.currentSelectedRow, "manualtbl1");

    let row = modal['row'];
    let manualdnsrno = row['manualdnsrno'];
    this.getByIdCall(manualdnsrno)
  }

  getByIdCall(srno){
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getManualDnById";
    let params = { "tablename": "manualnoteinwardmaster", manualdnsrno: srno};
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data'];
        this.bindDataToManualFormAndTable(item);
      }
    }, (error) => {
      // console.log(error);
    });
  }

  bindDataToManualFormAndTable(modal, flag: boolean = true) {
    if (this.model.customerno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.backupData.bindDataToManualFormAndTable = this.helper.removeRefrence(modal);
    this.refreshDetailTable = false;
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.manuallisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"manualtbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }

    this.manuallisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.makeColumnEditableAndUneditable(false);
    this.manuallisttabledata = modal['manualitems']?.length ? modal['manualitems'] : []

    let newArr = this.manuallisttabledata.filter((item)=>{
      return item.ddisDeleted !== 1;
    })
    this.manuallisttabledata = newArr;
    // console.log(newArr);
setTimeout(() => {
  this.refreshDetailTable = true;
}, 10);
    this.editBtn = true;

    this.addItemButton = false;
    this.cancelbtn = false;
    this.CreateNew = false;
    this.editDisableBtn = false;
    this.commonService.diasbleFormFields(this.model);
 this.isRowClicked = false;

  }

  addRowForManualIn() {
    let deepCopy = [...this.tabledataforprocess]
    let count = 0

    const item = { srno: count++, description: "", qty: 0, remark: "" };
    deepCopy.push(item);
    this.manuallisttabledata = deepCopy;
    this.saveBtn = false;
  }

  updatedtabledata(data: any) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;
    this.tabledataforprocess.forEach((item, i)=>{
      item['srno'] = i+1;
    })
  }

  editForm() {
    this.manuallisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.makeColumnEditableAndUneditable(true);
    this.deleteBtnDisable = true;
    this.visiblecanclebtn = true;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.cancelbtn = false;
    this.addItemButton = true;
    this.editDisableBtn = true;
    this.editBtn = false;
    this.commonService.enableFormFields(this.model);
  }

  cancelPopup(whichForm) {
    switch (whichForm) {
      case 'deletepopup':
        this.showDeleteModal.hide();
        break;
      case 'errorpopup':
        this.backendErrorMessageModal.hide();
        break;
    }
  }

  cancelErrPopup() {
    this.errorMessageModal.hide();
    this.PopupMessageModal.hide();
  }

  clearAll(flag: boolean = true) {
    this.commonService.clearForm(this.model);
    this.manuallisttabledata = [];
    this.visiblecanclebtn = false;
    this.addItemButton = false;
    this.cancelbtn = true;
    this.btnRow = false;
    this.deleteMsg = ""
    this.deleteBtnDisable = false;
    this.commonService.diasbleFormFields(this.model);
    this.manuallisttableconfig['actioncolmconfig']['displaycolumn'] = true;

    const ele = document.getElementById(`tr${"manualtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';

    if (this.CreateNew && this.backupData['bindDataToManualFormAndTable'] && flag) {
      this.bindDataToManualFormAndTable(this.backupData.bindDataToManualFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;
    }
    this.CreateNew = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;

  }

  rowUpdate(data) {
    data.row.rowmodifiedstatus = 'M';
    if (data['keyName'] == 'qty' && data?.row?.Qty < 0) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.qty = 0;
      this.errorMessageModal.show();
    }
  }

  cancel() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
        this.CreateNew = false;
      } else {
        this.clearAll(true);
        this.CreateNew = true;
        if (this.saveBtn && this.cancelbtn) {
          this.clearAll(false);
          this.CreateNew = false;
        }
      }
    }
  }

  CustomerData(val: any) {
    for (let items of this.customerNameArray) {
      if (val == items.value) {
        this.model.address.value = items.address;
      }
    }
  }

  deleteRow(event) {
    let id = event['row'];
    this.tabledataforprocess.splice(event.rowindex, 1);
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  makeColumnEditableAndUneditable(flag: boolean) {
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "srno", flag);
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "description", flag);
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "qty", flag);
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "remark", flag);
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "printremark", flag);
  }
}

