import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { SubmitFormService } from '../../services/submit-form.service';
import { ApiService } from '../../services/api.service';
import { CommonServicesService } from '../../services/common-services.service';
import { ConfigurationSetting } from '../../appconfig';
import { DatePipe } from '@angular/common';
import { SystemParamsService } from '../../services/system-params.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryChallanInwardComponent } from '../delivery-challan-inward/delivery-challan-inward.component';
import { JobSheetComponent } from '../job-sheet/job-sheet.component';
import { RoutineDcComponent } from '../material-outward-compo/routine-dc/routine-dc.component';
import { PoMasterComponent } from '../po-master/po-master.component';
import { DcOutwardComponent } from '../material-outward-compo/dc-outward/dc-outward.component';
import { SpareInwardComponent } from '../spare-transaction/spare-inward/spare-inward.component';
import { RejectedOutComponent } from '../material-outward-compo/rejected-out/rejected-out.component';
import { InvoiceComponent } from '../invoice/invoice.component';
import { SpareOutwardComponent } from '../spare-transaction/spare-outward/spare-outward.component';
import { SpareIssuedComponent } from '../spare-transaction/spare-issued/spare-issued.component';
import { SpareRecieptComponent } from '../spare-transaction/spare-reciept/spare-reciept.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('dataFetchingModal', { static: false }) public dataFetchingModal;

  tableconfig: any = {}
  tableheaders: any[] = []
  tabledata: any[] = [];

  internaltableconfig: any = {}
  internaltableheaders: any[] = []
  internaltabledata: any[] = [];

  reportType: string = "";
  reportName: string = "";
  SpareList: any[] = [];
  jobNoList: any[] = [];
  isClicked: boolean = false;

  tableShown: boolean = true;
  refreshTable: boolean = true;
  firstTableShow: boolean = false;
  todaysDate: any = new Date();

  maxFromDate: any;
  maxToDate: any;

  minFromDate: any;
  minToDate: any;

  ErrorDescription: string = '';
  itemNameForJob: string = '';
  DescriptionForJob: string = '';
  ModelNameForJob: string = '';
  ESNForJob: string = '';
  esncustomerForJob: string = '';
  EngTypeForJob: string = '';

  poNoForPoDetail: string = '';
  partNoForPoDetail: string = '';
  nameForPoDetail: string = '';

  inwardTotalQty_forSummary: number = 0;
  outwardTotalQty_forSummary: number = 0;
  issueTotalQty_forSummary: number = 0;
  receiptTotalQty_forSummary: number = 0;
  jobCardTotalQty_forSummary: number = 0;

  model: any = {
    reporttype: {
      valuType: 'String', value: '', label: "Select Report", isRequired: true,
      disable: false, err: "", element: { name: 'input', type: 'date' }
    },
    spareno: {
      valuType: 'String', value: '', label: "Select Report", isRequired: true,
      disable: false, err: "", element: { name: 'input', type: 'date' }
    },
    days: {
      valuType: 'Number', value: '', label: "Enter Days", isRequired: true,
      disable: false, err: "", element: { name: 'input', type: 'number' }
    },
    jobno: {
      valuType: 'String', value: '', label: "Select Report", isRequired: false,
      disable: false, err: "", element: { name: 'input', type: 'date' }
    },
    fromdate: {
      valuType: 'Date', value: '', label: "From Date", isRequired: false,
      disable: false, err: "", element: { name: 'input', type: 'date' }
    },
    todate: {
      valuType: 'Date', value: '', label: "To Date", isRequired: false,
      disable: false, err: "", element: { name: 'input', type: 'date' }
    },
  }

  SpareInOutCheckedObj = {
    inward: true,
    outward: true,
    issue: true,
    receipt: true,
    jobcard: true,
  }

  reportNameObj = {};

  srNoIndex = 0;
  constructor(private helper: HelperService, private submit: SubmitFormService, private apidata: ApiService, private commonService: CommonServicesService,
    private configurationsetting: ConfigurationSetting, private datepipe: DatePipe, public systemParamsService: SystemParamsService, private activeRoute: ActivatedRoute,
    private ngbModal: NgbModal) { }

  ngOnInit(): void {
    this.activeRoute.url.subscribe((item => {
      if (item[0]['path'] == "spare-report") {
        this.reportType = "SR";
      } else if (item[0]['path'] == "item-report") {
        this.reportType = "IR";
      } else if (item[0]['path'] == "po-report") {
        this.reportType = "POR";
      }else if (item[0]['path'] == "challan-ageing") {
        this.reportType = "CAR";
      }
    }))
    this.getSpareList();
    this.getJobNoList()
    this.maxFromDate = new Date();
    this.maxToDate = this.todaysDate;

  }

  getSpareList() {
    let endpoint = this.configurationsetting.getBaseUrl() + 'Master/GetDyanamicDDL';
    let params = { "ddlname": "spareddl" };
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.SpareList = [];
        this.SpareList = successResp['Data'];
      }
    }, err => { })
  }


  getJobNoList() {
    let endpoint = this.configurationsetting.getBaseUrl() + 'Master/GetDyanamicDDL';
    let params = { "ddlname": "jobddl" };
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.jobNoList = [];
        this.jobNoList = successResp['Data'];
      }
    }, err => { })
  }

  generatechallanAgeing(){
    if (!this.model.reporttype.value) {
      this.ErrorDescription = 'Please select report type.';
      this.PopupMessageModal.show();
      return;
    }

    let url = '';
    let param = {};
    this.refreshTable = false;
    if (this.model.reporttype.value == 'challanAgeingDcInwardReport' || this.model.reporttype.value == 'challanAgeingSpareInwardReport') {
      this.showWarningPopUp(this.model.days.value, 'Please enter days.');
      url = 'reports/challanAgeingReport'
      param = {
        reporttype: this.model.reporttype.value,
        days: this.model.days.value
      }
    }

    const endpoint = this.configurationsetting.getBaseUrl() + url;

    this.commonService.getWithParams(endpoint, param).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.firstTableShow = true;
        let items = successResp['Data'];

        this.tableconfig = {};
        this.tableheaders = [];
        this.tabledata = [];
        this.tableconfig = items['tableconfig'];
        this.tableheaders = items['tableheaders'];
        setTimeout(() => { this.refreshTable = true; }, 20);
        for (let i = 0; i < items['tabledata'].length; i++) {
          const element = items['tabledata'][i];
          element['SrNo'] = i+1;
          if (this.model.reporttype.value == 'challanAgeingDcInwardReport'){
            element['isBlanketPo'] = element['isBlanketPo'] == 0 ? 'Non Blanket Po' : 'Blanket PO';
            element['PoNo'] = element['PoNo'] ? element['PoNo'] : '-';
            element['Releases'] = element['Releases'] ? element['Releases'] : '-';
            element['jobno'] = element['jobno'] ? element['jobno'] : '-';
            element['dispatchQty'] = element['dispatchQty'] ? element['dispatchQty'] : '-';
          }
        }
        this.tabledata = items['tabledata'];
        this.tabledata.length ? this.isClicked = false : this.isClicked = true;

        this.tableShown = true;
        this.createObjForPdf();
        this.tableconfig['filenameprefix'] = this.reportNameObj[this.model.reporttype.value]['filenameprefix']

      }
    })

  }


  generateReport() {
    if (!this.model.reporttype.value) {
      this.ErrorDescription = 'Please select report type.';
      this.PopupMessageModal.show();
      return;
    }

    this.inwardTotalQty_forSummary = 0;
    this.outwardTotalQty_forSummary = 0;
    this.issueTotalQty_forSummary = 0;
    this.receiptTotalQty_forSummary = 0;
    this.jobCardTotalQty_forSummary = 0;

    this.refreshTable = false;
    let url = '';
    let param = {};
    if (this.reportType == 'IR') {
      if (this.model.reporttype.value == 'DetailSubAssemblyReportDispatch' || this.model.reporttype.value == 'detailSubAssemblyReportDispatchEngg') {
        this.showWarningPopUp(this.model.fromdate.value, 'Please select from date.');
        this.showWarningPopUp(this.model.todate.value, 'Please select to date.');
        url = 'reports/GetDetailSubAssemblyRecieptDispatch'
        param = {
          reporttype: this.model.reporttype.value,
          fromdate: this.model.fromdate.value,
          todate: this.model.todate.value
        }
      }

      if (this.model.reporttype.value == 'jobHistoryReport') {
        this.showWarningPopUp(this.model.jobno.value, 'Please select Job number.');
        url = 'reports/GetJobsheetReport'
        param = {
          reporttype: this.model.reporttype.value,
          reportId: this.model.jobno.value
        }
      }

      if (this.model.reporttype.value == 'itemWiseRecieptDispatchReport' || this.model.reporttype.value == 'itemWiseRecieptDispatchNonBlanketReport') {
        this.showWarningPopUp(this.model.fromdate.value, 'Please select from date.');
        this.showWarningPopUp(this.model.todate.value, 'Please select to date.');
        url = 'reports/GetItemWiseRecieptDispatchReport'
        param = {
          reporttype: this.model.reporttype.value,
          fromdate: this.model.fromdate.value,
          todate: this.model.todate.value
        }
      }

    } else {
      if (this.model.reporttype.value == 'spareRecieptDispatch') {
        this.showWarningPopUp(this.model.fromdate.value, 'Please select from date.');
        this.showWarningPopUp(this.model.todate.value, 'Please select to date.');
        url = 'reports/GetRecieptDispatchReport'
        param = {
          reporttype: this.model.reporttype.value,
          fromdate: this.model.fromdate.value,
          todate: this.model.todate.value
        }
      } else if (this.model.reporttype.value == 'spareWiseInOutReport') {
        this.showWarningPopUp(this.model.fromdate.value, 'Please select from date.');
        this.showWarningPopUp(this.model.todate.value, 'Please select to date.');
        this.showWarningPopUp(this.model.spareno.value, 'Please select spare no.');
        url = 'reports/GetSpareInOutReport'
        param = {
          reporttype: this.model.reporttype.value,
          spareno: this.model.spareno.value,
          fromdate: this.model.fromdate.value,
          todate: this.model.todate.value,
          option: this.SpareInOutCheckedObj
        }
      } else if (this.model.reporttype.value == 'quantity' || this.model.reporttype.value == 'wip') {
        url = 'reports/GetSpareReports'
        param = {
          reporttype: this.model.reporttype.value,
          reportId: this.model.spareno.value,
          fromdate: this.model.fromdate.value,
          todate: this.model.todate.value
        }
      }
    }

    const endpoint = this.configurationsetting.getBaseUrl() + url;

    this.commonService.getWithParams(endpoint, param).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.firstTableShow = true;
        let items = {};
        if (this.model.reporttype.value == 'spareRecieptDispatch' || this.model.reporttype.value == 'spareWiseInOutReport' || this.model.reporttype.value == 'DetailSubAssemblyReportDispatch' || this.model.reporttype.value == 'detailSubAssemblyReportDispatchEngg' || this.model.reporttype.value == 'itemWiseRecieptDispatchReport' || this.model.reporttype.value == 'itemWiseRecieptDispatchNonBlanketReport') {
          items = successResp['Data'];
        } else {
          items = successResp['Data'][0]['@Oritbl'];
        }

        this.tableconfig = {};
        this.tableheaders = [];
        this.tabledata = [];
        this.tableconfig = items['tableconfig'];
        this.tableheaders = items['tableheaders'];

        setTimeout(() => { this.refreshTable = true; }, 20);

        if (this.model.reporttype.value == 'itemWiseRecieptDispatchReport' || this.model.reporttype.value == 'itemWiseRecieptDispatchNonBlanketReport') {
          this.srNoIndex = 0;
          this.tabledata = this.createTable(items['tabledata']);
          this.tabledata.length ? this.isClicked = false : this.isClicked = true;

        } else {

          this.tabledata = items['tabledata'];
          this.tabledata.length ? this.isClicked = false : this.isClicked = true;
          if (this.model.reporttype.value == 'detailSubAssemblyReportDispatchEngg' || this.model.reporttype.value == 'spareWiseInOutReport') {
            this.tableheaders.forEach((item) => {
              if (this.model.reporttype.value == 'detailSubAssemblyReportDispatchEngg' && (item['key'] == 'Releases' || item['key'] == 'jobno' || item['key'] == 'BerQty')) {
                item['visiblecolm'] = false;
              }
              if (this.model.reporttype.value == 'spareWiseInOutReport'
                && (!this.SpareInOutCheckedObj.inward || !this.SpareInOutCheckedObj.outward || !this.SpareInOutCheckedObj.issue || !this.SpareInOutCheckedObj.receipt || !this.SpareInOutCheckedObj.jobcard)
                && (item['key'] == 'qtyinhand' || item['key'] == 'wip')) {
                item['visiblecolm'] = false;
              }
            })
          }

          if (this.model.reporttype.value == 'quantity' || this.model.reporttype.value == 'DetailSubAssemblyReportDispatch' || this.model.reporttype.value == 'detailSubAssemblyReportDispatchEngg' || this.model.reporttype.value == 'spareRecieptDispatch' || this.model.reporttype.value == 'spareWiseInOutReport') {

            let balQtyInHand = 0;
            let storePriviousWip = 0
            let storePriviousQtyInHand = 0
            for (let i = 0; i < this.tabledata.length; i++) {
              let item = this.tabledata[i];
              if (this.model.reporttype.value == 'spareWiseInOutReport' && (!this.SpareInOutCheckedObj.inward || !this.SpareInOutCheckedObj.outward || !this.SpareInOutCheckedObj.issue || !this.SpareInOutCheckedObj.receipt || !this.SpareInOutCheckedObj.jobcard)) {
                delete this.tabledata[0]['remark']
              }
              if (this.model.reporttype.value == 'DetailSubAssemblyReportDispatch' || this.model.reporttype.value == 'detailSubAssemblyReportDispatchEngg') {
                if (this.model.reporttype.value == 'DetailSubAssemblyReportDispatch') {
                  if (item['outwardtype'] == 'DO') {
                    item['OkQty'] = item['Qty'];
                  } else {
                    item['OkQty'] = 0;
                  }
                  if (item['outwardtype'] == 'RO') {
                    item['BerQty'] = item['Qty'];
                  } else {
                    item['BerQty'] = 0;
                  }
                } else {
                  if (item['outwardtype'] == 'RD') {
                    item['OkQty'] = item['Qty'];
                  }
                  else {
                    item['OkQty'] = 0;
                  }
                }

                item['OkQty'] == 0 ? item['OkQty'] = '-' : item['OkQty'];
                item['BerQty'] == 0 ? item['BerQty'] = '-' : item['BerQty'];
                item['inwardqty'] == 0 ? item['inwardqty'] = '-' : item['inwardqty'];
                item['balanceqty'] == 0 ? item['balanceqty'] = '-' : item['balanceqty'];
              }
              if (this.model.reporttype.value == 'quantity') {
                item['MinStock'] == 0 ? item['MinStock'] = '-' : item['MinStock'];
                item['WIP'] == 0 ? item['WIP'] = '-' : item['WIP'];
                item['CurrentBalance'] == 0 ? item['CurrentBalance'] = '-' : item['CurrentBalance'];
              }
              if (this.model.reporttype.value == 'spareRecieptDispatch') {
                if (item['spinwardqty'] > item['spactualqty']) {
                  item['dispshort'] = item['spinwardqty'] - item['spactualqty'];
                } else {
                  item['dispexcess'] = item['spactualqty'] - item['spinwardqty'];
                }
                item['opqtyinhand'] = item['opqtyinhand'] + item['opspareinward'] - item['opissueqty'] + item['oprecieptqty'] - item['opoutwardqty'];
                item['opwip'] = item['opwip'] + item['opissueqty'] - item['opjobsheetqty'] - item['oprecieptqty'];
                item['optotalqty'] = item['opqtyinhand'] + item['opwip'];

                item['clqtyinhand'] = item['clqtyinhand'] + item['spinwardqty'] + item['dispexcess'] - item['dispshort'] - item['spissue'] + item['recieptqty'] - item['spoutward'] + item['opqtyinhand'];
                item['clwip'] = item['clwip'] + item['spissue'] - item['jobsheet'] - item['recieptqty'] + item['opwip'];
                item['cltotalqty'] = item['clqtyinhand'] + item['clwip'];


                // item['discrepancy'] = item['spinwardqty'] != item['spactualqty'] ? 'Y' : 'N';
                item['MinStock'] == 0 ? item['MinStock'] = '-' : item['MinStock'];
                item['optotalqty'] == 0 ? item['optotalqty'] = '-' : item['optotalqty'];
                item['opqtyinhand'] == 0 ? item['opqtyinhand'] = '-' : item['opqtyinhand'];
                item['opwip'] == 0 ? item['opwip'] = '-' : item['opwip'];
                item['spinwardqty'] == 0 ? item['spinwardqty'] = '-' : item['spinwardqty'];
                item['spactualqty'] == 0 ? item['spactualqty'] = '-' : item['spactualqty'];
                item['dispshort'] == 0 ? item['dispshort'] = '-' : item['dispshort'];
                item['dispexcess'] == 0 ? item['dispexcess'] = '-' : item['dispexcess'];
                item['spoutward'] == 0 ? item['spoutward'] = '-' : item['spoutward'];
                item['spissue'] == 0 ? item['spissue'] = '-' : item['spissue'];
                item['jobsheet'] == 0 ? item['jobsheet'] = '-' : item['jobsheet'];
                item['cltotalqty'] == 0 ? item['cltotalqty'] = '-' : item['cltotalqty'];
                item['clqtyinhand'] == 0 ? item['clqtyinhand'] = '-' : item['clqtyinhand'];
                item['clwip'] == 0 ? item['clwip'] = '-' : item['clwip'];
                item['recieptqty'] == 0 ? item['recieptqty'] = '-' : item['recieptqty'];

              }
              if (this.model.reporttype.value == 'spareWiseInOutReport') {
                if (!item['qtyinhand']) item['qtyinhand'] = 0;
                if (item['trnxtype'] == 'Issue') {
                  item['wip'] = storePriviousWip
                  this.issueTotalQty_forSummary += item['Qty'];
                  item['wip'] = item['wip'] + item['Qty'];
                  let deductWip = item['wip'] - storePriviousWip
                  item['qtyinhand'] = this.tabledata[i - 1]['qtyinhand'] - deductWip;
                  // item['qtyinhand'] = balQtyInHand - item['wip'];
                  balQtyInHand = item['qtyinhand'];
                } else if (item['trnxtype'] == 'Job Card') {
                  this.jobCardTotalQty_forSummary += item['Qty'];
                  item['qtyinhand'] = balQtyInHand;
                  item['wip'] = this.tabledata[i - 1]['wip'] - item['Qty'];
                  storePriviousWip = item['wip']
                } else if (item['trnxtype'] == 'Inward') {
                  this.inwardTotalQty_forSummary += item['Qty'];
                  item['qtyinhand'] = this.tabledata[i - 1]['qtyinhand'] + item['Qty'];
                } else if (item['trnxtype'] == 'Outward') {
                  this.outwardTotalQty_forSummary += item['Qty'];
                  item['qtyinhand'] = this.tabledata[i - 1]['qtyinhand'] - item['Qty'];
                } else if (item['trnxtype'] == 'Receipt') {
                  this.receiptTotalQty_forSummary += item['Qty'];
                  item['wip'] -= item['Qty']
                  item['qtyinhand'] = item['qtyinhand'] + item['wip'];
                  balQtyInHand = item['qtyinhand'];
                }

              }

            }
            if (this.model.reporttype.value == 'spareWiseInOutReport' && (!this.SpareInOutCheckedObj.inward || !this.SpareInOutCheckedObj.outward || !this.SpareInOutCheckedObj.issue || !this.SpareInOutCheckedObj.receipt || !this.SpareInOutCheckedObj.jobcard)) {
              this.inwardTotalQty_forSummary ? this.tabledata.push({ remark: 'Inward Qty - ', trnxtype: this.inwardTotalQty_forSummary }) : [];
              this.outwardTotalQty_forSummary ? this.tabledata.push({ remark: 'Outward Qty - ', trnxtype: this.outwardTotalQty_forSummary }) : [];
              this.issueTotalQty_forSummary ? this.tabledata.push({ remark: 'Issue Qty - ', trnxtype: this.issueTotalQty_forSummary }) : [];
              this.receiptTotalQty_forSummary ? this.tabledata.push({ remark: 'Reciept Qty - ', trnxtype: this.receiptTotalQty_forSummary }) : [];
              this.jobCardTotalQty_forSummary ? this.tabledata.push({ remark: 'Jobsheet Qty - ', trnxtype: this.jobCardTotalQty_forSummary }) : [];
            } else {
              this.inwardTotalQty_forSummary ? this.tabledata.push({ remark: 'Inward Qty - ', qtyinhand: this.inwardTotalQty_forSummary }) : [];
              this.outwardTotalQty_forSummary ? this.tabledata.push({ remark: 'Outward Qty - ', qtyinhand: this.outwardTotalQty_forSummary }) : [];
              this.issueTotalQty_forSummary ? this.tabledata.push({ remark: 'Issue Qty - ', qtyinhand: this.issueTotalQty_forSummary }) : [];
              this.receiptTotalQty_forSummary ? this.tabledata.push({ remark: 'Reciept Qty - ', qtyinhand: this.receiptTotalQty_forSummary }) : [];
              this.jobCardTotalQty_forSummary ? this.tabledata.push({ remark: 'Jobsheet Qty - ', qtyinhand: this.jobCardTotalQty_forSummary }) : [];
            }
          }
        }


      }

      if (this.model.reporttype.value == 'jobHistoryReport') {
        this.itemNameForJob = this.tabledata[0]['itemName'] ? this.tabledata[0]['itemName'] : '';
        this.DescriptionForJob = this.tabledata[0]['Description'] ? this.tabledata[0]['Description'] : '';
        this.ModelNameForJob = this.tabledata[0]['ModelName'] ? this.tabledata[0]['ModelName'] : '';
        this.ESNForJob = this.tabledata[0]['ESN'] ? this.tabledata[0]['ESN'] : '';
        this.esncustomerForJob = this.tabledata[0]['esncustomer'] ? this.tabledata[0]['esncustomer'] : '';
        this.EngTypeForJob = this.tabledata[0]['EngType'] ? this.tabledata[0]['EngType'] : '';
      }
      this.tableShown = true;
      this.createObjForPdf();

      this.tableconfig['filenameprefix'] = this.reportNameObj[this.model.reporttype.value]['filenameprefix']
    }, err => { })

  }

  sortAsy1(data) {
    const AssemblyObj = {}
    data.forEach(element => {
      if (!AssemblyObj[element['Description']]) {
        AssemblyObj[element['Description']] = {
          data: [],
          hrader: {},
          total: {}
        };
      }
      AssemblyObj[element['Description']].push(element);
    });
    return AssemblyObj;
  }

  sortAsy = (data) => {
    const AssemblyObj = {}
    data.forEach(element => {
      if (!AssemblyObj[element['Description']]) {
        AssemblyObj[element['Description']] = {
          data: [],
          headerObj: {
            ItemNo: '',
            subAssemblySrNo: '',
            Description: '',
            itemCode: element['Description'],
            ModelName: '',
            openingbal: '',
            inwardqty: '',
            regoutwardqty: '',
            rejoutqty: '',
            closingbal: '',
            openinginwardqty: '',
            openingregoutwardqty: '',
            openingrejoutqty: ''
          }, totalObj: {
            "ModelName": 'Total :-',
            "openingbal": 0,
            "inwardqty": 0,
            "regoutwardqty": 0,
            "rejoutqty": 0,
            "closingbal": 0,
            "openinginwardqty": 0,
            "openingregoutwardqty": 0,
            "openingrejoutqty": 0
          },
          emptyRowObj: {
            ItemNo: '',
            subAssemblySrNo: '',
            Description: '',
            itemCode: '',
            ModelName: '',
            openingbal: '',
            inwardqty: '',
            regoutwardqty: '',
            rejoutqty: '',
            closingbal: '',
            openinginwardqty: '',
            openingregoutwardqty: '',
            openingrejoutqty: ''
          }
        };
      }
      this.srNoIndex++;
      element['SrNo'] = this.srNoIndex;
      AssemblyObj[element['Description']].data.push(this.assemblyData(element, AssemblyObj[element['Description']].totalObj));
    });
    return AssemblyObj
  }
  assemblyData = (item, totalObj) => {


    // assemblyArray.forEach((item) => {
    // headerObj['Description'] = item['Description'];

    item['openingbal'] = item['openinginwardqty'];//<PRAVIN>
    item['closingbal'] = item['openingbal'] + item['inwardqty'] - item['regoutwardqty'] - item['rejoutqty'];

    totalObj['openingbal'] += item['openingbal'];
    totalObj['inwardqty'] += item['inwardqty'];
    totalObj['regoutwardqty'] += item['regoutwardqty'];
    totalObj['rejoutqty'] += item['rejoutqty'];
    totalObj['closingbal'] += item['closingbal'];

    item['openingbal'] = item['openingbal'] ? item['openingbal'] : '-';
    item['inwardqty'] = item['inwardqty'] ? item['inwardqty'] : '-';
    item['rejoutqty'] = item['rejoutqty'] ? item['rejoutqty'] : '-';
    item['regoutwardqty'] = item['regoutwardqty'] ? item['regoutwardqty'] : '-';
    item['closingbal'] = item['closingbal'] ? item['closingbal'] : '-';

    // totalObj['openingbal'] = totalObj['openingbal'] ? totalObj['openingbal'] : '-';
    // totalObj['inwardqty'] = totalObj['inwardqty'] ? totalObj['inwardqty'] : '-';
    // totalObj['regoutwardqty'] = totalObj['regoutwardqty'] ? totalObj['regoutwardqty'] : '-';
    // totalObj['rejoutqty'] = totalObj['rejoutqty'] ? totalObj['rejoutqty'] : '-';
    // totalObj['closingbal'] = totalObj['closingbal'] ? totalObj['closingbal'] : '-';
    // })
    // return [headerObj, ...assemblyArray, totalObj];

    return item;
  }

  // Commented for optamized code if SrNo doesnot work uncomment this.
  // assemblyHeaderAndSummaryTotal (assemblyArray)  {
  //   const headerObj = {
  //       ItemNo: '',
  //       subAssemblySrNo: '',
  //       Description: '',
  //       itemCode: '',
  //       ModelName: '',
  //       openingbal: '',
  //       inwardqty: '',
  //       regoutwardqty: '',
  //       rejoutqty: '',
  //       closingbal: '',
  //       openinginwardqty: '',
  //       openingregoutwardqty: '',
  //       openingrejoutqty: ''
  //   }
  //   const totalObj = {
  //       "ModelName": 'Total :-',
  //       "openingbal": 0,
  //       "inwardqty": 0,
  //       "regoutwardqty": 0,
  //       "rejoutqty": 0,
  //       "closingbal": 0,
  //       "openinginwardqty": 0,
  //       "openingregoutwardqty": 0,
  //       "openingrejoutqty": 0
  //   };

  //   assemblyArray.forEach((item, ind) => {
  //       this.srNoIndex++
  //       headerObj['itemCode'] = item['Description'];
  //       item['SrNo'] = (this.srNoIndex)
  //       item['openingbal'] = item['openinginwardqty'] - item['openingregoutwardqty'] - item['openingrejoutqty'];
  //       item['closingbal'] = item['openingbal'] + item['inwardqty'] - item['regoutwardqty'] - item['rejoutqty'];

  //       totalObj['openingbal'] += item['openingbal'];
  //       totalObj['inwardqty'] += item['inwardqty'];
  //       totalObj['regoutwardqty'] += item['regoutwardqty'];
  //       totalObj['rejoutqty'] += item['rejoutqty'];
  //       totalObj['closingbal'] += item['closingbal'];
  //   })
  //   return [headerObj, ...assemblyArray, totalObj]
  // }

  // createTableDataFromSortedAssiblyObj1 (sortedAssiblyObj) {
  //   let tableData = [];
  //   for (const key in sortedAssiblyObj) {
  //       if (Object.hasOwnProperty.call(sortedAssiblyObj, key)) {
  //           const item = sortedAssiblyObj[key];
  //           tableData  = [...tableData, ...this.assemblyHeaderAndSummaryTotal(item)]
  //       }
  //   }
  //   return tableData;
  // }

  createTableDataFromSortedAssiblyObj = (sortedAssiblyObj) => {
    let tableData = [];
    for (const key in sortedAssiblyObj) {
      if (Object.hasOwnProperty.call(sortedAssiblyObj, key)) {
        const item = sortedAssiblyObj[key];
        tableData = [...tableData, item.headerObj, item.emptyRowObj, ...item.data, item.emptyRowObj, item.totalObj, item.emptyRowObj,]
      }
    }
    return tableData;
  }

  createTable(data) {
    const sortedAssiblyObj = this.sortAsy(data);
    const tableData = this.createTableDataFromSortedAssiblyObj(sortedAssiblyObj);
    return tableData;
  }


  showWarningPopUp(value, msg) {
    if (!value) {
      this.ErrorDescription = msg;
      this.PopupMessageModal.show();
      return;
    }
  }

  generatePOReport() {
    if (!this.model.reporttype.value) {
      this.ErrorDescription = 'Please select report type.';
      this.PopupMessageModal.show();
      return;
    }
    this.refreshTable = false;
    let param = {
      reporttype: this.model.reporttype.value
    }

    const endpoint = this.configurationsetting.getBaseUrl() + 'reports/GetPOReports';

    this.commonService.getWithParams(endpoint, param).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.firstTableShow = true;
        const items = successResp['Data'][0]['@Oritbl'];

        this.tableconfig = {};
        this.tableheaders = [];
        this.tabledata = [];
        setTimeout(() => { this.refreshTable = true; }, 20);
        this.tableconfig = items['tableconfig'];
        this.tableheaders = items['tableheaders'];
        
        let indx = 1;
        for (let i = 0; i < items['tabledata'].length; i++) {
          const item = items['tabledata'][i];
          item['pono'] = item['documentno'];
          let prno =  items['tabledata'][i]['PrNo'];
          if(i==0)items['tabledata'][i]['SrNo'] = indx++;
          if(i > 0){
              if(items['tabledata'][i-1]['PrNo'] == prno){
                items['tabledata'][i]['documentno'] = ' ';
              }else{
                items['tabledata'][i]['SrNo'] = indx++;
              }
            }
          item['Qty'] == 0 ? item['Qty'] = '-' : item['Qty'];
          item['qtybilled'] == 0 ? item['qtybilled'] = '-' : item['qtybilled'];
          item['qtybalance'] == 0 ? item['qtybalance'] = '-' : item['qtybalance'];
          item['openingQtyBilled'] == 0 ? item['openingQtyBilled'] = '-' : item['openingQtyBilled'];
          item['duringcreate'] == 0 ? item['duringcreate'] = '-' : item['duringcreate'];
        }
        this.tabledata = items['tabledata'];
        
        this.createObjForPdf();
        this.tableShown = true;
        this.tabledata.length ? this.isClicked = false : this.isClicked = true;
        
        this.tableconfig['filenameprefix'] = this.reportNameObj[this.model.reporttype.value]['filenameprefix']
      }
    }, err => { })

  }

  singleClick(data) {
    
    if (this.model.reporttype.value == 'quantity' || this.model.reporttype.value == 'wip' || this.model.reporttype.value == 'spareRecieptDispatch'
      || this.model.reporttype.value == 'DetailSubAssemblyReportDispatch' || this.model.reporttype.value == 'detailSubAssemblyReportDispatchEngg'
      || this.model.reporttype.value == 'pendingPoReport'  || this.model.reporttype.value == 'challanAgeingDcInwardReport'  || this.model.reporttype.value == 'challanAgeingSpareInwardReport') {
      return;
    }

    if (this.model.reporttype.value == 'jobHistoryReport' || this.model.reporttype.value == 'spareWiseInOutReport') {
      let component;
      let id =  this.model.reporttype.value == 'spareWiseInOutReport' ? data['row']['primaryTrnxNo'] : data['row']['trnxid'];    
      if(!id) return;
      if (data['row']['trnxtype'] == 'DC Inward') {
        component = DeliveryChallanInwardComponent;
      } else if (data['row']['trnxtype'] == 'JobSheet' || data['row']['trnxtype'] == 'Job Card') {
        component = JobSheetComponent;
      } else if (this.model.reporttype.value == 'jobHistoryReport' && (data['row']['trnxtype'] == 'Outward' || data['row']['trnxtype'] == 'Rejected Outward')) {
        if (data['row']['outwardtype'] == 'DO') {
          component = DcOutwardComponent;
        } else if (data['row']['outwardtype'] == 'RD') {
          component = RoutineDcComponent;
        } else if (data['row']['outwardtype'] == 'RO') {
          component = RejectedOutComponent
        }
      } else if (data['row']['trnxtype'] == 'Invoice' || data['row']['trnxtype'] == 'Rejected Invoice') {
        component = InvoiceComponent
      } else if (data['row']['trnxtype'] == 'Inward') {
        component = SpareInwardComponent
      } else if (this.model.reporttype.value == 'spareWiseInOutReport' && data['row']['trnxtype'] == 'Outward') {
        component = SpareOutwardComponent
      } else if (data['row']['trnxtype'] == 'Issue') {
        component = SpareIssuedComponent
      } else if (data['row']['trnxtype'] == 'Receipt') {
        component = SpareRecieptComponent
      }

      const ngbModalRef = this.ngbModal.open(component, { windowClass: "item-master-modal-pop", backdrop: 'static' });
      ngbModalRef.componentInstance.isOpenFromReport = true;
      ngbModalRef.componentInstance.transactionID = id;

      ngbModalRef.result.then((result) => {
        if (result && result?.status == "close") {

        }
      });
    } else {

      let row = data['row'];
      let endpoint = '';
      let param = {};
      if (this.model.reporttype.value == 'itemWiseRecieptDispatchReport' || this.model.reporttype.value == 'itemWiseRecieptDispatchNonBlanketReport') {
        endpoint = this.configurationsetting.getBaseUrl() + 'reports/GetInternalItemWiseRecieptDispatchReport';
        if(!row['ItemNo']) return;
        param = {
          reporttype: this.model.reporttype.value,
          itemNo: row['ItemNo'],
          fromdate: this.model.fromdate.value,
          todate: this.model.todate.value
        }
      } else  if (this.model.reporttype.value == 'challanAgeingDcInwardReport') {
        endpoint = this.configurationsetting.getBaseUrl() + 'reports/challanAgeingInwardJob';
        param = {
          reporttype: this.model.reporttype.value,
          trnxid: row['DcInwardSrNo'],
          days:this.model.days.value
        }
      }else {
        if(!row['PrNo']) return;
        if(row['duringcreate'] == 0){
          alert("No transaction for this document.")
          return;
        } 
        endpoint = this.configurationsetting.getBaseUrl() + 'reports/getInternalPOReport';
        param = { poreportid: row['PrNo'], subAssyNo:row['ItemSrNo'], poreporttype: this.model.reporttype.value }
      }
      this.commonService.getWithParams(endpoint, param).subscribe(successResp => {
        if (successResp['errorCode'] == "000000") {
          let items = {};
          if (this.model.reporttype.value == 'itemWiseRecieptDispatchReport' || this.model.reporttype.value == 'itemWiseRecieptDispatchNonBlanketReport' || this.model.reporttype.value == 'challanAgeingDcInwardReport') {
            items = successResp['Data'];
            items['tabledata'].forEach((item, i) => {
              item['SrNo'] = i+1;
              if (item['trnxtype'] == 'RD' || item['trnxtype'] == 'DO' || item['trnxtype'] == 'RO') {
                item['Releases'] = '';
                item['CDSSJobNo'] = '';
              }
              if (item['trnxtype'] == 'RD') item['trnxtype'] = 'Routine';
              if (item['trnxtype'] == 'DO') item['trnxtype'] = 'Outward';
              if (item['trnxtype'] == 'RO') item['trnxtype'] = 'Rejected';
            })
          } else {
            items = successResp['Data'];
            this.poNoForPoDetail = row['pono'] ? row['pono'] : '';
            this.partNoForPoDetail = row['PartNo'] ? row['PartNo'] : '';
            this.nameForPoDetail = row['Description'] ? row['Description'] : '';
          }
          this.internaltableconfig = {};
          this.internaltableheaders = [];
          this.internaltabledata = [];
          this.internaltabledata.length ? this.isClicked = false : this.isClicked = true;

          this.internaltableconfig = items['tableconfig'];
          this.internaltableheaders = items['tableheaders'];
          this.internaltabledata = items['tabledata'];

          this.tableShown = false;
        }
      }, err => { })
    }

  }

  singleClickInternalTbl(eve) {
    let id;
    if (this.model.reporttype.value == 'itemWiseRecieptDispatchReport' || this.model.reporttype.value == 'itemWiseRecieptDispatchNonBlanketReport') {
      id = eve['row']['trnxid'];
    }else{
      id = eve['row']['DCOutSrNo'];
    }
    if(!id) return;

    let component;
    if (eve['row']['outwardtype'] == 'RD' || eve['row']['trnxtype'] == 'Routine') {
      component = RoutineDcComponent;
    } else if (eve['row']['outwardtype'] == 'DO' || eve['row']['trnxtype'] == 'Outward') {
      component = DcOutwardComponent;
    } else if (eve['row']['outwardtype'] == 'RO' || eve['row']['trnxtype'] == 'Rejected') {
      component = RejectedOutComponent;
    }else if(eve['row']['trnxtype'] == 'Inward'){
      component = DeliveryChallanInwardComponent;
    }


    const ngbModalRef = this.ngbModal.open(component, { windowClass: "item-master-modal-pop", backdrop: 'static' });
    ngbModalRef.componentInstance.isOpenFromReport = true;
    ngbModalRef.componentInstance.transactionID = id;

    ngbModalRef.result.then((result) => {
      if (result && result?.status == "close") {

      }
    });


  }

  createObjForPdf() {
    this.reportNameObj = {
      'quantity': {
        'pdfname': 'Spare Quantity Report',
        'pdfcolwidth': ["5%", "15%", "15", "30%", "10%", "10%", "10%"],
        'filenameprefix':'Spare_Quantity_Report'
      },
      'wip': {
        'pdfname': 'Spare WIP Report',
        'pdfcolwidth': ["5%", "15%", "15", "30%", "10%", "10%", "10%"],
        'filenameprefix':'Spare_WIP_Report'
      },
      'spareRecieptDispatch': {
        'pdfname': 'Spare Reciept Dispatch Report',
        'pdfcolwidth': "*",
        'fromdate': this.model.fromdate.value,
        'todate': this.model.todate.value,
        'filenameprefix':'Spare_Reciept_Dispatch_Report'
      },
      'spareWiseInOutReport': {
        'pdfname': 'Spare Wise Inward Outward Report',
        'pdfcolwidth': "*",
        'fromdate': this.model.fromdate.value,
        'todate': this.model.todate.value,
        'filenameprefix':'Spare_Wise_Inward_Outward_Report'
      },
      'itemWiseRecieptDispatchReport': {
        'pdfname': 'Item Wise Reciept Dispatch Report',
        'pdfcolwidth': "*",
        'fromdate': this.model.fromdate.value,
        'todate': this.model.todate.value,
        'filenameprefix':'Item_Wise_Reciept_Dispatch_Report'
      },
      'itemWiseRecieptDispatchNonBlanketReport': {
        'pdfname': 'Item Wise Reciept Dispatch Report (Non Blanket)',
        'pdfcolwidth': "*",
        'fromdate': this.model.fromdate.value,
        'todate': this.model.todate.value,
        'filenameprefix':'Item_Wise_Reciept_Dispatch_Report_Non_Blanket'
      },
      'jobHistoryReport': {
        'pdfname': 'Job History Report',
        'pdfcolwidth': ["10%", "20%", "30%", "15%", "10%", "15%"],
        'headerdata': {
          'jobno': this.model.jobno.value,
          'itemno': this.itemNameForJob,
          'description': this.DescriptionForJob,
          'model': this.ModelNameForJob,
          'esn': this.ESNForJob,
          'customer': this.esncustomerForJob,
          'engtype': this.EngTypeForJob,
          'filenameprefix':'Job_History_Report'
        }
      },
      'DetailSubAssemblyReportDispatch': {
        'pdfname': 'Detail Sub Assembly Report Dispatch',
        'pdfcolwidth': '*',
        'fromdate': this.model.fromdate.value,
        'todate': this.model.todate.value,
        'filenameprefix':'Detail_Sub_Assembly_Report_Dispatch'
      },
      'detailSubAssemblyReportDispatchEngg': {
        'pdfname': 'Detail Sub Assembly Report Dispatch (Engg)',
        'pdfcolwidth': '*',
        'fromdate': this.model.fromdate.value,
        'todate': this.model.todate.value,
        'filenameprefix':'Detail_Sub_Assembly_Report_Dispatch_Engg'
      },
      'poDetailsReport': {
        'pdfname': 'PO Details Report',
        'pdfcolwidth': "*",
        'filenameprefix':'PO_Details_Report'
      },
      'pendingPoReport': {
        'pdfname': 'Pending PO Report',
        'pdfcolwidth': "*",
        'filenameprefix':'Pending_PO_Report'
      },
      'challanAgeingDcInwardReport':{
        'pdfname': 'Challan Ageing Dc Inward Report',
        'pdfcolwidth': "*",
        'filenameprefix':'Challan_Ageing_Dc_Inward_Report'
      },
      'challanAgeingSpareInwardReport':{
        'pdfname': 'Challan Ageing Spare Inward Report',
        'pdfcolwidth': "*",
        'filenameprefix':'Challan_Ageing_Spare_Inward_Report'
      }
    }
  }

  setReportName(event) {
    this.reportName = this.reportNameObj[event.target.value];
    this.firstTableShow = false;
    this.model.todate.value = '';
    this.model.fromdate.value = '';
    this.model.days.value = '';
    this.SpareInOutCheckedObj = {
      inward: true,
      outward: true,
      issue: true,
      receipt: true,
      jobcard: true,
    }
  }

  openDcInwardComponent() {
    const ngbModalRef = this.ngbModal.open(DeliveryChallanInwardComponent, { windowClass: "item-master-modal-pop", backdrop: 'static' });
    ngbModalRef.componentInstance.isOpenFromReport = true;

    ngbModalRef.result.then((result) => {
      if (result && result?.status == "close") {

      }
    });
  }

  openJobsheetComponent() {
    const ngbModalRef = this.ngbModal.open(RoutineDcComponent, { windowClass: "item-master-modal-pop", backdrop: 'static' });
    ngbModalRef.componentInstance.isOpenFromReport = true;

    ngbModalRef.result.then((result) => {
      if (result && result?.status == "close") {

      }
    });
  }

  FromDateChange(date) {
    if (date)
      this.minToDate = date;
  }

  ToDateChange(date) {
    if (date)
      this.maxFromDate = date
  }

}

