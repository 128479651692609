import { Component, Input, OnInit, ViewChild } from '@angular/core';
// import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonServicesService } from '../../../services/common-services.service';
import { SubmitFormService } from '../../../services/submit-form.service';
import { ApiService } from '../../../services/api.service';
import { HelperService } from '../../../services/helper.service';
import { ConfigurationSetting } from '../../../appconfig';
import { DatePipe, Location } from '@angular/common';
import { SystemParamsService } from '../../../services/system-params.service';
import { ToastService } from '../../../services/toast.service';
import { DC_OUTWARD_CONFIRM_PO_TABLE_JSON, DC_OUTWARD_DETAILED_TABLE_JSON, DC_OUTWARD_LEFT_SIDE_TABLE_JSON, DC_OUTWARD_SELECTITEM_TABLE_JSON } from './dc-outwardgtconfig';
import { DomSanitizer } from '@angular/platform-browser';
import { log } from 'console';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var $;
@Component({
  selector: 'app-dc-outward',
  templateUrl: './dc-outward.component.html',
  styleUrls: ['./dc-outward.component.scss']
})
export class DcOutwardComponent implements OnInit {
  @ViewChild('ShowSelectItemPopup', { static: false }) public ShowSelectItemPopup;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('showPrintPopupModal', { static: false }) public showPrintPopupModal;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('DeletePopupMessageModal', { static: false }) public DeletePopupMessageModal;
  @ViewChild('showPopupoSelectConfirmPo', { static: false }) public showPopupoSelectConfirmPo;
  @ViewChild('printPopup', { static: false }) public printPopup;
  @ViewChild('showPrintESNPopupModal', { static: false }) public showPrintESNPopupModal;

  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;

  selectedPoNumber: boolean = false;
  isFreezedDcOut: boolean = false;
  displayDcOutwardSubTable: boolean = false;
  selectedDate: any = [];
  outwardType:string = 'DO' //DC Outward(Regular)
  fileName:string = "";
  dataLocalUrl: any;
  DcOutwardForm: boolean = true;
  showCloseBtn: boolean = true;
  showFormBtn: boolean = false;
  isPoUpOpen: boolean = false;
  saveBtnVisible: boolean = false;
  saveBtn: boolean = true;
  editBtn: boolean = false;
  addItemButton: boolean = false;
  CreateNew: boolean = false;
  cancelbtn: boolean = false;
  editDisableBtn: boolean = false;
  currentSelectedRow: number;
  deleteBtnDisable: boolean = false;
  isRowClicked: boolean = false;
  isPoPopUpOpen: boolean = false;
  customerNameArray: any[] = [];
  disabledSelectItemBtn:boolean = true;
  totalqty = 0
  dcoutwarduniqueno: number = 0;
  btnRow: boolean = false;
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  // maxDate = new Date();
  minDate = new Date();
  label: string = "Material DC Outward"
  deleteMsg: string = "";
  emptySearchField: boolean = true;
  dataForPrint:any = {};
  text = "Add New Dc";
  private backupData: any = { bindDataToFormAndTable: {} };
  tabledataforprocess: any[] = [];
  deletedArray: number[] = [];
  userRole: string = "";
  selectPoBtn:boolean = true;
  err: boolean = false;
  private backuptabledataforData: any = [];
  operation:string = '';
  priviousQty: number = 0;
  invoiceCreatedCount:number = 0;
  refreshDetailTable:boolean = true;

  model = {
    dcoutno: {
      valuType: 'String', value: '', label: "DC Outward ", isRequired: false,
      disable: true, maxLength: 4, err: "", element: { name: 'input', type: 'text' }
    },
    dcoutwardsrno: {
      valuType: 'String', value: '', label: "DC Outward ", isRequired: false,
      disable: true, maxLength: 4, err: "", element: { name: 'input', type: 'text' }
    },
    outwarddate: {
      valuType: 'Date', value: '', label: "Outward Date", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'date' }
    },
    customerno: {
      valuType: 'Number', value: '', label: "Cust No ", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    jmremark: {
      valuType: 'String', value: '', label: "Remark", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    pono: {
      valuType: 'Number', value: '', label: 'P.O', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    showpono: {
      valuType: 'String', value: '', label: "Remark", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  materialdcoutwardtableconfig: any = DC_OUTWARD_LEFT_SIDE_TABLE_JSON.tableconfig;
  materialdcoutwardtableheaders: any = DC_OUTWARD_LEFT_SIDE_TABLE_JSON.tableheaders;
  materialdcoutwardtabledata: any = [];

  dcoutlisttableconfig: any = DC_OUTWARD_DETAILED_TABLE_JSON.tableconfig;
  dcoutlisttableheaders: any = DC_OUTWARD_DETAILED_TABLE_JSON.tableheaders;
  dcoutlisttabledata: any[] = [];

  // Filter option in Popup
  selectitemstableconfig: any = DC_OUTWARD_SELECTITEM_TABLE_JSON.tableconfig;
  selectitemstableheaders: any = DC_OUTWARD_SELECTITEM_TABLE_JSON.tableheaders;
  selectitemstabledata: any[] = [];

  confirmPotableconfig: any = DC_OUTWARD_CONFIRM_PO_TABLE_JSON.tableconfig;
  confirmPotableheader: any = DC_OUTWARD_CONFIRM_PO_TABLE_JSON.tableheaders;
  confirmPotabledata: any = [];

  constructor(private ngbActiveModal: NgbActiveModal, private domSanitizer: DomSanitizer,private helper: HelperService, private submit: SubmitFormService, private apidata: ApiService, public commonService: CommonServicesService,
    private configurationsetting: ConfigurationSetting, private datepipe: DatePipe, public systemParamsService: SystemParamsService, private toastService: ToastService) { }

  ngOnInit() {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];

    this.getAllDcOutward();
    this.getCustomerName();
    this.getConfirmPoList();

    if (this.isOpenFromReport) {
      this.getByIdCall(this.transactionID)
    }
  }

  CreateNewDc() {
    this.emptySearchField = false;
    this.label = "Add Material DC Outward"
    this.operation = 'new';
    this.text = "Add DC"
    this.CreateNew = true;
    this.editBtn = false;
    this.saveBtn = true;
    this.btnRow = true;
    this.deleteMsg = "";
    this.selectPoBtn = false;
    this.cancelbtn = true;
    this.saveBtnVisible = true;
    this.addItemButton = true;
    this.dcoutlisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.selectedDate = new Date();
    this.model.outwarddate.value = this.selectedDate;
    this.model.jmremark.value = " "
    this.disabledSelectItemBtn = false;
    // if (this.userRole === "Admin") {
    //   this.selectedDate = "";
    // }
    this.dcoutlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.selectitemstableconfig['checkedfilter']['checkOnKey'] = 'none';
    this.selectitemstableconfig['checkedrecordscondition']['selectedFieldsArray'][0]['key'] = 'outquantity';
    this.selectitemstableheaders[0]['datatype'] = 'checkboxfilter';
    const ele = document.getElementById(`tr${"dcouttbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    this.dcoutlisttableheaders = this.commonService.enableEditForGt(this.dcoutlisttableheaders, "quantity", true);
    delete this.backupData.bindDataToFormAndTable;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  saveDcOutwardForm() {
    let data = this.submit.submitForm(this.model);
    // console.log(data);

    if (data) {
      this.saveBtn = true;
      let editedRow = [];
      console.log(this.tabledataforprocess);
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.tabledataforprocess[i]['quantity'] <= 0 || (this.tabledataforprocess[i]['quantity'] <= "" || null || undefined)) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }

        if (this.tabledataforprocess[i]?.rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i]);
        }
      }
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];
      // this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      data["criteria"]["pdffor"] = "dcregular";
      data["criteria"]["outwardtype"] = this.outwardType;

      let endpoint = 'transaction/insertOutwardTransaction';
      if (data["criteria"]['dcoutwardsrno']) {
        endpoint = 'transaction/updateOutwardTransaction';
        data["criteria"]["dcoutwardlist"] = editedRow;
        this.ErrorDescription = "Data Updated Successfully";
      } else {
        data["criteria"]["dcoutwardlist"] = this.tabledataforprocess;
        this.ErrorDescription = "Data Inserted Successfully";
      }

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.dcoutwarduniqueno = res['Data']
          this.saveBtn = true;
          this.deleteBtnDisable = false;
          this.addItemButton = false;
          this.text = "Add New DC";
          this.cancelbtn = false;
          this.CreateNew = false;
          this.saveBtnVisible = false;
          this.editBtn = true;
          this.commonService.diasbleFormFields(this.model);
          this.dcoutlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.dcoutlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcoutlisttableheaders), "quantity", false);
          this.getAllDcOutward();

          this.PopupMessageModal.show();
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  alertDeleteConfirmation(){
    if(this.invoiceCreatedCount > 0){
      this.ErrorDescription = 'Can not delete this transaction. Because invoice has been created for this transaction.';
      this.PopupMessageModal.show();
    }else{
      this.deleteDcOutwardForm()
    }
  }

  deleteDcOutwardForm() {
    // console.log("delete");

    let data = this.submit.submitForm(this.model);
    // console.log(data);

    if (data && data["criteria"]["dcoutwardsrno"]) {

      data["criteria"]["dcoutwardlist"] = this.tabledataforprocess;

      let items: any = {};
      items.dcoutwardsrno = data["criteria"]["dcoutwardsrno"];
      items.customerno = data["criteria"]["customerno"];
      items.type = this.outwardType;
      // console.log(this.outwardType, items);


      items.delLineItems = [];
      data["criteria"]["dcoutwardlist"].forEach((ele) => {
        delete ele?.qty;
        if (ele?.SrNo) {
          items.delLineItems.push(ele?.SrNo);
        }
      })

      data["criteria"] = items;

      let endpoint = 'transaction/deleteOutwardTransaction';

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('showDeleteModal')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.clearAll(false);

          this.getAllDcOutward();

          this.ErrorDescription = "Record Deleted Successfully";
          this.DeletePopupMessageModal.show();
        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }


  bindDataToFormAndTable(modal, flag: boolean = true) {
    this.label = "Display Material DC Outward"
    // console.log("modal", modal, flag);

    if (this.model.jmremark.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.refreshDetailTable = false;

    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    this.model.dcoutno.value = modal['dcoutno'];
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    this.invoiceCreatedCount = modal['invoicemadecount']
    if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.dcoutlisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"dcouttbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }
    this.dcoutlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.dcoutlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcoutlisttableheaders), "quantity", false);
    this.saveBtn = true;
    this.dcoutlisttabledata = modal['dcoutwardlist']?.length ? modal['dcoutwardlist'] : [];

    let newArr = this.dcoutlisttabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.dcoutlisttabledata = newArr;
    this.backuptabledataforData = this.helper.removeRefrence(this.dcoutlisttabledata)
    setTimeout(() => {
    this.refreshDetailTable = true;
    }, 10);
    // console.log(newArr);
    this.editBtn = true;
    this.addItemButton = false;
    this.cancelbtn = false;
    this.deleteBtnDisable = false;
    this.CreateNew = false;
    this.editDisableBtn = false;
    this.commonService.diasbleFormFields(this.model);

    this.isRowClicked = false;
    this.selectPoBtn = true;

  }

  selectItemPopUp() {
    this.displayJobNoWise();
    this.isPoPopUpOpen = true;
    this.ShowSelectItemPopup.show();
  }


  getAllDcOutward() {
    let params = { "formname": this.outwardType };

    const endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getAllMaterialOutward';
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];

        this.materialdcoutwardtabledata = []
        this.materialdcoutwardtabledata = items;

        for (let i = 0; i < items.length; i++) {
          if (items[i]['dcoutwardsrno'] == this.dcoutwarduniqueno || items[i]['dcoutwardsrno'] == this.model.dcoutwardsrno.value) {
            // console.log("Index", items[i], i);
            this.model.dcoutwardsrno.value = items[i]['dcoutwardsrno'];
            setTimeout(() => { this.getByIdCall(items[i]['dcoutwardsrno']);}, 900);
            if (!this.isOpenFromReport) {
            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trdcouttbl1' + i).style.backgroundColor = "#7de4f0";
            }, 900);
          }
            break;
          }
        }

        // this.isPoUpOpen = true;

      }
    }, err => { })
  }

  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.outwarddate.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.materialdcoutwardtabledata, modal.rowindex, this.currentSelectedRow, "dcouttbl1");

    let row = modal['row'];
    // console.log(row);

    let dcoutno = row['dcoutwardsrno'];
    this.getByIdCall(dcoutno);
  }

  getByIdCall(id) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getDcOutwardById";
    let params = { dcoutwardsrno: id, outwardtype: this.outwardType };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data'];
        this.bindDataToFormAndTable(item);
        this.dataForPrint = item;

      }
    }, (error) => {
      console.log(error);
    });
  }

  printPdf(){
    this.showPrintESNPopupModal.show();
    // this.downloadPdf(this.dataForPrint);

  }

  downloadPdf(value) {

    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/generateoutwardpdf";
    let body = { "criteria": { "transId": this.model.dcoutwardsrno.value, "printEsnDetails":value, printPdfFor:this.outwardType } }

    this.commonService.postMethod(apiUrl, body).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        // console.log("success");
        this.fileName = successResp['Data']['filename'];

        this.downloadFile(this.fileName)
      }
      else {
        this.ErrorDescription = "Something went wrong, please contact admin";
      }
    },
      err => {
        this.ErrorDescription = "Something went wrong, please contact admin";
      });
  }

  downloadFile(filename: string) {
    let body = { "filename": filename }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/downloadpdf";
    this.commonService.getFile(apiUrl, body) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        // saveAs(blob, this.fileName);
        let a = document.createElement('a');

        let file = new Blob([blob], { type: 'application/pdf' });
        // console.log("file ", file);

        let fileURL = URL.createObjectURL(file);

        this.dataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
        a.download = this.fileName;
        setTimeout(() => {
          this.printPopup.show();
        }, 200);

        this.fileName = "";
      })
      .catch((err) => {
        console.log("Something went wrong");
      });

  }
  deepCopyForDcoutItems:any = {}

  displayJobNoWise() {
    let params = { "formname": "regular" };
    let endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getMaterialOutwardLineItem';
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.selectitemstabledata = [];
        this.selectitemstabledata = items;
        this.deepCopyForDcoutItems = this.helper.removeRefrence(items);

        this.filterItemsTableIfExistingSubTable();
      }
    }, err => { })
  }

  selectItemsDataBind(modal) {
    let row = modal['row'];
    this.model = this.commonService.setFromKeysValues(this.model, row);
    this.dcoutlisttableconfig['summary'] = ['sum'];

    // console.log(row.itemsrno);
    if (row.itemsrno) {
      this.getDetailsForOutward(row.itemsrno);
    }
    this.ShowSelectItemPopup.hide();
  }
  getDetailsForOutward(itemsrno: any) {
    let params = { itemNo: itemsrno, "formname": "regular" };
    // console.log(params);
    this.dcoutlisttableconfig['summary'] = ['sum'];

    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/displayMaterialOutwardList";

    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']["dcoutwardlist"];

        this.dcoutlisttabledata = item;


        this.dcoutlisttabledata.forEach((ele) => {
          ele['quantity'] = 0;
        })

      }
    }, (error) => {
      console.log(error);
    });

  }
  filterItemsTableIfExistingSubTable(){
    let preparedarr = [];
    let inwardno;
    preparedarr = this.deepCopyForDcoutItems.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < this.dcoutlisttabledata.length; ind++) {
        if (this.dcoutlisttabledata[ind]['JobCardSrNo'] == row['JobCardSrNo']) {
          inwardno = this.dcoutlisttabledata[ind]['dnno']
          recavailable = false;
        }
      }
      return recavailable;
    });
    this.selectitemstabledata = []
    this.selectitemstabledata = preparedarr

    if(this.model.customerno.value){
      let filterwithdnno = preparedarr.filter(row=>{
        if(this.model.customerno.value) {
          this.selectitemstableconfig['checkedrecordscondition']['selectedFieldsArray'][0]['key']  = 'checkbox';
          this.selectitemstableheaders[0]['datatype'] = 'checkbox';
          return row.customerNo === this.model.customerno.value;
        }
      })
      this.selectitemstabledata = filterwithdnno;
    }else{
      this.selectitemstabledata = preparedarr;
    }
  }

  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }

  selectDcItemsList(itemdata: any) {
    this.saveBtn = false;
    this.saveBtnVisible = true;

    let deepCopy = this.helper.removeRefrence(this.tabledataforprocess);
    itemdata = this.helper.removeRefrence(itemdata);

    console.log("PO NO", this.model.pono.value);


    for (let i = 0; i < itemdata.length; i++) {
      for (let j = i; j < itemdata.length; j++) {
        if (itemdata[i].customerNo == itemdata[j].customerNo) {
          if (this.model.customerno.value && this.model.customerno.value !== itemdata[i].customerNo) {
            this.toastService.showToastMsg('warning', 'Warning', "Can not select different customer data");
            return;
          }
          this.model.customerno.value = itemdata[i].customerNo;
        } else {
          this.toastService.showToastMsg('warning', 'Warning', "Can not select different customer data");
          return;
        }
    //     if (itemdata[i].pono == itemdata[j].pono) {
    //       if (this.model.pono.value && this.model.pono.value !== itemdata[i].pono) {
    //         this.toastService.showToastMsg('warning', 'Warning', "Can not select different PO.");
    //         return;
    //       }
    //       this.model.pono.value = itemdata[i].pono;

    // console.log("PO NO", this.model.pono.value);

    //     } else {
    //       this.toastService.showToastMsg('warning', 'Warning', "Can not select different PO.");
    //       return;
    //     }
      }

     let {
        dcsrno,dcno,Releases,issuedate,dnno,pono,itemsrno,itemno,itemname,jmdescription,modelname,quantity,maxquantity,invoicependingqty,esn,customerNo,customername,customernm,jmremark:remark,engtypejeop,esncustomer,jobno,refrenceno, SrNo1, SrNo2, JobCardSrNo
    } = itemdata[i]
      deepCopy.push({dcsrno,dcno,Releases,issuedate,dnno,pono,itemsrno,itemno,itemname,jmdescription,modelname,quantity:quantity,inwardmaxqtyfordc:quantity,hiddenoutquantity:quantity, maxquantity,invoicependingqty:0,esn,customerNo,customername,customernm,remark,engtypejeop,esncustomer,jobno,refrenceno, SrNo1, SrNo2, JobCardSrNo});
    }
    this.dcoutlisttabledata = [];

    this.dcoutlisttabledata = deepCopy;
    console.log(this.dcoutlisttabledata);
   this.cancelPopup('ShowSelectItemPopup');
  }
  obj:any = {}
  checkSingleRecord(data){
    let row = data['row']
    data['filterobj'] = {
      action : "filter",
      datatype: "string",
      row:row,
      selectedtext:row['customername'],
      targetcolumn:'customername'
    }
    if(row['checked']){
      this.obj[data] = row['customerNo']
    }
    if(!row['checked']){
      this.model.pono.value = null;
    }

    // console.log(data['filterobj']);

  }

  updatedtabledata(data: any) {

    data.forEach((item, i) => {
      if (this.operation == 'edit') {
        item['rowmodifiedstatus'] = 'M';
      }
      if (this.backuptabledataforData[i] && this.operation === 'edit') {
        // console.log(this.backuptabledataforData[i]);

        this.priviousQty = this.backuptabledataforData[i]["invoicependingqty"]

        const prevQtyInHand = this.backuptabledataforData[i]["inwardmaxqtyfordc"]
        
        item['totalqty'] = item['oldquantity'] + item['inwardmaxqtyfordc'];

        // console.log("item['totalqty']", item['totalqty']);
        if(item['quantity']>item['totalqty']){
          item['quantity'] = this.backuptabledataforData[i]["quantity"];
        }

      }else{
        item['totalqty'] = item['maxquantity']
        if(item['quantity']>item['totalqty']){
          item['quantity'] = item["hiddenoutquantity"];
        }
      }


    })

    this.tabledataforprocess = data;

  }

  rowChangeOrUpdate(data) {
    // console.log("totalqty ", data?.row?.totalqty);
    if (this.commonService.userRole != 'Admin' && data['keyName'] == 'quantity' && data?.row?.isJobSheetFreezed == 'Y') {
      this.ErrorDescription = `Can not edit quantity for this item, because jobsheet has been freezed for this item. Please contact Admin to edit quantity.`;
      this.errorMessageModal.show();
      return;
    }

    data.row.invoicemadeqty = data?.row?.invoicemadeqty ? data?.row?.invoicemadeqty : 0;
    if (data['keyName'] == 'quantity' && data?.row?.quantity > data?.row?.totalqty || data?.row?.quantity < 0) {
      data.row.rowmodifiedstatus = 'M';
      this.ErrorDescription = `Enter valid Quantity. Can not enter Quantity greater than ${data?.row?.totalqty}`;
      this.ErrorHeader = "Quantity must be less than " + data?.row?.maxquantity;
      this.ErrorObj = data.row;
      data.row.quantity = data.row.hiddenoutquantity;
      this.errorMessageModal.show();
    }else if (this.operation == 'edit' && data['keyName'] == 'quantity' && data?.row?.quantity > data?.row?.maxquantity) {
      data.row.rowmodifiedstatus = 'M';
      this.ErrorDescription = `Invoice of ${data?.row?.invoicemadeqty} has created. so qty should be greater than ${data?.row?.invoicemadeqty}`;
      data.row.quantity = data.row.hiddenoutquantity;
      this.errorMessageModal.show();
    }else if (this.operation == 'edit' && data['keyName'] == 'quantity' && data?.row?.quantity < data?.row?.invoicemadeqty) {
      data.row.rowmodifiedstatus = 'M';
      this.ErrorDescription = `Invoice of ${data?.row?.invoicemadeqty} has created. so qty should be greater than ${data?.row?.invoicemadeqty}`;
      data.row.quantity = data.row.hiddenoutquantity;
      this.errorMessageModal.show();
    }


  }

  editForm() {
    this.label = "Edit Material DC Outward"
    this.operation = 'edit';
    this.dcoutlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.dcoutlisttableheaders = this.commonService.enableEditForGt(this.dcoutlisttableheaders, "quantity", true);
    this.deleteBtnDisable = true;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.cancelbtn = true;
    this.addItemButton = true;
    this.editDisableBtn = true;
    this.editBtn = false;
    this.CreateNew = true;
    this.selectPoBtn = true;
    this.disabledSelectItemBtn = false;
    this.commonService.enableFormFields(this.model);
  }

  clearAll(flag: boolean = true) {
    this.label = "Material DC Outward"
    this.operation = 'new';

    // console.log('editBtn', this.editBtn, 'editDisableBtn', this.editDisableBtn);

    this.commonService.clearForm(this.model);
    this.dcoutlisttabledata = [];
    this.addItemButton = false;
    this.text = "Add New DC";
    this.cancelbtn = false;
    this.CreateNew = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.btnRow = true;
    this.deleteMsg = ""
    this.commonService.diasbleFormFields(this.model);
    this.dcoutlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.disabledSelectItemBtn = true;
    const ele = document.getElementById(`tr${"dcouttbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;
    }

  }

  cancelPopup(popup) {
    // this.isPoUpOpen = false;
    switch (popup) {
      case 'ShowSelectItemPopup':
        this.ShowSelectItemPopup.hide();
        this.isPoPopUpOpen = false;
        break;
      case 'showDeleteModal':
        this.showDeleteModal.hide()
        break;
      case 'backendErrorMessageModal':
        this.backendErrorMessageModal.hide();
        break;
      case 'showPrintPopupModal':
        this.showPrintPopupModal.hide();
        break;
      case 'qtyerr':
        this.errorMessageModal.hide();
        break;
      case 'alert':
        this.showItemAlert.hide();
        break;
      case 'msg':
        this.PopupMessageModal.hide();
        // this.showPrintPopupModal.show();
        break;
      case 'del':
        this.DeletePopupMessageModal.hide();
        break;
    }
  }


  cancel() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
        // console.log("if clear");
      } else {
        this.clearAll(true);
      }
    }
  }

  deleteRow(event) {
    let row = event['row'];
    if((row['quantity'] != row['invoicependingqty']) && row['invoicemadeqty']){
      this.ErrorDescription = 'Can not delete this item. Because invoice has been created for this item.';
      this.PopupMessageModal.show();
      return;
    }
    this.tabledataforprocess.splice(event.rowindex, 1);
    if (!this.tabledataforprocess.length) {
      this.showItemAlert.show();
      this.tabledataforprocess = [];
      this.model.customerno.value = '';
      this.saveBtn = true;
      this.saveBtnVisible = false;
    } else {
      this.saveBtn = false;
      this.saveBtnVisible = true;
    }
    // console.log(id);
    const deepCopy = this.helper.removeRefrence(this.tabledataforprocess);
    this.dcoutlisttabledata = [];
    this.dcoutlisttabledata = deepCopy;
    this.pushInDeletedArray(event.row, 'SrNo');

  }


  pushInDeletedArray(row, key) {
    if(row[key]){
      this.deletedArray.push(row[key])
    }else{
      this.deletedArray = [];
    }
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  showPopupToSelectPo() {
    this.showPopupoSelectConfirmPo.show();
  }

  bindPoNoAndGetItem(modal) {
    let row = modal['row'];
    // console.log(row);
    // this.model = this.commonService.setFromKeysValues(this.model, row);

    this.model.showpono.value = `${row.PoNo} - ${row.description}`;

    this.showPopupoSelectConfirmPo.hide();

  }

  getConfirmPoList() {
    let endpoint = 'transaction/getPoListByBlanket';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.confirmPotabledata = [];
        this.confirmPotabledata = items;
      }
    }, err => { })
  }


  isPrint() { }

  closeModal() {
    this.ngbActiveModal.close();
  }
}

