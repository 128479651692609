import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GtPlusModule } from '../component/gt-plus/gt-plus.module';
import { DataFetchingPopupComponent } from './data-fetching-popup/data-fetching-popup.component';



@NgModule({
  declarations: [DataFetchingPopupComponent],
  imports: [
    CommonModule,
    GtPlusModule,
    FormsModule
  ],
  exports:[GtPlusModule,]
})
export class SharedModule { }
