import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { ToastService } from './toast.service';
// import { ApiRequestsService } from './api-requests.service';
import { ValidateObjectService } from './validate-object.service';

@Injectable({
  providedIn: 'root'
})
export class SubmitFormService {

  errorDescription = "";
  public isFormSubmiting = false;
  constructor(private vo: ValidateObjectService, private router: Router,
    // private toastService: ToastService,
    // private apiRequestsService: ApiRequestsService
  ) { }
  validateData = (formObj) => {
    this.vo.validateDataObject(formObj);
    // console.log(JSON.stringify(this.vo.errArray));

    if (this.vo.errArray.length > 0) {
      this.errorDescription = this.vo.errArray[this.vo.errArray.length - 1];
      // console.log(this.vo.errArray);

      return false;
    }
    return true;
  }

  // submitForm(formObj, apiEndPoint: string, afterSuccessCB, afterFailureCB)
  submitForm(formObj) {
    // console.log(formObj);
    // console.log(formObj);

    // this.vo.errArray=[];
    if (!this.validateData(formObj)) {
      this.showErrorMessage();
      return false;
    } else {
    formObj = JSON.parse(JSON.stringify(formObj));
      let lv_formDataToSendInAPI = this.vo.generateKeyValuePairForFormObject(formObj);
      const data = { criteria: { data: lv_formDataToSendInAPI } };
      console.log(data);
      return { criteria: lv_formDataToSendInAPI  };
      // return this.apiRequestsService.postApiCall(apiEndPoint, { criteria: { data: lv_formDataToSendInAPI } });
    }
    // this.apiRequestsService.postApiCall(apiEndPoint, sendObject).subscribe(response => {
    //   if (response['errorCode'] == this.apiRequestsService.NO_ERROR) {
    //     afterSuccessCB();

    //   }

    // });


  }
  showErrorMessage() {
    // this.toastService.showErrorToastInArray(this.vo.errArray)
  }
  navigateToPath(path: string) {
    this.router.navigateByUrl(path);
  }
}
