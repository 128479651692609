export const LEFT_SIDE_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped sticky-header  horizontal-scroll table-lightblue table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "enablefreezepanes": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showonlysearch": true,
    "checkDeletedFlag": true,
    "checkDeletedFlagKey": "isDeleted",
    "fullheighttable":"fullheight-table"
  },
  tableheaders: [
    {
      "key": "sparechallanno",
      "display": "Challan No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-left",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "SpareInwardNo",
      "display": "Inward Sr No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-left",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "inwarddt",
      "display": "Inward Date",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "date",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata: []
}

export const DETAIL_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped sticky-header  horizontal-scroll table-lightblue table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "enablefreezepanes": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showMismatchRecord": true

  },
  tableheaders: [
    {
      "key": "partno",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "200px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qty",
      "display": "Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": [],
      "positive":true
    },
    {
      "key": "actualqty",
      "display": "Actual Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": [],
      "positive":true

    },
    {
      "key": "qtyinhand",
      "display": "Qty In Hand",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      // "formula": "actualqty+qty",
      "visiblecolm": true,
      "positive":true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "wip",
      "display": "WIP",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "remark",
      "display": "Remark",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "60px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
  ],
  tabledata: []
}

export const POPUP_ITEMS_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": true },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": true,
      "selectedFieldsArray": [
        {
          "key": 'qty',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    },
    "showcolumnlevelcheckbox":true
  },
  tableheaders: [
    // {
    //   "key": "checkbox,",
    //   "display": "",
    //   "editable": false,
    //   "sorting": true,
    //   "allowaggregation": false,
    //   "enablehide": true,
    //   "visiblecolm": true,
    //   "decimaldigit": '',
    //   "datatype": "checkbox",
    //   "thstyle": {  "text-align": "center", "width": "30px" },
    //   "tdalignment": "",
    //   "menulist": [{ "label": "Filter On", "action": "filter" }],
    //   "summary": []
    // },
    {
      "key": "partno",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "110px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qty",
      "display": "Quantity",
      "editable": true,
      "sorting": true,
      "allowaggregation": false,
      "positive":true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "40px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "actualqty",
      "display": "Actual Qty",
      "editable": true,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qtyinhand",
      "display": "Qty In Hand",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "wip",
      "display": "WIP",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "positive":true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "right", "width": "100px" },
      "tdalignment": "hr-right vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },

  ],
  tabledata: []
}
