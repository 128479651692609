import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url='http://192.168.100.107:3009/'

  constructor(private http:HttpClient) { }

  postData(endpoint:string, data:any){
    // console.log(endpoint);

    return this.http.post(this.url+endpoint, data)
  }

  getData(endpoint:string){
    return this.http.get(this.url+endpoint)
  }

}
