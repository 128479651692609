import { Injectable, NgModule } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs'
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigurationSetting } from '../appconfig';
import { Subject } from 'rxjs';
import { CustomEvents } from '../component/gt-plus/events';
import { finalize, map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { SystemParamsService } from './system-params.service';
import { Router } from '@angular/router';

@Injectable()
export class CommonServicesService {

  exportAsConfig: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementIdOrContent: 'DeliveryChlnRpt', // the id of html/table element
  }

  public apiToken: string = "";
  public NavBars: any;
  public userRole: string = "";
  public btnControlMaster:any = {};
  public btnControlTrnx:any = {};
  public accessControl:any = {};
  public apiErrorDesc: string = "";
  private subject = new Subject<any>();
  public sendName: string = "";
  public baseURL = this.configurationsetting.getBaseUrl();
  public DB_Deep_Copy = {}

  /**testing*/
  private msgSource = new BehaviorSubject('default message');
  currentMsg = this.msgSource.asObservable();
  /**testing*/

  public charArray = ["!", "#", "^", "=", ";", ":", "{", "}"]

  constructor(private configurationsetting: ConfigurationSetting, private http: HttpClient, private exportAsService: ExportAsService, private customSrvc: CustomEvents, private datepipe: DatePipe,private systemParamsService:SystemParamsService,private route:Router) {
  this.baseURL = this.configurationsetting.getBaseUrl();

   }
    logout(){
      this.route.navigateByUrl("/login");
      sessionStorage.clear();
    }
  getWithoutParams(apiurl) {
    let ele = document.getElementById('loader-div');
    // console.log('ele', ele);
    ele.style.display = "block";
    apiurl = this.configurationsetting.getBaseUrl()+apiurl;
    var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });
    return this.http.get(apiurl, { headers: httpheaders }).pipe(map((res)=>{ this.apiErrorDesc =res['errorDescription'];
    if(this.apiErrorDesc == 'Invalid token.') this.logout();
    return res;}),finalize(() => {
      ele.style.display = "none";
      // console.log('[finalize] Called')
    }));
  }

  // getWithParams(apiurl, options) {
  //   return this.http.get(apiurl, options);
  // }

  // postMethod(apiurl, body, headers: HttpHeaders) {
  //   return this.http.post(apiurl, body);
  // }

  getMethod(apiurl) {
    const url = "assets/sample.json"
    let ele = document.getElementById('loader-div');
    // console.log('ele', ele);
    ele.style.display = "block";
    var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });
    return this.http.get(apiurl, { headers: httpheaders }).pipe(map((res)=>{ this.apiErrorDesc =res['errorDescription'];
    if(this.apiErrorDesc == 'Invalid token.') this.logout();
     return res;}),finalize(() => {
      ele.style.display = "none";
      // console.log('[finalize] Called')
    }));
  }

  getWithParams(apiurl, params) {
    let httpparams = new HttpParams();
    httpparams = httpparams.append("criteria", JSON.stringify(params));

    let ele = document.getElementById('loader-div');
    // console.log('ele', ele);
    ele.style.display = "block";
    var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });

    // if ((params && typeof params != "object") || (typeof params == "object" && Object.keys(params).length)) {

    //   if (typeof params == "object" && !params['criteria']) {
    //     httpparams = httpparams.append("criteria", params);
    //   }
    //   else {
    //     httpparams = httpparams.append("criteria", params['criteria']);
    //   }
    // }


    return this.http.get(apiurl, { params: httpparams, headers: httpheaders }).pipe(map((res)=>{ this.apiErrorDesc =res['errorDescription'];
    if(this.apiErrorDesc == 'Invalid token.') this.logout();
     return res;})
     ,finalize(() => {
      ele.style.display = "none";
      // console.log('[finalize] Called')
    }));
  }

  postMethod(apiurl, body,isBaseUrlGiven=false) {

    if(isBaseUrlGiven)
    apiurl = this.configurationsetting.getBaseUrl()+apiurl;

    let ele = document.getElementById('loader-div');
    // console.log('ele', ele);
    ele.style.display = "block";

    let httpparams = new HttpParams();

    var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });

    if ((body && typeof body != "object") || (typeof body == "object" && Object.keys(body).length)) {

      if (typeof body == "object" && !body['criteria']) {
        httpparams = httpparams.append("criteria", body);
      }
      else {
        httpparams = httpparams.append("criteria", body['criteria']);
      }
    }


    return this.http.post(apiurl, body, { headers: httpheaders }).pipe(map((res)=>{ this.apiErrorDesc =res['errorDescription'];
    if(this.apiErrorDesc == 'Invalid token.') this.logout();

     return res;}),finalize(() => {
      ele.style.display = "none";
      // console.log('[finalize] Called')
    }));
  }



  getGenericTableNForm(formname, tablename, baseurl): Observable<any> {
    if (navigator.onLine) {
      let ele = document.getElementById('loader-div');
      // console.log('ele', ele);
      ele.style.display = "block";

      let apiurl = baseurl + "Master/GetDynamicForms";
      let params = JSON.stringify({ "formname": formname, "tablename": tablename });
      let httpparams = new HttpParams();
      httpparams = httpparams.append("criteria", params);
      var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });

      return this.http.get(apiurl, { params: httpparams, headers: httpheaders }).pipe(map((res)=>{ this.apiErrorDesc =res['errorDescription'];
      if(this.apiErrorDesc == 'Invalid token.') this.logout();
       return res;}),finalize(() => {
        ele.style.display = "none";
        // console.log('[finalize] Called')
      }));

    }
    else {
      alert("Internet Connection is Not Available.");
    }
  }

  getGenericTable(tablename, baseurl): Observable<any> {
    if (navigator.onLine) {
      let ele = document.getElementById('loader-div');
      // console.log('ele', ele);
      ele.style.display = "block";

      let apiurl = baseurl + "Master/GetGenericTableFormAndData";
      let params = JSON.stringify({ "tblname": tablename });
      let httpparams = new HttpParams();
      httpparams = httpparams.append("criteria", params);
      var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });
      return this.http.get(apiurl, { params: httpparams, headers: httpheaders }).pipe(map((res)=>{ this.apiErrorDesc =res['errorDescription'];
      if(this.apiErrorDesc == 'Invalid token.') this.logout();
       return res;}),finalize(() => {
        ele.style.display = "none";
        // console.log('[finalize] Called')
      }));

    }
    else {
      alert("Internet Connection is Not Available.");
    }
  }

  checkUniqueNo(value, checkfor, baseurl) {
    if (navigator.onLine) {
      let ele = document.getElementById('loader-div');
      // console.log('ele', ele);
      ele.style.display = "block";

      let apiurl = baseurl + "Master/CheckUniqueValues";
      let params = JSON.stringify({ "value": value, "checkfor": checkfor });
      let httpparams = new HttpParams();
      httpparams = httpparams.append("criteria", params);
      var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });

      return this.http.get(apiurl, { params: httpparams, headers: httpheaders }).pipe(map((res)=>{ this.apiErrorDesc =res['errorDescription'];
      if(this.apiErrorDesc == 'Invalid token.') this.logout();
       return res;}),finalize(() => {
        ele.style.display = "none";
        // console.log('[finalize] Called')
      }))
    } else {
      alert("Internet Connection is Not Available.");
    }

  }

  getDynamicDropDownList(ddlname, baseurl) {
    if (navigator.onLine) {
      let apiurl = baseurl + "Master/GetDyanamicDDL";
      let params = JSON.stringify({ "ddlname": ddlname });
      let httpparams = new HttpParams();
      httpparams = httpparams.append("criteria", params);
      var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });

      return this.http.get(apiurl, { params: httpparams, headers: httpheaders }).pipe(map((res)=>{ this.apiErrorDesc =res['errorDescription'];
      if(this.apiErrorDesc == 'Invalid token.') this.logout();
       return res;}))

    } else {
      alert("Internet Connection is Not Available.");
    }
  }


  getFile(apiUrl, options) {
    var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });

    return this.http.get(apiUrl, {
      params: options,
      headers: httpheaders,
      responseType: 'blob' // very important that this is set
    })
  }

  downloadFilesReq(url: string, params: any = undefined) {
    let httpparams = new HttpParams();
    var httpheaders = new HttpHeaders({ 'authorization': 'Bearer ' + this.apiToken, "authfor": "W" });

    if (params)
      httpparams = httpparams.append("criteria", JSON.stringify(params));
    return this.http.get(this.baseURL + url, {
      params: httpparams,
      responseType: "blob",
      headers: httpheaders
    })
  }


  /* below method is to print starts here*/
  export() {
    // download the file using javascript method
    this.exportAsService.save(this.exportAsConfig, 'pdftotest').subscribe(() => {
      // save started
    });
  }
  printPage(id) {
    let printHTML = document.getElementById(id)["outerHTML"];
    let popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" media="print"/></head><body onload="window.print()">' + printHTML + '</html>');
    popupWinindow.document.close();
  }

  /* below method is to print ends here*/



  /**testing*/
  setFromKeysValues(formObj, FormData: any) {
    // console.log(formObj);
    Object.keys(FormData).forEach(key => {
      if (formObj[key]) {
        if (formObj[key]['valuType'] == 'Date' && FormData[key]) {
          // console.log(key,FormData[key], formObj[key].value);
          let d = new Date(FormData[key]);
          // formObj[key].value = FormData[key];
          formObj[key].value = d;
          // formObj[key].value =this.datepipe.transform(d,this.systemParamsService.ngDateFormatddMMy);
          // console.log(formObj[key].value,d);
        } else
          formObj[key]['value'] = FormData[key];
      }
    });
    // console.log(formObj);
    return formObj;
  }

  enableFormFields(formObj: any) {
    Object.keys(formObj).forEach((item) => {
      if (formObj[item] && item != 'isInValid') {
        formObj[item]['disable'] = false;
      }
    })
    return formObj;
  }


  diasbleFormFields(formObj: any) {
    Object.keys(formObj).forEach((item) => {
      if (formObj[item] && item != 'isInValid') {
        formObj[item]['disable'] = true;
      }
    })
    return formObj;
  }

  enableEditForGt(tableheader: any, colmkey: string, flag: boolean) {
    let empArray = [];
    for (let itemheaders of tableheader) {
      // console.log('Item header ', itemheaders['key']);
      // console.log('colm key ', colmkey);

      if (itemheaders['key'] === colmkey) {
        itemheaders['editable'] = flag;
      }
      empArray.push(itemheaders);
    }

    // tableheader = empArray;
    return empArray;
  }


  clearForm(form: any) {
    Object.keys(form).forEach((item) => {
      // console.log(item);
      if (typeof (form[item]) == 'object') {
        form[item].value = undefined;

        form[item]['err'] = ''
      }
    });
    // console.log(form);
  }

  identifySelectedRow(tabledata:any, rowindex, currentSelectedRow, tableid:string){
    tabledata.forEach((ele, index)=>{
      if(index == rowindex){
        // console.log(`tr${tableid+currentSelectedRow}`);

        const doc = document.getElementById(`tr${tableid+currentSelectedRow}`);


        if(doc?.style)
        doc.style.background = '#7de4f0';
      }else{

        const ele = document.getElementById(`tr${tableid+index}`);
        if(ele)
        ele.style.background = '';
      }
    });
  }

  addBlankRow(tableHeaders, tableid, extComnArr: any[] = []) {
    let blankRow = {};

    for (let index = 0; index < tableHeaders.length; index++) {
      const element = tableHeaders[index];

      if (element['datatype'] == "string") {
        blankRow[element['key']] = "";
      }
      else if (element['datatype'] == "number") {
        blankRow[element['key']] = 0;
      }
      else if (element['datatype'] == "dropdown") {
        blankRow[element['key']] = "";
      }
      else if (element['datatype'] == "date") {
        blankRow[element['key']] = null;
      }
    }

    for (let index = 0; index < extComnArr.length; index++) {
      const element = extComnArr[index];

      blankRow[element['key']] = element['defValue'];// Default Value

    }

    // this.tabledata.push(blankRow);
    this.customSrvc.publish(tableid, {
      "tableid": tableid, "actiontype": "update", "operation": "add", "data": blankRow
    });
  }

  setDecimalDigit(value, decimaldigit) {
    if (value) {
      value = +(value);
      const number = value.toFixed(decimaldigit);
      // console.log(number, value, typeof number);
      return number;
    } else {
      // console.log('de', value);

      return value
    }

  }

  filterSpareListTableIfExistingSubTable(deepCopy:any={}, tablelist:any[]=[], popupitems:any[]=[]) {
    let preparedarr = [];
    preparedarr = deepCopy.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < tablelist.length; ind++) {
        if (tablelist[ind]['partno'] == row['partno']) {
          recavailable = false;
        }
      }
      return recavailable;
    });
    console.log("preparedarr", preparedarr);

    popupitems = []
    popupitems = preparedarr;
    // console.log("popupitems", popupitems);
    return popupitems;
  }

  deleteExtraFlagsFromTable(arr:any[]=[]){
    arr.forEach((ele) => {
      delete ele['autoid'];
      delete ele['actualindex'];
      delete ele['rowindex'];
      delete ele['colindex'];
      delete ele['keyName'];
      delete ele['ptIndex'];
      delete ele['actioncolumn'];
      delete ele['quantityctrl'];
      delete ele['checked'];
    });

    return arr;
  }

  capitalize(str){
    let arr = str.split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    let str2 = arr.join(" ");
    return str2;
  }

}
