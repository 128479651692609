import { Directive, ElementRef, Renderer2, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[CustomEventlistener]'
})

export class EventlistenerDirective {
  @Output() emitEventData: EventEmitter<any> = new EventEmitter();
  @Input("referencevar") referencevar: any;
  hovertimer: any;
  constructor(private element: ElementRef,
    private renderer: Renderer2) {
    // console.log("hi........");
    setTimeout(() => {
      this.element.nativeElement.focus();
    }, 100);
  }

  @HostListener('keydown', ['$event']) keyDownEvent(event: Event) {
    // console.log("hhhhhhhh");
    this.emitEventData.emit(event)
  }

  @HostListener('mouseover') onMouseOut() {
    // console.log("------------------------");
    window.getSelection().removeAllRanges();
    this.element.nativeElement.blur();
    if (this.hovertimer) {
      clearTimeout(this.hovertimer);
    }
    this.hovertimer = setTimeout(() => {
      this.element.nativeElement.focus();
    }, 50);
  }
  @HostListener('blur',['$event']) OnBlur(event) {
    event.code = 'Blur';
    this.emitEventData.emit(event);
  }
}
