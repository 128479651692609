import { Component, OnInit, ViewChild } from '@angular/core';
// import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {
  Router, Event, NavigationStart, RoutesRecognized,
  RouteConfigLoadStart, RouteConfigLoadEnd,
  NavigationEnd, NavigationCancel, NavigationError, GuardsCheckStart, ChildActivationStart, ActivationStart, GuardsCheckEnd, ResolveStart, ResolveEnd, ChildActivationEnd, ActivationEnd, Scroll
} from '@angular/router';
import { element } from 'protractor';
import { ActiveDashService } from '../../services/active-dash.service';
import { navItems, navItemsCopy } from '../../_nav';
import { CommonServicesService } from '../../services/common-services.service';
import { AuthService } from '../../services/auth/auth.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('financialYearModal', { static: false }) public financialYearModal;



  public sidebarMinimized = false;
  public navItems = [];
  userRole = "";
  private toggle: any;
  tabsPermission = {};
  HeadingText: string;
  fileName: string = '';
  private backupNavData: any = { backupNavItems: navItems };

  constructor(public commonService: CommonServicesService, private helper: HelperService, public activeDash: ActiveDashService, private router: Router) {

    /****************Side Navigation Open Issue solved*********************/
    setTimeout(() => {
      this.removeOpenClassFromNavItemAtStartUp();
    }, 500);
    this.commonService.NavBars = JSON.stringify(navItemsCopy)

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // * NavigationEnd: When navigation ends successfully.
        // console.log('NavigationEnd --- ', event.url);
        // this.toggleCount++;
        this.toggleMenu();
      }
    });
  }
  ngOnInit() {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    console.log(this.commonService.accessControl);

    if (this.userRole !== 'Admin') {
      if (!this.commonService.accessControl || Object.keys(this.commonService.accessControl).length === 0) {
        alert("Please contact admin to set your rights.")
        this.logout();
        return;
      }
    }
    this.tabsPermission = this.commonService.accessControl?.tabs
    // console.log(this.tabsPermission);

    this.modifyUserMenuAccess();

  }

  modifyUserMenuAccess() {

    if (this.userRole == 'Admin') {

      this.navItems = JSON.parse(this.commonService.NavBars);
    } else {
      this.navItems = navItems.filter((item) => {

        const elementName = item['elementName'];
        const permission = this.tabsPermission[elementName];
        return this.checkNavPermission(item, permission);
      })
    }
  }

  private checkNavPermission(item, tabPermission) {
    const elementName = item['elementName'];
    let tempItemObj: any = {};
    if (elementName && tabPermission && (tabPermission == 'Y' || tabPermission['permission'] == 'Y')) {
      tempItemObj = item;
      if (item['children']?.length) {
        const children = [];
        item['children'].forEach((childItem) => {
          const elementName = childItem['elementName'];
          const permission = tabPermission['children'][elementName];
          if (this.checkNavPermission(childItem, permission)) {
            children.push(childItem);
          }
        })
        tempItemObj['children'] = children;
      }
      return tempItemObj;
    } else {
      return false;
    }
  }

  removeOpenClassFromNavItemAtStartUp = () => {
    let navElement = document.getElementById('side-nav-id');

    if (navElement) {
      let elements = navElement.getElementsByTagName('app-sidebar-nav-dropdown');
      //debugger;
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("open");
      }
    }

  }
  /****************Side Navigation Open Issue solved*********************/


  toggleMinimize(e) {
    this.sidebarMinimized = e;
    console.log(e);

  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.toggle = document.getElementsByTagName('body');//document.getElementsByClassName('d-lg-block d-none navbar-toggler');
    // console.warn('d-lg-block d-none navbar-toggler',toggle);
    // if (this.toggle?.length) {
    //   this.toggle[0].click()
    //   this.toggle[0].addEventListener("click", function () {
    //     console.log('clck');
    //   });
    // }

  }
  toggleMenu() {


// console.log(this.toggleCount);
// document.body.className = "sidebar-collapse";
// const body:any = document.getElementsByTagName('body');
// console.log(body);
// body[0].classList.remove("sidebar-lg-show")
// if(this.toggleCount%2 || true){
//   return 0;
// }
if (this.toggle?.length) {
  // console.log('data',this.toggle);

  this.toggle[0].classList.remove("sidebar-lg-show");//.click();

} else {
  // console.log('No data',this.toggle);
  this.toggle = document.getElementsByTagName('body');//document.getElementsByClassName('d-lg-block d-none navbar-toggler');
}
  }

downloadFileName() {
  // console.log("downloadFileName");

  let endpoint = "transaction/getdump";
  this.commonService.getWithoutParams(endpoint).subscribe(successResp => {

    if (successResp['errorCode'] == "000000") {

      this.fileName = successResp['Data']['filename'];
      // console.log(this.fileName);

      if (this.fileName) {
        this.PopupMessageModal.show();
      }
    }
  }, (err) => {
    console.log(err);
  });
}


downloadBackupFile() {
  let params: any = { "filename": this.fileName };

  let endpoint = 'transaction/downloadFile'
  this.commonService.downloadFilesReq(endpoint, params).subscribe(successResp => {

    var blob = new Blob([successResp], { type: 'application/sql' });
    var url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = this.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.fileName = "";
    this.PopupMessageModal.hide();
  },
    err => {
      alert("Problem while downloading the file.");
      console.error(err);
    }
  );

}
logout(){
  // this.auth.logout();
  localStorage.removeItem('currentUser');
  this.commonService.apiToken = '';
  this.router.navigateByUrl('/login');
}
}
