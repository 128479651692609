import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public storeSubTax:any;

  constructor() { }

  removeRefrence(data:any){
    return JSON.parse(JSON.stringify(data));
  }

}
