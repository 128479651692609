import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonServicesService } from '../../../services/common-services.service';
import { SubmitFormService } from '../../../services/submit-form.service';
import { ApiService } from '../../../services/api.service';
import { HelperService } from '../../../services/helper.service';
import { ConfigurationSetting } from '../../../appconfig';
import { DatePipe, Location } from '@angular/common';
import { SystemParamsService } from '../../../services/system-params.service';
import { ToastService } from '../../../services/toast.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var $;
interface customername {
  value: number,
  display: string
}

interface address {

  description: string,
  address: string
}


@Component({
  selector: 'app-manual-dn',
  templateUrl: './manual-dn.component.html',
  styleUrls: ['./manual-dn.component.scss']
})
export class ManualDnComponent implements OnInit {
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('printPopup', { static: false }) public printPopup;

  fileName:string = "";
  dataLocalUrl: any;
  selectedPoNumber: boolean = false;
  isFreezedDcOut: boolean = false;
  dataForPrint:any = {};
  ManualDnForm: boolean = true;
  showFormBtn: boolean = true;
  showCloseBtn: boolean = false;
  saveBtnVisible: boolean = false;
  totalQtyIn: boolean = false;
  currentSelectedRow: number;
  deletedArray: number[] = [];
  btnRow: boolean = false;
  emptySearchField:boolean = true;
  saveBtn: boolean = true;
  editBtn: boolean = false;
  addItemButton: boolean = false;
  CreateNew: boolean = false;
  cancelbtn: boolean = true;
  editDisableBtn: boolean = false;
  todaysDate: any = [];
  deleteBtnDisable: boolean = false;
  visiblecanclebtn: boolean = false;
  isRowClicked:boolean = false;
  refreshDetailTable:boolean = true;

  emptyArrayToStoreJobSheetData: any[] = [];
  deleteMsg: string = "";
  manualdnuniqueno:number = 0;
  minDate:any;
  ErrorDescription: string = "";
  public text: string = 'Create New Manual';
  displayTitle :string = "Material Manual DN";
  private backupData: any = { bindDataToManualFormAndTable: {} };
  tabledataforprocess: any[] = [];


  model = {
    showmanualdnsrno: {
      valuType: 'String',
      value: '',
      label: "DC Outward ",
      isRequired: false,
      disable: true,
      maxLength: 4,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    manualdnsrno: {
      valuType: 'String',
      value: '',
      label: "DC Outward ",
      isRequired: false,
      disable: true,
      maxLength: 4,
      err: "",
      element: { name: 'input', type: 'text' }
    },

    manualdndate: {
      valuType: 'Date',
      value: '',
      label: "Outward Date",
      isRequired: true,
      disable: true,
      err: "",
      element: { name: 'input', type: 'date' }
    },
    customerno: {
      valuType: 'String',
      value: '',
      label: "Customer Name",
      isRequired: true,
      disable: true,
      maxLength: 100,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    address: {
      valuType: 'String',
      value: '',
      label: "Address",
      isRequired: true,
      disable: true,
      maxLength: 100,
      err: "",
      element: { name: 'input', type: 'text' }
    },

    jmremark: {
      valuType: 'String',
      value: '',
      label: "Remark",
      isRequired: false,
      disable: true,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    printremark: {
      valuType: 'String',
      value: '',
      label: "Print Remark",
      isRequired: false,
      disable: true,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }


  customerNameArray: any[] = [];

  manualsidetableconfig: any = {
    "tableclasses": "table-striped sticky-header  horizontal-scroll table-lightblue  table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "enablefreezepanes": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showonlysearch": true,
    "checkDeletedFlag": true,
    "checkDeletedFlagKey": "isDeleted",
    "fullheighttable":"fullheight-table"
  };
  manualsidetableheaders: any = [
    {
      "key": "manualdnsrno",
      "display": "Note No.",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": false,
      "decimaldigit": 0,
      "datatype": "number",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "icon": "fa fa-search",
      "title": "Search Item Name",
      "thclass": "",
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "manualOutNo",
      "display": "Note No.",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "icon": "fa fa-search",
      "title": "Search Item Name",
      "thclass": "",
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "manualdndate",
      "display": "Outward Date",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "date",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "icon": "fa fa-search",
      "title": "Search Item Name",
      "thclass": "",
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
  ];
  manualsidetabledata: any = [];


  manuallisttableconfig: any = {
    "tableclasses": "table-striped sticky-header sorting  horizontal-scroll table-lightblue  table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "enablefirstbtn": false,
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": true },
  };
  manuallisttableheaders: any =
    [
      {
        "key": "description",
        "display": "Description",
        "editable": false,
        "sorting": true,
        "enablehide": true,
        "visiblecolm": true,
        "datatype": "string",
        "thstyle": { "text-align": "center", "width": "400px" },
        "tdalignment": "hr-left vt-middle",
        "menulist": [{ "label": "Filter On", "action": "filter" }],
        "summary": []
      },
      {
        "key": "qty",
        "display": "Quantity",
        "editable": false,
        "sorting": true,
        "enablehide": true,
      "positive":true,
      "visiblecolm": true,
        "decimaldigit": 0,
        "datatype": "number",
        "thstyle": { "text-align": "center", "width": "70px" },
        "tdalignment": "hr-center vt-middle",
        "menulist": [{ "label": "Filter On", "action": "filter" }],
        "summary": []
      },
      {
        "key": "remark",
        "display": "Remark",
        "editable": false,
        "sorting": true,
        "enablehide": true,
        "visiblecolm": true,
        "datatype": "string",
        "thstyle": { "text-align": "center", "width": "250px" },
        "tdalignment": "hr-left vt-middle",
        "menulist": [{ "label": "Filter On", "action": "filter" }],
        "summary": []
      }
    ]
  manuallisttabledata: any[] = [];



  constructor(private domSanitizer: DomSanitizer,private helper: HelperService, private submit: SubmitFormService, private apidata: ApiService, public commonService: CommonServicesService,
    private configurationsetting: ConfigurationSetting, private datepipe: DatePipe, public systemParamsService: SystemParamsService, private toastService: ToastService) { }

  ngOnInit(): void {
    this.getAllManualDn();
    this.getCustomerName();
  }


  CreateNewDc() {
    this.emptySearchField = false;
    this.displayTitle = "Add Material Manual DN";
    if (this.text === 'Create New Manual') {
      this.text = 'Add Manual'
    } else {
      this.text = 'Create New Manual'
    }
    this.saveBtn = false;
    this.CreateNew = true;
    this.editBtn = false;
    this.btnRow = true;
    this.saveBtnVisible = true;
    this.cancelbtn = false;
    this.deleteMsg = "";
    this.btnRow = true;

    this.addItemButton = true;
    this.visiblecanclebtn = true;
    this.manuallisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.manuallisttableconfig['actioncolmconfig']['displaycolumn'] = true;

    this.todaysDate = new Date();
    this.model.manualdndate.value = this.todaysDate;
    this.model.jmremark.value = " "
    this.makeColumnUneditable(true);
    const ele = document.getElementById(`tr${"manualtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    delete this.backupData.bindDataToManualFormAndTable;
    this.minDate = new Date();

    setTimeout(() => {
      this.emptySearchField = true;
      }, 30);
  }



  submitManualForm() {
    let data = this.submit.submitForm(this.model);
    console.log(data);

    if (data) {
      this.saveBtn = true;
      let editedRow = [];
      for(let i=0; i<this.tabledataforprocess.length; i++){
        // if((this.tabledataforprocess[i]['description'] == "" || null || undefined) || (this.tabledataforprocess[i]['srno'] == "" || null || undefined) ){
        //   this.toastService.showToastMsg('warning', 'Warning', "Please enter data in table.");
        //   return;
        // }
        // if(this.tabledataforprocess[i]['qty'] <= 0 || (this.tabledataforprocess[i]['qty'] <= "" || null || undefined)){
        //   this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
        //   return;
        // }
        if (this.tabledataforprocess[i].rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i]);
        }
      }
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];
      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      data["criteria"]["tablename"] = "manualnotemaster";

      let endpoint = 'transaction/insertManualDn';
      if (data["criteria"]['manualdnsrno']) {
        endpoint = 'transaction/updateManualDn';
        data["criteria"]["manualitems"] = this.tabledataforprocess;
        this.ErrorDescription = "Data Updated Successfully";
      } else {
        data["criteria"]["manualitems"] = this.tabledataforprocess;
        this.ErrorDescription = "Data Inserted Successfully";
      }
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.manualdnuniqueno = res['Data']
          this.saveBtn = true;
          this.visiblecanclebtn = false;
          this.addItemButton = false;
          this.cancelbtn = true;
          this.editBtn = true;
          this.deleteBtnDisable = false;
          this.commonService.diasbleFormFields(this.model);
          // this.model.manualdnsrno = res['Data']
          this.manuallisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.makeColumnUneditable(false);
          this.deleteBtnDisable = false;
          this.getAllManualDn();
          this.PopupMessageModal.show();
          this.saveBtnVisible = false;
          this.CreateNew = false;

        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });

    }

  }

  deleteManualDnForm() {
    console.log("delete");

    let data = this.submit.submitForm(this.model);
    console.log(data);

    if (data && data["criteria"]["manualdnsrno"]) {

      data["criteria"]["manualitems"] = this.tabledataforprocess;

      console.log(data["manualitems"]);

      let items: any = {};
      items.manualdnsrno = data["criteria"]["manualdnsrno"];
      items.customerno = data["criteria"]["customerno"];

      items.delLineItems = [];
      data["criteria"]["manualitems"].forEach((ele) => {
        if (ele?.SrNo) {
          items.delLineItems.push(ele?.SrNo);
        }
      })

      data["criteria"] = items;
      data["criteria"]["tablename"] = "manualnotemaster";

      let endpoint = 'transaction/deleteManualDn';

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('deletepopup')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.clearAll(false);
          this.getAllManualDn();

          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();

        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  getAllManualDn() {
    let params = { "tablename": "manualnotemaster" };

    const endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getAllManualDn';

    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];

        this.manualsidetabledata = []
        this.manualsidetabledata = items;

        for (let i = 0; i < items.length; i++) {
          if (items[i]['manualdnsrno'] == this.manualdnuniqueno || items[i]['manualdnsrno'] == this.model.manualdnsrno.value) {
            // console.log("Index", items[i], i);
            this.model.manualdnsrno.value = items[i]['manualdnsrno'];
            this.getByIdCall(this.model.manualdnsrno.value)
            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trmanualtbl1' + i).style.backgroundColor = "#7de4f0";
            }, 900);
            break;
          }
        }

        // this.isPoUpOpen = true;

      }
    }, err => { })
  }
  getCustomerName() {
    let endpoint = 'transaction/getCustomerwithAdress';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      } else {
        if (successResp['errorDescription']) {
          // alert("Error Occured!");
        }
      }
    }, err => { })
  }

  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.manualdndate.disable == false && flag) {
           this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.manualsidetabledata, modal.rowindex, this.currentSelectedRow, "manualtbl1");

    this.displayTitle = "Display Material Manual DN";
    let row = modal['row'];
    let manualdnsrno = row['manualdnsrno'];
    this.getByIdCall(manualdnsrno);
  }

  getByIdCall(srno){
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getManualDnById";
    let params = { "tablename": "manualnotemaster", manualdnsrno: srno };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data'];
        this.bindDataToManualFormAndTable(item);
        this.dataForPrint = item;
      }
    }, (error) => {
      console.log(error);
    });
  }

  printPdf(){
    this.downloadPdf(this.dataForPrint);
  }

  downloadPdf(data) {
    data['pdffor'] = 'manualdn';

    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/generateoutwardpdf";
    let body = { "criteria": { "transId": this.model.manualdnsrno.value, "printEsnDetails":"y", printPdfFor:'manualdn' } }


    this.commonService.postMethod(apiUrl, body).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        console.log("success");
        this.fileName = successResp['Data']['filename'];

        this.downloadFile(this.fileName)
      }
      else {
        this.ErrorDescription = "Something went wrong, please contact admin";
      }
    },
      err => {
        this.ErrorDescription = "Something went wrong, please contact admin";
      });
  }

  downloadFile(filename: string) {
    let body = { "filename": filename }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/downloadpdf";
    this.commonService.getFile(apiUrl, body) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        // saveAs(blob, this.fileName);
        let a = document.createElement('a');

        let file = new Blob([blob], { type: 'application/pdf' });
        console.log("file ", file);

        let fileURL = URL.createObjectURL(file);

        this.dataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
        a.download = this.fileName;
        setTimeout(() => {
          this.printPopup.show();
        }, 200);

        this.fileName = "";
      })
      .catch((err) => {
        console.log("Something went wrong");
      });

  }
  makeColumnUneditable(flag:boolean) {
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "srno", flag);
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "description", flag);
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "qty", flag);
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "remark", flag);
    this.manuallisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.manuallisttableheaders), "printremark", flag);

  }

  bindDataToManualFormAndTable(modal, flag: boolean = true) {
    if (this.model.customerno.disable == false && flag) {
           this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.backupData.bindDataToManualFormAndTable = this.helper.removeRefrence(modal);
    this.refreshDetailTable = false;

    this.model.showmanualdnsrno.value = modal['manualOutNo'];
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.manuallisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"manualtbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }
    this.manuallisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.makeColumnUneditable(false);
    this.manuallisttabledata = modal['manualitems']?.length ? modal['manualitems'] : [];
    console.log(this.model.manualdnsrno.value, this.model.showmanualdnsrno.value);


    let newArr = this.manuallisttabledata.filter((item)=>{
      return item.ddisDeleted !== 1;
    })
    this.manuallisttabledata = newArr;
    // console.log(newArr);
    setTimeout(() => {
      this.refreshDetailTable = true;
      }, 10);
    this.editBtn = true;

    this.addItemButton = false;
    this.cancelbtn = false;
    this.CreateNew = false;
    this.editDisableBtn = false;
    this.commonService.diasbleFormFields(this.model);

   this.isRowClicked = false;
  }

  addRowForManualDn() {
    let deepCopy = [...this.tabledataforprocess]

    const item = { srno: "", description: "", qty: 0, remark: "" };

    deepCopy.push(item);
    this.manuallisttabledata = deepCopy;
  }

  updatedtabledata(data: any) {

    this.tabledataforprocess = [];
    this.tabledataforprocess = data;
    this.tabledataforprocess.forEach((item, i)=>{
      item['srno'] = i+1;
    })
    console.log('update', data, this.manuallisttableheaders);
  }

  editForm() {
    this.manuallisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.makeColumnUneditable(true);
    this.displayTitle = "Edit Material Manual DN";

    this.deleteBtnDisable = true;
    this.visiblecanclebtn = true;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.cancelbtn = false;
    this.addItemButton = true;
    this.editDisableBtn = true;
    this.editBtn = false;
    this.commonService.enableFormFields(this.model);
  }

  cancelPopup(whichForm) {
    switch (whichForm) {
      case 'deletepopup':
        this.showDeleteModal.hide();
        break;
      case 'errorpopup':
        this.backendErrorMessageModal.hide();
        break;
      case  'msg':
        this.PopupMessageModal.hide();
    }
  }


  clearAll(flag: boolean = true) {
    console.log('editBtn', this.editBtn, 'editDisableBtn', this.editDisableBtn);
    this.displayTitle = "Material Manual DN";

    this.commonService.clearForm(this.model);
    this.manuallisttabledata = [];

    this.visiblecanclebtn = false;
    this.addItemButton = false;
    this.cancelbtn = true;
    this.deleteMsg = ""
    this.btnRow = true;

    this.deleteBtnDisable = false;
    this.commonService.diasbleFormFields(this.model);
    this.manuallisttableconfig['actioncolmconfig']['displaycolumn'] = true;


    const ele = document.getElementById(`tr${"manualtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';

    if (this.CreateNew && this.backupData['bindDataToManualFormAndTable'] && flag) {
      this.bindDataToManualFormAndTable(this.backupData.bindDataToManualFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;

    }
    this.CreateNew = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;

  }


  cancel() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
        this.CreateNew = false;

      } else {
        this.clearAll(true);
        this.CreateNew = true;

        if (this.saveBtn && this.cancelbtn) {
          this.clearAll(false);
          this.CreateNew = false;
        }
      }
    }
  }

  CustomerData(val: any) {
    console.log(val, "val");
    for (let items of this.customerNameArray) {

      if (val == items.value) {
        console.log(items);
        this.model.address.value = items.address;
      }
    }
  }

  deleteRow(event) {
    let id = event['row'];
    this.tabledataforprocess.splice(event.rowindex, 1);
    // console.log(id);
    this.pushInDeletedArray(event.row, 'SrNo');
  }
  pushInDeletedArray(row, key) {
    console.log(row[key])
    this.deletedArray.push(row[key])
  }

  showManualDnForm() {
    this.ManualDnForm = true;
    this.showCloseBtn = true;
    this.showFormBtn = false;

  }
  closeManualDnForm() {
    this.ManualDnForm = false;
    this.showFormBtn = true;
    this.showCloseBtn = false;
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

}
