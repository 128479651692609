import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-data-fetching-popup',
  templateUrl: './data-fetching-popup.component.html',
  styleUrls: ['./data-fetching-popup.component.scss']
})
export class DataFetchingPopupComponent implements OnInit {

  // dashBoardDataLoadedStatus = {
  //   totalclosure: { loading: false, retry: false, success: false, text: "" },
  //   conflict: { loading: false, retry: false, success: false, text: "" },
  //   allocation: { loading: false, retry: false, success: false, text: "" },
  //   referred: { loading: false, retry: false, success: false, text: "" },
  //   fiDailyMonitor: { loading: true, retry: false, success: false, text: "" },
  //   docDailyMonitor: { loading: true, retry: false, success: false, text: "" },
  // };
  // isDataLoading = true;

  // constructor(private toastService: ToastService, 
  //   public commonDataService: CommonDataService,
  //   public popoverController: PopoverController
  // ) {
  //   this.dashBoardDataLoadedStatus = this.commonDataService.dashBoardDataLoadedStatus;

  // }

  ngOnInit() {
  //   // console.log(this.dashBoardDataLoadedStatus);
  //   if(this.commonDataService.selectedDataType=='DM'){
  //     this.getDailyMonitorData();
  //   }else{
  //     this.getDashboardData();
  //   }
  //   this.isDataLoading = true;
  //   // this.commonDataService.getTotalClosureCount();
  // }
  // canClose() {
  //   let flag = false;

  //   for (const objKey in this.commonDataService.dashBoardDataLoadedStatus) {
  //     const ItemObj = this.commonDataService.dashBoardDataLoadedStatus[objKey];
  //     if (ItemObj['loading']) {
  //       flag = true;

  //     }
  //   }

  //   // this.isDataLoading = !flag;

  //   if (!this.isDataLoading) {
  //     this.closePopover();
  //   } else {
  //     this.toastService.showToastMsg('warning', 'Data is loading, cannot continue  ')
  //   }
  }

  // closePopover() {
  //   this.popoverController.getTop()
  //     .then(popover => {
  //       // console.log('pop', popover);
  //       if (popover) {
  //         this.commonDataService.isloaded = false;
  //         popover.dismiss('11', 'close');
  //       }

  //     })
  // }

  // ngOnDestroy() {
  //   this.commonDataService.isloaded = false;

  // }

  // getDashboardData() {
  //   this.commonDataService.setObjectFlag(this.commonDataService.dashBoardDataLoadedStatus.totalclosure, 'loading', true);
  //   this.commonDataService.setObjectFlag(this.commonDataService.dashBoardDataLoadedStatus.conflict, 'loading', true);
  //   this.commonDataService.setObjectFlag(this.commonDataService.dashBoardDataLoadedStatus.referred, 'loading', true);
  //   this.commonDataService.setObjectFlag(this.commonDataService.dashBoardDataLoadedStatus.allocation, 'loading', true);

  //   this.commonDataService.getTotalClosureCount().finally(() => {
  //     this.commonDataService.getTotalConflictCount().finally(() => {
  //       this.commonDataService.getTotalReferredCount().finally(() => {
  //         this.commonDataService.getTotalAllocationCount().finally(() => {
  //           this.isDataLoading = false;
  //           if (this.dashBoardDataLoadedStatus.totalclosure.success && this.dashBoardDataLoadedStatus.conflict.success && this.dashBoardDataLoadedStatus.referred.success && this.dashBoardDataLoadedStatus.allocation.success)
  //             setTimeout(() => {
  //               this.closePopover()
  //             }, 1000)
  //         });
  //       });
  //     });
  //   });
  // }

  // getDailyMonitorData() {
  //   this.commonDataService.setObjectFlag(this.commonDataService.dashBoardDataLoadedStatus.fiDailyMonitor, 'loading', true);
  //   this.commonDataService.setObjectFlag(this.commonDataService.dashBoardDataLoadedStatus.docDailyMonitor, 'loading', true);
  //   this.commonDataService.getTotalDailyMonitorCountFI().finally(() => {
  //     this.commonDataService.getTotalDailyMonitorCountDoc().finally(() => {
  //       this.isDataLoading = false;
  //       if (this.dashBoardDataLoadedStatus.fiDailyMonitor.success && this.dashBoardDataLoadedStatus.docDailyMonitor.success)
  //         setTimeout(() => {
  //           this.closePopover()
  //         }, 1000)
  //     });
  //   });
  // }

}
