export class ConfigurationSetting {
  // private Environment: string = "Local";
  // private Environment: string = "Staging";
  private Environment: string = "Production";
  PrintConsole: boolean = true;
  public BaseUlr: string = "";

  constructor() {
    console.log("constructor in App Settings")
    window.addEventListener('online', this.setNetworkFlags);
    window.addEventListener('offline', this.setNetworkFlags);
  }

  getBaseUrl() {
    if (this.Environment === "Local") {
      // return "https://breezy-pans-flow-223-233-86-197.loca.lt/"
      return "http://localhost:3008/"
    }
    else if (this.Environment === "Staging") {

      return "http://13.234.47.193:3018/";
    }
    else if (this.Environment === "Production") {
      // return "http://13.234.47.193:3009/";
      return "https://shreemetal.viotalworks.com/"

      // return "http://shreemetal.viotalworks.com/";
    }
  }

  setNetworkFlags(NetworkStatus) {
    let NetConnectionMsg: string = "";
    if (NetworkStatus["type"] == "offline") {
      NetConnectionMsg = "Internet Connection is Not Avaible."
    }
    else {
      NetConnectionMsg = "Internet Connection is Avaible."
    }
    alert(NetConnectionMsg);
  }

  printConsoleLog(Level, Msg) {
    if (this.PrintConsole) {
      if (Level == 1) {
        console.debug(Msg);
      }
      else if (Level == 2) {
        console.error(Msg);
      }
    }
  }

  hideLeftMenu() {
    // document.body.className = "skin-blue sidebar-mini";
    // document.getElementById('side-bar').style.marginRight = '25px';
  }
}
