import { Injectable } from '@angular/core';
import { CustomEvents } from '../component/gt-plus/events';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionService {

  constructor( private customSrvc: CustomEvents) { }


  /**testing*/
  setFromKeysValues(formObj, FormData: any) {
    // console.log(formObj);
    Object.keys(FormData).forEach(key => {
      if (formObj[key]) {
        if (formObj[key]['valuType'] == 'Date' && FormData[key]) {
          // console.log(key,FormData[key], formObj[key].value);
          let d = new Date(FormData[key]);
          // formObj[key].value = FormData[key];
          formObj[key].value = d;
          // formObj[key].value =this.datepipe.transform(d,this.systemParamsService.ngDateFormatddMMy);
          // console.log(formObj[key].value,d);
        } else
          formObj[key]['value'] = FormData[key];
      }
    });
    // console.log(formObj);
    return formObj;
  }

  enableFormFields(formObj: any) {
    Object.keys(formObj).forEach((item) => {
      if (formObj[item] && item != 'isInValid') {
        formObj[item]['disable'] = false;
      }
    })
    return formObj;
  }


  diasbleFormFields(formObj: any) {
    Object.keys(formObj).forEach((item) => {
      if (formObj[item] && item != 'isInValid') {
        formObj[item]['disable'] = true;
      }
    })
    return formObj;
  }

  enableEditForGt(tableheader: any, colmkey: string, flag: boolean) {
    let empArray = [];
    for (let itemheaders of tableheader) {
      // console.log('Item header ', itemheaders['key']);
      // console.log('colm key ', colmkey);

      if (itemheaders['key'] === colmkey) {
        itemheaders['editable'] = flag;
      }
      empArray.push(itemheaders);
    }

    // tableheader = empArray;
    return empArray;
  }


  clearForm(form: any) {
    Object.keys(form).forEach((item) => {
      // console.log(item);
      if (typeof (form[item]) == 'object') {
        form[item].value = undefined;

        form[item]['err'] = ''
      }
    });
    // console.log(form);
  }

  identifySelectedRow(tabledata:any, rowindex, currentSelectedRow, tableid:string){
    tabledata.forEach((ele, index)=>{
      if(index == rowindex){
        // console.log(`tr${tableid+currentSelectedRow}`);

        const doc = document.getElementById(`tr${tableid+currentSelectedRow}`);


        if(doc?.style)
        doc.style.backgroundColor = '#7de4f0';
      }else{

        const ele = document.getElementById(`tr${tableid+index}`);
        if(ele)
        ele.style.backgroundColor = '';
      }
    });
  }

  addBlankRow(tableHeaders, tableid, extComnArr: any[] = []) {
    let blankRow = {};

    for (let index = 0; index < tableHeaders.length; index++) {
      const element = tableHeaders[index];

      if (element['datatype'] == "string") {
        blankRow[element['key']] = "";
      }
      else if (element['datatype'] == "number") {
        blankRow[element['key']] = 0;
      }
      else if (element['datatype'] == "dropdown") {
        blankRow[element['key']] = "";
      }
      else if (element['datatype'] == "date") {
        blankRow[element['key']] = null;
      }
    }

    for (let index = 0; index < extComnArr.length; index++) {
      const element = extComnArr[index];

      blankRow[element['key']] = element['defValue'];// Default Value

    }

    // this.tabledata.push(blankRow);
    this.customSrvc.publish(tableid, {
      "tableid": tableid, "actiontype": "update", "operation": "add", "data": blankRow
    });
  }

  setDecimalDigit(value, decimaldigit) {
    if (value) {
      value = +(value);
      const number = value.toFixed(decimaldigit);
      // console.log(number, value, typeof number);
      return number;
    } else {
      // console.log('de', value);

      return value
    }

  }

  filterSpareListTableIfExistingSubTable(deepCopy:any={}, tablelist:any[]=[], popupitems:any[]=[]) {
    let preparedarr = [];
    preparedarr = deepCopy.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < tablelist.length; ind++) {
        if (tablelist[ind]['partno'] == row['partno']) {
          recavailable = false;
        }
      }
      return recavailable;
    });
    console.log("preparedarr", preparedarr);

    popupitems = []
    popupitems = preparedarr;
    // console.log("popupitems", popupitems);
    return popupitems;
  }

  deleteExtraFlagsFromTable(arr:any[]=[]){
    arr.forEach((ele) => {
      delete ele['autoid'];
      delete ele['actualindex'];
      delete ele['rowindex'];
      delete ele['colindex'];
      delete ele['keyName'];
      delete ele['ptIndex'];
      delete ele['actioncolumn'];
      delete ele['quantityctrl'];
      delete ele['checked'];
    });

    return arr;
  }

  capitalize(str){
    let arr = str.split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    let str2 = arr.join(" ");
    return str2;
  }

  decideOutQty(subasyno, maxQty, outQty, hiddenOutQty, columnKeyName, allowOutQtyForSubAsy) {

    let maxallowedqty = 0;
    allowOutQtyForSubAsy = allowOutQtyForSubAsy ? +allowOutQtyForSubAsy:0
    if (columnKeyName == 'checkbox') {
      maxallowedqty = Math.min(maxQty, +allowOutQtyForSubAsy);
      allowOutQtyForSubAsy = allowOutQtyForSubAsy - maxallowedqty;
    } else if (columnKeyName == 'outquantity') {
      if (outQty !== hiddenOutQty) {
        maxallowedqty = Math.min(maxQty, allowOutQtyForSubAsy + hiddenOutQty);
        maxallowedqty = Math.min(maxallowedqty, outQty);

        allowOutQtyForSubAsy = allowOutQtyForSubAsy - maxallowedqty + hiddenOutQty;
      } else {
        maxallowedqty = outQty;
      }
    }
    console.log({subasyno:allowOutQtyForSubAsy});

    return maxallowedqty;
  }

  tableBackgroundColorChange(index, quantity, outquantity) {
    let ele = document.getElementById('trselectitem' + index);
    if (quantity !== outquantity) {
      setTimeout(() => { if (ele) { ele.style.background = 'antiquewhite' } }, 50);
    } else {
      setTimeout(() => { if (ele) { ele.style.background = '' } }, 50);
    }
  }

  
  manipulateString(stringVal) {

    if (stringVal != null && stringVal != "") {
        stringVal = stringVal.replace(/\\/gi, "\\\\");
        stringVal = stringVal.replace(/\'/gi, "''");
        stringVal = stringVal.replace(/\n/g, "\\n");
        stringVal = stringVal.replace(/\"/gi, '\\"');
    }

    return stringVal;
}


}
