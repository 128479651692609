import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidateObjectService {
  errArray = [];
  constructor() { }
  validateDataObject = (inputObject) => {
    // console.log(JSON.stringify(inputObject));

    this.errArray = [];
    Object.keys(inputObject).forEach(key => {
      // console.log(key);

      const validateDataObject = inputObject[key];
      // console.log(validateDataObject,'validateDataObject');

      switch (validateDataObject['valuType']) {
        case 'Number': this.validateNumber(validateDataObject); break;
        case 'String': this.validateString(validateDataObject); break;
        case 'Email': this.validateEmail(validateDataObject); break;
        case 'Date': this.validateDate(validateDataObject); break;
        case 'Time': this.validateTime(validateDataObject); break;
      }
    })
  }

  validateNumber = (validateDataObject) => {
    const value = validateDataObject['value'];
    const dispName = validateDataObject['label'];
    validateDataObject['err'] = "";


    if (validateDataObject['isRequired']) {
      if (value == undefined || value == null || !value) {
        if (validateDataObject['element']['name'] == 'select') {
          validateDataObject['err'] = `${dispName} must be selected.`;
          this.errArray.push(`${dispName} must be selected.`);
        } else {
          validateDataObject['err'] = `${dispName} must have a numeric value.`;
          this.errArray.push(`${dispName} must have a numeric value.`);
        }
        return false;
      }
      validateDataObject['err'] = '';
    }

    if (value && isNaN(+value)) {
      validateDataObject['err'] = `${dispName} must have a numeric value.`;
      this.errArray.push(`${dispName} must have a numeric value.`)
      return false;
    } else {
      if (value && (+validateDataObject['min'] || +validateDataObject['min'] == 0)) {
        if (+validateDataObject['min'] > +value) {
          validateDataObject['err'] = `${dispName} must have a  value less than ${validateDataObject['min']}`
          this.errArray.push(`${dispName} must have a  value less than ${validateDataObject['min']}`)
          return false;
        }
      }
      if (value && (+validateDataObject['max'] || +validateDataObject['min'] == 0)) {
        if (+validateDataObject['max'] < +value) {
          validateDataObject['err'] = `${dispName} must have a  value greater than ${validateDataObject['max']}`;
          this.errArray.push(`${dispName} must have a  value greater than ${validateDataObject['max']}`)
          return false;
        }
      }

    }
    if (value && +validateDataObject['maxLength']) {
      if (value.toString().length > +validateDataObject['maxLength']) {
        validateDataObject['err'] = `${dispName} can have ${validateDataObject['maxLength']} digits only.`;
        this.errArray.push(`${dispName} can have ${validateDataObject['maxLength']} digits only.`);
      }
    }
    if (value && +validateDataObject['minLength']) {
      if (value.toString().length < +validateDataObject['minLength']) {
        validateDataObject['err'] = `${dispName} must have ${validateDataObject['minLength']} digits only.`;
        this.errArray.push(`${dispName} must have ${validateDataObject['minLength']} digits only.`);
      }
    }
    if (validateDataObject['checkPhoneNumber'] && value && !this.phoneNumberRegEx(value)) {
      validateDataObject['err'] = `${dispName} must have a 12 digit phone number.`;
      this.errArray.push(`${dispName} must have a 12 digit phone number.`);
      return false;
    }
  }

  validateString = (validateDataObject) => {
    const value = validateDataObject['value'];
    const dispName = validateDataObject['label'];
    validateDataObject['err'] = "";

    if (validateDataObject['isRequired']) {
      if (value == undefined || value == null || !value) {
        // console.warn(value,'value');

        validateDataObject['err'] = `${dispName} is required.`
        this.errArray.push(`${dispName} is required.`);
        return false;
      }
    }

    if (value && +validateDataObject['maxLength']) {
      if (value.length > +validateDataObject['maxLength']) {
        validateDataObject['err'] = `${dispName} can have ${validateDataObject['maxLength']} characters only.`
        this.errArray.push(`${dispName} can have ${validateDataObject['maxLength']} characters only.`);
      }
    }
    if (value && +validateDataObject['minLength']) {
      if (value.length < +validateDataObject['minLength']) {
        validateDataObject['err'] = `${dispName} must have ${validateDataObject['minLength']} characters only.`
        this.errArray.push(`${dispName} must have ${validateDataObject['minLength']} characters only.`);
      }
    }

    if (validateDataObject['regX']) {
      if (value && !value.match(validateDataObject['regX'])) {
        validateDataObject['err'] = `${dispName} does not a valid input.`
        this.errArray.push(`${dispName} does not a valid input.`)
      }
    }



  }
  validateEmail = (validateDataObject) => {
    const value = validateDataObject['value'];
    const dispName = validateDataObject['label'];
    validateDataObject['err'] = "";


    if (validateDataObject['isRequired']) {
      if (value == undefined || value == null || !value) {
        validateDataObject['err'] = `${dispName} is required.`
        this.errArray.push(`${dispName} is required.`);
        return false;
      }
    }
    if (value && +validateDataObject['maxLength']) {
      if (value.toString().length > +validateDataObject['maxLength']) {
        validateDataObject['err'] = `${dispName} can have ${validateDataObject['maxLength']} characters only.`
        this.errArray.push(`${dispName} can have ${validateDataObject['maxLength']} characters only.`);
      }
    }

    const emailValidRegX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value && !value.match(emailValidRegX)) {
      validateDataObject['err'] = `${dispName} does not a valid Email Address.`
      this.errArray.push(`${dispName} does not a valid Email Address.`)
    }

  }
  validateDate = (validateDataObject) => {
    validateDataObject['err'] = "";

    const value = validateDataObject['value'] ? new Date(validateDataObject['value']) : '';
    const dispName = validateDataObject['label'];

    if (validateDataObject['isRequired']) {
      if (!value) {
        validateDataObject['err'] = `${dispName} is required.`
        this.errArray.push(`${dispName} is required.`);
        return false;
      }
    }

    if (value && !this.isDate(value)) {
      validateDataObject['err'] = `${dispName} is invalid date.`
      this.errArray.push(`${dispName} is invalid date.`);
      return false;
    } else {
      if (value && validateDataObject['minDate']) {
        if (new Date(validateDataObject['minDate']) > value) {
          validateDataObject['err'] = `${dispName} must have a  date less than ${validateDataObject['minDate']}`
          this.errArray.push(`${dispName} must have a  date less than ${validateDataObject['minDate']}`);
          return false;
        }
      }
      if (value && validateDataObject['maxDate']) {
        if (new Date(validateDataObject['maxDate']) < value) {
          validateDataObject['err'] = `${dispName} must have a  date greater than ${validateDataObject['maxDate']}`
          this.errArray.push(`${dispName} must have a  date greater than ${validateDataObject['maxDate']}`);
          return false;
        }
      }

    }
    if (validateDataObject['value']) {
      // console.log(validateDataObject['value'], this.convertDateToYYYYMMDDForwordSlash(validateDataObject['value']));

      // validateDataObject['value'] = this.convertDateToYYYYMMDDForwordSlash(validateDataObject['value']);
    }
  }
  validateTime = (validateDataObject) => {
    const value = validateDataObject['value'];
    const dispName = validateDataObject['label'];
    validateDataObject['err'] = "";


    if (validateDataObject['isRequired']) {
      if (!value) {
        validateDataObject['err'] = `${dispName} is required.`
        this.errArray.push(`${dispName} is required.`);
        return false;
      }
    }



  }

  compareTwoDatesLess = (dtOb1, dtObj2) => {
    const value1 = new Date(dtOb1['value']);
    const dispName1 = dtOb1['label'];

    const value2 = new Date(dtObj2['value']);
    const dispName2 = dtObj2['label'];

    if (!this.isDate(value1)) {

      this.errArray.push(`${dispName1} has invalid date.`);
      return false;
    }

    if (!this.isDate(value2)) {

      this.errArray.push(`${dispName2} has invalid date.`);
      return false;
    }

    if (value1 >= value2) {

      this.errArray.push(`${dispName1} must be less than ${dispName2}`);
      return false;
    }
    return true;

  }

  compareTwoDatesLessOrEqual = (dtOb1, dtObj2) => {
    const value1 = new Date(dtOb1['value']);
    const dispName1 = dtOb1['label'];

    const value2 = new Date(dtObj2['value']);
    const dispName2 = dtObj2['label'];

    if (!this.isDate(value1)) {

      this.errArray.push(`${dispName1} has invalid date.`);
      return false;
    }

    if (!this.isDate(value2)) {

      this.errArray.push(`${dispName2} has invalid date.`);
      return false;
    }

    if (value1 > value2) {

      this.errArray.push(`${dispName1} must be less than or equals to ${dispName2}`);
      return false;
    }

    return true;

  }

  isDate(x) {
    return (null != x) && !isNaN(x) && ("undefined" !== typeof x.getDate);
  }

  generateKeyValuePairForFormObject = (formObject) => {
    let returnObject = {};

    Object.keys(formObject).forEach(key => {
      // returnObject[key] = formObject[key]['value'];
      if (formObject[key]['value'] != '' || formObject[key]['value'] != "" || formObject[key]['value'] === false) {
        // if (formObject[key]['value'] || formObject[key]['value'] == false || (formObject[key]['value'] && +formObject[key]['value'] <= 0))
        if(formObject[key]['valuType'] === 'Date'){
          returnObject[key] = this.convertDateToYYYYMMDDForwordSlash(formObject[key]['value']);
        }else{
          returnObject[key] = formObject[key]['value'];

        }


      }
    })

    return returnObject;

  }

  shuoldDisableFunctionality = (functionalityObject, functionalityName, FunctionalityFor) => {
    if (!functionalityObject) {
      return false;
    }

    if (!functionalityObject[functionalityName]) {
      return false;
    }

    if (functionalityObject[functionalityName] == "") {
      return false;
    }

    if (functionalityObject[functionalityName].includes(FunctionalityFor)) {
      return true;
    }
    else {
      return false;
    }



  }


  validateFunctionality = (functionalityObject, functionalityName, FunctionalityFor) => {
    if (!functionalityObject) {
      return true;
    }

    if (!functionalityObject[functionalityName]) {
      return true;
    }

    if (functionalityObject[functionalityName] == "") {
      return false;
    }

    if (functionalityObject[functionalityName].includes(FunctionalityFor)) {
      return true;
    }
    else {
      return false;
    }



  }

  validateShow = (functionalityObject, functionalityName) => {
    if (!functionalityObject) {
      return true;
    }
    if (functionalityObject[functionalityName] != undefined && functionalityObject[functionalityName] == "") {
      return false;
    }
    else {
      return true;
    }

  }

  checkIsDataContainsOnlyNumberRegEx(value) {
    const numbersRegEx = /^[0-9]+$/;
    return numbersRegEx.test(value);
  }

  checkIfStringContainsOnlyAlphabetRegEx(value: string) {
    const alphabetRegEx = /^[A-Za-z ]+$/;
    return alphabetRegEx.test(value);
  }

  validateDateRegEx(inputText) {
    // Input a valid date [mm/dd/yyyy or mm-dd-yyyy format]
    let dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    // Match the date format through regular expression
    if (inputText.value.match(dateformat)) {

      //Test which seperator is used '/' or '-'
      var opera1 = inputText.value.split('/');
      var opera2 = inputText.value.split('-');
      let lopera1 = opera1.length;
      let lopera2 = opera2.length;
      // Extract the string into month, date and year
      if (lopera1 > 1) {
        var pdate = inputText.value.split('/');
      }
      else if (lopera2 > 1) {
        var pdate = inputText.value.split('-');
      }
      let dd = parseInt(pdate[0]);
      let mm = parseInt(pdate[1]);
      let yy = parseInt(pdate[2]);
      // Create list of days of a month [assume there is no leap year by default]
      let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (mm == 1 || mm > 2) {
        if (dd > ListofDays[mm - 1]) {

          return false;
        }
      }
      if (mm == 2) {
        var lyear = false;
        if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
          lyear = true;
        }
        if ((lyear == false) && (dd >= 29)) {

          return false;
        }
        if ((lyear == true) && (dd > 29)) {

          return false;
        }
      }
    }
    else {
      alert("Invalid date format!");

      return false;
    }
  }

  checkLengthRange(inputtxt: string, minLength: number = 0, maxLength: number = 1) {
    return (inputtxt.length >= minLength && inputtxt.length <= maxLength)
  }
  phoneNumberRegEx(inputtxt) {
    let phoneNoRegEx = /^\d{10,12}$/; ///^(\d{10})|(\d{12})$/
    return phoneNoRegEx.test(inputtxt)
  }


  alphaNumericRegEx(inputtxt) {
    let letterNumberRegEx = /^[0-9a-zA-Z ]+$/;
    return letterNumberRegEx.test(inputtxt);
  }
  convertDateToYYYYMMDDForwordSlash(strDate) {
    if (!strDate) return '';
    let date = new Date(strDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("/");
  }
}
