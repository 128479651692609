import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../../appconfig';
import { CommonServicesService } from '../../../services/common-services.service';
import { HelperService } from '../../../services/helper.service';
import { SubmitFormService } from '../../../services/submit-form.service';
import { SystemParamsService } from '../../../services/system-params.service';
import { ToastService } from '../../../services/toast.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var $;
@Component({
  selector: 'app-spare-reciept',
  templateUrl: './spare-reciept.component.html',
  styleUrls: ['./spare-reciept.component.scss']
})
export class SpareRecieptComponent implements OnInit {

  @ViewChild('showPopupForSpareRecieptList', { static: false }) public showPopupForSpareRecieptList;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;
  createNewBtn: boolean = false;
  editBtn: boolean = false;
  cancelBtn: boolean = false;
  saveBtn: boolean = true;
  addItemButton: boolean = false;
  btnRow: boolean = false;
  editDisableBtn: boolean = false;
  deleteBtnDisable: boolean = false;
  currentSelectedRow: number;
  isPopUpOpen: boolean = false;
  saveBtnVisible: boolean = false;
  isRowClicked: boolean = false;
  deleteMsg: string = "";
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  sprecieptuniqueno: number = 0;
  emptySearchField: boolean = true;
  maxDate = new Date();
  label: string = "Spare Receipt";
  deletedArray: number[] = [];
  todaysDate: any = [];
  private tabledataforprocessData: any = [];
  priviousQty: number = 0;
  operation: string = "";
  private backupData: any = { bindDataToFormAndTable: {} };
  tabledataforprocess: any[] = []
  refreshDetailTable: boolean = true;
  deepCopyForSpareDetailTable: any = {}


  model = {
    uId: {
      valuType: 'Number',
      value: '',
      label: "Id",
      isRequired: false,
      err: "",
      element: { name: 'input', type: 'number' }
    },
    showsparerecieptno: {
      valuType: 'String',
      value: '',
      label: "Spare Receipt No.",
      isRequired: false,
      disable: true,
      maxLength: 16,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    sparerecieptno: {
      valuType: 'String',
      value: '',
      label: "Spare Receipt No.",
      isRequired: false,
      disable: true,
      maxLength: 16,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    recieptdt: {
      valuType: 'Date',
      value: '',
      label: "Inward Date",
      isRequired: true,
      disable: true,
      err: "",
      element: { name: 'input', type: 'date' }
    },
    rmremark: {
      valuType: 'String',
      value: '',
      label: "Remark",
      isRequired: false,
      disable: true,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  sparereciepttableconfig: any = {
    "tableclasses": "table-striped sticky-header  horizontal-scroll table-lightblue table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "enablefreezepanes": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showonlysearch": true,
    "checkDeletedFlag": true,
    "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  };
  sparereciepttableheaders: any[] = [
    {
      "key": "sparerecieptno",
      "display": "Reciept No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "number",
      "decimaldigit": 0,
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-left",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "ReceiptNo",
      "display": "Receipt No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "decimaldigit": 0,
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-left",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "recieptdt",
      "display": "Receipt Date",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "date",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "rmremark",
      "display": "Remark",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
  ];
  sparereciepttabledata: any[] = [];

  sparerecieptlisttableconfig: any = {
    "tableclasses": "table-striped horizontal-scroll table-lightblue  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": true },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
    ],
    "exporttoexcel": false,
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false, "controlsfor": {} },
    "defaultcellmenus": [{
      "label": "Quick Update",
      "action": "quickupdate"
    }]
  };
  sparerecieptlisttableheaders: any = [
    {
      "key": "partno",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "200px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qty",
      "display": "Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "positive": true,
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qtyinhand",
      "display": "Current Balance",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },

    {
      "key": "wip",
      "display": "WIP",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "positive": true,
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "remark",
      "display": "Remark",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
  ];
  sparerecieptlisttabledata: any[] = [];

  sparerecieptitemtableconfig: any = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": true },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": true,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": true,
      "selectedFieldsArray": [
        {
          "key": 'qty',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    }
  };
  sparerecieptitemtableheader: any = [
    // {
    //   "key": "checkbox,",
    //   "display": "",
    //   "editable": false,
    //   "sorting": true,
    //   "allowaggregation": false,
    //   "enablehide": true,
    //   "visiblecolm": true,
    //   "decimaldigit": '',
    //   "datatype": "checkbox",
    //   "thstyle": { "text-align": "left", "width": "30px" },
    //   "tdalignment": "",
    //   "menulist": [{ "label": "Filter On", "action": "filter" }],
    //   "summary": []
    // },
    {
      "key": "partno",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },

    {
      "key": "qtyinhand",
      "display": "Qty In Hand",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "positive": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "wip",
      "display": "WIP",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qty",
      "display": "Quantity",
      "editable": true,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      //  "formula": "qtyinhand+wip",
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
  ];
  sparerecieptitemdata: any[] = [];

  constructor(private ngbActiveModal: NgbActiveModal,private submit: SubmitFormService, public commonService: CommonServicesService, public systemParamsService: SystemParamsService, private helper: HelperService, private configurationsetting: ConfigurationSetting, private toastService: ToastService) { }

  ngOnInit(): void {
    this.getSearchAllSpareReciept();
    if (this.isOpenFromReport) {
      this.getByIdClickCall(this.transactionID)
    }
  }

  createNew() {
    this.emptySearchField = false;
    this.label = "Add Spare Receipt";
    this.createNewBtn = true;
    this.operation = '';
    this.saveBtn = true;
    this.editBtn = false;
    this.cancelBtn = true;
    this.addItemButton = true;
    this.deleteMsg = "";
    this.btnRow = true;
    this.saveBtnVisible = true;
    this.deleteBtnDisable = false;
    this.sparerecieptlisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.todaysDate = new Date();
    this.model.recieptdt.value = this.todaysDate;
    this.model.rmremark.value = " "
    this.sparerecieptlisttableheaders = this.commonService.enableEditForGt(this.sparerecieptlisttableheaders, "qty", true);
    this.sparerecieptlisttableheaders = this.commonService.enableEditForGt(this.sparerecieptlisttableheaders, "remark", true);
    const ele = document.getElementById(`tr${"sparerectbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    this.sparerecieptlisttableconfig['actioncolmconfig']['displaycolumn'] = true;

    delete this.backupData.bindDataToFormAndTable;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  saveSpareRecieptForm() {
    let data = this.submit.submitForm(this.model)
    console.log(data);

    if (data) {
      this.saveBtn = true;
      let editedRow = [];
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.tabledataforprocess[i]['qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        if (this.tabledataforprocess[i]?.rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i])
        }
      }
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];

      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      let endpoint = 'transaction/insertSpareReciept';
      if (data["criteria"]['sparerecieptno']) {
        endpoint = 'transaction/updateSpareReciept';
        this.ErrorDescription = "Data Updated Successfully";
        data["criteria"]["recieptlist"] = this.tabledataforprocess;
      } else {
        this.ErrorDescription = "Data Inserted Successfully";
        data["criteria"]["recieptlist"] = this.tabledataforprocess;
      }

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.sprecieptuniqueno = res['Data']
          this.saveBtn = true;
          this.deleteBtnDisable = false;
          this.label = "Spare Receipt";
          this.addItemButton = false;
          this.cancelBtn = false;
          this.createNewBtn = false;
          this.saveBtnVisible = false;
          this.editBtn = true;
          this.commonService.diasbleFormFields(this.model);
          this.sparerecieptlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.sparerecieptlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.sparerecieptlisttableheaders), "qty", false);
          this.sparerecieptlisttableheaders = this.commonService.enableEditForGt(this.sparerecieptlisttableheaders, "remark", false);

          this.getSearchAllSpareReciept();
          this.PopupMessageModal.show();
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show()
        }
      }, (err) => { });
    }
  }

  deleteSpareRecieptForm() {
    let data = this.submit.submitForm(this.model);
    console.log(data);

    if (data || data["criteria"]["sparerecieptno"]) {

      data["criteria"]["recieptlist"] = this.tabledataforprocess;

      console.log(data["criteria"]);

      let items: any = {};
      items.sparerecieptno = data["criteria"]["sparerecieptno"];
      items.delLineItems = [];
      items.SpareNo = [];
      data["criteria"]["recieptlist"].forEach((ele) => {
        if (ele?.SrNo || ele?.SpareNo) {
          items.delLineItems.push(ele?.SrNo);
          items.SpareNo.push(ele?.SpareNo);
        }
      })
      data["criteria"] = items;

      let endpoint = 'transaction/deleteSpareReciept';

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('closePopupDelete')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.clearAll(false);
          this.deleteBtnDisable = false;
          this.getSearchAllSpareReciept();
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }


  }

  bindDataToFormAndTable(modal: any, flag: boolean = true) {
    if (this.model.sparerecieptno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");

      return;
    }
    this.refreshDetailTable = false;

    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    this.model.showsparerecieptno.value = modal['ReceiptNo'];
    // console.log(this.spareinwardlisttabledata[1]);
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.sparerecieptlisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"sparerectbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }
    this.sparerecieptlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.sparerecieptlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.sparerecieptlisttableheaders), "qty", false);
    this.sparerecieptlisttableheaders = this.commonService.enableEditForGt(this.sparerecieptlisttableheaders, "remark", false);
    this.sparerecieptlisttabledata = modal['recieptlist']?.length ? modal['recieptlist'] : [];
    let newArr = this.sparerecieptlisttabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.sparerecieptlisttabledata = newArr;
    setTimeout(() => {
      this.refreshDetailTable = true;
    }, 10);
    this.tabledataforprocessData = this.helper.removeRefrence(this.sparerecieptlisttabledata);
    // console.log(newArr);
    this.editBtn = true;
    this.saveBtn = true;
    this.saveBtnVisible = false;

    this.cancelBtn = false;
    this.editDisableBtn = false;
    this.addItemButton = false;
    this.deleteBtnDisable = false;
    this.createNewBtn = false;
    this.commonService.diasbleFormFields(this.model);
    this.isRowClicked = false;
  }


  selectItemsList(itemdata) {
    this.saveBtn = false;
    this.saveBtnVisible = true;
    itemdata = this.helper.removeRefrence(itemdata);
    let selectedArray = [...this.tabledataforprocess];
    this.sparerecieptlisttabledata = [];

    for (let item of itemdata) {
      let flag = true;
      this.sparerecieptlisttabledata.forEach(element => {
        if (element['partno'] == item['partno']) {
          flag = false;
          element['qty'] = item['qty'];
        }
      });
      if (flag)
        selectedArray.push(item);
    }

    this.cancelPopup('closepopupforsparereciept');
    this.sparerecieptlisttabledata = [...this.sparerecieptlisttabledata, ...selectedArray];
  }

  updatedtabledata(data: any) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;

    data.forEach((item, i) => {
      if (this.tabledataforprocessData[i] && this.operation === 'edit') {
        const prevQty = this.tabledataforprocessData[i]["qty"]
        this.priviousQty = prevQty;
        const prevQtyInHand = this.tabledataforprocessData[i]["qtyinhand"]
        const prevWip = this.tabledataforprocessData[i]["wip"]
        const currQty = item['qty'];
        const currQtyInHand = item['qtyinhand'];

        item['totalqty'] = prevQty - currQty;
        // item['qtyinhand'] = prevQtyInHand - item['totalqty'];
        // item['wip'] = prevWip + item['totalqty'];
        if (item['qty'] > item['wip']) {
          item['qty'] = this.tabledataforprocessData[i]["qty"];
        }
      }
    })
  }

  rowUpdate(data) {
    // this.tabledataforprocess = data;
    console.log(data);
    const currentValue = data.row.qty;
    data.row.rowmodifiedstatus = 'M';
    console.log(data.row.rowmodifiedstatus = 'M', 'data')

    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.wip || data?.row?.qty < 0) {
      this.ErrorDescription = "Quantity should not be greater than WIP";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.wip;
      this.ErrorObj = data.row;
      data.row.qty = this.priviousQty;
      this.errorMessageModal.show();
    }
  }

  rowUpdatePopup(data) {
    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.wip) {
      this.ErrorDescription = "Quantity should not be greater than WIP";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.wip;
      this.ErrorObj = data.row;
      data.row.qty = 0;
      this.errorMessageModal.show();
    }
  }

  editForm() {
    this.sparerecieptlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.operation = 'edit';
    this.sparerecieptlisttableheaders = this.commonService.enableEditForGt(this.sparerecieptlisttableheaders, "qty", true);
    this.sparerecieptlisttableheaders = this.commonService.enableEditForGt(this.sparerecieptlisttableheaders, "remark", true);
    this.label = "Edit Spare Receipt";

    this.editDisableBtn = true;
    this.cancelBtn = true;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.addItemButton = true;
    this.createNewBtn = true;
    this.deleteBtnDisable = true;
    this.commonService.enableFormFields(this.model);
    this.editBtn = false;
  }

  addItemBtn() {
    // this.sparerecieptitemdata = this.helper.removeRefrence(this.sparerecieptitemtabledata);
    // this.isPopUpOpen = true;
    this.getItemListForPopup()

  }

  clearAll(flag: boolean = true) {
    // console.log('editBtn', this.editBtn, 'editDisableBtn', this.editDisableBtn);
    this.commonService.clearForm(this.model);
    this.sparerecieptlisttabledata = [];
    this.label = "Spare Receipt";
    this.addItemButton = false;
    this.cancelBtn = false;
    this.btnRow = true;
    this.createNewBtn = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;

    this.deleteMsg = "";
    this.commonService.diasbleFormFields(this.model);
    this.sparerecieptlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    const ele = document.getElementById(`tr${"sparerectbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;

    }
  }


  cancleForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
        // this.createNewBtn = false;
      } else {
        this.clearAll(true);
      }
    }
  }

  cancelPopup(form) {
    this.sparerecieptitemdata = [];
    this.isPopUpOpen = false;
    switch (form) {
      case 'closepopupforsparereciept':
        this.showPopupForSpareRecieptList.hide();
        break;
      case "closePopupDelete":
        this.showDeleteModal.hide()
        break;
      case "itemalert":
        this.showItemAlert.hide();
        break;
    }
  }

  deleteRow(event) {
    let id = event['row'];
    let tabledata = this.helper.removeRefrence(this.sparerecieptlisttabledata);
    tabledata.splice(event.rowindex, 1);
    this.sparerecieptlisttabledata = []
    this.sparerecieptlisttabledata = tabledata;
    // console.log(event.rowindex);
    this.tabledataforprocess.splice(event.rowindex, 1);
    if (!this.tabledataforprocess.length) {
      this.showItemAlert.show();
      this.saveBtn = true;
      this.saveBtnVisible = false;
    } else {
      this.saveBtnVisible = true;
      this.saveBtn = false;
    }
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }



  getSearchAllSpareReciept() {
    const endpoint = 'transaction/getAllSpareReciept';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];

        this.sparereciepttabledata = []
        this.sparereciepttabledata = items;

        for (let i = 0; i < items.length; i++) {
          if (items[i]['sparerecieptno'] == this.sprecieptuniqueno || items[i]['sparerecieptno'] == this.model.sparerecieptno.value) {
            // console.log("Index", items[i], i);
            this.model.sparerecieptno.value = items[i]['sparerecieptno'];
            this.getByIdClickCall(this.model.sparerecieptno.value)
            if (!this.isOpenFromReport) {
              setTimeout(() => {
                $(`tr[tabindex=${i}]`).focus();
                document.getElementById('trsparerectbl1' + i).style.backgroundColor = "#7de4f0";
              }, 900);
            }
            break;
          }
        }

        // this.dcreciepttableheaders = successResp['Data']['tableheaders'];
        // this.dcreciepttableconfig = successResp['Data']['tableconfig'];
        this.isPopUpOpen = true;

      }
    }, err => { })
  }

  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.sparerecieptno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }

    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.sparereciepttabledata, modal.rowindex, this.currentSelectedRow, "sparerectbl1");

    let row = modal['row'];
    let sparerecieptno = row['sparerecieptno'];

    this.getByIdClickCall(sparerecieptno);
  }

  getByIdClickCall(no: any) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getSpareRecieptById";
    let params = { sparerecieptno: no };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']
        this.bindDataToFormAndTable(item);
      }
    }, (error) => {
      console.log(error);
    });
  }


  getItemListForPopup() {
    const endpoint = 'transaction/getSpareRecieptItemList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        items.forEach(element => {
          // element.item = element['itemcode'];
          // element.description = element['subassemblyname'];
          element.qty = '';
        });
        this.sparerecieptitemdata = []
        this.sparerecieptitemdata = items;
        this.deepCopyForSpareDetailTable = this.helper.removeRefrence(items);


        this.isPopUpOpen = true;

        this.showPopupForSpareRecieptList.show();
        this.filterSpareListTableIfExistingSubTable();

      }
    }, err => { })
  }

  filterSpareListTableIfExistingSubTable() {
    let preparedarr = [];
    preparedarr = this.deepCopyForSpareDetailTable.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < this.sparerecieptlisttabledata.length; ind++) {
        if (this.sparerecieptlisttabledata[ind]['partno'] == row['partno']) {
          recavailable = false;
        }
      }
      return recavailable;
    });
    this.sparerecieptitemdata = []
    this.sparerecieptitemdata = preparedarr;
  }

  closeErrorPopup() {
    this.errorMessageModal.hide();
    this.backendErrorMessageModal.hide();
    this.PopupMessageModal.hide();
  }


  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  closeModal() {
    this.ngbActiveModal.close();
  }
}
