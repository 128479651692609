export class LoginModel {
    username: string = "";
    password: string = "";
    ckey: string = "";
  
}

export class ConfirmationPopupModel{
    inputmsg:string="";
    submitbtntext:string="";
    displaysubmit:boolean=false;
    cancelbtntext:string="";
    displaycancel:boolean=false;
    editbtntext:string="";
    displayedit:boolean=false;
}