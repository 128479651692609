import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../../appconfig';
import { ApiService } from '../../../services/api.service';
import { CommonServicesService } from '../../../services/common-services.service';
import { HelperService } from '../../../services/helper.service';
import { SubmitFormService } from '../../../services/submit-form.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemParamsService } from '../../../services/system-params.service';
import { SpareMasterComponent } from '../../spare-master/spare-master.component';
import { LEFT_SIDE_TABLE_JSON, DETAIL_TABLE_JSON, POPUP_ITEMS_TABLE_JSON } from './spare-inward.gtconfig'
import { ToastService } from '../../../services/toast.service';
declare var $;
@Component({
  selector: 'app-spare-inward',
  templateUrl: './spare-inward.component.html',
  styleUrls: ['./spare-inward.component.scss']
})
export class SpareInwardComponent implements OnInit {

  @ViewChild('showPopupForSpareInwardList', { static: false }) public showPopupForSpareInwardList;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;
  createNewBtn: boolean = false;
  editBtn: boolean = false;
  btnRow: boolean = false;
  cancelBtn: boolean = false;
  saveBtn: boolean = true;
  addItemButton: boolean = false;
  currentSelectedRow: number;
  editDisableBtn: boolean = false;
  deleteBtnDisable: boolean = false;
  isPopUpOpen: boolean = false;
  saveBtnVisible: boolean = true;
  deleteMsg: string = "";
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  todaysDate: any = [];
  maxDate = new Date();
  label: string = 'Spare Inward';
  deletedArray: number[] = [];
  private backupData: any = { bindDataToFormAndTable: {} };
  tabledataforprocess: any[] = []
  private backuptabledataforprocess: any = [];
  userRole: string;
  spinwarduniqueno: number = 0;
  priviousQty: number = 0;
  priviousAcutalQty: number = 0;
  operation: string = "";
  emptySearchField: boolean = true;
  isRowClicked: boolean = false;
  refreshDetailTable:boolean = true;
  customerNameArray: {
    value: number;
    display: string;
  }[] = [];

  model = {
    SpareInwardSrNo: {
      valuType: 'String', value: '', label: "Spare Inward", disable: true,
      isRequired: false, maxLength: 16, err: "", element: { name: 'input', type: 'text' }
    },
    inwarddt: {
      valuType: 'Date', value: '', label: "Inward Date", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'date' }
    },
    sparechallanno: {
      valuType: 'Number', value: '', label: "Spare Challan No", isRequired: true,
      disable: true, maxLength: 20, err: "", element: { name: 'input', type: 'number' }
    },
    customername: {
      valuType: 'Number', value: '', label: "Customer Name", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    jmremark: {
      valuType: 'String', value: '', label: "Remark", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }
  deepCopyForSpareDetailTable: any = {}

  spareinwardtableconfig: any = LEFT_SIDE_TABLE_JSON.tableconfig;
  spareinwardtableheaders: any[] = LEFT_SIDE_TABLE_JSON.tableheaders;
  spareinwardtabledata: any[] = [];

  spareinwardlisttableconfig: any = DETAIL_TABLE_JSON.tableconfig;
  spareinwardlisttableheaders: any = DETAIL_TABLE_JSON.tableheaders;
  spareinwardlisttabledata: any[] = [];

  spareinwarditemtableconfig: any = POPUP_ITEMS_TABLE_JSON.tableconfig;
  spareinwarditemtableheader: any = POPUP_ITEMS_TABLE_JSON.tableheaders;
  spareinwarditemdata: any[] = [];

  constructor(private ngbActiveModal: NgbActiveModal, private ngbModal: NgbModal, private submit: SubmitFormService, public commonService: CommonServicesService, private apiData: ApiService, private configurationsetting: ConfigurationSetting, public systemParamsService: SystemParamsService, private helper: HelperService, private datepipe: DatePipe, private toastService: ToastService
  ) { }


  ngOnInit(): void {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    console.log(this.userRole);
    this.getCustomerName();
    this.getSearchAllSpareInward();
    if (this.isOpenFromReport) {
      this.getByIdClickCall(this.transactionID)
    }
  }

  createNew() {
    this.emptySearchField = false;
    this.label = 'Add Spare Inward';
    this.deleteMsg = ""
    this.operation = "new";
    this.createNewBtn = true;
    this.saveBtn = true;
    this.editBtn = false;
    this.deleteBtnDisable = false;
    this.cancelBtn = true;
    this.btnRow = true;
    this.saveBtnVisible = true;
    this.addItemButton = true;
    this.spareinwardlisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.todaysDate = new Date();
    this.model.inwarddt.value = this.todaysDate;
    this.model.jmremark.value = " "
    this.makeColumnsEditableAndUneditable(true);
    const ele = document.getElementById(`tr${"spareinwardtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    this.spareinwardlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    delete this.backupData.bindDataToFormAndTable;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  SaveSpareInwardForm() {
    let data = this.submit.submitForm(this.model);

    if (data) {
      this.saveBtn = true;
      let editedRow = [];
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.tabledataforprocess[i]['qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        if (this.tabledataforprocess[i]?.rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i]);
        }
      }
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];

      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      let endpoint = 'transaction/insertSpareInward';
      if (data["criteria"]['SpareInwardSrNo']) {
        endpoint = 'transaction/updateSpareInward';
        data["criteria"]["inwardlist"] = this.tabledataforprocess;
      } else {
        data["criteria"]["inwardlist"] = this.tabledataforprocess;
      }
      console.log(this.model);

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.spinwarduniqueno = res['Data'];
          this.label = 'Spare Inward';
          this.addItemButton = false;
          this.cancelBtn = false;
          this.createNewBtn = false;
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.editBtn = true;
          this.commonService.diasbleFormFields(this.model);
          this.spareinwardlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.deleteBtnDisable = false;
          this.makeColumnsEditableAndUneditable(false);
          this.getSearchAllSpareInward();
          this.ErrorDescription = res['errorDescription']
          this.PopupMessageModal.show();
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  deleteDcInwardForm() {
    let data = this.submit.submitForm(this.model);

    if (data || data["criteria"]["SpareInwardSrNo"]) {
      data["criteria"]["inwardlist"] = this.tabledataforprocess;
      let items: any = {};
      items.SpareInwardSrNo = data["criteria"]["SpareInwardSrNo"];
      items.customername = data["criteria"]["customername"];
      items.delLineItems = [];
      items.SpareNo = [];
      data["criteria"]["inwardlist"].forEach((ele) => {
        if (ele?.SrNo || ele?.SpareNo) {
          items.delLineItems.push(ele?.SrNo);
          items.SpareNo.push(ele?.SpareNo);
        }
      })
      data["criteria"] = items;

      let endpoint = 'transaction/deleteSpareInward';
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('closePopupDelete');
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.clearAll(false);
          this.deleteBtnDisable = false;
          this.getSearchAllSpareInward();
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        }else{
          this.cancelPopup('closePopupDelete');
          this.ErrorDescription = "Can not delete record";
          this.PopupMessageModal.show();
        }
      }, (err) => { });
    }
  }

  bindDataToFormAndTable(modal: any, flag: boolean = true) {

    if (this.model.sparechallanno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.refreshDetailTable = false;
    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    if (modal["isDeleted"] === 1) {

      // for (let item in this.model) {
        this.model.customername.value = null;
        this.model.SpareInwardSrNo.value = "";
        this.model.jmremark.value = "";
        this.model.sparechallanno.value = "";
        this.model.inwarddt.value = "";
        this.spareinwardlisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"spareinwardtbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      // }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }
    this.spareinwardlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.makeColumnsEditableAndUneditable(false);
    this.spareinwardlisttabledata = [];

    this.spareinwardlisttabledata = modal['spareinwardlist']?.length ? modal['spareinwardlist'] : [];

    let newArr = this.spareinwardlisttabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.spareinwardlisttabledata = newArr;
    // console.log(newArr);
    setTimeout(() => {
      this.refreshDetailTable = true;
    }, 10);

    this.backuptabledataforprocess = this.helper.removeRefrence(this.spareinwardlisttabledata);

    this.editBtn = true;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.label = 'Display Spare Inward';
    this.cancelBtn = false;
    this.editDisableBtn = false;
    this.addItemButton = false;
    this.deleteBtnDisable = false;
    this.createNewBtn = false;
    this.commonService.diasbleFormFields(this.model);
    this.isRowClicked = false;

  }

  updatedtabledata(data: any) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;
    console.log("updated", data);

    data.forEach((item, i) => {
      if(this.operation === 'new'){
        if(item['qty'] == item['actualqty']){
          item['descripencyStatus'] = "N";
        }else{
          item['descripencyStatus'] = "D";
        }
      }
      if (this.backuptabledataforprocess[i] && this.operation === 'edit') {

        this.priviousQty = this.backuptabledataforprocess[i]["qty"]
        this.priviousAcutalQty = this.backuptabledataforprocess[i]["actualqty"]
        item['backupQty'] = this.backuptabledataforprocess[i]["qty"]
        item['backupActualQty'] = this.backuptabledataforprocess[i]["actualqty"]
        const prevQtyInHand = this.backuptabledataforprocess[i]["qtyinhand"]

        if(item['qtyinhand']<item['qty']){
          item['prevqty'] = this.backuptabledataforprocess[i]["qty"]
          item['totalqty'] = item['qtyinhand'] - item['qty'];
        }

        // item['totalqty'] = prevQtyInHand;

        // console.log("item['totalqty']", item['totalqty']);
        // if(item['qty']<item['totalqty']){
        //   item['qty'] = item['totalqty'];
        // }
        // item['qty'] = item['actualqty'];
      }

    })
  }

  rowUpdate(data) {
    data.row.rowmodifiedstatus = 'M';
    let tot = Math.abs(data?.row?.totalqty);

    if(data?.row['descripencyStatus'] == "P"){
      this.ErrorDescription = "Spare Discrepancy has been created for this item.";
      this.errorMessageModal.show();
      data.row.qty = data.row.backupQty;
      data.row.actualqty = data.row.backupActualQty;
      return;
    }

    if (data?.row?.qty < tot && this.operation === 'edit') {
      this.ErrorDescription = `Qty in hand can be negative if you enter Qty ${data?.row?.qty}. `;
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      data.row.qty = data?.row?.prevqty;
      this.errorMessageModal.show();
    }

    if(data['keyName']=='qty'){
      data.row.actualqty = data?.row?.qty
    }

    if (data?.row?.qty == data?.row?.actualqty) {
      data['row']['descripencyStatus'] = "N";
    } else {
      data['row']['descripencyStatus'] = "D";
    }


  }



  editForm() {
    this.spareinwardlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.makeColumnsEditableAndUneditable(true);
    this.label = 'Edit Spare Inward';
    this.editDisableBtn = true;
    this.operation = "edit"
    this.cancelBtn = true;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.createNewBtn = true;
    this.addItemButton = true;

    if (this.userRole === "Admin") {
      this.deleteBtnDisable = true;
    } else {
      this.deleteBtnDisable = false;
    }
    this.commonService.enableFormFields(this.model);
    this.editBtn = false;
  }



  clearAll(flag: boolean = true) {
    this.commonService.clearForm(this.model);
    this.spareinwardlisttabledata = [];
    this.label = 'Spare Inward';
    this.addItemButton = false;
    this.cancelBtn = false;
    this.createNewBtn = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.btnRow = false;
    this.commonService.diasbleFormFields(this.model);
    this.spareinwardlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    const ele = document.getElementById(`tr${"spareinwardtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;
    }
  }

  cancleForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
      } else {
        this.clearAll(true);
      }
    }
  }

  cancelPopup(form) {
    this.spareinwarditemdata = [];
    this.isPopUpOpen = false;
    switch (form) {
      case 'closepopupforspareinward':
        this.showPopupForSpareInwardList.hide();
        break;
      case "closePopupDelete":
        this.showDeleteModal.hide()
        break;
      case "itemalert":
        this.showItemAlert.hide();
        break;
    }
  }

  deleteRow(event) {
    let id = event['row'];
    let tabledata = this.helper.removeRefrence(this.spareinwardlisttabledata);
    tabledata.splice(event.rowindex, 1);
    this.spareinwardlisttabledata = []
    this.spareinwardlisttabledata = tabledata;
    if (!this.tabledataforprocess.length) {
      this.showItemAlert.show();
      this.saveBtn = true;
      this.saveBtnVisible = false;
    } else {
      this.saveBtn = false;
      this.saveBtnVisible = true;
    }
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }

  openNewItemMaster1() {
    const ngbModalRef = this.ngbModal.open(SpareMasterComponent, { windowClass: "allocate-modal-pop", backdrop: 'static' });
    ngbModalRef.componentInstance.showonlyForm = true;


    ngbModalRef.result.then((result) => {

      if (result && result?.status == "save") {
        // this.getItemListForPopup();
        setTimeout(() => {

          console.log(result);
        }, 5000);
      }
    });
  }

  openNewItemMaster() {
    const ngbModalRef = this.ngbModal.open(SpareMasterComponent, { windowClass: "allocate-modal-pop", backdrop: 'static' });
    ngbModalRef.componentInstance.showonlyForm = true;
    ngbModalRef.result.then((result) => {
      // console.log(result);
      if (result && result?.status == "save") {

        this.ErrorDescription = "Data Inserted Successfully"
        this.PopupMessageModal.show();
        this.getItemListForPopup();
      }
    });
  }

  getMasterTable() {
    this.commonService.getGenericTable("SpareMaster", this.configurationsetting.getBaseUrl())
      .subscribe(successResp => {
        if (successResp['errorCode'] == 0) {
          const items = successResp['Data'][0]['@tbldata'];
          items.forEach(element => {
            element.partno = element['partno']
            element.sparename = element['sparename'];
            element.wip = element['wip'];
            // console.log(element.sparename);
          });
          this.spareinwarditemdata = []
          this.spareinwarditemdata = successResp['Data'][0]['@tbldata'];
          this.isPopUpOpen = true;

          this.showPopupForSpareInwardList.show();
        }
      }, err => { })
  }

  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }

  getSearchAllSpareInward() {
    const endpoint = 'transaction/getAllSpareInward';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        this.spareinwardtabledata = []
        this.spareinwardtabledata = items;
        for (let i = 0; i < items.length; i++) {
          if (items[i]['SpareInwardSrNo'] == this.spinwarduniqueno || items[i]['SpareInwardSrNo'] == this.model.SpareInwardSrNo.value) {
            // console.log("Index", this.tabledata[i], i);
            this.model.SpareInwardSrNo.value = items[i]['SpareInwardSrNo'];
            this.getByIdClickCall(this.model.SpareInwardSrNo.value)
            if (!this.isOpenFromReport) {
            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trspareinwardtbl1' + i).style.backgroundColor = "#7de4f0";
            }, 900);
          }
            break;
          }
        }
        this.isPopUpOpen = true;
      }
    }, err => { })
  }


  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.sparechallanno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }

    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.spareinwardtabledata, modal.rowindex, this.currentSelectedRow, "spareinwardtbl1");
    let row = modal['row'];
    let SpareInwardSrNo = row['SpareInwardSrNo'];
    this.getByIdClickCall(SpareInwardSrNo);
  }

  getByIdClickCall(no: any) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getSpareInwardById";
    let params = { SpareInwardSrNo: no };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']['tabledata']
        this.bindDataToFormAndTable(item);

      }
    }, (error) => {
      console.log(error);
    });
  }

  selectItemsList(itemdata) {
    this.saveBtn = false;
    this.saveBtnVisible = true;

    let deppCopySubDynamicTable = this.helper.removeRefrence(this.tabledataforprocess);

    console.log(itemdata);

    itemdata = this.helper.removeRefrence(itemdata);
    this.spareinwardlisttabledata = [];

    itemdata.forEach((item) => {
      const { SpareNo, partno, description, wip, qtyinhand, actualqty, qty } = item;
      deppCopySubDynamicTable.push({ SpareNo, partno, description, wip, actualqty, qtyinhand, qty });
    })

    this.spareinwardlisttabledata = deppCopySubDynamicTable;
    this.cancelPopup('closepopupforspareinward');
  }

  popupRowUpdate(data){
    if(data['keyName']=='qty'){
      data.row.actualqty = data?.row?.qty
    }
  }

  popupupdatedtabledata(data){
    // console.log(data);

    // data.forEach((item)=>{
    //   if((!item?.actualqty) && item['qty'] >= 0){
    //     item.actualqty = item?.qty
    //   }
    // })

  }

  addItemBtn() {
    // this.emptySearchField = false;

    // setTimeout(() => {
    //   this.emptySearchField = true;
    // }, 10);

    if(this.commonService.DB_Deep_Copy['SpareInwardDBDeepCopy'] && this.commonService.DB_Deep_Copy['SpareInwardDBDeepCopy'].length){
      this.spareinwarditemdata = []
        this.spareinwarditemdata = this.helper.removeRefrence(this.commonService.DB_Deep_Copy['SpareInwardDBDeepCopy']);
        this.deepCopyForSpareDetailTable = this.helper.removeRefrence(this.commonService.DB_Deep_Copy['SpareInwardDBDeepCopy']);


        this.isPopUpOpen = true;
        this.showPopupForSpareInwardList.show();
        this.filterSpareListTableIfExistingSubTable();
        console.log("IF");

      }else{
        console.log("ELSE");

        this.getItemListForPopup();
      }


  }

  filterSpareListTableIfExistingSubTable() {
    let preparedarr = [];

    preparedarr = this.deepCopyForSpareDetailTable.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < this.spareinwardlisttabledata.length; ind++) {
        if (this.spareinwardlisttabledata[ind]['partno'] == row['partno']) {
          recavailable = false;
        }
      }
      return recavailable;
    });

    this.spareinwarditemdata = []
      this.spareinwarditemdata = preparedarr;

  }

  getItemListForPopup() {
    const endpoint = 'transaction/getSpareInwardItemList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        items.forEach(element => {
          element.qty = '';
        });
        this.spareinwarditemdata = []
        this.spareinwarditemdata = items;

        this.deepCopyForSpareDetailTable = this.helper.removeRefrence(items);
        this.commonService.DB_Deep_Copy['SpareInwardDBDeepCopy'] = items;
        this.isPopUpOpen = true;
        this.showPopupForSpareInwardList.show();
        this.filterSpareListTableIfExistingSubTable();
      }
    }, err => { })
  }

  closeErrorPopup() {
    this.errorMessageModal.hide();
    this.backendErrorMessageModal.hide();
    this.PopupMessageModal.hide();
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  makeColumnsEditableAndUneditable(flag: boolean) {
    this.spareinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.spareinwardlisttableheaders), "qty", flag);
    this.spareinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.spareinwardlisttableheaders), "actualqty", flag);
    this.spareinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.spareinwardlisttableheaders), "remark", flag);
    this.spareinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.spareinwardlisttableheaders), "descripencyremark", flag);
  }

  
  closeModal() {
    this.ngbActiveModal.close();
  }
}
