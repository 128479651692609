export const REJECTED_OUT_LEFT_SIDE_TABLE_JSON =  {
  tableconfig:{
    "tableclasses": "table-striped sticky-header  horizontal-scroll table-lightblue  table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "enablefreezepanes": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showonlysearch": true,
    "checkDeletedFlag": true,
    "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  },
  tableheaders: [
    {
      "key": "dcoutwardsrno",
      "display": "Rejected No",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": false,
      "decimaldigit": 0,
      "datatype": "number",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "icon": "fa fa-search",
      "title": "Search Item Name",
      "thclass": "",
      "topheader": "",
      "thstyle": {
        "text-align": "center",
        "width": "60px"
      },
      "tdalignment": "hr-left vt-center",
      "menulist": [{
        "label": "Filter On",
        "action": "filter"
      }],
      "summary": []
    },
    {
      "key": "DcOutNo",
      "display": "Rejected No",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "icon": "fa fa-search",
      "title": "Search Item Name",
      "thclass": "",
      "topheader": "",
      "thstyle": {
        "text-align": "center",
        "width": "60px"
      },
      "tdalignment": "hr-left vt-center",
      "menulist": [{
        "label": "Filter On",
        "action": "filter"
      }],
      "summary": []
    },
     {
      "key": "rejecteddate",
      "display": "Outward Date",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "date",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "icon": "fa fa-search",
      "title": "Search Item Name",
      "thclass": "",
      "topheader": "",
      "thstyle": {
        "text-align": "center",
        "width": "100px"
      },
      "tdalignment": "hr-left vt-center",
      "menulist": [{
        "label": "Filter On",
        "action": "filter"
      }],
      "summary": []
    }
  ],
  tabledata:[]
};

export const REJECTED_OUT_DETAILED_TABLE_JSON =  {
  tableconfig:{
    "tableclasses": "table-striped sticky-header sorting  horizontal-scroll table-lightblue  table-bordered",
    "summary": ["sum"],
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "enablefirstbtn": false,
    "firstbtntext": "Add Item",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": true }
  },
  tableheaders:[
    {
      "key": "itemname",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "modelname",
      "display": "Model",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-left vt-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "jmdescription",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "200px" },
      "tdalignment": "hr-left vt-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "jobno",
      "display": "Job No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "refrenceno",
      "display": "Ref No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive": true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "quantity",
      "display": "Quantity",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": ["sum"]
    },
    {
      "key": "invoicependingqty",
      "display": "Invoice Pending Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": false,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []
    },{
      "key": "maxquantity",
      "display": "Max Quantity",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": false,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []
    },
    {
      "key": "hiddenoutquantity",
      "display": "Out Qty",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "30px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "subAsyNo",
      "display": "SubAsy No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "20px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "remark",
      "display": "Remark",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center vt-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "dnno",
      "display": "Dc Inward No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
  ],
  tabledata:[]
};


export const REJECTED_OUT_SELECTITEM_TABLE_JSON =  {
  tableconfig:{
    "tableclasses": "table-striped sticky-header sorting  horizontal-scroll table-lightblue  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "controlconfig": { "displaycontrol": true, "controlsfor": {} },
    "defaultcellmenus": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": true,
      "selectedFieldsArray": [
        {
          "key": 'outquantity',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    },
    'checkedfilter': {
      "action" : "filter",
      "datatype": "string",
      "key":'customername',
      "checkOnKey":'outquantity',
      "conditionkey":'checked',
      "checkboxtype":'checkboxfilter'
    }
  },
  tableheaders: [
    {
      "key": "checkbox",
      "display": "",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "checkbox",
      "thstyle": { "text-align": "left", "width": "30px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "refrenceno",
      "display": "Ref No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "dnno",
      "display": "Dc Inward No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "issuedate",
      "display": "DC Inward Date",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "date",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "itemname",
      "display": "Item No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "modelname",
      "display": "Model",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "subAsyNo",
      "display": "SubAsy No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "20px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "jmdescription",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "jobno",
      "display": "Job No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "quantity",
      "display": "Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "outquantity",
      "display": "Out Qty",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "30px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "hiddenoutquantity",
      "display": "Out Qty",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "30px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },

    {
      "key": "esn",
      "display": "ESN",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "esncustomer",
      "display": "Customer",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "customername",
      "display": "Customer",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "itemname",
      "display": "Item Name",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "engtypejeop",
      "display": "Eng Type",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "jmremark",
      "display": "Remark",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "dnno",
      "display": "DC No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    }
  ],
  tabledata:[]
};


export const REJECTED_OUT_CONFIRM_PO_TABLE_JSON =  {
  tableconfig:{
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": false },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": true,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "customactionbuttons": [],
    "buttonspriority": "row",

  },
  tableheaders: [
    {
      "key": "PoNo",
      "display": "PO Number",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "70px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "200px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "poqty",
      "display": "PO Quantity",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "thstyle": { "text-align": "left", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "poqtybilled",
      "display": "Qty Billed",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "thstyle": { "text-align": "left", "width": "90px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "poqtybalance",
      "display": "Balance",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "thstyle": { "text-align": "left", "width": "70px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata:[]
};

