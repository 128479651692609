export const LEFT_SIDE_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped sticky-header  horizontal-scroll table-lightblue  table-bordered",
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "enablefreezepanes": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showonlysearch": true,
    "checkDeletedFlag": true,
    "checkDeletedFlagKey": "isDeleted",
    "fullheighttable":"fullheight-table"
  },
  tableheaders: [
    {
      "key": "manualdnsrno",
      "display": "Manual Inward No.",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "icon": "fa fa-search",
      "title": "Search Item Name",
      "thclass": "",
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "ManualNoteInwardNo",
      "display": "Manual Inward No.",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": false,
      "decimaldigit": 0,
      "datatype": "number",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "icon": "fa fa-search",
      "title": "Search Item Name",
      "thclass": "",
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "manualdndate",
      "display": "Inward Date",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "date",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "icon": "fa fa-search",
      "title": "Search Item Name",
      "thclass": "",
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata: []
}

export const DETAIL_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped sticky-header sorting  horizontal-scroll table-lightblue  table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "enablefirstbtn": false,
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": true },
  },
  tableheaders: [
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "250px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qty",
      "display": "Quantity",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "remark",
      "display": "Remark",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata: []
}
