import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
//////version 1.0.0
@Component({
  selector: 'dynamicform',
  templateUrl: './dynamicform.component.html',
  styleUrls: ['./dynamicform.component.scss']
})
export class DynamicformComponent implements OnInit {


  @ViewChild("myForm", { static: true }) myForm: NgForm;
  @Output() isFormValid = new EventEmitter();
  //@Input() myData = {};inputformdata
  @Input() inputformdata = {};
  @Input() errorMessage: boolean = true;
  @Input() iconLabel: boolean = false;
  @Input() inputTextCss: string = "";
  @Input() errorCss: string = "";
  @Input() iconerrorCss: string = "";
  @Input() labelCss: string = "";
  @Input() iconlabelCss: string = "";
  @Input() customButton: string = "";
  @Input() httperrormsg: string = "";
  @Input() httperrorcss = {};
  @Input() customSaveBtnClick: Observable<void>;
  private eventsSubscription: Subscription;
  private myValidFormSubscription: Subscription;

  @Output() textChanged = new EventEmitter();
  @Output() numberChanged = new EventEmitter();

  @Output() submitedData = new EventEmitter();
  @Output() cancelSubmission = new EventEmitter();
  @Output() changeSelectList = new EventEmitter();
  @Output() changeRadioButton = new EventEmitter();
  @Output() changeDateTime = new EventEmitter();
  @Output() changeCheckBox = new EventEmitter();
  @Output() changeSingleChange = new EventEmitter();
  @Output() normalBtnClickEvent = new EventEmitter();
  @Output() keyUpEvent = new EventEmitter();
  @Output() ngModelChangeEvent = new EventEmitter();
  @Output() newFormEvent = new EventEmitter();
  invalidform: boolean = false;
  hideDate: boolean = true;
  @Output() resetForm = new EventEmitter();

  @Input() canShowFormBtn: boolean = true;

  constructor() { }

  ngOnInit() {
    if (this.customSaveBtnClick)
      this.eventsSubscription = this.customSaveBtnClick.subscribe((data: any) => {
        if (data == 'save') {
          this.submitData(this.myForm.value);
        }
        // console.log("SaveBtn", data);
      });

    setTimeout(() => {
      this.setNgClass()
    }, 300)


  }

  ngAfterViewInit(){
    if (this.customSaveBtnClick){
      this.myValidFormSubscription = this.myForm.form.valueChanges.subscribe((value) => {
        // console.log(value, this.myForm.invalid, this.isFormValid);
        this.isFormValid.emit(this.myForm.invalid)
      })
    }
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
    this.myValidFormSubscription?.unsubscribe();
  }

  // resetFormData(){
  //   debugger;
  //   this.myForm.reset();
  //   this.resetForm.emit();
  // }

  /// text event /////
  getTextChangeEvent(event, controlname, controlid, isitrequired) {
    let obj = {};
    obj["value"] = event;
    obj["controlname"] = controlname;
    obj["controlid"] = controlid;
    obj["isitrequired"] = isitrequired;

    this.textChanged.emit(obj);
  }
  numberChange(event, controlname, controlid, isitrequired) {
    let obj = {};
    obj["value"] = event;
    obj["controlname"] = controlname;
    obj["controlid"] = controlid;
    obj["isitrequired"] = isitrequired;
    this.ngModelChangeEvent.emit(obj);


  }
  getTextKeyUpEvent(event, controlname, controlid, isitrequired) {
    let obj = {};
    obj["value"] = event['currentTarget']['value'];
    obj["controlname"] = controlname;
    obj["controlid"] = controlid;
    obj["isitrequired"] = isitrequired;
    this.keyUpEvent.emit(obj);
  }
  getTextKeyDownEvent(textevent, onlydecimal, pattern) {
    if (onlydecimal) {
      let decimalpattern = new RegExp(pattern);
      let result = decimalpattern.test(textevent['currentTarget']['value'])
      if (!result) {
        event.preventDefault();
      }
      // if (event.keyCode < 65 || event.keyCode > 90)
      //   // if (event.keyCode != 9 && event.keyCode != 8 && event.keyCode != 32 && event.keyCode != 37 && event.keyCode != 39 && event.keyCode != 17)
      //     event.preventDefault();
      return true;
    }
  }
  getTextKeyPressEvent(event) { }

  /// number event /////
  getNumberChangeEvent(event, controlname, controlid, isitrequired) {
    let obj = {};
    obj["value"] = event;
    obj["controlname"] = controlname;
    obj["controlid"] = controlid;
    obj["isitrequired"] = isitrequired;
    this.numberChanged.emit(obj);
  }
  getNumberKeyUpEvent(numberevent, value, onlynumber: boolean) {
    //event.preventDefault();
    ////190-Dot(.)
    ////187-Plus(+)
    ////189-minus(-)
    event.preventDefault();
    // if (onlynumber && (numberevent['keyCode'] == 189 || numberevent['keyCode'] == 187 || numberevent['keyCode'] == 190)) {
    //   event.preventDefault();
    // }
    // else if (!onlynumber && (numberevent['keyCode'] == 187 || numberevent['keyCode'] == 189)) {
    //   event.preventDefault();
    // }
  }
  getNumberKeyDownEvent(numbedrevent, value, maxlength: number) {
    ////190-Dot(.)
    ////187-Plus(+)
    ////189-minus(-)
    ////69- e(small e)
    ////8 -Backspace
    if (numbedrevent['keyCode'] == 69 || numbedrevent['keyCode'] == 189 || numbedrevent['keyCode'] == 187 || numbedrevent['keyCode'] == 190) {
      event.preventDefault();
    }
    else if ((numbedrevent['keyCode'] != 8) && value != undefined && value != null) {
      let str: string = value.toString();
      if (str.trim().length == maxlength)
        event.preventDefault();
    }
  }
  getNumberKeyPressEvent(event, value) {
    // event.preventDefault();
  }

  /// textarea event /////
  getTextareaChangeEvent(event) { }
  getTextareaKeyUpEvent(event) { }
  getTextareaKeyDownEvent(event) {


  }
  getTextareaKeyPressEvent(event) { }

  /// email event /////
  getEmailChangeEvent(event) { }
  getEmailKeyUpEvent(event) { }
  getEmailKeyDownEvent(event) { }
  getEmailKeyPressEvent(event) { }

  /// date event /////
  getDateChangeEvent(event) { }

  /// radio event /////
  getRadioChangeEvent(event) { }

  /// checkbox event /////
  getCheckboxChangeEvent(event) { }

  /// below code will execute if user change the selected item/option /////
  getSelectChangeEvent(event, controlname, controlid, isitrequired) {
    let obj = {};
    obj["value"] = event['currentTarget']['value'];
    obj["controlname"] = controlname;
    obj["controlid"] = controlid;
    obj["isitrequired"] = isitrequired;
    this.changeSelectList.emit(obj);
  }

  ////////when user click on Save/Continue/Next/Submit

  submitData(values) {
    for (var key in this.inputformdata["controls"]) {
      if (this.inputformdata["controls"].hasOwnProperty(key)) {
        var val = this.inputformdata["controls"][key];
        if (val["type"] == "checkbox") {
          values[key] = [];
          val["options"].forEach(element => {
            if (element["checked"] == true) {
              values[key].push(element["value"]);
            }
            delete values[element['display']];
          });
        }
      }
    }
    this.submitedData.emit(values);
  }

  ////////when user click on cancel/close/back button/Reset
  cancelForm() {

    this.cancelSubmission.emit();
  }

  newForm() {
    this.newFormEvent.emit();
  }

  normalBtnClick(event, id, name) {
    let obj = {};
    obj['event'] = event;
    obj['id'] = id;
    obj['name'] = name;
    this.normalBtnClickEvent.emit(obj)
  }
  ///////if user select a radio button then change event will be fire
  /////event parameter will take an object which is returned by the radio button control

  changRadiobutton(radioevent, controlname, isrequired) {
    if (isrequired)
      document.getElementById(controlname).className = "valid-rd-ch";
    this.changeRadioButton.emit(radioevent);
  }

  onDateTimeChange(inputdate, id, maxdate, mindate, dateformat) {
    if (inputdate != null && inputdate != "Invalid Date") {
      let obj = {};
      obj["selecteddatetime"] = inputdate;
      obj["controlname"] = id;
      obj["maxdate"] = maxdate;
      obj["mindate"] = mindate;
      obj["dateformat"] = dateformat;
      this.changeDateTime.emit(obj);
    }
  }

  //////////if user change check box or check unchecked happen
  changeCheckBoxes(checkboxevent, controlname, value, options, name) {
    // document.getElementById(controlname).className = "valid-rd-ch";
    this.checkingCheckedElement(controlname, checkboxevent['currentTarget']['checked'], name);
    let obj = {};
    obj["checked"] = checkboxevent['currentTarget']['checked'];
    obj["controlname"] = controlname;
    obj["value"] = value;
    obj["options"] = options;
    this.changeCheckBox.emit(obj);
  }

  checkingCheckedElement(controlname, isitchecked, name) {
    let checked: boolean = false;
    this.invalidform = false;
    let checkedcount: number = 0;
    for (let i = 0; i < this.inputformdata['controls'][controlname]['options'].length; i++) {
      if (this.inputformdata['controls'][controlname]['options'][i]['checked']) {
        checked = true;
        checkedcount++;
      }
      else {
        document.getElementById(name)['checked'] = false;
      }
    }
    if (this.inputformdata['controls'][controlname]['required']) {
      if (checked) {
        if (checkedcount == this.inputformdata['controls'][controlname]['options'].length) {
          document.getElementById(name)['checked'] = true;
        }
        document.getElementById(controlname).className = "valid-rd-ch";
        return;
      }
      else {
        document.getElementById(controlname).className = "mandatory-rd-ch";
        this.invalidform = true;
      }
    }
    else {
      if (checkedcount == this.inputformdata['controls'][controlname]['options'].length) {
        document.getElementById(name)['checked'] = true;
      }
      document.getElementById(controlname).className = "non-mandatory";
    }
  }



  /////below code will execute when single change event fire
  changeSingleSelection(singlechangeevent) {
    this.changeSingleChange.emit(singlechangeevent)
  }

  pasteOnTextBox(pasteevent, allowpaste: boolean) {
    //let content = event.clipboardData.getData('text/plain');
    if (allowpaste != undefined && !allowpaste) {
      pasteevent.preventDefault();
    }
  }

  ///////paste on input type number
  pasteOnNumberControl(pastenoevent, allowpaste: boolean) {
    //let content = event.clipboardData.getData('text/plain');
    if (allowpaste != undefined && !allowpaste) {
      pastenoevent.preventDefault();
    }
  }

  /////past on Email Text Box
  pasteOnEmail(pastenoevent, allowpaste: boolean) {
    //let content = event.clipboardData.getData('text/plain');
    if (allowpaste != undefined && !allowpaste) {
      pastenoevent.preventDefault();
    }
  }
  /////past on Text Area
  pasteOnTextArea(textareaevent, allowpaste: boolean) {
    //let content = event.clipboardData.getData('text/plain');
    if (allowpaste != undefined && !allowpaste) {
      textareaevent.preventDefault();
    }
  }


  setNgClass() {
    if (!this.inputformdata || !this.inputformdata['css'] || (this.inputformdata['css'] && !this.inputformdata['css']['mappingarray'])) {
      return;
    }

    for (let i = 0; i < this.inputformdata['css']['mappingarray'].length; i++) {
      for (let j = 0; j < this.inputformdata['css']['mappingarray'][i].length; j++) {
        if (this.inputformdata['css']['mappingarray'][i][j]['keyname']) {
          if (this.inputformdata['controls'][this.inputformdata['css']['mappingarray'][i][j]['keyname']]['type'] === "checkbox") {
            for (let k = 0; k < this.inputformdata['controls'][this.inputformdata['css']['mappingarray'][i][j]['keyname']]['options'].length; k++) {
              if (this.inputformdata['controls'][this.inputformdata['css']['mappingarray'][i][j]['keyname']]['options'][k]["checked"] && this.inputformdata['controls'][this.inputformdata['css']['mappingarray'][i][j]['keyname']]['required']) {
                document.getElementById(this.inputformdata['css']['mappingarray'][i][j]['keyname']).className = "valid-rd-ch";
                this.invalidform = false;
                break;
              }
              else if (!this.inputformdata['controls'][this.inputformdata['css']['mappingarray'][i][j]['keyname']]['required']) {
                this.invalidform = false;
              }
              else if (this.inputformdata['controls'][this.inputformdata['css']['mappingarray'][i][j]['keyname']]['required']) {
                this.invalidform = true;
              }
            }
          }
        }
      }
    }

  }

  ///////////below code will execute if user click on select all checkbox option
  selectAllCheckboxes(selectallevent, controlname) {
    for (let i = 0; i < this.inputformdata['controls'][controlname]['options'].length; i++) {
      this.inputformdata['controls'][controlname]['options'][i]['checked'] = selectallevent['currentTarget']['checked'];
    }
    this.checkingCheckedElement(controlname, false, controlname)
  }
}
