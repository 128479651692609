import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ConfigurationSetting } from '../../appconfig';
@Injectable()
export class AuthService {

    private baseURL = "";
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient, private config:ConfigurationSetting,private route:Router) {
        this.baseURL = config["EnvironmentObject"][config]["apiUrl"] + "/"
        console.log("baseurl", this.baseURL);

        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }



    loginsignup(params) {
        return this.http.post<any>(this.baseURL + "users/login", params).pipe(
            map(data => {
                // console.log(data);

                if (data && data.Data.ntk) {
                    // console.log(true);

                    this._syncUser(data);
                }
                return data;
            }));
    }



    refreshtoken(params = null) {
        return this.http.post<any>(this.baseURL + "refreshtoken", params).pipe(
            map(data => {
                this._syncUser(data);
                return data;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.route.navigateByUrl("/login");
        this.currentUserSubject.next(null);
    }

    _syncUser(data) {
        // localStorage.setItem('currentUser', JSON.stringify(data));
        this.currentUserSubject.next(data);
    }

}
