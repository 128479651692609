import { Component, OnInit, Input } from '@angular/core';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonServicesService } from '../../services/common-services.service'
import { ConfigurationSetting } from '../../appconfig';
import { ConfirmationPopupModel } from '../../models/models';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-printjobsheet',
  templateUrl: './printjobsheet.component.html',
  styleUrls: ['./printjobsheet.component.scss']
})
export class PrintjobsheetComponent implements OnInit {
  httpHeaders = new HttpHeaders();
  @Input('jobcardSrNo') jobcardSrNo: number;
  showPrintInvoice: boolean = false;
  ReportJSONArr: any[] = [];
  qty: number = 0;
  // jobcardSrNo:any;
  getGobSheetDetails: any;
  parts: any[] = [];                  // array of parts with "islabour"="N"
  services: any[] = [];               // array of parts with "islabour"="Y" called Services
  companydetails: any[] = [];
  companylogo: any;
  partservicearr: any[] = [];
  extracomplaint: any = [];

  mindx;

  errormsg: string = "";
  companyname: string = "";
  tagline1: string = "";
  tagline2: string = "";
  address1: string = "";
  address2: string = "";
  address3: string = "";
  state: string = "";
  customer: string = "";
  description: string = "";

  cdssDnNo: any;
  jobcardno: any;
  jobno: any;
  pincode: any;
  stdcode: any;
  phoneno1: any;
  phoneno2: any;
  mobileno: any;
  companygstnumber: any;
  totalpart: any;
  totalservice: any;
  totalpartservice: any;
  lengthofcomplaints: any;
  defaultrowcountforcomplaints: any;

  date: any;
  esn: any;
  typeofjob: any;
  model: any;
  partno: any;
  srno1: any;
  srno2: any;
  smpDnNo: any;
  SmpDate: any;
  showloader: boolean = false;
  flagforcustomerpincode: boolean = false;
  FinalTemplateArr: any[] = [];
  testWidth = "";
  workDone = false;
  showconfirmpopup: boolean = false;
  confirmationpopupmodelobj: ConfirmationPopupModel = new ConfirmationPopupModel();
  constructor(private configurationsetting: ConfigurationSetting, private datepipe: DatePipe, private commonservices: CommonServicesService, ) { }//private exportAsService: ExportAsService

  // constructor() { }

  ngOnInit() {
    /************
     * Start Loader here and stop it once PDF Preparation is completed
     *
     * Stop loadet code should be in setimeout function
     *
     * Where timeout is 4 second i.e 4000
     *
     */
    this.showloader = true;
    this.configurationsetting.hideLeftMenu();
    // this.setHeaders();
    // this.hideComponent();
    this.getDetails();

  }

  getDetails() {
    if (navigator.onLine) {
      this.showloader = true;
      let apiUrl = this.configurationsetting.getBaseUrl() + "TransactionProcess/GetJobCardDetails";
      let params = { "jobcardsrno": this.jobcardSrNo, "actionfor": "Edit", "jobno": 0 };


      this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
        if (successResp['errorCode'] == 0) {

          this.getGobSheetDetails = [];
          this.getGobSheetDetails = successResp["Data"];
          if (this.getGobSheetDetails[0]['@datajson'][0]['qty']) { this.qty = this.getGobSheetDetails[0]['@datajson'][0]['qty']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['customer']) { this.customer = this.getGobSheetDetails[0]['@datajson'][0]['customer']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['subassembtype']) { this.description = this.getGobSheetDetails[0]['@datajson'][0]['subassembtype']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['jobcardsrno']) { this.jobcardno = this.getGobSheetDetails[0]['@datajson'][0]['jobcardsrno']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['jobno']) { this.jobno = this.getGobSheetDetails[0]['@datajson'][0]['jobno']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['prevqty']) { this.cdssDnNo = this.getGobSheetDetails[0]['@datajson'][0]['prevqty']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['jobcarddate']) {
            this.date = this.datepipe.transform(this.getGobSheetDetails[0]['@datajson'][0]['jobcarddate'], "dd/MMM/yyyy");
          }
          if (this.getGobSheetDetails[0]['@datajson'][0]['esn']) { this.esn = this.getGobSheetDetails[0]['@datajson'][0]['esn']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['jeop']) { this.typeofjob = this.getGobSheetDetails[0]['@datajson'][0]['jeop']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['model']) { this.model = this.getGobSheetDetails[0]['@datajson'][0]['model']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['ItemNo']) { this.partno = this.getGobSheetDetails[0]['@datajson'][0]['ItemNo']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['srno']) { this.srno1 = this.getGobSheetDetails[0]['@datajson'][0]['srno']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['dcdetsrno']) { this.srno2 = this.getGobSheetDetails[0]['@datajson'][0]['dcdetsrno']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['prevqty']) { this.smpDnNo = this.getGobSheetDetails[0]['@datajson'][0]['prevqty']; }
          if (this.getGobSheetDetails[0]['@datajson'][0]['prevqty']) { this.SmpDate = this.getGobSheetDetails[0]['@datajson'][0]['prevqty']; }

          // console.log("Details***----------", JSON.stringify(this.getGobSheetDetails));
          if (this.getGobSheetDetails.length != 0) {

            this.companydetails = [];
            this.parts = [];
            // this.companydetails = ((this.getGobSheetDetails[0]['companydetails'] != undefined && this.getGobSheetDetails[0]['companydetails'] != null) ? this.getGobSheetDetails[0]['companydetails'] : []);
            // this.companyname = ((this.companydetails[0]['companyname'] != null && this.companydetails[0]['companyname'] != undefined) ? this.companydetails[0]['companyname'] : '');
            // this.tagline1 = ((this.companydetails[0]['tagline1'] != null && this.companydetails[0]['tagline1'] != undefined) ? this.companydetails[0]['tagline1'] : '');
            // this.tagline2 = ((this.companydetails[0]['tagline2'] != null && this.companydetails[0]['tagline2'] != undefined) ? this.companydetails[0]['tagline2'] : '');
            // this.address1 = ((this.companydetails[0]['address1'] != null && this.companydetails[0]['address1'] != undefined) ? this.companydetails[0]['address1'] : '');
            this.companyname = "Shree Metals Products";
            this.tagline1 = "Works - Gat No 394, At Post Nibhore, Taluka Phaltan, Distr. Satara, 415523";
            this.tagline2 = "Office - Flat No 2/3, Simran Apts, Chintamani CHS, Karve Nagar, Pune 411052";

            if (this.getGobSheetDetails[0]["@itemsubtabledata"] != null && this.getGobSheetDetails[0]["@itemsubtabledata"] != []) {
              this.parts = this.getGobSheetDetails[0]["@itemsubtabledata"];

            }

            this.totalservice = 0;
            this.totalservice = this.parts.length;
            let DeepCopyServices: any[] = [];
            DeepCopyServices = JSON.parse(JSON.stringify(this.parts));

            /************Starts Printing Process
             * Set Total height of table and Image height to be 700 and 220 respectively
             * calculate the height of Row Cell as per the font size
             * Find out total rows required for printing complaints and Image
             * Find out total rows required for printing Parts and Services
             * define startPage Number and totalPages = 0
             * Start Printing Compls and Parts sections separately

            */

            var tableHeight = 700;
            var imageHeight = 220;
            //Height of Row Cell
            let heightOfRow = 0;
            var span;
            // setTimeout(() => {
            //   span = document.getElementById("cmplCell");
            //   heightOfRow = span.clientHeight;

            // }, 1);
            //Calculate number of rows required for printing Complaints section
            //Number of rows for printing image
            let nRowsForImage = Math.floor(imageHeight / heightOfRow)
            // let totalComplRows = this.allcomplaints.length + nRowsForImage;

            //Number of rows for parts and services
            let totalPartsRows = this.parts.length + this.parts.length + 2;

            //Total Rows per page
            let totalRowsPerPage = Math.floor(tableHeight / heightOfRow)
            var totalPages = 1; //Initially there will be only one page
            var pageNo = 0; //Page Index to be processed. 0 as arrayindex begins with 0 actually it is +1
            //Push Empty Page Object in final templateArray. This is binded Pagewise to HTML template
            this.FinalTemplateArr.push({});
            //Prepare Current Page Object Initialy Empty as pageNo is 0
            let processPageObject = this.FinalTemplateArr[pageNo];

            //Set the used Height to 0 initially For complaints
            var pendingHeightCompl = tableHeight;
            //Initilise Page Object Arrys to empty for first Page
            processPageObject["allcomplaints"] = [];
            processPageObject["parts"] = [];
            processPageObject["services"] = [];
            processPageObject["img"] = false;
            processPageObject["complPendingHeight"] = pendingHeightCompl;
            processPageObject["patrspending"] = pendingHeightCompl;

            /** Process Complaints : Put the complaint in the temp cell calculate height of the cell
              /* if it is less than table height put it in the same object else create new page and
              /* Add that value to new page. Ensure to reset all parameters for new page */
            // var ComplSrno = 1;
            // setTimeout(() => {


            //   this.allcomplaints.forEach(element => {
            //     //Place the Text in temp Cell ang extract its height to ensure that even if more than one row is printed within the same cell proper height should be extracted
            //     element['no'] = (ComplSrno).toString();
            //     ComplSrno += 1;
            //     span = document.getElementById("cmplCell");
            //     span.innerHTML = element["complaintdescription"];
            //     var htRequired = span.clientHeight;

            //     if (pendingHeightCompl < htRequired) {
            //       //Required Height is more than the available space. Prepare New Page and Initilise all parameter like initilisation of first page
            //       this.FinalTemplateArr.push({});
            //       totalPages += 1;
            //       pageNo += 1;
            //       processPageObject = this.FinalTemplateArr[pageNo];
            //       //Make all the arrays to empty for this first page
            //       processPageObject["allcomplaints"] = [];
            //       processPageObject["parts"] = [];
            //       processPageObject["services"] = [];
            //       processPageObject["img"] = false;
            //       processPageObject["patrspending"] = tableHeight
            //       pendingHeightCompl = tableHeight;
            //     }
            //     else {
            //       //Put all complaints element into the all complaints element array for the page object
            //       processPageObject["allcomplaints"].push(element);
            //       pendingHeightCompl -= htRequired;
            //       processPageObject["complPendingHeight"] = pendingHeightCompl;

            //     }

            //   })

            //   //Al Complaints are processed and Now fit the image. If Space required for image is available then ensure to add blank complaints and add image to same page
            //   //Else Add new Page Initilise it and Add blank complaints then add image

            //   if (pendingHeightCompl > imageHeight) {
            //     span = document.getElementById("cmplCell");
            //     span.innerHTML = "&nbsp;"
            //     var htRequired = span.clientHeight;

            //     //The Space Required For image is available on the same page. Add Blank Complaints to ensure that image is bottom of the page
            //     while (pendingHeightCompl > imageHeight + htRequired) {
            //       processPageObject["allcomplaints"].push({ 'no': ComplSrno.toString() });
            //       ComplSrno += 1;
            //       pendingHeightCompl -= htRequired;
            //     }
            //     processPageObject["img"] = true;
            //     processPageObject["complPendingHeight"] = 0;
            //   }
            //   else {
            //     //Add all empty lines to current page
            //     while (pendingHeightCompl > imageHeight) {
            //       span = document.getElementById("cmplCell");
            //       span.innerHTML = "&nbsp;"
            //       var htRequired = span.clientHeight;
            //       processPageObject["allcomplaints"].push({ 'no': ComplSrno.toString() });
            //       ComplSrno += 1;
            //       pendingHeightCompl -= htRequired;

            //     }
            //     processPageObject["img"] = false;
            //     //Prepare new page for complaints and add the image to the bottom of the page.
            //     processPageObject["complPendingHeight"] = pendingHeightCompl;
            //     this.FinalTemplateArr.push({});
            //     totalPages += 1;
            //     pageNo += 1;
            //     processPageObject = this.FinalTemplateArr[pageNo];
            //     //Make all the arrays to empty for this first page
            //     processPageObject["allcomplaints"] = [];
            //     processPageObject["parts"] = [];
            //     processPageObject["services"] = [];
            //     processPageObject["img"] = true;
            //     pendingHeightCompl = tableHeight;
            //     processPageObject["complPendingHeight"] = 0;
            //     processPageObject["patrspending"] = tableHeight




            //   }
            // }, 1);


            /** Process Parts And Services : Put the Parts or services in the temp cell calculate height of the cell
             /* if it is less than table height put it in the same object else create new page and
             /* Add that value to new page. Ensure to reset all parameters for new page */
            /***
             * Ensure that While moving to next page Create and initilise only if not already created. Else use te already created one
             */


            var partsSrNo = 1;
            let servNo = 1;

            // setTimeout(() => {
            //   //Start With First Page and all value initilise
            //   var pendingHeightPart = tableHeight;
            //   pendingHeightCompl = tableHeight;
            //   pageNo = 0;
            //   processPageObject = this.FinalTemplateArr[pageNo];
            //   var partCell = document.getElementById("partServCell");
            //   //Start with Parts and then continue with Services without reinlisiting any thing
            //   this.parts.forEach(element => {
            //     partCell.innerHTML = element["partname"];
            //     element['no'] = (+partsSrNo).toString();;
            //     partsSrNo += 1;
            //     var htRequired = partCell.clientHeight;
            //     //Verify that the space is availabe for parts to be printed on the same page else move to next page
            //     if (pendingHeightCompl < htRequired) {
            //       //Need to Move to next page
            //       pageNo = pageNo + 1;
            //       if (pageNo == totalPages) {
            //         //already Processed last page so Create a new page as mentioned in above comment
            //         this.FinalTemplateArr.push({});
            //         totalPages += 1;
            //         processPageObject = this.FinalTemplateArr[pageNo];
            //         //Make all the arrays to empty for this  page
            //         processPageObject["allcomplaints"] = [];
            //         processPageObject["parts"] = [];
            //         processPageObject["services"] = [];
            //         processPageObject["img"] = false;
            //         processPageObject["complPendingHeight"] = tableHeight;
            //       }
            //       else {
            //         //The page is present and re use it for parts to be continued
            //         processPageObject = this.FinalTemplateArr[pageNo];
            //       }

            //       //Reset the height to table height
            //       pendingHeightCompl = tableHeight; //Reinitilise used height

            //     }
            //     processPageObject["parts"].push(element);
            //     pendingHeightCompl -= htRequired;
            //     processPageObject["patrspending"] = pendingHeightCompl
            //   })

            //   DeepCopyServices.forEach(element => {
            //     partCell.innerHTML = element["partname"];
            //     element['no'] = (servNo).toString();
            //     servNo += 1;
            //     var htRequired = partCell.clientHeight;

            //     if (pendingHeightCompl < htRequired) {
            //       //Ned to move to next page check if exists else create new one and proceed
            //       pageNo += 1;
            //       if (pageNo == totalPages) {
            //         //already Processed last page so keep Create a new page as mentioned in above comment
            //         this.FinalTemplateArr.push({});
            //         totalPages += 1;
            //         processPageObject = this.FinalTemplateArr[pageNo];
            //         //Make all the arrays to empty for this first page
            //         processPageObject["allcomplaints"] = [];
            //         processPageObject["parts"] = [];
            //         processPageObject["services"] = [];
            //         processPageObject["img"] = false;
            //         processPageObject["complPendingHeight"] = tableHeight;
            //       }
            //       else {
            //         processPageObject = this.FinalTemplateArr[pageNo];
            //       }
            //       pendingHeightCompl = tableHeight; //Reinitilise used height
            //       processPageObject["patrspending"] = tableHeight;
            //     }

            //     processPageObject["services"].push(element);
            //     pendingHeightCompl -= htRequired;
            //     processPageObject["patrspending"] = pendingHeightCompl

            //   })
            // }, 100)


            //All Parts and Services and Complaints are Processed. Now ensure that there are proper blank Spaces are adjusted
            //For Complaints as well as parts and services section of each page

            // setTimeout(() => {
            //   var partCell = document.getElementById("partServCell");
            //   for (let i = 0; i < totalPages; i++) {

            //     let processPageObject = this.FinalTemplateArr[i];
            //     partCell.innerHTML = "&nbsp;";
            //     var htRequired = partCell.clientHeight;
            //     //For Complaints
            //     while (processPageObject["complPendingHeight"] > htRequired) {
            //       processPageObject["allcomplaints"].push({ "no": (ComplSrno).toString(), "complaintdescription": " " });
            //       processPageObject["complPendingHeight"] -= htRequired;
            //       ComplSrno += 1;
            //     }

            //     var SrNo;
            //     if (servNo == 1) SrNo = partsSrNo; else SrNo = servNo;

            //     while (processPageObject["patrspending"] > htRequired) {
            //       if (processPageObject["services"].length > 0) {
            //         let obj = {};
            //         obj["no"] = " ";
            //         obj["partname"] = "";
            //         // obj["qtyrequested"] = "";
            //         obj["estimatepartrate"] = "";
            //         processPageObject["services"].push(obj);
            //       }
            //       else {
            //         let obj = {};
            //         obj["no"] = " ";
            //         obj["partname"] = "";
            //         obj["qtyrequested"] = "";
            //         // obj["estimatepartrate"] = "";
            //         processPageObject["parts"].push(obj);
            //       }
            //       SrNo += 1;
            //       processPageObject["patrspending"] -= htRequired;
            //     }

            //   }
            // }, 1000)


            // setTimeout(() => {
            //   let remRow = document.getElementById("addRows");
            //   remRow.parentNode.removeChild(remRow);
            //   /******Stop Loader Here*
            //    * Once PDF File layout/Preview is created
            //    * *****/
            //   this.showloader = false;

            //   ////Stop Loader Here.......
            // }, 4000);


            /*End Process For Printing */

            this.totalpart = 0;
            let partreq = 0;
            if (this.parts.length != 0) {
              this.totalpart = this.parts.length;
              partreq = this.totalpart + 1;
            }
            this.totalpartservice = 0;
            this.totalpartservice = this.totalpart + this.totalservice;

          }

          // let x= this.cardata.split(",");
          // this.model=x[1]+x[2];
          this.showloader = false;
        }
        // else if( successResp["statuscode"] === "DB101"){
        //   this.showloader = false;
        //   this.displayMessagePopup( successResp["description"],"Ok",true,"",false);
        // }
        else {
          this.errormsg = successResp["description"];
        }
        this.showloader = false;

      },
        err => {
          this.showloader = false;
          this.errormsg = err['message'];
        })

    }
    else {
      alert("Internet Connection is Not Available.");
    }
  }
  generatePdf() {
    this.commonservices.export();

  }
  printPreview() {
    this.commonservices.printPage('print_page');
  }

  generateInvoice() {
    this.showPrintInvoice = true;

  }

  printPage() {
    let printHTML = document.getElementById('print_page')["outerHTML"];
    let popupWinindow = window.open('', '_blank', 'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="style.css" media="print"/></head><body onload="window.print()">' + printHTML + '</html>');
    popupWinindow.document.close();
    // this.cancelPrintInvoice();
  }

  closePopUpEvent() {
    this.showPrintInvoice = false;

  }

}


/*

  this.ReportJSONArr=
    [
      [
        [
          "Shree Metal Products",// Rowspan
          "",
          10,
          "",
          { 'text-align': 'center', 'font-size': '18px', 'border': 'none' }
        ]
      ],
      [
        [
          "Works: Gat No 394, At Post Nimbhore, Taluka Phaltan , Dist.Satara,415523",// Rowspan
          "",
          10,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
      ],
      [
        [
          "Office : Flat No2/3, Simran Apts, Chintamani CHS, Karve Nagar, Pune 411052",// Rowspan
          "",
          10,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ]
      ],
      [
        [
          "JOBSHEET",// Rowspan
          "",
          10,
          "",
          { 'text-align': 'center', 'border': 'none', 'border-bottom': 'hidden' }
        ],
      ],
      [],
      [
        [
          "Job Card No",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none', 'border-top-style': 'hidden' }
        ],
        [
          ": 1289",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          6,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "Job No",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": 131223",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none', ' font-weight': '600' }
        ],

      ],
      [
        [
          "CDSS D/N No",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": 5064 / R 2389",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "Date",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": 26/06/2019",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          4,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ]

      ],
      [
        [
          "Customer",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": CIL",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "ESN",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": 25435437",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "Type of Job",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": OP",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ]


      ],
      [
        [
          "Description",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": Lub Oil Pump",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          1,
          "",
          { 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          1,
          "",
          { 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "Model",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": Big Cam",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ]


      ],
      [
        [
          "Part No",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": RC3609833",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "Qty",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": 1",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "Sr No1",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none', ' font-weight': '600' }
        ],
        [
          ": P - 0.2",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none', ' font-weight': '600' }
        ]


      ],
      [
        [
          "SMP D/N",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": 348",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "Date",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          ": 09/08/2019",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border': 'none' }
        ],
        [
          "Sr No2",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border': 'none', ' font-weight': '600' }
        ],
        [
          ": F - 176",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none', ' font-weight': '600' }
        ]
      ],
      [
        [
          "Part Replaced - ",// Rowspan
          "",
          10,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ]
      ],
      [
        [
          "S/N",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center' }
        ],
        [
          "Description",// Rowspan
          "",
          5,
          "",
          { 'text-align': 'center' }
        ],
        [
          "Part Number",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center' }
        ],
        [
          "Quantity",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center' }
        ]
      ],
      [
        [
          "1",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "Washer Plain",// Rowspan
          "",
          5,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "146161",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "1",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ]
      ]
      ,
      [
        [
          "2",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "Spring",// Rowspan
          "",
          5,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "3010146",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "1",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ]
      ]
      ,
      [
        [
          "3",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "Washer",// Rowspan
          "",
          5,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "3014754",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "1",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ]
      ],
      [
        [
          "4",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "Gasket",// Rowspan
          "",
          5,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "3014778",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "1",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ]
      ],
      [
        [
          "5",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "Gear",// Rowspan
          "",
          5,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "3245456",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "1",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ]
      ],
      [
        [
          "6",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "Plug, Rtg",// Rowspan
          "",
          5,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "3412892",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ],
        [
          "1",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center', 'border-bottom-style': 'hidden' }
        ]
      ],
      [
        [
          "7",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'center' }
        ],
        [
          "Plungger",// Rowspan
          "",
          5,
          "",
          { 'text-align': 'center' }
        ],
        [
          "3881126",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center' }
        ],
        [
          "1",// Rowspan
          "",
          2,
          "",
          { 'text-align': 'center' }
        ]
      ],
      [
        [
          "Inspection Report - ",// Rowspan
          "",
          10,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ]
      ],
      [
        [
          "End  Play - ",// Rowspan
          "",
          1,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ],
        [
          "0.004''",// Rowspan
          "",
          9,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ]
      ],
      [
        [
          "Remarks - ",// Rowspan
          "",
          10,
          "",
          { 'text-align': 'left', 'border': 'none' }
        ]
      ],
      [

        [
          "For Shree Metal Products",// Rowspan
          "",
          10,
          "",
          { 'text-align': 'right', 'border': 'none' }
        ]
      ]
    ]



*/
