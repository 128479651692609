import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationSetting } from '../../appconfig';
import { HttpHeaders } from '@angular/common/http';
import { LoginModel } from '../../models/models';
import { ManageCoockies } from '../../commonfiles/managecoockies';
import { CommonServicesService } from '../../services/common-services.service';
import { ToastService } from '../../services/toast.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  showloader: boolean = false;
  errormsg: string = "";
  typeForPassword: string = 'password';
  typeForConPassword: string = 'password';

  managecoockies = new ManageCoockies();
  LoginModel = new LoginModel();
  constructor(private router: Router, private configurationsetting: ConfigurationSetting,
  private commonservices: CommonServicesService, private toastService: ToastService) { }

  ngOnInit() {
    // console.log(location.origin);
    // if (location.origin == 'http://localhost:4200' || location.origin == 'http://localhost:5600') {
    //   this.LoginModel.username = "test";      //"viotal";       "test"
    //   this.LoginModel.password = 'test@123';  //"viotal@123";   "test@123"
    //   this.login();
    //  }
  }

  changeViewOfPasswrd(FOR, typeForPassword) {
    if (typeForPassword == 'password') {
      if (FOR == 'pass') {
        this.typeForPassword = 'text';
      }
      else {
        this.typeForConPassword = 'text';
      }
    }
    else {
      if (FOR == 'pass') {
        this.typeForPassword = 'password';
      }
      else {
        this.typeForConPassword = 'password';
      }
    }
  }


  // login() {

  // }
  ///////////
  login() {
    if (navigator.onLine) {


      this.showloader = true;
      this.errormsg = "";
      // if (this.LoginModel.ckey.trim().length == 0) {
      //   this.errormsg = "Clientkey is mandatory.";
      //   this.showloader = false;
      //   return;
      // }
      if (this.LoginModel.username.trim().length == 0) {
        this.errormsg = "Username is mandatory.";
        this.showloader = false;
        return;
      }
      else if (this.LoginModel.password.trim().length == 0) {
        this.showloader = false;
        this.errormsg = "Password is mandatory.";
        return;
      }
      // (this.LoginModel.ckey.trim().length == 0 ||
      else if (this.LoginModel.password.trim().length == 0 || this.LoginModel.username.trim().length == 0) {
        this.showloader = false;
        this.errormsg = "Clientkey, Username and Password are mandatory.";
        return;
      }
      this.loaderTimeOut();
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      let APIUrl: string = this.configurationsetting.getBaseUrl() + "Master/LoginValidation";
      this.commonservices.postMethod(APIUrl, this.LoginModel)

        .subscribe(
          loginsuccess => {
            if (loginsuccess["errorCode"] == 0) {
              this.showloader = false;
              let LoginObj = {};
              // console.log("login:------"+ JSON.stringify(loginsuccess));
              LoginObj["username"] = loginsuccess["Data"]["userInfo"]["username"];
              LoginObj['UserID'] = loginsuccess["Data"]["userInfo"]["UserID"];
              LoginObj['UserRole'] = loginsuccess["Data"]["userInfo"]["UserRole"];
              LoginObj['IsActive'] = loginsuccess["Data"]["userInfo"]["IsActive"];

              this.commonservices.apiToken = loginsuccess["Data"]["token"];
              this.commonservices.userRole = loginsuccess["Data"]["userInfo"]["UserRole"];
              let accessControl = {}
              accessControl = JSON.parse(loginsuccess["Data"]["userInfo"]['accesscontrol']);
              // console.log(accessControl);
              
              // this.commonservices.btnControlMaster = accessControl['btns']['master'];
              // this.commonservices.btnControlTrnx = accessControl['btns']['trnx'];
              // this.commonservices.accessControl = {}
              this.commonservices.accessControl = accessControl;
              

              sessionStorage.setItem('clientkey', JSON.stringify(loginsuccess["Data"]["userInfo"]['clientkey']));
              // LoginObj["role"] = loginsuccess["data"][0]["ROLE"];
              //LoginObj["password"] = loginsuccess["data"][0]["password"];
              sessionStorage.setItem("logindata", JSON.stringify(LoginObj));
              this.router.navigateByUrl('/dashboard');
              // this.router.navigateByUrl('/user-master');
              // this.router.navigateByUrl('/material-outward/rejected-outward');
              
              // this.toastService.showToastMsg('success', 'Login', loginsuccess['errorDescription']);
              // this.managecoockies.setCookie("token", loginsuccess["data"][0]["token"]);
              //this.configurationsetting.printConsoleLog(1, loginsuccess["description"]);
              //this.pageRedirection(LoginObj["role"]);
            }

            else if (loginsuccess["errorCode"] != "0") {
              this.showloader = false;
              this.errormsg = loginsuccess["errorDescription"];
              // this.configurationsetting.printConsoleLog(1, loginsuccess["description"]);
            }
          },
          loginerror => {
            this.showloader = false;
            this.errormsg = "Login failure.";
          }
        );
    }
    else {
      alert("Internet Connection is Not Available.");
    }
  }

  //////////
  //////if http not gave the response after spend 30 seconds the we will show the error.
  loaderTimeOut() {
    setTimeout(() => {
      if (this.showloader) {
        this.showloader = false;
        this.errormsg = "Error while login your account.";
      }
    }, 50000)
  }

}
