import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerMasterComponent } from './component/customer-master/customer-master.component';
import { DeliveryChallanInwardComponent } from './component/delivery-challan-inward/delivery-challan-inward.component';
import { ItemMasterComponent } from './component/item-master/item-master.component';
import { JobSheetComponent } from './component/job-sheet/job-sheet.component';
import { LoginComponent } from './component/login/login.component';
import { ManualInwardNoteComponent } from './component/manual-inward-note/manual-inward-note.component';
import { ModelMasterComponent } from './component/model-master/model-master.component';
import { PoMasterComponent } from './component/po-master/po-master.component';
import { ReRepairsComponent } from './component/re-repairs/re-repairs.component';
import { SpareMasterComponent } from './component/spare-master/spare-master.component';
import { SubAssemblyMasterComponent } from './component/sub-assembly-master/sub-assembly-master.component';
import { TaxMasterComponent } from './component/tax-master/tax-master.component';
import { UserMasterComponent } from './component/user-master/user-master.component';


import { SpareInwardComponent } from './component/spare-transaction/spare-inward/spare-inward.component';
import { SpareOutwardComponent } from './component/spare-transaction/spare-outward/spare-outward.component';
import { SpareIssuedComponent } from './component/spare-transaction/spare-issued/spare-issued.component';
import { SpareRecieptComponent } from './component/spare-transaction/spare-reciept/spare-reciept.component';
import { SpareDiscrepancyComponent } from './component/spare-transaction/spare-discrepancy/spare-discrepancy.component';
import { SpareRequisitionComponent } from './component/spare-transaction/spare-requisition/spare-requisition.component';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { InvoiceComponent } from './component/invoice/invoice.component';
import { ReportComponent } from './component/report/report.component';
import { UtiliyComponent } from './component/utiliy/utiliy.component';
import { MailSysparamsComponent } from './component/mail-sysparams/mail-sysparams.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '*',
    redirectTo: 'login'
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },

    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },

      {
        path:'sub-Assembly',
        component:SubAssemblyMasterComponent
      },
      {
        path:'spare-master',
        component:SpareMasterComponent
      },
      {
        path:'model-master',
        component:ModelMasterComponent
      },
      {
        path:'item-master',
        component:ItemMasterComponent
      },
      {
        path:'customer-master',
        component:CustomerMasterComponent
      },
      {
        path:'tax-master',
        component:TaxMasterComponent
      },
      {
        path:'po-master',
        component:PoMasterComponent
      },
      {
        path:'delivery-challan-inward',
        component:DeliveryChallanInwardComponent
      },
      {
        path:'manual-inward-note',
        component:ManualInwardNoteComponent
      },
      {
        path:'job-sheet',
        component:JobSheetComponent
      },
      {
        path:'re-repairs/:name',
        component:ReRepairsComponent
      },
      {
        path:'user-master',
        component:UserMasterComponent
      },
      {
        path:'spare-inward',
        component:SpareInwardComponent
      },
      {
        path:'spare-outward',
        component:SpareOutwardComponent
      },
      {
        path:'spare-issued',
        component:SpareIssuedComponent
      },
      {
        path:'spare-reciept',
        component:SpareRecieptComponent
      },
      {
        path:'spare-discrepancy',
        component:SpareDiscrepancyComponent
      },
      {
        path:'spare-requisition',
        component:SpareRequisitionComponent
      },
      {
        path:'material-outward',
        loadChildren:()=>import('../app/component/material-outward-compo/material-outward-compo.module').then(m=>m.MaterialOutwardCompoModule)
      },
      {
        path:'regular-invoice',
        component:InvoiceComponent
      },
      {
        path:'manual-invoice',
        component:InvoiceComponent
      },
      {
        path:'spare-report',
        component:ReportComponent
      },
      {
        path:'item-report',
        component:ReportComponent
      },
      {
        path:'po-report',
        component:ReportComponent
      },
      {
        path:'challan-ageing',
        component:ReportComponent
      },
      {
        path:'utility',
        component:UtiliyComponent
      },
      {
        path:'mail-sysparams',
        component:MailSysparamsComponent
      }
      // ,
      // {
      //   path:'spare-transaction',
      //   loadChildren:()=>import('../app/component/spare-transaction/spare-transaction.module').then(m=>m.SpareTransactionModule)
      // }
    ]
  },
  
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
