import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SystemParamsService {

  public ngDateFormatyMMDD: string = "y-MM-d";
  public ngDateFormatddMMy: string = "dd/MM/yyyy";
  public ngTimeFormathmma: string = "h:mm a";
  public bsDateFormatDDMMYYYY:string='DD/MM/YYYY';
  public activeDateTimeFormat: any = {};
  private dateFormatObj = {
    angularDateFormat: "dd-MM-y",
    angularTimeFormat: "h:mm a",
    angularDateAndTimeFormat: "dd-MM-y h:mm a",
    bootstrapDateFormat: "'DD-MM-YYYY'",
    bootstrapTimeFormat: "",
    displayName: "Refrence",
  };
  constructor() {}
}
