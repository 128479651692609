export const LEFT_SIDE_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": true },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "showonlysearch": true,
    "checkDeletedFlag": true,
    "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  },
  tableheaders: [
    {
      "key": "jobSrNo",
      "display": "Job Card No.",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "thstyle": { "text-align": "center", "width": "65px" },
      "tdalignment": "hr-left",
      "menulist": [],
      "summary": []
    },
    {
      "key": "JobNo",
      "display": "Job No.",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-left",
      "menulist": [],
      "summary": []
    },
    {
      "key": "jobsheetdate",
      "display": "Job Date",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "date",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "thstyle": { "text-align": "center", "width": "60px" },
      "tdalignment": "",
      "menulist": [],
      "summary": []
    }
  ],
  tabledata:[]
}

export const JOBSHEET_DETAILS_TABLE_JSON = {
  tableconfig:  {
    "tableclasses": "table-striped horizontal-scroll table-lightblue  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": true },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
    ],
    "exporttoexcel": false,
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false, "controlsfor": {} },
    "defaultcellmenus": [{
      "label": "Quick Update",
      "action": "quickupdate"
    }]
  },
  tableheaders: [
    {
      "key": "PartNo",
      "display": "Part No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "left", "width": "100px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": []
    },
    {
      "key": "unitqty",
      "display": "Unit Qty",
      "editable": false,
      "sorting": true,
      "positive": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []
    },
    {
      "key": "qty",
      "display": "Qty Consumed",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []

    },
    {
      "key": "originalqty",
      "display": "original qty",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": false,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []

    },
    {
      "key": "CurrentBalance",
      "display": "Current Balance",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []
    },
    {
      "key": "WIP",
      "display": "WIP",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": []
    },
    {
      "key": "remark",
      "display": "Remark",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [],
    }
  ],
  tabledata:[]
}

export const SELECT_JOBSHEET_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped sticky-header sorting  horizontal-scroll table-lightblue  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "controlconfig": { "displaycontrol": true, "controlsfor": {} },
    "defaultcellmenus": []
  },
  tableheaders:[
    {
      "key": "refrenceno",
      "display": "Ref No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "left", "width": "80px" },
      "tdalignment": "",
      "menulist": []
    },
    {
      "key": "dcno",
      "display": "Dc No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": []
    },
    {
      "key": "issuedate",
      "display": "DC Inward Date",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "date",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": []
    },
    {
      "key": "itemname",
      "display": "Item No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "50px" },
      "tdalignment": "",
      "menulist": []
    },
    {
      "key": "model",
      "display": "Model",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "50px" },
      "tdalignment": "",
      "menulist": []
    }, {
      "key": "jmdescription",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "50px" },
      "tdalignment": "",
      "menulist": []
    },
    {
      "key": "jobno",
      "display": "Job No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "",
      "menulist": []
    },
    {
      "key": "quantity",
      "display": "Qty",
      "editable": false,
      "sorting": true,
      "positive": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "right", "width": "70px" },
      "tdalignment": "hr-right",
      "menulist": []
    },
    {
      "key": "esn",
      "display": "ESN",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "right", "width": "70px" },
      "tdalignment": "hr-right",
      "menulist": []
    },
    {
      "key": "customername",
      "display": "Customer",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": []
    },
    {
      "key": "itemname",
      "display": "Item Name",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": []
    },
    {
      "key": "engtypejeop",
      "display": "Eng Type",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center",
      "menulist": []
    },
    {
      "key": "jmremark",
      "display": "Remark",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center",
      "menulist": []
    },
    {
      "key": "dnno",
      "display": "DC No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "right", "width": "50px" },
      "tdalignment": "",
      "menulist": []
    }
  ],
  tabledata:[]
}

export const SELECT_ACTUAL_ITEM_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": true },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": false,
      "selectedFieldsArray": [
        {
          "key": 'checkbox',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    }
  },
  tableheaders: [
    // {
    //   "key": "checkbox",
    //   "display": "",
    //   "editable": false,
    //   "sorting": true,
    //   "allowaggregation": false,
    //   "enablehide": true,
    //   "visiblecolm": true,
    //   "decimaldigit": '',
    //   "datatype": "checkbox",
    //   "thstyle": { "text-align": "left", "width": "30px" },
    //   "tdalignment": "",
    //   "menulist": [],
    //   "summary": []
    // },
    {
      "key": "PartNo",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "string",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "",
      "menulist": [],
    },
    {
      "key": "modelname",
      "display": "Model Name",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "",
      "menulist": [],
    }
  ],
  tabledata:[]
}

export const BOM_LIST_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": true },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": false,
      "selectedFieldsArray": [
        {
          "key": 'qty',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    }
  },
  tableheaders:  [
    {
      "key": "checkbox,",
      "display": "",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "checkbox",
      "thstyle": { "text-align": "left", "width": "30px" },
      "tdalignment": "",
      "menulist": [],
      "summary": []
    },
    {
      "key": "PartNo",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "string",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "",
      "menulist": [],
    },
    {
      "key": "modelname",
      "display": "Model Name",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": []
    },
    {
      "key": "unitqty",
      "display": "Qty",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "rigth", "width": "50px" },
      "tdalignment": "hr-right vt-middle",
      "menulist": []
    },

    {
      "key": "CurrentBalance",
      "display": "Current Balance",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": false,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-right vt-middle",
      "menulist": []
    },
    {
      "key": "WIP",
      "display": "WIP",
      "editable": false,
      "positive": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-right vt-middle",
      "menulist": []
    },
    {
      "key": "qty",
      "display": "Qty",
      "editable": false,
      "sorting": true,
      "positive": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []
    },

  ],
  tabledata:[]
}

export const UPADATE_BOM_LIST_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "dragdrop": { "columns": true },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": true,
      "selectedFieldsArray": [
        {
          "key": 'qty',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    }
  },
  tableheaders: [
    // {
    //   "key": "checkbox,",
    //   "display": "",
    //   "editable": false,
    //   "sorting": true,
    //   "allowaggregation": false,
    //   "enablehide": true,
    //   "visiblecolm": true,
    //   "decimaldigit": '',
    //   "datatype": "checkbox",
    //   "thstyle": { "text-align": "left", "width": "30px" },
    //   "tdalignment": "",
    //   "menulist": [],
    //   "summary": []
    // },
    {
      "key": "PartNo",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "string",
      "btnclasses": "btn-primary",
      "btnstyle": {},
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "",
      "menulist": [],
    },
    {
      "key": "unitqty",
      "display": "Unit Qty",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []
    },
    {
      "key": "CurrentBalance",
      "display": "Current Balance",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []
    },
    {
      "key": "WIP",
      "display": "WIP",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []
    },
    {
      "key": "qty",
      "display": "Quantity",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "positive": true,
      "visiblecolm": true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": []
    },
  ],
  tabledata:[]
}
