import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationSetting } from '../../appconfig';
import { CommonServicesService } from '../../services/common-services.service';
import { HelperService } from '../../services/helper.service';
import { SubmitFormService } from '../../services/submit-form.service';
import { SystemParamsService } from '../../services/system-params.service';
import { ToastService } from '../../services/toast.service';
import { saveAs } from 'file-saver';
import { DETAILED_TABLE_JSON, ITEM_TABLE_JSON, LEFT_SIDE_TABLE_JSON, REJECTEDPO_TABLE_JSON } from './invoice.tableconfig';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
declare var $;
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @ViewChild('selectIteminvoice', { static: false }) public selectIteminvoice;
  @ViewChild('ShowSelectItemPopup', { static: false }) public ShowSelectItemPopup;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('showPopupoSelectConfirmPo', { static: false }) public showPopupoSelectConfirmPo;
  @ViewChild('printInvoicePopup', { static: false }) public printInvoicePopup;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;

  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;

  createNewBtn: boolean = false;
  editBtn: boolean = false;
  saveBtn: boolean = true;
  visiblecanclebtn: boolean = false;
  deleteBtnDisable: boolean = false;
  editDisableBtn: boolean = false;
  currentSelectedRow: number;
  todaysDate;
  saveBtnVisible: boolean = false;
  refreshDetailTable:boolean = true;
  emptySearchField: boolean = true;
  isRowClicked: boolean = false;
  visibleSelectBtn: boolean = false;
  visibleSelectBtnManual: boolean = false;
  private backupData: any = { bindDataToFormAndTable: {} };
  deletedArray: number[] = [];
  tabledataforprocess: any[] = [];
  invoiceuniqueno: number = 0;
  taxPercentage;
  subtax: any;
  deleteMsg: string = "";
  ErrorDescription: string = "";
  ErrorHeader: string = "";
  operation: string = "";
  fileName: string = "";
  invoiceData: any = {};
  private backupTableDataForProcess: any = [];
  priviousQty: number = 0;
  dataForPrint: any = {};
  invoiceType: string = "";
  isReadOnly: boolean = false;
  btnRow: boolean = false;
  dataLocalUrl: any;
  refreshtable: boolean = true;
  priceForRejDC: number = 0;
  invoiceFor: string = ''
  taxArray = []

  customerNameArray: any[] = [];

  model:any = {
    invoicesrno: {
      valuType: 'String', value: '', label: "Id", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    invoiceno: {
      valuType: 'String', value: '', label: "Invoice No. ", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    customerno: {
      valuType: 'String', value: '', label: "Customer Name", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    billfor: {
      valuType: 'String', value: '', label: "Bill for", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isBillForRejected: {
      value: false, isRequired: true, err: "", label: "Bill for Rejected",
      disable: true,
    },
    invoicedate: {
      valuType: 'Date', value: '', label: "Invoice Date", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'date' }
    },
    showpono: {
      valuType: 'String', value: '', label: "PO No.", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    pono: {
      valuType: 'String', value: '', label: "PO No.", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    sachsncode: {
      valuType: 'String', value: '', label: "SAC HSN Code", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    invtype: {
      valuType: 'String', value: '', label: "inv type", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    jmremark: {
      valuType: 'String', value: '', label: "Remark", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    servicetaxvat: {
      valuType: 'Number', value: '', label: "Main Tax", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    taxableamount: {
      valuType: 'Number', value: '', label: "Invoice Amount", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    taxamount: {
      valuType: 'Number', value: '', label: "Rounding Amount", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    totalinvoiceamount: {
      valuType: 'Number', value: '', label: "Total Amount", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    taxId: {
      valuType: 'Number', value: '', label: "Total Amount", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    dcsrno: {
      valuType: 'Number', value: '', label: "Delivery note", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  // Left Side gt-table
  invoicelefttableconfig: any = LEFT_SIDE_TABLE_JSON.tableconfig;
  invoicelefttableheaders: any[] = LEFT_SIDE_TABLE_JSON.tableheaders;
  invoicelefttabledata: any[] = [];

  // Right side bottom gt-table
  invoicelisttableconfig: any = DETAILED_TABLE_JSON.tableconfig;
  invoicelisttableheaders: any[] = DETAILED_TABLE_JSON.tableheaders;
  invoicelisttabledata: any[] = [];

  // Popup Items in gt-table
  invoicepopupitemstableconfig: any = ITEM_TABLE_JSON.tableconfig;
  invoicepopupitemstableheader: any[] = ITEM_TABLE_JSON.tableheaders;
  invoicepopupitemstabledata: any[] = []

  confirmPotableconfig: any = REJECTEDPO_TABLE_JSON.tableconfig;
  confirmPotableheader: any[] = REJECTEDPO_TABLE_JSON.tableheaders;
  confirmPotabledata: any[] = [];

  constructor(private ngbActiveModal: NgbActiveModal, private domSanitizer: DomSanitizer, private activeRoute: ActivatedRoute, public systemParamsService: SystemParamsService, public commonService: CommonServicesService, private submit: SubmitFormService, private helper: HelperService, private configurationsetting: ConfigurationSetting, private toastService: ToastService) { }

  ngOnInit(): void {
    this.configurationsetting.hideLeftMenu();
    this.getAllInvoice();
    this.getCustomerName();

    this.activeRoute.url.subscribe((item => {
      if (item[0]['path'] == "regular-invoice") {
        this.invoiceType = "R";
      } else if (item[0]['path'] == "manual-invoice") {
        this.invoiceType = "M";
        this.getTaxSelection()
        this.model.servicetaxvat.isRequired = false;
        this.model.showpono.isRequired = false;
        this.model.pono.isRequired = false;
        this.model.dcsrno.isRequired = false;
        this.model.taxId.isRequired = false;
      }
    }))
    if (this.isOpenFromReport) {
      this.invoiceType = "R";
      this.getByIdClickCall(this.transactionID, 'R')
    }

    this.invoicelisttableheaders.forEach((item)=>{
      if( this.invoiceType == "M"){
        if(item['key'] == 'dcsrno' || item['key'] == 'itemnumber' || item['key'] == 'modelname' || item['key'] == 'jobno' || item['key'] == 'releaseno'){
          item['visiblecolm'] = false;
        }
        if(item['key'] == 'SerialNo' || item['key'] == 'remark'){
          item['visiblecolm'] = true;
        }
      }else{
        if(item['key'] == 'dcsrno' || item['key'] == 'itemnumber' || item['key'] == 'modelname' || item['key'] == 'jobno' || item['key'] == 'releaseno'){
          item['visiblecolm'] = true;
        }
        if(item['key'] == 'SerialNo' || item['key'] == 'remark'){
          item['visiblecolm'] = false;
        }
      }
    })


  }

  createNew() {
    this.emptySearchField = false;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.createNewBtn = true;
    this.visiblecanclebtn = true;
    this.editBtn = false;
    if (this.invoiceType == "R") {
      this.selectIteminvoice.show();
      this.isRowEditableOrNot(true);
      this.isReadOnly = true;
    }
    if (this.invoiceType == "M") {
      this.isReadOnly = false;

      this.allCellEditableForManualInvoice(true);
    }
    this.invoicelisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.btnRow = true;
    this.deleteMsg = "";
    this.commonService.enableFormFields(this.model);
    this.commonService.clearForm(this.model);
    this.invoicelisttabledata = [];
    this.tabledataforprocess = [];
    this.visibleSelectBtn = true;
    this.visibleSelectBtnManual = true;
    this.todaysDate = new Date()
    this.model.invoicedate.value = this.todaysDate;
    this.operation = "create";
    const ele = document.getElementById(`tr${"invoicelefttbl" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    delete this.backupData.bindDataToFormAndTable;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);

  }

  saveInvoice() {
    if (this.invoiceType == "R") {
      this.model.invtype.value = "R";
    } else {
      this.model.invtype.value = "M";
      this.model.customerno.value =  +this.model.customerno.value;
      this.model.pono.value = this.model.showpono.value;
    }
    console.log(this.model);

    let data = this.submit.submitForm(this.model)

    if (data) {
      this.saveBtn = true;
      let editedRow = [];

      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if(this.model.invtype.value == "M"){
          if (!this.tabledataforprocess[i]['Description']) {
            this.toastService.showToastMsg('warning', 'Warning', "Please enter description in table.");
            return;
          }
          if (this.tabledataforprocess[i]['price'] <= 0) {
            this.toastService.showToastMsg('warning', 'Warning', "Please enter price in table.");
            return;
          }
        }
        if (this.tabledataforprocess[i]['qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }

        if (this.tabledataforprocess[i]['rowmodifiedstatus']) {
          delete this.tabledataforprocess[i]['rowmodifiedstatus'];
          editedRow.push(this.tabledataforprocess[i]);
        }
      }

      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray;
      }
      data["criteria"]["isBillForRejected"] = data["criteria"]["isBillForRejected"] ? 'Y' : 'N';
      this.deletedArray = [];

      let endpoint = 'transaction/insertInvoiceData';
      if (data["criteria"]['invoicesrno']) {
        endpoint = 'transaction/updateInvoice';
        data["criteria"]["invoicelist"] = editedRow;
        this.ErrorDescription = "Data Updated Successfully";
      } else {
        data["criteria"]["invoicelist"] = this.tabledataforprocess;
        this.ErrorDescription = "Data Inserted Successfully";
      }
      console.log("invoicelist", data["criteria"]["invoicelist"]);

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.invoiceuniqueno = res['Data']
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.visibleSelectBtn = false;
          this.visibleSelectBtnManual = false;
          if (this.invoiceType == "R") {
            this.isRowEditableOrNot(false);
          }
          if (this.invoiceType == "M") {
            this.allCellEditableForManualInvoice(false);
          }
          this.deleteBtnDisable = false;
          this.getAllInvoice();
          this.invoicelisttableconfig['actioncolmconfig']['displaycolumn'] = false;

          this.commonService.diasbleFormFields(this.model)
          this.visiblecanclebtn = false;
          this.createNewBtn = false;
          this.editBtn = true;
          // this.downloadPdf(data["criteria"]);
          this.PopupMessageModal.show();
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  downloadPdf(invoicedata) {
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/generateinvoicepdf";
    let body = { "criteria": { "transId": this.model.invoicesrno.value, transType: this.invoiceType} }

    this.commonService.postMethod(apiUrl, body).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        console.log("success");
        this.fileName = successResp['Data']['filename'];
        console.log(this.fileName);
        this.downloadFile(this.fileName)
      }
      else {
        this.ErrorDescription = "Something went wrong, please contact admin";
      }
    },
      err => {
        this.ErrorDescription = "Something went wrong, please contact admin";
      });
  }

  downloadFile(filename: string) {
    let body = { "filename": filename }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/downloadpdf";
    this.commonService.getFile(apiUrl, body) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        // saveAs(blob, this.fileName);
        let a = document.createElement('a');

        let file = new Blob([blob], { type: 'application/pdf' });
        console.log("file ", file);

        let fileURL = URL.createObjectURL(file);

        this.dataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
        a.download = this.fileName;
        setTimeout(() => {
          this.printInvoicePopup.show();
        }, 200);


        this.fileName = "";
      })
      .catch((err) => {
        console.log("Something went wrong");
      });

  }


  deleteInvoice() {
    console.log("delete");
    let data = this.submit.submitForm(this.model);

    if (data && data["criteria"]["invoicesrno"]) {
      data["criteria"]["invoicelist"] = this.tabledataforprocess;
      console.log(data["criteria"]);

      let items: any = {};
      items.invoicesrno = data["criteria"]["invoicesrno"];
      items.CustomerNo = data["criteria"]["customerno"];

      items.invtype = this.invoiceType;
      items.delLineItems = [];

      console.log(items, data);

      data["criteria"]["invoicelist"].forEach((ele) => {
        if (ele?.SrNo) {
          items.delLineItems.push(ele?.SrNo);
        }
      })

      data["criteria"] = items;
      let endpoint = 'transaction/deleteInvoice';
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('closePopupDelete')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.clearAll(false);
          this.getAllInvoice();
        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }
  getNormalItemsForInvoice(invfor) {
    let endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getItemsForInvoice';
    let param = { invfor: invfor }
    this.commonService.getWithParams(endpoint, param).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.invoicepopupitemstabledata = [];
        this.invoicepopupitemstabledata = items;
      }
    }, err => { })
  }


  getRejectedItemsForInvoice() {
    let endpoint = 'transaction/getRejectedItemsForInvoice';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.invoicepopupitemstabledata = [];
        this.invoicepopupitemstabledata = items;
      }
    }, err => { })
  }



  getAllInvoice() {
    const endpoint = 'transaction/getAllInvoice';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        let newArr;
        if (this.invoiceType == 'R') {
          newArr = items.filter((item) => {
            return item.invtype !== 'M';
          })
        } else {
          newArr = items.filter((item) => {
            return item.invtype !== 'R';
          })
        }

        setTimeout(() => {
          this.invoicelefttabledata = newArr;
        }, 70);

        for (let i = 0; i < newArr.length; i++) {
          if (newArr[i]['invoicesrno'] == this.invoiceuniqueno || newArr[i]['invoicesrno'] == this.model.invoicesrno.value) {
            this.model.invoicesrno.value = newArr[i]['invoicesrno'];
            // console.log("Index", this.tabledata[i], i);
            this.getByIdClickCall(this.model.invoicesrno.value, newArr[i]['invtype']);
            if (!this.isOpenFromReport) {
            setTimeout(() => {
              document.getElementById('trinvoicelefttbl' + i).style.backgroundColor = "#7de4f0";
              $(`tr[tabindex=${i}]`).focus();
            }, 900);
          }
            break;
          }
        }
      }
    }, err => { })
  }

  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }

  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.customerno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.operation = 'edit';
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.invoicelefttabledata, modal.rowindex, this.currentSelectedRow, "invoicelefttbl");

    let row = modal['row'];
    let invoiceno = row['invoicesrno'];
    let invtype = row['invtype'];
    this.getByIdClickCall(invoiceno, invtype);
  }
  getByIdClickCall(id: any, type) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getInvoiceById";
    let params = { invoicesrno: id, invtype: type };

    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data'];
        this.bindDataToFormAndTable(item);
        this.dataForPrint = item;
      }
    }, (error) => {
      console.log(error);
    });
  }

  bindDataToFormAndTable(modal, flag: boolean = true) {
    if (this.model.customerno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }

    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    this.refreshDetailTable = false;
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    if(modal['InvType']=='M'){
      this.model.showpono.value = modal['pono']
    }

    this.model.invoiceno.value = modal['InvoiceNo'];

  if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.invoicelisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"routinedctbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }

    if (modal['isRejectBilling'] == "Y") {
      this.model.isBillForRejected.value = true;
    } else {
      this.model.isBillForRejected.value = false;
    }

    this.invoicelisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "quantity", false);
    if (this.invoiceType == "R") {
      this.isRowEditableOrNot(false);
    }
    if (this.invoiceType == "M") {
      this.allCellEditableForManualInvoice(false);
    }
    setTimeout(() => {
    this.refreshDetailTable = true;
    }, 10);
    if (modal['billfor'] == "R") {
      // this.getRejectedItemsForInvoice();
      this.getNormalItemsForInvoice('R');

    } else {
      this.getNormalItemsForInvoice('N');
    }
    this.invoicelisttabledata = modal['invoicelist']?.length ? modal['invoicelist'] : [];
    let newArr = this.invoicelisttabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.invoicelisttabledata = newArr;

    if(this.invoiceType == 'M'){
      this.model.taxId.value = modal['taxId']+"";
      this.model.servicetaxvat.value = modal['servicetaxvat']+"";
    }

    this.backupTableDataForProcess = this.helper.removeRefrence(this.invoicelisttabledata);

    this.taxPercentage = modal['servicetaxvat'];
    this.editBtn = true;
    this.editDisableBtn = false;
    this.isRowClicked = false;

  }

  addItems() {
    this.ShowSelectItemPopup.show();
  }

  selectItemsList(itemdata) {
    this.saveBtn = false;
    this.saveBtnVisible = true;

    let deppCopyPoDetailTable = this.helper.removeRefrence(this.tabledataforprocess);
    itemdata = this.helper.removeRefrence(itemdata);

    for (let i = 0; i < itemdata.length; i++) {
      for (let j = i; j < itemdata.length; j++) {
        if (itemdata[i].dcsrno == itemdata[j].dcsrno) {
          if (this.model.dcsrno.value && this.model.dcsrno.value !== itemdata[i].dcsrno) {
            this.toastService.showToastMsg('warning', 'Warning', "Can not select different delivery note.");
            return;
          }
          this.model.customerno.value = itemdata[i].customerno;
          this.model.dcsrno.value = itemdata[i].dcsrno;

        } else {
          this.toastService.showToastMsg('warning', 'Warning', "Can not select different delivery note.");
          return;
        }
        if (itemdata[i].pono == itemdata[j].pono) {
          let value = itemdata[i].pono;
          let itemNo = itemdata[i].ItemSrNo
          this.getDetailsFromPO(value, itemNo);
        }
      }
      let { dcoutdetailsrno, ItemSrNo:RejectedItemNo, itemno, PartNo, Price: price, Description, jobno, dnno, maxqty, qty, subtax, releaseno, dcsrno, pono, modelname, itemnumber } = itemdata[i];
      console.log(this.invoiceFor, this.priceForRejDC);
      // let price = 0;
      // if(this.invoiceFor == 'R'){
      //   price = this.priceForRejDC
      //   console.log('if', this.invoiceFor, price);
      // }else{
      //   console.log('else', this.invoiceFor, price);
      //   price = itemdata[i]['Price']
      // }
      console.log("price", price);

      deppCopyPoDetailTable.push({ RejectedItemNo, itemno, price, PartNo, Description, jobno, dnno, maxqty, qty: maxqty, subtax, releaseno, dcsrno, pono, dcoutdetailsrno, modelname, itemnumber });
    }
    this.invoicelisttabledata = deppCopyPoDetailTable;
    this.model.invoicedate.value = this.todaysDate;
    this.cancelPopup('closeitempopup');
  }

  addRowForManualInvoice() {
    let deepCopy = [...this.tabledataforprocess];

    const item = { PartNo: "", Description: "", jobno: "", DCOutSrNo: "", qty: 0, maxqty: 0, subtax: "", price: 0, amount: 0, releaseno: "", remark: "" };
    deepCopy.push(item);
    this.invoicelisttabledata = deepCopy;
    this.saveBtn = false;
    // this.invoicelisttableheaders.forEach((item) => {
    //   if (item?.key === 'subtax') {
    //     item.visiblecolm = true;
    //   }
    // })
  }

  checkSingleRecord(event){
    console.log(event);

    let checked = event['row']['checked']
    if(!checked && this.tabledataforprocess.length == 0){
      this.commonService.clearForm(this.model);
    }else if(!checked && this.tabledataforprocess.length > 0){
      return;
    }
  }

  updatedtabledata(data) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;
    let totalAmount = 0
    let subtax: string = '';

    setTimeout(() => {
      if (this.operation !== 'edit') {
        if (this.helper.storeSubTax) {
          let taxes = JSON.parse(this.helper.storeSubTax);
          taxes.forEach((item) => {
            subtax += item['subtax'] + '&nbsp;' + item['rate'] + '\n'
          })
        }

        data.forEach((item, i) => {
          if (this.invoiceFor == "R") {
            item['price'] = this.priceForRejDC;
          }
          console.log(item);

          item['amount'] = item['qty'] * item['price'];
          totalAmount += item['amount'];
          item['subtax'] = subtax;
        })
        let taxableAmt: number;
        if (this.invoiceType == 'N') {
          taxableAmt = totalAmount * this.taxPercentage / 100;
        } else {
          taxableAmt = totalAmount * +this.model.servicetaxvat.value / 100;
        }
        this.model.taxamount.value = taxableAmt.toFixed(2);
        this.model.taxableamount.value = totalAmount.toFixed(2);
        this.model.totalinvoiceamount.value = (totalAmount + taxableAmt).toFixed(2);
      }

      if (this.invoiceType == "R") {
        data.forEach((item, i) => {
          if (this.backupTableDataForProcess[i] && this.operation === 'edit') {
            this.priviousQty = this.backupTableDataForProcess[i]["qty"]

            item['totalqty'] = item['qty'] + item['invoicependingqty'];
            if (item['qty'] > item['totalqty']) {
              item['qty'] = this.backupTableDataForProcess[i]["qty"];
            }
          } else {
            item['totalqty'] = item['maxqty']
            if (item['qty'] > item['totalqty']) {
              item['qty'] = this.backupTableDataForProcess[i]["qty"];
            }
          }
        })
      }
    }, 300);
  }

  rowChangeOrUpdate(data) {
    let isJobSheetFreezed = data?.row?.isJobSheetFreezed;
    if (this.commonService.userRole != 'Admin' && data['keyName'] == 'qty' && isJobSheetFreezed == 'Y') {
      this.ErrorDescription = `Can not edit quantity for this item, because jobsheet has been freezed for this item. Please contact Admin to edit quantity.`;
      this.errorMessageModal.show();
      return;
    }
    this.operation = "update";
    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.totalqty || data?.row?.qty < 0) {
      this.ErrorDescription = `Enter valid Quantity. Can not enter Qunatity greater than ${data?.row?.totalqty}`;
      data.row.qty = data.row.oldquantity;
      this.errorMessageModal.show();
    }
  }

  getDetailsFromPO(value: string, itemno) {
    let params = { "prno": value, "itemsrno": itemno };
    console.log(params);

    let endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getPoDetailsForInvoice';
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];

        this.model.pono.value = value;
        this.model.showpono.value = items?.PoNo;
        this.model.servicetaxvat.value = items?.Rate;
        this.model.isBillForRejected.value = items?.ispoforreject == 'Y' ? true : false;
        this.model.taxId.value = items?.TaxId;
        this.model.sachsncode.value = items?.sachsncode;
        this.taxPercentage = this.model.servicetaxvat.value;
        this.priceForRejDC = items?.Price;
        console.log(this.priceForRejDC);

        // let totalTax = +this.model.taxableamount.value * +this.model.servicetaxvat.value / 100
        // let totalinvoiceamount = this.model.taxableamount.value + totalTax

        let subtax = items?.TaxSubDetails;
        console.log({subtax});

        if (subtax) {
          // this.invoicelisttableheaders.forEach((item) => {
          //   if (item?.key === 'subtax') {
          //     item.visiblecolm = true;
          //   }
          // })
          this.helper.storeSubTax = subtax;

        }
      }
    }, err => { })
  }

  bindPoNoAndGetItem(item) {
    let row = item['row'];
    console.log(row);

  }

  getRejectedPoList() {
    let endpoint = 'transaction/getRejectedPoList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.confirmPotabledata = [];
        this.confirmPotabledata = items;
      }
    }, err => { })
  }

  getTaxSelection() {
    let endpoint = 'transaction/gettax';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.taxArray = [];
        this.taxArray = successResp['Data'];
      }
    }, err => { })
  }

  fixTaxRate(val: any): void {
    // console.log(val);
      if (val == '0') {
        this.model.servicetaxvat.value = '0';
        this.model.servicetaxvat.err = "";
        this.helper.storeSubTax = 0
      }else{
      for (let items of this.taxArray) {
        if(items['tax'] == +val){
          this.model.taxId.value = items['tax'];
          this.model.servicetaxvat.value = items['Rate']?items['Rate']:0;
          this.helper.storeSubTax = items['TaxSubDetails']
          let amount = 0;
          this.tabledataforprocess.forEach((item)=>{
            item['amount'] = item['qty'] * item['price'];
            amount += item['amount']
          })
          let taxamount = (amount * +this.model.servicetaxvat.value / 100)
          this.model.taxamount.value = taxamount.toFixed(2);
        }

      }
      }
  }

  printPdf() {
    this.downloadPdf(this.dataForPrint);
  }

  cancelPopup(form) {
    switch (form) {
      case 'closeInvoicePopup':
        this.clearAll()
        this.selectIteminvoice.hide();
        break;
      case 'closeitempopup':
        this.ShowSelectItemPopup.hide();
        break;
      case 'backenderr':
        this.backendErrorMessageModal.hide();
        break;
      case 'closePopupDelete':
        this.showDeleteModal.hide();
        break;
    }
  }

  clearAll(flag: boolean = true) {
    this.commonService.clearForm(this.model);
    this.invoicelisttabledata = [];
    this.createNewBtn = true;
    this.visiblecanclebtn = false;
    this.deleteBtnDisable = false;
    this.createNewBtn = false;
    this.saveBtn = true;
    this.btnRow = false;
    this.visibleSelectBtn = false;
    this.visibleSelectBtnManual = false;
    this.saveBtnVisible = false;
    this.deleteMsg = "";
    this.commonService.diasbleFormFields(this.model);
    this.invoicelisttableconfig['actioncolmconfig']['displaycolumn'] = true;

    const ele = document.getElementById(`tr${"invoicelefttbl" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';

    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;
    }
  }

  clearForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
      } else {
        this.clearAll(true);
      }
    }
  }

  noNormalItem() {
    this.addItems();
    this.selectIteminvoice.hide();
  }

  yesRejectedItem(value: string) {
    this.selectIteminvoice.hide();
    this.addItems();
    this.invoiceFor = value;
    if (value == 'N') {
      this.getNormalItemsForInvoice('N');
    } else {
      // this.getRejectedPoList();
      // this.showPopupoSelectConfirmPo.show();
      this.getNormalItemsForInvoice('R');


      // this.getRejectedItemsForInvoice();
    }
  }

  selectItemPopUp() {
    this.refreshtable = false;
    this.ShowSelectItemPopup.show();
    setTimeout(() => {
      this.refreshtable = true;
    }, 30);
  }

  setTwoNumberDecimal(value, lable) {
    switch (lable) {
      case 'invamt':
        this.model.taxableamount.value = value.toFixed(2)
        break;

      case 'totamt':
        this.model.totalinvoiceamount.value = value.toFixed(2)
        break;
    }
  }

  editForm() {
    this.invoicelisttableconfig['actioncolmconfig']['displaycolumn'] = true;

    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.invoicelisttableheaders, "quantity", true);
    this.editDisableBtn = false;
    this.saveBtn = false;
    this.createNewBtn = true;
    this.saveBtnVisible = true;
    this.visiblecanclebtn = true;
    this.deleteBtnDisable = true;
    this.editBtn = false;
    if (this.invoiceType == "R") {
      this.isRowEditableOrNot(true);
    }
    if (this.invoiceType == "M") {
      this.allCellEditableForManualInvoice(true);
    }
    this.visibleSelectBtn = true;
    this.visibleSelectBtnManual = true;
    this.commonService.enableFormFields(this.model);

  }

  deleteRow(event) {
    let id = event['row'];
    this.tabledataforprocess.splice(event.rowindex, 1);
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    if (row[key]) {
      this.deletedArray.push(row[key])
    } else {
      this.deletedArray = [];
    }
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  closeErrorPopup() {
    this.errorMessageModal.hide()
  }

  isRowEditableOrNot(flag: boolean) {
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "qty", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "amount", false);
  }

  allCellEditableForManualInvoice(flag: boolean) {
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "itemnumber", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "modelname", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "Description", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "jobno", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "DCOutSrNo", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "qty", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "maxqty", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "price", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "releaseno", flag);
    this.invoicelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.invoicelisttableheaders), "subtax", flag);

  }

  closeModal() {
    this.ngbActiveModal.close();
  }

  sendInvoiceOnMail(){
    let body = { "criteria": { "transId": this.model.invoicesrno.value, transType: this.invoiceType} }

    let endpoint = 'transaction/sendMail';
    this.commonService.postMethod(endpoint, body, true).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        this.ErrorDescription = res['errorDescription'];
        // this.PopupMessageModal.show();
      }else{
        this.ErrorDescription = res['errorDescription'];
        this.errorMessageModal.show()
      }
    })
  }
}
