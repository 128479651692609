import { INavData } from '@coreui/angular';
// export class test{
//   static navToInward=()=>{
//     return (new Date().getTime());
//   }
// }
export const navItems: INavData[] | any = [
  {
    name: 'Master Maintenance',
    url: '/dashboard',
    icon: 'icon-speedometer',
    elementName:"master",

    children:[
      {
        name:'Sub-Assembly Master',
        url:'/sub-Assembly',
        icon: 'fa fa-wrench',
        elementName:"subasy"
      },
      {
        name:'Spare Master',
        url:'/spare-master',
        icon: 'fa fa-cubes',
        elementName:"spare"
      },
      {
        name:'Model Master',
        url:'/model-master',
        icon: 'fa fa-sun-o',
        elementName:"model"
      },
      {
        name:'Item Master',
        url:'/item-master',
        icon: 'fa fa-bullseye',
        elementName:"item"
      },
      {
        name:'Customer Master',
        url:'/customer-master',
        icon: 'icon-people',
        elementName:"customer"
      },
      {
        name:'Tax Master',
        url:'/tax-master',
        icon: 'icon-doc',
        elementName:"tax"
      },
      {
        name:'User Master',
        url:'/user-master',
        icon: 'icon-user',
        elementName:"user"
      }
    ]
  },
  {
    name: 'Transactions',
    url: '#',
    icon: 'icon-bulb',
    elementName:"trnx",

    children: [
      {
        name: 'PO Master',
        url: '/po-master',
        icon: 'icon-grid',
        elementName:"po"
      },
      {
        name: 'Inward Transactions',
        icon: 'icon-compass',
        elementName:"inwardtrnx",
        children: [
          {
            name: 'DC Inward',
            url: '/delivery-challan-inward',
            icon: 'icon-layers',
            elementName:"dcinward"
          },
          {
            name: 'Manual Inward Note ',
            url: '/manual-inward-note',
            icon: 'icon-energy',
            elementName:"manualinward"
          }
        ]

      },
      {
        name: 'Spare Transactions',
        icon: 'icon-briefcase',
        elementName:"sparetrnx",

        children: [
          {
            name: 'Spare Inward',
            url: '/spare-inward',
            icon: 'icon-disc',
            elementName:"spareinward"

          },
          {
            name: 'Spare Outward',
            url: '/spare-outward',
            icon: 'icon-magnet',
            elementName:"spareoutward"

          },
          {
            name: 'Spare Issued To Shop Floor',
            url: '/spare-issued',
            icon: 'icon-magic-wand',
            elementName:"spareissue"

          },
          {
            name: 'Spare Receipt From Shop Floor',
            url: '/spare-reciept',
            icon: 'icon-cursor',
            elementName:"sparerecipt"

          },
          {
            name: 'Spare Discrepancy',
            url: '/spare-discrepancy',
            icon: 'icon-fire',
            elementName:"sparediscrepancy"

          },
          {
            name: 'Spare Requisition',
            url: '/spare-requisition',
            icon: 'icon-anchor',
            elementName:"sparerequisition"
            
          }
        ]

      },
      {
        name: 'Job Sheet',
        url: '/job-sheet',
        icon: 'icon-folder-alt',
        elementName:"jobsheet"
      },
      {
        name: 'Material Outward',
        icon: 'icon-book-open',
        elementName:"outwardtrnx",

        children: [
          {
            name: 'DC Outward (Regular)',
            url: '/material-outward/dc-outward',
            icon: 'icon-basket-loaded',
            elementName:"regoutward"

          },
          {
            name: 'Routine DC',
            url: '/material-outward/routine-dc',
            icon: 'icon-wrench',
            elementName:"routinedc"
            
          },
          {
            name: 'Recjected Outward',
            url: '/material-outward/rejected-outward',
            icon: 'icon-dislike',
            elementName:"rejectedout"

          },
          {
            name: 'Manual DN',
            url: '/material-outward/manual-dn',
            icon: 'icon-rocket',
            elementName:"manualoutward"
          }
        ]

      },
      {
        name: 'Invoice',
        icon: 'icon-grid',
        elementName:"invoice",

        children:[
          {
            name: 'Regular Invoice',
            url: '/regular-invoice',
            icon: 'icon-rocket',
            elementName:"regularinvoice"

          },
          {
            name: 'Manual Invoice',
            url: '/manual-invoice',
            icon: 'icon-grid',
            elementName:"manualinvoice"
          }
        ]
      },
      // {
      //   name: 'Re Repairs',
      //   icon: 'icon-target',
      //   elementName:"rerepair",
      //   children:[
      //     {
      //       name: 'Re Repairs Inward',
      //       url: '/re-repairs/reRepairsInward',
      //       icon: 'icon-settings',
      //       elementName:"repairinward"
      //     },
      //     {
      //       name: 'Re Repairs Outward',
      //       url: '/re-repairs/reRepairsOutward',
      //       icon: 'icon-settings',
      //       elementName:"repairoutward"
      //     }
      //   ]
      // }
    ]
  },
  {
    name: 'Reports',
    url: '#',
    icon: 'icon-book-open',
    elementName:"reports",

    children: [
      {
        name: 'Spare Report',
        url: '/spare-report',
        icon: 'icon-disc',
        elementName:"sparereport"
      },
      {
        name: 'Item Report',
        url: '/item-report',
        icon: 'fa fa-bullseye',
        elementName:"itemreport"
      },
      {
        name: 'PO Report',
        url: '/po-report',
        icon: 'icon-grid',
        elementName:"poreport"
      },
      {
        name: 'Challan Ageing',
        url: '/challan-ageing',
        icon: 'icon-layers',
        elementName:"challanageing"
      }
    ]
  },
  {
    name: 'Utility',
    url: '',
    icon: 'icon-speedometer',
    elementName:"utility",
    children:[
      {
        name: 'Data Integrity',
        url: '/utility',
        icon: 'icon-settings',
        elementName:"dataintegrity"
      },
      {
        name: 'Mail Details',
        url: '/mail-sysparams',
        icon: 'icon-grid',
        elementName:"maildetails"
      }
    ]
  }
  
];

export const navItemsCopy: INavData[] | any = [
  {
    name: 'Master Maintenance',
    url: '/dashboard',
    icon: 'icon-speedometer',
    elementName:"master",

    children:[
      {
        name:'Sub-Assembly Master',
        url:'/sub-Assembly',
        icon: 'fa fa-wrench',
        elementName:"subasy"
      },
      {
        name:'Spare Master',
        url:'/spare-master',
        icon: 'fa fa-cubes',
        elementName:"spare"
      },
      {
        name:'Model Master',
        url:'/model-master',
        icon: 'fa fa-sun-o',
        elementName:"model"
      },
      {
        name:'Item Master',
        url:'/item-master',
        icon: 'fa fa-bullseye',
        elementName:"item"
      },
      {
        name:'Customer Master',
        url:'/customer-master',
        icon: 'icon-people',
        elementName:"customer"
      },
      {
        name:'Tax Master',
        url:'/tax-master',
        icon: 'icon-doc',
        elementName:"tax"
      },
      {
        name:'User Master',
        url:'/user-master',
        icon: 'icon-user',
        elementName:"user"
      }
    ]
  },
  {
    name: 'Transactions',
    url: '#',
    icon: 'icon-bulb',
    elementName:"trnx",

    children: [
      {
        name: 'PO Master',
        url: '/po-master',
        icon: 'icon-grid',
        elementName:"po"
      },
      {
        name: 'Inward Transactions',
        icon: 'icon-compass',
        elementName:"inwardtrnx",
        children: [
          {
            name: 'DC Inward',
            url: '/delivery-challan-inward',
            icon: 'icon-layers',
            elementName:"dcinward"
          },
          {
            name: 'Manual Inward Note ',
            url: '/manual-inward-note',
            icon: 'icon-energy',
            elementName:"manualinward"
          }
        ]

      },
      {
        name: 'Spare Transactions',
        icon: 'icon-briefcase',
        elementName:"sparetrnx",

        children: [
          {
            name: 'Spare Inward',
            url: '/spare-inward',
            icon: 'icon-disc',
            elementName:"spareinward"

          },
          {
            name: 'Spare Outward',
            url: '/spare-outward',
            icon: 'icon-magnet',
            elementName:"spareoutward"

          },
          {
            name: 'Spare Issued To Shop Floor',
            url: '/spare-issued',
            icon: 'icon-magic-wand',
            elementName:"spareissue"

          },
          {
            name: 'Spare Receipt From Shop Floor',
            url: '/spare-reciept',
            icon: 'icon-cursor',
            elementName:"sparerecipt"

          },
          {
            name: 'Spare Discrepancy',
            url: '/spare-discrepancy',
            icon: 'icon-fire',
            elementName:"sparediscrepancy"

          },
          {
            name: 'Spare Requisition',
            url: '/spare-requisition',
            icon: 'icon-anchor',
            elementName:"sparerequisition"
            
          }
        ]

      },
      {
        name: 'Job Sheet',
        url: '/job-sheet',
        icon: 'icon-folder-alt',
        elementName:"jobsheet"
      },
      {
        name: 'Material Outward',
        icon: 'icon-book-open',
        elementName:"outwardtrnx",

        children: [
          {
            name: 'DC Outward (Regular)',
            url: '/material-outward/dc-outward',
            icon: 'icon-basket-loaded',
            elementName:"regoutward"

          },
          {
            name: 'Routine DC',
            url: '/material-outward/routine-dc',
            icon: 'icon-wrench',
            elementName:"routinedc"
            
          },
          {
            name: 'Recjected Outward',
            url: '/material-outward/rejected-outward',
            icon: 'icon-dislike',
            elementName:"rejectedout"

          },
          {
            name: 'Manual DN',
            url: '/material-outward/manual-dn',
            icon: 'icon-rocket',
            elementName:"manualoutward"
          }
        ]

      },
      {
        name: 'Invoice',
        icon: 'icon-grid',
        elementName:"invoice",

        children:[
          {
            name: 'Regular Invoice',
            url: '/regular-invoice',
            icon: 'icon-rocket',
            elementName:"regularinvoice"

          },
          {
            name: 'Manual Invoice',
            url: '/manual-invoice',
            icon: 'icon-grid',
            elementName:"manualinvoice"
          }
        ]
      },
      // {
      //   name: 'Re Repairs',
      //   icon: 'icon-target',
      //   elementName:"rerepair",
      //   children:[
      //     {
      //       name: 'Re Repairs Inward',
      //       url: '/re-repairs/reRepairsInward',
      //       icon: 'icon-settings',
      //       elementName:"repairinward"
      //     },
      //     {
      //       name: 'Re Repairs Outward',
      //       url: '/re-repairs/reRepairsOutward',
      //       icon: 'icon-settings',
      //       elementName:"repairoutward"
      //     }
      //   ]
      // }
    ]
  },
  {
    name: 'Reports',
    url: '#',
    icon: 'icon-book-open',
    elementName:"reports",

    children: [
      {
        name: 'Spare Report',
        url: '/spare-report',
        icon: 'icon-disc',
        elementName:"sparereport"
      },
      {
        name: 'Item Report',
        url: '/item-report',
        icon: 'fa fa-bullseye',
        elementName:"itemreport"
      },
      {
        name: 'PO Report',
        url: '/po-report',
        icon: 'icon-grid',
        elementName:"poreport"
      },
      {
        name: 'Challan Ageing',
        url: '/challan-ageing',
        icon: 'icon-layers',
        elementName:"challanageing"
      }
    ]
  },
  {
    name: 'Utility',
    url: '',
    icon: 'icon-speedometer',
    elementName:"utility",
    children:[
      {
        name: 'Data Integrity',
        url: '/utility',
        icon: 'icon-settings',
        elementName:"dataintegrity"
      },
      {
        name: 'Mail Details',
        url: '/mail-sysparams',
        icon: 'icon-grid',
        elementName:"maildetails"
      }
    ]
  }
  
];





