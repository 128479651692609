import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GtPlusDataService {

  private gtplusTopic: Subject<any> = new Subject<any>()
  public gtplusCellmenulist:any[] = []

  public autoLogNotes = {};/// This variable will store the auto log notes

  constructor() { }


  /*****************
   * Below method will share the data to GT-Plus, follow the JSON structure.
   *
   * {
   *
   * }
   *
   *
   */

  sendGtData(data) {
    this.gtplusTopic.next(data);
  }


  getgtPlusData() {
    return this.gtplusTopic.asObservable();
  }


}
