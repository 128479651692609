import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonServicesService } from '../../../services/common-services.service';
import { SystemParamsService } from '../../../services/system-params.service';

@Component({
  selector: 'app-job-sheet-popup',
  templateUrl: './job-sheet-popup.component.html',
  styleUrls: ['./job-sheet-popup.component.css']
})
export class JobSheetPopupComponent implements OnInit, OnChanges {

   // filter
   displayjobnumwisetableconfig: any = {
    "tableclasses": "table-striped sticky-header sorting  horizontal-scroll table-lightblue  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
      "evenrowstyle": { "cursor": "pointer" },
      "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "controlconfig": { "displaycontrol": true, "controlsfor": {}  },
    "defaultcellmenus": [],
    "fullheighttable": 'fullheight-table',
  };

  displayjobnumwisetableheaders: any = [
    {
      "key": "refrenceno",
      "display": "Ref No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "dnno",
      "display": "Dc No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "issuedate",
      "display": "DC Inward Date",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "date",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "itemname",
      "display": "Item No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "model",
      "display": "Model",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    }, {
      "key": "jmdescription",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "jobno",
      "display": "Job No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "quantity",
      "display": "Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "esn",
      "display": "ESN",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "customername",
      "display": "Customer",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "customernm",
      "display": "Customer",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "itemname",
      "display": "Item Name",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "engtypejeop",
      "display": "Eng Type",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "jmremark",
      "display": "Remark",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "dnno",
      "display": "DC No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    }
  ];

  @Input() data: any =  []
  @Input() displayjobnumwisetabledata: any =  []
  tabledata:any[] = []


  constructor(private commonService: CommonServicesService, public systemParamsService:SystemParamsService, private ngbActiveModal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log(this.displayjobnumwisetabledata);
    // this.tabledata = this.displayjobnumwisetabledata;
    console.log("In Jobsheet Popup, ngOnInit");

    let ele = document.getElementById('loader-div');
    ele.style.display = "block";
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.displayjobnumwisetabledata);

    console.log("In Jobsheet Popup, ngOnChanges");
    console.log(changes);
    if(changes){
      this.tabledata = this.data;
    }

  }

    ngAfterViewInit(): void{
    console.log(this.displayjobnumwisetabledata);

    console.log("In Jobsheet Popup, ngAfterViewInit");
    setTimeout(() => {
      this.tabledata = this.displayjobnumwisetabledata;
        let ele = document.getElementById('loader-div');
        ele.style.display = "none";
      }, 50);
    }

  displayJobNoWiseDataBind(modal) {
    console.log(modal);

    this.closeModel({data : modal, status : 'select'});
    // let row = modal['row'];
    // this.model = this.commonService.setFromKeysValues(this.model, row);
    // // row.quantity = row?.maxquantity;
    // console.log(row.ItemSrNo);
    // this.jobsheetQty = row.quantity;
    // if (row.ItemSrNo) {
    //   this.getListForJobSheet(row.ItemSrNo)
    //   this.updateBomButton = false;
    // }
    // this.cancelPopup('JobSheetFormPopupClose')
  }


  closeModel(data = null) {
    this.ngbActiveModal.close(data);
  }




}
