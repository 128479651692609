import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DataSharingService {

  private gtplusTopic: Subject<any> = new Subject<any>()

  constructor() { }

  sendData() {
    this.gtplusTopic.next({});
  }


  getgtPlusData() {
    return this.gtplusTopic.asObservable();
  }






}
