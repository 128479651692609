import { Injectable } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toasterService: ToasterService;

  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3500
    });

  constructor(toasterService: ToasterService) {
    this.toasterService = toasterService;
    // console.log(this.toasterService);

  }

  private showSuccess() {
    console.log('success');
    setTimeout(() => {
      this.showWarning(); console.log('warning');
    }, 1000);
    this.toasterService.pop('success', 'Success Toaster', 'This is toaster description');
  }

  public showError(msg: string, desc: string = '') {
    this.toasterService.pop('error', `Error ${msg}`, desc);
  }

  private showWarning() {
    this.toasterService.pop('warning', 'Warning Toaster', 'This is toaster description');
  }

  private showInfo() {
    this.toasterService.pop('info', 'Info Toaster', 'This is toaster description');
  }

  private showPrimary() {
    this.toasterService.pop('primary', 'Primary Toaster', 'This is toaster description');
  }

  showErrorToastInArray(errorArray: any) {
    errorArray.forEach(msg => {
      // console.log(msg);
      this.showError('', msg);
    });
  }

  showToastMsg(type: string, title: string, msg: string='') {
    // console.log("showToastMsg");

    this.toasterService.pop(type,title,msg);
  }
}
