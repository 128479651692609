
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../appconfig'
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfirmationPopupModel } from '../../models/models';
import { CommonServicesService } from '../../services/common-services.service';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DataSharingService } from '../gt-plus/data-sharing.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { helpers } from 'chart.js';
import { HelperService } from '../../services/helper.service';
import { element } from 'protractor';
import { ToastService } from '../../services/toast.service';
import { CommonFunctionService } from '../../services/common-function.service';
declare var $;
@Component({
  selector: 'app-item-master',
  templateUrl: './item-master.component.html',
  styleUrls: ['./item-master.component.scss']
})
export class ItemMasterComponent implements OnInit {
  @ViewChild('itemMasterForm') public resetitemMasterForm: NgForm;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('showSpareModal', { static: false }) public showSpareModal;
  @ViewChild('showErrorModal', { static: false }) public showErrorModal;

  @Input() showonlyForm: boolean = false;
  @Input() showRightSideFormAndTable: boolean = false;
  @Input() itemmasterevent = "";
  @Input() leftsiderowitem: any = {}
  @Input() leftsidetbl: boolean = true;
  @Input() item: any;
  @Input() ItemSrNo: number;

  createNewBtn: boolean = true;
  addsparebtn: boolean = true;
  opensparebtn: boolean = true;
  popupCloseModel: boolean = false;
  dynamicformdata: any;
  modeldynamicformdata: any;
  subassembdynamicformdata: any;
  dynamicTable: any = {};
  subDynamicTable: any = {}
  showloader: boolean = false;
  showconfirmpopup: boolean = false;
  displayTable: boolean = false;
  displaySubTable: boolean = false;
  errormsg: string = "";
  headers = new HttpHeaders();
  confirmationpopupmodelobj: ConfirmationPopupModel = new ConfirmationPopupModel();
  validationmsgforQty: string = "";
  validationmsg: string = "";
  allowedToAddEditCtrl: boolean = true;
  allowedtoaddeditctrl: boolean = true;
  isRowClicked:boolean = false;
  isInsertTableData:boolean = false;

  rowIndex: any;
  colIndex: any;
  deleteMsg = "";
  btnRow: boolean = true;
  public itemMasterTab: boolean = true;
  rowsperpage = 100;
  itemCode: string = "";
  remarks: string = "";
  routineDC: boolean = false;
  modelName: string = "";
  subAssembly: string = "";
  disableSaveBtn: boolean = false;
  disableNewBtn: boolean = false;
  disableCancelBtn: boolean = false;
  disableItemCode: boolean = false;
  disableRemark: boolean = false;
  disableRoutineDc: boolean = false;
  disableModelName: boolean = false;
  disableSubAssembly: boolean = false;
  disableAddModelNameBtn: boolean = false;
  disableAddSubAssBtn: boolean = false;
  disableTax: boolean = false;
  disableRate: boolean = false;
  editDisableBtn: boolean = false;
  modelNameList: any = [];
  subAssemblyList: any = [];
  tabledataprocess: any = [];
  deletedArray: number[] = [];
  operation: string = "";
  operationStage: string = "";
  isEditable: boolean = false;
  srNoForSave;
  emptySearchField: boolean = true;
  showFormsPopup: boolean = false;
  showModelDynamicForm: boolean = false;
  showSubAssemblyDynamicForm: boolean = false;
  tempModelName = "";
  isPoPopUpOpen: boolean = false;
  showSpareTablePopup: boolean = false;
  itemUniqueNo: number = 0;
  spareDynamicTable: any = {};
  currentSelectedRow: number = 0;
  deepCopySpareDynamicTable: any = {};
  spareItemTableConfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": false },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": false,
      "selectedFieldsArray": [
        {
          "key": 'checkbox',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    },
    "showcolumnlevelcheckbox":true
  };
  showSpareTable: boolean = false;
  highlightedObj = {
    "id": "",
    "indx": -1
  }
  userRole: string = "";
  deleteById;
  opeartionStageForDelete: string = "";
  showSpareformPopup: boolean = false;
  showSpareDynamicForm: boolean = false;
  sparedynamicformdata: any;
  savebuttonflagforQty: boolean = false;
  public matReqTab: boolean = true;
  whichStage: string = "";
  tempArrayToSend: any[] = [];
  duplicationSpareMsg: string = "";
  validationMsgForSpareNo: string = "";
  doubleClickStage: string = "";
  indexForEdit: number;
  editOperation: string = "";
  eventRowIndex;
  uniqueMsg: string = "";
  oprationForSearchPannel: string = "";
  showSearchPannelProp: boolean;
  searchPannelClick: string = "";
  itemTax;
  itemRate: number;
  visibleNewBtn: boolean = false;
  visibleSaveBtn: boolean = false;
  visibleCancelBtn: boolean = false;
  taxList: any = [];
  editBtn: boolean = false;
  savebtn: boolean = false;
  CancelBtn: boolean = false;
  deleteBtn: boolean = false;

  tableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": true,
      "delete": true,
      "print": false
    },
    "partno": { "text-align": "left" },
    "Qty": { "text-align": "Right" },
    "CurrentBalance": { "text-align": "Right" },
    "WIP": { "text-align": "Right" },
    "controlconfig": { "displaycontrol": false },
    // "checkDeletedFlag": true,
    // "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  };
  tableheaders = [];
  tabledata = [];


  subtableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "enablefirstbtn": false,
    "enablesecondbtn": false,
    "firstbtntext": "Add Spares",
    "secondbtntext": "Spare List",
    "firstBtnStyle": {},
    "secondBtnStyle": {},
    "firstBtnClass": "btn btn-primary btn-sm",
    "secondBtnClass": "btn btn-primary btn-sm",
    "PartNo": { "text-align": "center" },
    "Qty": { "text-align": "center", "background-color": "#b4f9df" },
    "CurrentBalance": { "text-align": "center" },
    "WIP": { "text-align": "center" },
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": true }
  };
  subtableheaders = [
    {
      "key": "PartNo",
      "display": "Part No",
      "sorting": true,
      "enablehide": true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "15%" }
    },
    {
      "key": "Description",
      "display": "Description",
      "sorting": true,
      "enablehide": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "35%" }
    },
    {
      "key": "Qty",
      "display": "Qty",
      "editable": true,
      "sorting": true,
      "visiblecolm": true,
      "enablehide": true,
      "datatype": "number",
      "positive": true,
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "15%" },
      "tdalignment": "hr-center vt-middle",
    },
    {
      "key": "CurrentBalance",
      "display": "Cur Bal",
      "sorting": true,
      "enablehide": true,
      "datatype": "number",
      "positive": true,
      "decimaldigit": 0,
      "tdalignment": "hr-center vt-middle",
      "thstyle": { "text-align": "center", "width": "15%" },
    },
    {
      "key": "WIP",
      "display": "WIP",
      "sorting": true,
      "enablehide": true,
      "datatype": "number",
      "positive": true,
      "decimaldigit": 0,
      "tdalignment": "hr-center vt-middle",
      "thstyle": { "text-align": "center", "width": "10%" }
    }
  ];
  subtabledata = [];

  tabledataforprocess = [];

  addSpareButton: boolean = true;
  spareListButton: boolean = true;

  @ViewChild('showSpare', { static: false }) public showSpare: ModalDirective;
  @ViewChild('showSpareTableModal', { static: false }) public showSpareTableModal: ModalDirective;

  constructor(private commonFunction:CommonFunctionService, private dataSharingSrvc: DataSharingService, private ngbActiveModal: NgbActiveModal, private datepipe: DatePipe, public commonservices: CommonServicesService, private configurationsetting: ConfigurationSetting, private helperservice: HelperService, private toastService: ToastService) { }

  ngOnInit() {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];

    setTimeout(() => {
      if (!this.leftsidetbl) {
        this.CancelBtn = false;
        this.opensparebtn = true;
        this.createNewBtn = false;
        this.popupCloseModel = true;
        this.deleteBtn = false;
        this.addsparebtn = true;
        this.rowDoubleClickItemMaster(this.leftsiderowitem, false);
      } else {
        this.getMasterTable();
      }
    }, 400)
    this.getSpareList();
    this.routineDC = false;
    /*
       below settimeout is used to disable form for the forst time when user enters
       */
    setTimeout(() => {
      if (!this.showonlyForm)
        this.disableForm();
    }, 1000)
    if (this.showonlyForm) {
      // this.visibleSaveBtn = true;
      // this.visibleNewBtn = false;
      setTimeout(() => {
        this.showItemMasterForm();
      }, 200)
    }
    this.getModelNameList();
    this.getSubAssemNameList();
    this.getTaxList()


    // this.getSpareList();
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.showonlyForm) {
      // this.visibleSaveBtn = true;
      // this.visibleNewBtn = false;
      setTimeout(() => {
        this.showItemMasterForm();
      }, 200)
    }

  }


  showItemMasterForm() {
    this.operation = "NEW";
    this.emptySearchField = false;
    const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    this.itemUniqueNo = 0;
    this.srNoForSave = 0;

    this.editBtn = false;
    this.btnRow = true;
    this.deleteMsg = "";
    this.savebtn = true;
    this.whichStage = "newTable";
    this.disableItemCode = false;
    this.disableRemark = false;
    this.disableRoutineDc = false;
    this.disableModelName = false;
    this.disableSubAssembly = false;
    this.disableAddModelNameBtn = false;
    this.disableAddSubAssBtn = false;
    this.disableTax = false;
    this.disableRate = false;
    this.CancelBtn = true;
    this.addSpareButton = false;
    this.spareListButton = false;
    this.subtableheaders = this.commonservices.enableEditForGt(this.helperservice.removeRefrence(this.subtableheaders), "Qty", true);
    // this.disableSaveBtn = true;
    this.disableNewBtn = true;
    this.resetitemMasterForm.reset();
    this.subDynamicTable['tabledata'] = [];
    this.tabledataforprocess = [];
    if (!this.showonlyForm) {
      this.subDynamicTable['tabledata'] = [];
      this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));

      this.subDynamicTable['tableconfig']['enablefirstbtn'] = true;
      this.subtableconfig['actioncolmconfig']['displaycolumn'] = true;


      this.subDynamicTable['tableconfig']['enablesecondbtn'] = true;
      this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = true;
      // this.subDynamicTable['tableheaders'][1]['editable']=true;
      this.subDynamicTable['tableconfig']['exporttoexcel'] = false;
    }
    this.displaySubTable = true;
    // this.allowedToAddEditCtrl = true;
    //this.isEditable = false;
    //this.isEditable = true;
    this.visibleNewBtn = false;
    this.visibleSaveBtn = true;
    this.visibleCancelBtn = false;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  disableInputs() {
    this.disableItemCode = true;
    this.disableRemark = true;
    this.disableRoutineDc = true;
    this.disableModelName = true;
    this.disableSubAssembly = true;
    this.disableAddModelNameBtn = true;
    this.disableAddSubAssBtn = true;
  }

  disableForm() {
    this.disableInputs();
    this.routineDC = false;
    this.disableTax = true;
    this.disableRate = true;
    this.visibleNewBtn = true;
    this.visibleSaveBtn = false;
    this.visibleCancelBtn = false;
  }

  deleteRow(event) {
    // console.log(event);
    let id = event['row'];
    const tempdata = this.helperservice.removeRefrence(this.subDynamicTable['tabledata'])
    tempdata.splice(event.rowindex, 1);

    this.subDynamicTable['tabledata'] = [];
    this.subDynamicTable['tabledata'] = tempdata;
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }

  rowDoubleClickItemMaster(event, flag = true) {
    console.log(event);
    
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 5000);
    this.isRowClicked = true;

      return;
    }
    this.isRowClicked = true;
    if (this.disableModelName == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    // console.log("event", event);

    this.createNewBtn = true;
    this.leftsiderowitem = this.helperservice.removeRefrence(event);
    console.log(this.leftsiderowitem);
    // console.log( this.srNoForSave);
    this.editDisableBtn = false;
    this.addSpareButton = true;
    this.spareListButton = true;
    this.deleteBtn = false;
    this.CancelBtn = false
    this.savebtn = false;
    this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.subtableheaders = this.commonservices.enableEditForGt(this.helperservice.removeRefrence(this.subtableheaders), "Qty", false);
    this.editBtn = true;

    let srNo;
    if (!this.leftsidetbl) {
      srNo = this.ItemSrNo;
      // console.log("Spare Master", srNo);
    } else {
      srNo = event['row']['SrNo']
      // console.log("Item Master", srNo);
    }
    // console.log("srNo", srNo);

    this.getFormDataAndTableData(srNo);

    this.itemCode = this.leftsiderowitem["row"]["ItemNo"];
    setTimeout(() => {
      this.subAssembly = this.leftsiderowitem["row"]['subAssemblySrNo'];
      this.modelName = this.leftsiderowitem["row"]['ModelNo'];
    }, 800);


    this.displaySubTable = true;
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      if (this.oprationForSearchPannel == "editItemMaster") {
        this.subDynamicTable['tableconfig']['defaultsearch'] = false;
        this.oprationForSearchPannel = "";
      }

      this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
      this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = false;

      setTimeout(() => {
        this.currentSelectedRow = event.rowindex;
        this.commonservices.identifySelectedRow(this.tabledata, event.rowindex, this.currentSelectedRow, "table1");
      }, 200);

      this.itemCode = event['row']['itemcode'];
      if (event['row']['Remarks'] == "null") {
        this.remarks = "";

      } else {
        this.remarks = event['row']['Remarks'];

      } if (event['row']['RoutineDC'] == "Y") {
        this.routineDC = true;

      } else {
        this.routineDC = false;

      }
      this.disableItemCode = true;
      this.disableRemark = true;
      this.disableRoutineDc = true;
      this.disableModelName = true;
      this.disableSubAssembly = true;
      this.disableTax = true;
      this.disableAddModelNameBtn = true;
      this.disableAddSubAssBtn = true;
      this.visibleNewBtn = true;
      this.visibleSaveBtn = false;
      this.visibleCancelBtn = false;
      this.subDynamicTable['tableconfig']['enablefirstbtn'] = false;
      this.subDynamicTable['tableconfig']['enablesecondbtn'] = false;
      // this.subtableconfig = this.subDynamicTable["tableconfig"];
      this.subtabledata = this.subDynamicTable["tabledata"];
      // this.subtableheaders = this.subDynamicTable["tableheaders"];
      // this.modelName = event['row']['ModelNo'];
      this.subAssembly = event['row']['subAssemblySrNo'];
      this.itemTax = event['row']['tax'];
      this.itemRate = event['row']['rate'];
      this.displaySubTable = true;
      this.doubleClickStage = "rowDoubleClickItemMaster";
      this.btnRow = true;
      if (!flag)
        this.editForm(this.leftsiderowitem);

    } else {
      this.showloader = false;
      alert("Internet Connection is Not Available.");
    }

  }

  getFormDataAndTableData(SrNo) {
    let apiUrl = this.configurationsetting.getBaseUrl() + "Master/GetListofSpareforItem";
    let params;
    params = { "partvalue": SrNo, "requestfor": "Item" };
    this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.subDynamicTable['tabledata'] = [];
        this.subDynamicTable['tabledata'] = JSON.parse(JSON.stringify(successResp['Data']));
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        if(successResp['Data'].length){
          this.isInsertTableData = false;
          // console.log(false);
        }else{
          this.tabledataforprocess = [];
          this.isInsertTableData = true;
          // console.log(true);
        }
        this.isRowClicked = false;

      }else{
        this.isRowClicked = false;

      }
    }, errorResp => {
      this.isRowClicked = false;

      this.showloader = false;
      this.errormsg = errorResp['message']
    });
  }

  checkUniqueSpPartCode(event) {
    if (navigator.onLine) {
      if (event['controlname'] == "partno") {
        this.errormsg = "";
        this.validationMsgForSpareNo = "";
        this.showloader = true;
        this.loaderTimeOut();
        let apiurl = this.configurationsetting.getBaseUrl() + "Master/CheckUniqueSpareNumber";
        let params = { "sparenumber": event['value']['currentTarget']['value'] };


        this.commonservices.getWithParams(apiurl, params)
          .subscribe(successResp => {
            this.showloader = false;
            if (successResp['errorCode'] == 0) {
              this.validationMsgForSpareNo = successResp['errorDescription'];
              this.sparedynamicformdata['css']['btnconfiguration']['disableSave'] = true;
              // this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
            } else if (successResp['errorCode'] != 0) {
              this.validationMsgForSpareNo = "";
              // this.sparedynamicformdata['css']['btnconfiguration']['visiblesave'] = true;
              this.sparedynamicformdata['css']['btnconfiguration']['disableSave'] = false;
            }
          }, errorResp => {
            this.showloader = false;
            this.errormsg = errorResp['message']
          });
      }

    } else {
      this.showloader = false;
      alert("Internet Connection is Not Available.");
    }
  }

  showSearchPannel(event) {
    this.subDynamicTable['tableconfig']['defaultsearch'] = event['checkedsearchpannel'];
    // if(this.searchPannelClick == "editItemMaster"){
    //   this.subDynamicTable['tableconfig']['enablefirstbtn'] = event['checkedsearchpannel'];
    //   this.subDynamicTable['tableconfig']['enablesecondbtn'] = event['checkedsearchpannel'];
    // }


  }

  editForm(event) {
    // console.log(event);
    if (!this.leftsidetbl) {
      this.srNoForSave = event["row"]["SrNo"];
    }
    this.editBtn = false;
    this.disableItemCode = false;
    this.disableRemark = false;
    this.disableRoutineDc = false;
    this.disableModelName = false;
    this.disableSubAssembly = false;
    this.disableTax = false;
    this.editDisableBtn = true
    this.disableAddModelNameBtn = false;
    this.disableAddSubAssBtn = false;
    this.deleteBtn = true;
    this.disableNewBtn = true;
    this.addSpareButton = false;
    this.spareListButton = false;
    // this.disableSaveBtn = false;
    // this.disableCancelBtn = false;
    // this.disableNewBtn = true;
    this.visibleNewBtn = false;
    this.visibleSaveBtn = true;
    this.visibleCancelBtn = true;
    this.savebtn = true;
    this.CancelBtn = true;
    this.subtableheaders = this.commonservices.enableEditForGt(this.helperservice.removeRefrence(this.subtableheaders), "Qty", true);

    this.subDynamicTable['tableconfig']['enablefirstbtn'] = true;
    this.subDynamicTable['tableconfig']['enablesecondbtn'] = true;
    // this.subtableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = true;
    this.editItemMaster(event);

  }

  getMasterTable() {
    this.errormsg = "";
    this.showloader = true;
    this.commonservices.getGenericTable("ItemMaster", this.configurationsetting.getBaseUrl())
      .subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == 0) {

          this.dynamicTable = successResp['Data'][0]['@oritable'];
          this.dynamicTable['tabledata'] = successResp['Data'][0]['@tbldata'];
          this.subDynamicTable['tableheaders'] = successResp['Data'][0]['@subtable']['tableheaders'];
          this.subDynamicTable['tableconfig'] = successResp['Data'][0]['@subtable']['tableconfig'];

          this.disableForm();
          this.savebtn = false;
          this.editDisableBtn = false;
          this.disableNewBtn = false;
          this.addSpareButton = true;
          this.spareListButton = true;
          this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = false;

          let rowData = successResp['Data'][0]['@tbldata'];
          for (let i = 0; i < rowData.length; i++) {
            if (rowData[i]['SrNo'] == this.itemUniqueNo) {
              // console.log(rowData[i]);

              this.rowDoubleClickItemMaster({
                "row": rowData[i],
                "rowindex": i
              })

              setTimeout(() => {
                //   // rowData[i]['SrNo'] = this.itemUniqueNo;
                //   // this.itemCode = rowData[i]['itemcode']
                //   // this.subAssembly = rowData[i]['subAssemblySrNo']
                //   // this.modelName = rowData[i]['ModelNo']
                //   // this.routineDC = rowData[i]['RoutineDC']
                //   // this.remarks = rowData[i]['Remarks']
                $(`tr[tabindex=${i}]`).focus();
                //   document.getElementById('trtable1' + i).style.backgroundColor = "#7de4f0";
              }, 900);
              break;
            }
          }
          this.subDynamicTable['tableconfig']['defaultsearch'] = false;
          /*********changes starts here********/
          if (this.userRole == "Admin") {
            this.dynamicTable['tableconfig']['actioncolmconfig']['delete'] = true;
          } else {
            this.dynamicTable['tableconfig']['actioncolmconfig']['delete'] = false;
          }
          // this.tableconfig = this.dynamicTable["tableconfig"];
          this.tabledata = this.dynamicTable["tabledata"];
          this.tableheaders = this.dynamicTable["tableheaders"];
          /********chnages ends here*********/
          this.displayTable = true;
          // this.displaySubTable = true;
          this.subDynamicTable['tableconfig']['exporttoexcel'] = false;

        }
        else {
          this.errormsg = successResp["errorDescription"];
        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });
  }

  deleteItemMaster(event) {
    if (navigator.onLine) {
      if (this.operation == "editItemMaster" || this.doubleClickStage == "rowDoubleClickItemMaster") {
        const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.operation = "";
        this.doubleClickStage = "";
      }
      this.deleteById = event['row']['SrNo'];
      this.opeartionStageForDelete = "delete";
      this.CancelBtn = false;
      this.deleteBtn = false;
      this.btnRow = false;
      this.confirmDeleteItemMaster()
      // this.displayMessagePopup("Are you sure want to delete this record?", "Yes", true, "No", true);
    }
    else {
      this.displayMessagePopup("Internet Connection is Not Available.", "Ok", true, false, "");
    }
  }
  showDeleteConfirmationYes() {
    // console.log(this.srNoForSave);

    this.showDeleteModal.show();
  }
  deleteItem(event) {
    this.deleteItemMaster(event);
    this.showDeleteModal.hide();
  }

  confirmDeleteItemMaster() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/CheckDeleteItem";
      let obj = {};
      // obj['deletefor'] = "Item"
      obj['ItemNo'] = this.deleteById;

      this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == 3) {
          this.showconfirmpopup = false;
          this.opeartionStageForDelete = "NOtdeleted";
          // console.log(successResp['errorDescription'], 1);

          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);


        } else if (successResp['errorCode'] == "000000") {
          this.showconfirmpopup = false;
          this.opeartionStageForDelete = "deleted";
          // console.log(successResp['errorDescription'], 2);

          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
        } else {

          // console.log(this.subDynamicTable['tableconfig']['actioncolmconfig']);
          this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.subtableheaders = this.commonservices.enableEditForGt(this.helperservice.removeRefrence(this.subtableheaders), "Qty", false);
          this.createNewBtn = true;
          this.disableNewBtn = false;
          this.disableItemCode = true;
          this.disableRemark = true;
          this.disableRoutineDc = true;
          this.disableModelName = true;
          this.disableSubAssembly = true;
          this.disableAddModelNameBtn = true;
          this.disableAddSubAssBtn = true;
          this.addSpareButton = true;
          this.spareListButton = true;
          // console.log(successResp['errorDescription'], 3);

          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
          // alert(`${successResp['errorDescription']}`)
          this.errormsg = successResp['errorDescription']
        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      this.showloader = false;

      alert("Internet Connection is Not Available.");

    }
  }

  cancelConfirmationPopup() {
    this.showconfirmpopup = false;
    this.opeartionStageForDelete = "";
  }

  confirmPopup(event) {
    if (this.opeartionStageForDelete == "delete") {
      this.opeartionStageForDelete = "";
      // this.confirmDeleteItemMaster();
    }
    else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
      this.getMasterTable();
      this.resetitemMasterForm.reset();

    } else if (this.opeartionStageForDelete == "NOtdeleted" || this.operation == "editItemMaster") {
      this.opeartionStageForDelete = "";
      this.operation = "";
    }
    if (this.editOperation == "saveItemMaster") {
      this.editOperation = "";
    }
    this.showconfirmpopup = false;
  }


  //Comparer Function to sort by json obj starts here
  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  //Comparer Function to sort by json obj ends here

  editItemMaster(event) {
    if (navigator.onLine) {
      // console.log(event);

      this.errormsg = "";
      this.operation = "";
      this.eventRowIndex = "";
      this.subtableconfig['actioncolmconfig']['displaycolumn'] = true;
      // this.subDynamicTable['tableconfig']['defaultsearch']=true;//change
      this.indexForEdit = -1;
      // this.indexForEdit = event['row']['actualindex'];
      // this.eventRowIndex = event['rowindex'];
      this.showloader = true;
      this.loaderTimeOut();
      this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = true;
      // this.subDynamicTable['tableconfig']['defaultsearch'] = this.showSearchPannelProp;
      // this.subDynamicTable
      // this.allowedToAddEditCtrl = true;
      this.disableItemCode = false;
      this.disableRemark = false;
      this.disableRoutineDc = false;
      this.disableModelName = false;
      this.disableSubAssembly = false;
      this.disableTax = false;
      this.disableAddModelNameBtn = false;
      this.disableAddSubAssBtn = false;
      // this.disableSaveBtn = false;
      // this.disableCancelBtn = false;
      // this.disableNewBtn = true;
      this.visibleNewBtn = false;
      this.visibleSaveBtn = true;
      this.visibleCancelBtn = true;
      this.subDynamicTable['tableconfig']['enablefirstbtn'] = true;
      this.subDynamicTable['tableconfig']['enablesecondbtn'] = true;

      this.srNoForSave = event['row']['SrNo'];
      this.itemCode = event['row']['itemcode'];
      if (event['row']['Remarks'] == "null") {
        this.remarks = "";

      } else {
        this.remarks = event['row']['Remarks'];

      }
      if (event['row']['RoutineDC'] == "Y") {
        this.routineDC = true;

      } else {
        this.routineDC = false;

      }
      this.modelName = event['row']['ModelNo'];
      this.subAssembly = event['row']['subAssemblySrNo'];
      this.itemTax = event['row']['tax'];
      this.itemRate = event['row']['rate'];
      if (typeof (event['row']['Itemspare']) == 'string') {
        // this.subDynamicTable['tabledata'] = JSON.parse(event['row']['Itemspare']);

        let tempArray = [];
        tempArray = JSON.parse(event['row']['Itemspare']);
        tempArray.sort(this.GetSortOrder("PartNo"));
        // this.subDynamicTable['tabledata'] = tempArray;
        this.subDynamicTable['tabledata'] = JSON.parse(JSON.stringify(tempArray));//change here
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        // this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        // this.subtableheaders = this.subDynamicTable["tableheaders"];

      } else if (event['row']['Itemspare'] == null) {
        this.subDynamicTable['tabledata'] = [];
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        // this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        // this.subtableheaders = this.subDynamicTable["tableheaders"];
      }
      else {
        let tempArray = [];
        tempArray = event['row']['Itemspare'];
        tempArray.sort(this.GetSortOrder("PartNo"));
        // this.subDynamicTable['tabledata'] = tempArray;
        this.subDynamicTable['tabledata'] = JSON.parse(JSON.stringify(tempArray));//change here
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        // this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        // this.subtableheaders = this.subDynamicTable["tableheaders"];
      }


      this.displaySubTable = true;
      this.showloader = false;
      this.isEditable = true;
      this.operation = "editItemMaster";
      this.whichStage = "editTable";
      this.oprationForSearchPannel = "editItemMaster";
      this.searchPannelClick = "editItemMaster";

    }
    else {
      alert("Internet Connection is Not Available.");
    }

  }



  //

  addSpare() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.operationStage = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiurl = this.configurationsetting.getBaseUrl() + "Master/GetDynamicFormOnly";

      //debugger;
      let params = { "formname": "SpareMasterForm" };



      // this.operationStage = "addForm";


      this.commonservices.getWithParams(apiurl, params)
        .subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {

            this.sparedynamicformdata = {};
            this.sparedynamicformdata = successResp['Data'][0]['@formjson'];
            this.sparedynamicformdata['controls']['partno']['pattern'] = "[A-Z0-9]*";
            this.sparedynamicformdata['controls']['partno']['patternmsg'] = "Enter valid no.";
            this.sparedynamicformdata['controls']['sparename']['pattern'] = "[a-zA-Z0-9 ]*";
            this.sparedynamicformdata['controls']['sparename']['patternmsg'] = "Enter valid name";
            this.sparedynamicformdata['controls']['application']['required'] = false;
            // console.log();

            this.setBootstrapClassesForSpare();
            this.sparedynamicformdata['controls']['supersednumber']['disabled'] = true;
            this.showSpare.show();
            this.showSpareformPopup = true;
            this.showSpareDynamicForm = true;


          } else {
            this.errormsg = successResp['errorDescription']

          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });


    } else {
      alert("Internet Connection is Not Available.");
    }


  }


  submitSpareMasterForm(event) {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;

      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveSpareMaster";
      event['spareno'] = 0;
      event['supersednumber'] = 0;
      if (event['minimumstock'] == "") {
        event['minimumstock'] = 0
      } else {
        event['minimumstock'] = JSON.parse(event['minimumstock']);

      }
      if (event['oldnumber'] == "") {
        event['oldnumber'] = 0;

      } else {
        event['oldnumber'] = JSON.parse(event['oldnumber']);

      }
      event["spareservice"] = "new";

      let body = {};
      body = {
        "delarry": [],
        "sparemaster": event,
        "itemspare": []
      };
      this.commonservices.postMethod(apiUrl, body).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          // console.log("Success");

          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
          console.log(successResp['errorDescription']);
          // this.getSpareList();
          this.showSpare.hide();
          this.showSpareformPopup = false;

        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {

      alert("Internet Connection is Not Available.");

    }
  }
  // check update
  openSpareListTable() {
    this.duplicationSpareMsg = "";
    this.showSpareTableModal.show();
    this.showSpareTablePopup = true;
    this.isPoPopUpOpen = true;
    this.filterSpareListTableIfExistingSubTable();
    // this.showSpareTable = true;
  }

  filterSpareListTableIfExistingSubTable() {
    // console.log(this.deepCopySpareDynamicTable.tabledata);

    let preparedarr = [];
    preparedarr = this.deepCopySpareDynamicTable.tabledata.filter(row => {
      // console.log(row);

      let recavailable: boolean = true;
      for (let ind = 0; ind < this.subDynamicTable['tabledata'].length; ind++) {
        // console.log(this.subDynamicTable['tabledata'][ind]);

        if (this.subDynamicTable['tabledata'][ind]['PartNo'] == row['PartNo']) {
          recavailable = false;
        }
      }
      return recavailable;
    });
    // console.log(preparedarr);

    this.spareDynamicTable['tabledata'] = []
    this.spareDynamicTable['tabledata'] = preparedarr;
    this.showSpareTable = true;
  }

  getSpareList() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiurl = this.configurationsetting.getBaseUrl() + "Master/GetDynamicTableAndTableData";
      let params = { "tblname": "ItemSubAssemblyTable" };


      this.commonservices.getWithParams(apiurl, params)
        .subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == "000000") {

            this.spareDynamicTable = successResp['Data'][0]['@oritable'];
            this.spareDynamicTable['tabledata'] = successResp['Data'][0]['@tbldata'];
            this.deepCopySpareDynamicTable = this.helperservice.removeRefrence(this.spareDynamicTable);

            // this.spareDynamicTable['tabledata'] = successResp['Data'][0]['@tbldata'];


            /* starts here below codew is for :- if sub table conatins some records it wont be appear in spare list table*/
            // let preparedarr = [];
            // preparedarr = successResp['Data'][0]['@tbldata'].filter(row => {
            //   let recavailable: boolean = true;
            //   for (let ind = 0; ind < this.subDynamicTable['tabledata'].length; ind++) {
            //     if (this.subDynamicTable['tabledata'][ind]['PartNo'] == row['PartNo']) {
            //       recavailable = false;
            //     }
            //   }
            //   return recavailable;
            // });
            // // debugger;
            // this.spareDynamicTable['tabledata'] = JSON.parse(JSON.stringify(preparedarr));
            // this.sparetableconfig = this.spareDynamicTable["tableconfig"];
            // this.sparetabledata = this.spareDynamicTable["tabledata"];
            // this.sparetableheaders = this.spareDynamicTable["tableheaders"];


            /* ends here below codew is for :- if sub table conatins some records it wont be appear in spare list table*/




          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });


    } else {
      alert("Internet Connection is Not Available.");
    }

  }

  getModelNameList() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.modelName = "";
      this.modelNameList = [];
      this.showloader = true;
      this.loaderTimeOut();
      this.commonservices.getDynamicDropDownList("modelddl", this.configurationsetting.getBaseUrl())
        .subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {
            this.modelNameList = successResp['Data'];
            this.modelName = this.tempModelName;
          } else {
            this.errormsg = successResp['errorDescription']

          }


        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
    } else {
      alert("Internet Connection is Not Available.");
    }
  }

  getSubAssemNameList() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.subAssemblyList = [];
      this.showloader = true;
      this.loaderTimeOut();
      this.commonservices.getDynamicDropDownList("subassemblyddl", this.configurationsetting.getBaseUrl())
        .subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {
            this.subAssemblyList = successResp['Data'];
          } else {
            this.errormsg = successResp['errorDescription']

          }


        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
    } else {
      alert("Internet Connection is Not Available.");
    }
  }

  getTaxList() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.taxList = [];
      this.showloader = true;
      this.loaderTimeOut();
      this.commonservices.getDynamicDropDownList("TaxDetails", this.configurationsetting.getBaseUrl())
        .subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {
            this.taxList = successResp['Data'];
          } else {
            this.errormsg = successResp['errorDescription']

          }


        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
    } else {
      alert("Internet Connection is Not Available.");
    }
  }


  getRate(event) {
    let rate = this.taxList.filter(row => {
      if (row['value'] == event.target.value) {
        return row;
      }
    });
    this.itemRate = rate[0]['rate'];
  }

  updateTableData(event) {
    this.tabledataforprocess = []
    // event = this.helperservice.removeRefrence(event);
    this.tabledataforprocess = event;
    console.log("update tabledata", this.tabledataforprocess);
  }

  saveItemMaster(event) {
    // console.log("Save Items");
    // debugger
    if (this.itemCode == null || this.subAssembly == null || this.modelName == null) {
      this.toastService.showToastMsg('warning', 'Warning', "Please fill required fields.");
      return;
    }

    if (navigator.onLine) {
      this.errormsg = "";

      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        this.tabledataforprocess[i]['Description'] = this.commonFunction.manipulateString(this.tabledataforprocess[i]['Description'])

        if (this.tabledataforprocess[i]['Qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
      }
      this.deleteBtn = false;
      this.showloader = true;
      this.loaderTimeOut();
      //debugger;
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveItemMaster";
      let obj = {};
      obj['itemcode'] = event.form.value['itemcode'];
      obj['remark'] = event.form.value['remark'];
      obj['modelname'] = JSON.parse(event.form.value['modelname']);
      obj['subassembly'] = JSON.parse(event.form.value['subassembly']);
      obj['tax'] = parseInt(event.form.value['tax']) ? parseInt(event.form.value['tax']) : 0;
      obj['rate'] = this.itemRate ? this.itemRate : 0;

      if (event.form.value['routinedc'] == true) {
        obj['routinedc'] = 'Y';

      } else if (event.form.value['routinedc'] == null || event.form.value['routinedc'] == false) {
        obj['routinedc'] = 'N';
      }
      if (this.isEditable) {
        obj['SrNo'] = this.srNoForSave;

      } else {
        //send SrNo=0 when it is a new entry i.e while saving
        obj['SrNo'] = 0;
      }

      if (!this.leftsidetbl) {
        this.isEditable = true;
        obj['SrNo'] = this.leftsiderowitem['row']['itemnumber'];
      }

      this.tempArrayToSend = [];
      this.tempArrayToSend = this.tabledataforprocess;
      // this.tempArrayToSend = JSON.parse(JSON.stringify(this.subDynamicTable['tabledata']));//deep copy
      // console.log("tempArrayToSend", this.tempArrayToSend);
      // console.log("tabledataforprocess", this.tabledataforprocess);




      // console.log("Tabledata while saving : " + JSON.stringify(this.tabledataforprocess));

      for (let ind = 0; ind < this.tempArrayToSend.length; ind++) {

        this.tempArrayToSend[ind]['Qty'] = +(this.tempArrayToSend[ind]['Qty']);
        if (this.isEditable) {
          // tempArray[ind]['SrNo'] = 0;
          if (!this.tempArrayToSend[ind]['SrNo'] || this.tempArrayToSend[ind]['SrNo'] == 0) {
            this.tempArrayToSend[ind]['SrNo'] = 0
          }
          this.tempArrayToSend[ind]['ItemNo'] = this.srNoForSave;

        } else {
          //send SrNo=0 when it is a new entry i.e while saving
          this.tempArrayToSend[ind]['SrNo'] = 0;
          this.tempArrayToSend[ind]['ItemNo'] = 0;
        }
      }
      if (!this.leftsidetbl) {
        this.tabledataforprocess.forEach((item)=>{
          item['ItemNo'] = this.ItemSrNo;
        })
        // = this.ItemSrNo;
        // console.log("Spare Master", srNo);
      }

      /* starts here it will craete deletearray which conains array of SrNo if any record contains qty =0 it should get delete*/
      let deleteArray = [];
      for (let k = 0; k < this.tempArrayToSend.length; k++) {
        let objDelete = {};
        if (this.tempArrayToSend[k]['Qty'] == 0) {
          if (this.tempArrayToSend[k]['SrNo'] > 0) {
            objDelete['SrNo'] = this.tempArrayToSend[k]['SrNo'];
            deleteArray.push(objDelete);
          }
        }
      }
      /* ends here it will craete deletearray which conains array of SrNo if any record contains qty =0 it should get delete*/

      /* starts here filter array here if record contains Qty="0", so below code will remove it */
      this.tempArrayToSend = this.tempArrayToSend.filter(row => {
        return row.Qty > "0";
      });
      /* ends here filter array here if record contains Qty="0", so below code will remove it */

      let body: any = {};
      body = {
        "itemmaster": obj,
        "itemspare": this.tempArrayToSend,
        "deletearray": this.deletedArray
      };

      this.commonservices.postMethod(apiUrl, body).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.itemUniqueNo = successResp['Data'][0]['ItemData'][0]['SrNo'];

          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
          this.getMasterTable();//change it


          this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;



          /**code for not to hit service starts here**/
          // console.log(this.event);

          if (!this.isEditable && successResp['Data'][0]['ItemData']['SrNo']) {
            // console.log("isedit");
            this.isEditable = true;
            this.srNoForSave = successResp['Data'][0]['ItemData']['SrNo'];
          }


          this.CancelBtn = false;

          // this.visibleNewBtn = true;
          // this.visibleSaveBtn = false;
          // this.visibleCancelBtn = true;

          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
          // this.closeModel();
          // if (this.showonlyForm) {
          //   this.closeModel({ status: "save" });
          //   return 0;
          // }
          this.editBtn = true;
          if (!this.leftsidetbl) {
            this.closeModel({ status: "save" });
          }
        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {

      alert("Internet Connection is Not Available.");

    }

  }



  checkUniqueItemCode(event) {
    if (navigator.onLine) {
      if (event['currentTarget']['id'] == "ItemCode") {
        this.errormsg = "";
        this.showloader = true;
        this.loaderTimeOut();
        let apiurl = this.configurationsetting.getBaseUrl() + "Master/CheckUniqueItemNo";
        let params = { "ItemNumber": event.currentTarget.value };


        this.commonservices.getWithParams(apiurl, params)
          .subscribe(successResp => {
            this.showloader = false;
            if (successResp['errorCode'] == 0) {
              // this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
              this.disableSaveBtn = false;
            } else if (successResp['errorCode'] != 0) {
              this.disableSaveBtn = true;
              this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);

            }
          }, errorResp => {
            this.showloader = false;
            this.errormsg = errorResp['message']
          });
      }

    } else {
      this.showloader = false;
      alert("Internet Connection is Not Available.");
    }
  }




  closePopUpEvent(whichPopup) {
    switch (whichPopup) {
      case 'closeModelPopup':
        this.showFormsPopup = false;
        this.uniqueMsg = "";
        this.showModelDynamicForm = false;
        this.operationStage = "";
        break;
      case 'closeModelDynamicForm':
        this.showFormsPopup = false;
        this.uniqueMsg = "";
        this.showModelDynamicForm = false;
        this.operationStage = "";
        break;
      case 'closeSubAssembDynamicForm':
        this.showFormsPopup = false;
        this.uniqueMsg = "";
        this.showModelDynamicForm = false;
        this.operationStage = "";
        break;
      case 'closeSpareTablePopup':
        this.showSpareTableModal.hide();
        this.showSpareTablePopup = false;
        this.showSpareTable = false;
        this.duplicationSpareMsg = "";
        break;
      case 'closeSpareTableForm':
        this.showSpareTableModal.hide();
        this.showSpareTablePopup = false;
        this.showSpareTable = false;
        this.duplicationSpareMsg = "";

        break;
      case 'closeSparePopup':
        this.showSpare.hide();
        this.showSpareformPopup = false;
        this.showSpareDynamicForm = false;
        break;
      case 'closeSpareDynamicForm':
        this.showSpare.hide();
        this.showSpareformPopup = false;
        this.showSpareDynamicForm = false;
        break;
      case 'deletepopup':
        this.showDeleteModal.hide()
        this.showSpareformPopup = false;
        this.showSpareDynamicForm = false;
      case 'addPopupClose':
        this.showSpareModal.hide();
        this.showModelDynamicForm = false;
        this.showSubAssemblyDynamicForm = false;
    }
  }

  cancelItemMasterForm() {

    if (confirm("Do you want to cancel!")) {
      if (this.operation === "NEW") {
        this.resetitemMasterForm.reset();
        this.subDynamicTable['tabledata'] = [];
        this.editBtn = false;
        this.btnRow = false;
      } else {
        this.editBtn = true;
        this.btnRow = true;
      }
      this.deleteMsg = "";
      this.addSpareButton = true;
      this.spareListButton = true;
      this.visibleNewBtn = true;
      this.visibleSaveBtn = false;
      this.visibleCancelBtn = false;
      this.savebtn = false;
      this.editDisableBtn = false;
      this.disableNewBtn = false;
      this.CancelBtn = false;
      this.deleteBtn = false;
      this.disableInputs();
      this.subtableheaders = this.commonservices.enableEditForGt(this.helperservice.removeRefrence(this.subtableheaders), "Qty", false);
      this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;
    }

  }
  // confirmPopup(event) {
  //   this.showconfirmpopup = false;
  // }

  //////if http not gave the response after spend 30 seconds the we will show the error.
  loaderTimeOut() {
    setTimeout(() => {
      if (this.showloader) {
        this.showloader = false;
        this.errormsg = "Error while login your account.";
      }
    }, 50000)
  }

  //displayMessagePopup() is used to call and maintain confirmation popup modal
  displayMessagePopup(popupmessage, submittext, displaysubmit, canceltext, displaycancel) {
    this.confirmationpopupmodelobj.inputmsg = popupmessage;
    this.confirmationpopupmodelobj.submitbtntext = submittext
    this.confirmationpopupmodelobj.displaysubmit = displaysubmit;
    this.confirmationpopupmodelobj.cancelbtntext = canceltext;
    this.confirmationpopupmodelobj.displaycancel = displaycancel;
    this.showconfirmpopup = true;
  }

  setBootstrapClassesForModel() {
    let colName = 0;
    for (let i = 0; i < this.modeldynamicformdata['css']["mappingarray"].length; i++) {
      for (let j = 0; j < this.modeldynamicformdata['css']["mappingarray"][i].length; j++) {
        if (this.modeldynamicformdata['css']["mappingarray"][i][j]["colspan"] != undefined)
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]) * +this.dynamicformdata["mappingarray"][i][j]["colspan"];
          colName = +this.modeldynamicformdata['css']["gridinfo"]["gridcols"] * +this.modeldynamicformdata['css']["mappingarray"][i][j]["colspan"];
        else
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]);
          colName = +this.modeldynamicformdata['css']["gridinfo"]["gridcols"];
        this.modeldynamicformdata['css']["mappingarray"][i][j]["ColClassName"] = "col-xs-12 col-sm-12 col-md-" + colName + " col-lg-" + colName + " col-xl-" + colName;
      }
    }
  }
  setBootstrapClassesForAssemb() {
    let colName = 0;
    for (let i = 0; i < this.subassembdynamicformdata['css']["mappingarray"].length; i++) {
      for (let j = 0; j < this.subassembdynamicformdata['css']["mappingarray"][i].length; j++) {
        if (this.subassembdynamicformdata['css']["mappingarray"][i][j]["colspan"] != undefined)
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]) * +this.dynamicformdata["mappingarray"][i][j]["colspan"];
          colName = +this.subassembdynamicformdata['css']["gridinfo"]["gridcols"] * +this.subassembdynamicformdata['css']["mappingarray"][i][j]["colspan"];
        else
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]);
          colName = +this.subassembdynamicformdata['css']["gridinfo"]["gridcols"];
        this.subassembdynamicformdata['css']["mappingarray"][i][j]["ColClassName"] = "col-xs-12 col-sm-12 col-md-" + colName + " col-lg-" + colName + " col-xl-" + colName;
      }
    }
  }

  setBootstrapClassesForSpare() {
    let colName = 0;
    for (let i = 0; i < this.sparedynamicformdata['css']["mappingarray"].length; i++) {
      for (let j = 0; j < this.sparedynamicformdata['css']["mappingarray"][i].length; j++) {
        if (this.sparedynamicformdata['css']["mappingarray"][i][j]["colspan"] != undefined)
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]) * +this.dynamicformdata["mappingarray"][i][j]["colspan"];
          colName = +this.sparedynamicformdata['css']["gridinfo"]["gridcols"] * +this.sparedynamicformdata['css']["mappingarray"][i][j]["colspan"];
        else
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]);
          colName = +this.sparedynamicformdata['css']["gridinfo"]["gridcols"];
        this.sparedynamicformdata['css']["mappingarray"][i][j]["ColClassName"] = "col-xs-12 col-sm-12 col-md-" + colName + " col-lg-" + colName + " col-xl-" + colName;
      }
    }
  }


  showAddForm(whichForm) {
    if (navigator.onLine) {
      this.errormsg = "";
      this.operationStage = "";
      this.uniqueMsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiurl = this.configurationsetting.getBaseUrl() + "Master/GetDynamicFormOnly";

      let params;
      /*below takes body as per whichForm starts here*/
      switch (whichForm) {
        case 'addModelForm':
          params = { "formname": "ModelMasterForm" };

          this.operationStage = "addModelForm";
          break;
        case 'addSubassemblyForm':
          params = { "formname": "SubAssemblyMasterForm" };

          this.operationStage = "addSubassemblyForm";
          break;
      }
      /*below takes body as per whichForm ends here*/
      this.commonservices.getWithParams(apiurl, params)
        .subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == "000000") {
            /*below takes formdata as per whichForm starts here*/
            switch (whichForm) {
              case 'addModelForm':
                this.modeldynamicformdata = {};
                this.modeldynamicformdata = successResp['Data'][0]['@formjson'];
                this.modeldynamicformdata['controls']['modelname']['pattern'] = "[a-zA-Z0-9/ ]*";
                this.modeldynamicformdata['controls']['modelname']['patternmsg'] = "Enter valid name";

                this.setBootstrapClassesForModel();
                this.showFormsPopup = true;
                this.showSpareModal.show()
                this.showSubAssemblyDynamicForm = false;

                this.showModelDynamicForm = true;

                break;
              case 'addSubassemblyForm':
                // console.log("addSubassemblyForm");

                this.subassembdynamicformdata = {};
                this.subassembdynamicformdata = successResp['Data'][0]['@formjson'];
                this.subassembdynamicformdata['controls']['subassemblyno']['label'] = "Sub-Assembly No.";
                this.subassembdynamicformdata['controls']['subassemblyno']['pattern'] = "[A-Z0-9]*";
                this.subassembdynamicformdata['controls']['subassemblyno']['patternmsg'] = "Enter valid no.";
                this.subassembdynamicformdata['controls']['dateeffectivefrom']['dateformat'] = 'DD/MM/YYYY';
                this.setBootstrapClassesForAssemb();
                this.showFormsPopup = true;
                this.showSpareModal.show()
                this.showModelDynamicForm = false;

                this.showSubAssemblyDynamicForm = true;
                // console.log("addSubassemblyForm2");

                break;
            }
            /*below takes formdata as per whichForm ends here*/
          } else {
            this.errormsg = successResp['errorDescription']

          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });


    } else {
      alert("Internet Connection is Not Available.");
    }

  }
  closePopUp() {
    this.showDeleteModal.hide();
  }
  /*
   submitModelMaster()
     In success error code is '0'
     1.send "modelno"=0 if You want to save new assembly
   */
  submitModelMaster(event) {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;

      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveModelMaster";
      let obj = {};
      obj['modelname'] = event['modelname'];
      obj['remark'] = event['remark'];
      //send modelno=0 when it is a new entry i.e while saving
      obj['modelno'] = 0;

      this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == 0) {
          this.showModelDynamicForm = false;
          this.showFormsPopup = false;
          this.operationStage = "";
          this.getModelNameList();
          // this.modelName = successResp['Data'][0]['modelname'];
          this.tempModelName = successResp['Data'][0]['modelno'];
          this.showSpareModal.hide();
          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      alert("Internet Connection is Not Available.");

    }
  }



  //below method is to save sub-assembly master starts here
  /*
  In success error code is '0'
  1.send "srno"=0 if You want to save new assembly
  2.send "srno" with coming value if you want to edit it
  */

  submitAssemblyMaster(event) {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveSubAssemblyMaster";
      let obj = {};
      obj['subassemblyno'] = event['subassemblyno'];
      obj['description'] = event['description'];
      obj['dateeffectivefrom'] = this.datepipe.transform(new Date(event['dateeffectivefrom']), "yyyy-MM-dd,hh:mm:ss");
      obj['billrejecteditems'] = event['billrejecteditems'][0];
      obj['rate'] = event['rate'];

      //send srno=0 when it is a new entry i.e while saving
      obj['srno'] = 0;

      this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == 0) {
          this.showSubAssemblyDynamicForm = false;
          this.showFormsPopup = false;
          this.operationStage = "";
          this.getSubAssemNameList();
          this.subAssembly = successResp['Data'][0]['SrNo'];
          this.showSpareModal.hide();
          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {

      alert("Internet Connection is Not Available.");

    }
  }
  //above method is to save sub-assembly master ends here

  /*checkUniquePartNo(event) this method will take value which is entered in event
   and this method checks whether entered part no or sub assembly no is duplicate or not */
  checkUniquePartNo(event) {
    if (navigator.onLine) {
      if (event['controlname'] == 'subassemblyno') {
        this.errormsg = "";
        this.uniqueMsg = "";
        this.showloader = true;
        this.loaderTimeOut();
        let apiUrl = this.configurationsetting.getBaseUrl() + "Master/CheckUniquePartNumber";
        let params = { "PartNo": event['value']['currentTarget']['value'] };
        this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {
            this.uniqueMsg = successResp['Data'];
            this.subassembdynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
            this.subassembdynamicformdata['css']['btnconfiguration']['disableSave'] = true;
            // this.displayMessagePopup(successResp['Data'], "Ok", true, "", false);
          } else if (successResp['errorCode'] == 1) {
            this.subassembdynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
            this.subassembdynamicformdata['css']['btnconfiguration']['disableSave'] = false;
            // this.errormsg = successResp['errorDescription'];
          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });

      }
    }
    else {

      alert("Internet Connection is Not Available.");

    }


  }
  /*checkUniquePartNo() ends here*/


  /*checkUniqueModelName(event) this method will take value which is entered in event
and this method checks whether entered model name  is duplicate or not */
  checkUniqueModelName(event) {
    if (navigator.onLine) {
      if (event['controlname'] == 'modelname') {
        this.errormsg = "";
        this.uniqueMsg = "";;
        this.showloader = true;
        this.loaderTimeOut();
        let apiUrl = this.configurationsetting.getBaseUrl() + "Master/CheckUniqueModelName";
        let params = { "ModelName": event['value']['currentTarget']['value'] };
        this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {
            this.uniqueMsg = successResp['Data'];

            this.modeldynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
            this.modeldynamicformdata['css']['btnconfiguration']['disableSave'] = true;
            // this.displayMessagePopup(successResp['Data'], "Ok", true, "", false);
          } else if (successResp['errorCode'] == 1) {

            this.modeldynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
            this.modeldynamicformdata['css']['btnconfiguration']['disableSave'] = false;
            // this.errormsg = successResp['errorDescription'];
          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
      }
    }
    else {

      alert("Internet Connection is Not Available.");

    }


  }
  /*checkUniqueModelName() ends here*/

  pushSpareList(selectedtabledata) {
    // console.log(selectedtabledata);
    // let deppCopySubDynamicTable = this.helperservice.removeRefrence(this.subDynamicTable['tabledata']);
    if(this.isInsertTableData){
      this.tabledataforprocess = [];
    }
    let deppCopySubDynamicTable = this.helperservice.removeRefrence(this.tabledataforprocess);
    selectedtabledata = this.helperservice.removeRefrence(selectedtabledata);
    this.subDynamicTable['tabledata'] = [];
    // console.log("deep copy", deppCopySubDynamicTable);



    selectedtabledata.forEach((item) => {
      const { SpareNo, SrNo, PartNo, Description, CurrentBalance, WIP } = item;
      deppCopySubDynamicTable.push({ SpareNo, SrNo, PartNo, Description, CurrentBalance, WIP, Qty: 0 });
    })

    // deppCopySubDynamicTable = [...deppCopySubDynamicTable, ...selectedtabledata];
    this.subDynamicTable['tabledata'] = deppCopySubDynamicTable;

    this.showSpareTableModal.hide();

    this.displaySubTable = true;
  }



  closeModel(data = null) {
    // this.displayMessagePopup("Updated data Successfully", "Ok", true, "", false);
    this.ngbActiveModal.close(data);
  }



}
