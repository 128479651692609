import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {

  }
  canActivate(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (currentUser && currentUser.Data.ntk && currentUser.errorCode == "000000") {
        // logged in so return true
        // console.log('auth', true);
        return true;
      }
    }
    // not logged in so redirect to login page with the return url
    // console.log('auth', false);
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
