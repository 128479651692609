import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: `<router-outlet><app-toastr></app-toastr></router-outlet>
    <div class="loading-buttons" style="display: none; cursor: not-allowed !important; pointer-events: all !important;" id="loader-div">
       <span class="loading-icon">
         <img src="assets/img/loader/spinner.gif" />
      </span>
    </div>
    `
})
export class AppComponent implements OnInit {
  constructor(private router: Router) { }
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
