import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../appconfig';
import { HttpHeaders } from '@angular/common/http';
import { ConfirmationPopupModel } from '../../models/models';
import { CommonServicesService } from '../../services/common-services.service';
import { NgForm } from '@angular/forms';
import { DataSharingService } from '../gt-plus/data-sharing.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HelperService } from '../../services/helper.service';
import { ToastService } from '../../services/toast.service';
declare var $;
@Component({
  selector: 'app-tax-master',
  templateUrl: './tax-master.component.html',
  styleUrls: ['./tax-master.component.scss']
})
export class TaxMasterComponent implements OnInit {
  @ViewChild('taxMasterForm') public resetTaxMasterForm: NgForm;
  @ViewChild('subtaxMasterForm') public resetSubTaxMasterForm: NgForm;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;

  eve = {};
  dynamicTable: any = {};
  subDynamicTable: any = {}
  showloader: boolean = false;
  showconfirmpopup: boolean = false;
  displayTable: boolean = false;
  displaySubTable: boolean = false;
  errormsg: string = "";
  headers = new HttpHeaders();
  confirmationpopupmodelobj: ConfirmationPopupModel = new ConfirmationPopupModel();
  hasSubTaxes: boolean = false;
  showsubtaxpopup: boolean = false;
  subTaxArray: any[] = [];
  subTax: string = "";
  subTaxId: number = 0;
  subTaxRate: string;
  taxRate: string;
  taxDescription: string = "";
  isEditable: boolean = false;
  isEditableSubTax: boolean = false;
  taxIdForSave;
  operation: string = "";
  rowsperpage = 10;
  taxUniqueNo: number = 0;
  currentSelectedRow: number = 0;
  disableTaxDescription: boolean = false;
  disableTaxRate: boolean = false;
  disablehasSubTaxes: boolean = false;
  disableNewBtn: boolean = false;
  disableCancelBtn: boolean = false;
  disableSaveBtn: boolean = false;
  saveBtnVisible: boolean = false;
  userRole: string = "";
  deleteById;
  opeartionStageForDelete: string = "";
  deleteSubTbleById;
  opeartionStageForSubDelete: string = "";
  doubleClickStage: string = "";
  emptySearchField: boolean = true;
  highlightedObj = {
    "id": "",
    "indx": -1
  }
  deleteMsg = "";
  btnRow: boolean = false;
  oprationForRowClick: string = "";
  operationStage: string = "";
  toatalRate: number = 0.00;
  disableAddBtn: boolean = false;
  validationMsg: string = "";
  operationForEditToCheck: string = "";
  indexForEdit: number;
  eventRowIndex;
  editOperation: string = "";
  subtaxRateForEdit: number = 0.00;
  isEditableSubTaxCheck: boolean = false;
  visibleSaveBtn: boolean = false;
  visibleNewBtn: boolean = true;
  visibleCancelBtn: boolean = false;
  disableDt: boolean = false;
  effectiveDate: any;
  editDisableBtn: boolean = false;
  hasSubTax: boolean = false;
  delteBtn: boolean = false
  isRowClicked:boolean = false;
  tabledataforprocess: any[] = [];

  tableheaders = [];
  tabledata = [];
  tableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": true,
      "delete": true,
      "print": false
    },
    "partno": { "text-align": "left" },
    "Qty": { "text-align": "Right" },
    "CurrentBalance": { "text-align": "Right" },
    "WIP": { "text-align": "Right" },
    "controlconfig": { "displaycontrol": false },
    // "checkDeletedFlag": true,
    // "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  };
  subtableheaders = [];
  subtabledata = [];
  subtableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "enablefirstbtn": false,
    "enablesecondbtn": false,
    "firstbtntext": "Add Tax",
    "secondbtntext": "Spare List",
    "firstBtnStyle": {},
    "secondBtnStyle": {},
    "firstBtnClass": "btn btn-primary btn-sm",
    "secondBtnClass": "",
    "rate": { "text-align": "right" },
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": true,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showonlysearch": true,
    "fullheighttable": ''
  };
  editBtn: boolean = false;
  @ViewChild('showSubTaxModal', { static: false }) public showSubTaxModal: ModalDirective;
  constructor(private dataSharingSrvc: DataSharingService, public commonservices: CommonServicesService, private configurationsetting: ConfigurationSetting, private helper: HelperService, private toastService: ToastService) { }

  ngOnInit() {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    this.getMasterTable();
    /*
    below settimeout is used to disable form for the forst time when user enters
    */
    this.effectiveDate = new Date();
  }

  model = {
    taxDesc: {
      value: '',
      type: 'String',
      strLength: 12,
      err: '',
      lable: 'Tax Description'
    },
  }


  showtaxMasterForm() {
    this.operation = 'NEW';
    this.emptySearchField = false;
    this.taxIdForSave = 0;
    this.taxUniqueNo = 0;
    // this.hasSubTax = true;
    this.editBtn = false;
    this.subDynamicTable['tabledata'] = [];
    this.subtabledata = [];
    if (this.doubleClickStage == "rowDoubleClickTaxMaster") {
      // this.resetSpareMasterForm.reset();
      this.subDynamicTable['tabledata'] = [];
      this.subtableconfig['actioncolmconfig']['displaycolumn'] = true;
      // this.subtableconfig =  this.subDynamicTable["tableconfig"];
      this.subtabledata = this.subDynamicTable["tabledata"];
      this.subtableheaders = this.subDynamicTable["tableheaders"];
      this.oprationForRowClick = "";
      this.isEditable = false;
      // this.hasSubTax = true;
      this.subtableconfig['actioncolmconfig']['displaycolumn'] = true;
      this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
      this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = true;
      this.displaySubTable = false;
      const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
      if (ele) ele.style.backgroundColor = '';

    }
    this.deleteMsg = "";
    this.btnRow = true;
    this.resetTaxMasterForm.reset();
    this.disableDt = false;
    this.disableTaxDescription = false;
    this.disableTaxRate = false;
    this.disablehasSubTaxes = false;
    this.disableCancelBtn = false;
    this.disableSaveBtn = false;
    this.saveBtnVisible = true;
    this.disableNewBtn = true;
    this.visibleNewBtn = true;
    this.visibleSaveBtn = true;
    this.visibleCancelBtn = true;

    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);

  }

  disableForm() {
    if (this.oprationForRowClick == "clickedOnRow") {
      // this.resetSpareMasterForm.reset();
      this.subDynamicTable['tabledata'] = [];
      this.oprationForRowClick = "";
      this.isEditable = false;
      const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
      if (ele) ele.style.backgroundColor = '';

    }
    this.disableDt = true;
    this.disableTaxDescription = true;
    this.disableTaxRate = true;
    this.disablehasSubTaxes = true;
    this.disableCancelBtn = true;
    this.disableSaveBtn = true;
    this.saveBtnVisible = false;
    this.visibleNewBtn = true;
    this.visibleSaveBtn = false;
    this.visibleCancelBtn = false;
  }

  /*
  getMasterTable() is used to get table.
  1.below is the dynamic services have to send tableName and url in a params
  */
  getMasterTable() {
    this.errormsg = "";
    this.showloader = true;
    this.commonservices.getGenericTable("TaxMaster", this.configurationsetting.getBaseUrl())
      .subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.dynamicTable = successResp['Data'][0]['@oritable'];
          this.dynamicTable['tabledata'] = successResp['Data'][0]['@tbldata'];
          this.subDynamicTable = successResp['Data'][0]['@subtable'];
          let rowData = successResp['Data'][0]['@tbldata']
          for (let i = 0; i < rowData.length; i++) {
            if (rowData[i]['TaxId'] == this.taxUniqueNo) {
              // console.log("Index", this.tabledata[i], i);
              this.rowDoubleClickTaxMaster({
                "row": rowData[i],
                "rowindex": i
              })
              setTimeout(() => {
                $(`tr[tabindex=${i}]`).focus();
                // document.getElementById('trtable1' + i).style.backgroundColor = "#7de4f0";
              }, 900);
              break;
            }
          }
          /*********changes starts here********/
          if (this.userRole == "Admin") {
            this.dynamicTable['tableconfig']['actioncolmconfig']['delete'] = true;
          } else {
            this.dynamicTable['tableconfig']['actioncolmconfig']['delete'] = false;
          }
          // this.tableconfig =  this.dynamicTable["tableconfig"];
          this.tabledata = this.dynamicTable["tabledata"];
          this.tableheaders = this.dynamicTable["tableheaders"];
          /********chnages ends here*********/
          this.displayTable = true;

          // this.displaySubTable = true;
          this.disableForm();

        }
        else {
          this.errormsg = successResp["errorDescription"];
        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });
  }


  deleteTaxMaster(event) {
    if (navigator.onLine) {
      if (this.operation == "editTaxMasterForm" || this.doubleClickStage == "rowDoubleClickTaxMaster") {
        const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.operation = "";
        this.doubleClickStage = "";
      }

      this.deleteById = event['row']['TaxId'];
      this.opeartionStageForDelete = "delete";
      this.displayMessagePopup("Are you sure want to delete this record?", "Yes", true, "No", true);
    }
    else {
      this.displayMessagePopup("Internet Connection is Not Available.", "Ok", true, false, "");
    }
  }

  deleteTaxMasterData(taxid: number) {
    let apiUrl = this.configurationsetting.getBaseUrl() + "Master/CheckDeleteTax";
    let obj = {};
    obj['TaxId'] = taxid;
    // obj['recordvalue'] = this.deleteById;

    this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
      this.showloader = false;
      if (successResp['errorCode'] == "000000") {
        this.showconfirmpopup = false;
        this.opeartionStageForDelete = "deleted";
        this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
        this.getMasterTable();
        this.cancleTableForm();
        this.btnRow = false;
        this.subDynamicTable['tabledata'] = [];
        this.resetTaxMasterForm.reset();
      } else {
        this.btnRow = true;
        this.delteBtn = false;
        this.visibleCancelBtn = false;
        this.disableForm();
        this.editDisableBtn = false;
        this.disableNewBtn = false;
        this.hasSubTax = false;
        this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;

        this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
      }
      this.showDeleteModal.hide();
    }, errorResp => {
      this.showloader = false;
      this.errormsg = errorResp['message']
    });
  }

  showDeleteConfirmationYes() {
    console.log(this.taxIdForSave);

    this.showDeleteModal.show();
  }

  confirmDeleteTaxMaster() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/CheckDeleteTax";
      let obj = {};
      obj['TaxId'] = this.deleteById;
      // obj['recordvalue'] = this.deleteById;

      this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] != '000000') {
          this.showconfirmpopup = false;
          console.log(1);
          this.opeartionStageForDelete = "deleted";
          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
        } else if (successResp['errorCode'] == "000000") {
          this.showconfirmpopup = false;
          this.opeartionStageForDelete = "deleted";
          console.log(2);
          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
        } else {
          console.log(3);

          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
          this.errormsg = successResp['errorDescription'];

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      this.showloader = false;

      alert("Internet Connection is Not Available.");

    }
  }

  cancelConfirmationPopup() {
    this.showconfirmpopup = false;
    this.opeartionStageForDelete = "";
    this.opeartionStageForSubDelete = "";
  }

  confirmPopup(event) {
    if (this.opeartionStageForDelete == "delete") {
      this.opeartionStageForDelete = "";
      this.confirmDeleteTaxMaster();
    }
    else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
      this.resetTaxMasterForm.reset();
      // this.getMasterTable();
      //  setTimeout(() => {
      //   this.disableForm();

      // }, 500)

    } else if (this.opeartionStageForSubDelete == "deleteSubTax") {
      this.opeartionStageForSubDelete = "";
      this.confirmDeleteSubTaxMaster();
    }

    if (this.editOperation == "saveTaxMaster") {

      this.editOperation = "";
    }
    this.showconfirmpopup = false;
  }

  // checkSubTaxes() below method show popup when user checks the checkbox
  checkSubTaxes(issubtaxes) {
    console.log("issubtaxes", issubtaxes);

    if (issubtaxes) {
      this.hasSubTax = true;
      this.displaySubTable = true;
      this.subDynamicTable['tableconfig']['enablefirstbtn'] = true;
      this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
      // this.subtableconfig =  this.subDynamicTable["tableconfig"];
      this.subtabledata = this.subDynamicTable["tabledata"];
      this.subtableheaders = this.subDynamicTable["tableheaders"];
      // this.showsubtaxpopup = true;

    } else {
      // this.subDynamicTable['tabledata'] = [];
      // this.subtableconfig =  this.subDynamicTable["tableconfig"];
      // this.subtabledata = this.subDynamicTable["tabledata"];
      // this.subtableheaders = this.subDynamicTable["tableheaders"];
      this.displaySubTable = false;
      this.hasSubTax = false;


    }

  }

  checkUniqueTax(){
    let params = { taxname: this.taxDescription };
    let endpoint = this.configurationsetting.getBaseUrl() + "Master/uniqueTaxName";
    this.commonservices.getWithParams(endpoint, params).subscribe((successResp) => {
      if (successResp['errorCode'] == "000000") {
        this.disableTaxRate = false;
        this.disableDt = false;
        this.disablehasSubTaxes = false;
        this.disableSaveBtn = false;
      }else{
        this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
        this.disableTaxRate = true;
        this.disableDt = true;
        this.disablehasSubTaxes = true;
        this.disableSaveBtn = true;
      }
    }, (error) => {
      // console.log(error);
    });
  }

  /*
  1.saveTaxMaster(taxformdata) method saves and update the tax master
  2.savetaxMasterobj:- this obj contains tax, rate, checkbox status, and subtable
  */
  saveTaxMaster(taxformdata) {
    console.log(taxformdata.form.value);

    if (navigator.onLine) {
      let totalSubRate = 0.00
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        totalSubRate = totalSubRate + parseFloat(this.tabledataforprocess[i]['rate']);
      }

      console.log(this.hasSubTaxes);

      if (this.hasSubTaxes == true) {
        if (totalSubRate == taxformdata.form.value['taxrate']) {
          this.errormsg = "";
          this.showloader = true;
          this.loaderTimeOut();
          let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveTaxMaster";
          let savetaxMasterobj = {}
          savetaxMasterobj["taxdescription"] = taxformdata.form.value['taxdescription'];
          savetaxMasterobj["taxrate"] = taxformdata.form.value['taxrate'];
          savetaxMasterobj["effectivedate"] = taxformdata.form.value['effectivedate'];
          savetaxMasterobj["hassubtaxes"] = taxformdata.form.value['hassubtaxes'];
          /***below date is need to be save***/
          // savetaxMasterobj["date"] = taxformdata.form.value['effectivedate'];
          savetaxMasterobj['subtaxes'] = this.subDynamicTable['tabledata'];
          if (this.isEditable) {
            savetaxMasterobj['taxid'] = this.taxIdForSave;
            // this.isEditable = false;

            //false edit key here after testing
          } else {
            //send taxid=0 when it is a new entry i.e while saving
            savetaxMasterobj['taxid'] = 0;
          }
          this.commonservices.postMethod(apiUrl, savetaxMasterobj).subscribe(successResp => {
            this.showloader = false;
            if (successResp['errorCode'] == "000000") {
              this.taxUniqueNo = successResp['Data'][0]['TaxId']
              this.getMasterTable();//change it
              if (this.eve) {
                this.rowDoubleClickTaxMaster(this.eve, false);
              }
              /**code for not to hit service starts here but having some issue so thats why commented**/
              this.btnRow = false;
              this.hasSubTax = false;
              this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;

              this.disableNewBtn = false;
              this.visibleNewBtn = true;
              this.visibleSaveBtn = false;
              this.visibleCancelBtn = true;
              this.disableCancelBtn = false;
              this.delteBtn = false;
              this.disableForm();
              this.editBtn = true;
              this.btnRow = true;

              this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
            } else {

              this.errormsg = successResp['errorDescription']

            }
          }, errorResp => {
            this.showloader = false;
            this.errormsg = errorResp['message']
          });
        }
        else {
          this.displayMessagePopup("Subtax is not matching with basic rate", "Ok", true, "", false);
        }
      } else {
        this.errormsg = "";
        this.showloader = true;
        this.loaderTimeOut();
        let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveTaxMaster";
        let savetaxMasterobj = {}
        savetaxMasterobj["taxdescription"] = taxformdata.form.value['taxdescription'];
        savetaxMasterobj["taxrate"] = taxformdata.form.value['taxrate'];
        savetaxMasterobj["effectivedate"] = taxformdata.form.value['effectivedate'];
        savetaxMasterobj["hassubtaxes"] = taxformdata.form.value['hassubtaxes'];
        /***below date is need to be save***/
        // savetaxMasterobj["date"] = taxformdata.form.value['effectivedate'];
        savetaxMasterobj['subtaxes'] = this.subDynamicTable['tabledata'];
        if (this.isEditable) {
          savetaxMasterobj['taxid'] = this.taxIdForSave;
          // this.isEditable = false;

          //false edit key here after testing
        } else {
          //send taxid=0 when it is a new entry i.e while saving
          savetaxMasterobj['taxid'] = 0;
        }
        this.commonservices.postMethod(apiUrl, savetaxMasterobj).subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == "000000") {
            this.taxUniqueNo = successResp['Data'][0]['TaxId']
            this.getMasterTable();//change it
            if (this.eve) {
              this.rowDoubleClickTaxMaster(this.eve, false);
            }
            /**code for not to hit service starts here but having some issue so thats why commented**/
            this.btnRow = false;
            this.hasSubTax = false;
            this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;

            this.disableNewBtn = false;
            this.visibleNewBtn = true;
            this.visibleSaveBtn = false;
            this.visibleCancelBtn = true;
            this.disableCancelBtn = false;
            this.delteBtn = false;
            this.disableForm();
            this.editBtn = true;
            this.btnRow = true;

            this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
          } else {

            this.errormsg = successResp['errorDescription']

          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
      }

      // if (totalSubRate == taxformdata.form.value['taxrate']) {



      // }


    }
    else {

      alert("Internet Connection is Not Available.");

    }

  }

  addTax() {
    this.subTax = "";
    this.subTaxRate = "";
    this.showSubTaxModal.show();
    this.showsubtaxpopup = true;
    this.validationMsg = "";
    this.isEditableSubTaxCheck = false;
  }


  rowDoubleClickTaxMaster(event, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 3000);
    this.isRowClicked = true;

      return;
    }
    this.isRowClicked = true;

    if (this.disableTaxDescription == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.eve = event;
    this.editBtn = true;
    this.visibleCancelBtn = false;
    if (navigator.onLine) {
      this.editDisableBtn = false;
      this.errormsg = "";
      this.operation = "";
      let subtablearr: any = [];
      this.subDynamicTable['tabledata'] = [];
      this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
      this.showloader = true;
      this.doubleClickStage = "rowDoubleClickTaxMaster";
      this.oprationForRowClick = "clickedOnRow";
      this.loaderTimeOut();
      setTimeout(() => {
        this.currentSelectedRow = event.rowindex;
        this.commonservices.identifySelectedRow(this.tabledata, event.rowindex, this.currentSelectedRow, "table1");
      }, 200);
      this.subDynamicTable['tableconfig']['enablefirstbtn'] = false;
      this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
      this.disableNewBtn = false;
      this.disableDt = true;
      this.disableTaxDescription = true;
      this.disableTaxRate = true;
      this.disablehasSubTaxes = true;
      this.disableSaveBtn = true;
      this.saveBtnVisible = false;
      this.disableCancelBtn = false;
      this.taxIdForSave = event['row']['TaxId'];
      this.taxDescription = event['row']['tax'];
      this.taxRate = event['row']['rate'];
      // this.effectiveDate = event['row']
      console.log(event['row']);

      if (event['row']['HasSubTax'] == "null") {
        this.hasSubTaxes = true;
      } else {
        this.hasSubTaxes = JSON.parse(event['row']['HasSubTax']);

      }

      if (typeof (event['row']['TaXSubDetails']) == "string") {
        subtablearr = JSON.parse(event['row']['TaXSubDetails']);
      } else if (event['row']['TaXSubDetails'] == null) {
        subtablearr = [];
      } else {
        subtablearr = event['row']['TaXSubDetails'];

      }


      if (subtablearr.length == 0 || subtablearr.length == null) {
        // this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];
        this.displaySubTable = false;

      } else {
        for (let i = 0; i < subtablearr.length; i++) {
          this.subDynamicTable['tabledata'].push(subtablearr[i]);
        }
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        // this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];
        this.displaySubTable = true;
      }


      let row = event['row']
      let userdeleted = event['username']
      let deletedDate = event['deldate']
      if (row["isDeleted"] === "N") {
        this.taxIdForSave = "";
        this.taxDescription = "";
        this.taxRate = "";
        this.subDynamicTable['tabledata'] = [];
        this.subtabledata = [];
        // this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
      } else {
        // this.deleteMsg = ""
      }

      this.btnRow = true;
      this.showloader = false;
      this.isEditable = true;
      this.visibleNewBtn = true;
      this.visibleSaveBtn = false;
      this.isRowClicked = false;

    }
    else {
      alert("Internet Connection is Not Available.");
    }

  }

  /*
  1.editSubTaxMaster(event) this method is used to edit subtax table
  */
  editSubTaxMaster(event) {
    if (navigator.onLine) {
      this.isEditableSubTaxCheck = true;
      this.validationMsg = "";
      this.errormsg = "";
      this.operation = "";
      this.showloader = true;
      this.loaderTimeOut();
      this.subTaxId = event['rowindex']
      this.subTax = event['row']['subtax'];
      this.subTaxRate = event['row']['rate'];
      this.subtaxRateForEdit = event['row']['rate'];
      this.showSubTaxModal.show();
      this.showsubtaxpopup = true;
      this.showloader = false;
      this.isEditableSubTax = true;
      this.operation = "editSubTaxMaster";
      this.operationForEditToCheck = "edit";

    }
    else {
      alert("Internet Connection is Not Available.");
    }
  }

  editForm(event) {
    this.delteBtn = true;
    this.editDisableBtn = true;
    this.disableNewBtn = false;
    this.disableDt = false;
    this.disableTaxDescription = false;
    this.disableTaxRate = false;
    this.disablehasSubTaxes = false;
    this.hasSubTax = true;
    this.disableSaveBtn = false;
    this.saveBtnVisible = false;
    this.editBtn = false;
    this.editTaxMasterForm(event)
    this.subtableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
    this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = true;

  }

  /*
  1.editTaxMasterForm(event) is used to edit tax master table
  */
  editTaxMasterForm(event) {
    if (navigator.onLine) {
      this.errormsg = "";
      this.operation = "";
      this.indexForEdit = -1;

      this.indexForEdit = event['row']['actualindex'];
      let subtablearr: any = [];
      this.subDynamicTable['tabledata'] = [];
      this.showloader = true;
      this.loaderTimeOut();
      this.disableNewBtn = true;
      this.disableDt = false;
      this.disableTaxDescription = false;
      this.disableTaxRate = false;
      this.disablehasSubTaxes = false;
      this.disableSaveBtn = false;
      this.saveBtnVisible = true;

      this.disableCancelBtn = false;
      this.taxIdForSave = event['row']['TaxId'];
      this.taxDescription = event['row']['tax'];
      this.taxRate = event['row']['rate'];
      if (event['row']['HasSubTax'] == "null") {
        this.hasSubTaxes = true;
      } else {
        this.hasSubTaxes = JSON.parse(event['row']['HasSubTax']);
        this.hasSubTax = false;


      }

      if (typeof (event['row']['TaXSubDetails']) == "string") {
        subtablearr = JSON.parse(event['row']['TaXSubDetails']);
      } else if (event['row']['TaXSubDetails'] == null) {
        subtablearr = [];
      } else {
        subtablearr = event['row']['TaXSubDetails'];

      }


      if (subtablearr.length == 0 || subtablearr.length == null) {
        // this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];
        this.displaySubTable = false;

      } else {
        for (let i = 0; i < subtablearr.length; i++) {
          this.subDynamicTable['tabledata'].push(subtablearr[i]);
        }
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        // this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];
        this.displaySubTable = true;
      }
      if (this.oprationForRowClick == "clickedOnRow") {
        this.oprationForRowClick = "";
      }
      this.showloader = false;
      this.isEditable = true;
      this.operation = "editTaxMasterForm";
      this.subDynamicTable['tableconfig']['enablefirstbtn'] = true;
      this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
      this.visibleNewBtn = true;
      this.visibleSaveBtn = true;
      this.visibleCancelBtn = true;
      // this.operationStage = "editTaxMasterForm";

    }
    else {
      alert("Internet Connection is Not Available.");
    }
  }

  // push values into table
  addSubTax(taxformdata) {
    console.log("operation", this.operation);
    console.log("taxformdata", taxformdata);
    console.log("value", taxformdata.form.value['subtax'], taxformdata.form.value['subtaxrate']);

    this.subtableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
    this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = true;
    if (this.isEditableSubTax) {
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (i == this.subTaxId) {
          this.tabledataforprocess[i]['subtax'] = taxformdata.form.value['subtax'];

          this.tabledataforprocess[i]['rate'] = parseFloat(taxformdata.form.value['subtaxrate']).toFixed(2);

          console.log("value", this.tabledataforprocess[i]['subtax'], this.tabledataforprocess[i]['rate']);


        }
      }
      this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
      this.isEditableSubTax = false;
      this.showSubTaxModal.hide();
      this.showsubtaxpopup = false;
      this.operation = "";
    } else {
      let obj = {};
      this.subTaxArray = [];
      obj["subtax"] = taxformdata.form.value['subtax'];
      obj["rate"] = parseFloat(taxformdata.form.value['subtaxrate']).toFixed(2);
      this.subDynamicTable['tabledata'].push(obj);
      this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
      // this.subtableconfig = this.subDynamicTable["tableconfig"];
      this.subtabledata = this.subDynamicTable["tabledata"];
      this.subtableheaders = this.subDynamicTable["tableheaders"];
    }
    this.displaySubTable = true;
    this.resetSubTaxMasterForm.reset();
    this.showSubTaxModal.hide();
  }

  closeSubTaxPopUpEvent() {

    if (this.operation == "editSubTaxMaster") {
      this.isEditableSubTax = false;
      this.operation = "";

    }
    this.resetSubTaxMasterForm.reset();
    this.showSubTaxModal.hide();
    this.showsubtaxpopup = false;
    if (this.subDynamicTable['tabledata'].length > 0) {
      this.hasSubTaxes = true;

    } else {
      this.hasSubTaxes = false;

    }

  }

  // deleteSubTaxMaster(event) {
  //   this.subDynamicTable['tabledata'].splice(event['rowindex'], 1);
  // }

  closePopUpEvent() {
    this.operation = "";
    this.showSubTaxModal.hide();
    this.showDeleteModal.hide();
  }

  updatedtabledata(data) {
    this.tabledataforprocess = data;
    console.log("tabledataforprocess", this.tabledataforprocess);
  }

  deleteSubTaxMaster(event) {
    if (navigator.onLine) {
      if (this.operation == "editSubTaxMaster") {
        this.isEditableSubTax = false;
        this.operation = "";

      }
      console.log(this.subDynamicTable['tabledata']);

      let row = event['rowindex'];
      console.log(row);

      this.tabledataforprocess.splice(row, 1);

      const deepCopy = this.helper.removeRefrence(this.tabledataforprocess);
      this.subDynamicTable['tabledata'] = [];
      this.subDynamicTable['tabledata'] = deepCopy;

      this.deleteSubTbleById = event['rowindex'];
      this.opeartionStageForSubDelete = "deleteSubTax";
    }
    else {
      this.displayMessagePopup("Internet Connection is Not Available.", "Ok", true, false, "");
    }
  }

  confirmDeleteSubTaxMaster() {

    this.subDynamicTable['tabledata'].splice(this.deleteSubTbleById, 1);
    this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
    this.showconfirmpopup = false;
    this.opeartionStageForSubDelete = "deletedSubTax";
    this.displayMessagePopup("Record Deleted Successfully.", "Ok", true, "", false);
  }

  buttonHandels() {
    this.deleteMsg = "";
    this.btnRow = false;
    this.hasSubTax = false;
    this.visibleCancelBtn = false;
    this.disableDt = true;
    this.disableTaxDescription = true;
    this.disableTaxRate = true;
    this.disablehasSubTaxes = true;
    this.disableSaveBtn = true;
    this.saveBtnVisible = true;

    this.delteBtn = false;
    this.subDynamicTable['tabledata'] = [];
  }

  cancelTaxMasterForm() {


    if (confirm("Do you want to cancel!")) {
      if (this.operation == 'NEW') {
        this.taxDescription = "";
        this.taxRate = "";
        this.effectiveDate = "";
        this.hasSubTaxes = false;
        this.subTax = "";
        this.subTaxRate = "";
        this.subDynamicTable['tabledata'] = [];
        this.subtabledata = [];
        this.btnRow = false;
        this.editBtn = false;
      } else {
        this.editBtn = true;
        this.btnRow = true;
      }
      this.disableDt = true;
      this.disableTaxDescription = true;
      this.disableTaxRate = true;
      this.disablehasSubTaxes = true;
      this.disableCancelBtn = true;
      this.disableSaveBtn = true;
      this.saveBtnVisible = false;
      this.disableNewBtn = false;
      this.visibleNewBtn = true;
      this.visibleSaveBtn = false;
      this.visibleCancelBtn = false;
      this.hasSubTax = false;
      this.delteBtn = false;
      this.editDisableBtn = false;
      this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;
    }
  }

  cancleTableForm() {
    this.buttonHandels();
    this.subtableconfig['actioncolmconfig']['displaycolumn'] = false;
    switch (this.operation) {
      case 'editTaxMasterForm':
        this.isEditable = false;
        this.subDynamicTable['tabledata'] = [];
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        // this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];
        this.displaySubTable = false;
        break;
    }
    this.subDynamicTable['tabledata'] = [];
    this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
    // this.subtableconfig = this.subDynamicTable["tableconfig"];
    this.subtabledata = this.subDynamicTable["tabledata"];
    this.subtableheaders = this.subDynamicTable["tableheaders"];
    this.displaySubTable = false;
    this.disableNewBtn = false;
    if (this.oprationForRowClick == "clickedOnRow") {
      this.oprationForRowClick = "";

    }
    const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    this.resetTaxMasterForm.reset();
  }

  checkRate(subrate) {
    if (subrate != null) {
      this.toatalRate = 0.00;

      for (let i = 0; i < this.subtabledata.length; i++) {
        this.toatalRate = this.toatalRate + parseFloat(this.subtabledata[i]['rate']);
      }

      let totals = this.toatalRate + parseFloat(subrate);
      // console.log();

      if (totals > parseFloat(this.taxRate)) {
        this.validationMsg = "Sub tax rate cannot be greater than tax rate";
        this.disableAddBtn = true;
      }
      else {
        this.validationMsg = "";
        this.disableAddBtn = false;

      }
    }


    var inputBox = document.getElementById("SubTaxRate");

    var invalidChars = ["-", "+", "e"];

    inputBox.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });

  }

  //  setTwoNumberDecimal() below method converts integer value into two place decimal no
  setTwoNumberDecimal(value, whichtax) {

    switch (whichtax) {
      case 'tax':
        this.taxRate = parseFloat(value).toFixed(2);
        break;
    }
  }

  restrictChar() {

    var inputBox = document.getElementById("TaxRate");

    var invalidChars = [
      "-",
      "+",
      "e",
    ];

    inputBox.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });


  }

  // confirmPopup(event) {
  //   this.showconfirmpopup = false;
  // }

  //////if http not gave the response after spend 30 seconds the we will show the error.
  loaderTimeOut() {
    setTimeout(() => {
      if (this.showloader) {
        this.showloader = false;
        this.errormsg = "Error while login your account.";
      }
    }, 50000)
  }

  //displayMessagePopup() is used to call and maintain confirmation popup modal
  displayMessagePopup(popupmessage, submittext, displaysubmit, canceltext, displaycancel) {
    this.confirmationpopupmodelobj.inputmsg = popupmessage;
    this.confirmationpopupmodelobj.submitbtntext = submittext
    this.confirmationpopupmodelobj.displaysubmit = displaysubmit;
    this.confirmationpopupmodelobj.cancelbtntext = canceltext;
    this.confirmationpopupmodelobj.displaycancel = displaycancel;
    this.showconfirmpopup = true;
  }


}
