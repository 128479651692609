import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../appconfig';
import { CommonServicesService } from '../../services/common-services.service';
import { SubmitFormService } from '../../services/submit-form.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-mail-sysparams',
  templateUrl: './mail-sysparams.component.html',
  styleUrls: ['./mail-sysparams.component.scss']
})
export class MailSysparamsComponent implements OnInit {

  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;

  model = {
    paramid: {
      valuType: 'Number', value: '', label: "Param Id", isRequired: false,
      err: "", element: { name: 'input', type: 'number' }
    },
    host: {
      valuType: 'String', value: '', label: "Host", disable: true,
      isRequired: false, err: "", apiKey: "host",
      element: { name: 'input', type: 'text' }
    },
    port: {
      valuType: 'String', value: '', label: "Port", disable: true,
      isRequired: false, err: "", apiKey: "port",
      element: { name: 'input', type: 'text' }
    },
    password: {
      valuType: 'String', value: '', label: "Password", disable: true,
      isRequired: false, err: "", apiKey: "password",
      element: { name: 'input', type: 'text' }
    },
    copyrighttext: {
      valuType: 'String', value: '', label: "Copyright", disable: true,
      isRequired: false, err: "", apiKey: "copyrighttext",
      element: { name: 'input', type: 'text' }
    },
    mailsubject: {
      valuType: 'String', value: '', label: "Mail Subject", disable: true,
      isRequired: false, err: "", apiKey: "mailsubject",
      element: { name: 'input', type: 'text' }
    },
    frommailid: {
      valuType: 'String', value: '', label: "From email", disable: true,
      isRequired: false, err: "", apiKey: "frommailid",
      element: { name: 'input', type: 'text' }
    },
    tomailid: {
      valuType: 'String', value: '', label: "To email", disable: true,
      isRequired: false, err: "", apiKey: "tomailid",
      element: { name: 'input', type: 'text' }
    },
    ccmailid: {
      valuType: 'String', value: '', label: "CC email", disable: false,
      isRequired: false, err: "", apiKey: "ccmailid",
      element: { name: 'input', type: 'text' }
    }
  }

  disableForm: boolean = true;
  visibleEditBtn: boolean = true;
  visibleSaveBtn: boolean = false;
  visibleCancelBtn: boolean = false;
  private backupData: any = { bindDataToFormAndTable: {} };
  MessageDescription: string = "";
  ErrorDescription: string = "";

  constructor(private configurationsetting: ConfigurationSetting, public commonService: CommonServicesService, private submit: SubmitFormService, private helper: HelperService) { }

  ngOnInit(): void {
    this.getDetailsById(1);
  }

  getDetailsById(id) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getMailDetails";
    let params = { paramid: id };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data'];
        this.model = this.commonService.setFromKeysValues(this.model, item);
        this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(item);
      }
    }, (error) => {
      console.log(error);
    });
  }

  saveDetails() {
    let data = this.submit.submitForm(this.model);

    if (data) {
      let endpoint = 'transaction/updateMailDetails';
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.MessageDescription = res['errorDescription'];
          this.PopupMessageModal.show();
          this.getDetailsById(1);
          this.enableDisableForm(true, true, false, false);
        } else {
          this.ErrorDescription = res['errorDescription'];
          this.errorMessageModal.show();
        }
      })
    }
  }

  editForm() {
    this.enableDisableForm(false, false, true, true);
  }

  cancelForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.backupData['bindDataToFormAndTable']) {
        this.model = this.commonService.setFromKeysValues(this.model, this.backupData.bindDataToFormAndTable)
      }
      this.enableDisableForm(true, true, false, false);
    } else {
      this.enableDisableForm(false, false, true, true);
    }
  }

  enableDisableForm(form: boolean, edit: boolean, save: boolean, cancel: boolean) {
    this.disableForm = form;
    this.visibleEditBtn = edit;
    this.visibleSaveBtn = save;
    this.visibleCancelBtn = cancel;
  }
}
