import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../../appconfig';
import { ApiService } from '../../../services/api.service';
import { CommonServicesService } from '../../../services/common-services.service';
import { HelperService } from '../../../services/helper.service';
import { SubmitFormService } from '../../../services/submit-form.service';
import { SystemParamsService } from '../../../services/system-params.service';
import { ToastService } from '../../../services/toast.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var $;
@Component({
  selector: 'app-spare-requisition',
  templateUrl: './spare-requisition.component.html',
  styleUrls: ['./spare-requisition.component.scss']
})
export class SpareRequisitionComponent implements OnInit {

  @ViewChild('showPopupForSpareReqList', { static: false }) public showPopupForSpareReqList;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('printPopup', { static: false }) public printPopup;

  createNewBtn: boolean = false;
  editBtn: boolean = false;
  cancelBtn: boolean = false;
  saveBtn: boolean = true;
  btnRow: boolean = false;
  addItemButton: boolean = false;
  deleteBtnDisable: boolean = false;
  editDisableBtn: boolean = false;
  currentSelectedRow: number;
  isPopUpOpen: boolean = false;
  deleteMsg: string = "";
  saveBtnVisible: boolean = false;
  isRowClicked:boolean = false;
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  todaysDate: any = [];
  label: string = 'Spare Requisition';
  deletedArray: number[] = [];
  emptySearchField: boolean = true;
  requniqueid: number = 0;
  private backupData: any = { bindDataToFormAndTable: {} };
  tabledataforprocess: any[] = []
  fileName:string = "";
  refreshDetailTable:boolean = true;
  dataLocalUrl: any;
  model = {
    uId: {
      valuType: 'Number',
      value: '',
      label: "Id",
      isRequired: false,
      err: "",
      element: { name: 'input', type: 'number' }
    },showsparerequisitionno: {
      valuType: 'String',
      value: '',
      label: "Spare Inward",
      isRequired: false,
      disable: true,
      maxLength: 16,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    sparerequisitionno: {
      valuType: 'String',
      value: '',
      label: "Spare Inward",
      isRequired: false,
      disable: true,
      maxLength: 16,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    requisitiondt: {
      valuType: 'Date',
      value: '',
      label: "Inward Date",
      disable: true,
      isRequired: true,
      err: "",
      element: { name: 'input', type: 'date' }
    },
    rmremark: {
      valuType: 'String',
      value: '',
      label: "Remark",
      disable: true,
      isRequired: false,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  sparerequisitiontableconfig: any = {
    "tableclasses": "table-striped sticky-header  horizontal-scroll table-lightblue  table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "enablefreezepanes": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showonlysearch": true,
    "checkDeletedFlag": true,
    "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  };
  sparerequisitiontableheaders: any[] = [
    {
      "key": "sparerequisitionno",
      "display": "Requisition No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "number",
      "decimaldigit": 0,
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-left",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "RequisitionNo",
      "display": "Requisition No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "decimaldigit": 0,
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-left",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "requisitiondt",
      "display": "Requisition Date",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "date",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "rmremark",
      "display": "Remark",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "decimaldigit": '',
      "datatype": "string",
      "topheader": "",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ];
  sparerequisitiontabledata: any[] = [];

  sparerequisitionlisttableconfig: any = {
    "tableclasses": "table-striped horizontal-scroll table-lightblue  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": true },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
    ],
    "exporttoexcel": false,
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false, "controlsfor": {} },
    "defaultcellmenus": [{
      "label": "Quick Update",
      "action": "quickupdate"
    }]
  };
  sparerequisitionlisttableheaders: any = [
    {
      "key": "partno",
      "display": "Part NO",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "200px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "MinStock",
      "display": "Min Level",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "decimaldigit": 0,
      "positive":true,
      "visiblecolm": true,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "40px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qty",
      "display": "Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "positive":true,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "40px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qtyinhand",
      "display": "Qty In Hand",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "decimaldigit": 0,
      "positive":true,
      "visiblecolm": true,
      "datatype": "number",
      "thstyle": { "text-align": "right", "width": "70px" },
      "tdalignment": "hr-right vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "wip",
      "display": "WIP",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "datatype": "number",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
  ];
  sparerequisitionlisttabledata: any[] = [];

  sparerequisitionitemtableconfig: any = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": true },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": true,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": true,
      "selectedFieldsArray": [
        {
          "key": 'qty',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    }
  };
  sparerequisitionitemtableheader: any = [
    // {
    //   "key": "checkbox,",
    //   "display": "",
    //   "editable": false,
    //   "sorting": true,
    //   "allowaggregation": false,
    //   "enablehide": true,
    //   "visiblecolm": true,
    //   "decimaldigit": '',
    //   "datatype": "checkbox",
    //   "thstyle": { "text-align": "left", "width": "30px" },
    //   "tdalignment": "",
    //   "menulist": [{ "label": "Filter On", "action": "filter" }],
    //   "summary": []
    // },
    {
      "key": "partno",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "150px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qtyinhand",
      "display": "Qty In Hand",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "wip",
      "display": "WIP",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "MinStock",
      "display": "Minimum Stocks",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      //  "formula": "qtyinhand+wip",
      "positive":true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },

    {
      "key": "qty",
      "display": "Quantity",
      "editable": true,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "positive":true,
      "visiblecolm": true,
      //  "formula": "qtyinhand+wip",
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ];
  sparerequisitionitemdata: any[] = [];

  constructor(private domSanitizer: DomSanitizer, private submit: SubmitFormService, public commonService: CommonServicesService, private apiData: ApiService, private configurationsetting: ConfigurationSetting, public systemParamsService: SystemParamsService, private helper: HelperService, private datepipe: DatePipe, private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.getSearchAllSpareRequisition();
  }

  createNew() {
    this.emptySearchField = false;
    this.label = 'Add Spare Requisition';
    this.createNewBtn = true;
    this.saveBtn = true;
    this.editBtn = false;
    this.cancelBtn = true;
    this.btnRow = true;
    this.addItemButton = true;
    this.deleteMsg = ""
    this.saveBtnVisible = true;
    this.deleteBtnDisable = false;
    this.sparerequisitionlisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.todaysDate = new Date();
    this.model.requisitiondt.value = this.todaysDate;
    this.model.rmremark.value = " "
    this.sparerequisitionlisttableheaders = this.commonService.enableEditForGt(this.sparerequisitionlisttableheaders, "qty", true);
    const ele = document.getElementById(`tr${"sparereqtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    this.sparerequisitionlisttableconfig['actioncolmconfig']['displaycolumn'] = true;

    delete this.backupData.bindDataToFormAndTable;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }


  saveSpareRequisitionForm() {
    let data = this.submit.submitForm(this.model)
    if (data) {
      this.saveBtn = true;
      let editedRow = [];
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.tabledataforprocess[i]['qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        if (this.tabledataforprocess[i]?.rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i])
        }
      }
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];

      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      let endpoint = 'transaction/insertSpareRequisition';
      if (data["criteria"]['sparerequisitionno']) {
        endpoint = 'transaction/updateSpareRequisition';
        data["criteria"]["requisitionlist"] = this.tabledataforprocess;
        this.ErrorDescription = "Data Updated Successfully";
      } else {
        data["criteria"]["requisitionlist"] = this.tabledataforprocess;
        this.ErrorDescription = "Data Inserted Successfully";
      }

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.requniqueid = res['Data'];
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.editBtn = true;
          this.addItemButton = false;
          this.cancelBtn = false;
          this.createNewBtn = false;
          this.label = 'Spare Requisition';
          this.commonService.diasbleFormFields(this.model);
          this.sparerequisitionlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.sparerequisitionlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.sparerequisitionlisttableheaders), "qty", false);
          this.getSearchAllSpareRequisition();
          this.PopupMessageModal.show();
          // this.model.sparerequisitionno.value = res['Data'];
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }

  }

  deleteSpareRequisitionForm() {
    let data = this.submit.submitForm(this.model);
    console.log(data);

    if (data || data["criteria"]["sparerequisitionno"]) {

      data["criteria"]["requisitionlist"] = this.tabledataforprocess;

      // console.log(data["criteria"]);

      let items: any = {};
      items.sparerequisitionno = data["criteria"]["sparerequisitionno"];
      items.delLineItems = [];
      items.SpareNo = [];

      data["criteria"]["requisitionlist"].forEach((ele) => {
        if (ele?.SrNo || ele?.SpareNo) {
          items.delLineItems.push(ele?.SrNo);
          items.SpareNo.push(ele?.SpareNo);
        }
      })
      data["criteria"] = items;

      let endpoint = 'transaction/deleteSpareRequisition';

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('closePopupDelete')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.clearAll(false);
          this.deleteBtnDisable = false;
          this.getSearchAllSpareRequisition();
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }


  bindDataToFormAndTable(modal: any, flag: boolean = true) {
    if (this.model.requisitiondt.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");

      return;
    }
    this.refreshDetailTable = false;
    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    this.model.showsparerequisitionno.value = modal['RequisitionNo'];
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.sparerequisitionlisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"sparereqtbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }
    // console.log(this.spareinwardlisttabledata[1]);

    this.sparerequisitionlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.sparerequisitionlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.sparerequisitionlisttableheaders), "qty", false);
    this.sparerequisitionlisttabledata = modal['requisitionlist']?.length ? modal['requisitionlist'] : [];
    let newArr = this.sparerequisitionlisttabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.sparerequisitionlisttabledata = newArr;
    // console.log(newArr);
    setTimeout(() => {
    this.refreshDetailTable = true;
    }, 10);
    this.editBtn = true;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.label = 'Display Spare Requisition';

    this.cancelBtn = false;
    this.editDisableBtn = false;
    this.addItemButton = false;
    this.createNewBtn = false;
    this.deleteBtnDisable = false;
    this.commonService.diasbleFormFields(this.model);
    this.isRowClicked = false;

  }

  selectItemsList(itemdata) {
    this.saveBtn = false;
    this.saveBtnVisible = true;
    itemdata = this.helper.removeRefrence(itemdata);
    let selectedArray = [...this.tabledataforprocess];
    this.sparerequisitionlisttabledata = [];

    for (let item of itemdata) {
      let flag = true;
      this.sparerequisitionlisttabledata.forEach(element => {
        if (element['partno'] == item['partno']) {
          flag = false;
          element['qty'] = item['qty'];
        }
      });
      if (flag)
        selectedArray.push(item);
    }

    this.cancelPopup('closepopupforsparereq');
    this.sparerequisitionlisttabledata = [...this.sparerequisitionlisttabledata, ...selectedArray];
  }

  updatedtabledata(data: any) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;
  }

  editForm() {
    this.sparerequisitionlisttableconfig['actioncolmconfig']['displaycolumn'] = true;

    this.sparerequisitionlisttableheaders = this.commonService.enableEditForGt(this.sparerequisitionlisttableheaders, "qty", true);
    this.label = 'Edit Spare Requisition';

    this.editDisableBtn = true;
    this.cancelBtn = true;
    this.createNewBtn = true;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.addItemButton = true;
    this.deleteBtnDisable = true;
    this.commonService.enableFormFields(this.model);
    this.editBtn = false;
  }
  addItemBtn() {
    // this.sparerequisitionitemdata = this.helper.removeRefrence(this.sparerequisitionitemtabledata);
    // this.isPopUpOpen = true;
    this.getItemListForPopup()

  }

  clearAll(flag: boolean = true) {
    // console.log('editBtn', this.editBtn, 'editDisableBtn', this.editDisableBtn);
    this.commonService.clearForm(this.model);
    this.sparerequisitionlisttabledata = [];

    this.addItemButton = false;
    this.cancelBtn = false;
    this.createNewBtn = false;
    this.btnRow = true;
    this.deleteMsg = ""
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.label = 'Spare Requisition';
    this.commonService.diasbleFormFields(this.model);
    this.sparerequisitionlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    const ele = document.getElementById(`tr${"sparereqtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;

    }
  }


  cancleForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
        // this.createNewBtn = false;
      } else {
        this.clearAll(true);

      }
    }
  }

  cancelPopup(form) {
    this.sparerequisitionitemdata = [];
    this.isPopUpOpen = false;
    switch (form) {
      case 'closepopupforsparereq':
        this.showPopupForSpareReqList.hide();
      case "closePopupDelete":
        this.showDeleteModal.hide()
        break;
    }
  }

  deleteRow(event) {
    let id = event['row'];
    console.log('tabledataforprocess', this.tabledataforprocess);
    let tabledata = this.helper.removeRefrence(this.sparerequisitionlisttabledata);
    tabledata.splice(event.rowindex, 1);
    this.sparerequisitionlisttabledata = []
    this.sparerequisitionlisttabledata = tabledata;
    // console.log(event.rowindex);
    this.tabledataforprocess.splice(event.rowindex, 1);
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }

  rowUpdate(data) {
    // this.tabledataforprocess = data;
    console.log(data);
    const currentValue = data.row.qty;
    data.row.rowmodifiedstatus = 'M';
    console.log(data.row.rowmodifiedstatus = 'M', 'data')


    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.qtyinhand || data?.row?.qty < 0) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.qty = 0;
      this.errorMessageModal.show();
    }
  }

  getSearchAllSpareRequisition() {
    const endpoint = 'transaction/getAllSpareRequisition';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];

        this.sparerequisitiontabledata = []
        this.sparerequisitiontabledata = items;
        for (let i = 0; i < items.length; i++) {
          if (items[i]['sparerequisitionno'] == this.requniqueid || items[i]['sparerequisitionno'] == this.model.sparerequisitionno.value) {
            // console.log("Index", this.tabledata[i], i);
            this.model.sparerequisitionno.value = items[i]['sparerequisitionno'];
            this.getByIdClickCall(this.model.sparerequisitionno.value);

            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trsparereqtbl1' + i).style.backgroundColor = "#7de4f0";
            }, 900);
            break;
          }
        }
        // this.dcreciepttableheaders = successResp['Data']['tableheaders'];
        // this.dcreciepttableconfig = successResp['Data']['tableconfig'];
        this.isPopUpOpen = true;

      }
    }, err => { })
  }


  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.sparerequisitionno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.sparerequisitiontabledata, modal.rowindex, this.currentSelectedRow, "sparereqtbl1");
    let row = modal['row'];
    let sparerequisitionno = row['sparerequisitionno'];
    this.getByIdClickCall(sparerequisitionno);
  }

  getByIdClickCall(no: any) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getSpareRequisitionById";
    let params = { sparerequisitionno: no };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']
        this.bindDataToFormAndTable(item);
      }
    }, (error) => {
      console.log(error);
    });
  }


  getItemListForPopup() {
    const endpoint = 'transaction/getSpareRequisitionItemList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        items.forEach(element => {
          // element.item = element['itemcode'];
          // element.description = element['subassemblyname'];
          element.qty = '';
        });
        this.sparerequisitionitemdata = []
        this.sparerequisitionitemdata = items;

        this.isPopUpOpen = true;

        this.showPopupForSpareReqList.show();


      }
    }, err => { })
  }

  printPdf() {

    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/generatesparepdf";
    let body = { "criteria": { "transId": this.model.sparerequisitionno.value, "printPdfFor":"sparerequisition" } }

    this.commonService.postMethod(apiUrl, body).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        // console.log("success");
        this.fileName = successResp['Data']['filename'];

        this.downloadFile(this.fileName)
      }
      else {
        this.ErrorDescription = "Something went wrong, please contact admin";
      }
    },
      err => {
        this.ErrorDescription = "Something went wrong, please contact admin";
      });
  }


  closeErrorPopup() {
    this.errorMessageModal.hide();
    this.backendErrorMessageModal.hide();
    this.PopupMessageModal.hide();
  }


  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }


  downloadFile(filename: string) {
    let body = { "filename": filename }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/downloadpdf";
    this.commonService.getFile(apiUrl, body) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        // saveAs(blob, this.fileName);
        let a = document.createElement('a');

        let file = new Blob([blob], { type: 'application/pdf' });
        // console.log("file ", file);

        let fileURL = URL.createObjectURL(file);

        this.dataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
        a.download = this.fileName;
        setTimeout(() => {
          this.printPopup.show();
        }, 200);

        this.fileName = "";
      })
      .catch((err) => {
        console.log("Something went wrong");
      });

  }

}
