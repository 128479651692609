import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationSetting } from '../../appconfig'
import { HttpHeaders, HttpParams } from '@angular/common/http'
import { ConfirmationPopupModel } from '../../models/models';
import { CommonServicesService } from '../../services/common-services.service';
import { DatePipe } from '@angular/common';
import { DataSharingService } from '../gt-plus/data-sharing.service';
import { SystemParamsService } from '../../services/system-params.service';
import { EventEmitter } from 'stream';
import { Subject } from 'rxjs';
import { ToastService } from '../../services/toast.service';
declare var $;
@Component({
  selector: 'app-sub-assembly-master',
  templateUrl: './sub-assembly-master.component.html',
  styleUrls: ['./sub-assembly-master.component.scss']
})
export class SubAssemblyMasterComponent implements OnInit {
  // @Output() submitButtonEvent = new EventEmitter();
  submitButtonEventSubject: Subject<any> = new Subject<any>();
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;

  masterData: any[] = [];
  dynamicformdata: any;
  dynamictable: any = {};
  dynamicsubtable: any = {};
  historyRecords: any = [];
  showloader: boolean = false;
  showconfirmpopup: boolean = false;
  displayTable: boolean = false;
  displayForm: boolean = false;
  errormsg: string = "";
  headers = new HttpHeaders();
  srNoForSave;
  isEditable: boolean = false;
  operation: string = "";
  operationStage: string = "";
  rowsperpage = 100;
  currentSelectedRow: number = 0;
  role = "Admin";
  userRole: string = "";
  deleteById;
  description: string = "";
  opeartionStageForDelete: string = "";
  oprationForRowClick: string = "";
  displaySubTable: boolean = false;
  isRowClicked: boolean = false;
  isEffectiveDate:boolean = false;
  highlightedObj = {
    "id": "",
    "indx": -1
  }
  doubleClickStage: string = "";
  partNo: any;
  tblevent = {};
  confirmationpopupmodelobj: ConfirmationPopupModel = new ConfirmationPopupModel();
  btnRow: boolean = false;
  emptySearchField: boolean = true;
  deleteMsg = "";
  isValidForm: boolean = true;
  editedDate: string;

  tableheaders = [
    {
      "key": "PartNo",
      "display": "Sub Assembly No",
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center ", "width": "17%" }
    },
    {
      "key": "subassemblyname",
      "display": "Sub Assembly Name",
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center ", "width": "40%" }
    },
    {
      "key": "DateEffectiveFrom",
      "display": "WEF Date",
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "date",
      "thstyle": { "text-align": "center ", "width": "10%" }
    },
    {
      "key": "Rate",
      "display": "Rate",
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 2,
      "datatype": "number",
      "tdalignment": "hr-right vt-middle",
      "thstyle": { "text-align": "center ", "width": "10%" }
    }
  ];
  tabledata = [];
  tableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": true,
      "delete": true,
      "print": false
    },
    "partno": { "text-align": "left" },
    "Qty": { "text-align": "Right" },
    "CurrentBalance": { "text-align": "Right" },
    "WIP": { "text-align": "Right" },
    "controlconfig": { "displaycontrol": false },
    // "checkDeletedFlag": true,
    // "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  };

  addItemButton;
  editDisableBtn: boolean = false;
  saveDisableBtn: boolean = false;
  saveBtn;
  editBtn: boolean = false;
  deleteBtn: boolean = false;
  cancelBtn: boolean = false;
  newForm: boolean = false;
  subAssUniqueNo: number = 0;
  newEntry: boolean = false;

  subtableheaders = [
    {
      "key": "DateEffectiveFrom",
      "display": "WEF Date",
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "date",
      "tdalignment": "hr-center vt-middle",
      "thstyle": { "text-align": "center ", "width": "50%" }
    },
    {
      "key": "Rate",
      "display": "Rate",
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "number",
      "decimaldigit": 2,
      "tdalignment": "hr-center vt-middle",
      "thstyle": { "text-align": "center ", "width": "50%" }
    }
  ];
  subtabledata = [];
  subtableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": true,
      "edit": false,
      "delete": false,
      "print": false
    },
    "enablefirstbtn": false,
    "enablesecondbtn": false,
    "firstBtnText": "Add Spares",
    "secondBtnText": "Spare List",
    "firstBtnStyle": {},
    "secondBtnStyle": {},
    "firstBtnClass": "",
    "secondBtnClass": "",
    "Qty": { "text-align": "center", "background-color": "#b4f9df" },
    "ItemNo": { "text-align": "center" },
    "controlconfig": {
      "displaycontrol": true,
      "controlsfor": { "Qty": { "pattern": "^\\d+$" } }
    },
  };

  constructor(private dataSharingSrvc: DataSharingService, private datepipe: DatePipe, public commonservices: CommonServicesService, private router: Router, private configurationsetting: ConfigurationSetting, public systemParamsService: SystemParamsService, private toastService: ToastService,) { }

  ngOnInit() {
    this.setHeaders();
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    this.getMasterTable();
    setTimeout(() => {
      // this.disableForm();
    }, 800)

    // this.dynamicformdata['css']['btnconfiguration']['visiblenew'] = false;
    // this.dynamicformdata['css']['btnconfiguration']['visiblesave'] = false;
    // this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = false;

  }

  onSubmitBtnClick() {
    if (this.dynamicformdata['controls']['subassemblyno']['value'] == "" ||
      this.dynamicformdata['controls']['description']['value'] == "" ||
      this.dynamicformdata['controls']['rate']['value'] == "") {
      this.toastService.showToastMsg('warning', 'Warning', "Please fill required fields.");

      return;
    }
    // this.submitButtonEventSubject.emit('savebtnclick');
    this.submitButtonEventSubject.next('save');

    // this.editBtn = false;
    this.editDisableBtn = false;
  }

  setHeaders() {
    // this.userid = JSON.parse(sessionStorage.getItem("logindata"))["userid"];
    // let token = this.managecoockies.getCookie("token");
    this.headers = this.headers.append("Content-Type", "application/json");
    // this.headers = this.headers.append("token", token);
    // this.headers = this.headers.append("userid", this.userid.toString());
  }

  getNewForm() {
    this.operation = "NEW";
    this.emptySearchField = false;
    this.tblevent = "";
    this.newForm = true;
    this.saveDisableBtn = true;
    this.cancelBtn = true;
    this.deleteMsg = "";
    this.btnRow = true;
    this.editBtn = false;
    this.displaySubTable = false;
    const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.oprationForRowClick == "clickedOnRow") {

      this.oprationForRowClick = "";
      const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
      if (ele) ele.style.backgroundColor = '';

    }

    if (this.operation == "editSubAssembly") {
      this.isEditable = false;
      this.operation = "";
    }
    this.dynamicformdata['controls']['subassemblyno']['value'] = "";
    this.dynamicformdata['controls']['description']['value'] = "";
    this.dynamicformdata['controls']['rate']['value'] = "";
    // this.dynamicformdata['controls']['billrejecteditems']['options'][0]['value'] = "";
    this.dynamicformdata['controls']['billrejecteditems']['options'][0]['checked'] = false;
    this.dynamicformdata['controls']['dateeffectivefrom']['value'] = new Date();
    this.dynamicformdata['css']['btnconfiguration']['disableSave'] = false;
    this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
    this.dynamicformdata['css']['btnconfiguration']['disableNew'] = false;//editing from "true" to false ask to client
    this.dynamicformdata['controls']['subassemblyno']['disabled'] = false;
    this.dynamicformdata['controls']['description']['disabled'] = false;
    this.dynamicformdata['controls']['rate']['disabled'] = false;
    this.dynamicformdata['controls']['billrejecteditems']['disabled'] = false;
    this.dynamicformdata['controls']['billrejecteditems']['options'][0]['disabled'] = false;
    this.dynamicformdata['controls']['dateeffectivefrom']['disabled'] = false;
    this.dynamicformdata['css']['btnconfiguration']['visiblenew'] = false;
    this.dynamicformdata['css']['btnconfiguration']['visiblesave'] = true;
    this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = true;
    this.subtabledata = [];
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  disableFields() {
    this.dynamicformdata['controls']['subassemblyno']['disabled'] = true;
    this.dynamicformdata['controls']['description']['disabled'] = true;
    this.dynamicformdata['controls']['rate']['disabled'] = true;
    this.dynamicformdata['controls']['billrejecteditems']['disabled'] = true;
    this.dynamicformdata['controls']['dateeffectivefrom']['disabled'] = true;
  }

  disableForm() {
    /*disable form*/
    this.dynamicformdata['controls']['subassemblyno']['disabled'] = true;
    this.dynamicformdata['controls']['description']['disabled'] = true;
    this.dynamicformdata['controls']['rate']['disabled'] = true;
    this.dynamicformdata['controls']['billrejecteditems']['disabled'] = true;
    this.dynamicformdata['controls']['dateeffectivefrom']['disabled'] = true;
    this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = true;
    this.dynamicformdata['css']['btnconfiguration']['disableSave'] = true;
    this.dynamicformdata['controls']['billrejecteditems']['disabled'] = true;
    this.dynamicformdata['controls']['billrejecteditems']['options'][0]['disabled'] = true;
    this.dynamicformdata['css']['btnconfiguration']['visiblenew'] = true;
    this.dynamicformdata['css']['btnconfiguration']['visiblesave'] = false;
    this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = false;

    /*disable form visiblesave visiblenew visiblecancel*/
  }

  deleteSubAssemblyMaster(event) {
    if (navigator.onLine) {
      if (this.operation == "editSubAssembly" || this.doubleClickStage == "rowDoubleClickSubAssembly") {
        const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
      }

      this.deleteById = event['row']['SrNo'];
      this.opeartionStageForDelete = "delete";
      this.displayMessagePopup("Are you sure want to delete this record?", "Yes", true, "No", true);
    }
    else {
      this.displayMessagePopup("Internet Connection is Not Available.", "Ok", true, false, "");
    }
  }

  confirmDeleteSubAssMaster() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/DeleteMasterRecords";
      let obj = {};
      obj['deletefor'] = "Subassembly"
      obj['recordvalue'] = this.deleteById;
      let param = { "criteria": obj }
      this.commonservices.postMethod(apiUrl, param).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.showconfirmpopup = false;
          this.opeartionStageForDelete = "deleted";
          this.deleteBtn = false;
          this.btnRow = false;
          this.subtabledata = [];
          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
        } else if (successResp['errorCode'] == 111000) {
          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);

        }else{

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      this.showloader = false;

      alert("Internet Connection is Not Available.");

    }
  }




  cancelConfirmationPopup() {
    this.showconfirmpopup = false;
    this.opeartionStageForDelete = "";
  }

  editForm(event) {
    this.editSubAssembly(event);

    this.deleteBtn = true;
    this.editDisableBtn = true;
    this.saveDisableBtn = true;
    this.cancelBtn = true;
    this.newForm = true;
    this.editBtn = false;
  }

  deleteSubAsyMaster(event) {
    this.deleteSubAssemblyMaster(event)
  }


  rowDoubleClickSubAssembly(event, flag = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 3000);
      this.isRowClicked = true;
      return;
    }
    this.isRowClicked = true;
    if (navigator.onLine) {
      if (this.dynamicformdata['controls']['description']['disabled'] == false && flag) {
        this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
        return;
      }
      this.tblevent = event;
      this.cancelBtn = false;
      this.updateTableData(this.tblevent);
      setTimeout(() => {
        this.currentSelectedRow = event.rowindex;
        this.commonservices.identifySelectedRow(this.tabledata, event.rowindex, this.currentSelectedRow, "table1");
      }, 100);
      this.errormsg = "";
      this.operation = "";
      this.oprationForRowClick = "clickedOnRow";
      this.showloader = true;
      this.loaderTimeOut();
    }
    else {

      alert("Internet Connection is Not Available.");

    }
  }

  updateTableData(event) {
    console.log(event['row']);

    let apiUrl = this.configurationsetting.getBaseUrl() + "Master/EditSubAssemblyMaster";
    let params = { "SrNo": event['row']['SrNo'] };

    this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
      this.showloader = false;
      if (successResp['errorCode'] == "000000") {
        this.dynamicformdata = {};
        this.dynamicformdata = successResp['Data'][0]['@formjson'];
        this.setBootstrapClasses();
        this.dynamicformdata['controls']['dateeffectivefrom']['dateformat'] = this.systemParamsService.bsDateFormatDDMMYYYY;

        if(this.dynamicformdata['controls']['dateeffectivefrom']['value']){
          let date = new Date(this.dynamicformdata['controls']['dateeffectivefrom']['value']);
          this.dynamicformdata['controls']['dateeffectivefrom']['value'] = this.datepipe.transform(date, this.systemParamsService.ngDateFormatddMMy)
        }

        this.dynamicformdata['controls']['dateeffectivefrom']['placeholder'] = 'dd/mm/yyyy'
        this.dynamicformdata['controls']['subassemblyno']['label'] = "Sub Assembly No."
        this.dynamicformdata['controls']['subassemblyno']['pattern'] = "[a-zA-Z0-9- ]*"
        this.dynamicformdata['controls']['subassemblyno']['patternmsg'] = "Allowed characters are a-z A-Z 0-9 - Space"

        this.dynamicformdata['controls']['subassemblyno']['disabled'] = true;
        this.dynamicformdata['controls']['description']['disabled'] = true;
        this.dynamicformdata['controls']['rate']['disabled'] = true;
        this.dynamicformdata['controls']['billrejecteditems']['disabled'] = true;
        this.dynamicformdata['controls']['billrejecteditems']['options'][0]['disabled'] = true;
        // this.dynamicformdata['controls']['rate'] = this.dynamicformdata['controls']['rate'].toFixed(2)
        this.dynamicformdata['controls']['dateeffectivefrom']['disabled'] = true;
        this.dynamicformdata['css']['btnconfiguration']['visiblenew'] = true;
        this.dynamicformdata['css']['btnconfiguration']['visiblesave'] = false;
        this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = true;
        this.doubleClickStage = "rowDoubleClickSubAssembly";
        this.dynamicsubtable = successResp['Data'][0]['@subtbl'];
        console.log(this.dynamicsubtable);

        for (let i = 0; i < this.dynamicsubtable['tableheaders'].length; i++) {
          if (this.dynamicsubtable['tableheaders'][i]['key'] == 'PartNo' ||
            this.dynamicsubtable['tableheaders'][i]['key'] == 'subassemblyname') {
            this.dynamicsubtable['tableheaders'][i]['visiblecolm'] = false;
          }
        }
        this.dynamicsubtable['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
        this.dynamicformdata['controls']['dateeffectivefrom']['dateformat'] = this.systemParamsService.bsDateFormatDDMMYYYY;
        let str = this.dynamicsubtable['tabledata'];
        // let subtable = JSON.parse(str);
        let subtable = str;

        this.dynamicsubtable['tabledata'] = subtable;
        this.dynamicsubtable = JSON.parse(JSON.stringify(this.dynamicsubtable));

        let row = event['row']
        let userdeleted = event['username']
        let deletedDate = event['deldate']
        if (row["isDeleted"] === "N") {
          this.btnRow = false;
          this.dynamicformdata['controls']['subassemblyno']['value'] = "";
          this.dynamicformdata['controls']['description']['value'] = "";
          this.dynamicformdata['controls']['rate']['value'] = "";
          this.dynamicformdata['controls']['billrejecteditems']['value'] = "";
          this.dynamicformdata['controls']['dateeffectivefrom']['value'] = "";
          this.dynamicsubtable['tabledata'] = [];

          console.log(true);
          this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        } else {
          this.btnRow = true;
          this.deleteMsg = ""
        }
        this.editBtn = true;

        // if (this.dynamicsubtable['tabledata'].length == 0 || this.dynamicsubtable['tabledata'] == null || this.dynamicsubtable['tabledata'] == "[]") {
        if (!this.dynamicsubtable['tabledata']) {
          this.dynamicsubtable['tabledata'] = [];
          // this.subtableconfig = this.dynamicsubtable["tableconfig"];
          this.subtabledata = this.dynamicsubtable["tabledata"];
          // this.subtableheaders = this.dynamicsubtable["tableheaders"];
          this.displaySubTable = false;

        } else {
          /*make date format starts here*/
          this.dynamicsubtable['tabledata'].filter(row => {
            row['DateEffectiveFrom'] = this.datepipe.transform(row['DateEffectiveFrom'], "dd/MMM/yyyy");
          });
          /*make date format ends here*/
          // this.subtableconfig = this.dynamicsubtable["tableconfig"];
          this.subtabledata = this.dynamicsubtable["tabledata"];
          // this.subtableheaders = this.dynamicsubtable["tableheaders"];
          this.displaySubTable = true;
        }

        this.isRowClicked = false;


      } else {

        this.errormsg = successResp['errorDescription']
        this.isRowClicked = false;

      }
    }, errorResp => {
      this.isRowClicked = false;
      this.showloader = false;
      this.errormsg = errorResp['message']
    });

  }

  //get master table and dynamic forms starts here
  getMasterTable() {

    this.errormsg = "";
    this.showloader = true;
    this.loaderTimeOut();
    this.commonservices.getGenericTableNForm("SubAssemblyMasterForm", "SubAssemblyMasterTable", this.configurationsetting.getBaseUrl())
      .subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.dynamicformdata = successResp['Data'][0]['@formjson'];
          // console.log();
          this.dynamicformdata['css']['btnconfiguration']['visiblenew'] = false;
          this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = false;

          this.dynamicformdata['controls']['dateeffectivefrom']['dateformat'] = this.systemParamsService.bsDateFormatDDMMYYYY;
          this.dynamicformdata['controls']['dateeffectivefrom']['placeholder'] = 'dd/mm/yyyy'
          this.dynamicformdata['controls']['subassemblyno']['label'] = "Sub Assembly No."
          this.dynamicformdata['controls']['subassemblyno']['pattern'] = "[a-zA-Z0-9- ]*"
          this.dynamicformdata['controls']['subassemblyno']['patternmsg'] = "Allowed characters are a-z A-Z 0-9 - Space"
          this.setBootstrapClasses();
          this.dynamictable = successResp['Data'][0]['@tbljson'];

          this.disableFields();
          this.saveDisableBtn = false;
          this.editBtn = false;
          this.editDisableBtn = false;
          this.newForm = false;
          this.cancelBtn = false;
          // console.log(this.dynamicformdata['controls']);

          let rowData = successResp['Data'][0]['@tbldata']
          for (let i = 0; i < rowData.length; i++) {
            if (rowData[i]['SrNo'] == this.subAssUniqueNo) {
              // console.log("Index", this.tabledata[i], i);
              this.rowDoubleClickSubAssembly({
                "row": rowData[i],
                "rowindex": i
              })
              setTimeout(() => {
                $(`tr[tabindex=${i}]`).focus();
                this.editBtn = true;
                // console.log(document.getElementById('trtable1' + i));
                // console.log($(`tr[tabindex=${i}]`).click());

                document.getElementById('trtable1' + i).style.backgroundColor = "#7de4f0";
                // this.dynamicformdata['controls']['subassemblyno']['value'] = rowData[i]['PartNo']
                // this.dynamicformdata['controls']['description']['value'] = rowData[i]['subassemblyname']
                // this.dynamicformdata['controls']['rate']['value'] = rowData[i]['Rate']
                // if (this.newEntry) {
                //   this.dynamicformdata['controls']['dateeffectivefrom']['value'] = this.datepipe.transform(rowData[i]['DateEffectiveFrom'], this.systemParamsService.bsDateFormatDDMMYYYY)
                // }
              }, 900);
              // break;
            }
            // console.log(rowData[i]['Rate']);

            rowData[i]['Rate'] = rowData[i]['Rate'];
            if (rowData[i]['CurrentBalance'] == 0 || rowData[i]['WIP'] == 0) {
              rowData[i]['CurrentBalance'] = 0;
              rowData[i]['WIP'] = 0;
            }
          }

          // this.dynamictable['tabledata'] = successResp['Data'][0]['@tbldata'];

          if (successResp['Data'][0]['@tbldata'].length > 0) {
            // successResp['Data'][0]['@tbldata'].sort(function (a, b) {
            //   var nameA = a.subassemblyname.toLowerCase(), nameB = b.subassemblyname.toLowerCase()
            //   if (nameA < nameB) //sort string ascending
            //     return -1
            //   if (nameA > nameB)
            //     return 1
            //   return 0 //default return value (no sorting)
            // })
            this.dynamictable['tabledata'] = successResp['Data'][0]['@tbldata'];
            /*make date format starts here*/
            // this.dynamictable['tabledata'].filter(row => {
            //   row['DateEffectiveFrom'] = this.datepipe.transform(row['DateEffectiveFrom'], "dd/MMM/yyyy");
            // });
            /*make date format ends here*/

          }



          /*********changes starts here********/
          if (this.userRole == "Admin") {
            this.dynamictable['tableconfig']['actioncolmconfig']['delete'] = true;
          } else {
            this.dynamictable['tableconfig']['actioncolmconfig']['delete'] = false;
          }
          /********chnages ends here*********/
          // this.tableconfig = this.dynamictable["tableconfig"];
          this.tabledata = this.dynamictable["tabledata"];
          // this.tableheaders = this.dynamictable["tableheaders"];
          this.dynamictable["tableheaders"][3]['datatype'] = 'date'
          this.displayTable = true;
          this.displayForm = true;

          if (this.operationStage == "saveOperation") {
            this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
            this.dynamicformdata['css']['btnconfiguration']['visiblenew'] = true;
            this.dynamicformdata['css']['btnconfiguration']['visiblesave'] = false;
            this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = true;
            this.operationStage = "";

          } else if (this.operationStage == "cancelSubAssembly") {
            this.dynamicformdata['controls']['subassemblyno']['disabled'] = true;
            this.dynamicformdata['controls']['description']['disabled'] = true;
            this.dynamicformdata['controls']['rate']['disabled'] = true;
            this.dynamicformdata['controls']['billrejecteditems']['disabled'] = true;
            this.dynamicformdata['controls']['dateeffectivefrom']['disabled'] = true;
            // this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = true;
            // this.dynamicformdata['css']['btnconfiguration']['disableSave'] = true;
            this.dynamicformdata['controls']['billrejecteditems']['disabled'] = true;
            this.dynamicformdata['controls']['billrejecteditems']['options'][0]['disabled'] = true;
            this.dynamicformdata['css']['btnconfiguration']['visiblenew'] = true;
            this.dynamicformdata['css']['btnconfiguration']['visiblesave'] = false;
            this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = true;
            this.operationStage = "";


          }
          else {
            // this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = true;
            this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = true;

          }



        } else {
          this.errormsg = successResp['errorDescription']

        }

      }, errorRsp => {
        this.showloader = false;
        this.errormsg = errorRsp['message']
      });
  }
  //get master table and dynamic forms ends here


  // myFormToReset(event){
  //   console.log("reset form");
  //   event.reset();
  // }


  //below method is to save sub-assembly master starts here
  /*
  In success error code is '0'
  1.send "srno"=0 if You want to save new assembly
  2.send "srno" with coming value if you want to edit it
  */
  event = {};
  submitAssemblyMaster(event) {
    console.log("save event", event);
    this.event = event;
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveSubAssemblyMaster";//SaveSubAssemblyMaster
      let objtosend = {};
      let subassembhistory = {};
      // objtosend['subassemblyno'] = event['subassemblyno'];//change
      objtosend['description'] = event['description'];


        console.log(typeof(event['dateeffectivefrom']), event['dateeffectivefrom']);
        if(typeof(event['dateeffectivefrom']) == 'string'){
          let dateParts:any = event['dateeffectivefrom'].split("/");
          var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
          objtosend['dateeffectivefrom'] = this.datepipe.transform(dateObject, "yyyy-MM-dd");//,hh:MM:ss
        }else{
          objtosend['dateeffectivefrom'] = this.datepipe.transform(new Date(event['dateeffectivefrom']), "yyyy-MM-dd");
        }



      if (event['billrejecteditems'].length == 0) {
        objtosend['billrejecteditems'] = "N";
      } else if (event['billrejecteditems'].length > 0) {
        objtosend['billrejecteditems'] = event['billrejecteditems'][0];

      }
      objtosend['rate'] = event['rate'];
      if (this.isEditable) {
        objtosend['subassemblyno'] = this.partNo;
        objtosend['srno'] = this.srNoForSave;
        this.isEditable = false;

        objtosend['subassemblyhistory'] = this.historyRecords;

      } else {
        //send srno=0 when it is a new entry i.e while saving
        objtosend['subassemblyno'] = event['subassemblyno'];
        objtosend['srno'] = 0;
        objtosend['subassemblyhistory'] = [];
      }

      if (objtosend['srno'] == 0) {
        this.newEntry = true;
      } else {
        this.newEntry = false
      }

      console.log("event::-", JSON.stringify(objtosend));
      this.commonservices.postMethod(apiUrl, objtosend).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          // debugger
          this.subAssUniqueNo = successResp['Data'][0]['SrNo']
          this.dynamicformdata['css']['btnconfiguration']['disableNew'] = false;
          this.operationStage = "saveOperation";

          this.getMasterTable();//change it

          // if (this.tblevent) {
          //   this.rowDoubleClickSubAssembly(this.tblevent, false)
          //   this.updateTableData(this.tblevent);
          // }

          this.dynamicformdata['css']['btnconfiguration']['visiblenew'] = true;
          this.dynamicformdata['css']['btnconfiguration']['visiblesave'] = false;
          this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = true;
          //
          this.dynamicsubtable = JSON.parse(JSON.stringify(this.dynamicsubtable));



          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);

          this.dynamicformdata['controls']['subassemblyno']['value'] = successResp['Data'][0]['PartNo'];
          this.dynamicformdata['controls']['description']['value'] = successResp['Data'][0]['subassemblyname'];
          this.dynamicformdata['controls']['rate']['value'] = successResp['Data'][0]['Rate'];

          this.btnRow = true;
          this.editBtn = true;
          this.deleteBtn = false;
        } else {

          this.errormsg = successResp['errorDescription']

        }
        // this.cancelSubAssembly();
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      this.showloader = false;

      alert("Internet Connection is Not Available.");

    }
  }

  //above method is to save sub-assembly master ends here

  /**editSubAssembly(event) method gives populated form for edit starts here*/
  editSubAssembly(event) {
    console.log(" editSubAssembly ", event);

    if (navigator.onLine) {
      this.errormsg = "";
      this.operation = "";
      this.dynamicsubtable = {};
      if (this.oprationForRowClick == "clickedOnRow") {
        this.oprationForRowClick = "";
      }
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/EditSubAssemblyMaster";
      let params = { "SrNo": event['row']['SrNo'] };


      this.srNoForSave = event['row']['SrNo'];
      this.partNo = event['row']['PartNo'];
      let SubAssemblyHistory = [];
      let obj = {};
      this.historyRecords = [];
      if(event['row']['DateEffectiveFrom'] && event['row']['Rate']){
        obj['DateEffectiveFrom'] = event['row']['DateEffectiveFrom'];
        obj['Rate'] = event['row']['Rate'];
        if (event['row']['SubAssemblyHistory'] != null) {
          SubAssemblyHistory = JSON.parse(event['row']['SubAssemblyHistory']);
        } else {
          SubAssemblyHistory = [];
        }
        SubAssemblyHistory.push(obj)
        this.historyRecords = SubAssemblyHistory;
      }else{
        this.isEffectiveDate = true;
      }

      this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == 0) {
          this.dynamicformdata = {};
          this.dynamicsubtable = {};
          this.dynamicformdata = successResp['Data'][0]['@formjson'];
          this.setBootstrapClasses();
          this.dynamicformdata['controls']['subassemblyno']['disabled'] = true;
          // this.dynamicformdata['controls']['subassemblyno']['disabled'] = false;
          this.dynamicformdata['controls']['description']['disabled'] = false;
          this.dynamicformdata['controls']['rate']['disabled'] = false;
          this.dynamicformdata['controls']['billrejecteditems']['disabled'] = false;
          this.dynamicformdata['controls']['billrejecteditems']['options'][0]['disabled'] = false;
          this.dynamicformdata['controls']['dateeffectivefrom']['disabled'] = false;
          this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
          this.dynamicformdata['css']['btnconfiguration']['disableSave'] = false;
          this.dynamicformdata['css']['btnconfiguration']['disableNew'] = false;
          this.dynamicformdata['css']['btnconfiguration']['visiblenew'] = false;
          this.dynamicformdata['css']['btnconfiguration']['visiblesave'] = true;
          this.dynamicformdata['css']['btnconfiguration']['visiblecancel'] = true;
          this.isEditable = true;
          this.operation = "editSubAssembly";
          // this.editedDate = this.dynamicformdata['controls']['dateeffectivefrom']['value'];
          // console.log(this.editedDate);

          if(this.dynamicformdata['controls']['dateeffectivefrom']['value']){
            let date = new Date(this.dynamicformdata['controls']['dateeffectivefrom']['value']);
            this.dynamicformdata['controls']['dateeffectivefrom']['value'] = this.datepipe.transform(date, this.systemParamsService.ngDateFormatddMMy)
          }

          this.dynamicformdata['controls']['dateeffectivefrom']['dateformat'] = this.systemParamsService.bsDateFormatDDMMYYYY;
          this.dynamicformdata['controls']['dateeffectivefrom']['placeholder'] = 'dd/mm/yyyy';


          this.dynamicformdata['controls']['subassemblyno']['label'] = "Sub Assembly No.";
          this.dynamicformdata['controls']['subassemblyno']['pattern'] = "[a-zA-Z0-9- ]*";
          this.dynamicformdata['controls']['subassemblyno']['patternmsg'] = "Allowed characters are a-z A-Z 0-9 - Space";

          this.dynamicsubtable = successResp['Data'][0]['@subtbl'];
          console.log(this.dynamicsubtable);
          for (let i = 0; i < this.dynamicsubtable['tableheaders'].length; i++) {
            if (this.dynamicsubtable['tableheaders'][i]['key'] == 'PartNo' ||
              this.dynamicsubtable['tableheaders'][i]['key'] == 'subassemblyname') {
              this.dynamicsubtable['tableheaders'][i]['visiblecolm'] = false;
            }
          }
          this.dynamicsubtable['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
          let str = this.dynamicsubtable['tabledata'];
          // let subtable = JSON.parse(str);
          let subtable = str;
          if (subtable != null) {
            this.dynamicsubtable['tabledata'] = subtable;
          } else {
            this.dynamicsubtable['tabledata'] = [];

          }
          this.dynamicsubtable = JSON.parse(JSON.stringify(this.dynamicsubtable));

          if (this.dynamicsubtable['tabledata'] == null || this.dynamicsubtable['tabledata'].length == 0 || this.dynamicsubtable['tabledata'] == "[]") {

            this.dynamicsubtable['tabledata'] = [];
            // this.subtableconfig = this.dynamicsubtable["tableconfig"];
            this.subtabledata = this.dynamicsubtable["tabledata"];
            // this.subtableheaders = this.dynamicsubtable["tableheaders"];
            this.displaySubTable = false;

          } else {
            /*make date format starts here*/
            this.dynamicsubtable['tabledata'].filter(row => {
              row['DateEffectiveFrom'] = this.datepipe.transform(row['DateEffectiveFrom'], "dd/MMM/yyyy");
            });
            /*make date format ends here*/
            // this.subtableconfig = this.dynamicsubtable["tableconfig"];
            this.subtabledata = this.dynamicsubtable["tabledata"];
            // this.subtableheaders = this.dynamicsubtable["tableheaders"];
            this.displaySubTable = true;
          }
          if (this.userRole == "Admin") {
            this.dynamicformdata['controls']['subassemblyno']['disabled'] = false;
          } else {
            this.dynamicformdata['controls']['subassemblyno']['disabled'] = true;
          }

        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {

      alert("Internet Connection is Not Available.");
    }
  }
  /**editSubAssembly(event)ends here*/

  /*cancelSubAssembly() method will clear form and refresh table starts here*/
  cancelSubAssembly() {
    if (confirm("Do you want to cancel!")) {
      if (this.operation == "NEW") {
        this.dynamicformdata['controls']['subassemblyno']['value'] = "";
        this.dynamicformdata['controls']['description']['value'] = "";
        this.dynamicformdata['controls']['rate']['value'] = "";
        this.btnRow = false;
        this.editBtn = false;
      } else {
        this.btnRow = true;
        this.editBtn = true;
      }
      this.isEditable = false;
      this.operation = "";
      this.deleteBtn = false;
      this.saveDisableBtn = false;
      this.cancelBtn = false;
      this.editDisableBtn = false;
      this.newForm = false;
      this.deleteMsg = "";
      this.disableForm();
    }
  }
  /*cancelSubAssembly() method will clear form and refresh table ends here*/

  /*checkUniquePartNo(event) this method will take value which is entered in event
  and this method checks whether entered part no or sub assembly no is duplicate or not */
  checkUniquePartNo(event) {
    if (navigator.onLine) {
      if (event['controlname'] == 'subassemblyno') {
        this.errormsg = "";
        this.showloader = true;
        this.loaderTimeOut();
        let apiUrl = this.configurationsetting.getBaseUrl() + "Master/CheckUniquePartNumber";
        let params = { "PartNo": event['value']['currentTarget']['value'] };


        this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == "000000") {
            this.dynamicformdata['controls']['subassemblyno']['disabled'] = false;
            // this.dynamicformdata['controls']['description']['disabled'] = true;
            // this.dynamicformdata['controls']['rate']['disabled'] = true;
            // this.dynamicformdata['controls']['billrejecteditems']['disabled'] = true;
            // this.dynamicformdata['controls']['billrejecteditems']['options'][0]['disabled'] = true;
            // this.dynamicformdata['controls']['dateeffectivefrom']['disabled'] = true;
            this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
            this.dynamicformdata['css']['btnconfiguration']['disableSave'] = true;
            this.dynamicformdata['css']['btnconfiguration']['disableNew'] = false;
            this.displayMessagePopup(successResp['Data'], "Ok", true, "", false);
            // this.description = successResp['Data'];
            // this.PopupMessageModal.show();


          } else if (successResp['errorCode'] == '1') {
            this.dynamicformdata['controls']['description']['disabled'] = false;
            this.dynamicformdata['controls']['rate']['disabled'] = false;
            this.dynamicformdata['controls']['billrejecteditems']['disabled'] = false;
            this.dynamicformdata['controls']['billrejecteditems']['options'][0]['disabled'] = false;
            this.dynamicformdata['controls']['dateeffectivefrom']['disabled'] = false;
            this.dynamicformdata['css']['btnconfiguration']['disableCancel'] = false;
            this.dynamicformdata['css']['btnconfiguration']['disableSave'] = false;
            this.dynamicformdata['css']['btnconfiguration']['disableNew'] = true;
          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
        // this.errormsg = "";
        // this.showloader = true;
        // this.loaderTimeOut();

        // this.commonservices.checkUniqueNo(event['value']['currentTarget']['value'],
        //   "SubAssembly", this.configurationsetting.getBaseUrl())
        //   .subscribe(successResp => {
        //     this.showloader = false;
        //     if (successResp['errorCode'] == 0) {
        //       this.displayMessagePopup(successResp['Data'], "Ok", true, "", false);
        //     }
        //     else {
        //       this.errormsg = successResp['errorDescription'];
        //     }
        //   }, errorResp => {
        //     this.showloader = false;
        //     this.errormsg = errorResp['message']
        //   });
      }
    }
    else {

      alert("Internet Connection is Not Available.");

    }


  }
  /*checkUniquePartNo() ends here*/

  confirmPopup(event) {
    if (this.opeartionStageForDelete == "delete") {
      this.opeartionStageForDelete = "";
      this.confirmDeleteSubAssMaster();
    }
    else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
      this.getMasterTable();
      setTimeout(() => {
        this.disableForm();

      }, 500)
    }
    this.showconfirmpopup = false;
  }

  setBootstrapClasses() {
    let colName = 0;
    for (let i = 0; i < this.dynamicformdata['css']["mappingarray"].length; i++) {
      for (let j = 0; j < this.dynamicformdata['css']["mappingarray"][i].length; j++) {
        if (this.dynamicformdata['css']["mappingarray"][i][j]["colspan"] != undefined)
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]) * +this.dynamicformdata["mappingarray"][i][j]["colspan"];
          colName = +this.dynamicformdata['css']["gridinfo"]["gridcols"] * +this.dynamicformdata['css']["mappingarray"][i][j]["colspan"];
        else
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]);
          colName = +this.dynamicformdata['css']["gridinfo"]["gridcols"];
        this.dynamicformdata['css']["mappingarray"][i][j]["ColClassName"] = "col-xs-12 col-sm-12 col-md-" + colName + " col-lg-" + colName + " col-xl-" + colName;
      }
    }
  }

  //displayMessagePopup() is used to call and maintain confirmation popup modal
  displayMessagePopup(popupmessage, submittext, displaysubmit, canceltext, displaycancel) {
    this.confirmationpopupmodelobj.inputmsg = popupmessage;
    this.confirmationpopupmodelobj.submitbtntext = submittext
    this.confirmationpopupmodelobj.displaysubmit = displaysubmit;
    this.confirmationpopupmodelobj.cancelbtntext = canceltext;
    this.confirmationpopupmodelobj.displaycancel = displaycancel;
    this.showconfirmpopup = true;
  }

  //////if http not gave the response after spend 30 seconds the we will show the error.
  loaderTimeOut() {
    setTimeout(() => {
      if (this.showloader) {
        this.showloader = false;
        this.errormsg = "Error while login your account.";
      }
    }, 50000)
  }

  closePopup() {
    this.PopupMessageModal.hide();
  }
  OnchangePopup() { }

}
