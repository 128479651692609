export const LEFT_SIDE_TABLE_JSON = {
  tableconfig: {
    "dragdrop": { "rows": false, "columns": true },
    "resizable": { "rows": false, "columns": true },
    "columnwisefilter": false,
    "headercontextmenu": false,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": true,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "enablefreezepanes": false,
    "exporttoexcel": false,
    "filenameprefix": "test",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showonlysearch": true,
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "checkDeletedFlag":true,
    "checkDeletedFlagKey":"isDeleted",
    "fullheighttable":"fullheight-table"
  },
  tableheaders: [
    {
      "key": "challanno",
      "display": "Challan No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "40px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "releases",
      "display": "Release No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "challandate",
      "display": "Challan Date",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "date",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata:[]
};

export const DC_DETAILS_TABLE_JSON = {
  tableconfig:{
    "tableclasses": "table-striped horizontal-scroll table-lightblue  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": true },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
    ],
    "exporttoexcel": false,
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false, "controlsfor": {} },
    "defaultcellmenus": [{
      "label": "Quick Update",
      "action": "quickupdate"
    }]
  },
  tableheaders:  [
    {
      "key": "item",
      "display": "Part No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "modelname",
      "display": "Model",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "200px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "refrenceno",
      "display": "Refrence No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "positive":true,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "jobno",
      "display": "Job No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qty",
      "display": "Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "20px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "remark",
      "display": "Remark",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "40px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "srno1",
      "display": "Sr.No. 1",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "positive":true,
      "visiblecolm": true,
      "datatype": "string",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "srno2",
      "display": "Sr.No. 2",
      "editable": true,
      "sorting": true,
      "positive":true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "decimaldigit": 0,
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "esn",
      "display": "ESN",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "70px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "customer",
      "display": "Customer",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "120px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "engtypejeop",
      "display": "JE/OP",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "50px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
  ],
  tabledata:[]
};

export const DC_ITEMS_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": true },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [],
    "customactionbuttons": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": true,
      "selectedFieldsArray": [
        {
          "key": 'qty',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    },
    "showcolumnlevelcheckbox":true
  },
  tableheaders:  [
    {
      "key": "item",
      "display": "Item Code",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "180px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "modelname",
      "display": "Model Name",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "80px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }]
    },
    {
      "key": "jobno",
      "display": "Job No",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "positive":true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "qty",
      "display": "Quantity",
      "editable": true,
      "sorting": true,
      "allowaggregation": false,
      "positive":true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "40px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata:[]
};

export const CONFIRM_PO_TABLE_JSON = {
  tableconfig:  {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": true },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "searchPannelCheckBox": false,
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "customactionbuttons": [],
    "buttonspriority": "row",

  },
  tableheaders: [
    {
      "key": "PrNo",
      "display": "PR No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "left", "width": "70px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "PoNo",
      "display": "PO Number",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "left", "width": "70px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "150px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "item",
      "display": "Item",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata:[]
};
