export const LEFT_SIDE_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped sticky-header  horizontal-scroll table-lightblue table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": false,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": true,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "enablefreezepanes": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" },
      { "label": "Specific Search", "action": "specificsearch" }

    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false },
    "showonlysearch": true,
    "checkDeletedFlag": true,
    "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": 'fullheight-table',
  },
  tableheaders: [
    {
      "key": "invoicesrno",
      "display": "Invoice No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "InvoiceNo",
      "display": "Invoice No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "invoicedate",
      "display": "Invoice Date",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "date",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata:[]
};

export const DETAILED_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped sticky-header sorting  horizontal-scroll table-lightblue table-bordered",

    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "calculationtype": "forwardonly",
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "hidecolumns": false,
    "defaultsearch": true,
    "searchPannelCheckBox": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }
    ],
    "exporttoexcel": false,
    "filenameprefix": "test",
    "enablefirstbtn": false,
    "firstBtnStyle": {},
    "firstbtnicon": "fa fa-plus-circle mr-1",
    "firstBtnClass": "btn btn-primary btn-sm",
    "actioncolmconfig": {
      "displaycolumn": true,
      "view": false,
      "edit": false,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": true },
  },
  tableheaders: [
    {
      "key": "SerialNo",
      "display": "Sr. No.",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "90px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "itemnumber",
      "display": "Part No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "90px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "modelname",
      "display": "Model",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "Description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "200px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "jobno",
      "display": "Job No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "dcsrno",
      "display": "D/N No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    // {
    //   "key": "cdssgnno",
    //   "display": "CDSS GnNo",
    //   "editable": false,
    //   "sorting": true,
    //   "enablehide": true,
    //   "visiblecolm": true,
    //   "datatype": "string",
    //   "thstyle": { "text-align": "center", "width": "80px" },
    //   "tdalignment": "hr-left vt-middle",
    //   "menulist": [{ "label": "Filter On", "action": "filter" }],
    //   "summary": []
    // },
    {
      "key": "qty",
      "display": "Quantity",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "60px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "maxqty",
      "display": "Pending Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "decimaldigit": 0,
      "visiblecolm": false,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "60px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "subtax",
      "display": "Sub Tax",
      "editable": false,
      "sorting": false,
      "enablehide": false,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "60px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    // {
    //   "key": "igst",
    //   "display": "IGST",
    //   "editable": false,
    //   "sorting": true,
    //   "enablehide": true,
    //   "decimaldigit": 0,
    //   "visiblecolm": true,
    //   "datatype": "number",
    //   "thstyle": { "text-align": "center", "width": "60px" },
    //   "tdalignment": "hr-center vt-middle",
    //   "menulist": [{ "label": "Filter On", "action": "filter" }],
    //   "summary": []
    // },
    {
      "key": "price",
      "display": "Rate",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "decimaldigit": 2,
      "visiblecolm": true,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-right vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "amount",
      "display": "Amount",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "decimaldigit": 2,
      "visiblecolm": true,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-right vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "releaseno",
      "display": "Release",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "decimaldigit": 0,
      "visiblecolm": true,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "remark",
      "display": "Remark",
      "editable": true,
      "sorting": true,
      "enablehide": true,
      "decimaldigit": 0,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "200px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata:[]
};

export const ITEM_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped sticky-header sorting  horizontal-scroll table-lightblue  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": false,
    "stickycolumns": { "initial": 4, "maxlimit": 5 },
    "columnwisefilter": false,
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" },
      { "label": "Specific Search", "action": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "controlconfig": { "displaycontrol": true, "controlsfor": {} },
    "defaultcellmenus": [],
    "buttonspriority": "row",
    "checkedrecordscondition": {
      "ischeckcondition": false,
      "selectedFieldsArray": [
        {
          "key": 'checkbox',
          "condition": ">0"
        }
      ],
      "conditionBetweenTwoFields": 'and',
      "checkconditionbtnname": "Ok"
    },
    "fullheighttable": 'fullheight-table',
  },
  tableheaders: [
    {
      "key": "checkbox",
      "display": "",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": '',
      "datatype": "checkbox",
      "thstyle": { "text-align": "left", "width": "30px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "dcoutdetailsrno",
      "display": "Sr No",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "60px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "Description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "120px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "PartNo",
      "display": "Part No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "60px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "modelname",
      "display": "Model Name",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "60px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "releaseno",
      "display": "Release",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "decimaldigit": 0,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "dnno",
      "display": "D/N No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": false,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },{
      "key": "dcsrno",
      "display": "D/N No.",
      "coloumnwisesearch":true,
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    // {
    //   "key": "grrno",
    //   "display": "GRR No.",
    //   "editable": false,
    //   "sorting": true,
    //   "enablehide": true,
    //   "visiblecolm": false,
    //   "datatype": "string",
    //   "thstyle": { "text-align": "center", "width": "80px" },
    //   "tdalignment": "hr-left vt-middle",
    //   "menulist": [{ "label": "Filter On", "action": "filter" }],
    //   "summary": []
    // },
    {
      "key": "jobno",
      "display": "Job No.",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "center", "width": "80px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "maxqty",
      "display": "Pending Qty",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "decimaldigit": 0,
      "visiblecolm": true,
      "datatype": "number",
      "thstyle": { "text-align": "center", "width": "60px" },
      "tdalignment": "hr-left vt-middle",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    // {
    //   "key": "pono",
    //   "display": "PO No.",
    //   "editable": false,
    //   "sorting": true,
    //   "enablehide": true,
    //   "visiblecolm": true,
    //   "datatype": "string",
    //   "thstyle": { "text-align": "center", "width": "80px" },
    //   "tdalignment": "hr-left vt-middle",
    //   "menulist": [{ "label": "Filter On", "action": "filter" }],
    //   "summary": []
    // },
    {
      "key": "date",
      "display": "DC Date",
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "editable": false,
      "allowaggregation": true,
      "datatype": "date",
      "dtconfig": { "editformat": "dd-MM-yyyy" },
      "thstyle": { "text-align": "center", "width": "100px" },
      "tdalignment": "hr-center vt-middle",
      "menulist": [
        {
          "label": "Between",
          "action": "between",
          "showcelltext": true
        }
      ]
    },
    // {
    //   "key": "price",
    //   "display": "Price",
    //   "editable": false,
    //   "sorting": true,
    //   "enablehide": true,
    //   "decimaldigit": 2,
    //   "visiblecolm": true,
    //   "datatype": "number",
    //   "thstyle": { "text-align": "center", "width": "80px" },
    //   "tdalignment": "hr-left vt-middle",
    //   "menulist": [{ "label": "Filter On", "action": "filter" }],
    //   "summary": []
    // },
  ],
  tabledata:[]
};

export const REJECTEDPO_TABLE_JSON = {
  tableconfig: {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "summary": [],
    "dragdrop": { "columns": false },
    "oddrowstyle": {},
    "evenrowstyle": {},
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "enablefreezepanes": false,
    "enablefreezecolumns": true,
    "enablefreezecolumnsRight": false,
    "stickycolumns": { "initial": 0, "maxlimit": 5 },
    "headercontextmenu": true,
    "cellcontextmenu": true,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "label": "Hide Column", "action": "hidecolumn" },
      { "label": "Display Hidden Columns", "action": "displayhiddencols" }

    ],
    "exporttoexcel": false,
    "filenameprefix": "",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": false,
      "delete": false,
      "print": false
    },
    "defaultcellmenus": [
      { "label": "Quick Update", "action": "quickupdate" }
    ],
    "customactionbuttons": [],
    "buttonspriority": "row",

  },
  tableheaders: [
    {
      "key": "PoNo",
      "display": "PO Number",
      "editable": false,
      "sorting": true,
      "enablehide": true,
      "visiblecolm": true,
      "decimaldigit": 0,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "70px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "description",
      "display": "Description",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "200px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "poqty",
      "display": "PO Quantity",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "100px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "poqtybilled",
      "display": "Qty Billed",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "90px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    },
    {
      "key": "poqtybalance",
      "display": "Balance",
      "editable": false,
      "sorting": true,
      "allowaggregation": false,
      "enablehide": true,
      "visiblecolm": true,
      "datatype": "string",
      "thstyle": { "text-align": "left", "width": "70px" },
      "tdalignment": "",
      "menulist": [{ "label": "Filter On", "action": "filter" }],
      "summary": []
    }
  ],
  tabledata:[]
};
