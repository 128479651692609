import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationSetting } from '../../appconfig';
import { ApiService } from '../../services/api.service';
import { CommonServicesService } from '../../services/common-services.service';
import { HelperService } from '../../services/helper.service';
import { SubmitFormService } from '../../services/submit-form.service';
import { SystemParamsService } from '../../services/system-params.service';
import { ToastService } from '../../services/toast.service';
import { JobSheetPopupComponent } from './job-sheet-popup/job-sheet-popup.component';
import { BOM_LIST_TABLE_JSON, JOBSHEET_DETAILS_TABLE_JSON, LEFT_SIDE_TABLE_JSON, SELECT_ACTUAL_ITEM_TABLE_JSON, SELECT_JOBSHEET_TABLE_JSON, UPADATE_BOM_LIST_TABLE_JSON } from './job-sheet.tableconfig';
import { DomSanitizer } from '@angular/platform-browser';
declare var $;
@Component({
  selector: 'app-job-sheet',
  templateUrl: './job-sheet.component.html',
  styleUrls: ['./job-sheet.component.scss'],
  // template:`<app-job-sheet-popup [data]='displayjobnumwisetabledata'></app-job-sheet-popup>`
})

export class JobSheetComponent implements OnInit {

  @ViewChild('showAssembly', { static: false }) public showAssembly;
  @ViewChild('showSelectItemRowPopup', { static: false }) public showSelectItemRowPopup;
  @ViewChild('selectItemForJobSheet', { static: false }) public selectItemForJobSheet;
  @ViewChild('showSelectItemPopup', { static: false }) public showSelectItemPopup;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('showUpdateBomPopup', { static: false }) public showUpdateBomPopup;
  @ViewChild('showSelectBomRowPopup', { static: false }) public showSelectBomRowPopup;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('printPopup', { static: false }) public printPopup;
  @ViewChild('ConfirmationModal', { static: false }) public ConfirmationModal;
  @ViewChild('FreezeModal', { static: false }) public FreezeModal;
  
  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;

  selectedDate: any = [];
  showloader: boolean = false;
  showFreezedBtn:boolean = false;
  btnRow: boolean = false;
  jobSheetForm: boolean = true;
  showCloseBtn: boolean = true;
  showFormBtn: boolean = false;
  addItemButton: boolean = false;
  updateBomButton: boolean = false;
  saveVisible: boolean = false;
  editqty;
  isSaveBtnDisable: boolean = true;
  selectBtnDisable: boolean = true;
  createNewBtn: boolean = false;
  deleteMsg: string = "";
  editBtn: boolean = false;
  printBtn: boolean = false;

  saveBtn: boolean = true;
  cancelBtn: boolean = false;
  editDisableBtn: boolean = false;
  currentSelectedRow: number;
  isPopUpOpen: boolean = false;
  customerNameArray = [];
  deletedArray = [];
  deletedSpareItem = []
  deleteBtnDisable: boolean = false;
  isRowClicked: boolean = false;
  itemsrno;
  jobsheetQty;
  event = {}
  qtyValue;
  ErrorDescription: string = "";
  itemSrNo: string = "";
  dataForPrint: any = {};
  fileName: string = "";
  dataLocalUrl: any;
  isFreezed = '';
  ErrorHeader = "";
  ErrorObj: any = {};
  actualItemList: any[] = [];
  operation: string = '';
  outwardCreatedCount: number = 0;
  outwardCreatedQtySum: number = 0;

  label: string = "Job Sheet";
  newBtnLabel: string = " Add New Job Sheet";
  emptySearchField: boolean = true;
  private backupData: any = { bindDataToFormAndTable: {} };
  tabledataforprocess: any[] = [];
  updateBomList: any[] = []
  jobsheetuniqueno: number = 0;
  jobsheetQuantity: number = 0;
  itemSerialNumber: number = 0;
  deepCopyForDetailTable: any = {};
  itemNo;
  private tabledatabackup: any = [];
  showMaxQty: boolean = false;
  refreshDetailTable: boolean = true;

  model = {
    showjobno: {
      valuType: 'Number', value: '', label: "Id", isRequired: false,
      err: "", element: { name: 'input', type: 'number' }
    },
    dcsrno: {
      valuType: 'Number', value: '', label: "Id", isRequired: false,
      err: "", element: { name: 'input', type: 'number' }
    },
    JobCardSrNo: {
      valuType: 'Number', value: '', label: "Job card no", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'number' }
    },
    ItemSrNo: {
      valuType: 'Number', value: '', label: "Id", isRequired: false,
      err: "", element: { name: 'input', type: 'number' }
    },
    dcno: {
      valuType: 'Number', value: '', label: "Id", isRequired: false,
      err: "", element: { name: 'input', type: 'number' }
    },
    jobcardno: {
      valuType: 'String', value: '', label: "Job Card No", disable: true,
      isRequired: false, maxLength: 8, err: "", apiKey: "jbNo",
      element: { name: 'input', type: 'text' }
    },
    selectbtn: {
      valuType: 'String', value: '', disable: true,
    },
    jobsheetdate: {
      valuType: 'Date', value: '', disable: true, label: "Job Sheet Date",
      isRequired: true, err: "", element: { name: 'input', type: 'text' }
    },
    itemname: {
      valuType: 'String', value: '', label: "Item Name", disable: true, isRequired: false,
      err: "", element: { name: 'input', type: 'text' }
    },
    jmdescription: {
      valuType: 'String', value: '', label: "Sub Assembly Type", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    model: {
      valuType: 'String', value: '', label: "Model", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    aitemname: {
      valuType: 'String', value: '', label: "Item Name", disable: true, isRequired: false,
      err: "", element: { name: 'input', type: 'text' }
    },
    ajmdescription: {
      valuType: 'String', value: '', label: "Sub Assembly Type", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    amodel: {
      valuType: 'String', value: '', label: "Model", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    refrenceno: {
      valuType: 'String', value: '', label: "Reference Number", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    jobno: {
      valuType: 'String', value: '', label: "Job Number", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    dnno: {
      valuType: 'String', value: '', label: "DN Number", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    jmremark: {
      valuType: 'String', value: '', label: "Remark", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    quantity: {
      valuType: 'Number', value: 0, label: "Quantity", disable: true,
      isRequired: true, err: "", element: { name: 'input', type: 'number' }
    },
    oldquantity: {
      valuType: 'Number', value: 0, label: "Old Quantity", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'number' }
    },
    maxquantity: {
      valuType: 'Number', value: 0, label: "Maximum Quantity", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'number' }
    },
    pendingoutqty: {
      valuType: 'Number', value: 0, label: "Maximum Quantity", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'number' }
    },
    esn: {
      valuType: 'String', value: '', label: "ENV", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    customerNo: {
      valuType: 'Number', value: '', label: "Customer Name", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    actualItemNo: {
      valuType: 'Number', value: '', label: "Actual Item No", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    engtypejeop: {
      valuType: 'String', value: '', label: "JE/OP", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    dcdetailsrno: {
      valuType: 'String', value: '', label: "SrNo", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  jobsheettableconfig: any = LEFT_SIDE_TABLE_JSON.tableconfig;
  jobsheettableheaders: any = LEFT_SIDE_TABLE_JSON.tableheaders;
  jobsheettabledata: any = [];

  joblisttableconfig: any = JOBSHEET_DETAILS_TABLE_JSON.tableconfig;
  joblisttableheader: any[] = JOBSHEET_DETAILS_TABLE_JSON.tableheaders;
  joblisttabledata: any[] = [];

  // Select Job sheet btn table data
  displayjobnumwisetableconfig: any = SELECT_JOBSHEET_TABLE_JSON.tableconfig;
  displayjobnumwisetableheaders: any = SELECT_JOBSHEET_TABLE_JSON.tableheaders;
  displayjobnumwisetabledata: any = [];

  jobsheetactualitemdataconfig: any = SELECT_ACTUAL_ITEM_TABLE_JSON.tableconfig;
  jobsheetactualitemdataheader: any = SELECT_ACTUAL_ITEM_TABLE_JSON.tableheaders;
  jobsheetactualitemtabledata: any[] = [];


  bomlisttableconfig: any = BOM_LIST_TABLE_JSON.tableconfig;
  bomlisttableheader: any = BOM_LIST_TABLE_JSON.tableheaders;
  bomlistdata: any = [];

  updateBomTableconfig: any = UPADATE_BOM_LIST_TABLE_JSON.tableconfig;
  updateBomTableheader: any = UPADATE_BOM_LIST_TABLE_JSON.tableheaders;
  updateBomTableData: any[] = [];

  constructor(private submit: SubmitFormService, private apidata: ApiService, private domSanitizer: DomSanitizer,
    public commonService: CommonServicesService, private configurationsetting: ConfigurationSetting, public systemParamsService: SystemParamsService, private helper: HelperService, private ngbModal: NgbModal, private toastService: ToastService,
    private ngbActiveModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.isOpenFromReport) {
      this.getByIdClickCall(this.transactionID)
    }

    this.getSearchAllJobSheetData();
    this.getCustomerName();
    this.displayJobNoWise();
    console.log(this.commonService.userRole);
    
  }

  openNewJobSheetPopup() {
    // let ele = document.getElementById('loader-div');
    // console.log('ele', ele);
    // ele.style.display = "block";

    const ngbModalRef = this.ngbModal.open(JobSheetPopupComponent, { windowClass: "allocate-modal-pop", backdrop: 'static' });
    ngbModalRef.componentInstance.displayjobnumwisetabledata = this.displayjobnumwisetabledata;
    ngbModalRef.result.then((result) => {
      console.log(result);

      if (result && result?.status == "select") {
        this.displayJobNoWiseDataBind(result.data)
      }
    });
  }

  enableFieldsToCreateNewJobSheet() {
    this.emptySearchField = false;
    this.label = "Add Job Sheet";
    this.newBtnLabel = "Add Job Sheet";
    this.selectBtnDisable = false;
    this.operation = '';
    this.createNewBtn = true;
    this.saveBtn = true;
    this.editBtn = false;
    this.printBtn = false;
    this.deleteBtnDisable = false;
    this.isSaveBtnDisable = false;
    this.saveVisible = true;
    this.cancelBtn = true;
    this.showFreezedBtn = false;
    this.addItemButton = true;
    this.btnRow = true;
    this.deleteMsg = "";
    
    this.joblisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.selectedDate = new Date();
    this.model.jobsheetdate.value = this.selectedDate;
    this.commonService.enableFormFields(this.model);
    this.model.jmremark.value = " "
    const ele = document.getElementById(`tr${"jobsheettbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    this.joblisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.joblisttableheader = this.commonService.enableEditForGt(this.joblisttableheader, "qty", true);
    // this.joblisttableheader = this.commonService.enableEditForGt(this.joblisttableheader, "remark", false);

    delete this.backupData.bindDataToFormAndTable;

    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  confirmQtyWhileSaving() {

    if (this.tabledataforprocess.length) {
      this.joblisttabledata = this.tabledataforprocess;
    }
    let isQtyZero = false;
    let count = 0;

    if(!this.joblisttabledata) this.joblisttabledata = [];

      for (let i = 0; i < this.joblisttabledata.length; i++) {
        if (this.joblisttabledata[i]['qty'] <= 0) {
          isQtyZero = true
          count++;
        }
      }


    if (count > 0) {
      this.ConfirmationModal.show();
    } else {
      this.saveJobSheetFormData()
    }
  }

  yesToSaveJobsheet() {
    let jobListHavingQty = []
    if (this.tabledataforprocess.length) {
      this.joblisttabledata = this.tabledataforprocess;
    }
    if(!this.joblisttabledata) this.joblisttabledata = [];

    for (let i = 0; i < this.joblisttabledata.length; i++) {
      if (this.joblisttabledata[i]['qty'] > 0) {
        jobListHavingQty.push(this.joblisttabledata[i]);
      }
    }
    this.joblisttabledata = []
    this.joblisttabledata = jobListHavingQty;

    this.saveJobSheetFormData();
    this.ConfirmationModal.hide();
  }

  saveJobSheetFormData() {
    let data = this.submit.submitForm(this.model);

    if (data) {
      this.isSaveBtnDisable = true;
      if(!this.joblisttabledata) this.joblisttabledata = [];

      let editedRow = [];
      if (this.model.quantity.value > this.model.maxquantity.value) {
        this.toastService.showToastMsg('warning', 'Warning', "Quantity should not be greater than max quantity.");
        return;
      }
      for (let i = 0; i < this.joblisttabledata.length; i++) {
        if (this.joblisttabledata[i]?.rowmodifiedstatus) {
          delete this.joblisttabledata[i].rowmodifiedstatus;
          editedRow.push(this.joblisttabledata[i]);
        }
        this.joblisttabledata[i]["ItemSrNo"] = data['criteria']['ItemSrNo'];
      }
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray;
      }
      this.deletedArray = [];

      this.commonService.deleteExtraFlagsFromTable(this.joblisttabledata);

      let endpoint = 'transaction/insertJobSheet';
      if (data["criteria"]["JobCardSrNo"]) {
        if (!data["criteria"]['esn']) {
          data["criteria"]['esn'] = null;
        }
        if (!data["criteria"]['jmremark']) {
          data["criteria"]['jmremark'] = null;
        }
        if (!data["criteria"]['engtypejeop']) {
          data["criteria"]['engtypejeop'] = null;
        }
        endpoint = 'transaction/updateJobSheet';
        data["criteria"]["updatedRow"] = editedRow;
        this.ErrorDescription = "Data Updated Successfully";
        data["criteria"]["jobsheetitems"] = editedRow;
      } else {
        data["criteria"]["updatedRow"] = editedRow;
        // data["criteria"]["jobsheetitems"] = this.joblisttabledata;
        data["criteria"]["jobsheetitems"] = this.joblisttabledata;
        this.ErrorDescription = "Data Inserted Successfully";
      }

      console.log('payload', data);
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.jobsheetuniqueno = res['Data'];
          this.saveBtn = true;
          this.deleteBtnDisable = false;
          this.label = "Job Sheet";
          this.newBtnLabel = "Add New Job Sheet";
          this.selectBtnDisable = true;
          this.addItemButton = false;
          this.cancelBtn = false;
          this.showFreezedBtn = false;

          this.createNewBtn = false;
          this.isSaveBtnDisable = true;
          this.saveVisible = false;
          this.editBtn = true;
          this.printBtn = true;
          this.commonService.diasbleFormFields(this.model);
          this.joblisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.joblisttableheader = this.commonService.enableEditForGt(this.helper.removeRefrence(this.joblisttableheader), "qty", false);
          this.joblisttableheader = this.commonService.enableEditForGt(this.helper.removeRefrence(this.joblisttableheader), "remark", false);
          this.getSearchAllJobSheetData();
          this.displayJobNoWise();
          this.operation = '';
          this.PopupMessageModal.show();
          this.showFreezedBtn = true;
        } else {
          this.isSaveBtnDisable = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }

    // console.log(this.emptyArrayToStoreJobSheetData, data);
  }

  alertNotToDeleted() {
    if (this.outwardCreatedCount > 0) {
      this.ErrorDescription = 'Can not delete Jobsheet. Because outward transaction has been created for this Jobsheet.';
      this.PopupMessageModal.show();
    } else {
      this.deleteJobSheetForm()
    }
  }

  deleteJobSheetForm() {
    let data = this.submit.submitForm(this.model);

    if (data || data["criteria"]["JobCardSrNo"]) {
      data["criteria"]["jobsheetitems"] = this.tabledataforprocess;

      let items: any = {};
      items.JobCardSrNo = data["criteria"]["JobCardSrNo"];
      items.customerNo = data["criteria"]["customerNo"];

      items.delLineItems = [];
      items.deleteSpareItem = [];
      items.SpareNo = [];

      data["criteria"]["jobsheetitems"].forEach((ele) => {
        if (ele?.SrNo || ele?.SpareNo || ele?.iSrNo) {
          if (ele?.SrNo) {
            items.delLineItems.push(ele?.SrNo);
          } else {
            if(!items.delLineItems.length){
              items.delLineItems = [];
            }
          }
          items.SpareNo.push(ele?.SpareNo);
          items.deleteSpareItem.push(ele?.iSrNo);
        }
      })

      data["criteria"] = items;

      // console.log("delLineItems", items.delLineItems);
      // console.log("deleteSpareItem", items.deleteSpareItem);

      let endpoint = 'transaction/deleteJobSheet';

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('closePopupDelete')
          this.saveBtn = true;
          this.deleteBtnDisable = false;
          this.clearAll(false);
          this.showFreezedBtn = false;
          this.getSearchAllJobSheetData()
          this.displayJobNoWise();
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        }
      }, (err) => { });
    }
  }

  bindDataToFormAndTable(modal, flag: boolean = true) {
    // console.log(modal);
    if (this.model.customerNo.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");

      return;
    }
    this.refreshDetailTable = false;
    this.updateBomButton = false;
    this.showFreezedBtn = true;
    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    this.model.showjobno.value = modal['jobSrNo'];
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    this.itemSerialNumber = modal['itemnumber'];
    this.outwardCreatedCount = modal['outwardcreatedCount'];
    this.outwardCreatedQtySum = modal['outwardcreatedQtySum'];
    this.isFreezed = modal['Freezed']
    // this.getBomList(this.itemSerialNumber);

    if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        console.log(this.model[item].value);

        this.joblisttabledata = [];
        this.btnRow = false;
        // this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"jobsheettbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }

    

    if (modal['esn'] == null) modal['esn'] = "";
    if (modal['engtypejeop'] == "null") modal['engtypejeop'] = "";
    if (modal['jmremark'] == "null") modal['jmremark'] = "";


    this.joblisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.joblisttableheader = this.commonService.enableEditForGt(this.helper.removeRefrence(this.joblisttableheader), "qty", false);
    this.joblisttableheader = this.commonService.enableEditForGt(this.helper.removeRefrence(this.joblisttableheader), "remark", false);
    this.joblisttabledata = modal['jobsheetitems']?.length ? modal['jobsheetitems'] : [];

    if (!this.joblisttabledata.length) {
      this.tabledataforprocess = [];
    }

    let newArr = this.joblisttabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.joblisttabledata = newArr;
    setTimeout(() => {
      this.refreshDetailTable = true;
    }, 10);
    this.tabledatabackup = this.helper.removeRefrence(this.joblisttabledata);
    // console.log(newArr);
    this.editBtn = true;
    this.printBtn = true;
    this.label = "Display Job Sheet";
    this.isSaveBtnDisable = true;
    this.saveVisible = false;
    this.cancelBtn = false;
    this.editDisableBtn = false;
    this.createNewBtn = false;
    this.addItemButton = false;
    this.selectBtnDisable = true;
    this.deleteBtnDisable = false;

    this.commonService.diasbleFormFields(this.model);

    this.isRowClicked = false;
    console.log(modal);

    this.itemNo = modal['itemnumber'];
    if(this.isFreezed == 'Y'){
      this.editBtn = false;
      this.printBtn = true;

      this.showFormBtn = false;
      this.deleteMsg = `This Jobsheet is freezed`
    }else{
      this.btnRow = true;
      this.deleteMsg = '';
    }

    if(this.commonService.userRole == 'Admin'){
      this.editBtn = true;
    }
  }

  getJobById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.customerNo.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.event = modal;
    let row = modal['row'];
    let jobno = row['JobCardSrNo'];
    // let jobno = row['jobSrNo'];
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.jobsheettabledata, modal.rowindex, this.currentSelectedRow, "jobsheettbl1");

    this.getByIdClickCall(jobno);
  }

  getByIdClickCall(jobno) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getJobSheetById";
    let params = { JobCardSrNo: jobno };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        // const item = res['Data']['jobsheetitems'];
        const item = res['Data'];
        console.log({item});
        

        this.bindDataToFormAndTable(item);
        this.dataForPrint = item;

        // this.joblisttabledata = [];
        // this.joblisttabledata = item["jobsheetitems"];
      }
    }, (error) => {
      console.log(error);
    });
  }

  printPdf() {
    this.downloadPdf(this.dataForPrint);
  }

  downloadPdf(invoicedata) {
    if (invoicedata['dcdetailsrno'] == 'undefined') {
      invoicedata['dcdetailsrno'] = "";
    }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/generatejobsheetpdf";
    let body = { "criteria": { "transId": this.model.JobCardSrNo.value } }

    this.commonService.postMethod(apiUrl, body).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        console.log("success");
        this.fileName = successResp['Data']['filename'];

        this.downloadFile(this.fileName)
      }
      else {
        this.ErrorDescription = "Something went wrong, please contact admin";
      }
    },
      err => {
        this.ErrorDescription = "Something went wrong, please contact admin";
      });
  }

  downloadFile(filename: string) {
    let body = { "filename": filename }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/downloadpdf";
    this.commonService.getFile(apiUrl, body) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        // saveAs(blob, this.fileName);
        // setTimeout(() => {
        let a = document.createElement('a');

        let file = new Blob([blob], { type: 'application/pdf' });
        console.log("file ", file);

        let fileURL = URL.createObjectURL(file);


        this.dataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
        a.download = this.fileName;
        this.printPopup.show();
        // }, 250);

        this.fileName = "";
      })
      .catch((err) => {
        console.log("Something went wrong");
      });

  }

  displayJobNoWiseDataBind(modal) {
    let row = modal['row'];
    let arr: any = [];
    if (row['SrNo1'] || row['SrNo2']) {
      arr.push(row['SrNo1'], row['SrNo2']);
      this.model.dcdetailsrno.value = arr;
    } else {
      this.model.dcdetailsrno.value = "";
    }

    this.model = this.commonService.setFromKeysValues(this.model, row);
    this.jobsheetQuantity = row.quantity;

    this.jobsheetQty = row.quantity;
    if (row.ItemSrNo) {
      this.getListForJobSheet(row.ItemSrNo, "selectjob")
      this.updateBomButton = false;
    }
    // this.cancelPopup('JobSheetFormPopupClose')
  }

  getListForJobSheet(ItemSrNo: any, datafrom) {
    let params = { itemNo: ItemSrNo };
    console.log(params);

    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/dispalyJobNoWise";

    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']["jobsheetitems"];
        this.joblisttabledata = item;
        // let deepCopy = this.helper.removeRefrence(item);
        // this.filterListTableIfExistingSubTable(deepCopy, item);
        this.updateQuantityInDetailTable(this.joblisttabledata, this.jobsheetQuantity);
        if (datafrom == 'editjob') {
          let newArr = [];
          console.log(this.tabledataforprocess, item);
          if (item) {
            item.forEach((data) => {
              // newArr = item.filter((item) => {
              //   return item.iSrNo !== data.iSrNo;
              // })
              let flag = true;


              this.tabledataforprocess.forEach((itemdata) => {
                if (data['iSrNo'] == itemdata['iSrNo']) {
                  flag = false;
                }
              })

              if (flag)
                newArr.push(data);
            })
          }

          this.joblisttabledata = [...this.tabledataforprocess, ...newArr];

        }
      }
    }, (error) => {
      console.log(error);
    });

  }
  qtymessage = ''
  qtyCheck() {
    console.log(this.model.quantity.value);

    this.jobsheetQuantity = this.model.quantity.value ? this.model.quantity.value : 0;
    if (this.model.quantity.value > this.model.maxquantity.value) {
      this.qtymessage = `Max qty all for Jobsheet is ${this.model.maxquantity.value}. Qty should not be greater than ${this.model.maxquantity.value}`
    } else if (this.model.quantity.value < this.outwardCreatedQtySum) {
      this.qtymessage = `Outward of ${this.outwardCreatedQtySum} qty has created. So qty should be greater than ${this.outwardCreatedQtySum}.`
    } else if (this.model.quantity.value == 0){
      this.qtymessage = `Enter Valid quantity.`
    } else {
      this.qtymessage = ``;
    }
    if (this.jobsheetQuantity || this.jobsheetQuantity == 0) this.updateQuantityInDetailTable(this.tabledataforprocess, this.jobsheetQuantity);
  }

  updateQuantityInDetailTable(data: any, qty: number) {
    let updatedQuantity = 0;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        data[i]['qty'] = data[i]['unitqty'] * qty;
        if (data[i]['qty'] > data[i]['WIP']) {
          data[i]['qty'] = 0;
        }
        updatedQuantity = data[i]['qty'];
        data[i]['rowmodifiedstatus'] = 'M';
      }
    }
    return updatedQuantity;
  }

  getItemListForPopup() {
    let ele = document.getElementById('loader-div');
    // console.log('ele', ele);
    ele.style.display = "block";
    const endpoint = 'transaction/getActualItemsForJS';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];


        this.jobsheetactualitemtabledata = []
        this.jobsheetactualitemtabledata = items;

        this.isPopUpOpen = true;
        ele.style.display = "none";

        this.showSelectItemRowPopup.show();
      }
    }, err => { })
  }

  getSelectBomForPopup() {
    const endpoint = 'transaction/getSpareMaster';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.bomlistdata = []
        this.bomlistdata = items;

        this.isPopUpOpen = true;
        this.showSelectBomRowPopup.show();
      }
    }, err => { })
  }


  selectJobSheetList(itemdata: any) {
    let row = itemdata['row'];
    console.log(row);

    // let qtyArr = [];
    // itemdata.forEach((item) => {
    //   srnoArr.push(item['SrNo']);
    //   qtyArr.push(item['qty']);
    // })
    this.model.aitemname.value = row['PartNo'];
    this.model.ajmdescription.value = row['description'];
    this.model.amodel.value = row['modelname'];

    this.model.actualItemNo.value = row['SrNo'];
    this.getActualItemList(row['SrNo'])

    this.cancelPopup('closeSelectItemRowPopup');
  }

  getActualItemList(list) {
    const endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getActualItemList';
    let param = { itemlist: list }
    this.commonService.getWithParams(endpoint, param).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        let itemList = successResp['Data'];
        itemList = this.helper.removeRefrence(itemList);
        this.tabledataforprocess = [];
        itemList.forEach((item) => {
          item['qty'] = item['unitqty'] * +this.jobsheetQuantity;
          if (item['qty'] > item['WIP']) {
            item['qty'] = 0;
          }
        })
        this.joblisttabledata = [...this.tabledataforprocess, ...itemList];
        // itemList.forEach((item)=>{
        // })

      }
    }, err => { })
  }
  updateBomPopupList(itemdata) {

    this.isSaveBtnDisable = false;
    this.saveVisible = true;
    this.saveBtn = false;
    itemdata = this.helper.removeRefrence(itemdata);
    let selectedArray = [...this.tabledataforprocess];
    this.updateBomTableData = []

    for (let item of itemdata) {
      let flag = true;
      this.updateBomTableData.forEach(element => {
        if (element['SrNo'] == item['SrNo']) {
          flag = false;
          // element['qty'] = item['qty'];
          // element = item;
        }

      });
      if (flag)
        selectedArray.push(item);
    }

    this.cancelPopup('closeSelectBomRowPopup');
    const temp = [...this.updateBomTableData, ...selectedArray];
    this.updateBomTableData = this.helper.removeRefrence(temp);

  }

  saveUpdateBomToDetailTbl(itemdata) {

    this.isSaveBtnDisable = false;
    this.saveVisible = true;
    this.saveBtn = false;

    let deppCopyPoDetailTable = this.helper.removeRefrence(this.tabledataforprocess);
    itemdata = this.helper.removeRefrence(itemdata);

    itemdata.forEach((item) => {
      const { PartNo, description, unitqty, CurrentBalance, WIP, qty } = item;
      deppCopyPoDetailTable.push({ PartNo, description, unitqty, CurrentBalance, WIP, qty });
    })

    this.joblisttabledata = deppCopyPoDetailTable;
    this.cancelPopup('closeUpdateBomPopup');
  }


  priviousQty;
  updatedtabledata(data: any) {
    this.tabledataforprocess = data;
    let itemNo = data[0]?.['ItemSrNo'];
    data.forEach((item, i) => {
      item["ItemSrNo"] = itemNo;
      // if(this.operation == 'edit'){
      //   item['rowmodifiedstatus'] = 'M';
      // }
      if (this.tabledatabackup[i] && this.operation == 'edit') {
        this.priviousQty = this.tabledatabackup[i]["qty"]
        item['originalqty'] = this.priviousQty;
        const wip = this.tabledatabackup[i]["WIP"]

        item['total'] = this.priviousQty + wip;
        console.log("item['total']", item['total']);

        if (item['qty'] > item['total']) {
          item['qty'] = this.tabledatabackup[i]["qty"];
        }
      } else {
        item['total'] = item['WIP']
        if (item['qty'] > item['total']) {
          item['qty'] = this.tabledatabackup[i]["qty"];
        }
      }

    })
    console.log("updated ", this.tabledataforprocess);

    this.updateBomList = this.helper.removeRefrence(data);
  }

  rowChangeOrUpdate(data: any) {
    // this.tabledataforprocess = [];
    data.row.rowmodifiedstatus = 'M';
    console.log('change or update', data);
    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.total) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data?.row?.originalqty;
      data.row.qty = this.priviousQty ? this.priviousQty : 0;
      this.errorMessageModal.show();
    }

  }

  rowChangeOrUpdateForActualItem(data: any) {
    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.WIP) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.qty = 0;
      this.errorMessageModal.show();
    }

  }

  rowChangeOrUpdateBomList(data: any) {
    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.WIP) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.qty = 0;
      this.errorMessageModal.show();
    }
  }




  addItemBtn() {
    this.getItemListForPopup()
    // this.itemList.forEach((data)=>{
    //   this.jobsheetactualitemtabledata = data;
    // })
    // this.showSelectItemRowPopup.show();
  }

  addItemBtnForBom() {
    this.getSelectBomForPopup()
    // this.showSelectBomRowPopup.show();
  }

  updateBomBtn() {
    // const temp = [...this.updateBomList];
    // this.updateBomTableData = this.helper.removeRefrence(temp);
    this.getBomList(this.itemSerialNumber)
    this.showUpdateBomPopup.show();
  }

  getBomList(id: number) {
    let params = { "itemno": id };
    let endpoint = this.configurationsetting.getBaseUrl() + 'transaction/UpdateBomListForJobSheet';
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.updateBomTableData = [];
        this.updateBomTableData = items;
        let deepCopy = this.helper.removeRefrence(items);
        this.filterListTableIfExistingSubTable(deepCopy, this.updateBomTableData);
      }
    }, err => { })
  }

  filterListTableIfExistingSubTable(deepCopyForDetailTable, tableList) {
    let preparedarr = [];
    preparedarr = deepCopyForDetailTable.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < this.joblisttabledata.length; ind++) {
        if (this.joblisttabledata[ind]['PartNo'] == row['PartNo']) {
          recavailable = false;
        }
      }
      return recavailable;
    });
    tableList = []
    tableList = preparedarr;

  }


  getJobSheetData() {
    let endpoint: string = `jobsheet/jobget`;

    this.apidata.getData(endpoint).subscribe((value) => {
      // this.emptyArrayToStoreJobSheetData = value['data'];
      // console.log(value['data']);
    }, (error) => {
      console.log(error);
    })
  }

  getSearchAllJobSheetData() {
    const endpoint = 'transaction/getAllJobSheet';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];

        this.jobsheettabledata = []
        this.jobsheettabledata = items;

        for (let i = 0; i < items.length; i++) {
          if (items[i]['JobCardSrNo'] == this.jobsheetuniqueno || items[i]['JobCardSrNo'] == this.model.JobCardSrNo.value) {
            // console.log("Index", items[i], i);
            this.model.JobCardSrNo.value = items[i]['JobCardSrNo'];
            this.getByIdClickCall(this.model.JobCardSrNo.value);
            if (!this.isOpenFromReport) {
              setTimeout(() => {
                $(`tr[tabindex=${i}]`).focus();
                document.getElementById('trjobsheettbl1' + i).style.backgroundColor = "#7de4f0";
              }, 900);
            }
            break;
          }
        }

        this.isPopUpOpen = true;

      }
    }, err => { })
  }

  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }

  displayJobNoWise() {
    let params = { "formname": "forjobsheet" };
    let endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getDataFromDcInward';
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.displayjobnumwisetabledata = [];
        this.displayjobnumwisetabledata = items;

      }
    }, err => { })
  }



  // Assembly popup
  showAssemblyPopup() {
    this.showAssembly.show();
  }

  // Select Item Popup
  showSelectItem() {
    this.showSelectItemPopup.show();
  }

  showSelectJobWisePopup() {
    this.selectItemForJobSheet.show();
  }

  // Cancel all Popup
  cancelPopup(whichForm) {
    this.jobsheetactualitemtabledata = [];
    this.isPopUpOpen = false;

    switch (whichForm) {
      case 'assemblyClose':
        this.showAssembly.hide();
      case 'closeSelectItemPopup':
        this.showSelectItemPopup.hide();
      case 'closeSelectItemRowPopup':
        this.showSelectItemRowPopup.hide();
      case 'selectItemForJobSheetClose':
      // this.selectItemForJobSheet.hide();
      case 'closePopupDelete':
        this.showDeleteModal.hide();
      case 'closeUpdateBomPopup':
        this.showUpdateBomPopup.hide();
      case 'closeSelectBomRowPopup':
        this.showSelectBomRowPopup.hide()
    }
  }
  editForm() {
    this.joblisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.joblisttableheader = this.commonService.enableEditForGt(this.joblisttableheader, "qty", true);
    this.joblisttableheader = this.commonService.enableEditForGt(this.joblisttableheader, "remark", true);
    this.label = "Edit Job Sheet";
    this.isSaveBtnDisable = false;
    this.cancelBtn = true;
    this.addItemButton = true;
    this.selectBtnDisable = false;
    this.editDisableBtn = true;
    this.deleteBtnDisable = true;
    this.createNewBtn = true;
    this.saveVisible = true;

    this.updateBomButton = true;

    this.editBtn = false;
    this.printBtn = false;
    
    this.operation = 'edit';

    console.log("edit", this.tabledataforprocess);
    // console.log(this.tabledataforprocess);

    this.tabledataforprocess.forEach((item) => {
      console.log(item.qty);
      // if (this.model.quantity.disable == false) {
      item.qty = item?.qty
      // console.log(item.qty);

      // item.rowmodifiedstatus = "M";
      // }
    })

    this.commonService.enableFormFields(this.model);
    let itemSrNo;
    if (this.model.actualItemNo.value) {
      itemSrNo = this.model.actualItemNo.value;
    } else {
      itemSrNo = this.itemNo;
    }
    this.showFreezedBtn = false;
    this.getListForJobSheet(itemSrNo, "editjob");

  }

  clearAll(flag: boolean = true) {
    console.log('editBtn', this.editBtn, 'editDisableBtn', this.editDisableBtn);

    this.commonService.clearForm(this.model);
    this.joblisttabledata = [];
    this.label = "Job Sheet";
    this.newBtnLabel = "Add New Job Sheet";
    this.selectBtnDisable = true;
    this.addItemButton = false;
    this.cancelBtn = false;
    this.btnRow = true;
    this.deleteMsg = "";
    this.operation = '';
    this.qtymessage = '';
    this.createNewBtn = false;
    this.isSaveBtnDisable = true;
    this.saveVisible = false;
    this.commonService.diasbleFormFields(this.model);
    this.joblisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    const ele = document.getElementById(`tr${"jobsheettbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';

    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false)
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;
      this.printBtn = false;
    }

  }

  cancleForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
        this.operation = '';

      } else {
        this.clearAll(true);
        if (this.saveBtn) {
          this.createNewBtn = false;
          this.operation = '';

        }
      }
    }
  }

  deleteRow(event) {
    // console.log(event);
    let id = event['row'];
    console.log(id);
    this.tabledataforprocess.splice(event.rowindex, 1);
    this.pushInDeletedArray(event.row, 'SrNo');
    this.pushInDeletedArraySpare(event.row, 'iSrNo');
  }

  deleteBomList(event) {
    let id = event['row']['srno'];
    const tempdata = this.helper.removeRefrence(this.updateBomTableData);

    tempdata.splice(event.rowindex, 1);
    this.updateBomTableData = [];
    this.updateBomTableData = tempdata;
  }

  pushInDeletedArraySpare(row, key) {
    console.log(row);

    this.deletedSpareItem.push(row[key])
  }
  pushInDeletedArray(row, key) {
    console.log(row[key]);

    if (row[key]) {
      this.deletedArray.push(row[key])
    } else {
      this.deletedArray = [];
    }
  }

  closeErrorPopup() {
    this.showDeleteModal.hide();
    this.backendErrorMessageModal.hide();
    this.PopupMessageModal.hide();
    this.errorMessageModal.hide()
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  closeModal() {
    this.ngbActiveModal.close();
  }

  confirmFreezeJobsheet(){
    if(this.model.pendingoutqty.value == 0){
      this.FreezeModal.show()
    }else{
      this.PopupMessageModal.show();
      this.ErrorDescription = 'Can not freeze this jobsheet, because outward of this jobsheet not done.'
    }
  }

  freezeJobsheet(){
    let endpoint = 'transaction/freezeJobsheet';
    let data = {"criteria":{
      jobsheetNo: this.model.JobCardSrNo.value
    }
    }
    this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        this.FreezeModal.hide();
        this.getSearchAllJobSheetData();
      } else {
        this.backendErrorMessageModal.show();
      }
    }, (err) => { });
  }


}
