import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { LoginComponent } from './component/login/login.component';
import { DataSharingService } from './component/gt-plus/data-sharing.service';
import { ExportAsService } from 'ngx-export-as';
import { ObjServiceService } from './services/obj-service.service';
import { CommonServicesService } from './services/common-services.service';
import { ConfigurationSetting } from './appconfig';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddHeadersService } from './services/add-headers.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisplayErrorComponent } from './component/display-error/display-error.component';
import { ShowLoaderComponent } from './component/show-loader/show-loader.component';
import { SubAssemblyMasterComponent } from './component/sub-assembly-master/sub-assembly-master.component';
import { GtPlusComponent } from './component/gt-plus/gt-plus.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { CustomEvents } from './component/gt-plus/events';
import { GtPlusDataService } from './component/gt-plus/gt-plus-data.service';
// import { DataTableModule } from 'angular2-datatable';
import { DynamicformComponent } from './component/dynamicform/dynamicform.component';
import { EventlistenerDirective } from './component/gt-plus/eventlistener.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SpareMasterComponent } from './component/spare-master/spare-master.component';
import { ModelMasterComponent } from './component/model-master/model-master.component';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { HighlightPipe } from './component/gt-plus/highlight.pipe';
import { ItemMasterComponent } from './component/item-master/item-master.component';
import { CustomerMasterComponent } from './component/customer-master/customer-master.component';
import { TaxMasterComponent } from './component/tax-master/tax-master.component';
import { PoMasterComponent } from './component/po-master/po-master.component';
import { DeliveryChallanInwardComponent } from './component/delivery-challan-inward/delivery-challan-inward.component';
import { ManualInwardNoteComponent } from './component/manual-inward-note/manual-inward-note.component';
import { PopupComponentComponent } from './component/popup-component/popup-component.component';
import { JobSheetComponent } from './component/job-sheet/job-sheet.component';
import { PrintjobsheetComponent } from './component/printjobsheet/printjobsheet.component';
import { ReRepairsComponent } from './component/re-repairs/re-repairs.component';
import { UserMasterComponent } from './component/user-master/user-master.component';
import { GtPlusModule } from './component/gt-plus/gt-plus.module';
import { AuthGuard } from './services/auth.guard';
import {SharedModule} from './shared/shared.module';



import { SpareInwardComponent } from './component/spare-transaction/spare-inward/spare-inward.component';
import { SpareOutwardComponent } from './component/spare-transaction/spare-outward/spare-outward.component';
import { SpareIssuedComponent } from './component/spare-transaction/spare-issued/spare-issued.component';
import { SpareRecieptComponent } from './component/spare-transaction/spare-reciept/spare-reciept.component';
import { SpareDiscrepancyComponent } from './component/spare-transaction/spare-discrepancy/spare-discrepancy.component';
import { SpareRequisitionComponent } from './component/spare-transaction/spare-requisition/spare-requisition.component';
import { JobSheetPopupComponent } from './component/job-sheet/job-sheet-popup/job-sheet-popup.component';
import { InvoiceComponent } from './component/invoice/invoice.component';
import { ToastrComponent } from './views/toastr/toastr.component';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { ReportComponent } from './component/report/report.component';
import { RoutineDcComponent } from './component/material-outward-compo/routine-dc/routine-dc.component';
import { DcOutwardComponent } from './component/material-outward-compo/dc-outward/dc-outward.component';
import { RejectedOutComponent } from './component/material-outward-compo/rejected-out/rejected-out.component';
import { ManualDnComponent } from './component/material-outward-compo/manual-dn/manual-dn.component';
import { UtiliyComponent } from './component/utiliy/utiliy.component';
import { AccessControlDirective } from './directives/access-control.directive';
import { TreeModule } from '@circlon/angular-tree-component';
import { MailSysparamsComponent } from './component/mail-sysparams/mail-sysparams.component';
import { FinancialYearComponent } from './component/financial-year/financial-year.component';
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AddHeadersService, multi: true },
];
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ContextMenuModule.forRoot(),
    // DataTableModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    GtPlusModule,
    SharedModule,
    ToasterModule,
    TreeModule,
    ReactiveFormsModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    DisplayErrorComponent,
    ShowLoaderComponent,
    SubAssemblyMasterComponent,
    // GtPlusComponent,
    DynamicformComponent,
    // EventlistenerDirective,
    SpareMasterComponent,
    ModelMasterComponent,
    ItemMasterComponent,
    CustomerMasterComponent,
    TaxMasterComponent,
    PoMasterComponent,
    DynamicformComponent,
    DeliveryChallanInwardComponent,
    ManualInwardNoteComponent,
    PopupComponentComponent,
    JobSheetComponent,
    PrintjobsheetComponent,
    ReRepairsComponent,
    UserMasterComponent,
    SpareInwardComponent,
    SpareOutwardComponent,
    SpareIssuedComponent,
    SpareRecieptComponent,
    SpareDiscrepancyComponent,
    SpareRequisitionComponent,
    JobSheetPopupComponent,
    InvoiceComponent,
    ToastrComponent,
    ReportComponent,
    RoutineDcComponent,
    DcOutwardComponent,
    RejectedOutComponent,
    ManualDnComponent,
    UtiliyComponent,
    AccessControlDirective,
    MailSysparamsComponent,
    FinancialYearComponent
    // HighlightPipe
  ],


  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },GtPlusDataService, CustomEvents,
  AuthGuard,NgbActiveModal,BsModalRef,
  httpInterceptorProviders,ConfigurationSetting,CommonServicesService,ObjServiceService,ExportAsService,DatePipe,DataSharingService, ToasterService],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
