import { Component, OnInit,Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: 'popup-component',
  templateUrl: './popup-component.component.html',
  styleUrls: ['./popup-component.component.scss']
})
export class PopupComponentComponent implements OnInit {
@Input('inputmsg') inputmsg:string="";
@Input('displaysubmit') displaysubmit:boolean=false;
@Input('displaycancel') displaycancel:boolean=false;
@Input('displayedit') displayedit:boolean=false;
@Input('submitbtntext') submitbtntext:string="";
@Input('cancelbtntext') cancelbtntext:string="";
@Input('editbtntext') editbtntext:string="";

@Output('confirm') confirm=new EventEmitter();
@Output('cancelConfirmation') cancelConfirmation=new EventEmitter();
@Output('editClick') editClick=new EventEmitter();

@ViewChild('popupModal', { static: false }) public popupModal: ModalDirective;

  constructor() { }

  ngOnInit() {

  }
  ngAfterViewInit(){
    this.popupModal.show();
  }
  btnEdit()
  {
    this.editClick.emit();
    this.popupModal.hide();
  }
  btnConfirm()
  {    
    this.confirm.emit();
    this.popupModal.hide();
  }
  btnCancel()
  {   
    this.cancelConfirmation.emit();
    this.popupModal.hide();
  }
}
