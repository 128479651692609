import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationSetting } from '../../appconfig';
import { CommonServicesService } from '../../services/common-services.service';
import { HelperService } from '../../services/helper.service';
import { SubmitFormService } from '../../services/submit-form.service';
import { SystemParamsService } from '../../services/system-params.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemMasterComponent } from '../item-master/item-master.component';
import { LEFT_SIDE_TABLE_JSON, DC_DETAILS_TABLE_JSON, DC_ITEMS_TABLE_JSON, CONFIRM_PO_TABLE_JSON } from './dcinwardgtconfig'
import { DatePipe } from '@angular/common';
import { ToastService } from '../../services/toast.service';
import { CommonFunctionService } from '../../services/common-function.service';
declare var $
@Component({
  selector: 'app-delivery-challan-inward',
  templateUrl: './delivery-challan-inward.component.html',
  styleUrls: ['./delivery-challan-inward.component.scss']
})

export class DeliveryChallanInwardComponent implements OnInit {
  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('showItemPopup', { static: false }) public showPopupToDcInwardList;
  @ViewChild('showPopupoSelectConfirmPo', { static: false }) public showPopupoSelectConfirmPo;
  @ViewChild('ShowJobRepeated', { static: false }) public ShowJobRepeated;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;

  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;

  addItemButton: boolean = false;
  btnRow: boolean = false;
  editBtn: boolean = false;
  saveBtn: boolean = true;
  saveBtnVisible: boolean = false;
  cancelBtn: boolean = false;
  editBtnDisable: boolean = false;
  deleteBtnDisable: boolean = false;
  selectPoBtn: boolean = true;
  createNewBtn: boolean = false;
  currentSelectedRow: number;
  isPopUpOpen: boolean = false;
  showReleaseNo: boolean = true;
  showChallanNo: boolean = true;
  repeatedJobNo = {};
  refreshTable: boolean = true;
  isBlanket: boolean = false;
  typeOfPoBlanket: number = 0;
  PoNoArray: any[] = [];
  selectPoList: any[] = [];
  customerNameArray: any[] = [];
  deletedArray: number[] = [];
  newBtnLabel: string = "Add New DC";
  label: string = "DC Inward";
  deleteTag: string = '';
  maxDate = new Date();
  minDate;
  itemList: any[] = [];
  todaysDate: any = [];
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  deleteMsg: string = "";
  radiobtn: string = "blanketpo";
  dcuniqueno: number = 0;
  private backupData: any = { bindDataToFormAndTable: {} };
  tabledataforprocess: any[] = [];
  showPopupoPO: boolean = false
  emptySearchField: boolean = true;
  disableAddItemBtn: boolean = false;
  isRowClicked: boolean = false;
  showConfirmPoTable: boolean = true;
  deepCopyForSpareDetailTable: any = {}

  refreshDetailTable:boolean = true;

  selectBlanketPoList: any[] = []
  selectNonBlanketPoList: any[] = []
  selectRejectedPoList: any[] = []
  operation: string = '';

  model = {
    isBlanket: {
      valuType: 'Number', value: 0, label: "Is Blanket", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'number' }
    },
    dcinwardno: {
      valuType: 'String', value: '', label: "Dc Inward No.", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'number' }
    },
    grrdate: {
      valuType: 'Date', value: '', label: "Recieved Date", disable: true,
      isRequired: true, err: "", element: { name: 'input', type: 'date' }
    },
    PoNo: {
      valuType: 'String', value: 0, label: "PO No", disable: true,
      isRequired: true, maxLength: 6, err: "", element: { name: 'input', type: 'text' }
    },
    releases: {
      valuType: 'Number', value: '', label: "Release", disable: true,
      isRequired: true, err: "", element: { name: 'input', type: 'text' }
    },
    customername: {
      valuType: 'Number', value: '', label: "Customer Name", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    challanno: {
      valuType: 'String', value: '', label: "Challan no.", disable: true,
      isRequired: true, maxLength: 6, err: "", element: { name: 'input', type: 'text' }
    },
    challandate: {
      valuType: 'Date', value: '', label: "Recieved Date", disable: true,
      isRequired: true, err: "", element: { name: 'input', type: 'date' }
    },
    dmremark: {
      valuType: 'String', value: "", label: "Remark", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  // Left gt-table
  dcinwardtableconfig: any = LEFT_SIDE_TABLE_JSON.tableconfig;
  dcinwardtableheaders: any[] = LEFT_SIDE_TABLE_JSON.tableheaders;
  dcinwardtabledata: any[] = [];

  // DC Inward Details
  dcinwardlisttableconfig: any = DC_DETAILS_TABLE_JSON.tableconfig;
  dcinwardlisttableheaders: any = DC_DETAILS_TABLE_JSON.tableheaders;
  dcinwardlisttabledata: any[] = [];

  // DC Inward Items List
  dcinwardlistdatatableconfig: any = DC_ITEMS_TABLE_JSON.tableconfig;
  dcinwardlistdatatableheader: any = DC_ITEMS_TABLE_JSON.tableheaders;
  dcinwardlistdata: any[] = [];

  confirmPotableconfig: any = CONFIRM_PO_TABLE_JSON.tableconfig;
  confirmPotableheader: any = CONFIRM_PO_TABLE_JSON.tableheaders;
  confirmPotabledata: any = [];

  constructor(private ngbActiveModal: NgbActiveModal, private commonFunction: CommonFunctionService, private ngbModal: NgbModal, private submit: SubmitFormService, public commonService: CommonServicesService, private configurationsetting: ConfigurationSetting, public systemParamsService: SystemParamsService, private helper: HelperService, private route: Router, private datepipe: DatePipe, private toastService: ToastService) { }

  ngOnInit(): void {
    this.getCustomerName();
    this.getPOMasterListForPopup();
    this.getSearchAllDc();
    this.getConfirmBlanketPoList();
    this.commonService.clearForm(this.model);

     if (this.isOpenFromReport) {
      this.getDcInwardByIdForReport(this.transactionID);
      this.btnRow = true;
    }
  }

  enaieldsToCreateNewForm() {
    this.emptySearchField = false;
    this.newBtnLabel = "Add DC";
    this.label = "Add DC Inward";
    this.deleteMsg = "";
    this.disableAddItemBtn = true;
    this.createNewBtn = true;
    this.saveBtnVisible = true;
    this.deleteBtnDisable = false;
    this.saveBtn = true;
    this.btnRow = true;
    this.editBtn = false;
    this.cancelBtn = true;
    this.addItemButton = true;
    this.selectPoBtn = false;
    this.dcinwardlisttabledata = [];
    this.tabledataforprocess = [];

    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.model.customername.disable = true;
    this.todaysDate = new Date();
    this.model.challandate.value = this.todaysDate;
    this.model.grrdate.value = this.todaysDate;
    this.makeColumnUneditable(true);
    this.model.dmremark.value = " "
    const ele = document.getElementById(`tr${"dcinwardtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    delete this.backupData.bindDataToFormAndTable;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  ChangeDate() {
    console.log(this.model.challandate.value);

    this.model.grrdate.value = this.model.challandate.value;
  }

  saveDcInwardForm() {
    console.log(this.model);

    let data = this.submit.submitForm(this.model);

    if (data) {
      this.saveBtn = true;
      let editedRow = [];

      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.model.isBlanket.value == 1) {
          if ((this.tabledataforprocess[i]['jobno'] == "" || this.tabledataforprocess[i]['jobno'] == null || this.tabledataforprocess[i]['jobno'] == undefined)) {
            this.toastService.showToastMsg('warning', 'Warning', "Please enter Job no. in table.");
            return;
          }
        }

        if ((this.tabledataforprocess[i]['qty'] == "" || null || undefined)) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter data in table.");
          return;
        }
        if (this.tabledataforprocess[i]['qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        if (this.tabledataforprocess[i]?.rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i])
        }
      }
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      if (this.model.isBlanket.value == 1) {
        data["criteria"]["isBlanket"] = 1;
      } else {
        data["criteria"]["isBlanket"] = 0;
        data["criteria"]['releases'] = null;
      }
      this.deletedArray = [];

      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);


      let endpoint = 'transaction/insertDc';
      if (data["criteria"]['dcinwardno']) {
        endpoint = 'transaction/updateDc';
        this.ErrorDescription = "Data Updated Successfully";
        data["criteria"]["dcinwardlist"] = this.tabledataforprocess;
      } else {
        this.ErrorDescription = "Data Inserted Successfully";
        data["criteria"]["dcinwardlist"] = this.tabledataforprocess;
      }
      console.log("updated json ", data);
      
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.dcuniqueno = res['Data']['insertId']
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.commonService.diasbleFormFields(this.model)
          this.selectPoBtn = true;
          this.addItemButton = false;
          this.cancelBtn = false;
          this.createNewBtn = false;
          this.editBtn = true;
          this.dcinwardlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.makeColumnUneditable(false);
          this.getSearchAllDc()
          // this.toastService.showToastMsg('success', 'Success', this.ErrorDescription);
          this.PopupMessageModal.show();
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  deleteDcInwardForm() {
    let data = this.submit.submitForm(this.model);

    if (data || data["criteria"]["dcinwardno"]) {
      data["criteria"]["dcinwardlist"] = this.tabledataforprocess;
      let items: any = {};
      items.dcinwardno = data["criteria"]["dcinwardno"];
      items.customername = data["criteria"]["customername"];
      items.delLineItems = [];
      let permissionToDelete = false;
      console.log("data-criteria-",data["criteria"]);
      data["criteria"]["dcinwardlist"].forEach((ele) => {
        if (ele?.SrNo) {
          items.delLineItems.push(ele?.SrNo);
        }
        if(ele['qty'] != ele['QtyPending']){
          permissionToDelete = true;
        }
      })
      if(permissionToDelete){
          this.ErrorDescription = 'Can not delete this transaction. Because Jobsheet/Outward has been created for this transaction.';
          this.PopupMessageModal.show();
          return;
      }
      data["criteria"] = items;

      let endpoint = 'transaction/deleteDc';
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('closePopupDelete')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.clearAll(false);
          this.getSearchAllDc()
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        }
      }, (err) => { });
    }
  }

  getDcById(modal, flag: boolean = true) {
    console.log(modal);

    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.dmremark.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    let row = modal['row'];
    let userdeleted = row['username']
    let deletedDate = row['deldate']
    if (row["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.btnRow = false;
        // row['dcinwardlist'] = [];
        this.dcinwardlisttabledata = [];
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"dcinwardtbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = "";
    }
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getDcById";
    let params = { DcInwardSrNo: row['dcinwardno'] };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']['tabledata']
        modal['row'] = item;
        this.bindDataToFormAndTable(modal);
        this.dcinwardlisttabledata = [];
        this.dcinwardlisttabledata = item["dcinwardlist"];
      }
    }, (error) => {
      console.log(error);
    });
  }

  getDcInwardByIdForReport(id){
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getDcById";
    let params = { DcInwardSrNo:  id};
    let modal = {}
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']['tabledata']
        modal['row'] = item;
        this.bindDataToFormAndTable(modal);
        this.dcinwardlisttabledata = [];
        this.dcinwardlisttabledata = item["dcinwardlist"];
      }
    }, (error) => {
      console.log(error);
    });
  }

  bindDataToFormAndTable(modal, flag: boolean = true) {

    this.refreshDetailTable = false;
    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    let row = modal['row'];
    if (row['isBlanketPo'] == 0) {
      this.showReleaseNo = false;
      this.model.releases.isRequired = false;
      this.dcinwardlisttableheaders.forEach((item) => {
        if (item['key'] == 'jobno') {
          item['visiblecolm'] = false;
        }
      })
      this.model.customername.disable = false;
    } else {
      this.showReleaseNo = true;
      this.model.releases.isRequired = true;
      this.dcinwardlisttableheaders.forEach((item) => {
        if (item['key'] == 'jobno') {
          item['visiblecolm'] = true;
        }
      })
      this.model.customername.disable = true;
    }
    row['PoNo'] = +row['PoNo'];

    if(row['PoNo'] == "0"){
      this.model.PoNo.isRequired = false;
    }
    // console.log(typeof());



    // this.dcinwardlisttabledata = [];
    let rowData = row["dcinwardlist"]?.length ? row['dcinwardlist'] : [];
    let newArr = rowData.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    setTimeout(() => {
      this.dcinwardlisttabledata = newArr;
      this.refreshDetailTable = true;
    }, 50);
    // console.log(this.dcinwardlisttabledata);


    // if(this.model.PoNo.value = 0){
    //   this.model.PoNo.value = 'Non '
    // }

    this.editBtn = true;
    this.dcinwardlisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonFunction.setFromKeysValues(this.model, row);
    this.label = "Display DC Inward"
    this.editBtn = true;
    this.editBtnDisable = false;
    this.createNewBtn = false;
    this.addItemButton = false;
    this.selectPoBtn = true;
    this.deleteBtnDisable = false;
    this.makeColumnUneditable(false);
    this.commonService.diasbleFormFields(this.model);
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.dcinwardtabledata, modal.rowindex, this.currentSelectedRow, "dcinwardtbl1");
    this.isRowClicked = false;

    if(row['PoNo'] !== "0"){
      this.bindPoNoAndGetItem(modal);
    }
  }

  bindPoNoAndGetItem(modal) {
    let row = modal['row'];
    console.log(row);

    // AX1001807

    this.model.PoNo.value = row.PrNo || row.PoNo;
    this.model.customername.value = row.customername;
    if (this.model.PoNo.value) {
      this.getPoListForPrNo(this.model.PoNo.value);
    }
    this.model.isBlanket.value = row?.isBlanketPo;

    console.log("model.PoNo.value ", this.model.PoNo.value, this.model.isBlanket.value, row?.isBlanketPo);

    if (this.model.PoNo.value !== 0) {
      this.showReleaseNo = true;
      this.model.releases.isRequired = true;
      this.model.PoNo.isRequired = true;
      this.model.customername.disable = true;
      this.dcinwardlisttableheaders.forEach((item) => {
        if (item['key'] == 'jobno') {
          item['visiblecolm'] = true;
        }
      })
    }
    this.tabledataforprocess = [];
    this.cancelPopup('showPopupoSelectConfirmPoClose');
    this.disableAddItemBtn = false;
  }

  clickForNonBlanketPo() {
    this.model.PoNo.value = 0;
    if (this.model.PoNo.value == 0) {
      this.showReleaseNo = false;
      this.model.PoNo.isRequired = false;
      this.model.releases.isRequired = false;
      this.model.customername.disable = false;
      this.dcinwardlisttableheaders.forEach((item) => {
        if (item['key'] == 'jobno') {
          item['visiblecolm'] = false;
        }
      })
    }
    this.tabledataforprocess = [];

    this.cancelPopup('showPopupoSelectConfirmPoClose');
    this.disableAddItemBtn = false;
  }

  getPoListForPrNo(srno: number) {
    let params = { PrNo: srno };
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getItemList";
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']['tabledata']
        this.dcinwardlistdata = item;
        this.itemList.push(item);

      }
    }, (error) => {
      // console.log(error);
    });
  }
  filterSpareListTableIfExistingSubTable() {
    let preparedarr = [];
    preparedarr = this.deepCopyForSpareDetailTable.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < this.dcinwardlisttabledata.length; ind++) {
        if (this.dcinwardlisttabledata[ind]['ItemSrNo'] == row['ItemSrNo']) {
          recavailable = false;
        }
      }
      return recavailable;
    });
    this.dcinwardlistdata = []
    this.dcinwardlistdata = preparedarr;
  }

  getNoBlanketPoList() {
    let endpoint = 'transaction/getDcItemListForNonBlanket';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.dcinwardlistdata = [];
        this.dcinwardlistdata = items;
      }
    }, err => { })
  }

  getConfirmBlanketPoList() {
    let endpoint = 'transaction/getPoListByBlanket';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.confirmPotabledata = [];
        this.confirmPotabledata = items;
        this.selectPoList.push(items);
        this.confirmPotabledata.forEach((data) => {
          this.PoNoArray.push({ value: data.PrNo, display: data.PoNo })
        })
      }
    }, err => { })
  }

  selectDcItemsList(itemdata: any) {
    this.saveBtn = false;
    this.saveBtnVisible = true;
    console.log(itemdata);

    let selectedArray = this.helper.removeRefrence(this.tabledataforprocess);
    itemdata = this.helper.removeRefrence(itemdata);
    this.dcinwardlisttabledata = [];

    for (let ele of itemdata) {
      const { ItemSrNo, item, modelname, description, jobno, esn, customer, engtypejeop, remark, qty, isBlanketPo } = ele;
      selectedArray.push({ ItemSrNo, item, modelname, description, jobno, esn, customer, engtypejeop, remark, qty, isBlanketPo });
    }

    this.dcinwardlisttabledata = selectedArray;
    this.cancelPopup('closepopup');
    // console.log(this.dcinwardlisttabledata);

  }

  updatedtabledata(data: any) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;
    // this.tabledataforprocess.forEach((item)=>{
    //   if(item['oldqty'] != item['QtyPending']){
    //     item['newQty'] = item['oldqty'] - item['QtyPending']

    //     if(item['qty'] < item['newQty']){
    //       item['qty'] = item['newQty'];
    //       this.ErrorDescription = `Can not enter qty less than ${item['newQty']}`;
    //       this.errorMessageModal.show();
    //     }
    //   }
    // })
  }

  rowUpdate(data) {
    console.log(data);

    const currentValue = data.row.jobno;
    data.row.rowmodifiedstatus = 'M';
    let item = data.row;
    const jonnorepeat = {};
    const keyName = data.keyName;
    if (keyName == "jobno" && this.repeatedJobNo["name"] != currentValue) {
      const tabledata = data.tabledata;
      let count = 0;
      tabledata.forEach((item) => {
        if (item["jobno"] == currentValue) {
          count++;
        }
      })
      if (count > 1) {
        this.repeatedJobNo = { name: currentValue, count }
        this.ShowJobRepeated.show();
      }
      // console.log("current val", currentValue, count);
    }
    if (keyName == "qty" && item['oldqty'] != item['QtyPending']) {
     
        item['newQty'] = item['oldqty'] - item['QtyPending']

        if(item['qty'] < item['newQty']){
          item['qty'] = item['newQty'];
          this.ErrorDescription = `Can not enter qty less than ${item['newQty']}`;
          this.errorMessageModal.show();
        }
      
    }
    // if (keyName == "esn" && data.row.esn){
    //   let esnNo = data.row.esn
    //   let params = { esnno: esnNo };
    //   let endpoint = this.configurationsetting.getBaseUrl() + "transaction/checkUniqueEsnNo";
    //   this.commonService.getWithParams(endpoint, params).subscribe((res) => {
    //     if (res['errorCode'] == "000000") {
    //       console.log("Not Exit");

    //     }else{

    //       console.log("Already Exit");
    //       this.ErrorDescription = res['errorDescription'];
    //       this.errorMessageModal.show();
    //       data.row.esn = "";
    //     }
    //   }, (error) => {
    //     // console.log(error);
    //   });
    // }
    const currentVal = data.row.qty;
    // console.log(currentVal);
    data.row.rowmodifiedstatus = 'M';
    // console.log(data.row.rowmodifiedstatus = 'M', 'data')

    if (data['keyName'] == 'qty' && data?.row?.qty < 0) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.qty = 0;
      this.errorMessageModal.show();
    }
  }

  checkEsnUniqueNo(esnNo: string) {

  }

  addItemBtn() {
    this.refreshTable = false;
    this.operation = 'new'

    if (this.model.PoNo.value !== 0) {
      this.itemList.forEach((item) => {
        this.dcinwardlistdata = item;
      });
      this.jobnoColumnControl("blanketpo");
    } else {
      this.getNoBlanketPoList();
      this.jobnoColumnControl("nonblanketpo");
    }

    this.deepCopyForSpareDetailTable = this.helper.removeRefrence(this.dcinwardlistdata);
    // this.filterSpareListTableIfExistingSubTable();
    this.showPopupToDcInwardList.show();
    setTimeout(() => {
      this.refreshTable = true;
    }, 30);
  }

  showPopupToSelectPo() {
    this.checkBlanketPo('blanketpo');
    this.radiobtn = 'blanketpo';
    this.showPopupoSelectConfirmPo.show();
    this.showPopupoPO = true;
  }

  checkBlanketPo(val) {
    if (val === "blanketpo") {
      this.showConfirmPoTable = true;
      this.selectPoList.forEach((items) => {
        this.confirmPotabledata = items;
      })
    } else if (val === "blanketpo") {
      this.showConfirmPoTable = true;
      this.confirmPotabledata = this.selectBlanketPoList;
    } else if (val === "nonblanketpo") {
      this.confirmPotabledata = [];
      this.showConfirmPoTable = false;
    } else if (val === "rejectedpo") {
      this.confirmPotabledata = this.selectRejectedPoList;
    }
  }

  jobnoColumnControl(val){
    this.dcinwardlistdatatableheader.forEach((item)=>{
      if (val === "blanketpo") {
        if(item['key'] == 'jobno'){
          item['visiblecolm'] = true;
        }
      }else{
        if(item['key'] == 'jobno'){
          item['visiblecolm'] = false;
        }
      }
    })
  }

  editForm() {
    this.dcinwardlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.makeColumnUneditable(true);
    this.label = "Edit DC Inward"
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.cancelBtn = true;
    this.createNewBtn = true;
    this.addItemButton = true;
    this.selectPoBtn = true;
    this.editBtnDisable = true;
    this.deleteBtnDisable = true;
    this.editBtn = false;
    this.commonService.enableFormFields(this.model);
    if(this.model.PoNo.value == 0){
      this.model.customername.disable = false;
    }else{
      this.model.customername.disable = true;
    }

  }

  deleteRow(data: any) {
    let row = data['row'];
    if(row['qty'] != row['QtyPending']){
      this.ErrorDescription = 'Can not delete this item. Because it is used in forward transactions.';
      this.PopupMessageModal.show();
      return;
    }
    this.tabledataforprocess.splice(data.rowindex, 1);
    if (!this.tabledataforprocess.length) {
      this.showItemAlert.show();
      this.saveBtn = true;
      this.saveBtnVisible = false;
    } else {
      this.saveBtn = false;
      this.saveBtnVisible = true;
    }
    this.pushInDeletedArray(data.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }

  recievedDate(date) {
    this.minDate = date;
  }

  clearAll(flag: boolean = true) {
    this.commonService.clearForm(this.model);
    this.dcinwardlisttabledata = [];
    this.label = "DC Inward";
    this.newBtnLabel = "Add New DC";
    this.selectPoBtn = true;
    this.addItemButton = false;
    this.cancelBtn = false;
    this.createNewBtn = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.deleteMsg = "";
    this.btnRow = false;
    this.showReleaseNo = true;
    this.commonService.diasbleFormFields(this.model);
    this.dcinwardlisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.dcinwardlisttableheaders.forEach((item) => {
      if (item['key'] == 'jobno') {
        item['visiblecolm'] = true;
      }
    })
    const ele = document.getElementById(`tr${"dcinwardtbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editBtnDisable && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false)
      this.editBtnDisable = false;
    } else {
      this.editBtn = false;
    }
  }

  cancleForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editBtnDisable) {
        this.clearAll(false);
      } else {
        this.clearAll(true);
      }
    }
  }

  // Cancel all Popup
  cancelPopup(whichForm) {
    this.dcinwardlistdata = [];
    this.isPopUpOpen = false;
    this.showPopupoPO = false;
    switch (whichForm) {
      case 'closepopup':
        this.showPopupToDcInwardList.hide();
        break;
      case 'showPopupoSelectConfirmPoClose':
        this.showPopupoSelectConfirmPo.hide();
        break;
      case "closejobrepeated":
        this.ShowJobRepeated.hide()
        break;
      case "closePopupDelete":
        this.showDeleteModal.hide()
        break;
      case "itemalert":
        this.showItemAlert.hide();
        break;
    }
  }

  openNewItemMaster() {
    const ngbModalRef = this.ngbModal.open(ItemMasterComponent, { windowClass: "allocate-modal-pop", backdrop: 'static' });
    ngbModalRef.componentInstance.showonlyForm = true;
    ngbModalRef.result.then((result) => {
      // console.log(result);
      if (result && result?.status == "save") {
        this.getItemListForPopup();
      }
    });
  }

  getMasterTable() {
    this.commonService.getGenericTable("ItemMaster", this.configurationsetting.getBaseUrl()).subscribe(successResp => {
      if (successResp['errorCode'] == 0) {
        const items = successResp['Data'][0]['@tbldata'];
        items.forEach(element => {
          element.item = element['itemcode'];
          element.description = element['subassemblyname'];
          element.qty = '';
        });
        this.dcinwardlistdata = []
        this.dcinwardlistdata = successResp['Data'][0]['@tbldata'];
        this.isPopUpOpen = true;

        this.showPopupToDcInwardList.show();
      }
    }, err => { })
  }


  getItemListForPopup() {
    const endpoint = 'transaction/getItemList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        items.forEach(element => {
          element.qty = '';
        });
        this.dcinwardlistdata = []
        this.dcinwardlistdata = items;

        this.isPopUpOpen = true;
        this.showPopupToDcInwardList.show();
      }
    }, err => { })
  }

  getPOMasterListForPopup() {
    const endpoint = 'transaction/getPoList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];

        if (items)
          // this.confirmPotabledata = items;
          // this.confirmPotableheader = successResp['Data']['tableheaders'];
          // this.confirmPotableconfig = successResp['Data']['tableconfig'];
          this.isPopUpOpen = true;

        // this.showPopupoSelectConfirmPo.show();
        // this.showPopupoPO = true;
      }
    }, err => { })
  }
  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }

  getSearchAllDc() {
    const endpoint = 'transaction/getAllDc';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        this.dcinwardtabledata = []
        this.dcinwardtabledata = items;
        for (let i = 0; i < items.length; i++) {
          if (items[i]['dcinwardno'] == this.dcuniqueno || items[i]['dcinwardno'] == this.model.dcinwardno.value) {
            // console.log("Index", this.tabledata[i], i);
            setTimeout(() => {
              this.getDcById({
                "row": items[i],
                "rowindex": i
              })
              if (!this.isOpenFromReport) {
                document.getElementById('trdcinwardtbl1' + i).style.backgroundColor = "#7de4f0";
                const newInsertedRow = document.getElementById('trdcinwardtbl1' + i);
                $(`tr[tabindex=${i}]`).focus();
              }
              // $('#trdcinwardtbl1' + i).scrollTop(0);

            }, 900);
            break;
          }
        }
        this.isPopUpOpen = true;
      }
    }, err => { })
  }

  closeErrorPopup() {
    this.backendErrorMessageModal.hide();
    this.errorMessageModal.hide();
    this.PopupMessageModal.hide();
    this.showDeleteModal.hide();
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show();
  }

  makeColumnUneditable(flag: boolean) {
    this.dcinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcinwardlisttableheaders), "qty", flag);
    this.dcinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcinwardlisttableheaders), "remark", flag);
    this.dcinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcinwardlisttableheaders), "jobno", flag);
    this.dcinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcinwardlisttableheaders), "esn", flag);
    this.dcinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcinwardlisttableheaders), "customer", flag);
    this.dcinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcinwardlisttableheaders), "engtypejeop", flag);
    this.dcinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcinwardlisttableheaders), "srno1", flag);
    this.dcinwardlisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.dcinwardlisttableheaders), "srno2", flag);
  }

  closeModal() {
    this.ngbActiveModal.close();
  }
}
