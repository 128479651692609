import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GtPlusComponent } from './gt-plus.component';
import { EventlistenerDirective } from './eventlistener.directive';
import { HighlightPipe } from './highlight.pipe';
import { GtPlusDataService } from './gt-plus-data.service';
import { ContextMenuModule, ContextMenuService } from 'ngx-contextmenu';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxDataTableModule } from 'angular-9-datatable';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownListModule } from 'ngx-dropdown-list';

@NgModule({
  declarations: [
    GtPlusComponent,
    EventlistenerDirective,
    HighlightPipe
  ],
  providers: [
    DatePipe,
    GtPlusDataService,
    ContextMenuService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  imports: [
    CommonModule,BsDropdownModule,HttpClientModule,
    BsDatepickerModule.forRoot(),
    ContextMenuModule,
    NgxDataTableModule,
    FormsModule,
    DropdownListModule
  ],
  exports: [GtPlusComponent]
})
export class GtPlusModule { }
