import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../appconfig';
import { HttpHeaders } from '@angular/common/http';
import { ConfirmationPopupModel } from '../../models/models';
import { CommonServicesService } from '../../services/common-services.service';
import { NgForm } from '@angular/forms';
import { DataSharingService } from '../gt-plus/data-sharing.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ValidationService } from '../../services/validation.service';
import { ToastService } from '../../services/toast.service';
declare var $;
@Component({
  selector: 'app-customer-master',
  templateUrl: './customer-master.component.html',
  styleUrls: ['./customer-master.component.scss']
})
export class CustomerMasterComponent implements OnInit {
  @ViewChild('customerMasterForm') public resetCustomerForm: NgForm;
  @ViewChild('addTaxForm') public resetAddTaxForm: NgForm;
  dynamicTable: any = {};
  subDynamicTable: any = {}
  showloader: boolean = false;
  showconfirmpopup: boolean = false;
  displayTable: boolean = false;
  displaySubTable: boolean = false;
  errormsg: string = "";
  operation: string = "";
  oprationForRowClick: string = "";
  headers = new HttpHeaders();
  confirmationpopupmodelobj: ConfirmationPopupModel = new ConfirmationPopupModel();
  validationmsgforQty: string = "";
  allowedToAddEditCtrl: boolean = true;
  rowIndex: any;
  colIndex: any;
  public customerMasterTab: boolean = true;
  isEditable: boolean = false;
  custNoForSave;
  customerName: string = "";
  customerShortName: string = "";
  address1: string = "";
  gstin: string = "";
  address2: string = "";
  address3: string = "";
  pinCode: any = "";
  customerMasterForm;
  rowsperpage = 100;
  currentSelectedRow:number = 0;
  emptySearchField: boolean = true;
  disableCustomerName: boolean = false;
  disableCustomerShortName: boolean = false;
  disableAddress1: boolean = false;
  disablegstin: boolean = false;
  disableAddress2: boolean = false;
  disableAddress3: boolean = false;
  disablePincode: boolean = false;
  disableSaveBtn: boolean = false;
  saveBtnVisible: boolean = false;
  disableNewBtn: boolean = false;
  disablecancelBtn: boolean = false;
  isRowClicked:boolean = false;

  userRole: string = "";
  deleteById;
  deleteSubTbleById;
  opeartionStageForDelete: string = "";
  opeartionStageForSubDelete: string = "";
  showFormsPopup: boolean = false;
  taxMasterList: any[] = [];
  isEditableCustTax: boolean = false;
  custTaxId;
  taxId;
  registartionNO: any;
  custTax: any;
  srNo;
  deleteBySRNO;
  highlightedObj = {
    "id": "",
    "indx": -1
  }
  eve = {};
  indexForEdit: number;
  eventRowIndex;
  doubleClickStage: string = "";
  editOperation: string = "";
  visibleSaveBtn: boolean = false;
  visibleNewBtn: boolean = false;
  visibleCancelBtn: boolean = false;
  deleteBtn: boolean = false;
  customerUniqueNo: number = 0;
  tableheaders = [];
  tabledata = [];
  tableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": true,
      "delete": true,
      "print": false
    },
    "partno": { "text-align": "left" },
    "Qty": { "text-align": "Right" },
    "CurrentBalance": { "text-align": "Right" },
    "WIP": { "text-align": "Right" },
    "controlconfig": { "displaycontrol": false },
    // "checkDeletedFlag": true,
    // "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  };

  subtableheaders = [];
  subtabledata = [];
  subtableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "apicall": { "askconfirmation": false, "autoupdate": false },
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": true,
      "delete": true,
      "print": false
    },
    "controlconfig": { "displaycontrol": false, "controlsfor": {} },
    "buttonspriority": "row",
    "showonlysearch": true,
    "fullheighttable": 'fullheight-table'
  };
  addItemButton;
  editDisableBtn: boolean = false;
  editBtn: boolean = false;
  deleteMsg = "";
  btnRow: boolean = true;
  @ViewChild('showTaxModal', { static: false }) public showTaxModal: ModalDirective;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal: ModalDirective;

  constructor(private dataSharingSrvc: DataSharingService, public commonservices: CommonServicesService, private configurationsetting: ConfigurationSetting, private validator: ValidationService, private toastService: ToastService) { }

  ngOnInit() {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    this.getMasterTable();
    /*
    below settimeout is used to disable form for the forst time when user enters
    */

    this.taxDropdownList();
  }

  // Validation Start
  model = {
    customerName: {
      value: '',
      type: 'String',
      strLength: 12,
      err: '',
      label: 'Customer Name'
    },
    shortName: {
      value: '',
      type: 'String',
      strLength: 6,
      err: '',
      label: 'Customer Short Name'
    },
    address1: {
      value: '',
      type: 'String',
      strLength: 15,
      err: '',
      label: 'Address 1'
    },
    address2: {
      value: '',
      type: 'String',
      strLength: 15,
      err: '',
      label: 'Address 2'
    },
    address3: {
      value: '',
      type: 'String',
      strLength: 15,
      err: '',
      label: 'Address 3'
    },
    pincode: {
      value: '',
      type: 'Number',
      strLength: 6,
      err: '',
      label: 'Pincode'
    }
  }

  isValid() {
    if (this.validator.isValidate(this.model)) {
      return;
    }
  }

  saveBtn() {
    this.isValid();
  }




  addItemBtn() { }

  disableForm() {
    this.disableCustomerName = true;
    this.disableCustomerShortName = true;
    this.disablegstin = true;
    this.disableAddress1 = true;
    this.disableAddress2 = true;
    this.disableAddress3 = true;
    this.disablePincode = true;
    this.disableSaveBtn = true;
    this.saveBtnVisible = false;
    this.disablecancelBtn = false;
    this.displaySubTable = false;
    this.visibleNewBtn = true;
    this.visibleSaveBtn = false;
    this.visibleCancelBtn = false;
  }
  showCustomerMasterForm() {
    this.operation = "NEW";
    this.custNoForSave = 0;
    this.customerUniqueNo = 0;
    this.emptySearchField = false;
    this.btnRow = true;
    this.editBtn = false;
    if (this.oprationForRowClick == "clickedOnRow") {
      this.isEditable = false;
      this.btnRow = true;
      this.deleteMsg = "";
      this.isEditableCustTax = false;
      this.subDynamicTable['tableconfig']['enablefirstbtn'] = true;
      this.subDynamicTable['tabledata'] = [];
      this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
      this.subtableconfig = this.subDynamicTable["tableconfig"];
      this.subtabledata = this.subDynamicTable["tabledata"];
      this.subtableheaders = this.subDynamicTable["tableheaders"];
      this.oprationForRowClick = "";
      const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';

    }
    this.resetCustomerForm.reset();
    this.disableCustomerName = false;
    this.disableCustomerShortName = false;
    this.disablegstin = false;
    this.disableAddress1 = false;
    this.disableAddress2 = false;
    this.disableAddress3 = false;
    this.disablePincode = false;
    this.disableSaveBtn = false;
    this.saveBtnVisible = true;
    this.disableNewBtn = true;
    this.disablecancelBtn = false;
    this.displaySubTable = true;
    this.visibleNewBtn = false;
    this.visibleSaveBtn = true;
    this.visibleCancelBtn = true;

    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);

  }


  rowClickCustMaster(event, flag = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 3000);
      this.isRowClicked = true;
      return;
    }
    this.isRowClicked = true;
    if (this.disableCustomerName == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }

    this.eve = event;
    this.visibleCancelBtn = false;
    if (navigator.onLine) {
      this.editBtn = true;
      this.errormsg = "";
      this.oprationForRowClick = "clickedOnRow";
      this.showloader = true;
      this.loaderTimeOut();
      this.disableNewBtn = false;
      this.disableCustomerName = true;
      this.disableCustomerShortName = true;
      this.disablegstin = true;
      this.disableAddress1 = true;
      this.disableAddress2 = true;
      this.disableAddress3 = true;
      this.disablePincode = true;
      this.disableSaveBtn = true;
      this.saveBtnVisible = false;
      this.disablecancelBtn = false;
      this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
      this.subDynamicTable['tableconfig']['enablefirstbtn'] = false;
      this.custNoForSave = event['row']['CustomerNo'];
      this.customerName = event['row']['customername'];
      this.customerShortName = event['row']['customershortname'];
      this.gstin = event['row']['gstin'];
      this.address1 = event['row']['Address1'];
      this.address2 = event['row']['Address2'];
      if (event['row']['Address3'] == "null") {
        this.address3 = "";
      } else {
        this.address3 = event['row']['Address3'];

      }
      this.pinCode = event['row']['Pincode'];

      setTimeout(() => {
        this.currentSelectedRow = event.rowindex;
        this.commonservices.identifySelectedRow(this.tabledata, event.rowindex, this.currentSelectedRow, "table1");
      }, 200);

      if (typeof (event['row']['TaxDetails']) == 'string') {
        this.subDynamicTable['tabledata'] = JSON.parse(event['row']['TaxDetails']);
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];
      } else if (event['row']['taxdetails']) {
        this.subDynamicTable['tabledata'] = event['row']['taxdetails'];
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));

        this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];
      } else if (event['row']['TaxDetails'] == null) {
        this.subDynamicTable['tabledata'] = [];
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];
      }
      else if (event['row']['TaxDetails'].length > 0) {
        this.subDynamicTable['tabledata'] = event['row']['TaxDetails'];
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];

      } else if (event['row']['TaxDetails'].length == 0) {
        this.subDynamicTable['tabledata'] = [];
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));

        this.subtableconfig = this.subDynamicTable["tableconfig"];
        this.subtabledata = this.subDynamicTable["tabledata"];
        this.subtableheaders = this.subDynamicTable["tableheaders"];
      }
      this.displaySubTable = true;
      this.showloader = false;
      this.visibleNewBtn = true;
      this.visibleSaveBtn = false;
      this.doubleClickStage = "rowClickCustMaster";

      let row = event['row']
      let userdeleted = event['username']
      let deletedDate = event['deldate']
      if (row["isDeleted"] === "N") {
        this.custNoForSave = "";
        this.customerName = "";
        this.customerShortName = "";
        this.gstin = ""
        this.address1 = "";
        this.address2 = "";
        this.address3 = "";
        this.pinCode = "";

        this.subtabledata = [];
        // this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
      } else {
        this.btnRow = true;
        // this.deleteMsg = ""
      }
      this.editBtn = true;
      this.isRowClicked = false;

    }
    else {
      alert("Internet Connection is Not Available.");
    }
  }

  addTax() {
    this.showTaxModal.show();
    this.showFormsPopup = true;
  }

  deletecustomerMaster(event) {
    if (navigator.onLine) {
      if (this.operation == "editCustomerMaster" || this.doubleClickStage == "rowClickCustMaster") {
        const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
        this.operation = "";
        this.doubleClickStage = "";
      }
      this.deleteById = event['row']['CustomerNo'];
      this.opeartionStageForDelete = "delete";
      this.displayMessagePopup("Are you sure want to delete this record?", "Yes", true, "No", true);
    }
    else {
      this.displayMessagePopup("Internet Connection is Not Available.", "Ok", true, false, "");
    }
  }


  deleteCustTax(event) {
    if (navigator.onLine) {
      this.deleteBySRNO = event['row']['SrNo']
      this.deleteSubTbleById = event['rowindex'];
      this.opeartionStageForSubDelete = "deleteCustTax";
      this.displayMessagePopup("Are you sure want to delete this record?", "Yes", true, "No", true);
    }
    else {
      this.displayMessagePopup("Internet Connection is Not Available.", "Ok", true, false, "");
    }
  }

  showDeleteConfirmationYes() {
    console.log(this.custNoForSave);

    this.showDeleteModal.show();
  }

  deleteCustMasterData(srno: number) {
    let apiUrl = this.configurationsetting.getBaseUrl() + "Master/DeleteMasterRecords";
    let obj = {};
    obj['deletefor'] = "Customer"
    obj['recordvalue'] = srno;
    let param = { "criteria": obj }
    const unsubscribe = this.commonservices.postMethod(apiUrl, param).subscribe(successResp => {
      this.showloader = false;
      if (successResp['errorCode'] == "000000") {
        this.showconfirmpopup = false;
        //below splice method is used to delete it from table,coz i cannot call service again here
        this.subDynamicTable['tabledata'].splice(this.deleteSubTbleById, 1);
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        this.opeartionStageForSubDelete = "deletedCustTax";
        this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
        this.showDeleteModal.hide();

        this.getMasterTable();
        this.resetCustomerForm.reset();
        this.disableCustomerName = true;
        this.disableCustomerShortName = true;
        this.disablegstin = true;
        this.disableAddress1 = true;
        this.disableAddress2 = true;
        this.disableAddress3 = true;
        this.disablePincode = true;
        this.btnRow = false;
        this.saveBtnVisible = false;
        this.visibleCancelBtn = false;
        this.deleteBtn = false;
        this.disableNewBtn = false;


      } else if (successResp['errorCode'] == 111000) {
        this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);

      }else{

        this.errormsg = successResp['errorDescription']

      }
    }, errorResp => {
      this.showloader = false;
      this.errormsg = errorResp['message']
    }, ()=>{
      unsubscribe.unsubscribe();
    });
  }

  confirmDeleteCustTax() {
    if (navigator.onLine) {

      if (this.deleteBySRNO == 0) {
        this.subDynamicTable['tabledata'].splice(this.deleteSubTbleById, 1);
        this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
        this.opeartionStageForSubDelete = "deletedCustTax";
        this.displayMessagePopup("Record Deleted Successfully.", "Ok", true, "", false);
      } else if (this.deleteBySRNO > 0) {
        this.errormsg = "";
        this.showloader = true;
        this.loaderTimeOut();
        let apiUrl = this.configurationsetting.getBaseUrl() + "Master/DeleteMasterRecords";
        let obj = {};
        obj['deletefor'] = "CustomerTax"
        obj['recordvalue'] = this.deleteBySRNO;
        let param = { "criteria": obj }
        this.commonservices.postMethod(apiUrl, param).subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == "000000") {
            this.showconfirmpopup = false;
            //below splice method is used to delete it from table,coz i cannot call service again here
            this.subDynamicTable['tabledata'].splice(this.deleteSubTbleById, 1);
            this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
            this.opeartionStageForSubDelete = "deletedCustTax";
            this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
          } else  if (successResp['errorCode'] == 111000) {
            this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);

          }else{

            this.errormsg = successResp['errorDescription']

          }
        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        });
      }

    }
    else {
      this.showloader = false;

      alert("Internet Connection is Not Available.");

    }


  }


  confirmDeleteCustMaster() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/DeleteMasterRecords";
      let obj = {};
      obj['deletefor'] = "Customer"
      obj['recordvalue'] = this.deleteById;
      let param = { "criteria": obj }
      this.commonservices.postMethod(apiUrl, param).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.showconfirmpopup = false;
          this.opeartionStageForDelete = "deleted";
          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
        } else if (successResp['errorCode'] == 111000) {
          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);

        }else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }



    else {
      this.showloader = false;

      alert("Internet Connection is Not Available.");

    }
  }




  cancelConfirmationPopup() {

    if (this.opeartionStageForSubDelete == "deletedCustTax") {
      this.opeartionStageForSubDelete = "";
    } else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
    }

    this.showconfirmpopup = false;

  }

  taxDropdownList() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      this.commonservices.getDynamicDropDownList("TaxDetails", this.configurationsetting.getBaseUrl())
        .subscribe(successResp => {
          this.showloader = false;
          if (successResp['errorCode'] == 0) {
            this.taxMasterList = successResp['Data'];

          } else {
            this.errormsg = successResp['errorDescription']
          }

        }, errorResp => {
          this.showloader = false;
          this.errormsg = errorResp['message']
        })
    } else {
      this.showloader = false;
      alert("Internet Connection is Not Available.");
    }
  }

  addTaxToSubTable(event) {
    if (this.isEditableCustTax) {
      for (let i = 0; i < this.subDynamicTable['tabledata'].length; i++) {
        if (i == this.custTaxId) {
          for (let j = 0; j < this.taxMasterList.length; j++) {
            if (event.form.value['tax'] == this.taxMasterList[j]['value']) {
              this.subDynamicTable['tabledata'][i]['taxdescription'] = this.taxMasterList[j]['display'];
              this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));

            }
          }

          // this.subDynamicTable['tabledata'][i]['taxdescription'] = event.form.value['tax'];
          this.subDynamicTable['tabledata'][i]['registrationno'] = event.form.value['registrationno'];
          this.subDynamicTable['tabledata'][i]['taxId'] = this.taxId;
          if (this.srNo == 0) {
            this.subDynamicTable['tabledata'][i]['SrNo'] = 0;

          } else if (this.srNo != 0) {
            this.subDynamicTable['tabledata'][i]['SrNo'] = this.srNo;

          }

        }
      }
      this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
      this.subtableconfig = this.subDynamicTable["tableconfig"];
      this.subtabledata = this.subDynamicTable["tabledata"];
      this.subtableheaders = this.subDynamicTable["tableheaders"];
      this.isEditableCustTax = false;
      this.showTaxModal.hide();
      this.showFormsPopup = false;
      this.operation = "";
    } else {
      let obj = {};
      for (let j = 0; j < this.taxMasterList.length; j++) {
        if (event.form.value['tax'] == this.taxMasterList[j]['value']) {
          obj["taxdescription"] = this.taxMasterList[j]['display'];

        }
      }
      obj["registrationno"] = event.form.value['registrationno'];
      obj["taxId"] = parseInt(event.form.value['tax']);
      obj["SrNo"] = 0;


      this.subDynamicTable['tabledata'].push(obj);
      this.subDynamicTable = JSON.parse(JSON.stringify(this.subDynamicTable));
      this.subtableconfig = this.subDynamicTable["tableconfig"];
      this.subtabledata = this.subDynamicTable["tabledata"];
      this.subtableheaders = this.subDynamicTable["tableheaders"];
    }
    this.displaySubTable = true;
    this.resetAddTaxForm.reset();
  }




  editCustTax(event) {
    if (navigator.onLine) {
      this.errormsg = "";
      this.operation = "";
      this.showloader = true;
      this.loaderTimeOut();
      this.subDynamicTable['tableconfig']['defaultsearch'] = false;
      this.custTaxId = event['rowindex']
      this.registartionNO = event['row']['registrationno'];
      // this.custTax = event['row']['taxdescription'];
      this.custTax = event['row']['taxId'];
      this.taxId = event['row']['taxId'];
      this.srNo = event['row']['SrNo'];
      this.showTaxModal.show();
      this.showFormsPopup = true;
      this.showloader = false;
      this.isEditableCustTax = true;
      this.operation = "editCustTax";
    }
    else {
      alert("Internet Connection is Not Available.");
    }
  }

  closePopUpEvent(whichform) {
    switch (whichform) {
      case 'closeModelPopup':
        this.showTaxModal.hide();
        this.showFormsPopup = false;
        this.operation = "";
        this.isEditableCustTax = false;
        this.resetAddTaxForm.reset();
        break;
      case 'deletepopup':
        this.showDeleteModal.hide();
        this.showFormsPopup = false;
        this.operation = "";
        this.isEditableCustTax = false;
        this.resetAddTaxForm.reset();

    }
  }
  editCustomerMaster(event) {
    if (navigator.onLine) {
      this.errormsg = "";
      this.operation = "";
      this.oprationForRowClick = "";
      this.indexForEdit = -1;
      this.showloader = true;
      this.loaderTimeOut();
      this.disableNewBtn = true;
      this.indexForEdit = event['row']['actualindex'];
      this.eventRowIndex = event['rowindex'];
      this.disableCustomerName = false;
      this.disableCustomerShortName = false;
      this.disablegstin = false;
      this.disableAddress1 = false;
      this.disableAddress2 = false;
      this.disableAddress3 = false;
      this.disablePincode = false;
      this.disableSaveBtn = false;
      this.saveBtnVisible = true;
      this.disablecancelBtn = false;
      this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
      this.subDynamicTable['tableconfig']['enablefirstbtn'] = true;
      this.subtableconfig = this.subDynamicTable["tableconfig"];
      this.subtabledata = this.subDynamicTable["tabledata"];
      this.subtableheaders = this.subDynamicTable["tableheaders"];
      this.displaySubTable = true;
      this.custNoForSave = event['row']['CustomerNo'];
      this.customerName = event['row']['customername'];
      this.customerShortName = event['row']['customershortname'];
      this.gstin = event['row']['gstin']
      this.address1 = event['row']['Address1'];
      this.address2 = event['row']['Address2'];
      this.pinCode = event['row']['Pincode'];

      if (event['row']['Address3'] == "null") {
        this.address3 = "";
      } else {
        this.address3 = event['row']['Address3'];

      }

      if (typeof (event['row']['TaxDetails']) == 'string') {
        this.subDynamicTable['tabledata'] = JSON.parse(event['row']['TaxDetails']);

      } else if (event['row']['taxdetails']) {
        this.subDynamicTable['tabledata'] = event['row']['taxdetails'];

      } else if (event['row']['TaxDetails'] == null) {
        this.subDynamicTable['tabledata'] = [];
      } else if (event['row']['TaxDetails'].length > 0) {
        this.subDynamicTable['tabledata'] = event['row']['TaxDetails'];
      } else if (event['row']['TaxDetails'].length == 0) {
        this.subDynamicTable['tabledata'] = [];

      }

      this.showloader = false;
      this.isEditable = true;
      this.visibleNewBtn = false;
      this.visibleSaveBtn = true;
      this.visibleCancelBtn = true;
      this.operation = "editCustomerMaster";
    }
    else {
      alert("Internet Connection is Not Available.");
    }

  }

  saveCustomerMaster(event) {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveCustomerMaster";
      let obj = {};
      obj['customername'] = event['form']['value']['customername'];
      obj['customershortname'] = event['form']['value']['customershortname'];
      obj['gstin'] = event['form']['value']['gstin'];
      obj['Address1'] = event['form']['value']['Address1'];
      obj['Address2'] = event['form']['value']['Address2'];
      obj['Address3'] = event['form']['value']['Address3'];
      obj['pincode'] = event['form']['value']['pincode'];
      obj['taxdetails'] = this.subDynamicTable['tabledata'];
      if (this.isEditable) {
        obj['customerno'] = this.custNoForSave;
        // this.isEditable = false;

        //false edit key here after testing
      } else {
        //send customerno=0 when it is a new entry i.e while saving
        obj['customerno'] = 0;
      }
      this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          // if(this.eve){
          //   this.rowClickCustMaster(this.eve, false);
          // }
          this.customerUniqueNo = successResp['Data'][0]['customerno'];
          this.getMasterTable();//change it
          /**code for not to hit service starts here but having some issue so thats why commented**/

          this.editBtn = false;
          this.visibleCancelBtn = false;
          this.deleteBtn = false;

          /*below code is to keep screen after updating as it is starts here***/
          if (this.isEditable) {
            this.editOperation = "saveCustomerMaster";
            this.isEditable = false;
            this.disableForm();
            this.disablecancelBtn = false;
            this.disableNewBtn = false;
            this.subDynamicTable['tableconfig']['enablefirstbtn'] = false;
            this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = false;
            this.oprationForRowClick = "clickedOnRow";
            if (this.subDynamicTable['tabledata'].length > 0) {
              this.subtableconfig = this.subDynamicTable["tableconfig"];
              this.subtabledata = this.subDynamicTable["tabledata"];
              this.subtableheaders = this.subDynamicTable["tableheaders"];
              this.displaySubTable = true;

            } else {
              this.subDynamicTable['tabledata'] = [];
              this.subtableconfig = this.subDynamicTable["tableconfig"];
              this.subtabledata = this.subDynamicTable["tabledata"];
              this.subtableheaders = this.subDynamicTable["tableheaders"];
              this.displaySubTable = false;
            }

          }
          else {

            /**code for not to hit service ends*/
            /*this below statement will resets the form values to null
            And resets css control like touched=false,dirty=false
            sets to pristine=true that is not modified by user.*/
            // this.resetCustomerForm.reset();
            this.subtableconfig = this.subDynamicTable["tableconfig"];
            this.subtabledata = this.subDynamicTable["tabledata"];
            this.subtableheaders = this.subDynamicTable["tableheaders"];
            this.displaySubTable = false;
            this.subDynamicTable['tabledata'] = [];
            this.subtableconfig = this.subDynamicTable["tableconfig"];
            this.subtabledata = this.subDynamicTable["tabledata"];
            this.subtableheaders = this.subDynamicTable["tableheaders"];
            this.disableForm();
          }
          /*below code is to keep screen after updating as it is ends here***/




          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);

          this.btnRow = false;
          this.disableNewBtn = false;
          this.visibleNewBtn = true;
          this.visibleSaveBtn = false;
          this.visibleCancelBtn = false;
          this.disablecancelBtn = false;


        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {

      alert("Internet Connection is Not Available.");

    }



  }

  editForm(event) {
    console.log(event);

    this.editCustomerMaster(event);
    this.disableNewBtn = true;
    this.visibleCancelBtn = true;
    this.editBtn = false;
    this.deleteBtn = true;
    // this.disableNewBtn = false;
    this.disableCustomerName = false;
    this.disableCustomerShortName = false;
    this.disablegstin = false;
    this.disableAddress1 = false;
    this.disableAddress2 = false;
    this.disableAddress3 = false;
    this.disablePincode = false;
    this.disableSaveBtn = false;
    this.saveBtnVisible = true;
    this.disablecancelBtn = false;
    this.editDisableBtn = true;

    this.subDynamicTable['tableconfig']['actioncolmconfig']['displaycolumn'] = true;
    this.subDynamicTable['tableconfig']['controlconfig']['displaycontrol'] = true;


  }

  getMasterTable() {
    this.errormsg = "";
    this.showloader = true;
    this.loaderTimeOut();
    this.commonservices.getGenericTable("CustomerMaster", this.configurationsetting.getBaseUrl())
      .subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.dynamicTable = successResp['Data'][0]['@oritable'];
          this.dynamicTable['tabledata'] = successResp['Data'][0]['@tbldata'];
          this.subDynamicTable = successResp['Data'][0]['@subtable'];
          /*********changes starts here********/
          if (this.userRole == "Admin") {
            this.dynamicTable['tableconfig']['actioncolmconfig']['delete'] = true;
          } else {
            this.dynamicTable['tableconfig']['actioncolmconfig']['delete'] = false;
          }
          // this.tableconfig =  this.dynamicTable["tableconfig"];
          this.tabledata = this.dynamicTable["tabledata"];
          this.tableheaders = this.dynamicTable["tableheaders"];
          this.subtableconfig = this.subDynamicTable["tableconfig"];
          this.subtabledata = this.subDynamicTable["tabledata"];
          this.subtableheaders = this.subDynamicTable["tableheaders"];

          let rowData = successResp['Data'][0]['@tbldata']
          for (let i = 0; i < rowData.length; i++) {
            if (rowData[i]['CustomerNo'] == this.customerUniqueNo) {
              // console.log("Index", this.tabledata[i], i);
              this.rowClickCustMaster({
                "row": rowData[i],
                "rowindex": i
              })
              setTimeout(() => {
                $(`tr[tabindex=${i}]`).focus();
                // document.getElementById('trtable1' + i).style.backgroundColor = "#7de4f0";
              }, 900);
              break;
            }
          }
          /********chnages ends here*********/
          this.displayTable = true;
          this.displaySubTable = true;
          this.disableForm();
        }
        else {
          this.errormsg = successResp["errorDescription"];
        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });
  }



  cancelCustomerMaster() {
    if (confirm("Do you want to cancel?")) {
      if (this.operation == 'NEW') {
        this.resetCustomerForm.reset();
        this.btnRow = false;
        this.editBtn = false;
      } else {
        this.btnRow = true;
        this.editBtn = true;

      }
      this.deleteMsg = "";
      this.visibleCancelBtn = false;
      this.disableCustomerName = true;
      this.disableCustomerShortName = true;
      this.disablegstin = true;
      this.disableAddress1 = true;
      this.disableAddress2 = true;
      this.disableAddress3 = true;
      this.disablePincode = true;
      this.disableSaveBtn = true;
      this.saveBtnVisible = false;
      this.disablecancelBtn = false;
      this.visibleCancelBtn = false;
      this.deleteBtn = false;
      this.disableNewBtn = false
    }
  }

  confirmPopup(event) {
    if (this.opeartionStageForDelete == "delete") {
      this.opeartionStageForDelete = "";
      this.confirmDeleteCustMaster();
    }
    else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
      this.getMasterTable();

    } else if (this.opeartionStageForSubDelete == "deleteCustTax") {
      this.opeartionStageForSubDelete = "";
      this.confirmDeleteCustTax();
    }

    if (this.editOperation == "saveCustomerMaster") {

      this.editOperation = "";
      this.getMasterTable();
    }
    this.showconfirmpopup = false;
  }
  //displayMessagePopup() is used to call and maintain confirmation popup modal
  displayMessagePopup(popupmessage, submittext, displaysubmit, canceltext, displaycancel) {
    this.confirmationpopupmodelobj.inputmsg = popupmessage;
    this.confirmationpopupmodelobj.submitbtntext = submittext
    this.confirmationpopupmodelobj.displaysubmit = displaysubmit;
    this.confirmationpopupmodelobj.cancelbtntext = canceltext;
    this.confirmationpopupmodelobj.displaycancel = displaycancel;
    this.showconfirmpopup = true;
  }

  //////if http not gave the response after spend 30 seconds the we will show the error.
  loaderTimeOut() {
    setTimeout(() => {
      if (this.showloader) {
        this.showloader = false;
        this.errormsg = "Error while login your account.";
      }
    }, 50000)
  }


}
