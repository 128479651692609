import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SubmitFormService } from '../../../services/submit-form.service';
import { ValidationService } from '../../../services/validation.service';
import { ValidateObjectService } from '../../../services/validate-object.service';
import { ApiService } from '../../../services/api.service';
import { ConfigurationSetting } from '../../../appconfig';
import { CommonServicesService } from '../../../services/common-services.service';
import { DatePipe, Location } from '@angular/common';
import { SystemParamsService } from '../../../services/system-params.service';
import { HelperService } from '../../../services/helper.service';
import { ToastService } from '../../../services/toast.service';
import { REJECTED_OUT_CONFIRM_PO_TABLE_JSON, REJECTED_OUT_DETAILED_TABLE_JSON, REJECTED_OUT_LEFT_SIDE_TABLE_JSON, REJECTED_OUT_SELECTITEM_TABLE_JSON } from './rejectedout-gtconfig';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonFunctionService } from '../../../services/common-function.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var $;

@Component({
  selector: 'app-rejected-out',
  templateUrl: './rejected-out.component.html',
  styleUrls: ['./rejected-out.component.scss']
})
export class RejectedOutComponent implements OnInit {
  @ViewChild('showPopupoSelectConfirmPo', { static: false }) public showPopupoSelectConfirmPo;
  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('ShowSelectItemPopup', { static: false }) public ShowSelectItemPopup;
  @ViewChild('ShowPopupToBillOrNot', { static: false }) public ShowPopupToBillOrNot;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('printPopup', { static: false }) public printPopup;
  @ViewChild('showPrintESNPopupModal', { static: false }) public showPrintESNPopupModal;

  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;

  fileName: string = "";
  dataLocalUrl: any;
  outwardType: string = 'RO'; // Rejected Outward
  selectedPoNumber: boolean = false;
  label: string = "Material Rejected Outward";
  RejectedOutwardForm: boolean = true;
  showFormBtn: boolean = true;
  showCloseBtn: boolean = false;
  isPopUpOpen: boolean = false;
  rejoutwardtype: string;
  showPopupoPO: boolean = false
  saveBtnVisible: boolean = false;
  saveBtn: boolean = true;
  editBtn: boolean = false;
  editDisableBtn: boolean = false;
  addItemButton: boolean = false;
  NewBtn: boolean = true;
  selectPoBtn: boolean = true;
  CreateNew: boolean = false;
  cancelbtn: boolean = false;
  isPoPopUpOpen: boolean = false;
  disabledSelectItemBtn: boolean = true;
  currentSelectedRow: number;
  maxDate = new Date();
  minDate;
  pofielddisable: boolean = true
  isRowClicked: boolean = false;
  pofieldshow: boolean = false;
  tabledataforprocess: any[] = [];
  processDataForItemSelection: any[] = [];
  deleteBtnDisable: boolean = false;
  todaysDate: any = [];
  formType: string;
  PoNoArray: any[] = [];
  deleteMsg: string = "";
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  btnRow: boolean = false;
  emptySearchField: boolean = true;
  rejoutwarduniqueno: number = 0;
  showFields: boolean = true;
  dataForPrint: any = {};
  openPopup: boolean = false;
  // public text: string = 'Create New Rejected-Outward';
  text = "Add New Ro"
  private backupData: any = { bindData: {} };
  deletedArray: number[] = [];
  setPrNo: number = 0;
  selectedPrNo;
  customerNameArray: any[] = [];
  operation: string = '';
  private backuptabledataforData: any = [];
  priviousQty: number = 0;
  poBalanceQuantity: number = 0;
  RejectedItemNo: number = 0;
  invoiceCreatedCount: number = 0;
  isFilterRemoved:boolean = false;
  unFilterColumn:string = '';
  rejtabledataforprocess: any[] = [];
  refreshDetailTable:boolean = true;

  model = {
    dcoutno: {
      valuType: 'String', value: '', label: "Rejected Outward ", isRequired: false,
      disable: true, maxLength: 4, err: "", element: { name: 'input', type: 'text' }
    },
    dcoutwardsrno: {
      valuType: 'String', value: '', label: "Rejected Outward ", isRequired: false,
      disable: true, maxLength: 4, err: "", element: { name: 'input', type: 'text' }
    },
    outwarddate: {
      valuType: 'Date', value: '', label: "Outward Date", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'date' }
    },
    customerno: {
      valuType: 'String', value: '', label: "Customer Name", isRequired: true,
      disable: true, maxLength: 100, err: "", element: { name: 'input', type: 'text' }
    },
    jmremark: {
      valuType: 'String', value: '', label: "Remark", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    pono: {
      valuType: 'String', value: '', label: 'P.O', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    showpono: {
      valuType: 'String', value: '', label: 'P.O', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    podetailsrno: {
      valuType: 'Number', value: '', label: 'P.O', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isrejected: {
      valuType: 'String', value: '', label: 'Is Rejected', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    poqtybalance: {
      valuType: 'Number', value: '', label: 'P.O', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  sidetableconfig: any = REJECTED_OUT_LEFT_SIDE_TABLE_JSON.tableconfig;
  sidetableheaders: any = REJECTED_OUT_LEFT_SIDE_TABLE_JSON.tableheaders;
  sidetabledata: any = [];


  rejoutwardtableconfig: any = REJECTED_OUT_DETAILED_TABLE_JSON.tableconfig;
  rejoutwardtableheaders: any = REJECTED_OUT_DETAILED_TABLE_JSON.tableheaders;
  rejoutwardtabledata: any[] = [];


  // Filter option in Popup
  selectitemstableconfig: any = REJECTED_OUT_SELECTITEM_TABLE_JSON.tableconfig;
  selectitemstableheaders: any = REJECTED_OUT_SELECTITEM_TABLE_JSON.tableheaders;
  selectitemstabledata: any[] = [];

  // Popup to select Po

  confirmPotableconfig: any = REJECTED_OUT_CONFIRM_PO_TABLE_JSON.tableconfig;
  confirmPotableheader: any = REJECTED_OUT_CONFIRM_PO_TABLE_JSON.tableheaders;
  confirmPotabledata: any = [];

  refreshtable: boolean = true;

  constructor(private ngbActiveModal: NgbActiveModal,private domSanitizer: DomSanitizer, private validator: ValidationService, private validationObj: ValidateObjectService, private submit: SubmitFormService, private apidata: ApiService, public commonService: CommonServicesService,
    private configurationsetting: ConfigurationSetting, private datepipe: DatePipe, public systemParamsService: SystemParamsService, private helper: HelperService, private toastService: ToastService,
    private CommonFunction: CommonFunctionService) { }

  ngOnInit(): void {
    this.getAllRejOutward();
    this.getCustomerName();
    if (this.isOpenFromReport) {
      this.getDataOnClick(this.transactionID)
    }
  }


  CreateNewRO() {
    this.emptySearchField = false;
    this.label = "Add Material Rejected Outward";
    this.saveBtn = true;
    this.editBtn = false;
    this.text = "Add Row";
    this.operation = 'new';
    this.CreateNew = true;
    this.cancelbtn = true;
    this.btnRow = true;
    this.deleteMsg = ""
    this.saveBtnVisible = true;
    this.addItemButton = true;
    this.selectPoBtn = false;
    this.NewBtn = false;
    this.rejoutwardtabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.ShowPopupToBillOrNot.show();
    this.todaysDate = new Date();
    this.model.outwarddate.value = this.todaysDate;
    this.model.jmremark.value = " "
    this.rejoutwardtableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.rejoutwardtableheaders = this.commonService.enableEditForGt(this.rejoutwardtableheaders, "quantity", true);
    const ele = document.getElementById(`tr${"rejectedouttbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    delete this.backupData.bindData;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }


  saveRejectedOutForm() {
    let data = this.submit.submitForm(this.model);

    if (data) {
      this.saveBtn = true;
      let editedRow = [];
 //  console.log("Parent saveRejectedOutForm normal for loop");

      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.tabledataforprocess[i]['quantity'] <= 0 || (this.tabledataforprocess[i]['quantity'] <= "" || null || undefined)) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        if (this.tabledataforprocess[i]?.rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i]);
        }
      }
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      data["criteria"]["outwardtype"] = this.outwardType;
      this.deletedArray = [];
      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      let endpoint = 'transaction/insertOutwardTransaction';
      if (data["criteria"]['dcoutwardsrno']) {
        endpoint = 'transaction/updateOutwardTransaction';
        this.ErrorDescription = "Data Updated Successfully";
        data["criteria"]["dcoutwardlist"] = editedRow;
      } else {
        data["criteria"]["dcoutwardlist"] = this.tabledataforprocess;
        this.ErrorDescription = "Data Inserted Successfully";
      }

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.rejoutwarduniqueno = res['Data'];
          this.saveBtn = true;
          this.addItemButton = false;
          this.cancelbtn = false;
          this.CreateNew = false;
          this.saveBtnVisible = false;
          this.editBtn = true;
          this.NewBtn = true;
          this.pofieldshow = false;
          this.text = "Add New Ro";
          this.editBtn = false;
          this.commonService.diasbleFormFields(this.model);
          this.rejoutwardtableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.rejoutwardtableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.rejoutwardtableheaders), "quantity", false);
          this.deleteBtnDisable = false;
          this.getAllRejOutward();

          this.PopupMessageModal.show();
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });

    }
  }

  alertDeleteConfirmation() {
    if (this.invoiceCreatedCount > 0) {
      this.ErrorDescription = 'Can not delete this transaction. Because invoice has been created for this transaction.';
      this.PopupMessageModal.show();
    } else {
      this.deleteRejOutwardForm()
    }
  }

  deleteRejOutwardForm() {
 //  console.log("delete");

    let data = this.submit.submitForm(this.model);
 //  console.log(data);

    if (data && data["criteria"]["dcoutwardsrno"]) {

      data["criteria"]["dcoutwardlist"] = this.tabledataforprocess;

   //  console.log(data["dcoutwardlist"]);

      let items: any = {};
      items.dcoutwardsrno = data["criteria"]["dcoutwardsrno"];
      items.customerno = data["criteria"]["customerno"];
      items.type = this.outwardType;
   //  console.log(this.outwardType, items);

      items.delLineItems = [];
   //  console.log("Parent deleteRejOutwardForm ForEach");

      data["criteria"]["dcoutwardlist"].forEach((ele) => {
        delete ele?.qty;
        if (ele?.SrNo) {
          items.delLineItems.push(ele?.SrNo);
        }
      })

      data["criteria"] = items;

      let endpoint = 'transaction/deleteOutwardTransaction';

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.saveBtn = true;
          this.saveBtnVisible = false;

          this.deleteBtnDisable = false;
          this.clearAll(false);
          this.getAllRejOutward();
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
          this.showDeleteModal.hide();

          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }


  bindDataToFormAndTable(modal, flag: boolean = true) {
    if (this.model.jmremark.disable == false && flag) {
      confirm("Enable to edit!");
      return;
    }
    this.label = "Display Material Rejected Outward";
    this.refreshDetailTable = false;

    this.backupData.bindData = this.helper.removeRefrence(modal);
    this.model.dcoutno.value = modal['dcoutno'];
    this.model.poqtybalance.value = modal['poqtybalance']
    this.invoiceCreatedCount = modal['invoicemadecount']
    this.poBalanceQuantity = +this.model.poqtybalance.value;
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    if (modal["isDeleted"] === 1) {
   //  console.log("Parent bindDataToFormAndTable for loop");
      
      for (let item in this.model) {
        this.model[item].value = '';
        this.rejoutwardtabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"rejectedouttbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }

    if (modal['isrejected'] == 'Y') {
      this.pofieldshow = true;
      this.showInvColumn(true);
    } else {
      this.pofieldshow = false;
      this.showInvColumn(false);
    }
    this.rejoutwardtableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.rejoutwardtableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.rejoutwardtableheaders), "quantity", false);
    this.rejoutwardtabledata = modal['dcoutwardlist']?.length ? modal['dcoutwardlist'] : [];
 //  console.log("Parent bindDataToFormAndTable filter");

    let newArr = this.rejoutwardtabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.rejoutwardtabledata = newArr;
    // console.log(newArr);
    this.editBtn = true;
    setTimeout(() => {
    this.refreshDetailTable = true;
    }, 10);
    this.backuptabledataforData = this.helper.removeRefrence(this.rejoutwardtabledata)

    this.addItemButton = false;
    this.cancelbtn = false;
    this.CreateNew = false;
    this.deleteBtnDisable = false;

    this.editDisableBtn = false;
    this.commonService.diasbleFormFields(this.model);
    this.isRowClicked = false;
    this.selectPoBtn = true;
    this.saveBtnVisible = false;


  }

  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.outwarddate.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.sidetabledata, modal.rowindex, this.currentSelectedRow, "rejectedouttbl1");

    let row = modal['row'];
 //  console.log(row);

    let rejNo = row['dcoutwardsrno'];
    this.getDataOnClick(rejNo);

  }

  getDataOnClick(no: any) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getDcOutwardById";
    let params = { dcoutwardsrno: no, outwardtype: this.outwardType };
 //  console.log(params);

    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data'];
        this.bindDataToFormAndTable(item);
        this.dataForPrint = item;

      }
    }, (error) => {
   //  console.log(error);
    });
  }

  printPdf() {
    this.showPrintESNPopupModal.show();
    // this.downloadPdf(this.dataForPrint);

  }

  downloadPdf(value) {

    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/generateoutwardpdf";
    let body = { "criteria": { "transId": this.model.dcoutwardsrno.value, "printEsnDetails":value, printPdfFor:this.outwardType } }

    this.commonService.postMethod(apiUrl, body).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
     //  console.log("success");
        this.fileName = successResp['Data']['filename'];

        this.downloadFile(this.fileName)
      }
      else {
        this.ErrorDescription = "Something went wrong, please contact admin";
      }
    },
      err => {
        this.ErrorDescription = "Something went wrong, please contact admin";
      });
  }

  downloadFile(filename: string) {
    let body = { "filename": filename }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/downloadpdf";
    this.commonService.getFile(apiUrl, body) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        // saveAs(blob, this.fileName);
        let a = document.createElement('a');

        let file = new Blob([blob], { type: 'application/pdf' });
     //  console.log("file ", file);

        let fileURL = URL.createObjectURL(file);

        this.dataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
        a.download = this.fileName;
        setTimeout(() => {
          this.printPopup.show();
        }, 200);

        this.fileName = "";
      })
      .catch((err) => {
     //  console.log("Something went wrong");
      });

  }

  getAllRejOutward() {
    let params = { formname: this.outwardType };
    const endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getAllMaterialOutward';
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];

        this.sidetabledata = []
        this.sidetabledata = items;
     //  console.log("Parent getAllRejOutward normal for loop");
        
        for (let i = 0; i < items.length; i++) {
          if (items[i]['dcoutwardsrno'] == this.rejoutwarduniqueno || items[i]['dcoutwardsrno'] == this.model.dcoutwardsrno.value) {
            // console.log("Index", items[i], i);
            this.model.dcoutwardsrno.value = items[i]['dcoutwardsrno'];
            this.getDataOnClick(this.model.dcoutwardsrno.value);
            if (!this.isOpenFromReport) {
            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trrejectedouttbl1' + i).style.backgroundColor = "#7de4f0";
            }, 900);
          }
            break;
          }
        }


        // this.isPoUpOpen = true;

      }
    }, err => { })

  }

  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      } else {
        if (successResp['errorDescription']) {
          // alert("Error Occured!");
        }
      }
    }, err => { })
  }

  selectItemsDataBind(modal) {
    let row = modal['row'];
    this.model = this.commonService.setFromKeysValues(this.model, row);
 //  console.log(this.formType);
 //  console.log(row.itemsrno);
    if (row.itemsrno) {
      this.getDetailsForOutward(row.itemsrno, this.formType);
    }
    this.ShowSelectItemPopup.hide();

  }

  getDetailsForOutward(itemsrno: any, type: string) {
    let params = { itemNo: itemsrno, "formname": type };
 //  console.log(params);
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/displayMaterialOutwardList";

    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']["dcoutwardlist"];
     //  console.log("Parent getDetailsForOutward ForEach");

        this.rejoutwardtabledata = item;
        this.rejoutwardtabledata.forEach((ele) => {
          ele['quantity'] = 0;
        })
      }
    }, (error) => {
   //  console.log(error);
    });

  }

  getConfirmPoList() {
    let endpoint = 'transaction/getRejectedPoList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.confirmPotabledata = [];
        this.confirmPotabledata = items;
     //  console.log("Parent getConfirmPoList ForEach");

        this.confirmPotabledata.forEach((data) => {
          this.PoNoArray.push({ value: data.PrNo, display: data.PoNo })
        })
     //  console.log(this.PoNoArray);

      }
    }, err => { })
  }


  updatedtabledata(data: any) {
    console.log('in update table data',data);
    
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;
    if (data.length) {
      this.saveBtn = false;
    } else {
      this.saveBtn = true;
    }

    let totalTableQty = 0;
 //  console.log("Parent updatedtabledata ForEach");

    data.forEach((item, i) => {
      if (this.operation == 'edit') {
        item['rowmodifiedstatus'] = 'M';
      }
      if (this.backuptabledataforData[i] && this.operation === 'edit' && this.model.isrejected.value == 'Y') {

        if (!item['invoicemadeqty']) {
          item['invoicemadeqty'] = 0;
        }
        this.priviousQty = this.backuptabledataforData[i]["quantity"]

        // totalqty is addition of invoicependingqty, inwardmaxqtyfordc, invoicemadeqty

        item['totalqty'] = item['invoicependingqty'] + item['inwardmaxqtyfordc'] + item['invoicemadeqty'];

        item.invoicemadeqty = item.invoicemadeqty ? item.invoicemadeqty : 0;

        //invoicemadeqty = invoice made count
        // invoicemadeqty made quantity is less the quantity.

        if(item['quantity'] <  item['invoicemadeqty']) {
          this.ErrorDescription = `Invoice of ${ item['invoicemadeqty']} has created. so qty should be greater than ${ item['invoicemadeqty']}`;
          item['quantity'] =  item['hiddenoutquantity'];
          // this.errorMessageModal.show();
        }
        item['totalpobalqty'] = (+this.priviousQty + +this.model.poqtybalance.value)
        if ((item['quantity'] > item['totalqty'] && item['quantity'] < item['invoicemadeqty'])) {
          item['quantity'] = this.backuptabledataforData[i]["quantity"]
        }

      } else if ((this.operation === 'new' || !item['SrNo']) && this.model.isrejected.value == 'Y') {
        totalTableQty += item['quantity'];


        item['totalqty'] = item['maxquantity']
        if (item['quantity'] > item['totalqty']) {
          // item['quantity'] = this.backuptabledataforData[i]["quantity"];
        }
      } else if (this.model.isrejected.value == 'N') {
        item['totalqty'] = item['maxquantity']
        if (item['quantity'] > item['totalqty']) {
          if(this.operation == 'edit'){
            this.ErrorDescription = `Qty should not be greater than ${item.totalqty}. `; //item.inwardmaxqtyfordc + item.oldquantity
            this.errorMessageModal.show();
            item['quantity'] = item['hiddenoutquantity'];
          }else{
            item['quantity'] = item['hiddenoutquantity'];
          }
        }
      }


    })
  }

  rowUpdate(data) {

    data.row.rowmodifiedstatus = 'M';

    const subAsyNo = data?.row?.subAsyNo;
    
    // if (this.operation === 'edit' && data['keyName'] == 'quantity' && (data?.row?.quantity > data?.row?.totalqty || data?.row?.quantity < 0) || data?.row?.quantity < data?.row?.invoicemadeqty) {
    //   // this.ErrorDescription = `Invoice of ${data?.row?.invoicemadeqty == 0 ? data?.row?.invoicemadeqty + 1 : data?.row?.invoicemadeqty} quantity has been created, You can outward transaction for qty ${data?.row?.invoicemadeqty == 0 ? data?.row?.invoicemadeqty + 1 : data?.row?.invoicemadeqty} to ${data?.row?.totalqty}. `;
    //   this.ErrorDescription = `Enter valid Quantity. Can not enter Quantity greater than ${data?.row?.totalqty}.`
    //   data.row.quantity = data.row.hiddenoutquantity;
    //   this.errorMessageModal.show();
    // } else if (this.operation === 'edit' && data['keyName'] == 'quantity' && data?.row?.quantity > data?.row?.totalqty) {
    //   this.ErrorDescription = `Qty should not be greater than ${data?.row?.inwardmaxqtyfordc + data?.row?.oldquantity}. `;
    //   // data.row.quantity = this.priviousQty;
    //   this.errorMessageModal.show();
    // }

    if (this.operation === 'edit' && data['keyName'] == 'quantity' && (data?.row?.quantity > data?.row?.totalqty || data?.row?.quantity < 0) || data?.row?.quantity < data?.row?.invoicemadeqty) {
      this.ErrorDescription = `Enter valid Quantity. Can not enter Quantity greater than ${data?.row?.totalqty}. `;
      // data.row.quantity = this.priviousQty;
      // this.errorMessageModal.show();
    } else if (data['keyName'] == 'quantity' && data?.row?.quantity > data?.row?.totalqty || data?.row?.quantity < 0) {
      this.ErrorDescription = `Enter quantity valid quantity i.e. 1 to ${data?.row?.totalqty}. `;
      // data.row.quantity = this.priviousQty;
      // this.errorMessageModal.show();
    }

    data.row.invoicemadeqty = data?.row?.invoicemadeqty ? data?.row?.invoicemadeqty : 0;
    if (data['keyName'] == 'quantity' && data?.row?.quantity < data?.row?.invoicemadeqty) {
      data.row.rowmodifiedstatus = 'M';
      this.ErrorDescription = `Invoice of ${data?.row?.invoicemadeqty} has created. so qty should be greater than ${data?.row?.invoicemadeqty}`;
      data.row.quantity = data.row.hiddenoutquantity;
      this.errorMessageModal.show();
    }
 
    if (data['keyName'] == 'quantity' && data['eventName'] == 'Blur' && this.model.isrejected.value == 'Y') {
      let maxQty = 0;
  
      if(this.operation == 'edit'){
        let oldQty = data?.row?.oldquantity ? +data?.row?.oldquantity:0;
        maxQty = +data?.row?.inwardmaxqtyfordc + oldQty;
      }else{
        maxQty = data?.row?.inwardmaxqtyfordc;
      }

      const allowedOutQuantity = this.decideOutQty(subAsyNo, maxQty, data?.row?.quantity, data?.row?.hiddenoutquantity, 'outquantity');
      data.row.quantity = allowedOutQuantity;
      data.row.hiddenoutquantity = allowedOutQuantity;
      this.rejoutwardtabledata = this.tabledataforprocess;
    }
   
  }

  // Open New Button Popup
  selectItemPopUp() {
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.isPoPopUpOpen = true;
    this.ShowSelectItemPopup.show();
    this.openPopup = true;
    this.getSelectItemsForRejecetedOutward(this.rejoutwardtype);
  }

  //Enable Po onclick of yes Button
  bindPoNoAndGetItem(modal) {
    let row = modal['row'];
 //  console.log(row);
    this.model = this.commonService.setFromKeysValues(this.model, row);

    this.model.pono.value = row.PrNo;
    this.selectedPrNo = row.PrNo;
    this.model.showpono.value = `${row.PoNo} - ${row.description}`;
    this.disabledSelectItemBtn = false;
    this.poBalanceQuantity = row?.poqtybalance;
    this.RejectedItemNo = row?.ItemSrNo;
    this.model.customerno.value = row?.CustomerNo;
    this.model.podetailsrno.value = row?.pdsrno;
    this.rejoutwardtabledata = [];
    this.cancelPopup('showPopupoSelectConfirmPoClose');

  }

  yesToPrintBill(result: string) {
    this.rejoutwardtype = "rejectedyes";
    this.model.isrejected.value = result;
    this.pofieldshow = true;
    this.pofielddisable = false;
    this.ShowPopupToBillOrNot.hide();
    this.selectitemstableconfig['checkedfilter']['checkOnKey'] = 'none';
    this.selectitemstableconfig['checkedrecordscondition']['selectedFieldsArray'][0]['key'] = 'outquantity';
    this.selectitemstableheaders[0]['datatype'] = 'checkbox';

    this.showInvColumn(true);
  }

  noToPrintBill(result: string) {
    this.rejoutwardtype = "rejectedno";
    this.model.isrejected.value = result;
    this.disabledSelectItemBtn = false;
    this.ShowPopupToBillOrNot.hide();
    this.selectedPrNo = 0;
    this.selectitemstableconfig['checkedfilter']['checkOnKey'] = 'outquantity';
    this.selectitemstableconfig['checkedrecordscondition']['selectedFieldsArray'][0]['key'] = 'outquantity';
    this.selectitemstableheaders[0]['datatype'] = 'checkboxfilter';
    this.showInvColumn(false);
  }

  showInvColumn(flag) {
 //  console.log("Parent showInvColumn ForEach");

    this.rejoutwardtableheaders.forEach((item) => {
      if (item['key'] == 'invoicependingqty') {
        item['visiblecolm'] = flag;
      }
    })
  }
  deepCopyForDcoutItems: any = {}
  getSelectItemsForRejecetedOutward(type: string) {
 //  console.log(this.model.isrejected.value);

    if (this.model.isrejected.value == "Y") {
      type = "rejectedyes";
    } else {
      type = "rejectedno";
    }

    let params = { "formname": type, prno: this.model.pono.value, custno: this.model.customerno.value };

    this.formType = type;
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getMaterialOutwardLineItem";
    this.commonService.getWithParams(endpoint, params).subscribe((successResp) => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        
        this.selectitemstabledata = items;

        this.deepCopyForDcoutItems = this.helper.removeRefrence(items);

        this.filterItemsTableIfExistingSubTable();
      }
    }, (error) => {
   //  console.log(error);
    });
  }

  filterItemsTableIfExistingSubTable() {
    let preparedarr = [];
    let inwardno;
 //  console.log("Parent filterItemsTableIfExistingSubTable filter 1");

    preparedarr = this.deepCopyForDcoutItems.filter(row => {
      let recavailable: boolean = true;
   //  console.log("Parent filterItemsTableIfExistingSubTable normal for loop");

      for (let ind = 0; ind < this.rejoutwardtabledata.length; ind++) {
        if (this.rejoutwardtabledata[ind]['refrenceno'] == row['refrenceno']) {
          inwardno = this.rejoutwardtabledata[ind]['dnno']
          recavailable = false;
        }
      }
      return recavailable;
    });
    this.selectitemstabledata = []
    this.selectitemstabledata = preparedarr;

    if (this.model.customerno.value) {
 //  console.log("Parent filterItemsTableIfExistingSubTable filter 2");

      let filterwithdnno = preparedarr.filter(row => {
        if (this.model.customerno.value) {
          this.selectitemstableconfig['checkedfilter']['checkOnKey'] = 'none';
          this.selectitemstableconfig['checkedrecordscondition']['selectedFieldsArray'][0]['key'] = 'outquantity';
          this.selectitemstableheaders[0]['datatype'] = 'checkbox';
          return row.customerNo === this.model.customerno.value;
        }
      })
      this.selectitemstabledata = filterwithdnno;
    } else {
      this.selectitemstabledata = preparedarr;
    }
  }

  removeCustomerInNoData(){
    if(!this.tabledataforprocess.length){
      this.model.customerno.value = '';
    }
  }

  removeFilterEmit(data){
    this.isFilterRemoved = data['isFilterRemoved'];
    this.unFilterColumn = data['unFilterColumn'];
 //  console.log(this.isFilterRemoved, this.unFilterColumn, data);

  }

  updatedpopuptabledata(data){
    if(this.isFilterRemoved && this.unFilterColumn == 'customername'){
      // data.forEach((item)=>{
      //   if(item['checked']){
      //     item['checked'] = false;
      //   }
      //   if(item['outquantity']){
      //     item['outquantity'] = 0;
      //   }
      // })
    }
    //  console.log(data);
    //  ;
  }

  //Close Popup
  cancelPopup(whichForm) {
    this.isPopUpOpen = false;

    switch (whichForm) {
      case 'closenewpopup':
        this.ShowPopupToBillOrNot.hide();
        this.NewBtn = true;
        this.clearAll(false);
        break;
      case 'SelectItemPopupClose':
        this.ShowSelectItemPopup.hide()
        this.isPoPopUpOpen = false;

        break;
      case 'Backenderr':
        this.backendErrorMessageModal.hide();
        break;
      case 'deletepopup':
        this.showDeleteModal.hide();
        break;
      case 'qtyerropopup':
        this.errorMessageModal.hide();
        break;
      case 'showPopupoSelectConfirmPoClose':
        this.showPopupoSelectConfirmPo.hide()
        break;
      case 'qtyalert':
        this.showItemAlert.hide();
        break;

      case 'msg':
        this.PopupMessageModal.hide();
        break;
    }
  }

  showPopupToSelectPo() {
    this.getConfirmPoList();
    this.refreshtable = false;
    this.showPopupoSelectConfirmPo.show();
    this.showPopupoPO = true;
    setTimeout(() => {
      this.refreshtable = true;
    }, 30);
  }




  editForm() {
    this.label = "Edit Material Rejected Outward";

    this.rejoutwardtableconfig['actioncolmconfig']['displaycolumn'] = true;

    this.rejoutwardtableheaders = this.commonService.enableEditForGt(this.rejoutwardtableheaders, "quantity", true);
    this.deleteBtnDisable = true;
    this.saveBtn = false;
    this.operation = 'edit';
    this.saveBtnVisible = true;
    this.addItemButton = true;
    // this.CreateNew = true;
    this.editBtn = false;
    this.cancelbtn = true;
    this.NewBtn = false;
    this.editDisableBtn = true;
    this.selectPoBtn = true;
    this.disabledSelectItemBtn = false;
    this.commonService.enableFormFields(this.model);
  }


  clearAll(flag: boolean = true) {
    this.label = "Material Rejected Outward";

 //  console.log('editBtn', this.editBtn, 'editDisableBtn', this.editDisableBtn);
    this.commonService.clearForm(this.model);
    this.rejoutwardtabledata = [];
    this.btnRow = true;
    this.deleteMsg = "";
    this.addItemButton = false;
    this.cancelbtn = false;
    this.CreateNew = false;
    this.saveBtn = true;
    this.operation = '';
    this.saveBtnVisible = false;
    this.NewBtn = true;
    this.pofieldshow = false;
    this.disabledSelectItemBtn = true;
    this.text = "Add New Ro";
    this.showInvColumn(false);
    // this.NewBtn = true;
    this.commonService.diasbleFormFields(this.model);
    this.rejoutwardtableconfig['actioncolmconfig']['displaycolumn'] = true;

    const ele = document.getElementById(`tr${"rejectedouttbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editDisableBtn && this.backupData['bindData'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindData, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;

    }

  }

  selectRejItemsList(itemdata) {
 //  console.log(itemdata);
    this.openPopup = false;
    let deepCopy = this.helper.removeRefrence(this.tabledataforprocess);
    itemdata = this.helper.removeRefrence(itemdata);
 //  console.log("Parent selectRejItemsList normal for loop 1");

    for (let i = 0; i < itemdata.length; i++) {
 //  console.log("Parent selectRejItemsList normal for loop 2");

      for (let j = i; j < itemdata.length; j++) {
        if (this.rejoutwardtype == "rejectedno" || this.model.isrejected.value == "Y") {
          if (itemdata[i].customerNo == itemdata[j].customerNo) {
            if (this.model.customerno.value && this.model.customerno.value !== itemdata[i].customerNo) {
              this.toastService.showToastMsg('warning', 'Warning', "Can not select different customer data");
              return;
            }
            this.model.customerno.value = itemdata[i].customerNo;
          }
        }

        // if (itemdata[i].dnno !== itemdata[j].dnno) {
        //   this.toastService.showToastMsg('warning', 'Warning', "Can not select different delivery note");
        //   return;
        // }

      }
      let {
        dcsrno, dcno, Releases, issuedate, dnno, itemsrno, itemno, itemname, jmdescription, modelname, quantity, outquantity, maxquantity, invoicependingqty, esn, customerNo, customername, customernm, jmremark: remark, engtypejeop, esncustomer, jobno, refrenceno, SrNo1, SrNo2, RejectedItemNo
      } = itemdata[i]
      deepCopy.push({ dcsrno, dcno, Releases, issuedate, dnno, itemsrno, itemno, itemname, jmdescription, modelname, inwardmaxqtyfordc:quantity, quantity: outquantity,hiddenoutquantity:outquantity, maxquantity, invoicependingqty: 0, esn, customerNo, customername, customernm, remark, engtypejeop, esncustomer, jobno, refrenceno, SrNo1, SrNo2, RejectedItemNo: this.RejectedItemNo });
    }

    this.rejoutwardtabledata = [];
    this.rejoutwardtabledata = deepCopy;
    this.cancelPopup('SelectItemPopupClose');
  }


  cancel() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
        // this.CreateNew = false;

      } else {
        this.clearAll(true);
        // this.CreateNew = true;

        // if(this.saveBtn && this.cancelbtn){
        //   this.clearAll(false);
        //   this.CreateNew = false;
        // }
      }
    }
  }

  deleteRows(events) {
    let row = events['row'];
    if((row['quantity'] != row['invoicependingqty']) && row['invoicemadeqty']){
      this.ErrorDescription = 'Can not delete this item. Because invoice has been created for this item.';
      this.PopupMessageModal.show();
      return;
    }
    this.tabledataforprocess.splice(events.rowindex, 1);
    if (!this.tabledataforprocess.length) {
      this.showItemAlert.show();
      this.tabledataforprocess = [];
      this.model.customerno.value = '';
      this.saveBtn = true;
    } else {
      this.saveBtn = false;
    }
    const deepCopy = this.helper.removeRefrence(this.tabledataforprocess);
    this.rejoutwardtabledata = [];
    this.rejoutwardtabledata = deepCopy;
    this.pushInDeletedArray(events.row, 'SrNo');
  }


  pushInDeletedArray(row, key) {
    if (row[key]) {
      this.deletedArray.push(row[key])
    } else {
      this.deletedArray = [];
    }
    this.poBalanceQuantity = this.poBalanceQuantity + row['quantity'];
  }


  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  checkSingleRecord(data) {
    data['keyName'] = 'checkbox';
    if(this.model.isrejected.value == 'N'){
      if(!data['row']['checked']){
        data['row']['outquantity'] = 0;
      }
    }
    this.rowChangeOrUpdatePopup(data);
  }

  decideOutQty(subasyno, maxQty, outQty, hiddenOutQty, columnKeyName) {

    let maxallowedqty = 0;
    this.poBalanceQuantity = this.poBalanceQuantity ? +this.poBalanceQuantity:0
    if (columnKeyName == 'checkbox') {
      maxallowedqty = Math.min(maxQty, +this.poBalanceQuantity);
      this.poBalanceQuantity = this.poBalanceQuantity - maxallowedqty;
    } else if (columnKeyName == 'outquantity') {
      if (outQty !== hiddenOutQty) {
        maxallowedqty = Math.min(maxQty, this.poBalanceQuantity + hiddenOutQty);
        maxallowedqty = Math.min(maxallowedqty, outQty);

        this.poBalanceQuantity = this.poBalanceQuantity - maxallowedqty + hiddenOutQty;
      } else {
        maxallowedqty = outQty;
      }
    }
 //  console.log({subasyno:this.poBalanceQuantity});

    return maxallowedqty;
  }

  rowChangeOrUpdatePopup(data) {
 //  console.log("rowChangeOrUpdatePopup", data);

    if (this.openPopup && this.model.isrejected.value == 'Y') {
      const subAsyNo = data?.row?.subAsyNo;
      if (data['keyName'] == 'checkbox') {
        if (data?.row?.checked && data.row.outquantity) {
          return;
        }
        if (data?.row?.checked && !data.row.outquantity) {

          const allowedOutQuantity = this.decideOutQty(subAsyNo, data?.row?.quantity, data?.row?.outquantity ? data?.row?.outquantity : 0, data?.row?.hiddenoutquantity ? data?.row?.hiddenoutquantity : 0, 'checkbox');
          data.row.outquantity = allowedOutQuantity;
          data.row.hiddenoutquantity = allowedOutQuantity;
          if(data.row.outquantity == 0){
            this.ErrorDescription = 'Po balance quantity is 0 for this item.';
            this.errorMessageModal.show();
          }
          this.CommonFunction.tableBackgroundColorChange(data?.row?.ptIndex, data.row.quantity, data.row.outquantity)
        } else {
          let ele = document.getElementById('trselectitem' + data?.row?.ptIndex);
          this.poBalanceQuantity = this.poBalanceQuantity + data.row.outquantity;
          data.row.outquantity = 0;
          data.row.hiddenoutquantity = 0;
          setTimeout(() => { if (ele) { ele.style.background = '' } }, 50);
        }
      } else if (data['keyName'] == 'outquantity') {
        const allowedOutQuantity = this.decideOutQty(subAsyNo, data?.row?.quantity, data?.row?.outquantity, data.row.hiddenoutquantity ? data.row.hiddenoutquantity : 0, 'outquantity');
        if((data?.row?.outquantity > data?.row?.quantity)){
          this.ErrorDescription = `Qty should not be greater than ${data?.row?.quantity} `;
          // data.row.outquantity = allowedOutQuantity;
          // this.errorMessageModal.show();
        }
        data.row.outquantity = allowedOutQuantity;
        data.row.hiddenoutquantity = allowedOutQuantity;
        if(data.row.outquantity == 0 && data['eventName'] == 'Blur'){
          this.ErrorDescription = 'Po balance quantity is 0 for this item.';
          this.errorMessageModal.show();
        }
        this.CommonFunction.tableBackgroundColorChange(data?.row?.ptIndex, data.row.quantity, data.row.outquantity)
      }
    }
    if(this.model.isrejected.value == 'N'){
      if (data?.row?.checked && data.row.outquantity) {
        return;
      }
      if (data?.row?.checked && !data.row.outquantity) {
        data.row.outquantity = data.row.quantity;
      }
      if(data['keyName'] == 'outquantity' && (data?.row?.outquantity > data?.row?.quantity)){
        this.ErrorDescription = `Qty should not be greater than ${data?.row?.quantity} `;
        data.row.outquantity = data?.row?.quantity;
        // this.errorMessageModal.show();
      }
    }


  }

  updatedtabledatapopup(data) {
    if (this.openPopup) {
      if (data.length) {
     //  console.log("Parent updatedtabledatapopup ForEach");
        
        data.forEach(element => {
          let ele = document.getElementById('trselectitems' + element['ptIndex']);
          if (element['checked'] && element['quantity'] !== element['outquantity']) {
            setTimeout(() => {
              if (ele) {
                ele.style.background = 'antiquewhite'
              }
            }, 100);
          } else {
            setTimeout(() => {
              if (ele) {
                ele.style.background = ''
              }
            }, 100);
          }
        });
      }
    }
  }
  closeModal() {
    this.ngbActiveModal.close();
  }
}
