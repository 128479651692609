import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonServicesService } from '../../services/common-services.service';

@Component({
  selector: 'app-utiliy',
  templateUrl: './utiliy.component.html',
  styleUrls: ['./utiliy.component.scss']
})
export class UtiliyComponent implements OnInit {

  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('confirmationModal', { static: false }) public confirmationModal;
  
  ErrorDescription:string ="";
  constructor(public commonService: CommonServicesService) { }

  ngOnInit(): void {
  }

  clickToRecalculate(){
    this.PopupMessageModal.show();
    this.ErrorDescription = 'Are you sure do you want to "Recalculate DC Inward Quantities"?'
  }

  recaluteDcPendingQty(type){
    let data = {
      "criteria":{
        "utility": type
      }
    }
    let endpoint = 'utility/recaluteDcPendingQty';
    this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        this.PopupMessageModal.hide()
        this.confirmationModal.show();
        this.ErrorDescription = res['errorDescription'];
      }
    })
  }

}
