import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import { CommonServicesService } from '../services/common-services.service';

@Directive({
  selector: '[appAccessControlType],[elementName],[formName],[pageName]'
})
export class AccessControlDirective {

  @Input("elementName") elementName: string;
  @Input("appAccessControlType") appAccessControlType: 'btns' | 'tabs';
  @Input("formName") formName: string;
  @Input("pageName") pageName: string;

  constructor(private commonService: CommonServicesService, private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {

    if (this.commonService.accessControl?.[this.appAccessControlType] && this.commonService.accessControl?.[this.appAccessControlType]?.[this.pageName] && this.commonService.accessControl?.[this.appAccessControlType]?.[this.pageName]?.[this.formName] && this.commonService.accessControl?.[this.appAccessControlType]?.[this.pageName]?.[this.formName]?.[this.elementName]) {
      const elementPermissionValue: 'E' | 'H' = this.commonService.accessControl[this.appAccessControlType][this.pageName][this.formName][this.elementName];
      console.log(elementPermissionValue, this.elementName);
      
      switch (elementPermissionValue) {
        case 'E':
          // this.elementRef.nativeElement.setAttribute("disabled", false);
          break;
        case 'H':
          this.elementRef.nativeElement.remove();
          break;

        default:
          break;
      }

    }

  }

  ngAfterViewInit (){

  }

}
