import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../../appconfig';
import { CommonServicesService } from '../../../services/common-services.service';
import { HelperService } from '../../../services/helper.service';
import { SubmitFormService } from '../../../services/submit-form.service';
import { SystemParamsService } from '../../../services/system-params.service';
import { ToastService } from '../../../services/toast.service';
import { LEFT_SIDE_TABLE_JSON, DETAIL_TABLE_JSON, POPUP_ITEMS_TABLE_JSON } from "./spare-issued.gtconfig";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var $;
@Component({
  selector: 'app-spare-issued',
  templateUrl: './spare-issued.component.html',
  styleUrls: ['./spare-issued.component.scss']
})

export class SpareIssuedComponent implements OnInit {
  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('showPopupForSpareIssueList', { static: false }) public showPopupForSpareIssueList;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;
  createNewBtn: boolean = false;
  editBtn: boolean = false;
  cancelBtn: boolean = false;
  saveBtn: boolean = true;
  addItemButton: boolean = false;
  btnRow: boolean = false;
  editDisableBtn: boolean = false;
  deleteBtnDisable: boolean = false;
  currentSelectedRow: number;
  isPopUpOpen: boolean = false;
  saveBtnVisible: boolean = true;
  isRowClicked: boolean = false;
  refreshDetailTable:boolean = true;
  deepCopyForSpareDetailTable:any = {}
  userRole: string;
  deleteMsg: string = "";
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  todaysDate: any = [];
  maxDate = new Date();
  label: string = 'Spare Issued';
  private tabledataforprocessData: any = []
  operation: string = "";
  priviousQty: number = 0;
  spissueuniqueno: number = 0;
  deletedArray: number[] = [];
  emptySearchField: boolean = true;
  private backupData: any = { bindDataToFormAndTable: {} };
  tabledataforprocess: any[] = []

  model = {
    uId: {
      valuType: 'Number',
      value: '',
      label: "Id",
      isRequired: false,
      err: "",
      element: { name: 'input', type: 'number' }
    },showspareissueno: {
      valuType: 'String',
      value: '',
      label: "Spare Issue No.",
      isRequired: false,
      disable: true,
      maxLength: 16,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    spareissueno: {
      valuType: 'String',
      value: '',
      label: "Spare Issue No.",
      isRequired: false,
      disable: true,
      maxLength: 16,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    issuedt: {
      valuType: 'Date',
      value: '',
      label: "Inward Date",
      isRequired: true,
      disable: true,
      err: "",
      element: { name: 'input', type: 'date' }
    },
    imremark: {
      valuType: 'String',
      value: '',
      label: "Remark",
      disable: true,
      isRequired: false,
      err: "",
      element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  // Left side table
  spareissuetableconfig: any = LEFT_SIDE_TABLE_JSON.tableconfig;
  spareissuetableheaders: any[] = LEFT_SIDE_TABLE_JSON.tableheaders;
  spareissuetabledata: any[] = []

  // Details table
  spareissuelisttableconfig: any = DETAIL_TABLE_JSON.tableconfig;
  spareissuelisttableheaders: any = DETAIL_TABLE_JSON.tableheaders;
  spareissuelisttabledata: any[] = [];

  // Popup items table
  spareissueitemtableconfig: any = POPUP_ITEMS_TABLE_JSON.tableconfig;
  spareissueitemtableheader: any = POPUP_ITEMS_TABLE_JSON.tableheaders;
  spareissueitemdata: any[] = [];

  constructor(private ngbActiveModal: NgbActiveModal,private submit: SubmitFormService, public commonService: CommonServicesService, public systemParamsService: SystemParamsService, private helper: HelperService, private configurationsetting: ConfigurationSetting, private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    this.getSearchAllSpareIssue();
    if (this.isOpenFromReport) {
      this.getByIdClickCall(this.transactionID)
    }
  }

  createNew() {
    this.emptySearchField = false;
    this.label = "Add Spare Issued";
    this.operation = '';
    this.createNewBtn = true;
    this.saveBtn = true;
    this.editBtn = false;
    this.cancelBtn = true;
    this.saveBtnVisible = true;
    this.deleteBtnDisable = false;
    this.addItemButton = true;
    this.btnRow = true;
    this.deleteMsg = "";
    this.spareissuelisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.todaysDate = new Date();
    this.model.issuedt.value = this.todaysDate;
    this.model.imremark.value = " "
    this.makeColumnsEditableAndUneditable(true);
    const ele = document.getElementById(`tr${"spareissue1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    this.spareissuelisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    delete this.backupData.bindDataToFormAndTable;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  saveSpareIssueForm() {
    let data = this.submit.submitForm(this.model)

    if (data) {
      this.saveBtn = true;
      let editedRow = [];
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.tabledataforprocess[i]['qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        if (this.tabledataforprocess[i]?.rowmodifiedstatus) {
          delete this.tabledataforprocess[i].rowmodifiedstatus;
          editedRow.push(this.tabledataforprocess[i])
        }
      }

      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];

      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      let endpoint = 'transaction/insertSpareIssue';
      if (data["criteria"]['spareissueno']) {
        endpoint = 'transaction/updateSpareIssue';
        this.ErrorDescription = "Data Updated Successfully";
        data["criteria"]["issuedlist"] = this.tabledataforprocess;
      } else {
        data["criteria"]["issuedlist"] = this.tabledataforprocess;
        this.ErrorDescription = "Data Inserted Successfully";
      }
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.spissueuniqueno = res['Data']
          this.saveBtn = true;
          this.label = "Spare Issued";
          this.addItemButton = false;
          this.cancelBtn = false;
          this.createNewBtn = false;
          this.saveBtnVisible = false;
          this.editBtn = true;

          this.commonService.diasbleFormFields(this.model);
          this.spareissuelisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.getSearchAllSpareIssue();
          this.makeColumnsEditableAndUneditable(false);

          // this.model.spareissueno.value = res['Data'];
          this.deleteBtnDisable = false;
          this.PopupMessageModal.show();
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show()
        }
      }, (err) => { });
    }
  }

  deleteDcIssueForm() {
    let data = this.submit.submitForm(this.model);
    if (data || data["criteria"]["spareissueno"]) {
      data["criteria"]["issuelist"] = this.tabledataforprocess;
      let items: any = {};
      items.spareissueno = data["criteria"]["spareissueno"];
      items.delLineItems = [];
      items.SpareNo = [];
      data["criteria"]["issuelist"].forEach((ele) => {
        if (ele?.SrNo || ele?.SpareNo) {
          items.delLineItems.push(ele?.SrNo);
          items.SpareNo.push(ele?.SpareNo);
        }
      })
      data["criteria"] = items;

      let endpoint = 'transaction/deleteSpareIssue';
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {

          this.cancelPopup('closePopupDelete')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.clearAll(false);
          this.deleteBtnDisable = false;
          this.getSearchAllSpareIssue();
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        } else {
          this.cancelPopup('closePopupDelete');
          this.ErrorDescription = "Can not delete record";
          this.PopupMessageModal.show();
        }
      }, (err) => { });
    }
  }

  bindDataToFormAndTable(modal: any, flag: boolean = true) {
    if (this.model.spareissueno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    console.log(modal);
    this.refreshDetailTable = false;

    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    this.model.showspareissueno.value = modal['IssueNo'];
    let row = modal['row'];
    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.spareissuelisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"spareissue1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }
    this.spareissuelisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.makeColumnsEditableAndUneditable(false);
    this.spareissuelisttabledata = modal['issuedlist']?.length ? modal['issuedlist'] : [];


    let newArr = this.spareissuelisttabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.spareissuelisttabledata = newArr;
    // console.log(newArr);
    setTimeout(() => {
    this.refreshDetailTable = true;
    }, 10);
    this.tabledataforprocessData = this.helper.removeRefrence(this.spareissuelisttabledata);
    this.editBtn = true;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.label = "Display Spare Issued";
    this.cancelBtn = false;
    this.editDisableBtn = false;
    this.deleteBtnDisable = false;
    this.addItemButton = false;
    this.createNewBtn = false;
    this.commonService.diasbleFormFields(this.model);
    this.isRowClicked = false;

  }

  updatedtabledata(data: any) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;

    data.forEach((item, i) => {
      if (this.tabledataforprocessData[i] && this.operation === 'edit') {
        const prevQty = this.tabledataforprocessData[i]["qty"]
        this.priviousQty = prevQty;
        const prevQtyInHand = this.tabledataforprocessData[i]["qtyinhand"]
        const prevWip = this.tabledataforprocessData[i]["wip"]
        const currQty = item['qty'];
        const currQtyInHand = item['qtyinhand'];

        item['totalqty'] = prevQty - currQty;
        // item['qtyinhand'] = prevQtyInHand - item['totalqty'];
        // item['wip'] = prevWip + item['totalqty'];
        if(item['qty']>item['qtyinhand']){
          item['qty'] = this.tabledataforprocessData[i]["qty"];
        }

        // if(item['wip']<item['qty']){
        //   item['prevqty'] = this.tabledataforprocessData[i]["qty"]
        //   item['totalqty'] = item['wip'] - item['qty'];
        // }
      }
    })
  }

  rowUpdate(data) {
    data.row.rowmodifiedstatus = 'M';
    console.log("priviousQty", this.priviousQty);

    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.qtyinhand) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      // data.row.qty = this.priviousQty;
      // data.row.actualqty = 0
      this.errorMessageModal.show();
    }
  }

  rowUpdatePopup(data) {
    if (data['keyName'] == 'qty' && data?.row?.qty > data?.row?.qtyinhand) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.qty = 0;
      // data.row.actualqty = 0
      this.errorMessageModal.show();
    }
  }
  selectItemsList(itemdata: any) {
    console.log("selectItemsList", itemdata);

    this.saveBtn = false;
    this.saveBtnVisible = true;
    itemdata = this.helper.removeRefrence(itemdata);
    let selectedArray = [...this.tabledataforprocess];
    this.spareissuelisttabledata = [];

    for (let item of itemdata) {
      item.rowmodifiedstatus = "A";
      let flag = true;
      this.spareissuelisttabledata.forEach(element => {
        if (element['partno'] == item['partno']) {
          flag = false;
          element['qty'] = item['qty'];
        }
      });
      if (flag)
        selectedArray.push(item);
    }
    // let data;
    // for (let item of itemdata) {
    //   data = { SpareNo: item["SpareNo"], partno: item["partno"], description: item["description"], qty: item["qty"], qtyinhand: item["qtyinhand"], wip: item["wip"], remark: item["remark"] };
    //   selectedArray.push(data);
    // }
    this.cancelPopup('closepopupforspareissue');
    this.spareissuelisttabledata = [...this.spareissuelisttabledata, ...selectedArray];
  }


  // selectItemsList(itemdata: any) {
  //   this.saveBtn = false;
  //   this.saveBtnVisible = true;
  //   itemdata = this.helper.removeRefrence(itemdata);
  //   let selectedArray = [...this.tabledataforprocess];
  //   this.spareissuelisttabledata = [];

  //   let data;
  //   for (let item of itemdata) {
  //     data = { SpareNo: item["SpareNo"], partno: item["partno"], description: item["description"], qty: item["qty"], qtyinhand: item["qtyinhand"], wip: item["wip"], remark: item["remark"] };
  //     selectedArray.push(data);
  //   }
  //   this.cancelPopup('closepopupforspareissue');
  //   this.spareissuelisttabledata = [...this.spareissuelisttabledata, ...selectedArray];
  // }


  editForm() {
    this.spareissuelisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.makeColumnsEditableAndUneditable(true);
    this.label = "Edit Spare Issued";
    this.editDisableBtn = true;
    this.cancelBtn = true;
    this.operation = 'edit';
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.createNewBtn = true;
    this.addItemButton = true;

    if (this.userRole === "Admin") {
      this.deleteBtnDisable = true;
    } else {
      this.deleteBtnDisable = false;
    }
    this.commonService.enableFormFields(this.model);
    this.editBtn = false;
  }

  addItemBtn() {
    this.getItemListForPopup();
  }

  clearAll(flag: boolean = true) {
    this.commonService.clearForm(this.model);
    this.spareissuelisttabledata = [];
    this.label = "Spare Issued";
    this.addItemButton = false;
    this.cancelBtn = false;
    this.createNewBtn = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.btnRow = true;
    this.deleteMsg = "";
    this.commonService.diasbleFormFields(this.model);
    this.spareissuelisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    const ele = document.getElementById(`tr${"spareissue1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;
    }
  }

  cancleForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
      } else {
        this.clearAll(true);
      }
    }
  }

  cancelPopup(form) {
    this.spareissueitemdata = [];
    this.isPopUpOpen = false;
    switch (form) {
      case 'closepopupforspareissue':
        this.showPopupForSpareIssueList.hide();
        break;
      case "closePopupDelete":
        this.showDeleteModal.hide()
        break;
      case "itemalert":
        this.showItemAlert.hide();
        break;
    }
  }

  deleteRow(event) {
    let id = event['row'];
    let tabledata = this.helper.removeRefrence(this.spareissuelisttabledata);
    tabledata.splice(event.rowindex, 1);
    this.spareissuelisttabledata = []
    this.spareissuelisttabledata = tabledata;
    if (!this.tabledataforprocess.length) {
      this.showItemAlert.show();
      this.saveBtn = true;
      this.saveBtnVisible = false;
    } else {
      this.saveBtn = false;
      this.saveBtnVisible = true;
    }
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }


  getSearchAllSpareIssue() {
    const endpoint = 'transaction/getAllSpareIssue';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        this.spareissuetabledata = []
        this.spareissuetabledata = items;
        this.isPopUpOpen = true;
        for (let i = 0; i < items.length; i++) {
          if (items[i]['spareissueno'] == this.spissueuniqueno || items[i]['spareissueno'] == this.model.spareissueno.value) {
            this.model.spareissueno.value = items[i]['spareissueno'];
            // console.log("Index", items[i], i);
            this.getByIdClickCall(this.model.spareissueno.value);
            if (!this.isOpenFromReport) {
            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trspareissue1' + i).style.backgroundColor = "#7de4f0";
              // $(`tr[tabindex=${i}]`).click();
              // document.getElementById('trspareissue1' + i).click();
            }, 900);
          }
            break;
          }
        }
      }
    }, err => { })
  }

  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.spareissueno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.spareissuetabledata, modal.rowindex, this.currentSelectedRow, "spareissue1");

    let row = modal['row'];
    let spareissueno = row['spareissueno']
    this.getByIdClickCall(spareissueno);
  }

  getByIdClickCall(no: any) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getSpareIssueById";
    let params = { spareissueno: no };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']
        this.bindDataToFormAndTable(item);
      }
    }, (error) => {
      console.log(error);
    });
  }

  getItemListForPopup() {
    const endpoint = 'transaction/getSpareIssueItemList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        items.forEach(element => {
          element.qty = '';
        });
        this.spareissueitemdata = []
        this.spareissueitemdata = items;
        this.deepCopyForSpareDetailTable = this.helper.removeRefrence(items);

        this.isPopUpOpen = true;
        this.showPopupForSpareIssueList.show();
        this.filterSpareListTableIfExistingSubTable();
      }
    }, err => { })
  }

  filterSpareListTableIfExistingSubTable() {
    let preparedarr = [];
    preparedarr = this.deepCopyForSpareDetailTable.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < this.spareissuelisttabledata.length; ind++) {
        if (this.spareissuelisttabledata[ind]['partno'] == row['partno']) {
          recavailable = false;
        }
      }
      return recavailable;
    });
    this.spareissueitemdata = []
    this.spareissueitemdata = preparedarr;
  }

  closeErrorPopup() {
    this.backendErrorMessageModal.hide()
    this.errorMessageModal.hide();
    this.PopupMessageModal.hide();
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  makeColumnsEditableAndUneditable(flag: boolean) {
    this.spareissuelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.spareissuelisttableheaders), "qty", flag);
    this.spareissuelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.spareissuelisttableheaders), "remark", flag);
    this.spareissuelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.spareissuelisttableheaders), "printremark", flag);
  }
    
  closeModal() {
    this.ngbActiveModal.close();
  }
}
