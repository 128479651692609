import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonServicesService } from '../../services/common-services.service';

@Component({
  selector: 'app-financial-year',
  templateUrl: './financial-year.component.html',
  styleUrls: ['./financial-year.component.scss']
})
export class FinancialYearComponent implements OnInit {

  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('confirmationModal', { static: false }) public confirmationModal;

  ErrorDescription: string = "";
  constructor(public commonService: CommonServicesService) { }

  ngOnInit(): void {
  }

  clickToRecalculate() {
    this.PopupMessageModal.show();
    const d = new Date();
    let start = d.getFullYear();
    let end = d.getFullYear().toString().slice(-2);
    this.ErrorDescription = `Are you sure you want to "Create New Financial Year  ${start}_${+end + 1}"? `
  }

  createNewFinancialYear() {
    let data = {
      "criteria": {
      }
    }

    let endpoint = 'utility/getdumpandcreatedb';
    this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        this.PopupMessageModal.hide()
        this.confirmationModal.show();
        this.ErrorDescription = res['errorDescription'];
      }
    })
  }


}
