import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveDashService {

  public activeDashName: string;

  titleObject = {
    '/sub-Assembly': 'Sub Assembly Master',
    '/spare-master': 'Spare Master',
    '/model-master': 'Model Master',
    '/item-master': 'Item Master',
    '/customer-master': 'Customer Master',
    '/tax-master': 'Tax Master',
    '/user-master': 'User Master',
    '/po-master': 'PO Master',
    '/delivery-challan-inward': 'Delivery Challan Inward',
    '/manual-inward-note': 'Manual Inward Note',
    '/spare-inward': 'Spare Inward',
    '/spare-outward': 'Spare Outward',
    '/spare-issued': 'Spare Issued To Shop Floor',
    '/spare-reciept': 'Spare Receipt From Shop Floor',
    '/spare-discrepancy': 'Spare Discrepancy',
    '/spare-requisition': 'Spare Requisition',
    '/job-sheet': 'Job Sheet',
    '/material-outward/dc-outward': 'DC Outward (Regular)',
    '/material-outward/routine-dc': 'Routine DC',
    '/material-outward/rejected-outward': 'Rejected Outward',
    '/material-outward/manual-dn': 'Manual DN',
    '/regular-invoice': 'Regular Invoice',
    '/manual-invoice': 'Manual Invoice',
    '/re-repairs/reRepairsInward': 'Re-Repairs Inward',
    '/re-repairs/reRepairsOutward': 'Re-Repairs Outward',
    '/dashboard':'Dashboard',
    '/spare-report':'Spare Report',
    '/item-report':'Item Report',
    '/po-report':'PO Report',
    '/utility':'Utility',
    '/challan-ageing':'Challan Ageing Report',
    '/mail-sysparams':'Mail Details'
  }

  count = 1;

  constructor(private route: Router) {
    this.route.events.subscribe((event) => {
      // console.log(this.route.events, this.count++)
      if (event instanceof NavigationEnd) {
        this.activeDashName = this.titleObject[event.url] ? this.titleObject[event.url] : '?';
      }
    })
  }

}
