import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { from } from 'rxjs';
import { ConfigurationSetting } from '../../../appconfig';
import { ApiService } from '../../../services/api.service';
import { CommonServicesService } from '../../../services/common-services.service';
import { HelperService } from '../../../services/helper.service';
import { SubmitFormService } from '../../../services/submit-form.service';
import { SystemParamsService } from '../../../services/system-params.service';
import { ToastService } from '../../../services/toast.service';
import { LEFT_SIDE_TABLE_JSON, DETAIL_TABLE_JSON, POPUP_ITEMS_TABLE_JSON } from './sparedisp.gtconfig'
import { DomSanitizer } from '@angular/platform-browser';
declare var $;
@Component({
  selector: 'app-spare-discrepancy',
  templateUrl: './spare-discrepancy.component.html',
  styleUrls: ['./spare-discrepancy.component.scss']
})

export class SpareDiscrepancyComponent implements OnInit {
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('showPopupForSpareDispList', { static: false }) public showPopupForSpareDispList;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('showItemCustomerRepeatAlert', { static: false }) public showItemCustomerRepeatAlert;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('printPopup', { static: false }) public printPopup;

  createNewBtn: boolean = false;
  editBtn: boolean = false;
  cancelBtn: boolean = false;
  saveBtn: boolean = true;
  btnRow: boolean = false;
  addItemButton: boolean = true;
  editDisableBtn: boolean = false;
  currentSelectedRow: number;
  isPopUpOpen: boolean = false;
  saveBtnVisible: boolean = false;
  deleteMsg: string = "";
  maxDate = new Date();
  label: string = 'Spare Discrepancy';
  buttonlabel: string = 'Add New Discrepancy'
  todaysDate: any = []
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  dispuniqueno:number = 0;
  isRowClicked:boolean = false;
  deleteBtnDisable: boolean = false;
  deepCopyForItems:any = {}
  deletedArray: number[] = [];
  updatedSpareInwardList: number[] = [];
  emptySearchField: boolean = true;
  private backupData: any = { bindDataToFormAndTable: {} };
  tabledataforprocess: any[] = []
  customerNameArray = [];
  fileName:string = "";
  dataLocalUrl: any;
  spareDiscNum:number = 0;
  refreshDetailTable:boolean = true;

  model = {
    uId: {
      valuType: 'Number', value: '', label: "Id", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    showsparedescripencyno: {
      valuType: 'String', value: '', label: "Spare Issue No.", isRequired: false,
      disable: true, maxLength: 16, err: "", element: { name: 'input', type: 'text' }
    },
    sparedescripencyno: {
      valuType: 'String', value: '', label: "Spare Issue No.", isRequired: false,
      disable: true, maxLength: 16, err: "", element: { name: 'input', type: 'text' }
    },
    discrepancydate: {
      valuType: 'Date', value: '', label: "Report Date", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'date' }
    },
    customerno: {
      valuType: 'Number', value: '', label: "Customer Name", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    dmremark: {
      valuType: 'String', value: '', label: "Remark", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }
  // Left side table
  sparedisctableconfig: any = LEFT_SIDE_TABLE_JSON.tableconfig;
  sparedisctableheaders: any[] = LEFT_SIDE_TABLE_JSON.tableheaders;
  sparedisctabledata: any[] = []

  // Detail table table
  sparedisclisttableconfig: any = DETAIL_TABLE_JSON.tableconfig;
  sparedisclisttableheaders: any = DETAIL_TABLE_JSON.tableheaders;
  sparedisclisttabledata: any[] = [];

  // Popup Items table
  sparediscitemtableconfig: any = POPUP_ITEMS_TABLE_JSON.tableconfig;
  sparediscitemtableheader: any = POPUP_ITEMS_TABLE_JSON.tableheaders;
  sparediscrepancyitemdata: any[] = [];

  constructor(private domSanitizer: DomSanitizer, private submit: SubmitFormService, public commonService: CommonServicesService, private apiData: ApiService, private configurationsetting: ConfigurationSetting, public systemParamsService: SystemParamsService, private helper: HelperService, private datepipe: DatePipe, private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.getSearchAllSpareDiscrepacy();
    this.getCustomerName();
  }

  createNew() {
    this.emptySearchField = false;
    this.label = 'Add Spare Discrepancy';
    this.createNewBtn = true;
    this.deleteBtnDisable = false;
    this.saveBtn = true;
    this.editBtn = false;
    this.btnRow = true;
    this.cancelBtn = true;
    this.addItemButton = false;
    this.saveBtnVisible = true;
    this.deleteMsg = "";
    this.sparedisclisttabledata = [];
    this.tabledataforprocess = [];
    this.spareDiscNum = 0;
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.todaysDate = new Date();
    this.model.discrepancydate.value = this.todaysDate;
    this.model.dmremark.value = " "
    // this.makeColumnUneditable(true)
    this.sparedisclisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    const ele = document.getElementById(`tr${"podisp1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    delete this.backupData.bindDataToFormAndTable;
    this.sparediscitemtableheader[0]['datatype'] = 'checkboxfilter';
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  saveSpareDiscrepancyForm() {
    console.log(this.model);

    let data = this.submit.submitForm(this.model)
    if (data) {
      this.saveBtn = true;
      let addRow = []
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (this.tabledataforprocess[i]['quantity'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        this.tabledataforprocess[i]['descripencyStatus'] = "P";
        addRow.push(this.tabledataforprocess[i]);
      }
      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];

      if (this.updatedSpareInwardList.length) {
        data["criteria"]["updatedSpareInwardList"] = this.updatedSpareInwardList
      }
      this.updatedSpareInwardList = [];

      let endpoint = 'transaction/insertSpareDiscrepancy';
      this.ErrorDescription = "Data Inserted Successfully";
      if(data["criteria"]['sparedescripencyno']){
        this.spareDiscNum = data["criteria"]['sparedescripencyno'];
        endpoint = 'transaction/updateSpareDiscrepancy';
      this.ErrorDescription = "Data Updated Successfully";
      }
      data["criteria"]["discrepancylist"] = addRow;
      // data["criteria"]["discrepancylist"]["descripencyStatus"] = "P";

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {

          this.dispuniqueno = res['Data'];
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.label = 'Spare Discrepancy';
          this.addItemButton = true;
          this.cancelBtn = false;
          this.createNewBtn = false;
          this.editBtn = false;
          this.deleteBtnDisable = false;
          this.commonService.diasbleFormFields(this.model);
          this.sparedisclisttableconfig['actioncolmconfig']['displaycolumn'] = false;

          // this.makeColumnUneditable(false)
          this.getSearchAllSpareDiscrepacy();


          // this.toastService.showToastMsg('success', 'Success', this.ErrorDescription);
          this.PopupMessageModal.show();
        }
        else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
      this.saveBtn = false;
      this.saveBtnVisible = false;
      this.clearAll(false);
    }
  }

  deleteTransaction() {
    let data = this.submit.submitForm(this.model);

    if (data || data["criteria"]["sparedescripencyno"]) {
      data["criteria"]["discrepancylist"] = this.tabledataforprocess;
      let items: any = {};
      items.sparedescripencyno = data["criteria"]["sparedescripencyno"];
      items.customerno = data["criteria"]["customerno"];
      items.delLineItems = [];
      items.updatedSpareInwardList = [];
      items.SpareNo = [];
      data["criteria"]["discrepancylist"].forEach((ele) => {
        if (ele?.SrNo || ele?.SpareNo) {
          items.delLineItems.push(ele?.SrNo);
          items.updatedSpareInwardList.push(ele?.spareinwardno);
          items.SpareNo.push(ele?.SpareNo);
        }
      })
      data["criteria"] = items;
      console.log(data);

      let endpoint = 'transaction/deleteSpareDiscrepancy';
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('closePopupDelete');
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.clearAll(false);
          this.deleteBtnDisable = false;
          this.getSearchAllSpareDiscrepacy();
          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        }else{
          this.cancelPopup('closePopupDelete');
          this.ErrorDescription = "Can not delete record";
          this.PopupMessageModal.show();
        }
      }, (err) => { });
    }
  }

  bindDataToFormAndTable(modal: any, flag: boolean = true) {
    if (this.model.customerno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");

      return;
    }
    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    this.model.showsparedescripencyno.value = modal['SpareDescripencyNo'];
    this.refreshDetailTable = false;

    let userdeleted = modal['username']
    let deletedDate = modal['deldate']

    if (modal["isDeleted"] === 1) {
        this.model.customerno.value = null;
        this.model.sparedescripencyno.value = "";
        this.model.showsparedescripencyno.value = "";
        this.model.discrepancydate.value = "";
        this.model.dmremark.value = "";
        this.sparedisclisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"sparedesctbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }

    this.sparedisclisttableconfig['actioncolmconfig']['displaycolumn'] = false;

    this.model = this.commonService.setFromKeysValues(this.model, modal);


    // this.makeColumnUneditable(false)

    this.sparedisclisttabledata = modal['discrepancylist']?.length ? modal['discrepancylist'] : [];

    let newArr = this.sparedisclisttabledata.filter((item)=>{
      return item.ddisDeleted !== 1;
    })
    this.sparedisclisttabledata = newArr;
    setTimeout(() => {
    this.refreshDetailTable = true;
    }, 10);
    // console.log(newArr);
    this.deleteBtnDisable = false;
    this.editBtn = true;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.label = 'Display Spare Discrepancy';
    this.cancelBtn = false;
    this.editDisableBtn = false;
    this.addItemButton = true;
    this.createNewBtn = false;
    this.commonService.diasbleFormFields(this.model);

    this.isRowClicked = false;

  }

  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.customerno.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.sparedisctabledata, modal.rowindex, this.currentSelectedRow, "sparedesctbl1");

    let row = modal['row'];
    let sparedescripencyno = row['sparedescripencyno']
    this.getByIdClickCall(sparedescripencyno)
  }

  getByIdClickCall(no: any) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getSpareDiscrepancyById";
    let params = { sparedescripencyno: no };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data'];
        this.bindDataToFormAndTable(item);
      }
    }, (error) => {
      console.log(error);
    });
  }

  getItemListForPopup() {
    const endpoint = 'transaction/getSpareDiscrepancyItemList';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        items.forEach(element => {
          element.qty = '';
        });
        this.sparediscrepancyitemdata = []
        this.sparediscrepancyitemdata = items;

        this.isPopUpOpen = true;
        this.showPopupForSpareDispList.show();

        // this.deepCopyForItems = this.helper.removeRefrence(items);
        // this.filterItemsTableIfExistingSubTable();
      }
    }, err => { })
  }

  filterItemsTableIfExistingSubTable(){

    if(this.model.customerno.value){
      let filterwithdnno = this.deepCopyForItems.filter(row=>{
        if(this.model.customerno.value) {
          return row.customerno === this.model.customerno.value;
        }
      })
      this.sparediscrepancyitemdata = filterwithdnno;
    }
  }

  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }

  selectItemsList(itemdata) {
    this.saveBtn = false;
    this.saveBtnVisible = true;
    itemdata = this.helper.removeRefrence(itemdata);
    let selectedArray = [...this.tabledataforprocess];
    this.sparedisclisttabledata = [];

    for (let i = 0; i < itemdata.length; i++) {
      for (let j = i; j < itemdata.length; j++) {

        if (itemdata[i].customerno == itemdata[j].customerno) {
          if (this.model.customerno.value && this.model.customerno.value !== itemdata[i].customerno) {
            this.toastService.showToastMsg('warning', 'Warning', "Can not select different customer data");
            return;
          }
          this.model.customerno.value = itemdata[i].customerno;
        } else {
          this.toastService.showToastMsg('warning', 'Warning', "Can not select different customer data");
          return;
        }
      }
      let flag = true;
      this.sparedisclisttabledata.forEach(element => {
        if (element['SrNo'] == itemdata[i]['SrNo']) {
          flag = false;
        }
      });
      if (flag) {
        selectedArray.push(itemdata[i]);
      }
    }
    this.cancelPopup('closepopupforsparedisc');
    this.sparedisclisttabledata = [...this.sparedisclisttabledata, ...selectedArray];
  }

  updatedtabledata(data: any) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;

  }

  rowUpdate(data) {
    if (data?.row?.qty < 0) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.qty = 0;
      this.errorMessageModal.show();
    }
    if (data) {
      data['row']['descripencyStatus'] = "P";
    }

  }

  editForm() {
    this.sparedisclisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.makeColumnUneditable(true)
    this.buttonlabel = 'Add Discrepancy';
    this.label = 'Edit Spare Discrepancy';
    this.editDisableBtn = true;
    this.cancelBtn = true;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.createNewBtn = true;
    this.addItemButton = false;
    this.deleteBtnDisable = true;
    this.commonService.enableFormFields(this.model);

    this.editBtn = false;
  }

  addItemBtn() {
    this.createNew()
    this.getItemListForPopup();
  }

  clearAll(flag: boolean = true) {
    this.commonService.clearForm(this.model);
    this.sparedisclisttabledata = [];
    this.label = 'Spare Discrepancy';
    this.addItemButton = true;
    this.cancelBtn = false;
    this.createNewBtn = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.btnRow = true;
    this.deleteMsg = ""
    this.commonService.diasbleFormFields(this.model);
    this.sparedisclisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    const ele = document.getElementById(`tr${"podisp1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;

    }
  }

  cancleForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
      } else {
        this.clearAll(true);
      }
    }
  }

  getSearchAllSpareDiscrepacy() {

    const endpoint = 'transaction/getAllSpareDiscrepancy';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        this.sparedisctabledata = []
        this.sparedisctabledata = items;
        for (let i = 0; i < items.length; i++) {

          if (items[i]['sparedescripencyno'] == this.dispuniqueno || items[i]['sparedescripencyno'] == this.model.sparedescripencyno.value || items[i]['sparedescripencyno'] == this.spareDiscNum) {
           this.model.sparedescripencyno.value = items[i]['sparedescripencyno'];
            this.getByIdClickCall(this.model.sparedescripencyno.value);
            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trsparedesctbl1' + i).style.backgroundColor = "#7de4f0";
            }, 900);
            break;
          }
        }
        this.isPopUpOpen = true;
      }
    }, err => { })
  }


  cancelPopup(form) {
    this.sparediscrepancyitemdata = [];
    this.isPopUpOpen = false;
    switch (form) {
      case 'closepopupforsparedisc':
        this.showPopupForSpareDispList.hide();
        break;
      case 'itemalert':
        this.showItemAlert.hide();
        break;
      case "customeralert":
        this.showItemCustomerRepeatAlert.hide();
        break;
      case "closePopupDelete":
        this.showDeleteModal.hide();
        default:
          break;
    }

  }

  printPdf() {

    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/generatesparepdf";
    let body = { "criteria": { "transId": this.model.sparedescripencyno.value, "printPdfFor":"sparediscrepancy" } }

    this.commonService.postMethod(apiUrl, body).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        // console.log("success");
        this.fileName = successResp['Data']['filename'];

        this.downloadFile(this.fileName)
      }
      else {
        this.ErrorDescription = "Something went wrong, please contact admin";
      }
    },
      err => {
        this.ErrorDescription = "Something went wrong, please contact admin";
      });
  }


  deleteRow(event) {
    let id = event['row'];
    let tabledata = this.helper.removeRefrence(this.sparedisclisttabledata);
    tabledata.splice(event.rowindex, 1);
    this.sparedisclisttabledata = []
    this.sparedisclisttabledata = tabledata;
    if (!this.tabledataforprocess.length) {
      this.showItemAlert.show();
      this.saveBtn = true;
      this.saveBtnVisible = false;
    } else {
      this.saveBtn = false;
      this.saveBtnVisible = true;
    }
    console.log(event.row);

    this.pushInDeletedArray(event.row, 'SrNo');
    this.pushInSpareInwardArray(event.row, 'spareinwardno');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }

  pushInSpareInwardArray(row, key){
    this.updatedSpareInwardList.push(row[key])
    console.log(this.updatedSpareInwardList);

  }

  closeErrorPopup() {
    this.backendErrorMessageModal.hide();
    this.errorMessageModal.hide();
    this.PopupMessageModal.hide();
  }

  makeColumnUneditable(flag: boolean) {
    // this.sparedisclisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.sparedisclisttableheaders), "quantity", flag);
    // this.sparedisclisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.sparedisclisttableheaders), "actualqty", flag);
    this.sparedisclisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.sparedisclisttableheaders), "remark", flag);
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  downloadFile(filename: string) {
    let body = { "filename": filename }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/downloadpdf";
    this.commonService.getFile(apiUrl, body) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        // saveAs(blob, this.fileName);
        let a = document.createElement('a');

        let file = new Blob([blob], { type: 'application/pdf' });
        // console.log("file ", file);

        let fileURL = URL.createObjectURL(file);

        this.dataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
        a.download = this.fileName;
        setTimeout(() => {
          this.printPopup.show();
        }, 200);

        this.fileName = "";
      })
      .catch((err) => {
        console.log("Something went wrong");
      });

  }
}
