import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal'
import { SubmitFormService } from '../../../services/submit-form.service';
import { CommonServicesService } from '../../../services/common-services.service';
import { ApiService } from '../../../services/api.service';
import { HelperService } from '../../../services/helper.service';
import { ConfigurationSetting } from '../../../appconfig';
import { DatePipe, Location } from '@angular/common';
import { SystemParamsService } from '../../../services/system-params.service';
import { ToastService } from '../../../services/toast.service';
import { ROUTINE_DC_CONFIRM_PO_TABLE_JSON, ROUTINE_DC_DETAILED_TABLE_JSON, ROUTINE_DC_LEFT_SIDE_TABLE_JSON, ROUTINE_DC_SELECTITEM_TABLE_JSON } from './routine-dcgtconfig';
import { DomSanitizer } from '@angular/platform-browser';
import { min } from 'moment';
import { CommonFunctionService } from '../../../services/common-function.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var $;

@Component({
  selector: 'app-routine-dc',
  templateUrl: './routine-dc.component.html',
  styleUrls: ['./routine-dc.component.scss']
})
export class RoutineDcComponent implements OnInit {
  @ViewChild('ShowSelectItemPopup', { static: false }) public ShowSelectItemPopup;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('showPrintPopupModal', { static: false }) public showPrintPopupModal;
  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('showPopupoSelectConfirmPo', { static: false }) public showPopupoSelectConfirmPo;
  @ViewChild('printPopup', { static: false }) public printPopup;
  @ViewChild('showPrintESNPopupModal', { static: false }) public showPrintESNPopupModal;

  @Input() isOpenFromReport: boolean = false;
  @Input() transactionID: number = 0;

  fileName: string = "";
  dataLocalUrl: any;
  outwardType: string = 'RD' // Routine DC
  RoutineDcForm: boolean = true;
  showFormBtn: boolean = true;
  showCloseBtn: boolean = false;
  isPopUpOpen: boolean = false;
  label: string = "Material Routine DC";
  deleteMsg: string = "";
  savebtnvisible: boolean = true;
  saveBtn: boolean = true;
  editBtn: boolean = false;
  printBtn: boolean = false;
  addItemButton: boolean = true;
  CreateNew: boolean = false;
  cancelbtn: boolean = false;
  currentSelectedRow: number;
  editDisableBtn: boolean = false;
  isPoPopUpOpen: boolean = false;
  minDate = new Date();
  todaysDate: any = [];
  deleteBtnDisable: boolean = false;
  selectItemButton: boolean = false;
  userRole: string = "";
  totalQtyIn: boolean = false;
  visibleCancelBtn: boolean = false;
  btnRow: boolean = false;
  refreshDetailTable:boolean = true;
  emptySearchField: boolean = true;
  isRowClicked: boolean = false;
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  routindcuniqueno: number = 0;
  selectPoBtn: boolean = true;
  PoNoArray: any[] = [];
  disabledSelectItemBtn: boolean = true;
  dataForPrint: any = {};
  customerNameArray: any[] = [];
  private backuptabledataforData: any = [];
  operation: string = '';
  priviousQty: number = 0;
  poQuantity: number = 0;
  invoiceCreatedCount: number = 0;
  openPopup: boolean = false;
  // public text: string = 'Create New Routine';
  text = "Add New Routine"
  private backupData: any = { bindDataToRoutineForm: {} };
  tabledataforprocess: any[] = [];
  deletedArray: number[] = [];

  objToStoreBalancePoQty = {}
  private objToStoreBalancePoQty_copy = {}

  private qtyStore: number = 0;
  private objectToStoreqty = {}
  selectedPrNo:number;

  qtyCount = 0;
  objecteWhileSingleClick = {}

  model = {
    dcoutno: {
      valuType: 'String', value: '', label: "DC Outward ", isRequired: false,
      disable: true, maxLength: 4, err: "", element: { name: 'input', type: 'text' }
    },
    dcoutwardsrno: {
      valuType: 'String', value: '', label: "Routine Dc No ", isRequired: false,
      disable: true, maxLength: 16, err: "", element: { name: 'input', type: 'text' }
    },
    outwarddate: {
      valuType: 'Date', value: '', label: "Routine Date", disable: true,
      isRequired: true, err: "", element: { name: 'input', type: 'date' }
    },
    customerno: {
      valuType: 'Number', value: '', label: "Customer Name", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    jmremark: {
      valuType: 'String', value: '', label: "Remark", disable: true,
      isRequired: false, err: "", element: { name: 'input', type: 'text' }
    },
    pono: {
      valuType: 'Number', value: '', label: 'P.O', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    podetailsrno: {
      valuType: 'Number', value: '', label: 'P.O', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    showpono: {
      valuType: 'String', value: '', label: 'P.O', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    poqtybalance: {
      valuType: 'Number', value: '', label: 'P.O', isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  routinesidetableconfig: any = ROUTINE_DC_LEFT_SIDE_TABLE_JSON.tableconfig;
  routinesidetableheaders: any = ROUTINE_DC_LEFT_SIDE_TABLE_JSON.tableheaders;
  routinesidetabledata: any = [];

  routinelisttableconfig: any = ROUTINE_DC_DETAILED_TABLE_JSON.tableconfig;
  routinelisttableheaders: any = ROUTINE_DC_DETAILED_TABLE_JSON.tableheaders;
  routinelisttabledata: any = [];

  // Filter option in Popup
  selectItemsPopupTableConfig: any = ROUTINE_DC_SELECTITEM_TABLE_JSON.tableconfig;
  selectItemsPopupTableHeaders: any = ROUTINE_DC_SELECTITEM_TABLE_JSON.tableheaders;
  selectItemsPopupTableData: any[] = [];

  // Popup to select Po
  confirmPotableconfig: any = ROUTINE_DC_CONFIRM_PO_TABLE_JSON.tableconfig;
  confirmPotableheader: any = ROUTINE_DC_CONFIRM_PO_TABLE_JSON.tableheaders;
  confirmPotabledata: any = [];

  refreshtable: boolean = true;

  constructor(private ngbActiveModal: NgbActiveModal, private domSanitizer: DomSanitizer, private submit: SubmitFormService, private configurationsetting: ConfigurationSetting, private datepipe: DatePipe, public systemParamsService: SystemParamsService, private toastService: ToastService,
    private helper: HelperService, private apidata: ApiService, public commonService: CommonServicesService, private CommonFunction: CommonFunctionService) { }

  ngOnInit(): void {


    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    this.getAllRoutineDc();
    this.getCustomerName();
    this.getConfirmPoList();
    if (this.isOpenFromReport) {
      this.getByIdCall(this.transactionID)
    }
  }

  CreateNewDc() {
    this.emptySearchField = false;
    this.label = "Add Material Routine DC"
    this.operation = 'new';
    this.saveBtn = false;
    this.savebtnvisible = true;
    this.CreateNew = true;
    this.selectPoBtn = false;
    this.text = "Add Routine"
    this.editBtn = false;
    this.printBtn = false;
    this.btnRow = true;
    this.deleteMsg = "";
    this.cancelbtn = true;
    this.selectItemButton = true;
    this.routinelisttabledata = [];
    this.tabledataforprocess = [];
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);
    this.disabledSelectItemBtn = true;
    this.todaysDate = new Date();
    this.model.outwarddate.value = this.todaysDate;
    this.model.jmremark.value = " "

    // if (this.userRole === "Admin") {
    //   this.todaysDate = "";
    // }
    this.routinelisttableconfig['actioncolmconfig']['displaycolumn'] = true;

    this.routinelisttableheaders = this.commonService.enableEditForGt(this.routinelisttableheaders, "quantity", true);
    this.routinelisttableheaders = this.commonService.enableEditForGt(this.routinelisttableheaders, "remark", true);
    const ele = document.getElementById(`tr${"routinedctbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    delete this.backupData.bindDataToRoutineForm;

    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
  }

  saveRoutineDc() {
    let data = this.submit.submitForm(this.model);
    // console.log(data);

    if (data) {
      this.saveBtn = true;
      if(this.tabledataforprocess.length){
        this.routinelisttabledata = this.tabledataforprocess;
      }
      let editedRow = [];
      for (let i = 0; i < this.routinelisttabledata.length; i++) {
        if (this.routinelisttabledata[i]['quantity'] <= 0 || (this.routinelisttabledata[i]['quantity'] <= "" || null || undefined)) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        if (this.routinelisttabledata[i]?.rowmodifiedstatus) {
          delete this.routinelisttabledata[i].rowmodifiedstatus;
          editedRow.push(this.routinelisttabledata[i]);
        }
      }
      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }

      data["criteria"]["outwardtype"] = this.outwardType;

      this.deletedArray = [];
      this.commonService.deleteExtraFlagsFromTable(this.routinelisttabledata);

      if (!this.routinelisttabledata.length) {
        alert("No Items are selected!");
      }
      console.log("while save", this.routinelisttabledata, editedRow);

      let endpoint = 'transaction/insertOutwardTransaction';
      if (data["criteria"]['dcoutwardsrno']) {
        endpoint = 'transaction/updateOutwardTransaction';
        this.ErrorDescription = "Data Updated Successfully";
        data["criteria"]["dcoutwardlist"] = editedRow;
      } else {
        data["criteria"]["dcoutwardlist"] = this.routinelisttabledata;
        this.ErrorDescription = "Data Inserted Successfully";
      }

      // this.showPrintPopupModal.show();
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.routindcuniqueno = res['Data'];
          this.savebtnvisible = false;
          this.btnRow = false;
          this.addItemButton = true;
          this.cancelbtn = false;
          this.CreateNew = false;
          this.editBtn = true;
          this.printBtn = true;
          this.selectItemButton = false;
          this.text = "Add New Routine"
          this.commonService.diasbleFormFields(this.model);
          this.routinelisttableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.routinelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.routinelisttableheaders), "quantity", false);
          this.routinelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.routinelisttableheaders), "remark", false);

          this.getConfirmPoList();
          this.deleteBtnDisable = false;
          this.getAllRoutineDc();

          this.PopupMessageModal.show();
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  alertDeleteConfirmation() {
    if (this.invoiceCreatedCount > 0) {
      this.ErrorDescription = 'Can not delete this transaction. Because invoice has been created for this transaction.';
      this.PopupMessageModal.show();
    } else {
      this.deleteRoutineDCForm();
    }
  }

  deleteRoutineDCForm() {
    let data = this.submit.submitForm(this.model);

    if (data && data["criteria"]["dcoutwardsrno"]) {

      data["criteria"]["dcoutwardlist"] = this.routinelisttabledata;

      let items: any = {};
      items.dcoutwardsrno = data["criteria"]["dcoutwardsrno"];
      items.customerno = data["criteria"]["customerno"];
      items.type = this.outwardType;
      // console.log(this.outwardType, items);

      items.delLineItems = [];
      data["criteria"]["dcoutwardlist"].forEach((ele) => {
        if (ele?.SrNo) {
          items.delLineItems.push(ele?.SrNo);
        }
      })

      data["criteria"] = items;

      let endpoint = 'transaction/deleteOutwardTransaction';

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup()

          this.saveBtn = true;
          this.deleteBtnDisable = false;
          this.clearAll(false);
          this.getAllRoutineDc();

          this.ErrorDescription = "Record Deleted Successfully";
          this.PopupMessageModal.show();
        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }


  getAllRoutineDc() {
    let params = { "formname": this.outwardType };
    const endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getAllMaterialOutward';
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];

        this.routinesidetabledata = []
        this.routinesidetabledata = items;
        for (let i = 0; i < items.length; i++) {
          if (items[i]['dcoutwardsrno'] == this.routindcuniqueno || items[i]['dcoutwardsrno'] == this.model.dcoutwardsrno.value) {
            // console.log("Index", items[i], i);
            this.model.dcoutwardsrno.value = items[i]['dcoutwardsrno'];
            this.getByIdCall(items[i]['dcoutwardsrno']);
            if (!this.isOpenFromReport) {
            setTimeout(() => {
              $(`tr[tabindex=${i}]`).focus();
              document.getElementById('trroutinedctbl1' + i).style.backgroundColor = "#7de4f0";
            }, 900);
          }
            break;
          }
        }
        // this.isPoUpOpen = true;

      }
    }, err => { })
  }


  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.jmremark.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }

    this.currentSelectedRow = modal.rowindex;
    this.commonService.identifySelectedRow(this.routinesidetabledata, modal.rowindex, this.currentSelectedRow, "routinedctbl1");
    this.operation = ''
    this.opr = ''
    let row = modal['row'];
    let dcoutwardsrno = row['dcoutwardsrno'];
    this.getByIdCall(dcoutwardsrno);
  }

  getByIdCall(id: number) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getDcOutwardById?";
    let params = { dcoutwardsrno: id, outwardtype: this.outwardType };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data'];
        // console.log(item);

        this.bindDataToRoutineForm(item);
        this.dataForPrint = item;

      }
    }, (error) => {
      console.log(error);
    });
  }

  printPdf() {
    this.showPrintESNPopupModal.show();
    // this.downloadPdf(this.dataForPrint);

  }

  downloadPdf(value) {

    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/generateoutwardpdf";
    let body = { "criteria": { "transId": this.model.dcoutwardsrno.value, "printEsnDetails":value, printPdfFor:this.outwardType} }

    this.commonService.postMethod(apiUrl, body).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        // console.log("success");
        this.fileName = successResp['Data']['filename'];

        this.downloadFile(this.fileName)
      }
      else {
        this.ErrorDescription = "Something went wrong, please contact admin";
      }
    },
      err => {
        this.ErrorDescription = "Something went wrong, please contact admin";
      });
  }

  downloadFile(filename: string) {
    let body = { "filename": filename }
    let apiUrl = this.configurationsetting.getBaseUrl() + "transaction/downloadpdf";
    this.commonService.getFile(apiUrl, body) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        // saveAs(blob, this.fileName);
        let a = document.createElement('a');

        let file = new Blob([blob], { type: 'application/pdf' });
        // console.log("file ", file);

        let fileURL = URL.createObjectURL(file);

        this.dataLocalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
        a.download = this.fileName;
        setTimeout(() => {
          this.printPopup.show();
        }, 200);

        this.fileName = "";
      })
      .catch((err) => {
        console.log("Something went wrong");
      });

  }
  deepCopyForDcoutItems: any = {};
  displaySelectItems() {
    let params = { "pono": this.model.pono.value, custno: this.model.customerno.value, "formname": "forroutinedc" };
    let endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getDataFromDcInward';
    this.commonService.getWithParams(endpoint, params).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.selectItemsPopupTableData = [];
        this.selectItemsPopupTableData = items;


        this.deepCopyForDcoutItems = this.helper.removeRefrence(items);
        this.filterItemsTableIfExistingSubTable();
      }
    }, err => { })
  }


  filterItemsTableIfExistingSubTable() {
    let preparedarr = [];
    let inwardno;
    preparedarr = this.deepCopyForDcoutItems.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < this.routinelisttabledata.length; ind++) {
        if (this.routinelisttabledata[ind]['refrenceno'] == row['refrenceno']) {
          inwardno = this.routinelisttabledata[ind]['dnno']
          recavailable = false;
        }
      }
      return recavailable;
    });
    this.selectItemsPopupTableData = []
    this.selectItemsPopupTableData = preparedarr;

    // if(inwardno){
    //   let filterwithdnno = preparedarr.filter(row=>{
    //     if(inwardno) {
    //       return row.dnno === inwardno;
    //     }
    //   })
    //   this.selectItemsPopupTableData = filterwithdnno;
    // }else{
    //   this.selectItemsPopupTableData = preparedarr;
    // }
  }

  selectItemsDataBind(modal) {
    let row = modal['row'];
    this.model = this.commonService.setFromKeysValues(this.model, row);
    // this.routinelisttabledata = this.selectItemsPopupTableData;

    if (row.itemsrno) {
      this.getDetailsForRoutineDc(row.itemsrno)
    }
    this.ShowSelectItemPopup.hide();
  }

  getDetailsForRoutineDc(itemsrno: any) {
    let params = { itemNo: itemsrno, "formname": "routine" };
    // console.log(params);

    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/displayMaterialOutwardList";
    // console.log(modal);
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']["dcoutwardlist"];
        this.routinelisttabledata = item;
        this.routinelisttabledata.forEach((ele) => {
          ele['quantity'] = 0;
        })
      }
    }, (error) => {
      console.log(error);
    });

  }

  bindDataToRoutineForm(modal, flag: boolean = true) {
    if (this.model.outwarddate.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.label = "Display Material Routine DC"
    this.refreshDetailTable = false;

    this.backupData.bindDataToRoutineForm = this.helper.removeRefrence(modal);
    this.model.dcoutno.value = modal['dcoutno'];
    // this.model.poqtybalance.value = modal['poqtybalance']
    this.model.pono.value = modal['pono']

    let userdeleted = modal['username']
    let deletedDate = modal['deldate']
    this.invoiceCreatedCount = modal['invoicemadecount']


    if (modal["isDeleted"] === 1) {
      for (let item in this.model) {
        this.model[item].value = '';
        this.routinelisttabledata = [];
        this.btnRow = false;
        this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
        const ele = document.getElementById(`tr${"routinedctbl1" + this.currentSelectedRow}`);
        if (ele) ele.style.backgroundColor = '';
        this.toastService.showToastMsg('error', 'Alert', `Record is Deleted by ${userdeleted} on ${deletedDate}`);
        return;
      }
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }
    this.routinelisttableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.routinelisttableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.routinelisttableheaders), "quantity", false);
    this.routinelisttableheaders = this.commonService.enableEditForGt(this.routinelisttableheaders, "remark", true);
    this.routinelisttabledata = modal['dcoutwardlist']?.length ? modal['dcoutwardlist'] : [];

    let newArr = this.routinelisttabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.routinelisttabledata = newArr;
    // console.log(newArr);
    setTimeout(() => {
    this.refreshDetailTable = true;
    }, 10);
    this.backuptabledataforData = this.helper.removeRefrence(this.routinelisttabledata)

    this.editBtn = true;
    this.printBtn = true;
    this.savebtnvisible = false;
    this.addItemButton = true;
    this.cancelbtn = false;
    this.CreateNew = false;
    // if(!Object.keys(this.objToStoreBalancePoQty).length){
    //   this.editBtn = false;
    // }else{
    //   this.editBtn = true;
    // }
    this.deleteBtnDisable = false;
    this.editDisableBtn = false;
    this.commonService.diasbleFormFields(this.model);

    this.isRowClicked = false;
    this.selectPoBtn = true;
  }

  selectRoutineItemsList(itemdata: any) {
    this.saveBtn = false;
    this.openPopup = false;
    console.log(itemdata);

    let deepCopy = this.helper.removeRefrence(this.routinelisttabledata);
    // itemdata = this.helper.removeRefrence(itemdata);

    for (let i = 0; i < itemdata.length; i++) {
      let {
        dcsrno, dcno, Releases, issuedate, dnno, pono, itemsrno, itemno, itemname, jmdescription, modelname, quantity, outquantity, maxquantity, invoicependingqty, esn, customerNo, customername, customernm, jmremark: remark, engtypejeop, esncustomer, jobno, refrenceno, SrNo1, SrNo2, subAsyNo
      } = itemdata[i]
      deepCopy.push({ dcsrno, dcno, Releases, issuedate, dnno, pono: this.model.pono.value, itemsrno, itemno, itemname, jmdescription, modelname,inwardmaxqtyfordc:quantity, quantity: outquantity,hiddenoutquantity:outquantity, maxquantity, invoicependingqty: 0, esn, customerNo, customername, customernm, remark, engtypejeop, esncustomer, jobno, refrenceno, SrNo1, SrNo2, subAsyNo });
    }

    this.routinelisttabledata = [];
    this.routinelisttabledata = deepCopy;
    this.backuptabledataforData = this.helper.removeRefrence(this.routinelisttabledata);

    this.cancelPopup();
  }
  opr = '';
  selectItemsForRoutineDC() {
    this.isPoPopUpOpen = true;
    this.displaySelectItems();
    this.ShowSelectItemPopup.show()
    this.openPopup = true;
    if (this.operation == 'edit') {
      this.opr = 'addrowwhileedit'
    }
  }

  updatedtabledataforroutine(data: any) {

    if (data.length) {
      this.saveBtn = false;
    } else {
      this.saveBtn = true;
    }
    // console.log({ data });

    let totalTableQty = 0;
    data.forEach((item, i) => {

      if (this.operation == 'edit') {
        // setTimeout(() => {
          // item['hiddenoutquantity'] = item['quantity'];
        // }, 50);
        item['rowmodifiedstatus'] = 'M';
      }
      this.priviousQty = this.backuptabledataforData[i]["quantity"];
      if (this.backuptabledataforData[i] && this.operation === 'edit') {
        if (!item['invoicemadeqty']) {
          item['invoicemadeqty'] = 0;
        }

        item['totalqty'] = item['invoicependingqty'] + item['inwardmaxqtyfordc'] + item['invoicemadeqty'];
        item['totalpobalqty'] = (+this.priviousQty + +this.model.poqtybalance.value)

        if (item['quantity'] > item['totalqty'] && item['quantity'] < item['invoicemadeqty']) {
          item['quantity'] = this.backuptabledataforData[i]["quantity"]
        }

      } else if (this.operation === 'new' || !item['SrNo']) {
        const prevQtyInHand = this.backuptabledataforData[i]["quantity"]
        totalTableQty += item['quantity'];


        item['totalqty'] = item['maxquantity']
        if (item['quantity'] > item['totalqty']) {
          item['quantity'] = this.backuptabledataforData[i]["quantity"];
        }
      }
    })

    this.tabledataforprocess = [];
    this.tabledataforprocess = data;
  }

  rowChangeOrUpdate(data) {


    data.row.rowmodifiedstatus = 'M';
    const subAsyNo = data?.row?.subAsyNo;
    if (this.operation === 'edit' && data['keyName'] == 'quantity' && (data?.row?.quantity > data?.row?.totalqty || data?.row?.quantity < 0) || data?.row?.quantity < data?.row?.invoicemadeqty) {
      this.ErrorDescription = `Enter valid Quantity. Can not enter Quantity greater than ${data?.row?.totalqty}. `;
      // data.row.quantity = this.priviousQty;
      this.errorMessageModal.show();
    } else if (data['keyName'] == 'quantity' && data?.row?.quantity > data?.row?.totalqty || data?.row?.quantity < 0) {
      this.ErrorDescription = `Enter quantity valid quantity i.e. 1 to ${data?.row?.totalqty}. `;
      // data.row.quantity = this.priviousQty;
      this.errorMessageModal.show();
    }
 data.row.invoicemadeqty = data?.row?.invoicemadeqty ? data?.row?.invoicemadeqty : 0;
    if (data['keyName'] == 'quantity' && data?.row?.quantity < data?.row?.invoicemadeqty) {
      data.row.rowmodifiedstatus = 'M';
      this.ErrorDescription = `Invoice of ${data?.row?.invoicemadeqty} has created. so qty should be greater than ${data?.row?.invoicemadeqty}`;
      data.row.quantity = data.row.hiddenoutquantity;
      // this.errorMessageModal.show();
    }
    if (data['keyName'] == 'quantity' && data['eventName'] == 'Blur') {
      let maxQty;
      let oldQty =  data.row.oldquantity ? data.row.oldquantity : 0
      if(this.operation == 'edit'){
        maxQty = data.row.inwardmaxqtyfordc + oldQty;
      }else{
        maxQty = data.row.inwardmaxqtyfordc;
      }
      const allowedOutQuantity = this.decideOutQty(subAsyNo, maxQty, data?.row?.quantity, data?.row?.hiddenoutquantity, 'outquantity');
      data.row.quantity = allowedOutQuantity;
      data.row.hiddenoutquantity = allowedOutQuantity;

      this.routinelisttabledata = this.tabledataforprocess;
    }
   
  }

  editForm() {
    this.label = "Edit Material Routine DC"

    this.routinelisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.routinelisttableheaders = this.commonService.enableEditForGt(this.routinelisttableheaders, "quantity", true);
    this.routinelisttableheaders = this.commonService.enableEditForGt(this.routinelisttableheaders, "remark", true);

    this.deleteBtnDisable = true;
    this.saveBtn = false;
    this.savebtnvisible = true;
    this.cancelbtn = true;
    this.addItemButton = true;
    this.editDisableBtn = true;
    this.editBtn = false;
    this.printBtn = false;
    this.selectPoBtn = true;
    this.disabledSelectItemBtn = false;
    this.selectItemButton = true;
    this.operation = 'edit';
    this.poQtyBalanceObject(this.model.pono.value);
    this.commonService.enableFormFields(this.model);
  }



  clearAll(flag: boolean = true) {
    this.label = "Material Routine DC"
    this.objToStoreBalancePoQty = {};
    this.operation = '';
    this.commonService.clearForm(this.model);
    this.routinelisttabledata = [];
    this.btnRow = true;
    this.deleteMsg = "";
    this.addItemButton = true;
    this.cancelbtn = false;
    this.CreateNew = false;
    this.saveBtn = true;
    this.selectItemButton = false;
    this.selectPoBtn = true;
    this.savebtnvisible = false;
    this.disabledSelectItemBtn = true;
    this.text = "Add New Routine"
    this.commonService.diasbleFormFields(this.model);
    this.routinelisttableconfig['actioncolmconfig']['displaycolumn'] = true;
    const ele = document.getElementById(`tr${"routinedctbl1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    if (this.editDisableBtn && this.backupData['bindDataToRoutineForm'] && flag) {
      this.bindDataToRoutineForm(this.backupData.bindDataToRoutineForm, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;
      this.printBtn = false;

    }

  }

  cancelPopup() {
    this.ShowSelectItemPopup.hide();
    this.showDeleteModal.hide()
    this.backendErrorMessageModal.hide();
    this.PopupMessageModal.hide();
    this.showPopupoSelectConfirmPo.hide();
    this.showItemAlert.hide()
    this.ShowSelectItemPopup.hide();
    this.isPoPopUpOpen = false;

  }


  cancel() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
      } else {
        this.clearAll(true);
      }
    }
  }

  getConfirmPoList() {
    let endpoint = 'transaction/getPoListByNonBlanket';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data'];
        this.confirmPotabledata = [];
        this.confirmPotabledata = items;

        this.confirmPotabledata.forEach((data) => {
          this.PoNoArray.push({ value: data.PrNo, display: data.PoNo })
        })
        // console.log(this.PoNoArray);

      }
    }, err => { })
  }

  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }


  bindPoNoAndGetItem(modal) {
    let row = modal['row'];
    // console.log(row);
    this.model.pono.value = row.PrNo;
    this.model.showpono.value = `${row.PoNo} - ${row.description}`;
    this.disabledSelectItemBtn = false;
    this.poQuantity = row?.poqtybalance;
    this.model.customerno.value = row?.customername;
    this.model.podetailsrno.value = row?.pdsrno;
    this.routinelisttabledata = [];

    let prno = row.PrNo;
    this.poQtyBalanceObject(prno);
    this.cancelPopup();
  }

  poQtyBalanceObject(prno){
    this.objToStoreBalancePoQty = {};

    this.confirmPotabledata.forEach((item) => {
      if (prno == item['PrNo']) {
        this.objToStoreBalancePoQty[item.ItemSrNo] = item.poqtybalance;
      }
    })
    console.log(this.objToStoreBalancePoQty);
    // this.objToStoreBalancePoQty_copy = JSON.stringify(this.objToStoreBalancePoQty);
  }

  assignPoQtyCopy() {
    // console.log(this.objToStoreBalancePoQty_copy);
    let d = `[${this.objToStoreBalancePoQty_copy}]`;
    this.objToStoreBalancePoQty = JSON.parse(d)[0];
  }

  showPopupToSelectPo() {
    this.getConfirmPoList();
    this.refreshtable = false;
    this.showPopupoSelectConfirmPo.show();
    setTimeout(() => {
      this.refreshtable = true;
    }, 30);
  }

  isPrint() {

  }

  deleteRow(event) {
    let row = event['row'];
    if((row['quantity'] != row['invoicependingqty']) && row['invoicemadeqty']){
      this.ErrorDescription = 'Can not delete this item. Because invoice has been created for this item.';
      this.PopupMessageModal.show();
      return;
    }
    this.routinelisttabledata.splice(event.rowindex, 1);
    // console.log(id);
    if (!this.routinelisttabledata.length) {
      this.showItemAlert.show();
      this.routinelisttabledata = [];
      this.saveBtn = true;
    } else {
      this.saveBtn = false;
    }
    const deepCopy = this.helper.removeRefrence(this.routinelisttabledata);
    this.routinelisttabledata = [];
    this.routinelisttabledata = deepCopy;
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    console.log(row);

    if (row[key]) {
      this.deletedArray.push(row[key])
    } else {
      this.deletedArray = [];
    }
    this.objToStoreBalancePoQty[row['subAsyNo']] = this.objToStoreBalancePoQty[row['subAsyNo']] + row['quantity'];
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  /*
  1. Accept two parameters subAsyNo and Qty
  2. Jump to SubAsyNo recieved in step 1
  3. Get PO balance qty from POBalanceQtyObject (located)
  4. Get min of qty and PO balance qty
  5. pobalaceqty = pobalaceqty - minqty (from 4 step)
  6. Assign min qty to outquantity for selected row

  If unchecked
  1. -
  2. -
  5. pobalaceqty = pobalaceqty + qty (recieved in 1.)
  6. Assign 0 to outquantity for selected row

  If editQty
  1. -
  2. -
  3.
  */
  // {6:4, 8:6, 7:3}
  checkSingleRecord(data) {
    data['keyName'] = 'checkbox';
    this.rowChangeOrUpdatePopup(data);
  }

  decideOutQty(subasyno, maxQty, outQty, hiddenOutQty, columnKeyName) {

    let maxallowedqty = 0;
    const allowOutQtyForSubAsy = this.objToStoreBalancePoQty[subasyno] ? +this.objToStoreBalancePoQty[subasyno]:0
    if (columnKeyName == 'checkbox') {
      maxallowedqty = Math.min(maxQty, +allowOutQtyForSubAsy);
      this.objToStoreBalancePoQty[subasyno] = this.objToStoreBalancePoQty[subasyno] - maxallowedqty;
    } else if (columnKeyName == 'outquantity') {
      if (outQty !== hiddenOutQty) {
        maxallowedqty = Math.min(maxQty, allowOutQtyForSubAsy + hiddenOutQty);
        maxallowedqty = Math.min(maxallowedqty, outQty);

        this.objToStoreBalancePoQty[subasyno] = allowOutQtyForSubAsy - maxallowedqty + hiddenOutQty;
      } else {
        maxallowedqty = outQty;
      }
    }

    return maxallowedqty;
  }

  rowChangeOrUpdatePopup(data) {

    const subAsyNo = data?.row?.subAsyNo;
    if (data['keyName'] == 'checkbox') {
      if(data?.row?.checked && data.row.outquantity){
        return;
      }
      if (data?.row?.checked && !data.row.outquantity) {

        const allowedOutQuantity = this.decideOutQty(subAsyNo, data?.row?.quantity, data?.row?.outquantity ? data?.row?.outquantity : 0, data?.row?.hiddenoutquantity ? data?.row?.hiddenoutquantity : 0, 'checkbox');
        data.row.outquantity = allowedOutQuantity;
        data.row.hiddenoutquantity = allowedOutQuantity;
        if(data.row.outquantity == 0){
          this.ErrorDescription = 'Po balance quantity is 0 for this item.';
          this.errorMessageModal.show();
        }
        this.CommonFunction.tableBackgroundColorChange(data?.row?.ptIndex, data.row.quantity, data.row.outquantity)
      } else {
        let ele = document.getElementById('trselectitem' + data?.row?.ptIndex);
        this.objToStoreBalancePoQty[subAsyNo] = this.objToStoreBalancePoQty[subAsyNo] + data.row.outquantity;
        data.row.outquantity = 0;
        data.row.hiddenoutquantity = 0;
        setTimeout(() => { if (ele) { ele.style.background = '' } }, 50);
      }
    } else if (data['keyName'] == 'outquantity') {
      const allowedOutQuantity = this.decideOutQty(subAsyNo, data?.row?.quantity, data?.row?.outquantity, data.row.hiddenoutquantity ? data.row.hiddenoutquantity:0, 'outquantity');
      data.row.outquantity = allowedOutQuantity;
      data.row.hiddenoutquantity = allowedOutQuantity;
      if(data.row.outquantity == 0 && data['eventName'] == 'Blur'){
        this.ErrorDescription = 'Po balance quantity is 0 for this item.';
        this.errorMessageModal.show();
      }
      this.CommonFunction.tableBackgroundColorChange(data?.row?.ptIndex, data.row.quantity, data.row.outquantity)
    }
    // console.log(this.objToStoreBalancePoQty);

  }

  closeModal() {
    this.ngbActiveModal.close();
  }
}
