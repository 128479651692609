import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationSetting } from '../../appconfig'
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfirmationPopupModel } from '../../models/models';
import { CommonServicesService } from '../../services/common-services.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { IActionMapping, ITreeOptions, ITreeState, KEYS, TREE_ACTIONS } from '@circlon/angular-tree-component';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { element } from 'protractor';
declare var $;

interface ROUTE {
  name: string;
  displayname: string;
  permission: boolean;
  icon: boolean;
  cssClass: string;
  buttons?: {
    add: boolean,
    edit: boolean,
    delete: boolean,
    print?: boolean
  },
  children?: ROUTE[];
}

interface STORE_PERMISSION_TAB {
  [key: string]: {
    permission: "Y" | "N";
    children?: STORE_PERMISSION_TAB
  }
}

interface STORE_PERMISSION_BTN {
  [key: string]: {
    [key: string]: {
      add: "E" | "H";
      edit: "E" | "H";
      delete: "E" | "H";
      print?: "E" | "H";
    }
  }
}

interface STORE_PERMISSION {
  tabs: STORE_PERMISSION_TAB;
  btns: STORE_PERMISSION_BTN
}

const permissionsObj: ROUTE[] = [
  {
    name: "master",
    displayname: "Master",
    icon: true,
    permission: true,
    cssClass: "all-select",
    children: [
      {
        name: "subasy",
        icon: true,
        displayname: "Sub Assembly Master",
        cssClass: "all-select",
        permission: true,
        buttons: {
          add: true,
          edit: true,
          delete: true
        }
      },
      {
        name: "spare",
        icon: true,
        displayname: "Spare Master",
        cssClass: "all-select",
        permission: true,
        buttons: {
          add: true,
          edit: true,
          delete: true
        }
      },
      {
        name: "model",
        icon: true,
        displayname: "Model Master",
        cssClass: "all-select",
        permission: true,
        buttons: {
          add: true,
          edit: true,
          delete: true
        }
      },
      {
        name: "item",
        icon: true,
        displayname: "Item Master",
        cssClass: "all-select",
        permission: true,
        buttons: {
          add: true,
          edit: true,
          delete: true
        }
      },
      {
        name: "customer",
        icon: true,
        displayname: "Customer Master",
        cssClass: "all-select",
        permission: true,
        buttons: {
          add: true,
          edit: true,
          delete: true
        }
      },
      {
        name: "tax",
        icon: true,
        displayname: "Tax Master",
        permission: true,
        cssClass: "all-select",
        buttons: {
          add: true,
          edit: true,
          delete: true
        }
      }
    ]
  },
  {
    name: "trnx",
    icon: true,
    displayname: "Transaction",
    permission: true,
    cssClass: "all-select",
    children: [
      {
        name: "po",
        icon: true,
        displayname: "Po Master",
        permission: true,
        cssClass: "all-select",
        buttons: {
          add: true,
          edit: true,
          delete: true
        }
      },
      {
        name: "inwardtrnx",
        icon: true,
        displayname: "Inward Transaction",
        cssClass: "all-select",
        permission: true,
        children: [
          {
            name: "dcinward",
            icon: true,
            displayname: "Dc Inward",
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true
            }
          },
          {
            name: "manualinward",
            icon: true,
            displayname: "Manual Inward Note",
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true
            }
          }
        ]
      },
      {
        name: "sparetrnx",
        icon: true,
        displayname: "Spare Transactions",
        cssClass: "all-select",
        permission: true,
        children: [
          {
            name: "spareinward",
            icon: true,
            displayname: "Spare Inward'",
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true
            }
          },
          {
            name: "spareoutward",
            icon: true,
            displayname: "Spare Outward",
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true
            }
          },
          {
            name: "spareissue",
            icon: true,
            displayname: "Spare Issued To Shop Floor",
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true
            }
          },
          {
            name: "sparerecipt",
            icon: true,
            displayname: "Spare Receipt From Shop Floor",
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true
            }
          },
          {
            name: "sparediscrepancy",
            icon: true,
            displayname: "Spare Discrepancy",
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true,
              print: true
            }
          },
          {
            name: "sparerequisition",
            icon: true,
            displayname: "Spare Requisition",
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true,
              print: true
            }
          }
        ]
      },
      {
        name: "jobsheet",
        icon: true,
        displayname: "Job Sheet",
        cssClass: "all-select",
        permission: true,
        buttons: {
          add: true,
          edit: true,
          delete: true,
          print: true
        }
      },
      {
        name: "outwardtrnx",
        icon: true,
        displayname: "Material Outward",
        cssClass: "all-select",
        permission: true,
        children: [
          {
            name: "regoutward",
            displayname: "DC Outward (Regular)",
            icon: true,
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true,
              print: true
            }
          },
          {
            name: "routinedc",
            displayname: "Routine DC",
            icon: true,
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true,
              print: true
            }
          },
          {
            name: "rejectedout",
            displayname: "Recjected Outward",
            permission: true,
            cssClass: "all-select",
            icon: true,
            buttons: {
              add: true,
              edit: true,
              delete: true,
              print: true
            }
          },
          {
            name: "manualoutward",
            icon: true,
            displayname: "Manual DN",
            cssClass: "all-select",
            permission: true,
            buttons: {
              add: true,
              edit: true,
              delete: true,
              print: true
            }
          }
        ]
      },
      {
        name: "invoice",
        icon: true,
        displayname: "Invoice",
        cssClass: "all-select",
        permission: true,
        children: [
          {
            name: "regularinvoice",
            displayname: "Regular Invoice",
            icon: true,
            cssClass: "all-select",
            permission: true,
          },
          {
            name: "manualinvoice",
            displayname: "Manual Invoice",
            icon: true,
            cssClass: "all-select",
            permission: true,
          }
        ],
        buttons: {
          add: true,
          edit: true,
          delete: true,
          print: true
        }
      }
    ]
  },
  {
    name: "reports",
    icon: true,
    displayname: "Reports",
    cssClass: "all-select",
    permission: true,
    children: [
      {
        name: "sparereport",
        icon: true,
        displayname: "Spare Report",
        cssClass: "all-select",
        permission: true
      },
      {
        name: "itemreport",
        icon: true,
        displayname: "Item Report",
        cssClass: "all-select",
        permission: true
      },
      {
        name: "poreport",
        icon: true,
        displayname: "PO Report",
        cssClass: "all-select",
        permission: true
      },
      {
        name: "challanageing",
        icon: true,
        displayname: "Challan Ageing",
        cssClass: "all-select",
        permission: true
      },
    ]
  },
  {
    name: "utility",
    icon: true,
    displayname: "Utility",
    cssClass: "all-select",
    permission: true,
    children: [
      {
        name: "dataintegrity",
        icon: true,
        displayname: "Data Integrity",
        cssClass: "all-select",
        permission: true
      }
    ]
  }
]

@Component({
  selector: 'app-user-master',
  templateUrl: './user-master.component.html',
  styleUrls: ['./user-master.component.scss']
})
export class UserMasterComponent implements OnInit {
  @ViewChild('accessPermissionsModal', { static: false }) public accessPermissionsModal;
  @ViewChild('accessPermissionsModal2', { static: false }) public accessPermissionsModal2;
  @ViewChild('tabsContextMenu', { static: false }) public tabsContextMenu: ContextMenuComponent;

  submitButtonEventSubject: Subject<any> = new Subject<any>();
  permissionData = [
    {
      name: "master",
      displayname: "Master",
      icon: true,
      permission: true,
      cssClass: "all-select",
      children: [
        {
          name: "subasy",
          icon: true,
          displayname: "Sub Assembly Master",
          cssClass: "all-select",
          permission: true,
          buttons: {
            add: true,
            edit: true,
            delete: true
          }
        },
        {
          name: "spare",
          icon: true,
          displayname: "Spare Master",
          cssClass: "all-select",
          permission: true,
          buttons: {
            add: true,
            edit: true,
            delete: true
          }
        },
        {
          name: "model",
          icon: true,
          displayname: "Model Master",
          cssClass: "all-select",
          permission: true,
          buttons: {
            add: true,
            edit: true,
            delete: true
          }
        },
        {
          name: "item",
          icon: true,
          displayname: "Item Master",
          cssClass: "all-select",
          permission: true,
          buttons: {
            add: true,
            edit: true,
            delete: true
          }
        },
        {
          name: "customer",
          icon: true,
          displayname: "Customer Master",
          cssClass: "all-select",
          permission: true,
          buttons: {
            add: true,
            edit: true,
            delete: true
          }
        },
        {
          name: "tax",
          icon: true,
          displayname: "Tax Master",
          permission: true,
          cssClass: "all-select",
          buttons: {
            add: true,
            edit: true,
            delete: true
          }
        }
      ]
    },
    {
      name: "trnx",
      icon: true,
      displayname: "Transaction",
      permission: true,
      cssClass: "all-select",
      children: [
        {
          name: "po",
          icon: true,
          displayname: "Po Master",
          permission: true,
          cssClass: "all-select",
          buttons: {
            add: true,
            edit: true,
            delete: true
          }
        },
        {
          name: "inwardtrnx",
          icon: true,
          displayname: "Inward Transaction",
          cssClass: "all-select",
          permission: true,
          children: [
            {
              name: "dcinward",
              icon: true,
              displayname: "Dc Inward",
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true
              }
            },
            {
              name: "manualinward",
              icon: true,
              displayname: "Manual Inward Note",
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true
              }
            }
          ]
        },
        {
          name: "sparetrnx",
          icon: true,
          displayname: "Spare Transactions",
          cssClass: "all-select",
          permission: true,
          children: [
            {
              name: "spareinward",
              icon: true,
              displayname: "Spare Inward'",
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true
              }
            },
            {
              name: "spareoutward",
              icon: true,
              displayname: "Spare Outward",
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true,
                print: true,
              }
            },
            {
              name: "spareissue",
              icon: true,
              displayname: "Spare Issued To Shop Floor",
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true
              }
            },
            {
              name: "sparerecipt",
              icon: true,
              displayname: "Spare Receipt From Shop Floor",
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true
              }
            },
            {
              name: "sparediscrepancy",
              icon: true,
              displayname: "Spare Discrepancy",
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true,
                print: true
              }
            },
            {
              name: "sparerequisition",
              icon: true,
              displayname: "Spare Requisition",
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true,
                print: true
              }
            }
          ]
        },
        {
          name: "jobsheet",
          icon: true,
          displayname: "Job Sheet",
          cssClass: "all-select",
          permission: true,
          buttons: {
            add: true,
            edit: true,
            delete: true,
            print: true
          }
        },
        {
          name: "outwardtrnx",
          icon: true,
          displayname: "Material Outward",
          cssClass: "all-select",
          permission: true,
          children: [
            {
              name: "regoutward",
              displayname: "DC Outward (Regular)",
              icon: true,
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true,
                print: true
              }
            },
            {
              name: "routinedc",
              displayname: "Routine DC",
              icon: true,
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true,
                print: true
              }
            },
            {
              name: "rejectedout",
              displayname: "Recjected Outward",
              permission: true,
              cssClass: "all-select",
              icon: true,
              buttons: {
                add: true,
                edit: true,
                delete: true,
                print: true
              }
            },
            {
              name: "manualoutward",
              icon: true,
              displayname: "Manual DN",
              cssClass: "all-select",
              permission: true,
              buttons: {
                add: true,
                edit: true,
                delete: true,
                print: true
              }
            }
          ]
        },
        {
          name: "invoice",
          icon: true,
          displayname: "Invoice",
          cssClass: "all-select",
          permission: true,
          children: [
            {
              name: "regularinvoice",
              displayname: "Regular Invoice",
              icon: true,
              cssClass: "all-select",
              permission: true,
            },
            {
              name: "manualinvoice",
              displayname: "Manual Invoice",
              icon: true,
              cssClass: "all-select",
              permission: true,
            }
          ],
          buttons: {
            add: true,
            edit: true,
            delete: true,
            print: true
          }
        }
      ]
    },
    {
      name: "reports",
      icon: true,
      displayname: "Reports",
      cssClass: "all-select",
      permission: true,
      children: [
        {
          name: "sparereport",
          icon: true,
          displayname: "Spare Report",
          cssClass: "all-select",
          permission: true
        },
        {
          name: "itemreport",
          icon: true,
          displayname: "Item Report",
          cssClass: "all-select",
          permission: true
        },
        {
          name: "poreport",
          icon: true,
          displayname: "PO Report",
          cssClass: "all-select",
          permission: true
        },
        {
          name: "challanageing",
          icon: true,
          displayname: "Challan Ageing",
          cssClass: "all-select",
          permission: true
        },
      ]
    },
    {
      name: "utility",
      icon: true,
      displayname: "Utility",
      cssClass: "all-select",
      permission: true,
      children: [
        {
          name: "dataintegrity",
          icon: true,
          displayname: "Data Integrity",
          cssClass: "all-select",
          permission: true
        }
      ]
    }
  ];

  savePermissionObj: STORE_PERMISSION = {
    "tabs": {
      "master": {
        "permission": "Y",
        "children": {
          "subasy": {
            "permission": "Y"
          },
          "spare": {
            "permission": "Y"
          },
          "model": {
            "permission": "Y"
          },
          "item": {
            "permission": "Y"
          },
          "customer": {
            "permission": "Y"
          },
          "tax": {
            "permission": "Y"
          }
        }
      },
      "trnx": {
        "permission": "Y",
        "children": {
          "po": {
            "permission": "Y"
          },
          "inwardtrnx": {
            "permission": "Y",
            "children": {
              "dcinward": {
                "permission": "Y"
              },
              "manualinward": {
                "permission": "Y"
              }
            }
          },
          "sparetrnx": {
            "permission": "Y",
            "children": {
              "spareinward": {
                "permission": "Y"
              },
              "spareoutward": {
                "permission": "Y"
              },
              "spareissue": {
                "permission": "Y"
              },
              "sparerecipt": {
                "permission": "Y"
              },
              "sparediscrepancy": {
                "permission": "Y"
              },
              "sparerequisition": {
                "permission": "Y"
              }
            }
          },
          "jobsheet": {
            "permission": "Y"
          },
          "outwardtrnx": {
            "permission": "Y",
            "children": {
              "regoutward": {
                "permission": "Y"
              },
              "routinedc": {
                "permission": "Y"
              },
              "rejectedout": {
                "permission": "Y"
              },
              "manualoutward": {
                "permission": "Y"
              }
            }
          },
          "invoice": {
            "permission": "Y",
          }
        }
      },
      "reports": {
        "permission": "Y",
        "children": {
          "sparereport": {
            "permission": "Y"
          },
          "itemreport": {
            "permission": "Y"
          },
          "poreport": {
            "permission": "Y"
          },
          "challanageing": {
            "permission": "Y"
          }
        }
      },
      "utility": {
        "permission": "Y",
        "children": {
          "dataintegrity": {
            "permission": "Y"
          }
        }
      }
    },
    "btns": {
      "master": {
        "subasy": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "spare": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "model": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "item": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "customer": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "tax": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        }
      },
      "trnx": {
        "po": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "dcinward": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "manualinward": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "spareinward": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "spareoutward": {
          "add": "E",
          "edit": "E",
          "delete": "E",
          "print": "E"
        },
        "spareissue": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "sparerecipt": {
          "add": "E",
          "edit": "E",
          "delete": "E"
        },
        "sparediscrepancy": {
          "add": "E",
          "edit": "E",
          "delete": "E",
          "print": "E"
        },
        "sparerequisition": {
          "add": "E",
          "edit": "E",
          "delete": "E",
          "print": "E"
        },
        "jobsheet": {
          "add": "E",
          "edit": "E",
          "delete": "E",
          "print": "E"
        },
        "regoutward": {
          "add": "E",
          "edit": "E",
          "delete": "E",
          "print": "E"
        },
        "routinedc": {
          "add": "E",
          "edit": "E",
          "delete": "E",
          "print": "E"
        },
        "rejectedout": {
          "add": "E",
          "edit": "E",
          "delete": "E",
          "print": "E"
        },
        "manualoutward": {
          "add": "E",
          "edit": "E",
          "delete": "E",
          "print": "E"
        },
        "invoice": {
          "add": "E",
          "edit": "E",
          "delete": "E",
          "print": "E"
        }
      }
    }

  };

  createuseras;
  accessControl = {};
  accessControlWhileEdit = {};
  refreshTree: boolean = true;
  selectAllAdd: boolean = false;
  selectAllEdit: boolean = false;
  selectAllDelete: boolean = false;
  selectAllPrint: boolean = false;
  userList = []
  disabledCreateAs: boolean = true;
  masterData: any[] = [];
  dynamicformdata: any;
  dynamictable: any = {};
  showloader: boolean = false;
  showconfirmpopup: boolean = false;
  displayTable: boolean = false;
  errormsg: string = "";
  headers = new HttpHeaders();
  confirmationpopupmodelobj: ConfirmationPopupModel = new ConfirmationPopupModel();
  validationmsgforQty: string = "";
  showAdduserPopup: boolean = false;
  operation: string = "";
  isEditable: boolean = false;
  userIdForSave;
  rowsperpage = 10;
  userUniqueNo: number = 0;
  userIdToSave: any;
  username: any;
  userrole: any;
  mobilenumber: any;
  emailid: any;
  password: any;
  confirmpassword: any;
  // usertype: any;
  // checkinexpiry: any;
  userRole: string = "";
  userid: number;
  deleteById;
  highlightedObj = {
    "id": "",
    "indx": -1
  }
  deleteMsg = "";
  btnRow: boolean = false
  isRowClicked: boolean = false;
  emptySearchField: boolean = true;
  opeartionStageForDelete: string = "";
  passwordMsg: string = "";
  notmatchmsg: string = "";
  currentSelectedRow: number = 0
    ; validationMsgForUserName: string = "";
  tableheaders = [];
  tabledata = [];
  tableconfig = {
    "tableclasses": "table-striped table-lightblue sticky-header sorting  horizontal-scroll  table-bordered",
    "dragdrop": { "rows": false, "columns": false },
    "oddrowstyle": { "cursor": "pointer" },
    "evenrowstyle": { "cursor": "pointer" },
    "headrowstyle": {},
    "resizable": { "rows": false, "columns": false },
    "columnwisefilter": false,
    "contextmenu": false,
    "hidecolumns": false,
    "defaultsearch": true,
    "headermenulist": [
      { "display": "Hide Column", "identifier": "hidecolumn" },
      {
        "display": "Display Hidden Columns",
        "identifier": "displayhiddencols"
      },
      { "display": "Search", "identifier": "specificsearch" }
    ],
    "exporttoexcel": true,
    "filenameprefix": "test",
    "actioncolmconfig": {
      "displaycolumn": false,
      "view": false,
      "edit": true,
      "delete": true,
      "print": false
    },
    "partno": { "text-align": "left" },
    "Qty": { "text-align": "Right" },
    "CurrentBalance": { "text-align": "Right" },
    "WIP": { "text-align": "Right" },
    "controlconfig": { "displaycontrol": false },
    // "checkDeletedFlag": true,
    // "checkDeletedFlagKey": "isDeleted",
    "fullheighttable": "fullheight-table"
  };
  tblevent = {};
  showDynamicUserForm: boolean = true;
  addItemButton: boolean = false;
  editDisableBtn: boolean = false;
  saveDisableBtn: boolean = false;
  saveBtn;
  editBtn: boolean = false;
  deleteBtn: boolean = false;
  cancelBtn: boolean = false;
  newForm: boolean = false;
  isValidForm: boolean = true;
  setDefaultPermission = [];

  @ViewChild('showAdduserModal', { static: false }) public showAdduserModal: ModalDirective;

  constructor(private commonservices: CommonServicesService, private fb: FormBuilder, private contextMenuService: ContextMenuService, private configurationsetting: ConfigurationSetting, private toastService: ToastService) { }

  ngOnInit() {
    this.userRole = JSON.parse(sessionStorage.getItem("logindata"))['UserRole'];
    this.userid = JSON.parse(sessionStorage.getItem("logindata"))['UserID'];
    this.getUserMasterTable();
    this.getUserList();
    this.setDefaultPermission = JSON.parse(JSON.stringify(this.permissionData))
  }

  onSubmitBtnClick() {
    if (
      this.dynamicformdata['controls']['username']['value'] == "" ||
      this.dynamicformdata['controls']['confirmpassword']['value'] == "" ||
      this.dynamicformdata['controls']['emailId']['value'] == "" ||
      this.dynamicformdata['controls']['mobileno']['value'] == "" ||
      this.dynamicformdata['controls']['password']['value'] == "" ||
      this.dynamicformdata['controls']['userrole']['value'] == ""
    ) {
      this.toastService.showToastMsg('warning', 'Warning', "Please fill required fields.");
      return;
    }
    // this.submitButtonEventSubject.emit('savebtnclick');
    this.submitButtonEventSubject.next('save');
  }


  checkUniqueUserName(event) {
    if (navigator.onLine) {
      if (event['controlname'] == "username") {
        this.errormsg = "";
        this.validationMsgForUserName = "";
        this.showloader = true;
        this.loaderTimeOut();
        let apiurl = this.configurationsetting.getBaseUrl() + "Master/CheckUniqueUser";
        let params = { "username": event['value']['currentTarget']['value'] };


        this.commonservices.getWithParams(apiurl, params)
          .subscribe(successResp => {
            this.showloader = false;
            if (successResp['errorCode'] == "000000") {
              this.validationMsgForUserName = "";
              this.dynamicformdata['css']['btnconfiguration']['disableSave'] = false;
            } else if (successResp['errorCode'] == 1) {
              this.validationMsgForUserName = successResp['errorDescription'];
              this.dynamicformdata['css']['btnconfiguration']['disableSave'] = false;
            }
          }, errorResp => {
            this.showloader = false;
            this.errormsg = errorResp['message']
          });
      }

    } else {
      this.showloader = false;
      alert("Internet Connection is Not Available.");
    }
  }

  getUserMasterTable() {
    this.errormsg = "";
    this.showloader = true;

    this.commonservices.getGenericTableNForm("UserMasterForm", "UserMasterTable", this.configurationsetting.getBaseUrl())
      .subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.dynamicformdata = successResp['Data'][0]['@formjson'];

          this.dynamicformdata['controls']['userrole']['required'] = true;
          // this.dynamicformdata['controls']['usertype']['required'] = true;
          this.dynamicformdata['controls']['emailId']['required'] = true;
          this.setBootstrapClasses();
          this.dynamictable = successResp['Data'][0]['@tbljson'];
          this.dynamictable['tabledata'] = successResp['Data'][0]['@tbldata'];

          this.disabledFields();

          this.editDisableBtn = false;
          this.saveDisableBtn = false;
          this.deleteBtn = false;
          this.cancelBtn = false;
          this.newForm = false;

          // console.log(this.dynamicformdata);


          let rowData = successResp['Data'][0]['@tbldata']
          for (let i = 0; i < rowData.length; i++) {
            if (rowData[i]['UserID'] == this.userUniqueNo) {
              // console.log(rowData[i]);
              this.rowSingleClick({
                "row": rowData[i],
                "rowindex": i
              })
              setTimeout(() => {
                $(`tr[tabindex=${i}]`).focus();
                document.getElementById('trtable1' + i).style.backgroundColor = "#7de4f0";
              }, 900);
              break;
            }
          }

          /*********changes starts here********/
          if (this.userRole == "Admin") {
            this.dynamictable['tableconfig']['actioncolmconfig']['delete'] = true;
          } else {
            this.dynamictable['tableconfig']['actioncolmconfig']['delete'] = false;
          }
          /********chnages ends here*********/
          // this.tableconfig = this.dynamictable["tableconfig"];
          this.tabledata = this.dynamictable["tabledata"];
          this.tableheaders = this.dynamictable["tableheaders"];
          this.displayTable = true;
          // setTimeout(() => {
          this.disabledFields();
          if (this.dynamicformdata && this.dynamicformdata['css']) {
            this.dynamicformdata['css']['btnconfiguration']['disableSave'] = true;
          }

          // }, 800)
        }
        else {
          this.errormsg = successResp["errorDescription"];
        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']

      });

  }
  addItemBtn() { }

  deleteUserMaster(event) {
    if (navigator.onLine) {
      this.deleteById = event['row']['UserID'];
      this.opeartionStageForDelete = "delete";
      this.displayMessagePopup("Are you sure want to delete this record?", "Yes", true, "No", true);
    }
    else {
      this.displayMessagePopup("Internet Connection is Not Available.", "Ok", true, false, "");
    }
  }
  confirmDeleteUserMaster() {
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/DeleteMasterRecords";
      let obj = {};
      obj['deletefor'] = "User"
      obj['recordvalue'] = this.deleteById;
      let param = { "criteria": obj }
      this.commonservices.postMethod(apiUrl, param).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.showconfirmpopup = false;
          this.disabledFields();
          this.saveDisableBtn = true;
          this.deleteBtn = false;
          this.cancelBtn = false;
          this.btnRow = false;
          this.dynamicformdata['controls']['username']['disabled'] = true;
          this.dynamicformdata['controls']['password']['disabled'] = true;
          this.dynamicformdata['controls']['confirmpassword']['disabled'] = true;
          this.dynamicformdata['controls']['mobileno']['disabled'] = true;
          this.dynamicformdata['controls']['userrole']['disabled'] = true;
          // this.dynamicformdata['controls']['usertype']['disabled'] = true;
          this.dynamicformdata['controls']['emailId']['disabled'] = false;
          this.opeartionStageForDelete = "deleted";

          const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
          if (ele) ele.style.backgroundColor = '';

          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);
        } else if (successResp['errorCode'] == 111000) {
          this.displayMessagePopup(this.commonservices.capitalize(successResp['errorDescription']), "Ok", true, "", false);

        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      this.showloader = false;

      alert("Internet Connection is Not Available.");

    }
  }

  cancelUserMaster() {
    if (confirm("Do you want to cancel!")) {
      if (this.operation === "NEW") {
        this.dynamicformdata['controls']['username']['value'] = "";
        this.dynamicformdata['controls']['password']['value'] = "";
        this.dynamicformdata['controls']['confirmpassword']['value'] = "";
        this.dynamicformdata['controls']['mobileno']['value'] = "";
        this.dynamicformdata['controls']['userrole']['value'] = "";
        // this.dynamicformdata['controls']['usertype']['value'] = "";
        this.dynamicformdata['controls']['emailId']['value'] = "";
        this.notmatchmsg = "";
        this.editBtn = false;
        this.btnRow = false;
      } else {
        this.btnRow = true;
        this.editBtn = true;
      }
      this.disabledCreateAs = true;
      this.isEditable = false;
      this.operation = "";
      this.deleteBtn = false;
      this.saveDisableBtn = false;
      this.cancelBtn = false;
      this.editDisableBtn = false;
      this.deleteMsg = "";
      this.newForm = false;
      this.dynamicformdata['controls']['username']['disabled'] = true;
      this.dynamicformdata['controls']['password']['disabled'] = true;
      this.dynamicformdata['controls']['confirmpassword']['disabled'] = true;
      this.dynamicformdata['controls']['mobileno']['disabled'] = true;
      this.dynamicformdata['controls']['userrole']['disabled'] = true;
      // this.dynamicformdata['controls']['usertype']['disabled'] = true;
      this.dynamicformdata['controls']['emailId']['disabled'] = true;



      setTimeout(() => {
        this.dynamicformdata['css']['btnconfiguration']['disableSave'] = true;
      }, 800)
      this.validationMsgForUserName = '';
    }
  }

  cancelConfirmationPopup() {
    this.showconfirmpopup = false;
    this.opeartionStageForDelete = "";
  }

  confirmPopup(event) {
    if (this.opeartionStageForDelete == "delete") {
      this.opeartionStageForDelete = "";
      this.confirmDeleteUserMaster();
    }
    else if (this.opeartionStageForDelete == "deleted") {
      this.opeartionStageForDelete = "";
      this.getUserMasterTable();

    }
    this.showconfirmpopup = false;
  }
  deleteUserMasterData(event) {
    this.deleteUserMaster(event);

  }
  submitUserMaster(event) {

    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/SaveUserMaster";
      let obj = {};

      obj['username'] = event['username'];
      obj['userrole'] = event['userrole'];
      obj['mobileno'] = event['mobileno'];
      obj['emailId'] = event['emailId'];
      obj['password'] = event['password'];
      obj['confirmpassword'] = event['confirmpassword'];
      // obj['usertype'] = event['usertype'];
      // obj['checkinexpiry'] = event['checkinexpiry'][0];
      obj['accesscontrol'] = this.savePermissionObj;


      if (this.isEditable) {
        obj['userid'] = this.userIdToSave;
        this.isEditable = false;
      } else {
        //send userid=0 when it is a new entry i.e while saving
        obj['userid'] = 0;
      }
      
      this.commonservices.postMethod(apiUrl, obj).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          // if (obj['userid'] != 0) {
          this.userUniqueNo = successResp['Data'][0]['UserID'];

          this.displayMessagePopup(successResp['errorDescription'], "Ok", true, "", false);
          this.getUserMasterTable();
          if (this.tblevent) {
            this.rowSingleClick(this.tblevent, false);
          }


          this.operation = "";
          if (obj['userid'] == 0) {
            this.btnRow = false;
          }

          setTimeout(() => {
            this.disabledFields();
          }, 500)
          this.highlightedObj.id = 'trtable1' + this.tblevent['rowindex'];
          this.highlightedObj.indx = this.tblevent['rowindex'];
          this.username = successResp['Data'][0]['username'];
          this.password = successResp['Data'][0]['password'];
          this.confirmpassword = successResp['Data'][0]['confirmpassword'];
          this.emailid = successResp['Data'][0]['emailid'];
          this.mobilenumber = successResp['Data'][0]['mobilenumber'];
          this.userrole = successResp['Data'][0]['userrole'];
          // this.usertype = successResp['Data'][0]['usertype'];

        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {
      alert("Internet Connection is Not Available.");

    }
  }

  disabledFields() {
    if (!this.dynamicformdata || !this.dynamicformdata['controls']) {
      return;
    }
    this.dynamicformdata['controls']['username']['disabled'] = true;
    this.dynamicformdata['controls']['password']['disabled'] = true;
    this.dynamicformdata['controls']['confirmpassword']['disabled'] = true;
    this.dynamicformdata['controls']['mobileno']['disabled'] = true;
    this.dynamicformdata['controls']['userrole']['disabled'] = true;
    // this.dynamicformdata['controls']['usertype']['disabled'] = true;

    // console.log(this.dynamicformdata['controls']);

    this.dynamicformdata['controls']['emailId']['disabled'] = true;


  }

  // // function to compare entered passwoed and confirm password.
  checkPassword(event) {
    this.errormsg = "";
    this.notmatchmsg = "";
    this.passwordMsg = "";
    if (event['controlname'] == "password") {
      if (this.dynamicformdata['controls']['confirmpassword']['value'] && (this.dynamicformdata['controls']['password']['value'] != this.dynamicformdata['controls']['confirmpassword']['value'])) {
        this.notmatchmsg = "Password and Confirm Password does not Match.";
      }
    }

    if (event['controlname'] == "confirmpassword") {
      if (this.dynamicformdata['controls']['password']['value'] && (this.dynamicformdata['controls']['password']['value'] != this.dynamicformdata['controls']['confirmpassword']['value'])) {
        this.notmatchmsg = "Password and Confirm Password does not Match.";
      }
    }


    // if (event['controlname'] == "confirmpassword") {
    //   this.errormsg = "";
    //   this.notmatchmsg = "";
    //   this.passwordMsg = "";
    //   if (this.dynamicformdata['controls']['password']['value'] != event['value']['currentTarget']['value']) {

    //     this.notmatchmsg = "Password and Confirm Password does not Match.";
    //     this.dynamicformdata['controls']['confirmpassword']['value'] = "";
    //   } else if (event['value']['currentTarget']['value'] == undefined) {
    //     this.notmatchmsg = "Please enter Password first.";
    //   }
    //   else if (event['value']['currentTarget']['value'] == "") {
    //     this.errormsg = "";
    //     this.notmatchmsg = "";
    //   }
    //   else if (this.dynamicformdata['controls']['password']['value'] == undefined) {
    //     this.errormsg = "";
    //     this.notmatchmsg = "";
    //   }

    // }

    // if (event['controlname'] == "password") {
    //   this.errormsg = "";
    //   this.notmatchmsg = "";
    //   this.passwordMsg = "";
    //   if (this.dynamicformdata['controls']['confirmpassword']['value'] != event['value']['currentTarget']['value']) {

    //     this.notmatchmsg = "Password and Confirm Password does not Match.";
    //   }

    // }

  }

  setBootstrapClasses() {
    let colName = 0;
    for (let i = 0; i < this.dynamicformdata['css']["mappingarray"].length; i++) {
      for (let j = 0; j < this.dynamicformdata['css']["mappingarray"][i].length; j++) {
        if (this.dynamicformdata['css']["mappingarray"][i][j]["colspan"] != undefined)
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]) * +this.dynamicformdata["mappingarray"][i][j]["colspan"];
          colName = +this.dynamicformdata['css']["gridinfo"]["gridcols"] * +this.dynamicformdata['css']["mappingarray"][i][j]["colspan"];
        else
          //colName = (12 / +this.dynamicformdata["gridinfo"]["gridcols"]);
          colName = +this.dynamicformdata['css']["gridinfo"]["gridcols"];
        this.dynamicformdata['css']["mappingarray"][i][j]["ColClassName"] = "col-xs-12 col-sm-12 col-md-" + colName + " col-lg-" + colName + " col-xl-" + colName;
      }
    }
  }

  editForm(event) {
    // console.log(event);

    this.editUserForm(event);
    this.deleteBtn = true;
    this.editDisableBtn = true;
    this.saveDisableBtn = true;
    this.cancelBtn = true;
    this.editBtn = false
    this.newForm = true;
    this.disabledCreateAs = false;
  }

  /**editUserMaster(event) method gives populated form for edit starts here*/

  editUserForm(event) {
    this.dynamicformdata['controls']['username']['disabled'] = true;
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      let apiUrl = this.configurationsetting.getBaseUrl() + "Master/EditUserMaster";
      let params = { "userid": event['row']['UserID'] };

      this.userIdToSave = event['row']['UserID']
      this.username = event['username'];
      this.userrole = event['userrole'];
      this.mobilenumber = event['mobilenumber'];
      this.emailid = event['emailid'];
      this.password = event['password'];
      this.confirmpassword = event['confirmpassword'];
      // this.usertype = event['usertype'];
      // this.checkinexpiry = event['checkinexpiry'];
      // console.log(event);

      // console.log(event['row']['accesscontrol']);

      // this.accessControlWhileEdit = JSON.parse(event['row']['accesscontrol']);
      // console.log(this.accessControlWhileEdit);

      this.dynamicformdata['controls']['username']['disabled'] = false;
      this.dynamicformdata['controls']['password']['disabled'] = false;
      this.dynamicformdata['controls']['confirmpassword']['disabled'] = false;
      this.dynamicformdata['controls']['mobileno']['disabled'] = false;
      this.dynamicformdata['controls']['userrole']['disabled'] = false;
      // this.dynamicformdata['controls']['usertype']['disabled'] = false;
      setTimeout(() => {
        this.dynamicformdata['controls']['emailId']['disabled'] = false;
      }, 200);

      this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
        this.showloader = false;
        if (successResp['errorCode'] == "000000") {
          this.dynamicformdata = {};
          this.dynamicformdata = successResp['Data'];
          this.setBootstrapClasses();
          this.isEditable = true;
          this.operation = "Edit";
          // this.showAdduserModal.show();
          this.showDynamicUserForm = true;
          this.showAdduserPopup = true;
        } else {

          this.errormsg = successResp['errorDescription']

        }
      }, errorResp => {
        this.showloader = false;
        this.errormsg = errorResp['message']
      });

    }
    else {

      alert("Internet Connection is Not Available.");

    }
  }
  /**editUserMaster(event)ends here*/

  getUserForm() {
    // this.tblevent = {};
    this.createuseras = "";
    this.operation = "NEW"
    this.emptySearchField = false;
    this.userIdToSave = 0;
    this.userUniqueNo = 0;
    // this.showAdduserModal.show();
    this.newForm = true;
    this.saveDisableBtn = true;
    this.editBtn = false;
    this.deleteMsg = "";
    this.btnRow = true;
    this.cancelBtn = true;
    this.dynamicformdata['controls']['username']['disabled'] = false;
    this.dynamicformdata['controls']['password']['disabled'] = false;
    this.dynamicformdata['controls']['confirmpassword']['disabled'] = false;
    this.dynamicformdata['controls']['mobileno']['disabled'] = false;
    this.dynamicformdata['controls']['userrole']['disabled'] = false;
    // this.dynamicformdata['controls']['usertype']['disabled'] = false;
    this.dynamicformdata['controls']['emailId']['disabled'] = false;

    this.dynamicformdata['controls']['username']['value'] = "";
    this.dynamicformdata['controls']['password']['value'] = "";
    this.dynamicformdata['controls']['confirmpassword']['value'] = "";
    this.dynamicformdata['controls']['mobileno']['value'] = "";
    this.dynamicformdata['controls']['userrole']['value'] = "";
    // this.dynamicformdata['controls']['usertype']['value'] = "";
    this.dynamicformdata['controls']['emailId']['value'] = "";
    // this.dynamicformdata['controls']['checkinexpiry']['options'][0]['checked'] = false;

    this.dynamicformdata['controls']['userrole']['required'] = true;
    this.dynamicformdata['controls']['emailId']['required'] = true;

    this.showDynamicUserForm = true;
    this.showAdduserPopup = true;
    this.validationMsgForUserName = "";
    const ele = document.getElementById(`tr${"table1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
    this.disabledCreateAs = false;

  }

  rowSingleClick(event, flag = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 3000);
      this.isRowClicked = true;

      return;
    }
    this.isRowClicked = true;
    if (this.dynamicformdata['controls']['username']['disabled'] == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }
    this.createuseras = "";
    this.tblevent = event;
    this.disabledCreateAs = true;
    setTimeout(() => {
      this.currentSelectedRow = event.rowindex;
      this.commonservices.identifySelectedRow(this.tabledata, event.rowindex, this.currentSelectedRow, "table1");
    }, 200);
    let userdeleted = event['username']
    let deletedDate = event['deldate']
    if (event["isDeleted"] === 1) {
      this.btnRow = false;
      this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
    } else {
      this.btnRow = true;
      this.deleteMsg = ""
    }
    this.editBtn = true;
    if (navigator.onLine) {
      this.errormsg = "";
      this.showloader = true;
      this.loaderTimeOut();
      this.updateUserData(this.tblevent)
    }
    else {

      alert("Internet Connection is Not Available.");

    }
  }

  updateUserData(event) {
    // console.log(event);

    let apiUrl = this.configurationsetting.getBaseUrl() + "Master/EditUserMaster";
    let params = { "userid": event?.row?.UserID };

    let row = event['row']
    let userdeleted = event['username']
    let deletedDate = event['deldate']
    if (row?.isDeleted === "N") {
      this.username = "";
      this.userrole = "";
      this.mobilenumber = "";
      this.emailid = "";
      this.password = "";
      this.confirmpassword = "";
      // this.usertype = "";
      // this.checkinexpiry = "";
      // this.deleteMsg = `Record is Deleted by ${userdeleted} on ${deletedDate}`
    } else {
      this.btnRow = true;
      // this.deleteMsg = ""
    }
    this.editBtn = true;

    this.commonservices.getWithParams(apiUrl, params).subscribe(successResp => {
      this.showloader = false;
      if (successResp['errorCode'] == "000000") {
        this.dynamicformdata = {};
        this.dynamicformdata = successResp['Data'];
        this.setBootstrapClasses();
        this.isEditable = true;
        this.operation = "Edit";
        // this.showAdduserModal.show();
        this.showDynamicUserForm = true;
        this.showAdduserPopup = true;
        this.disabledFields();
        this.isRowClicked = false;
        // setTimeout(() => {
        // }, 500)
      } else {

        this.errormsg = successResp['errorDescription']
        this.isRowClicked = false;

      }
    }, errorResp => {
      this.isRowClicked = false;

      this.showloader = false;
      this.errormsg = errorResp['message']
    });

  }
  bindData() {

  }

  changeSelect(event) {

  }
  // confirmPopup(event) {
  //   this.showconfirmpopup = false;
  // }

  //displayMessagePopup() is used to call and maintain confirmation popup modal
  displayMessagePopup(popupmessage, submittext, displaysubmit, canceltext, displaycancel) {
    this.confirmationpopupmodelobj.inputmsg = popupmessage;
    this.confirmationpopupmodelobj.submitbtntext = submittext
    this.confirmationpopupmodelobj.displaysubmit = displaysubmit;
    this.confirmationpopupmodelobj.cancelbtntext = canceltext;
    this.confirmationpopupmodelobj.displaycancel = displaycancel;
    this.showconfirmpopup = true;
  }
  closePopUpEvent(whichForm) {
    setTimeout(() => {
      this.disabledFields();
    }, 800)
    switch (whichForm) {
      case 'closePopup':
        this.dynamicformdata['controls']['username']['value'] = "";
        this.dynamicformdata['controls']['password']['value'] = "";
        this.dynamicformdata['controls']['confirmpassword']['value'] = "";
        this.dynamicformdata['controls']['mobileno']['value'] = "";
        this.dynamicformdata['controls']['emailId']['value'] = "";
        this.dynamicformdata['controls']['userrole']['value'] = "";
        // this.dynamicformdata['controls']['usertype']['value'] = "";
        // this.showAdduserModal.hide();
        this.showDynamicUserForm = false;
        this.showAdduserPopup = false;
        break;

      case 'closeDynamicForm':
        this.dynamicformdata.controls.username.value = "";
        this.dynamicformdata['controls']['password']['value'] = "";
        this.dynamicformdata['controls']['confirmpassword']['value'] = "";
        this.dynamicformdata['controls']['mobileno']['value'] = "";
        this.dynamicformdata['controls']['emailId']['value'] = "";
        this.dynamicformdata['controls']['userrole']['value'] = "";
        // this.dynamicformdata['controls']['usertype']['value'] = "";
        // this.dynamicformdata['controls']['checkinexpiry']['options'][0]['checked'] = false;
        this.showAdduserModal.hide();
        this.showDynamicUserForm = false;
        this.showAdduserPopup = false;
        break;
    }

  }

  //////if http not gave the response after spend 30 seconds the we will show the error.
  loaderTimeOut() {
    setTimeout(() => {
      if (this.showloader) {
        this.showloader = false;
        this.errormsg = "Error while login your account.";
      }
    }, 50000)
  }

  getUserList() {
    let endpoint = 'master/getUserList';
    this.commonservices.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.userList = [];
        this.userList = successResp['Data'];
      }
    }, err => { })
  }

  getUserAccessControlById(id) {
    let endpoint = this.configurationsetting.getBaseUrl() + 'master/getUserAccessControlById';
    let param = { uid: id }
    this.commonservices.getWithParams(endpoint, param).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.accessControl = successResp['Data']?.accesscontrol ? JSON.parse(successResp['Data']['accesscontrol']) : {};
        this.mapAccessControl();

      }
    }, err => { })
  }

  userListSelect(id) {
    this.selectAllAdd = false;
    this.selectAllEdit = false;
    this.selectAllDelete = false;
    this.selectAllPrint = false;

    this.getUserAccessControlById(id)
  }

  createNewAccesControl() {
    this.selectAllAdd = false;
    this.selectAllEdit = false;
    this.selectAllDelete = false;
    this.selectAllPrint = false;
    this.permissionData = [];
    this.setDefaultPermission.forEach((route) => {
      route.permission = true;
      this.setPermissionByDefault(route);
    })
    this.accessPermissionsModal2.show();

    this.permissionData = this.setDefaultPermission;
  }

  setPermissionByDefault(route) {
    if (route?.children) {
      route.children.forEach(child => {
        child.permission = true;
        // child.cssClass = 'all-select'
        if (child?.buttons) {
          child.buttons.add = true;
          child.buttons.edit = true;
          child.buttons.delete = true;
          if (child?.buttons?.print)
            child.buttons.print = true;
        }
        this.setPermissionByDefault(child);
      })
    }
  }

  savePermission() {

    this.permissionData.forEach((route) => {
      this.savePermissionObj.tabs[route.name] = this.generateTabSavePermission(route);
      this.savePermissionObj.btns[route.name] = this.generateBtnSavePermission(route, {})
    })
    // console.log("savePermission", this.savePermissionObj);
  }

  editAccess() {
    this.getUserAccessControlById(this.userIdToSave);
  }


  mapAccessControl() {
    if (Object.keys(this.accessControl).length === 0) {
      alert("No Access control set for this user. Please create new user access or copy user access")
      return;
    } else {
      this.permissionData.forEach((itemObj) => {
        const name = itemObj['name'];
        const accessMenuObj = this.accessControl['tabs'][name];
        const accessButtonObj = this.accessControl['btns'][name];
        return this.setAccessControlObj(itemObj, accessMenuObj, accessButtonObj)
      })
      this.savePermissionObj['tabs'] = this.accessControl['tabs'];
      this.savePermissionObj['btns'] = this.accessControl['btns'];
      this.accessPermissionsModal2.show();
    }
  }

  private setAccessControlObj(itemObj, accessMenuObj, buttonObj) {

    if (accessMenuObj?.permission == "Y") {
      itemObj['permission'] = true;
    } else {
      itemObj['permission'] = false;
    }
    if (itemObj?.children?.length) {
      itemObj['children'].forEach((item) => {
        let name = item['name'];
        let permissionObj = accessMenuObj?.children?.[name];

        // console.log(name, item?.buttons, buttonObj[name]);
        if (item.buttons) {
          item.buttons.add = buttonObj[name]?.add == 'E' ? true : false;
          item.buttons.edit = buttonObj[name]?.edit == 'E' ? true : false;
          item.buttons.delete = buttonObj[name]?.delete == 'E' ? true : false;
          if (item.buttons.print)
            item.buttons.print = buttonObj[name]?.print == 'E' ? true : false;
        }
        return this.setAccessControlObj(item, permissionObj, buttonObj)
      })
    }
  }

  checkboxClickForAll(event, type) {
    event = event.target.checked;
    // console.log(event, type);

    this.permissionData.forEach((item) => {
      item['permission'] = true;
      // item['buttons'][type] = event;

      return this.checkedAllAction(event, item, type)
    })
  }

  private checkedAllAction(event, item, type) {
    // console.log("checkedAllAction", event, item, type);
    // item['permission'] = event;

    if (item?.children?.length) {
      item.children.forEach(element => {
        element['permission'] = true;
        if (element?.buttons) {
          // console.log(element['buttons'][type]);

          // if(element?.buttons?.print){
          //   element['buttons']['print'] = event;
          // }else{
          element['buttons'][type] = event;
          // }
        }
        // this.onCheckboxClick(event, route.name, 'route', nestedLevelForRoute, route, clickLevel);
        return this.checkedAllAction(event, element, type);
      })

    }
  }

  classControl(route) {
    // let element= document.getElementById(id)

    // // element.className.add('fa-angle-double-right')
    // console.log('route', route);
    route.icon = !route.icon

  }
  onCheckboxClick(event: boolean, name: string, type: string, nestedLevel: string, route = undefined) {
    // console.log("onCheckboxButuns", event, name, type, nestedLevel, route);


    // const tabObj = eval("this.savePermissionObj['tabs']." + nestedLevel);
    const tabObj = this.savePermissionObj['tabs'][nestedLevel]
    // console.log({ saveTabObj: tabObj, nestedLevel });


    // let nestFirstLvl = nestedLevel.split('.');
    if (type == 'route') {

      if (tabObj?.permission) {
        tabObj.permission = event ? "Y" : "N";
        route.icon = event;
      }

    } else {
      const btnObj = this.savePermissionObj['btns'];
      if (btnObj && btnObj[nestedLevel] && btnObj[nestedLevel][name] && btnObj[nestedLevel][name][type]) {
        btnObj[nestedLevel][name][type] = event ? "E" : "H";
      }
    }
  }

  clickForReverseSelection(event, name, tabObj, nestedLevel, result) {
    let subTabObj = eval("this.savePermissionObj['tabs']." + `${nestedLevel}.children.${name}`);
    tabObj.permission = event ? "Y" : "N";
    subTabObj.permission = event ? "Y" : "N";

    this.permissionData.forEach((item) => {
      if (item['name'] == nestedLevel) {
        item['permission'] = true;
        item['class'] = 'limited-access';
        return this.reverseSelection(item, name, result)
      }
    })
  }

  reverseSelection(item, name, result) {
    if (item?.children?.length) {
      item['children'].forEach((element) => {
        if (element['name'] == name) {
          element['permission'] = true;
          // element['class'] = result ? 'all-select' : 'limited-access';

          return this.reverseSelection(element, name, result);
        }
      })
    }
  }
  checkBoxClick(flag: boolean, route, nestedLevelForRoute: string, rootParentName: string) {
    // console.log('checkBoxClick',flag, route, nestedLevelForRoute, rootParentName);
    // route.icon = flag;
    this.markFlag(flag, route);
    if (flag) {
      const pLength = this.permissionData.length
      for (let index = 0; index < pLength; index++) {
        const route = this.permissionData[index];
        if (route.name == rootParentName) {
          this.reverceCheckTrue(true, route, nestedLevelForRoute)
          break;
        }
      }
    }
    // console.log('permissionData',this.permissionData);
    return;
  }
  markFlag(flag, route) {
    // console.warn('markFlag',flag, route);

    route.permission = flag
    route.icon = flag;
    // route.cssClass = flag ? 'all-select' : 'limited-access';
    if (route?.buttons?.add != undefined) {
      route.buttons.add = flag
    }

    if (route?.buttons?.edit != undefined) {
      route.buttons.edit = flag
    }

    if (route?.buttons?.delete != undefined) {
      route.buttons.delete = flag
    }

    if (route?.buttons?.print != undefined) {
      route.buttons.print = flag
    }
    if (route?.children) {
      route.children.forEach(child => {
        this.markFlag(flag, child)
      });
    }

  }
  actionButtonClick(event: boolean, name: string, type: string, nestedLevel: string, nestedLevelForRoute: string) {
    // this.onCheckboxClick(event, name, type, nestedLevel);

    // let cssClass: 'all-select' | 'limited-access' = event ? 'all-select' : 'limited-access'

    const pLength = this.permissionData.length
    for (let index = 0; index < pLength; index++) {
      const route = this.permissionData[index];
      if (route.name == nestedLevel) {
        this.reverceCheckTrue(true, route, nestedLevelForRoute)
        break;
      }
    }
    // console.log('permissionData', this.permissionData);

  }

  reverceCheckTrue = (flag: boolean, route: ROUTE, nestedLevelForRoute: string) => {
    if (nestedLevelForRoute.includes(route.name)) {
      // console.warn('reverceCheck', route, nestedLevelForRoute);
      route.permission = flag;
      route.icon = flag;
      // route.cssClass = cssClass;
      // this.onCheckboxClick(true, route.name, 'route', nestedLevelForRoute, route);
      route?.children?.forEach(child => {
        this.reverceCheckTrue(flag, child, nestedLevelForRoute)
      });
    }

  }
  generateTabSavePermission(route) {
    const tab: any = {
      permission: route.permission ? "Y" : "N",
    }
    if (route?.children) {
      const children = {
      }
      route?.children.forEach(child => {
        children[child.name] = this.generateTabSavePermission(child)
      })
      tab.children = children
    }
    return tab
  }
  generateBtnSavePermission(route, btnObj) {
    if (route?.buttons) {
      const btn = route?.buttons
      const actionBtn = {
        "add": btn?.add ? "E" : "H",
        "edit": btn?.edit ? "E" : "H",
        "delete": btn?.delete ? "E" : "H",
        "print": btn?.print ? "E" : "H"
      }
      btnObj[route.name] = actionBtn
    }
    if (route?.children) {
      route?.children.forEach(child => {
        this.generateBtnSavePermission(child, btnObj);
      })
    }
    return btnObj
  }
}
