export class ManageCoockies {

    constructor() { }


    setCookie(name: string, val: string) {
        const date = new Date();
        const value = val;
        // Set it expire in 7 days
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
        // Set it
        document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
    }


    getCookie(name: string) {
        ///new code
        const cookiesarr: any[] = document.cookie.split(";");
        for (let cindx = 0; cindx < cookiesarr.length; cindx++) {
            if (cookiesarr[cindx].indexOf(name) != -1) {
                return cookiesarr[cindx].split("=")[1];
            }
        }
        return "";
    }


    deleteCookie(name: string) {
        const date = new Date();
        // Set it expire in -1 days
        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
        // Set it
        document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
    }

}