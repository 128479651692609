import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonServicesService } from '../../services/common-services.service';
import { SubmitFormService } from '../../services/submit-form.service';
import { ApiService } from '../../services/api.service';
import { ConfigurationSetting } from '../../appconfig';
import { SystemParamsService } from '../../services/system-params.service';
import { HelperService } from '../../services/helper.service';
import { DatePipe, Location } from '@angular/common';
import { PO_LEFT_SIDE_TABLE_JSON, PO_DETAILS_TABLE_JSON, PO_POPUP_ITEMS_TABLE_JSON, POPUP_ITEMS_ROUTINE_TABLE_JSON } from './pogtconfig'
import { ToastService } from '../../services/toast.service';
declare var $;
@Component({
  selector: 'app-po-master',
  templateUrl: './po-master.component.html',
  styleUrls: ['./po-master.component.scss']
})
export class PoMasterComponent implements OnInit {
  @ViewChild('errorMessageModal', { static: false }) public errorMessageModal;
  @ViewChild('showItemPopup', { static: false }) public showPopupToAddItemForPoMaster;
  @ViewChild('backendErrorMessageModal', { static: false }) public backendErrorMessageModal;
  @ViewChild('showDeleteModal', { static: false }) public showDeleteModal;
  @ViewChild('showItemAlert', { static: false }) public showItemAlert;
  @ViewChild('PopupMessageModal', { static: false }) public PopupMessageModal;
  @ViewChild('ItemRoutineDcItemsPopup', { static: false }) public ItemRoutineDcItemsPopup;

  createNewBtn: boolean = false;
  editBtn: boolean = false;
  cancelBtn: boolean = false;
  saveBtn: boolean = true;
  saveBtnVisible: boolean = false;
  addItemButton: boolean = false;
  editDisableBtn: boolean = false;
  currentSelectedRow: number;
  isPoPopUpOpen: boolean = false;
  basicAmtSum = 0;
  deleteBtnDisable: boolean = false;
  btnRow: boolean = false;
  showloader: boolean = false;
  isRowClicked: boolean = false;
  showBasicAmt: boolean = true;
  poMasterTableData: any[] = [];
  // maxDate = new Date();
  isclosed = "OPEN"
  deletedArray: number[] = [];
  minDate;
  deleteMsg: string = "";
  ErrorDescription = "";
  ErrorHeader = "";
  ErrorObj: any = {};
  label: string = 'PO Master';
  newBtnLabel = "Add New PO";
  poValidMsg: boolean = false;
  poMaxDate: any = []
  poMinDate: any = []
  pouniqueno: number = 0;
  private backupData: any = { bindDataToFormAndTable: {} };
  tabledataforprocess: any[] = [];
  deepCopyForDetailTable: any = {};
  todayDate: any = new Date();
  potype: number = 0;
  isRejectedPo: string = '';
  itemFor: string = '';
  refreshDetailTable: boolean = true;
  operation: string = ''
  model = {
    pono: {
      valuType: 'Number', value: '', label: "PoNo", isRequired: false,
      err: "", element: { name: 'input', type: 'number' }
    },
    CustomerNo: {
      valuType: 'String', value: '', label: "Customer Name", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isBlanketPo: {
      value: false, isRequired: false, err: "", label: "Blanket Po", disable: true,
    },
    ispoforreject: {
      value: false, isRequired: false, err: "", label: "Po for rejected items",
      disable: true,
    },
    PoNo: {
      valuType: 'String', value: '', label: "PO No", isRequired: true, disable: true,
      maxLength: 25, err: "", element: { name: 'input', type: 'text' }
    },
    PrNo: {
      valuType: 'String', value: '', label: "PR No", isRequired: false, disable: true,
      minLength: 4, err: "", element: { name: 'input', type: 'text' }
    },
    PoDate: {
      valuType: 'Date', value: '', label: "PO Date", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'date' }
    },
    recievedOn: {
      valuType: 'Date', value: '', label: "Recieved Date", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'date' }
    },
    basicamount: {
      valuType: 'Number', value: '', label: "Basic Amount", isRequired: false,
      disable: true, minLength: 3, err: "", element: { name: 'input', type: 'number' }
    },
    tax: {
      valuType: 'String', value: '', label: "Tax", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'String' }
    },
    taxRate: {
      valuType: 'Number', value: 0, label: "Tax Rate", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    sachsncode: {
      valuType: 'Number', value: "", label: "SAC/HSN Code", isRequired: true,
      disable: true, err: "", element: { name: 'input', type: 'number' }
    },
    remark: {
      valuType: 'String', value: '', label: "Remark", isRequired: false,
      disable: true, err: "", element: { name: 'input', type: 'text' }
    },
    isInValid: true
  }

  customerNameArray: any[] = [];
  taxArray = []
  todaysDate: any = [];

  emptySearchField: boolean = true;

  // Left gt-table
  pomastertableconfig: any = PO_LEFT_SIDE_TABLE_JSON.tableconfig;
  pomastertableheaders: any[] = PO_LEFT_SIDE_TABLE_JSON.tableheaders;
  pomastertabledata: any[] = [];

  // Below gt-table
  podetailstableconfig: any = PO_DETAILS_TABLE_JSON.tableconfig;
  podetailstableheaders: any = PO_DETAILS_TABLE_JSON.tableheaders;
  podetailstabledata: any[] = [];

  // PopUp Add Items
  poitempopuptableconfig: any = PO_POPUP_ITEMS_TABLE_JSON.tableconfig;
  poitempopuptableheader: any = PO_POPUP_ITEMS_TABLE_JSON.tableheaders;
  poitempopuptabledata: any[] = [];

  itemmasterpopuptableconfig: any = POPUP_ITEMS_ROUTINE_TABLE_JSON.tableconfig;
  itemmasterpopuptableheader: any = POPUP_ITEMS_ROUTINE_TABLE_JSON.tableheaders;
  itemmasterpopuptabledata: any[] = [];
  constructor(private submit: SubmitFormService, public commonService: CommonServicesService, private apiData: ApiService, private configurationsetting: ConfigurationSetting, public systemParamsService: SystemParamsService, private helper: HelperService, private datepipe: DatePipe, private toastService: ToastService
  ) { }

  ngOnInit() {
    this.getCustomerName();
    this.getTaxSelection();
    this.getSearchAllPO();
  }

  createNewPo() {
    this.emptySearchField = false;
    this.label = 'Add PO Master';
    this.newBtnLabel = "Add PO"
    this.isclosed = "OPEN"
    this.deleteMsg = "";
    this.createNewBtn = true;
    this.deleteBtnDisable = false;
    this.saveBtn = true;
    this.saveBtnVisible = true;
    this.editBtn = false;
    this.cancelBtn = true;
    this.btnRow = true;
    this.addItemButton = true;
    this.podetailstabledata = [];
    this.tabledataforprocess = [];
    this.isPoPopUpOpen = true;
    this.commonService.clearForm(this.model);
    this.commonService.enableFormFields(this.model);

    this.todaysDate = new Date();
    this.model.PoDate.value = this.todaysDate;
    this.model.isBlanketPo.value = false;
    this.poMaxDate = this.todayDate;
    this.isRowEditableOrNot(true);
    this.model.remark.value = " "
    const ele = document.getElementById(`tr${"potable1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';

    this.podetailstableconfig['actioncolmconfig']['displaycolumn'] = true;
    delete this.backupData.bindDataToFormAndTable;
    setTimeout(() => {
      this.emptySearchField = true;
    }, 30);
    this.podetailstableheaders.forEach((ele) => {
      if (ele?.key === 'qtybilled' || ele?.key === 'Qty') {
        ele.visiblecolm = true;
        this.model.basicamount.isRequired = true;
        this.showBasicAmt = true;
      }
    })
  }

  savePoMasterForm() {
    let data = this.submit.submitForm(this.model);

    if (data) {
      this.saveBtn = true;
      let editedRow = [];
      let arr = [3, 2, 4];
      for (let i = 0; i < this.tabledataforprocess.length; i++) {
        if (!this.model.isBlanketPo.value && this.tabledataforprocess[i]['Qty'] <= 0) {
          this.toastService.showToastMsg('warning', 'Warning', "Please enter quantity.");
          return;
        }
        // if (!this.model.isBlanketPo.value && this.tabledataforprocess[i]['qtybilled'] <= 0) {
        //   this.toastService.showToastMsg('warning', 'Warning', "Please enter Qty billed.");
        //   return;
        // }
        // this.tabledataforprocess[i]['SrNo'] = arr[i];
        this.tabledataforprocess[i]['NeedByDate'] = this.datepipe.transform(this.tabledataforprocess[i]['NeedByDate'], this.systemParamsService.ngDateFormatyMMDD);
        delete this.tabledataforprocess[i]['qty'];
        delete this.tabledataforprocess[i]['NeedByDate_min']
        delete this.tabledataforprocess[i]['NeedByDate_max']
        if (this.tabledataforprocess[i]?.rowmodifiedstatus) {
          delete this.tabledataforprocess[i]['rowmodifiedstatus'];
          editedRow.push(this.tabledataforprocess[i]);
        }
      }

      if (this.deletedArray.length) {
        data["criteria"]["delLineItems"] = this.deletedArray
      }
      this.deletedArray = [];

      this.commonService.deleteExtraFlagsFromTable(this.tabledataforprocess);

      data["criteria"]["ispoforreject"] = data["criteria"]["ispoforreject"] ? "Y" : "N";
      data["criteria"]["isBlanketPo"] = data["criteria"]["isBlanketPo"] ? 1 : 0;

      if (data["criteria"]["ispoforreject"] == "Y" && data["criteria"]["isBlanketPo"] == 0) {
        data["criteria"]["isBlanketPo"] = 2;
      }

      let endpoint = 'transaction/insertPo';
      if (data["criteria"]['PrNo']) {
        endpoint = 'transaction/updatePo';
        this.ErrorDescription = "Data Updated Successfully";
        data["criteria"]["poitems"] = editedRow;
      } else {
        data["criteria"]["poitems"] = this.tabledataforprocess;
        this.ErrorDescription = "Data Inserted Successfully";
      }

      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.pouniqueno = res['Data']
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.commonService.diasbleFormFields(this.model)
          this.newBtnLabel = "Add New PO";
          this.addItemButton = false;
          this.cancelBtn = false;
          this.createNewBtn = false;
          this.editBtn = true;
          this.operation = '';
          this.podetailstableconfig['actioncolmconfig']['displaycolumn'] = false;
          this.isRowEditableOrNot(false);
          this.deleteBtnDisable = false;
          this.getSearchAllPO();
          // this.ErrorDescription = res['errorDescription']
          // this.toastService.showToastMsg('success', 'Success', this.ErrorDescription);

          this.PopupMessageModal.show();
        } else {
          this.saveBtn = false;
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  deletePOMasterForm() {
    let data = this.submit.submitForm(this.model);

    if (data && data["criteria"]["PrNo"]) {
      data["criteria"]["poitems"] = this.tabledataforprocess;
      let items: any = {};
      items.PrNo = data["criteria"]["PrNo"];
      items.CustomerNo = data["criteria"]["CustomerNo"];

      items.delLineItems = [];
      data["criteria"]["poitems"].forEach((ele) => {
        if (ele?.SrNo) {
          items.delLineItems.push(ele?.SrNo);
        }
      })
      data["criteria"] = items;
      let endpoint = 'transaction/deletePo';
      this.commonService.postMethod(endpoint, data, true).subscribe((res) => {
        if (res['errorCode'] == "000000") {
          this.cancelPopup('closePopupDelete')
          this.saveBtn = true;
          this.saveBtnVisible = false;
          this.deleteBtnDisable = false;
          this.clearAll(false);
          this.getSearchAllPO();
          this.ErrorDescription = "Record Deleted Successfully";
          this.operation = '';
          this.PopupMessageModal.show();
        } else {
          this.backendErrorMessageModal.show();
        }
      }, (err) => { });
    }
  }

  ifBlanketPoHideQtyBilled(val) {
    if (val === undefined || val === false) {
      this.model.ispoforreject.disable = true;
    } else {
      this.model.ispoforreject.disable = false;
    }
    this.podetailstableheaders.forEach((ele) => {
      if (ele?.key === 'qtybilled' || ele?.key === 'Qty') {
        if (val === undefined || val === false) {
          ele.visiblecolm = false;
          this.model.basicamount.isRequired = false;
          this.showBasicAmt = false;
        } else {
          ele.visiblecolm = true;
          this.model.basicamount.isRequired = true;
          this.showBasicAmt = true;
        }
      }
    })
  }

  itemForRejectedPo(val) {
    if (val === undefined || val === false) {
      this.model.isBlanketPo.disable = true;
      this.itemFor = "R";
    } else {
      this.itemFor = "N";
      this.model.isBlanketPo.disable = false;
    }
    console.log(this.itemFor);

  }

  bindDataToFormAndTable(modal, flag: boolean = true) {
    if (this.model.CustomerNo.disable == false && flag) {
      //      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      this.toastService.showToastMsg('warning', 'Alert', "Unable to edit");
      return;
    }

    this.backupData.bindDataToFormAndTable = this.helper.removeRefrence(modal);
    this.refreshDetailTable = false;
    if (modal["PoClosed"] === 'CLOSE') {
      this.isclosed = 'CLOSE';
      this.btnRow = false;
      this.toastService.showToastMsg('error', 'Alert', `PO record is closed.`);
    } else {
      this.isclosed = 'OPEN';
      this.btnRow = true;
    }
    setTimeout(() => {
      if (modal['ispoforreject'] == "Y") {
        this.model.ispoforreject.value = true;
        this.itemFor = 'R';
      } else {
        this.model.ispoforreject.value = false;
        this.itemFor = 'N';
      }
    }, 200);

    this.potype = modal['isBlanketPo'];

    this.isRejectedPo = modal['ispoforreject'];
    this.podetailstableheaders.forEach((ele) => {
      if (ele?.key === 'qtybilled' || ele?.key === 'Qty') {
        if (modal['isBlanketPo'] == 1) {
          ele.visiblecolm = false;
          this.model.basicamount.isRequired = false;
          this.showBasicAmt = false;
        } else {
          ele.visiblecolm = true;
          this.model.basicamount.isRequired = true;
          this.showBasicAmt = true;
        }
      }
    })

    this.podetailstableconfig['actioncolmconfig']['displaycolumn'] = false;
    this.model = this.commonService.setFromKeysValues(this.model, modal);
    this.isRowEditableOrNot(false);
    this.podetailstabledata = modal['poitems']?.length ? modal['poitems'] : [];

    let newArr = this.podetailstabledata.filter((item) => {
      return item.ddisDeleted !== 1;
    })
    this.podetailstabledata = newArr;
    setTimeout(() => {
      this.refreshDetailTable = true;
    }, 10);
    this.editBtn = true;
    this.label = "Display PO Master";
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.cancelBtn = false;
    this.editDisableBtn = false;
    this.deleteBtnDisable = false;
    this.addItemButton = false;
    this.createNewBtn = false;
    this.commonService.diasbleFormFields(this.model);
    this.isRowClicked = false;
    if (modal['isBlanketPo'] == 2) {
      this.model.isBlanketPo.value = false;
    }
  }

  selectPoItemsList(itemdata: any) {
    this.saveBtn = false;
    this.saveBtnVisible = true;
    let selectedItemNo = [];

    let deppCopyPoDetailTable = this.helper.removeRefrence(this.tabledataforprocess);
    itemdata = this.helper.removeRefrence(itemdata);

    itemdata.forEach((item) => {
      const { ItemSrNo, Description, PartNo, Rate, Qty, qtybilled } = item;
      deppCopyPoDetailTable.push({ ItemSrNo, Description, PartNo, Rate, Qty: 0, qtybilled: 0 });
      selectedItemNo.push(ItemSrNo)
    })

    this.podetailstabledata = deppCopyPoDetailTable;
    this.cancelPopup('closepopupforpomaster');

    if (!this.model.isBlanketPo.value && !this.model.ispoforreject.value) {
      this.getRoutineDcListFromItemMstrCall(selectedItemNo)
    }
  }

  getRoutineDcListFromItemMstrCall(no) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getCountOfRoutineDcFromItemMaster";
    let params = { subAssemblySrNo: no };
    console.log(params);

    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        this.isPoPopUpOpen = true;
        const item = res['Data']
        this.itemmasterpopuptabledata = [];
        this.itemmasterpopuptabledata = item;
        if (item.length) {
          this.ItemRoutineDcItemsPopup.show();
        }

      }
    }, (error) => {
      console.log(error);
    });
  }

  selectItemsForRoutineDcList(data) {
    console.log(data);

    let markedAsRoutineDc = []
    data.forEach((item) => {
      markedAsRoutineDc.push(item['srno'])
    })
    let endpoint = 'transaction/updateRoutineDcBySubAssemblyNo'
    let pdata = {}
    // pdata['criteria']['subAssemblySrNo'] = markedAsRoutineDc
    let param = { subAssemblySrNo: markedAsRoutineDc }
    console.log(pdata);

    this.commonService.postMethod(endpoint, { "criteria": param }, true).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        this.ItemRoutineDcItemsPopup.hide()

        this.ErrorDescription = 'Data Updated Successfully'
        this.PopupMessageModal.show();
      } else {
        this.errorMessageModal.show();
      }
    })
  }

  sendSrNoWhileSelect(data: any) {
    console.log();

    let selectedNoArray = []
    data.forEach((item) => {
      selectedNoArray.push(item['ItemSrNo'])
    })
    console.log(selectedNoArray);

    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/changeSmSrNoToImSrNo";
    let params = { ItemSrNo: selectedNoArray };
    console.log(params);

    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        // console.log("success");
      }
    }, (error) => {
      console.log(error);
    });
  }

  rowUpdate(data) {
    // console.log("row", data);
    data.row.rowmodifiedstatus = 'M';
    if (data['keyName'] == 'Qty' && data?.row?.Qty < 0) {
      this.ErrorDescription = "Enter valid Quantity ";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.Qty = 0;
      this.errorMessageModal.show();
    }
    if (data['keyName'] == 'qtybilled' && data?.row?.Qty < data?.row?.qtybilled) {
      this.ErrorDescription = "Qty billed should be less than Quantity.";
      this.ErrorHeader = "Quantity must be less than " + data?.row?.qtyinhand;
      this.ErrorObj = data.row;
      data.row.qtybilled = 0;
      this.errorMessageModal.show();
    }
  }

  updatedtabledata(data: any) {
    this.tabledataforprocess = [];
    this.tabledataforprocess = data;

    let total = 0;
    this.basicAmtSum = 0;
    for (let item of data) {
      if (this.operation == 'edit') {
        item['rowmodifiedstatus'] = 'M';
      }
      total = item["Qty"] * item["Rate"];
      this.basicAmtSum += total;
      if (!item['NeedByDate']) {
        item['NeedByDate'] = null;
      }
    }
    setTimeout(() => {
      this.model.basicamount.value = this.basicAmtSum.toFixed(2);
    }, 100);
    console.log("tabledataforprocess", this.tabledataforprocess);
  }

  getSearchAllPO() {
    const endpoint = 'transaction/getAllPo';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        this.pomastertabledata = []
        this.pomastertabledata = items;

        for (let i = 0; i < items.length; i++) {
          if (items[i]['PrNo'] == this.pouniqueno || items[i]['PrNo'] == this.model.PrNo.value) {
            this.model.PrNo.value = items[i]['PrNo'];
            this.getByIdClickCall(this.model.PrNo.value);
            // console.log("Index", this.tabledata[i], i);
            setTimeout(() => {
              document.getElementById('trpotable1' + i).style.backgroundColor = "#7de4f0";
              $(`tr[tabindex=${i}]`).focus();
            }, 900);
            break;
          }
        }
        this.isPoPopUpOpen = true;
      }
    }, err => { })
  }

  getCustomerName() {
    let endpoint = 'transaction/getCustomerName';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.customerNameArray = [];
        this.customerNameArray = successResp['Data'];
      }
    }, err => { })
  }

  getTaxSelection() {
    let endpoint = 'transaction/gettax';
    this.commonService.getWithoutParams(endpoint).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        this.taxArray = [];
        this.taxArray = successResp['Data'];
      }
    }, err => { })
  }

  fixTaxRate(val: any): void {
    // console.log(val);
    for (let items of this.taxArray) {
      if (val == items.tax) {
        this.model.taxRate.value = items['Rate'];
        this.model.taxRate.err = "";
      }
    }
  }

  PoDateChange(date) {
    this.poMinDate = date;
  }

  recivedOnDate(date) {
    this.poMaxDate = date;
  }

  showPopupToAddItem() {
    this.showPopupToAddItemForPoMaster.show();
  }

  // Cancel all Popup
  cancelPopup(form) {
    this.poitempopuptabledata = [];
    this.isPoPopUpOpen = false;

    switch (form) {
      case 'closepopupforpomaster':
        this.showPopupToAddItemForPoMaster.hide();
        break;
      case "closePopupDelete":
        this.showDeleteModal.hide();
        break;
      case "itemalert":
        this.showItemAlert.hide();
        break;
      case 'popupmsg':
        this.PopupMessageModal.hide();
      case 'itemroutinedc':
        this.ItemRoutineDcItemsPopup.hide();
    }
  }

  editForm() {
    this.operation = 'edit';
    this.podetailstableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.isRowEditableOrNot(true);
    this.label = "Edit PO Master";
    this.editDisableBtn = true;
    this.cancelBtn = true;
    this.deleteBtnDisable = true;
    this.saveBtn = false;
    this.saveBtnVisible = true;
    this.addItemButton = true;
    this.createNewBtn = true;
    this.commonService.enableFormFields(this.model);
    this.editBtn = false;
    this.checkBoxHandlePO(this.potype);
    this.checkBoxHandleRJ(this.isRejectedPo);
  }

  checkBoxHandlePO(val) {
    if (val == 1) {
      this.model.ispoforreject.disable = true;
    } else {
      this.model.ispoforreject.disable = false;
    }
  }

  checkBoxHandleRJ(val) {
    if (val == "Y") {
      this.model.isBlanketPo.disable = true;
    } else {
      this.model.isBlanketPo.disable = false;
    }
  }


  clearAll(flag: boolean = true) {
    this.commonService.clearForm(this.model);
    this.podetailstabledata = [];
    this.label = 'PO Master';
    this.newBtnLabel = "Add New PO";
    this.addItemButton = false;
    this.cancelBtn = false;
    this.deleteMsg = "";
    this.createNewBtn = false;
    this.saveBtn = true;
    this.saveBtnVisible = false;
    this.btnRow = false;
    this.commonService.diasbleFormFields(this.model);
    this.podetailstableconfig['actioncolmconfig']['displaycolumn'] = true;
    this.operation = '';

    const ele = document.getElementById(`tr${"potable1" + this.currentSelectedRow}`);
    if (ele) ele.style.backgroundColor = '';

    if (this.editDisableBtn && this.backupData['bindDataToFormAndTable'] && flag) {
      this.bindDataToFormAndTable(this.backupData.bindDataToFormAndTable, false);
      this.editDisableBtn = false;
    } else {
      this.editBtn = false;
    }
  }

  clearForm() {
    if (confirm("Do you want to cancel!")) {
      if (this.editBtn && !this.editDisableBtn) {
        this.clearAll(false);
      } else {
        this.clearAll(true);
      }
    }
  }

  getById(modal, flag: boolean = true) {
    if (this.isRowClicked) {
      setTimeout(() => {
        this.isRowClicked = false;
      }, 2000);
      return;
    }
    this.isRowClicked = true;
    if (this.model.PoNo.disable == false && flag) {
      this.toastService.showToastMsg('warning', 'Warning', "You are in edit mode can not select records.");
      return;
    }

    setTimeout(() => {
      this.currentSelectedRow = modal.rowindex;
      this.commonService.identifySelectedRow(this.pomastertabledata, modal.rowindex, this.currentSelectedRow, "potable1");
    }, 90);
    let row = modal['row'];

    let PrNo = row['PrNo'];
    this.getByIdClickCall(PrNo);
  }

  getByIdClickCall(no: any) {
    let endpoint = this.configurationsetting.getBaseUrl() + "transaction/getPoById";
    let params = { PrNo: no };
    this.commonService.getWithParams(endpoint, params).subscribe((res) => {
      if (res['errorCode'] == "000000") {
        const item = res['Data']

        this.bindDataToFormAndTable(item);
      }
    }, (error) => {
      console.log(error);
    });
  }

  getItemListForPopup() {
    let param = { itemfor: this.itemFor }
    const endpoint = this.configurationsetting.getBaseUrl() + 'transaction/getPoItemList';
    this.commonService.getWithParams(endpoint, param).subscribe(successResp => {
      if (successResp['errorCode'] == "000000") {
        const items = successResp['Data']['tabledata'];
        items.forEach(element => {
          element.qty = '';
        });
        this.poitempopuptabledata = []
        this.poitempopuptabledata = items;
        this.deepCopyForDetailTable = this.helper.removeRefrence(items);
        this.isPoPopUpOpen = true;
        this.showPopupToAddItemForPoMaster.show();
        this.filterSpareListTableIfExistingSubTable();
      }
    }, err => { })
  }


  filterSpareListTableIfExistingSubTable() {
    let preparedarr = [];
    preparedarr = this.deepCopyForDetailTable.filter(row => {
      let recavailable: boolean = true;
      for (let ind = 0; ind < this.podetailstabledata.length; ind++) {
        if (this.podetailstabledata[ind]['PartNo'] == row['PartNo']) {
          recavailable = false;
        }
      }
      return recavailable;
    });
    this.poitempopuptabledata = []
    this.poitempopuptabledata = preparedarr;
  }

  addItemBtn() {
    this.getItemListForPopup()
  }

  deleteRow(event) {
    console.log(this.tabledataforprocess);

    this.tabledataforprocess.splice(event.rowindex, 1);
    console.log('tabledataforprocess', this.tabledataforprocess.length);
    if (!this.tabledataforprocess.length) {
      this.showItemAlert.show();
      this.saveBtn = true;
      this.saveBtnVisible = false;
    } else {
      this.saveBtn = false;
      this.saveBtnVisible = true;
    }
    this.pushInDeletedArray(event.row, 'SrNo');
  }

  pushInDeletedArray(row, key) {
    this.deletedArray.push(row[key])
  }

  closeErrorPopup() {
    this.errorMessageModal.hide();
    this.backendErrorMessageModal.hide();
    this.PopupMessageModal.hide();
  }

  showDeleteConfirmationYes() {
    this.showDeleteModal.show()
  }

  restrictChar(val: any) {
    var inputBox = document.getElementById("PoNo");
    var invalidChars = this.commonService.charArray;
    if (invalidChars.includes(val)) {
      this.poValidMsg = true
    } else {
      this.poValidMsg = false
    }
    inputBox.addEventListener("keydown", function (e) {
      let arr = 'lp';
      if (invalidChars.includes(e.key)) {
        console.log("Allowed characters are a-z A-Z 0-9 - . Space");
        arr = "kk"
        e.preventDefault();
      }
    });

  }

  isRowEditableOrNot(flag: boolean) {
    this.podetailstableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.podetailstableheaders), "Qty", flag);
    this.podetailstableheaders = this.commonService.enableEditForGt(this.helper.removeRefrence(this.podetailstableheaders), "NeedByDate", flag);
  }

}
