import { Component, OnInit, Input, OnChanges, Output, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef, ViewEncapsulation, OnDestroy, ElementRef } from '@angular/core';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';

import { DatePipe } from '@angular/common';
import { GtPlusDataService } from './gt-plus-data.service';
import { HighlightPipe } from './highlight.pipe';
import { CustomEvents } from './events';
import { HttpClient } from '@angular/common/http';
import { PdfService } from './pdf.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, } from 'rxjs/operators';
import { SystemParamsService } from '../../services/system-params.service';

declare var $: any;
declare var _: any;
//_.cloneDeep(this.chartData);

@Component({
  selector: 'gt-plus',
  templateUrl: './gt-plus.component.html',
  styleUrls: ['./gt-plus.component.scss'],
  providers: [GtPlusDataService, HighlightPipe]
  // encapsulation:ViewEncapsulation.Native
})

export class GtPlusComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {


  @ViewChild("mf", { static: false }) tableRefvar;
  @ViewChild("textrefvar", { static: false }) textrefvar;
  @ViewChild("datepicker", { static: false }) datepicker: any;
  @ViewChild("ddlbtn", { static: false }) ddlbtn;
  ///////ifthere is any operation on the table then actioncolumn should be true
  @Input("serveripaddress") serveripaddress: string = "";

  @Input("tableid") tableid: string = "tbl1";
  @Input("showactioncolumn") showactioncolumn: boolean = false;
  ////no pagination flag//////////////////
  @Input("ispagination") ispagination: boolean = false;
  /////is editable
  @Input("iseditable") iseditable: boolean = false;
  /////is delete
  @Input("isdatachange") isdatachange: boolean = false;

  @Input("isdelete") isdelete: boolean = false;
  //////display View Icon

  @Input("isprintable") isprintable: boolean = false;
  //////display print Icon

  @Input("displayview") displayview: boolean = false;

  /////table JSON- This is our actual table data OR array
  @Input("tabledata") tabledata: any[] = [];
  /////Header of the table with the filter key ,Datatype, Sortable and LinkField ifthe Datatype is Date
  @Input("tableheaders") tableheaders: any[] = [];
  ////////////table Header Backup/////////////
  tableheaderbackup: any[] = [];
  //////////////Table Level Configuration/////////////
  @Input("tableconfig") tableconfig = {};
  /////filter query is nothing but the a string OR input value entered by the User in the Search Text Box
  ///////////////////////////Search on Placeholder/////////
  @Input("placeholder") placeholder: string = "Search";
  ///////////////search title //////////////////
  @Input("searchtitle") searchtitle: string = "Search";
  //////////////////////?Date Format////////////
  @Input("dateformat") dateformat: string = 'dd-MMM-yyyy';
  /////input array of Paginator i. footer array of table
  @Input("paginatorarray") paginatorarray: number[] = [10, 25, 50, 100, 150, 200];
  ///////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////Output Emitter////////////////////////////////////////
  /****************************Below properties are defining to add two way binding feature*************************/
  @Input() ngmodel: any[] = [];/// Binf this property
  @Output() ngmodelChange = new EventEmitter();

  /**************************************End two way binding**********************************************/
  // @Input("updatedtabledata") updatedtabledata: any[] = [];
  @Output("dropdownchange") dropdownchange = new EventEmitter();
  @Output("contextEvents") contextEvents = new EventEmitter();
  @Output("uploadEvent") uploadEvent = new EventEmitter();
  @Output("updatedtabledata") updatedtabledata = new EventEmitter();
  @Output("printEvent") printEvent = new EventEmitter();
  @Output("viewEvent") viewEvent = new EventEmitter();
  @Output("editEvent") editEvent = new EventEmitter();
  @Output("deleteEvent") deleteEvent = new EventEmitter();
  @Output("doubleClick") doubleClick = new EventEmitter();
  @Output("singleClick") singleClick = new EventEmitter();
  @Output("rowChangeOrUpdate") rowChangeOrUpdate = new EventEmitter();
  @Output("commitData") commitData = new EventEmitter();
  @Output("checkedRecords") checkedRecords = new EventEmitter();
  @Output("changeRadio") changeRadio = new EventEmitter();
  @Output() customizedSecondBtnEv = new EventEmitter();
  @Output() customizedFirstBtnEv = new EventEmitter();
  @Output() customizedThirdBtnEv = new EventEmitter();
  @Output() customizedFourthBtnEv = new EventEmitter();
  @Output() customizedFifthBtnEv = new EventEmitter();
  @Output() changeSearchPannel = new EventEmitter();
  @Output() buttonColumnEvt = new EventEmitter();// Additional Button Datatype
  @Output() customActionButtonEvent = new EventEmitter();// Additional Button Datatype

  /****below event takes to call change method on checkbox starts here****/
  @Output() checkedSingleRecord = new EventEmitter();
  /****below event takes to call change method on checkbox ends here****/

  /**
   * calculationtype:"entirerow"/"forwardonly";
   **/
  /////////////Show loader Flag
  showloader: boolean = false;
  /////////////Show loader Flag
  isViewInit: boolean = false;
  //////Hide Column Action Flag
  hidecolumnaction: boolean = false;
  //////User can set the rows perpage
  @Input("rowsperpage") rowsperpage: number = 50;
  ////Ascending OR Descending
  ascending: boolean;
  ////////////////Backup Table Array///////////////////
  tabledatabackup: any[] = [];
  ///////////Case Sensitive Flag///////////////
  casesensitive: boolean = false;
  searched: boolean = false;
  displayhiddencolspopup: boolean = false;
  setCellColourFlagPopup: boolean = false;
  setCellColourFlagObj: any = {};
  highlightsCellsArray: any = [];
  checkedrecordscount: number = 0;
  searchpannel: boolean = false;
  searchtext: string = "";
  hiddencolumns: any[] = [];
  selectedcolumn: any = "";
  @ViewChild('headerMenu', { static: false }) public headerMenu: ContextMenuComponent;


  /**********************
   * Following are the actions available on the cell context menu
   *
   * 1.Filter(filter on selected cell data/string/number)
   * 2.View(label will be like "More Details" or "Show Details" etc. )
   *
   **********************/
  cellcontextmenuName = 'cellcontextmenu';
  @ViewChild('cellcontextmenu', { static: false }) public cellcontextmenu: ContextMenuComponent;
  columnsearchtext: string = "";
  columnsearchobj = {
    "searchingindex": -1,
    "header": {}
  };
  colspancount: number = 0;
  showcheckedrecords: boolean = false;
  disabledmainsearch: boolean = false;
  hidecellcontextmenu: boolean = false;
  displaycsvmodal: boolean = false;
  iscolmeval: boolean = false;// This is forone time column data evaluation or set data format.
  displayinstructionmodal: boolean = false;
  selectedcellmenu = {};
  currentSelectedRowIndex: number = -1;
  appliedFilters: any[] = [];/// This array will store the applied filter columns. I am using this in addOrRemoveFilter method to apply filter.
  applfiltcolmsobj = {};/// This variable will store the applied filter columns with flag to hide and show the filterOn and exclude in the context menu.
  /*****************This table json object contains three keys
   * 1.headers- Table Headers of type array[]
   * 2.tabledata - Table Data/Rows of type array[]
   * 3.tableconfig- Table Configuration of type object{}
   *  **********************/
  // @Input("tablejson") tablejson = {
  //   "tableheaders": [],
  //   "tabledata": [],
  //   "tableconfig": {}
  // };

  /*************
   * These are the
   *************/
  cellstyle = {
    "tdkeyname": ""
  };
  cellelement: any = {};
  tablestyle = {};
  cellvalue: any = "";
  /********************************************* */
  controlsenabled: boolean = false;
  searchtimer = null;
  consolidatedetails = {};
  filterCheckObject:any = {
    rowPtIndex:{}
  };
  /***************************End table json**********************/

  valuebeforecommit: any = "";/// this object will store the cell data when user clicked on it and use it to get the diff between previous cell data and new cell data.

  /******************************************Table resize variables***********************/
  table_instanse;
  cellcontextobj = {};
  filteroncell: boolean = false;
  cellmenulist: any[] = [];// This variable store the context menu list of cell. Menulist will be a part of the table setting/configuration.
  showcommoncontxmenus: boolean = false;
  topheaders: any[] = [];/// Store the prepared top headers from the header definition.
  formulacolms: any[] = [];/// Store the columns with the formula.
  formulainterval: any = 0;
  stickyfooterinterval: any = 0;
  /***************************************
   *
   * Display Message boxes
   * 1.Error message
   * 2.success message
   * 3.COnfirmation message[Do not askme again.]
   *
   */
  //Confirmation message box
  tblsessiondata = {
    "allowedallcolms": false,/// Allowed all columns forupdation
    "allowedcolms": []/// Allowed columns forupdation
  };
  confMsgBox = {
    "show": false,
    "msg": "",
    "yestbtntxt": "Yes",
    "nobtntxt": "No",
    "selectedVal": "",
    "tempevtdata": {}
  };
  succOrErrMsgBox = {
    "iserror": false,
    "title": "",
    "message": "",
    "okbtntxt": "Ok",
    // "cancelbtntxt": "Cancel"
  };
  // errMsgBox = {
  //   "show": false,
  //   "message": "",
  //   "okbtntxt": "Ok",
  //   "cancelbtntxt": "Cancel"
  // };
  toastMsg = {
    "show": false,
    "issuccess": false,
    "msg": ""
  };
  TempSearchArr: any[] = [];/// iffilter us applied and user try to search any text. I will be the


  /***Paginator Variables */

  currentpageno: number = 1;
  presentpageno: number = 1;
  noofpages: number = 0;

  /**End Paginator */

  freezeColmsObj: any = {
    "isfrozen": false,
    "details": {}
  };// This object will store the sticky panes and sticky column details.
  freezePanesObj = {
    "isfrozen": ""
  }

  freezeHeaderMenu = [
    {
      "label": "Freeze Column(s)",
      "action": "freezecolumn"
    },
    {
      "label": "Unfreeze Column",
      "action": "unfreeze"
    }
  ];


  summaryHeaderMenu = [
    {
      "type": "sum",
      "label": "Show Sum",
      "action": "showsum",
      "visible": true
    },
    {
      "type": "sum",
      "label": "Hide Sum",
      "action": "hidesum",
      "visible": true
    },
    {
      "type": "average",
      "label": "Show average",
      "action": "showaverage",
      "visible": true
    },
    {
      "type": "average",
      "label": "Hide average",
      "action": "hideaverage",
      "visible": true
    },
    {
      "type": "min",
      "label": "Show min",
      "action": "showmin",
      "visible": true
    },
    {
      "type": "min",
      "label": "Hide min",
      "action": "hidemin",
      "visible": true
    },
    {
      "type": "max",
      "label": "Show max",
      "action": "showmax",
      "visible": true
    },
    {
      "type": "max",
      "label": "Hide max",
      "action": "hidemax",
      "visible": true
    }
  ];



  freezeColmMenus: any[] = [
    {
      "label": "Freeze Panes",
      "action": "freezepanes",
      "visible": true
    },
    {
      "label": "Unfreeze Panes",
      "action": "unfreezepanes",
      "visible": false
    }
  ];


  footerValObj = {
    "caltypes": [],
    "sum": [],
    "min": [],
    "max": [],
    "average": []
  };
  optionActionItems = [

  ];
  optionActionItemSelected = null;

  private updatedtabledataSubject = new Subject<any>();
  private searchSubject = new Subject<any>();
  private updatedtabledataSubjectUnsubscribe = null

  private rowChangeOrUpdateSubject = new Subject<any>();
  private rowChangeOrUpdateSubjectUnsubscribe = null
  private searchSubjectUnscribe = null;
  isCheckedRecordsCondition = false;
  displayDeleteConfpopup: boolean = false;
  displayQuantityConfpopup: boolean = false;
  displayDetailsPopup: boolean = false;
  displayDetailsPopupObj: any = {};
  deleteObj = {};
  // isDataUpdating: boolean = false;/// Flag is use to prevent the cell data change or keydown event execution.

  /*******************************************End table resize varibale*******************************/
  constructor(public gtPlusDataSrvc: GtPlusDataService, private contextMenuService: ContextMenuService, private changeDetectRef: ChangeDetectorRef,
    public datepipe: DatePipe, private customSrvc: CustomEvents, private httpClient: HttpClient,
    public pdfService: PdfService, private SystemParamsService:SystemParamsService) {
  }



  buttonColumnClick(item, header, rowindex, colindex) {

    this.buttonColumnEvt.emit({
      "row": item,
      "header": header,
      "rowindex": rowindex,
      "colindex": colindex
    });

  }



  /***Calculate Number of pages */

  calNoOfPages() {
   
    
    if (this.isConsolidateMode()) {
      // this.showToastMsg("Your can not change the records per page after aggregation", false);
      this.currentpageno = 1;
      this.presentpageno = 1;
      return
    }

    this.noofpages = Math.floor(this.tabledata.length / this.rowsperpage);
    if (this.tabledata.length % this.rowsperpage) {
      this.noofpages += 1;
    }

    if (this.tableRefvar) {
      setTimeout(() => {
        this.currentpageno = this.tableRefvar['activePage'];
        this.presentpageno = this.currentpageno;
      }, 10);
    }
   
  }


  isConsolidateMode() {
    return Object.keys(this.consolidatedetails).length && this.consolidatedetails['consolidation'];
  }


  gotoFirstPage() {
    if (this.isConsolidateMode()) {
      this.showToastMsg("You are already on the first page.", false);
      this.currentpageno = 1;
      this.presentpageno = 1;
      return
    }
    this.currentpageno = 1;
    this.presentpageno = this.currentpageno;
    this.tableRefvar['setPage'](this.currentpageno, this.rowsperpage);

    this.updateFreezeCSS(10);
  }

  gotoPreviousPage() {
    if (this.isConsolidateMode()) {
      this.showToastMsg("You can not go to the previous page after aggregation", false);
      this.currentpageno = 1;
      this.presentpageno = 1;
      return
    }
    this.currentpageno -= 1;
    if (this.currentpageno <= 0) {
      this.currentpageno = 1;
    }
    this.presentpageno = this.currentpageno;
    this.tableRefvar['setPage'](this.currentpageno, this.rowsperpage);
    this.updateFreezeCSS(10);
  }

  gotoPage(evt, fromGoBtn: boolean = false) {

    if (this.isConsolidateMode()) {
      this.showToastMsg("Your can not go to the another page after aggregation", false);
      this.currentpageno = 1;
      this.presentpageno = 1;
      return
    }

    if (evt['keyCode'] == 13 || fromGoBtn) {
      if (this.currentpageno > this.noofpages) {
        this.currentpageno = this.noofpages;
      }
      else if (this.currentpageno < 0) {
        this.currentpageno = 1;
      }
      else if (!this.currentpageno) {
        this.currentpageno = 1;
      }
      this.presentpageno = this.currentpageno;
      this.tableRefvar['setPage'](this.currentpageno, this.rowsperpage);

      this.updateFreezeCSS(10);
    }
  }

  gotoNextPage() {

    if (this.isConsolidateMode()) {
      this.showToastMsg("Your can not go to the next page after aggregation", false);
      this.currentpageno = 1;
      this.presentpageno = 1;
      return
    }

    this.currentpageno += 1;
    if (this.currentpageno >= this.noofpages) {
      this.currentpageno = this.noofpages;
    }
    this.presentpageno = this.currentpageno;
    this.tableRefvar['setPage'](this.currentpageno, this.rowsperpage);

    this.updateFreezeCSS(10);

    this.updatedtabledata.emit(this.tabledata);
    if (this.tableconfig['checkDeletedFlag']) {
    //  console.log("click next");
      // this.findIsDeletedAndHighlight();
    }
  }

  gotoLastPage() {
    if (this.isConsolidateMode()) {
      this.showToastMsg("Your can not go to the last page after aggregation", false);
      this.currentpageno = 1;
      this.presentpageno = 1;
      return
    }

    this.currentpageno = this.noofpages;
    this.presentpageno = this.currentpageno;
    this.tableRefvar['setPage'](this.currentpageno, this.rowsperpage);

    this.updateFreezeCSS(10);
  }

  /***End cal no of pages */




  /*********************
   * JSON difference
   *
   */

  showSuccOrErrMsg(title, msg, iserror, okbtntext = "Ok") {
    this.succOrErrMsgBox = {
      "iserror": iserror,
      "title": title,
      "message": msg,
      "okbtntxt": okbtntext
    };
  }

  messageOkBtn() {
    this.succOrErrMsgBox.message = "";
  }
  generateLognote() {

  }

  ngOnDestroy() {
    document.body.removeEventListener("click", () => {
    })
    this.updatedtabledataSubjectUnsubscribe?.unsubscribe();
    this.rowChangeOrUpdateSubjectUnsubscribe?.unsubscribe();
    this.searchSubjectUnscribe?.unsubscribe();
  }

  /*********************End Auto lognotes********************/

  /***************************dismiss CellFocus******************/

  dismissCellFocus() {
    if (this.valuebeforecommit == "" || (typeof this.valuebeforecommit == 'object' && !Object.keys(this.valuebeforecommit).length)) {
      return;
    }
    /********************Emitting updated value of column to update in the db*****************/
    // this.emitRowData(this.valuebeforecommit['rowindex'], this.valuebeforecommit['colindex'], this.valuebeforecommit['keyName']);
    /**************************************End data emitting**********************************/
    // console.log(this.valuebeforecommit, this.valuebeforecommit['actualindex'], this.valuebeforecommit["actioncolumn"]);
    if (this.tabledata[this.valuebeforecommit['actualindex']] && this.tabledata[this.valuebeforecommit['actualindex']][this.valuebeforecommit["actioncolumn"]])
      delete this.tabledata[this.valuebeforecommit['actualindex']][this.valuebeforecommit["actioncolumn"]];
    this.controlsenabled = false;
    this.cellstyle[this.cellstyle.tdkeyname] = {};
    this.cellstyle.tdkeyname = "";
    this.cellstyle = {
      tdkeyname: ""
    }
    // delete this.cellstyle.tdkeyname = "";
    this.valuebeforecommit = {};
  }
  /*************************End dismiss focus*******************/
  /// This function is responsible forrow and column resize as well as Column Drag and Drop ///
  setResizeAndDragDrop(permission: boolean = true) {

    if (this.tableconfig['resizable']['rows'] && permission) {
      $(".row-resizer-td").resizable({
        handles: 's',
        resizeHeight: true,
        resizeWidth: false,
        disabled: false
        // animate: true,
      });
      $(".row-resizer-td").resizable('enable');
    }
    if (this.tableconfig['resizable']['rows'] && !permission) {
      $(".row-resizer-td").resizable({ disabled: true });
    }
    if (this.tableconfig['resizable']['columns'] && permission) {
      $(".tbl-header").resizable({
        handles: 'e',
        // animate: true,
        resizeWidth: true,
        resizeHeight: false,
        disabled: false
      });
      $(".tbl-header").resizable('enable');
    }
    if (this.tableconfig['resizable']['columns'] && !permission) {
      $(".tbl-header").resizable({ disabled: true });
    }

    if (this.tableconfig['dragdrop']['columns'] && !this.topheaders.length) {
      var dragdroptbl = ("#" + this.tableid.toString());
      $(dragdroptbl).dragableColumns({
        drag: permission,
        dragClass: 'drag',
        overClass: 'over',
        movedContainerSelector: '.dnd-moved',
        onDragEnd: (evt) => {
          /***********************************************Update Column Reorder Code to solve the reorder issue */
          var newheaders: any[] = [];
          let objkeyslen = Object.keys(evt['object']).length;
  //  console.log("setResizeAndDragDrop normal for loop 1");

          for (let colmindx = 0; colmindx < objkeyslen; colmindx++) {
            const columnname = Object.keys(evt['object'])[colmindx];
            if (columnname == "Sticky-Column") {
              continue;
            }
            newheaders[evt['object'][columnname]] = columnname;
          }
          var finalheaders: any[] = [];
        //  console.log("setResizeAndDragDrop normal for loop 2");

          for (let hrdkey of newheaders) {
            // const element = array[index];
        //  console.log("setResizeAndDragDrop normal for loop 3");

            for (let index = 0; index < this.tableheaders.length; index++) {
              const element = this.tableheaders[index];
              if (hrdkey == element['key']) {
                // newheaders[index] = element;
                finalheaders.push(element);
                break;
              }
            }
          }
          this.tableheaders = [];
          this.tableheaders = finalheaders;
        }
      });
    }
  }
  /*********************************End resize****************************/
  /************************ following method will set the update permision as per the user's selection****************/
  setUpdationPerm(evt) {
    this.confMsgBox.selectedVal = evt['target']['value'];
  }
  /********************************************************END set update permission*************************/
  ngOnInit() {
    const randomNo: number = Math.random()

    // setTimeout(() => {
    //   this.addOrRemoveFilter();
    // }, 5000);

    // this.tblsessiondata.allowedcolms.push(this.confMsgBox['tempevtdata']['keyName']);
    sessionStorage.removeItem(this.tableid.toString());
    // let tblsession = sessionStorage.getItem(this.tableid.toString());
    // if(tblsession) {
    //   this.tblsessiondata = JSON.parse(tblsession);
    // }
    this.gtPlusDataSrvc.autoLogNotes[this.tableid] = {};
    /**************************Table data subscribe
     * Table id is must
     * ****************************/
    /*this.customSrvc.subscribe(this.tableid.toString(), (dataObj) => {
      if (!dataObj['tableid'] || (dataObj['tableid'] as string).trim().length == 0) {
        this.showSuccOrErrMsg("Invalid Table Properties", "Please provide table ID", true);
        return;
      }
      switch (dataObj['actiontype'].toUpperCase()) {

        case "FILTER":

          let spliceindx: number = -1;

          var appliedcolmobj = {};
          appliedcolmobj = dataObj['data'];
          this.removeFilter(dataObj);

          // for(let index = 0; index < this.appliedFilters.length; index++) {

          //   const applfilter = this.appliedFilters[index];

          //   if((applfilter['targetcolumn'] as string).toLowerCase() == (appliedcolmobj['targetcolumn'] as string).toLowerCase()) {
          //     spliceindx = index;
          //     this.applfiltcolmsobj[applfilter['targetcolumn']] = false;
          //     this.appliedFilters.splice(index, 1);
          //     break;
          //   }
          // }

          // if(!this.appliedFilters.length) {
          //   this.filteroncell = false;
          //   Object.keys(this.applfiltcolmsobj).forEach(key => {
          //     this.applfiltcolmsobj[key] = false;
          //   })
          // }

          // this.addOrRemoveFilter((dataObj['operation'] as string).toUpperCase());

          break;

        case "UPDATE":

          // var t0 = performance.now();

          if ((dataObj['operation'] == "edit" || dataObj['operation'] == "delete") && (dataObj['data'] && dataObj['data']['autoid'] == undefined) && dataObj['data']['autoid'] < 0) {
            // alert("Please provide AutoId to delete the record.");
            this.showSuccOrErrMsg("Invalid Row ID", "Please provide AutoId to delete the record.", true);
            return;
          }
          if (dataObj['operation'] == "edit") {
            var indxoftbldata = this.tabledata.findIndex(item => item['autoid'] == dataObj['data']['autoid']);
            var indxoftbldatabkp = this.tabledatabackup.findIndex(item => item['autoid'] == dataObj['data']['autoid']);
            Object.keys(dataObj['data'])
              .forEach(key => {
                if (key != 'autoid' && key != 'actualindex') {
                  if (indxoftbldata >= 0) {
                    this.tabledata[indxoftbldata][key] = dataObj['data'][key];
                  }
                  if (indxoftbldatabkp >= 0) {
                    this.tabledatabackup[indxoftbldatabkp][key] = dataObj['data'][key];
                  }
                }
              })
          }
          else if (dataObj['operation'] == "delete") {

            var indxoftbldata = this.tabledata.findIndex(item => item['autoid'] == dataObj['data']['autoid']);
            var indxoftbldatabkp = this.tabledatabackup.findIndex(item => item['autoid'] == dataObj['data']['autoid']);

            this.tabledata.splice(indxoftbldata, 1);
            this.tabledatabackup.splice(indxoftbldatabkp, 1);
          }
          else if (dataObj['operation'] == "add") {
            this.tabledata.push(dataObj['data']);
            if (JSON.stringify(this.tabledata[this.tabledata.length - 1]) != JSON.stringify(this.tabledatabackup[this.tabledatabackup.length - 1])) {
              this.tabledatabackup.push(dataObj['data']);
            }
          }

          if (dataObj['operation'] == "add" || dataObj['operation'] == "delete") {
            this.setActualIndex(this.tabledata);
            this.setActualIndex(this.tabledatabackup, true);
          }

          // this.updatedtabledata.emit(this.tabledata);
          this.updatedtabledataSubject.next(this.tabledata)
          this.calcFooterVals();
          break;

        case "HEADERS":
          if (dataObj['operation'] == "setvisibility") {
            for (let hrdindx = 0; hrdindx < this.tableheaders.length; hrdindx++) {
              for (let dtindx = 0; dtindx < dataObj['data'].length; dtindx++) {
                const element = dataObj['data'][dtindx];
                if (this.tableheaders[hrdindx]['key'] == element['key']) {
                  this.tableheaders[hrdindx]['visiblecolm'] = (!element['flag'] ? false : true);
                  break;
                }
              }
            }
            // this.verifyTblConfig();

          }
          else if (dataObj['operation'] == "add") {
            this.tableheaders.splice(dataObj['data']['index'], 0, dataObj['data']['headerObj']);
            this.tableheaderbackup.splice(dataObj['data']['index'], 0, dataObj['data']['headerObj']);
          }
          else if (dataObj['operation'] == "delete") {
            let hdrIndx = this.tableheaders.findIndex(item => (item['display'] == dataObj['data']['headerObj']['display'] && item['datatype'] == dataObj['data']['headerObj']['datatype']));
            let hdrIndxBkp = this.tableheaderbackup.findIndex(item => (item['display'] == dataObj['data']['headerObj']['display'] && item['datatype'] == dataObj['data']['headerObj']['datatype']));

            if (hdrIndx != -1) {
              this.tableheaders.splice(hdrIndx, 1);
            }
            if (hdrIndxBkp != -1) {
              this.tableheaderbackup.splice(hdrIndx, 1);
            }
          }

          this.setTopHeader();
          break;
      }
    })
    */
    /************************************End table data subscribe**********************/

    /**************************************************Adding click event listener to body element to detect the click target and hide/focusout from the cell**********/
    document.body.addEventListener("click", (evt) => {

      var classname: string = evt.target['className'];

      if (!classname.includes("gt-plus-identifier") && !classname.includes("highlight-text") && !classname.includes("autosetarrow") && Object.keys(this.valuebeforecommit).length) {

        this.checkDiffAndAutoUpdate().then((succ) => {
          this.dismissCellFocus();
          // this.updatedtabledata.emit(this.tabledata);
          this.updatedtabledataSubject.next(this.tabledata)
        },
          rej => {
            this.dismissCellFocus();
            // this.updatedtabledata.emit(this.tabledata);
            this.updatedtabledataSubject.next(this.tabledata)
          })


      }
      else {
        // this.checkDiffAndAutoUpdate();
      }
    });
    /****************************************End body "CLICK" event listener********************/

    /************************************Table resize code********************************/


    setTimeout(() => {
      this.table_instanse = document.getElementById(this.tableid);
      // if(this.tableconfig['resizable'] && this.tableconfig['resizable']['columns']) {
      this.setResizeAndDragDrop();

      this.tablestyle = {};

      var thwidth: string = this.tableheaders[0]['thstyle']['width'];

      if (thwidth.indexOf("px") != -1) {

        this.tablestyle['width'] = 0;
      //  console.log("OnInIt normal for loop 3");

        for (let index = 0; index < this.tableheaders.length; index++) {
          const header = this.tableheaders[index];
          let widthstr: string = header['thstyle']['width'];
          this.tablestyle['width'] += parseInt(widthstr.replace("px", ""));
        }
        this.tablestyle['width'] += "px";
      }
      else {
        if (this.table_instanse) {
          this.tablestyle['width'] = this.table_instanse.clientHeight + "px";
        }
      }
      // this.sampleResize();

    }, 1000);


    // if(this.tableconfig['resizable']['rows'] || (this.tableconfig['summary'] && this.tableconfig['summary'].length)) {
    //   this.tableconfig['tableclasses'] = !(this.tableconfig['tableclasses'] as string).includes("gt-resize") ? " gt-resize " : "";
    //   this.tableconfig['tableclasses'] = !(this.tableconfig['tableclasses'] as string).includes("sticky-excel-left-col") ? " sticky-excel-left-col " : "";
    // }

    /*********************************************End table resize code*************************/
    // setTimeout(() => {
    //   var tblcontrwidth = "tbl-cntr" + this.tableid.toString();
    //   var clientWidth = document.getElementById(tblcontrwidth).clientWidth;
    //   var scrollWidth = document.getElementById(tblcontrwidth).scrollWidth;
    //   if(scrollWidth > clientWidth) {
    //     this.tableconfig['tableclasses'] = (this.tableconfig['tableclasses'] + " horizontal-scroll");
    //   //  console.log("tableclasses : ",this.tableconfig['tableclasses'])
    //   }
    // }, 1200);

    if (this.tableconfig['checkedrecordscondition'])
      this.isCheckedRecordsCondition = this.tableconfig['checkedrecordscondition']['ischeckcondition'];


    this.updatedtabledataSubjectUnsubscribe = this.updatedtabledataSubject.subscribe(res => {
      // console.log(res);

      this.updatedtabledata.emit(res);
    });

    this.rowChangeOrUpdateSubjectUnsubscribe = this.rowChangeOrUpdateSubject.subscribe(res => {
      // console.log(res);

      this.rowChangeOrUpdate.emit(res);

    });
  //  console.log("onInIt filter");
    
    // this.searchSubjectUnscribe = this.searchSubject.pipe(
    //   filter((res) => res.length > 2),
    //   debounceTime(1000),
    //   distinctUntilChanged()
    // ).subscribe((str)=>{
    //   // this.searchString(str)
    // })
  }

  prepareBasicData() {
    this.prepareFilterAndSortingOnArray();
    //////set rows per page property
    /*below statement is commented becoz we want by default 100 records on page
    so ifwe comment it we have to declare rowsperpage in ts file as per our need.*/
    // this.rowsperpage = this.paginatorarray[0];
    /////////////////////Assign table Data to the tabledatabackup
    this.tabledatabackup = this.tabledata;
    ///////////Prapare headermenulist flags
    if (this.tableconfig['headermenulist']) {
    //  console.log("prepareBasicData normal for loop");

      for (let i = 0; i < this.tableconfig['headermenulist'].length; i++) {
        if (this.tableconfig['headermenulist'][i]['action'] == "displayhiddencols") {
          this.tableconfig['headermenulist'][i]['visible'] = false;
        }
        else {
          this.tableconfig['headermenulist'][i]['visible'] = true;
        }
      }
    }
    if (this.showactioncolumn) {
      this.colspancount += 1;
    }
    this.tableheaderbackup = _.cloneDeep(this.tableheaders);
  }

  setActualIndex(data: any[], onlydeeparray: boolean = false) {
    // var numcolobj = {};
    // for(let i = 0; i < this.tableheaders.length; i++) {
    //   var config = this.tableheaders[i]['numberconfig'];
    //   if(config && config['type'].trim().length) {
    //     if(!config['digit']) {
    //       config['digit'] = 0;
    //     }
    //     numcolobj[this.tableheaders[i]['key']] = this.tableheaders[i]['numberconfig'];
    //   }
    // }
    // console.log("numbercolmsObj : ", numcolobj);

    let temparr: any[] = _.cloneDeep(data);

    /*************************Below code will convert the data in expected format***********************/
    // if(Object.keys(numcolobj).length) {

    //   temparr.filter((row, indx) => {
    //     for(let key in numcolobj) {

    //       switch (numcolobj[key]['type'].toUpperCase()) {

    //         case "TOFIXED":
    //           row[key] = row[key].toFixed(+numcolobj[key]['digit']);
    //           break;
    //         case "ROUND":
    //           row[key] = Math.round(+row[key]);
    //           break;
    //         case "ABSOLUTE":
    //           row[key] = Math.abs(row[key]);
    //           break;
    //         case "CURRENCY":
    //           row[key] = Math.abs(row[key]);
    //           break;
    //       }
    //     }
    //     // if()
    //     return row['actualindex'] = indx;// this is an actual row index
    //   });
    // }

    /*****************************************End data format conversion******************************/
    /*******************************************Normal index se code*****************************/
  //  console.log("setActualIndex filter");

    temparr.filter((row, indx) => {
      if (this.tabledatabackup.length == 0) {
        row['autoid'] = indx;
      }
      if (!row['autoid']) {
        row['autoid'] = new Date().getTime() + indx;
      }
      row['actualindex'] = indx;// this is an actual row index

      if (!row['ptIndex'] || onlydeeparray) {
        row['ptIndex'] = indx;
      }
      return row;
    });

    /***************************************End seActual Index and do not focus on data format********************/

    if (!onlydeeparray) {
      // console.log("--------------");

      this.tabledata = _.cloneDeep(temparr);

    }
    else {
      this.tabledatabackup = _.cloneDeep(temparr);
    }

    this.calNoOfPages();
  }

  /********************************************End Currency conversion***********************/



  /**
   * Goup by Method forconsolidation
   */

  groupByMethod(list: any[], keyGetter, sortOn: string = "", isDate = false) {
  //  console.log('groupByMethod forEach 1');
    
    const map = new Map();
    list.forEach((item) => {
      let key = keyGetter(item);
      key = isDate ? (this.datepipe.transform(new Date(key), 'yyyy-MM-dd')) : key;
      const collection = map.get(key);
      if (!collection) {
        if (key) {
          map.set(key, [item]);
          if (sortOn) {
            map.set(key + "_sortOn", item[sortOn]);
          }
        }
      }
      else {
        collection.push(item);
      }
    });
    return map;
  }


  /**
   * End Group By Method
   */


  paginationTemp(ref) {
    // console.log(ref);
  }


  /********************************Add/Remove filters*****************************/

  addOrRemoveFilter(type: string = "I") {

    const t0 = performance.now();

    if (Object.keys(this.valuebeforecommit).length) {
    //  console.log("addOrRemoveFilter findIndex");

      // if(this.tabledata.length > this.valuebeforecommit['actualindex']) {
      var indxoftbldata = this.tabledata.findIndex(item => item['actualindex'] == this.valuebeforecommit['actualindex']);
      delete this.tabledata[indxoftbldata][this.valuebeforecommit["actioncolumn"]];
      this.valuebeforecommit = {};
      // }

    }

    let DataArrTofilter = [];

    if (type == 'I') {
      DataArrTofilter = _.cloneDeep(this.tabledata);
    }
    else {
      DataArrTofilter = _.cloneDeep(this.tabledatabackup);
    }
    // console.log('From check', type, this.tabledata, this.tabledatabackup, this.appliedFilters);

    let criteriastr = "";
  //  console.log("addOrRemoveFilter normal for loop");

    for (let index = 0; index < this.appliedFilters.length; index++) {

      var menu = this.appliedFilters[index];
      // console.log(menu);

      if (menu['text']) {
        menu['selectedtext'] = menu['text'];
      }
      if (menu['value']) {
        menu['selectedtext'] = menu['value'];
      }

      // this.applfiltcolmsobj[menu['targetcolumn']] = true;
      // console.log(menu)
      criteriastr = criteriastr + "item." + menu['targetcolumn'] + " && ";

      switch (menu['action']) {
        case "lessthan":
          if (menu['datatype'] == "date") {
            criteriastr = criteriastr + " new Date(item." + menu['targetcolumn'] + ")<=" + " new Date('" + menu['selectedtext'] + "') " + " && ";
          }
          else {
            criteriastr = criteriastr + "item." + menu['targetcolumn'] + "<=" + menu['selectedtext'] + " && ";
          }
          // criteriastr = criteriastr + "item." + menu['targetcolumn'] + "<" + (menu['datatype'] == "date" ? +" new Date(" + menu['selectedtext'] + ") " : menu['selectedtext']) + " && ";

          break;
        case "greaterthan":

          if (menu['datatype'] == "date") {
            criteriastr = criteriastr + " new Date(item." + menu['targetcolumn'] + ")>=" + " new Date('" + menu['selectedtext'] + "') " + " && ";
          }
          else {
            criteriastr = criteriastr + "item." + menu['targetcolumn'] + ">=" + menu['selectedtext'] + " && ";
          }

          break;
        case "between":
          if (menu['datatype'] == "date") {
            if (menu['from'] && !menu['to']) {
              criteriastr = criteriastr + " new Date(item." + menu['targetcolumn'] + ")>=" + " new Date('" + menu['from'] + " 00:00:00') " + " && ";
            }
            else if (!menu['from'] && menu['to']) {
              criteriastr = criteriastr + " new Date(item." + menu['targetcolumn'] + ")<=" + " new Date('" + menu['to'] + " 00:00:00') && ";
            }
            else if (menu['from'] && menu['to']) {
              if (new Date(menu['from']) > new Date(menu['to'])) {
                alert("From Date should be less than To Date");
                // return;
                continue;
              }
              criteriastr = criteriastr + " new Date(item." + menu['targetcolumn'] + ")>=" + " new Date('" + menu['from'] + " 00:00:00') " + " && " + " new Date(item." + menu['targetcolumn'] + ")<=" + " new Date('" + menu['to'] + " 00:00:00') && ";
            }
          }
          else {
            if (menu['from'] && !menu['to']) {
              criteriastr = criteriastr + "item." + menu['targetcolumn'] + ">=" + (menu['from'] ? menu['from'] : 0) + " && ";
            }
            else if (!menu['from'] && menu['to']) {
              criteriastr = criteriastr + "item." + menu['targetcolumn'] + "<=" + (menu['to'] ? menu['to'] : 0) + " && ";
            }
            else if (menu['from'] && menu['to']) {
              if (menu['from'] > menu['to']) {
                alert("Min can not be greater than Max");
                continue;
                // return;
              }
              criteriastr = criteriastr + "item." + menu['targetcolumn'] + ">=" + (menu['from'] ? menu['from'] : 0) + " && " + "item." + menu['targetcolumn'] + "<=" + (menu['to'] ? menu['to'] : 0) + " && ";
            }
          }
          // criteriastr = criteriastr + "item." + menu['targetcolumn'] + "===" + (menu['datatype'] == "date" ? +" new Date(" + menu['selectedtext'] + ") " : menu['selectedtext']) + " && ";
          break;
        case "notin":
          // var strvl: number = 0;
          // var val2=strvl.toString().trim().toUpperCase()
          if (menu['datatype'] == "number") {
            criteriastr = criteriastr + "item." + menu['targetcolumn'] + "!=" + menu['selectedtext'] + " && ";
          }
          else if (menu['datatype'] == "date") {
            // criteriastr = criteriastr + " this.datepipe.transform( new Date(item." + menu['targetcolumn'] + "),'dd-MMM-yyyy')!=" + " this.datepipe.transform(new Date('" + menu['row'][menu['targetcolumn']] + "'),'dd-MMM-yyyy') " + " && ";
            // var findval: string = menu['selectedtext'].toString().trim().toUpperCase();
            // findval = findval.replace("'", "");
            // console.log("findval : ", findval);
            criteriastr = criteriastr + "item." + menu['targetcolumn'] + "!=" + " '" + menu['row'][menu['targetcolumn']] + "' && ";
            // criteriastr = criteriastr + "item." + menu['targetcolumn'] + ".toString().trim().toUpperCase()." + "replace(" + '"' + "'" + '"' + ", '').includes('" + findval + "') && ";
          }
          else if (menu['datatype'] == "string" || menu['datatype'] == "dropdown") {
            var findval: string = (menu['selectedtext'] ? menu['selectedtext'] : '');
            findval = findval.replace("'", "").trim().toUpperCase();
            // console.log("findval : ", findval);
            criteriastr = criteriastr + "item." + menu['targetcolumn'] + ".toString().trim().toUpperCase()." + "replace(" + '"' + "'" + '"' + ", '')!='" + findval + "' && ";
          }
          // criteriastr = criteriastr + "item." + menu['targetcolumn'] + "!=" + (menu['datatype'] == "date" ? +" new Date(" + menu['selectedtext'] + ") " : menu['selectedtext']) + " && ";
          break;
        case "filter":
          // if(menu['datatype'] == "date") {
          //   criteriastr = criteriastr + " new Date(item." + menu['targetcolumn'] + ")>" + " new Date(" + menu['selectedtext'] + ") " + " && ";
          // }
          // else {
          //   criteriastr = criteriastr + "item." + menu['targetcolumn'] + ">" + menu['selectedtext'] + " && ";
          // }
          var findval: string = (menu['selectedtext'] ? menu['selectedtext'].toString().trim().toUpperCase() : '');
          findval = findval.replace("'", "").trim().toUpperCase();
          // var findval: string = (menu['selectedtext'] ? menu['selectedtext'] : '');
          // findval = findval.replace("'", "");
          // console.log("findval : ", findval);
          criteriastr = criteriastr + "item." + menu['targetcolumn'] + ".toString().trim().toUpperCase()." + "replace(" + '"' + "'" + '"' + ", '').includes('" + findval + "') && ";
          break;
        // case "between":
        //   criteriastr = criteriastr + "item." + element['targetcolumn'] + ">" + element['selectedtext'] + " && ";
        //   break;
      }
      if (menu['action'] == "filter" || menu['action'] == "notin" || menu['action'] == "between") {
        this.applfiltcolmsobj[menu['targetcolumn']] = true;
      }
      // if(this.casesensitive) {
      // criteriastr = criteriastr + "item." + element['targetcolumn'] + ".toLower().includes('" + element['selectedtext'] + "') && ";
      // }
      // else {
      // criteriastr = criteriastr + "item." + element['targetcolumn'] + ".includes('" + element['selectedtext'] + "') && ";
      // }
    }

    criteriastr = criteriastr.substr(0, criteriastr.length - 4);

    //console.log(JSON.stringify(this.tableheaders));
    // this.searchtext = "";
    this.columnsearchtext = "";

    if (!criteriastr.trim().length) {
      this.filteroncell = false;

      this.tabledata = [];
      // this.tabledata = DataArrTofilter;

      this.setActualIndex(DataArrTofilter);
      this.updatedtabledata.emit(this.tabledata);
      // this.updatedtabledataSubject.next(this.tabledata)
      var sortedcolm = this.identSortedColm();
      // this.search();
      this.sorting(sortedcolm['indx'], sortedcolm, true);
      return;
    }

    this.filteroncell = (this.appliedFilters.length ? true : false);

    // console.log("str : ", criteriastr);
  //  console.log("addOrRemoveFilter filter, eval");

    let filtreddata = DataArrTofilter.filter((item) => eval(criteriastr));

    // console.log("filtreddata---------------");

    this.tabledata = [];
    // this.tabledata = filtreddata;
    this.setActualIndex(filtreddata);
    this.updatedtabledata.emit(this.tabledata);
    // this.updatedtabledataSubject.next(this.tabledata)
    var sortedcolm = this.identSortedColm();
    this.sorting(sortedcolm['indx'], sortedcolm, true);

    // Calculate Footer Values //
    this.calcFooterVals();
    // End Footer Calculation //
  }

  /******************************************End Add/Remove Filter********************/

  /**************************************Identifying the sorted column******************/

  identSortedColm(): any {
    var headerobj = {};
  //  console.log("identSortedColm normal for loop");

    for (let index = 0; index < this.tableheaders.length; index++) {
      const element = this.tableheaders[index];
      if (element['visible'] && element['sorting']) {
        headerobj = element;
        headerobj['indx'] = index;
        break;
      }
    }
    return headerobj;
  }

  /********************************************End sorted column forLoop**********************/

  /*******************************************************Convert amount number into************************/


  setTopHeader() {
    var prevtopheader: string = "";
    var topheadersarr: any[] = [];
    var topheadercount: number = 0;
  //  console.log("setTopHeader normal for loop");

    for (let index = 0; index < this.tableheaders.length; index++) {

      const header = this.tableheaders[index];

      if (header['visiblecolm']) {
        this.tableheaders[index]['ddlrefkey'] = "";

        if (!header['topheader'] || (header['topheader'] && header['topheader'].toString().trim().length == 0)) {
          header['topheader'] = "";
          topheadersarr.push({ "label": header['topheader'], "colspan": 1 });
        }
        else {
          topheadercount += 1;
          if (prevtopheader.toUpperCase() != header['topheader'].toUpperCase()) {
            topheadersarr.push({ "label": header['topheader'], "colspan": 1 });
          }
          else {
            topheadersarr[topheadersarr.length - 1]['colspan'] += 1;
          }
        }
        prevtopheader = header['topheader'];

        /******************************************assigning the drodown reference key
         * This key will be use at the time of cascading dropdown list. Internally table will add a key in header object
         * ***********************/

        // if(header['dropdownlist'] && header['dropdownlist'].length) {
        //   var keys = [];
        //   keys = Object.keys(header['dropdownlist'][0]);
        //   for(let keyindx = 0; keyindx < keys.length; keyindx++) {
        //     const optkey = keys[keyindx];
        //     if(headersstr.includes(optkey)) {
        //       this.tableheaders[index]['ddlrefkey'] = optkey;
        //       break;
        //     }
        //   }
        // }

        /**************************************************End assigning drodown refernce key*********************/
      }
    }
    this.topheaders = topheadercount ? topheadersarr : [];
  }
  /**************Verify keys of table settings and update/add required keys in table setting/config************/

  verifyTblConfig() {
    /*****************************
     * Check the existing flag for"updation"
     */

    if (this.tableconfig['summary'] && this.tableconfig['summary'].length) {
      this.tableconfig['showsummary'] = false;
      if (this.isViewInit) {
        this.tableconfig['showsummary'] = !this.tableconfig['showsummary'];
        this.footerValObj['caltypes'] = [];
      }
    }
    if (!this.tableconfig['resizable']) {
      this.tableconfig['resizable'] = {};
      this.tableconfig['resizable']['rows'] = false;
      this.tableconfig['resizable']['columns'] = false;
    }
    if (!this.tableconfig['apicall']) {
      this.tableconfig['apicall'] = {};
      this.tableconfig['apicall']['autoupdate'] = false;
      // this.tableconfig['apicall']['columns'] = false;
    }
    if (!this.tableconfig['dragdrop']) {
      this.tableconfig['dragdrop'] = {};
      this.tableconfig['dragdrop']['rows'] = false;
      this.tableconfig['dragdrop']['columns'] = false;
    }
    if (!this.tableconfig['defaultcellmenus']) {
      this.tableconfig['defaultcellmenus'] = [];
    }
    if (this.tableconfig['resizable']['rows'] == undefined) {
      this.tableconfig['resizable']['rows'] = false;
    }
    if (!this.tableconfig['resizable']['columns'] == undefined) {
      this.tableconfig['resizable']['columns'] = false;
    }
    if (this.tableconfig['dragdrop']['rows'] == undefined) {
      this.tableconfig['dragdrop']['rows'] = false;
    }
    if (this.tableconfig['dragdrop']['columns'] == undefined) {
      this.tableconfig['dragdrop']['columns'] = false;
    }
    if (this.tableconfig['stickycolumns'] == undefined) {
      this.tableconfig['stickycolumns'] = {
        "initial": 0,
        "maxlimit": 0
      }
    }
    else if (this.tableconfig['stickycolumns']['initial'] == undefined) {
      this.tableconfig['stickycolumns']['initial'] = 0;
    }
    else if (this.tableconfig['stickycolumns']['maxlimit'] == undefined) {
      this.tableconfig['stickycolumns']['maxlimit'] = 0;
    }
    if (this.tableconfig['customactionbuttons'] == undefined) {
      this.tableconfig['customactionbuttons'] = [];
    }
    if (this.tableconfig['enablefreezepanes'] == undefined) {
      this.tableconfig['enablefreezepanes'] = false;
    }
    if (this.tableconfig['enablefreezecolumns'] == undefined) {
      this.tableconfig['enablefreezecolumns'] = false;
    }

    let isfreezecolmpresent: number = -1;

    if (this.tableconfig['headercontextmenu']) {
      if (!this.tableconfig['headermenulist']) {
        this.tableconfig['headermenulist'] = [];
      }
      this.tableconfig['headermenulist'] = (this.tableconfig['headermenulist'] as any[]).concat(this.freezeHeaderMenu);
    }
    else {
  //  console.log("verifyTblConfig normal for loop 1");

      for (let index = 0; index < this.tableconfig['headermenulist'].length; index++) {
        const element = this.tableconfig['headermenulist'][index];
        if (element['action'] == "freezecolumn") {
          isfreezecolmpresent = index;
        }
      }
      if (isfreezecolmpresent != -1) {
        (this.tableconfig['headermenulist'] as any[]).splice(isfreezecolmpresent, 3);
      }
      this.tableconfig['headermenulist'] = (this.tableconfig['headermenulist'] as any[]).concat(this.freezeHeaderMenu);

      // console.log('headermenulist',this.tableconfig['headermenulist']);
    }

    /*********************************Common cell level menus**************************/
  //  console.log("verifyTblConfig normal for loop 2");

    for (let index = 0; index < this.tableconfig['defaultcellmenus'].length; index++) {
      const element = this.tableconfig['defaultcellmenus'][index];
      //following actions are system reserved action. Developer can not get/set the expected behavour of table ifaction name is changed.
      if (element['action'] == "lognotes" || element['action'] == "timeline" || element['action'] == "quickupdate") {
        this.showcommoncontxmenus = true;
      }
    }
    /*************************************End common cell menus*****************************/
    /*********************************Checking the top headers of table in each header********************/

    // var headersstr: string = "";

    /****************************************End Top header preparation***********************************/
    let insertedcaltype: string = "";
  //  console.log("verifyTblConfig normal for loop 3");
    
    for (let index = 0; index < this.tableheaders.length; index++) {
      if (!this.tableheaders[index]['shownegativevalue']) {
        this.tableheaders[index]['shownegativevalue'] = false;
      }
      if (!this.tableheaders[index]['cascadingref']) {
        this.tableheaders[index]['cascadingref'] = {
          "parent": "",
          "child": ""
        };
      }
      if (!this.tableheaders[index]['cascadingref']['parent']) {
        this.tableheaders[index]['cascadingref']['parent'] = "";
      }
      if (!this.tableheaders[index]['cascadingref']['child']) {
        this.tableheaders[index]['cascadingref']['child'] = "";
      }

      const header = this.tableheaders[index];

      // headersstr += header['key'] + ",";

      /************************************push column into formulacolms ifit has formula************/
      if (header['formula'] && header['formula'].toString().trim().length) {
        var formula: string = header['formula'];
        formula = formula.replace(/\s/g, '');
        this.formulacolms.push({
          "key": header['key'], "display": header['display'], "formula": formula,
          "decimaldigit": header['decimaldigit'], "shownegativevalue": header['shownegativevalue'],
          "colindex": index
        });
      }

      /***********************************End push formula column*************************************/

      if (!header['summary']) {
        this.tableheaders[index]['summary'] = [];
      }
      else {

      //  console.log('groupByMethod forEach 2');
        (header['summary'] as string[]).forEach((caltype) => {
          caltype = caltype.trim().toLowerCase();
          if (!insertedcaltype.includes(caltype)) {
            insertedcaltype += caltype + ",";
            this.footerValObj.caltypes.push({ "show": ((this.tableconfig['summary'] as string[]).indexOf(caltype) == -1 ? false : true), "value": caltype });
          }

          //set the default flag of summary type.

          this.footerValObj[caltype + header['key']] = 0;
          (this.footerValObj[caltype] as any[]).push({ "key": header['key'], "display": header['display'] });
          this.footerValObj[caltype][this.footerValObj[caltype].length - 1][caltype] = 0;
          this.footerValObj[caltype][this.footerValObj[caltype].length - 1]["decimaldigit"] = header['decimaldigit'] != undefined ? header['decimaldigit'] : 2;
        });

        if ((header['summary'] as string[]).indexOf("average") >= 0 && (header['summary'] as string[]).indexOf("sum") == -1) {
          if (this.footerValObj.caltypes.indexOf("sum") == -1) {
            this.footerValObj.caltypes.push("sum");
          }
          (this.footerValObj["sum"] as any[]).push({ "key": header['key'], "display": header['display'], "sum": 0, "decimaldigit": header['decimaldigit'] != undefined ? header['decimaldigit'] : 2 });
        }
      }

      /***************************************End top headers creation*****************/
      if (!header['numberconfig'] && header['datatype'] == "number") {
        this.tableheaders[index]['numberconfig'] = {};
        this.tableheaders[index]['numberconfig'] = { "type": "", "digit": -1 };
      }
      if (!header['thstyle']) {
        this.tableheaders[index]['thstyle'] = {};
      }
      if (!header['thstyle']['width']) {
        this.tableheaders[index]['thstyle']['width'] = 'auto';
      }
      if (!header['menulist']) {
        this.tableheaders[index]['menulist'] = [];
      }
      if (header['menulist'] || (this.tableconfig['defaultcellmenus'] && this.tableconfig['defaultcellmenus'].length)) {
  //  console.log("verifyTblConfig normal for loop 4");

        for (let defindx = 0; defindx < this.tableconfig['defaultcellmenus'].length; defindx++) {
          const defmenu = this.tableconfig['defaultcellmenus'][defindx];
          if (this.tableheaders[index]['menulist'].indexOf(defmenu) == -1) {
            this.tableheaders[index]['menulist'].push(defmenu)
          }
        }
        //this.tableheaders[index]['menulist'] = this.tableheaders[index]['menulist'].concat(this.tableconfig['defaultcellmenus']);

      }
    }


    /**
     * Adding Summary Columns; in the table header
     */


    if (this.tableconfig['summary'] && this.tableconfig['summary'].length) {
    //  console.log('groupByMethod forEach 3');

      this.footerValObj['caltypes'].forEach((caltype) => {
        this.footerValObj[caltype['value'] + '_show'] = ((this.tableconfig['summary'] as string[]).indexOf(caltype['value']) != -1);
      })

      this.tableconfig['headermenulist'] = (this.tableconfig['headermenulist'] as any[]).concat(this.summaryHeaderMenu);
    }

    /***End Summary menus addition */



    this.prepareAppliedFilterObj();
    this.setTopHeader();
    this.calcFooterVals();
  }
  /*******************************************End keys verification in tableconfig*******************************/







  /**
   * prepare applied filter object forcell context menu
   */

  prepareAppliedFilterObj() {
  //  console.log("prepareAppliedFilterObj normal for loop 1");

    for (let index = 0; index < this.tableheaders.length; index++) {

      var tempheadermenus: any[] = [];
      tempheadermenus = JSON.parse(JSON.stringify(this.tableheaders[index]['menulist']));
    //  console.log("prepareAppliedFilterObj normal for loop 2");

      for (let menuindx = 0; menuindx < tempheadermenus.length; menuindx++) {
        if (tempheadermenus[menuindx]['action'] == "lognotes" || tempheadermenus[menuindx]['action'] == "timeline" || tempheadermenus[menuindx]['action'] == "quickupdate") {
          this.showcommoncontxmenus = true;
        }
        if (tempheadermenus[menuindx]['action'] == "filter") {/// if"filter" action is available forspecific column OR in common menus then add Exclude/Not_in menu by default.
          this.applfiltcolmsobj[this.tableheaders[index]['key']] = false;
          // console.log("----------------------------------------")
          this.tableheaders[index]['menulist'].splice((menuindx + 1), 0, { "action": "notin", "label": "Exclude", "showcelltext": true });
        }
      }

    }

  }



  /**End appliedfilterobject preparation */

  resetCalProps() {
  //  console.log('resetCalProps forEach 1');

    this.footerValObj['sum'].forEach((headerObj) => {
      headerObj['sum'] = 0;
      this.footerValObj["sum_common" + headerObj['key']] = headerObj['sum'];
    });
  //  console.log('resetCalProps forEach 2');

    this.footerValObj['min'].forEach((headerObj) => {
      headerObj['min'] = 0;
      this.footerValObj["min_common" + headerObj['key']] = headerObj['min'];
    });
  //  console.log('resetCalProps forEach 3');

    this.footerValObj['max'].forEach((headerObj) => {
      headerObj['max'] = 0;
      this.footerValObj["max_common" + headerObj['key']] = headerObj['max'];
    });

  }

  /**
   * Calculating footer values
   */
  calcFooterVals() {
    try {

      this.showloader = true;

      if (this.footerValObj['caltypes'] && this.footerValObj['caltypes'].length) {

        this.resetCalProps();
      //  console.log("calcFooterVals filter");

        this.tabledata.filter((item, index) => {
        //  console.log('calcFooterVals forEach 1');

          // Calculating SUM //
          this.footerValObj['sum'].forEach((headerObj) => {
            headerObj['sum'] += (item[headerObj['key']] ? parseFloat((item[headerObj['key']]).toString()) : 0);
            // this.footerValObj["sum_common" + headerObj['key']] = (+headerObj['sum']);
            this.footerValObj["sum_common" + headerObj['key']] = (headerObj['sum'] as number).toFixed(headerObj['decimaldigit'] != undefined ? headerObj['decimaldigit'] : 2);
          });
          // End Calculation //
        //  console.log('calcFooterVals forEach 2');

    // Calculating MIN //
          this.footerValObj['min'].forEach((headerObj) => {

            if (item[headerObj['key']]) {
              if (!headerObj['min']) {
                headerObj['min'] = parseFloat((item[headerObj['key']] ? item[headerObj['key']] : 0).toString());
                // headerObj["min"] = (+(((+item[headerObj['min']]) as number).toFixed(headerObj['decimaldigit'] != undefined ? headerObj['decimaldigit'] : 2)));
              }
              else if (headerObj['min'] > item[headerObj['key']]) {
                headerObj['min'] = parseFloat((item[headerObj['key']] ? item[headerObj['key']] : 0));
                // headerObj["min"] = (+(((+item[headerObj['min']]) as number).toFixed(headerObj['decimaldigit'] != undefined ? headerObj['decimaldigit'] : 2)));
              }
            }

            // this.footerValObj["min_common" + headerObj['key']] = (+headerObj['min']);
            this.footerValObj["min_common" + headerObj['key']] = (headerObj['min'] as number).toFixed(headerObj['decimaldigit'] != undefined ? headerObj['decimaldigit'] : 2);
          });
          // End MIN //

          // Calculating MAX //
        //  console.log('calcFooterVals forEach 3');

          this.footerValObj['max'].forEach((headerObj) => {
            let newMin = (item[headerObj['key']] ? parseFloat((item[headerObj['key']]).toString()) : 0);
            if ((headerObj['max']) < (newMin)) {
              headerObj['max'] = (newMin);
            }
            //this.footerValObj["max_common" + headerObj['key']] = (+headerObj['max']);
            this.footerValObj["max_common" + headerObj['key']] = (headerObj['max'] as number).toFixed(headerObj['decimaldigit'] != undefined ? headerObj['decimaldigit'] : 2);
          });
          // End MAX //

        });
      //  console.log('calcFooterVals forEach 4');

        this.footerValObj['average'].forEach(avgObj => {
        //  console.log('calcFooterVals forEach in ForEach 5');

          this.footerValObj['sum'].forEach((sumObj) => {
            if (avgObj['key'] == sumObj['key']) {
              sumObj['average'] = (sumObj['sum']) / this.tabledata.length;
              this.footerValObj["average_common" + avgObj['key']] = (sumObj['average'] as number).toFixed(avgObj['decimaldigit'] != undefined ? avgObj['decimaldigit'] : 2);
            }
          });
        });

      }
      this.footerValObj.caltypes

      this.showloader = false;

    }
    catch (error) {
      this.showloader = false;
      console.error(error);
    }
  }

  /**End footer values calculations */

  ngAfterViewInit() {


    if (this.tableconfig['enablefreezecolumns']) {
      if (this.tableconfig['stickycolumns']['initial'] && this.tableconfig['stickycolumns']['initial'] > 0) {
    //  console.log("ngAfterViewInit normal for loop");

        for (let index = 0; index < this.tableconfig['stickycolumns']['initial']; index++) {
          this.tableheaders[index]['isfrozen'] = true;
        }
        this.freezePanesOrColms("columns", 0, this.tableconfig['stickycolumns']['initial']);
      }
    }
    this.isViewInit = true;

    // setTimeout(() => {
    //  console.log("ngAfterViewInit");
      
    this.hideAndShowSummary();
    // }, 2000);
    this.updateStickyHeaderStyle();
    this.changeDetectRef.detectChanges();
    if (this.tableconfig['exporttoexcel'] || this.tableconfig['exporttopdf'] || this.cellcontextobj['action'] == 'filter' || this.filteroncell || this.tableconfig['showMismatch']) { this.eventForCleckOnBody(); }

    setTimeout(() => {
      this.actionOptionPraper();
    }, 200)
  }

  ngAfterViewChecked() {
    if (this.tableconfig['checkDeletedFlag']) {
      this.findIsDeletedAndHighlight();
    }
    if(this.tableconfig['checkBlanketPoFlag']){
      this.findIsBlankePoOrNot();
    }
  }


  /**
   * ifthere are top headers then calculate the index of
   */
  updateStickyHeaderStyle() {
    if (this.topheaders.length) {
      let positionTop = document.getElementById("topth0").clientHeight + 1;

      let stickyHTML = "";

      stickyHTML += `
      thead tr:nth-child(2) th {
                position: sticky !important;
                top: ${positionTop}px !important;
                display: table-cell !important;
                box-shadow: 1px 0px 0px #bfbfbf !important;
                z-index: 1000 !important;
        }
        `;

      let sheet = document.createElement('style');
      sheet.id = "mainStickyHeader";
      sheet.innerHTML = stickyHTML
      document.body.appendChild(sheet);
      stickyHTML = "";


    }
  }

  /***End header calculation */



  ngOnChanges() {
    // console.log("cell menu : ", this.selectedcellmenu);
    /// ngOnchanges executes while two way binding is set or emitting two way binding data. This will not re-assigned the data and cell default menus.
    if (this.filteroncell || Object.keys(this.selectedcellmenu).length) {
      return;
    }
  //  console.log("ngOnChanges Detect" );
    
    this.verifyTblConfig();

    this.setActualIndex(this.tabledata);
    /******set Auto Id of each record it is nothing but the actual index but this will not be change after the sorting and filter*****/
    /********************************************************End  */
    /////////////////////End////////////////
    this.prepareBasicData();
    if (this.hidecolumnaction) {
      this.setvisibleHiddenFlagOfMenuList(true);///visible flag of displayhiddencols will be true
    }
    this.search();
    if(this.tabledata.length > 250){
      this.ispagination = false;
    }
    // this.updatedtabledata.emit(this.tabledata);
    this.updatedtabledataSubject.next(this.tabledata)
    // this.setBackupOfSetCellColourFlag();
  }

  prepareFilterAndSortingOnArray() {
    // this.colspancount = this.tableheaders.length;
    this.colspancount = (this.tableconfig['actioncolmconfig'] ? (this.tableheaders.length + 1) : this.colspancount);
  //  console.log("prepareFilterAndSortingOnArray normal for loop");

    for (let i = 0; i < this.tableheaders.length; i++) {
      if (this.tableheaders[i]['sorting'] != undefined && this.tableheaders[i]['sorting']) {
        // this.tableheaders[i]['sorting'] = true;
        this.tableheaders[i]['visible'] = false;
        this.tableheaders[i]['ascending'] = false;
      }
      this.tableheaders[i]['filter'] = true;
      if (this.tableheaders[i]['visiblecolm'] == undefined || (this.tableheaders[i]['visiblecolm'] != undefined && this.tableheaders[i]['visiblecolm'] == true)) {
        this.tableheaders[i]['visiblecolm'] = true;
        this.tableheaders[i]['colhiddenby'] = "Visible";
      }
      else {
        this.tableheaders[i]['indx'] = i;
        // this.hiddencolumns.push(this.tableheaders[i]);
        //this.hidecolumnaction = true;
        // this.hidecolumnaction = false;
        // this.colspancount--;
        this.tableheaders[i]['colhiddenby'] = "System";
      }
      if (this.tableconfig[this.tableheaders[i]['key']] == undefined) {
        this.tableconfig[this.tableheaders[i]['key']] = {};
      }
      this.tableconfig[this.tableheaders[i]['key']]['width'] = this.tableheaders[i]['thstyle']['width'];
      if (this.tableheaders[i]['editable']) {
        this.tableconfig[this.tableheaders[i]['key']]['cursor'] = "text";
        this.tableconfig['editable'] = true;
      }
      // this.tableconfig[this.tableheaders[i]['key']]['min-width'] = this.tableheaders[i]['thstyle']['min-width'];
      // this.tableconfig[this.tableheaders[i]['key']]['max-width'] = this.tableheaders[i]['thstyle']['max-width'];
    }
  }
  ///////////////////////Preapare sorting Flags////////////////////

  setSortingFlags(indx) {
    if (this.tableheaders[indx]['sorting'] == undefined) {
      return;
    }
    this.tableheaders[indx]['visible'] = true;
  //  console.log("setSortingFlags normal for loop");

    for (let i = 0; i < this.tableheaders.length; i++) {
      if (indx != i) {
        this.tableheaders[i]['ascending'] = false;
        this.tableheaders[i]['visible'] = false;
        // this.tableheaders[i]['sorting'] = false;
      }
    }
  }

  ///////////////////////End Prapare sorting Flags

  ////////////////////Change Event forCheckbox/////////////////
  changeFilterCheckBox(indx, event) {
  }
  //////////////////////End Checkbox Event//////////////////////

  sorting(indx, headerobj, afterfilter: boolean = false) {

    try {
      if (Object.keys(this.consolidatedetails).length && this.consolidatedetails['consolidation']) {
        this.showToastMsg("Sorting is not available after aggregation", false);
        return;
      }

      if (this.controlsenabled) {
        this.dismissCellFocus();
      }

      if (!Object.keys(headerobj).length) return;

      if (headerobj['sorting'] != undefined && headerobj['sorting'] && !this.controlsenabled) {
        this.showloader = true;
        // this.ascending = this.ascending ? false : true;
        if (!afterfilter) {
          headerobj['ascending'] = headerobj['ascending'] ? false : true;
        }
        this.setSortingFlags(indx);
        switch (headerobj['datatype']) {
          case "number":
            this.sortNumber(headerobj);
            break;
          case "string":
          case "dropdown":
            this.sortString(headerobj);
            break;
          case "date":
            this.sortDateTime(headerobj);
            break;
        }
        // this.setTableRowIndx();
        // console.log("secatc555555");

        this.setActualIndex(this.tabledata);
        // this.updatedtabledata.emit(this.tabledata);
        this.updatedtabledataSubject.next(this.tabledata)
        // this.updatedtabledata = JSON.parse(JSON.stringify(this.tabledata));
        this.showloader = false;
      }
      else {
        this.showSuccOrErrMsg("Sorting is Not Allowed", "Please enable sorting on " + headerobj['display'] + " column", true);
      }
      this.updateFreezeCSS();
    }
    catch (error) {
      this.showloader = false;
    }



  }

  sortNumber(headerobj) {

    var sortedarray: string[] = [];

    // if(headerobj['ascending']) {
    //   sortedarray = this.tabledata.sort((n1, n2) => {
    //     return n1[headerobj['key']] - n2[headerobj['key']]
    //   });
    // }
    // else {
    //   sortedarray = this.tabledata.sort((n1, n2) => {
    //     return (n1[headerobj['key']] < n2[headerobj['key']]) ? 1 : -1;
    //   });
    // }
    // New code
    // console.time("sortno")
    sortedarray = this.tabledata.sort((n1, n2) => {
      const num1 = this.checkIsNumberOrNot(n1[headerobj['key']]) ? n1[headerobj['key']] : 0;
      const num2 = this.checkIsNumberOrNot(n2[headerobj['key']]) ? n2[headerobj['key']] : 0;

      if (headerobj['ascending']) {
        const returnValue = num1 - num2;

        // return n1[headerobj['key']] - n2[headerobj['key']]
        return returnValue;
      }
      else {
        const returnValue = num1 - num2;
        return (num1 < num2) ? 1 : returnValue == 0 ? 0 : -1;
      }

    });
    // console.log("sortNumber------------");
    // console.timeEnd("sortno")
    this.tabledata = [];
    this.tabledata = sortedarray;
    this.tabledata = _.cloneDeep(sortedarray);
    // this.tablejson = JSON.parse(JSON.stringify(this.tablejson))
    // this.dataSharingSrvc.sendData();
  }
  /**
   *


   */

  checkIsNumberOrNot(value: any) {
    // console.log("checkIsNumberOrNot", value);
    if (value == undefined || value == null) {
      return false;
    } else {
      const isNumber = !isNaN(+value)
      return isNumber;
    }

  }


  sortArray(a: string, b: string) {
    a = a.toString().toLowerCase();
    b = b.toString().toLowerCase();
    const digitRegex = /^\d/g;
    const alphabetRegex = /^[a-zA-Z]/g;
    const symbolRegex = /^[^\w\s]/g;

    const scoreA: any = (symbolRegex.test(a) as any) * 1 || (digitRegex.test(a) as any) * 10 || (alphabetRegex.test(a) as any) * 100;
    const scoreB: any = (symbolRegex.test(b) as any) * 1 || (digitRegex.test(b) as any) * 10 || (alphabetRegex.test(b) as any) * 100;

    if (scoreA !== scoreB) {
      return scoreA - scoreB;
    }

    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }

    return 0;
  }

  /**
   *

   */
  sortString(headerobj) {
    var sortedarray: string[] = [];

    // if(headerobj['ascending']) {
    //   sortedarray = this.tabledata.sort((str1, str2) => {
    //     str1[headerobj['key']] = str1[headerobj['key']] == null ? "" : str1[headerobj['key']];
    //     str2[headerobj['key']] = str2[headerobj['key']] == null ? "" : str2[headerobj['key']];
    //     if((str1[headerobj['key']] as string).trim().toLowerCase() > (str2[headerobj['key']] as string).trim().toLowerCase()) {
    //       return 1;
    //     }
    //     else if((str1[headerobj['key']] as string).trim().toLowerCase() < (str2[headerobj['key']] as string).trim().toLowerCase()) {
    //       return -1;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // }
    // else {
    //   sortedarray = this.tabledata.sort((str1, str2) => {
    //     str1[headerobj['key']] = str1[headerobj['key']] == null ? "" : str1[headerobj['key']];
    //     str2[headerobj['key']] = str2[headerobj['key']] == null ? "" : str2[headerobj['key']];
    //     if((str1[headerobj['key']] as string).trim().toLowerCase() < (str2[headerobj['key']] as string).trim().toLowerCase()) {
    //       return 1;
    //     }
    //     else if((str1[headerobj['key']] as string).trim().toLowerCase() > (str2[headerobj['key']] as string).trim().toLowerCase()) {
    //       return -1;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // }


    ///New code

    sortedarray = this.tabledata.sort((str1, str2) => {

      str1[headerobj['key']] = str1[headerobj['key']] == null ? "" : str1[headerobj['key']];
      str2[headerobj['key']] = str2[headerobj['key']] == null ? "" : str2[headerobj['key']];

      const string1: any = str1[headerobj['key']] ? (str1[headerobj['key']] as string).trim().toLowerCase() : 0;
      const string2: any = str2[headerobj['key']] ? (str2[headerobj['key']] as string).trim().toLowerCase() : 0;
      const returnValue = string1 - string2;

      if (headerobj['ascending']) {

        return (string1 > string2) ? 1 : returnValue == 0 ? 0 : -1;
      }
      else {

        return (string1 < string2) ? 1 : returnValue == 0 ? 0 : -1;
      }

    })


    this.tabledata = [];
    this.tabledata = sortedarray;
  }

  sortDateTime(headerobj) {
    var sortedarray: string[] = [];
    // if(headerobj['ascending']) {
    //   sortedarray = this.tabledata.sort((dt1, dt2) => {
    //     let date1: Date = new Date(dt1[headerobj['key']]);
    //     let date2: Date = new Date(dt2[headerobj['key']]);
    //     if(date1 > date2) {
    //       return 1;
    //     }
    //     else if(date1 < date2) {
    //       return -1;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // }
    // else {
    //   sortedarray = this.tabledata.sort((dt1, dt2) => {
    //     let date1: Date = new Date(dt1[headerobj['key']]);
    //     let date2: Date = new Date(dt2[headerobj['key']]);
    //     if(date1 < date2) {
    //       return 1;
    //     }
    //     else if(date1 > date2) {
    //       return -1;
    //     }
    //     else {
    //       return 0;
    //     }
    //   });
    // }

    // New Code

    sortedarray = this.tabledata.sort((dt1, dt2) => {

      const date1: any = this.checkIsNumberOrNot(Date.parse(dt1[headerobj['key']])) ? new Date(dt1[headerobj['key']]) : 0;
      const date2: any = this.checkIsNumberOrNot(Date.parse(dt2[headerobj['key']])) ? new Date(dt2[headerobj['key']]) : 0;
      const retuenValue = date1 - date2;

      if (headerobj['ascending']) {

        return (date1 > date2) ? 1 : retuenValue == 0 ? 0 : -1;
      }
      else {
        return date1 < date2 ? 1 : retuenValue == 0 ? 0 : -1;
      }

    });

    this.tabledata = [];
    this.tabledata = sortedarray;
  }


  //////////////////////Search Records//////////////////////
  search(fromcontextmenu: boolean = false) {
    try {
    //  console.log("---------------------------------search------------------------------------");
      
      if (this.searchtext || this.columnsearchtext) {
        let dt: Date = new Date();
        // let t0 = performance.now();
        // this.showloader = true;
        let backuparray: any[] = [];
        let filteredarray: any[] = [];
        if (!this.filteroncell) {
          // console.log("tabledata backup.....");
          backuparray = _.cloneDeep(this.tabledatabackup);
        }
        else {
          // console.log("tabledata backup.....Existing data is used....");
          backuparray = _.cloneDeep(this.tabledata);
        }
        let searchtxt = (this.searchtext && !this.disabledmainsearch) ? this.searchtext : this.columnsearchtext;
        var headerarr = [];
        // headerarr = this.tableheaders;
  //  console.log("search normal for loop");

        for (let index = 0; index < this.tableheaders.length; index++) {
          const element = this.tableheaders[index];
          if (this.searchtext && element['colhiddenby'] == "Visible") {
            headerarr.push(element)
          } else if (this.columnsearchtext && element['colhiddenby'] == "Visible" && element['coloumnwisesearch']) {
            headerarr.push(element);
          }
        }
        // console.log({backuparray});

        if (this.columnsearchtext) {
          // console.log("column wise ", headerarr);
        //  console.log("search filter 1");
          
          filteredarray = backuparray.filter(row => this.filterProcess(row, searchtxt, headerarr))
        } else {
        //  console.log("search filter 2");

          // console.log("Tablewise wise ", headerarr);
          filteredarray = backuparray.filter(row => this.filterProcess(row, searchtxt, headerarr))
        }


        this.tabledata = [];
        this.tabledata = filteredarray;
        // console.log("indexing");

        this.setActualIndex(this.tabledata);
        // console.log("search tabledata ",this.tabledata);

        // this.updatedtabledata.emit(this.tabledata);
        this.updatedtabledataSubject.next(this.tabledata)
        // this.assignToNgModel();
        this.showloader = false;
        this.searched = true;
        // Calculate Footer Values //
        this.calcFooterVals();
        // End Footer Calculation //
        // let t1 = performance.now();
        // console.log(t1 - t0);
      }
    }
    catch (ex) {
      this.showloader = false;
      throw ex;
    }
  }

  // Deprecat
  deprecatedFilterProcess(row, FilterQuery: string, filteronkeys) {

    let FilterStr: String = "";
  //  console.log("deprecatedFilterProcess normal for loop");

    for (let keyObj of filteronkeys) {
      if ((row[keyObj['key']] != undefined && row[keyObj['key']] != null) && keyObj['filter'])
        FilterStr += keyObj['datatype'] == 'date' ? this.datepipe.transform(row[keyObj['key']], this.dateformat) : row[keyObj['key']].toString();
    }
    //let ind = FilterStr.trim().toLowerCase().indexOf(FilterQuery.toString().toLowerCase());
    let ind: any = false;
    if (this.casesensitive) {
      ind = FilterStr.trim().includes(FilterQuery.toString());
    }
    else {
      ind = FilterStr.trim().toLowerCase().includes(FilterQuery.toString().toLowerCase());
    }
    if (ind) {
      return true
    }
    else {
      return false;
    }
  }

  filterProcess(row, FilterQuery: string, filteronkeys) {

    // let FilterStr: String = "";
    let ind: any = false;
  //  console.log("filterProcess normal for loop");

    for (let keyObj of filteronkeys) {
      if ((row[keyObj['key']] != undefined && row[keyObj['key']] != null) && keyObj['filter']) {
        const FilterStr = (keyObj['datatype'] == 'date' ? this.datepipe.transform(row[keyObj['key']], this.dateformat) : row[keyObj['key']].toString());
        if (this.casesensitive) {
          ind = FilterStr.trim().includes(FilterQuery.toString());
        }
        else {
          ind = FilterStr.trim().toLowerCase().includes(FilterQuery.toString().toLowerCase());
        }
        if (ind) {
          break;
        }
      }
    }

    if (ind) {
      return true
    }
    else {
      return false;
    }
  }

  ///////////////change event forCase Sensitive Checkbox////////////////
  changeCaseSensitive(event) {
    this.search();
  }
  //////////////End Case Sensitive Checkbox Event/////////////////
  //////////////refill the tabledata array using the original array
  reset() {
    try {
      this.searchtext = "";
      this.searched = false;
      // console.log("this.tabledata -----------");

      this.tabledata = [];
      this.tabledata = _.cloneDeep(this.tabledatabackup);
      // this.updatedtabledata.emit(this.tabledata);
      this.updatedtabledataSubject.next(this.tabledata)

    }
    catch (ex) {
      throw ex;
    }
  }



  updateFreezeCSS(timeout: number = 0) {

    if (this.freezeColmsObj['isfrozen']) {
      this.showloader = true;
      let tempFreezeObj = _.cloneDeep(this.freezeColmsObj);
      this.resetFreeseObj();
      this.freezeColmsObj = _.cloneDeep(tempFreezeObj);
      setTimeout(() => {
        //previous working original code
        this.freezePanesOrColms(this.freezeColmsObj['details']['type'], this.freezeColmsObj['details']['rowindex'], this.freezeColmsObj['details']['colindex'] + 1);
      }, timeout);
    }
  }





  /*******************************Clear All applied filters*************************/

  clearFilter(evt?) {
    this.cellcontextobj = {};
    this.columnsearchtext = "";
    this.cellmenulist = [];
    this.searched = false;
    this.searchtext = "";

    // console.log("this.tabledata====");

    this.tabledata = [];
    this.filteroncell = false;
    this.tabledata = _.cloneDeep(this.tabledatabackup);
    // this.updatedtabledata.emit(this.tabledata);
    this.updatedtabledataSubject.next(this.tabledata)

    this.appliedFilters = [];
    this.cellmenulist = [];
    // console.log("----------------------------------------")
  //  console.log('clearFilter forEach 1');

    Object.keys(this.applfiltcolmsobj).forEach(key => {
      this.applfiltcolmsobj[key] = false;
    })
    // console.log("----------------------------------------")
    this.filteroncell = (this.appliedFilters.length ? true : false)

    evt = evt ? evt : this.selectedcellmenu;

    var header = evt['item'] ? evt['item']['header'] : evt['header'];
    var item = evt['item'] ? evt['item']['item'] : evt['row'];
    var rowindex = evt['item'] ? evt['item']['rowindex'] : evt['rowindex'];
    var colindex = evt['item'] ? evt['item']['colindex'] : evt['colindex'];

    var selectedtext = item[header['key']];

    let emitobj = {};
    emitobj['menu'] = { "label": "Clear Filter", "action": "clearfilter" };
    emitobj['header'] = header;

    emitobj['targetcolumn'] = header['key'];
    emitobj['row'] = item;
    emitobj['rowindex'] = rowindex;
    emitobj['colindex'] = colindex;
    emitobj['selectedtext'] = selectedtext;
    // console.log({ emitobj });

    this.contextEvents.emit(emitobj);
  }

  /*****************************************End clear all filters**********************/




  /******************************************Clear Column Level Filter**********************/
  clearColmFilter(evt?) {
    this.cellcontextobj = {};
    this.columnsearchtext = "";
    this.cellmenulist = [];
    this.searched = false;
    this.searchtext = "";
    // console.log("========his.tabledata ===========");

    this.tabledata = [];
    // this.filteroncell = false;
    this.tabledata = _.cloneDeep(this.tabledatabackup);
    // this.updatedtabledata.emit(this.tabledata);
    this.updatedtabledataSubject.next(this.tabledata)

    evt = evt ? evt : this.selectedcellmenu;

    var header = evt['item'] ? evt['item']['header'] : evt['header'];
    var item = evt['item'] ? evt['item']['item'] : evt['row'];
    var rowindex = evt['item'] ? evt['item']['rowindex'] : evt['rowindex'];
    var colindex = evt['item'] ? evt['item']['colindex'] : evt['colindex'];

    var selectedtext = item[header['key']];

    let emitobj = {};
    emitobj['menu'] = { "label": "Clear Filter", "action": "clearcolmfilter" };
    emitobj['header'] = header;

    emitobj['targetcolumn'] = header['key'];
    emitobj['row'] = item;
    emitobj['rowindex'] = rowindex;
    emitobj['colindex'] = colindex;
    emitobj['selectedtext'] = selectedtext;

    this.applfiltcolmsobj[header['key']] = false;

    var tempapplfilters: any[] = [];
    tempapplfilters = JSON.parse(JSON.stringify(this.appliedFilters));
  //  console.log("clearColmFilter normal for loop");

    for (let index = 0; index < this.appliedFilters.length; index++) {
      const element = this.appliedFilters[index];
      if (element['targetcolumn'] == header['key']) {
        tempapplfilters.splice(index, 1);
      }
    }

    this.appliedFilters = [];
    this.appliedFilters = JSON.parse(JSON.stringify(tempapplfilters));

    this.filteroncell = (this.appliedFilters.length ? true : false)
    // console.log({ emitobj });

    this.contextEvents.emit(emitobj);

    this.selectedcellmenu = emitobj;
    this.cellmenulist = [];

    this.addOrRemoveFilter("D")
    this.updateFreezeCSS(500);
  }


  // tableIns(ins) {
  // //  console.log(ins);
  // //  console.log(ins['setPage'](2, 50));
  // }
  // changeSearchText(enteredvalue){
  //   if(enteredvalue){
  //     this.searchSubject.next(enteredvalue);
  //   }else{
  //     this.searchString(enteredvalue);
  //   }
  // }

  changeSearchText(enteredvalue) {

    this.searchtext = enteredvalue;

    if (this.searchtext.trim().length > 0) {
      clearTimeout(this.searchtimer);
      this.searchtimer = setTimeout(() => {
        this.search();
      }, 500);
    }
    if (this.searchtext.trim().length == 0) {
      {
        if (this.filteroncell) {
          // console.log("=========filteroncell========");
          this.tabledata = [];
          setTimeout(() => {
            this.addOrRemoveFilter("D");
          }, 100);

          // this.tabledata = JSON.parse(JSON.stringify(this.TempSearchArr));
        }
        else {
          // console.log("=========else {========");
          this.tabledata = [];
          // console.log("tabledatabackup ", this.tabledatabackup);

          this.tabledata = _.cloneDeep(this.tabledatabackup);
          // console.log("tabledata ", this.tabledata);

          this.updatedtabledata.emit(this.tabledata);
          // this.updatedtabledataSubject.next(this.tabledata)
        }
        setTimeout(() => {
          if (Object.keys(this.consolidatedetails).length) {
            if (this.consolidatedetails['consolidation']) {
              this.consolidatedetails['consolidation'] = false;
            }
            this.toggleView();
          }

        }, 300);
      }
    }
  }


  changeColumnSearchText(enteredvalue, fromcontextmenu: boolean = false) {
    this.columnsearchtext = enteredvalue;

    if (this.columnsearchtext.trim().length > 0) {
      this.search(fromcontextmenu);
    }
    if (this.columnsearchtext.trim().length == 0) {
      {
        // console.log("=========changeColumnSearchText========");
        this.tabledata = [];
        this.tabledata = this.tabledatabackup;
        // this.updatedtabledata.emit(this.tabledata);
        this.updatedtabledataSubject.next(this.tabledata)

      }
    }
  }
  //////////////////Get Checked Records ifthere is checkbox column///////////////
  getCheckedRecords1(CSVflag) {
    this.showloader = true;
    let checkedrecordsarr: any[] = [];
  //  console.log("getCheckedRecords1 filter");

    checkedrecordsarr = this.tabledata.filter(row => {
      return row['checked'];
    });
    this.showloader = false;
    this.checkedRecords.emit(checkedrecordsarr);
    if (CSVflag) {
      // this.yes_ExportToCSV(checkedrecordsarr)
    }
  }
  getCheckedRecords(CSVflag) {
    // console.log("getCheckedRecords 1", JSON.parse(JSON.stringify(this.tabledata)), JSON.parse(JSON.stringify(this.tabledatabackup)));

    this.addOrRemoveFilter("D");
    this.showloader = true;
    let checkedrecordsarr: any[] = [];
    // console.log("getCheckedRecords 2", JSON.parse(JSON.stringify(this.tabledata)), JSON.parse(JSON.stringify(this.tabledatabackup)));

    const tabledata = this.searchtext.trim().length == 0 && !this.appliedFilters.length ? this.tabledata:this.tabledatabackup;
    // const tabledata = this.tabledata;
    if (this.tableconfig['checkedrecordscondition']['ischeckcondition']) {
      let formulaAppledArray: any[] = this.evalTheCheckedRecordsWithFormula(tabledata);

    }
    else {
      // console.log(this.tabledatabackup, this.tabledata);
      // console.log("ELSE");
    //  console.log("getCheckedRecords filter");

      checkedrecordsarr = tabledata.filter(row => {
        return row['checked'];
      });
      this.checkedRecords.emit(checkedrecordsarr);
      this.showloader = false;
    }
    if (CSVflag) {
      // this.yes_ExportToCSV(checkedrecordsarr)
    }
  }

  evalTheCheckedRecordsWithFormula(checkedRowsArray: any[]) {
    checkedRowsArray = JSON.parse(JSON.stringify(checkedRowsArray));
    // console.log('checkedRowsArray', JSON.stringify(checkedRowsArray));
    let selectedFieldsArray = this.tableconfig['checkedrecordscondition']['selectedFieldsArray'];
    //  [
    //   {
    //     key: 'ModelNo',
    //     condition: ">=15"
    //   },
    //   {
    //     key: 'subAssemblySrNo',
    //     condition: "<40"
    //   }
    // ];
    const conditionBetweenTwoFields = this.tableconfig['checkedrecordscondition']['conditionBetweenTwoFields']//and,or'
    // console.log({ selectedFieldsArray, conditionBetweenTwoFields });
  //  console.log("evalTheCheckedRecordsWithFormula filter");

    let formulaAppledArray: any[] = checkedRowsArray.filter((row) => {
      // let flag = false;
      delete row['autoid'];
      delete row['rowindex'];
      delete row['actualindex'];
      delete row['colindex'];
      if (row['actioncolumn'])
        delete row['actioncolumn'];
      if (row['keyName'])
        delete row['keyName'];

      if (row[this.valuebeforecommit['keyName'] + "ctrl"])
        delete row[this.valuebeforecommit['keyName'] + "ctrl"];
      let condition = '';
      // console.log(selectedFieldsArray);
    //  console.log("evalTheCheckedRecordsWithFormula forEach");
      
      selectedFieldsArray.forEach((field, index) => {

        if (!index && row[field['key']]) {
          condition += `${row[field['key']]} ${field['condition']}`
        } else if (row[field['key']]) {
          const condotionAndOr = conditionBetweenTwoFields == 'and' ? '&&' : '||';
          condition += ` ${condotionAndOr} ${row[field['key']]} ${field['condition']}`
        }

      });
      // console.log('condition', condition, eval(condition));
      if (condition){

      //  console.log("evalTheCheckedRecordsWithFormula eval");
        
        return eval(condition);
      }
      else{

        return false;
      } 
      // return false;

    });
    // console.log('checkedRowsArray', checkedRowsArray?.length, 'formulaAppledArray', formulaAppledArray?.length);
    // console.log('checkedRowsArray', checkedRowsArray, 'formulaAppledArray', formulaAppledArray);
    if (formulaAppledArray?.length) {
      // console.log({ formulaAppledArray });

      // console.log('this.checkedRecords.emit');

      this.checkedRecords.emit(formulaAppledArray);
    }
    else {
      //show Message
      // console.log();
      this.displayQuantityConfpopup = true;

    }
    this.showloader = false;
    return formulaAppledArray;
  }
  /////////////////End Get Checked Records//////////////////////////

  /////////////////////Below method will be execute ifuse has a permission to select the menu on right click
  selectHeaderContextMenu(inputevent, option) {
    // debugger;
    // console.log(inputevent);


    if (this.consolidatedetails['consolidation'] && (option['action'] == "hidecolumn" || option['action'] == "hidecolumn")) {
      this.showToastMsg("Hide columns are not allowed after aggregation", false);
      return;
    }

    this.dismissCellFocus();

    if (option['action'] == "hidecolumn" && inputevent['item']['header']['enablehide']) {

      if (this.freezeColmsObj.isfrozen) {
        this.showToastMsg("Please Unfreeze column before hide", false, 5000);
        return;
      }

      this.tableheaders[inputevent['item']['index']]['indx'] = inputevent['item']['index'];
    //  console.log(this.tableheaders[inputevent['item']['index']]['indx']);
      
      this.hiddencolumns.push(this.tableheaders[inputevent['item']['index']]);
      this.tableheaders[inputevent['item']['index']]['visiblecolm'] = false;
      this.tableheaders[inputevent['item']['index']]['colhiddenby'] = "User";
      this.hidecolumnaction = true;
      this.colspancount--;
      this.setvisibleHiddenFlagOfMenuList(true);///visible flag of displayhiddencols will be true
    }
    else if (option['action'] == "displayhiddencols") {
      this.selectedcolumn = "";
      this.displayhiddencolspopup = true;
    }
    else if (option['action'] == "specificsearch") {
      if (inputevent['item']['header']['key'] == "") {
        this.showSuccOrErrMsg("Invalid Action", "You can not search on this column.", true);
        return;
      }
      this.columnsearchobj.searchingindex = inputevent['item']['index'];
      this.columnsearchobj.header = inputevent['item']['header'];
      this.setFilterFlagOfHeaders(inputevent['item']['header']['key']);
      this.disabledmainsearch = true;
    }
    else if (option['action'] == "aggregation") {

      if (this.freezeColmsObj.isfrozen) {
        this.showToastMsg("Please Unfreeze all columns and apply aggregation", false, 5000);
        return;
      }
      this.searchtext = "";
      this.columnsearchtext = "";
      this.appliedFilters = [];
      this.applfiltcolmsobj = {};
      this.resetFlagOfColumns();
      this.filteroncell = false;

      if (Object.keys(this.consolidatedetails).length) {
        this.tableheaders[0]['consolidation'] = false;
      }

      this.showloader = true;

      inputevent['item']['header']['headerindx'] = inputevent['item']['index'];

      this.tableheaders[inputevent['item']['index']]['consolidation'] = true;

      this.prepareConsolidation(inputevent['item']['header']);
      this.setResizeAndDragDrop(false);

      setTimeout(() => {
        this.showloader = false;
      }, 1000);
    }
    else if (option['action'] == "removeaggregation") {
      this.consolidatedetails = {};
      this.tableheaders = [];
      this.tableheaders = _.cloneDeep(this.tableheaderbackup);
      this.tabledata = [];
      this.tabledata = _.cloneDeep(this.tabledatabackup);
      this.resetFreeseObj();
      this.prepareAppliedFilterObj();
      setTimeout(() => {
        this.calNoOfPages();
        this.presentpageno = 1;
        this.currentpageno = 1;
        this.tableRefvar['setPage'](1, this.tabledata.length + 10);
        this.setResizeAndDragDrop(true);
      }, 200);
    }
    else if (option['action'] == "freezecolumn") {

      if (Object.keys(this.consolidatedetails).length && inputevent['item']['index'] > 0) {
        this.showToastMsg("Only first column can be freeze after aggregation ", false);
        return;
      }

      if (this.getValidFreezeColmsCount(inputevent['item']['index']) > this.tableconfig['stickycolumns']['maxlimit']) {
        this.showToastMsg("Only upto first " + this.tableconfig['stickycolumns']['maxlimit'] + " column" + (this.tableconfig['stickycolumns']['maxlimit'] > 1 ? "s" : "") + " can be freeze", false, 5000);
        return;
      }
      this.resetFrozenFlags();
      this.updateFreezeHeader(inputevent['item']['index'], false);
      this.tableheaders[inputevent['item']['index']]['isfrozen'] = true;
      this.freezePanesOrColms("columns", -1, inputevent['item']['index'] + 1);
    }

    else if (option['action'] == "unfreeze") {

      let latestcolmpos = inputevent['item']['index'];// latest position of column ifprevious columns are hidden and unfreezing the columns whose actually on first position then unfreeze all previous columns

      if (this.hiddencolumns.length) {/// ifcolumns are hidden from the current index
  //  console.log("selectHeaderContextMenu normal for loop");

        for (let index = 0; index < inputevent['item']['index']; index++) {
          if (!this.tableheaders[index]['visiblecolm']) {
            latestcolmpos -= 1;
          }
        }
      }


      this.resetFrozenFlags();

      this.updateFreezeHeader(inputevent['item']['index'], true);
      this.tableheaders[inputevent['item']['index']]['isfrozen'] = false;
      this.removeFreezing();
      this.freezePanesOrColms("columns", -1, inputevent['item']['index']);
      if (inputevent['item']['index'] == 0 || latestcolmpos == 0) {
        this.resetFreeseObj();
      }
    }
    else {

      if (option['action'] == "showsum" || option['action'] == "hidesum") {
        this.footerValObj['sum_show'] = !this.footerValObj['sum_show'];
        this.updateSummaryFlag("sum");
      }
      else if (option['action'] == "showaverage" || option['action'] == "hideaverage") {
        this.footerValObj['average_show'] = !this.footerValObj['average_show'];
        this.updateSummaryFlag("average");
      }
      else if (option['action'] == "showmin" || option['action'] == "hidemin") {
        this.footerValObj['min_show'] = !this.footerValObj['min_show'];
        this.updateSummaryFlag("min");
      }
      else if (option['action'] == "showmax" || option['action'] == "hidemax") {
        this.footerValObj['max_show'] = !this.footerValObj['max_show'];
        this.updateSummaryFlag("max");
      }
    }
  }



  getValidFreezeColmsCount(colindex): number {
    let validFreezeCount = 0;
  //  console.log("getValidFreezeColmsCount normal for loop");

    for (let index = 0; index <= colindex; index++) {
      validFreezeCount += (this.tableheaders[index]['visiblecolm'] ? 1 : 0);
    }
    return validFreezeCount;
  }

  updateSummaryFlag(calctype: string) {
  //  console.log("updateSummaryFlag forEach");

    this.footerValObj['caltypes'].forEach((caltype) => {
      if (caltype['value'] == calctype) {
        caltype['show'] = !caltype['show'];
      }
    });

    this.tableconfig['showsummary'] = !(this.getHiddenSummaryCount() == this.footerValObj['caltypes'].length);

    setTimeout(() => {
      this.setFooterStyle();
    }, 50);
  }



  resetFreeseObj() {
    this.freezeColmsObj.details = {};
    this.freezeColmsObj.isfrozen = false;
    this.setResizeAndDragDrop(true);
    this.unfreezeAllColms();
  }

  resetFrozenFlags() {
  //  console.log("resetFrozenFlags normal for loop");

    for (let index = 0; index < this.tableheaders.length; index++) {
      this.tableheaders[index]['isfrozen'] = false;
    }
  }


  unfreezeAllColms(evt?) {
    this.removeFreezing();
    this.resetFrozenFlags();
  }

  removeFreezing(evt?) {
    this.freezeColmsObj.isfrozen = false;
    this.freezeColmsObj.details = {};
    const elem = document.getElementById("stickyColmsStyles");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
    this.setResizeAndDragDrop(true);
  }

  updateFreezeHeader(colindex: number, isUnfreeze: boolean) {
  //  console.log("updateFreezeHeader normal for loop 1");

    for (let index = 0; index < this.tableheaders.length; index++) {
      this.tableheaders[index]['isfrozen'] = false;
    }
  //  console.log("updateFreezeHeader normal for loop 2");

    for (let index = 0; index <= colindex; index++) {
      this.tableheaders[index]['isfrozen'] = true;
    }
  }



  /**
   * Before apply consolidation/aggregation to any column make sure that the filter,sort flags are false
   */
  resetFlagOfColumns() {
  //  console.log("resetFlagOfColumns forEach");

    this.tableheaders.forEach((header, index) => {
      delete header['ascending'];
    })
  }


  prepareConsolidation(header) {

    this.cellstyle = { "tdkeyname": "" };

    const groupedData = this.groupByMethod(_.cloneDeep(this.tabledatabackup), (item => item[header['key']]), "", header['datatype'] == "date");

    let sortedData = [];
  //  console.log("prepareConsolidation forEach");

    groupedData.forEach((data, value) => {
      if (header['datatype'] == "date") {
        value = new Date(value);
        value = new Date((value as Date).setHours(0, 0, 0, 0));
      }
      sortedData.push({ "value": value, "data": data });
    })

    switch (header['datatype']) {
      case "string":
      case "dropdown":
      case "number":
        sortedData.sort((a, b) => {
          return a['value'] < b['value'] ? -1 : 1;
        });
        break;
      case "date":
        sortedData.sort((a, b) => {
          return new Date(a['value']) < new Date(b['value']) ? -1 : 1;
        });
        break;
    }

    this.moveArrElement(this.tableheaders, header['headerindx'], 0);

    this.tabledata = [];

    this.consolidatedetails = {};

    this.consolidatedetails = {
      "groupedData": sortedData,
      "isexpand": false,
      "consolidation": true,
      "header": header
    };
    this.setExpandOrShrinkRows(sortedData, this.consolidatedetails['isexpand'], this.consolidatedetails['header'], true);
  }

  /**Expand All Or Shrink All */

  expandOrShinkAll() {

    this.showloader = true;

    this.consolidatedetails['isexpand'] = !this.consolidatedetails['isexpand'];

    this.setExpandOrShrinkRows(this.consolidatedetails['groupedData'], this.consolidatedetails['isexpand'], this.consolidatedetails['header']);

    setTimeout(() => {
      this.updateFreezeCSS(20);
      this.showloader = false;
    }, 1000);
  }

  /**End Wxpand All or Shrink All */

  /**Reusable method forexand and shrink rows*/

  setExpandOrShrinkRows(groupedData, isExpand, header, isFirstTime = false) {

    //isExpand==true//ifexpanded ELSE shrink, do not consider the remaining records except 1st.

    var grpIndx = 0;
    let tableDt = [];
    this.tabledata = [];
  //  console.log("setExpandOrShrinkRows forEach");

    groupedData.forEach((valuesArr, key, map) => {

      let dataArr = [];

      dataArr = valuesArr['data'];

      grpIndx++;

      let evenOddClass = grpIndx % 2 == 0 ? " even-grp" : " odd-grp";

      dataArr[0][header['key'] + 'rsp'] = isExpand ? dataArr.length : 1;
      dataArr[0][header['key'] + 'exp'] = isExpand;
      dataArr[0]['grpIndx'] = grpIndx;
      dataArr[0]['showIcons'] = dataArr.length > 0;
      if (isFirstTime && header['datatype'] !== "date") {
        dataArr[0][header['key']] += " (" + dataArr.length + ")";
      }
      else if (isFirstTime && header['datatype'] == "date") {
        dataArr[0][header['key'] + "lbl"] = "(" + dataArr.length + ")";
      }
      dataArr[0]['groupClass'] = evenOddClass;
      if (isExpand) {
  //  console.log("setExpandOrShrinkRows normal for loop");

        for (let index = 0; index < dataArr.length; index++) {
          if (index > 0) {
            dataArr[index][header['key']] = "";
            dataArr[index][header['key'] + 'hide'] = true;
          }
          dataArr[index]['groupClass'] = evenOddClass;
          dataArr[index]['grpIndx'] = grpIndx;
          tableDt.push(dataArr[index]);
        }
      }
      else {
        tableDt.push(dataArr[0]);
      }
    });

    this.tabledata = [];
    this.tabledata = tableDt;
    // setTimeout(() => {
    this.tableRefvar['setPage'](1, this.tabledata.length + 10);
    // }, 200);
  }

  /**End expand and shrink record method  */

  /**Below method will set the expand flag and It will show */
  expandOrShrinkRow(header, item, rowindex) {

    item[header['key'] + 'exp'] = !item[header['key'] + 'exp'];
  //  console.log("expandOrShrinkRow findIndex");
    
    let recIndx = this.tabledata.findIndex(row => row['autoid'] == item['autoid']);
    let deleteCount = this.consolidatedetails['groupedData'][item['grpIndx'] - 1]['data'].length;
    let dataArr: any[] = this.consolidatedetails['groupedData'][item['grpIndx'] - 1]['data'];

    if (item[header['key'] + 'exp']) {

      this.tabledata[recIndx][header['key'] + 'rsp'] = deleteCount;
    //  console.log("expandOrShrinkRow normal for loop");

      for (let index = 1; index < dataArr.length; index++) {
        let insertAtIndx = recIndx + index;
        this.tabledata.splice(insertAtIndx, 0, dataArr[index]);
        this.tabledata[insertAtIndx][header['key'] + 'hide'] = true;
        this.tabledata[insertAtIndx]['groupClass'] = this.tabledata[recIndx]['groupClass'];
      }
      // this.setExpandOrShrinkRows(this.consolidatedetails['groupedData'], item[header['key'] + 'exp'], header);
    }
    else {
      this.tabledata[recIndx][header['key'] + 'rsp'] = 1;
      this.tabledata.splice(recIndx + 1, deleteCount - 1);
    }

    this.tableRefvar['setPage'](1, this.tabledata.length + 10);
    this.updateFreezeCSS(20);
  }

  /**Method will show tha normal table view and Consolidated view which was selected by the user using the header context menu */

  toggleView() {



    try {
      this.showloader = true;

      this.consolidatedetails['consolidation'] = !this.consolidatedetails['consolidation'];

      this.appliedFilters = [];
      this.applfiltcolmsobj = {};
      this.resetFlagOfColumns();
      this.filteroncell = false;
      this.searchtext = "";
      this.columnsearchtext = "";

      if (this.consolidatedetails['consolidation']) {
  //  console.log("toggleView findIndex");

        let headerIndx = this.tableheaders.findIndex(row => row['key'] == this.consolidatedetails['header']['key'])
        this.consolidatedetails['header']['headerindx'] = headerIndx;
        this.tableheaders[headerIndx]['consolidation'] = true;
        this.prepareConsolidation(this.consolidatedetails['header']);
      }
      else {
        this.tableheaders = [];

        this.tableheaders = _.cloneDeep(this.tableheaderbackup);
        this.tabledata = [];
        this.tabledata = _.cloneDeep(this.tabledatabackup);
        this.prepareAppliedFilterObj();
        setTimeout(() => {
          this.calNoOfPages();
          this.presentpageno = 1;
          this.currentpageno = 1;
          this.tableRefvar['setPage'](1, this.tabledata.length + 10);
        }, 50);
      }
      this.showloader = false;
    }
    catch (error) {
      this.showloader = false;
      console.error(error);
    }



  }
  /**End toggle View method */

  /**
   * Below method will help to change the array element position
   */
  moveArrElement(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // fortesting
  };

  /**
   * End Array Element position
   */


  setFilterFlagOfHeaders(key, specificcolumnsearch: boolean = true) {
  //  console.log("setFilterFlagOfHeaders normal for loop");

    for (let i = 0; i < this.tableheaders.length; i++) {
      if (specificcolumnsearch && this.tableheaders[i]['key'] != key) {
        this.tableheaders[i]['filter'] = false;
      }
      else {
        this.tableheaders[i]['filter'] = true;
      }
    }
  }

  /////////cancelColumnSearch()
  cancelColumnSearch() {
    this.disabledmainsearch = false;
    this.columnsearchtext = "";
    this.columnsearchobj.searchingindex = -1;
    this.columnsearchobj.header = {};
    this.setFilterFlagOfHeaders("", false);
    this.search();
  }

  ///below code will be execute ifuser hide any column OR Display Reset All the columns
  setvisibleHiddenFlagOfMenuList(Flag: boolean) {
  //  console.log("setvisibleHiddenFlagOfMenuList normal for loop");

    for (let menuindx = 0; menuindx < this.tableconfig['headermenulist'].length; menuindx++) {
      if (this.tableconfig['headermenulist'][menuindx]['action'] == "displayhiddencols") {
        this.tableconfig['headermenulist'][menuindx]['visible'] = Flag;
      }
    }
  }

  //////////////////////on drop method start////////////////
  // dragDropHeaders(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.tableheaders, event.previousIndex, event.currentIndex);
  // }
  // dropRow(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.tabledata, event.previousIndex, event.currentIndex);
  //   // this.setTableRowIndx();
  // }
  /////////////////////end ondrop  method////////////
  //////ifuser has a permission to hide the columns then below method will be execute and display him the context menu other wise nothing  will be happen
  onHeaderContextMenu($ievent, header, colindex) {
    // console.log('colindex', colindex);

    if (this.tableconfig['resizable']['rows'] || (this.tableconfig['summary'] && this.tableconfig['summary'].length)) {
      colindex = colindex + 1;
    }
    if (this.tableconfig['headercontextmenu']) {
    //  console.log("onHeaderContextMenu normal for loop");

      for (let menuindx = 0; menuindx < this.tableconfig['headermenulist'].length; menuindx++) {
        if (this.tableconfig['headermenulist'][menuindx]['action'] == 'hidecolumn') {
          this.tableconfig['headermenulist'][menuindx]['visible'] = header['enablehide'];
        }
        else if (this.tableconfig['headermenulist'][menuindx]['action'] == 'aggregation') {
          this.tableconfig['headermenulist'][menuindx]['visible'] = header['allowaggregation'] ? true : false;
          if (Object.keys(this.consolidatedetails).length && header['key'] === this.consolidatedetails['header']['key']) {
            this.tableconfig['headermenulist'][menuindx]['visible'] = false;
          }
        }
        else if (this.tableconfig['headermenulist'][menuindx]['action'] == 'removeaggregation') {
          this.tableconfig['headermenulist'][menuindx]['visible'] = header['consolidation'] ? true : false;
        }
        else if (this.tableconfig['headermenulist'][menuindx]['action'] == 'freezecolumn') {
          this.tableconfig['headermenulist'][menuindx]['visible'] = header['isfrozen'] ? false : true;
          /**
           * ifFreeze panes are applied the do not show the freeze column option in the header context menu.
           */
          if (this.freezeColmsObj['isfrozen'] && Object.keys(this.freezeColmsObj['details']).length && this.freezeColmsObj['details']['type'] == "panes") {
            this.tableconfig['headermenulist'][menuindx]['visible'] = false;
          }
          if (!this.tableconfig['enablefreezecolumns']) {
            this.tableconfig['headermenulist'][menuindx]['visible'] = false;
          }
        }
        else if (this.tableconfig['headermenulist'][menuindx]['action'] == 'unfreeze') {
          this.tableconfig['headermenulist'][menuindx]['visible'] = header['isfrozen'] ? true : false;
        }
        // else if(this.summaryHeaderMenu[0]['action'] == "") {
        if (this.tableconfig['summary'] && this.tableconfig['summary'].length && this.tableconfig['headermenulist'][menuindx]['type']) {

          this.tableconfig['headermenulist'][menuindx]['visible'] = false;

          if ((this.tableconfig['summary'] as string[]).indexOf(this.tableconfig['headermenulist'][menuindx]['type']) >= 0) {

            let actionname: string = this.tableconfig['headermenulist'][menuindx]['action'];

            switch (actionname) {

              case "showsum":
              case "hidesum":

                if (actionname == "showsum") {
                  this.tableconfig['headermenulist'][menuindx]['visible'] = this.footerValObj["sum_show"] ? false : true;
                }
                else {
                  this.tableconfig['headermenulist'][menuindx]['visible'] = this.footerValObj["sum_show"] ? true : false;
                }
                break;

              case "showaverage":
              case "hideaverage":
                if (actionname == "showaverage") {
                  this.tableconfig['headermenulist'][menuindx]['visible'] = this.footerValObj["average_show"] ? false : true;
                }
                else {
                  this.tableconfig['headermenulist'][menuindx]['visible'] = this.footerValObj["average_show"] ? true : false;
                }
                break;

              case "showmin":
              case "hidemin":
                if (actionname == "showmin") {
                  this.tableconfig['headermenulist'][menuindx]['visible'] = this.footerValObj["min_show"] ? false : true;
                }
                else {
                  this.tableconfig['headermenulist'][menuindx]['visible'] = this.footerValObj["min_show"] ? true : false;
                }
                break;

              case "showmax":
              case "hidemax":
                if (actionname == "showmax") {
                  this.tableconfig['headermenulist'][menuindx]['visible'] = this.footerValObj["max_show"] ? false : true;
                }
                else {
                  this.tableconfig['headermenulist'][menuindx]['visible'] = this.footerValObj["max_show"] ? true : false;
                }
                break;
            }
          }

        }
        //showsum,hidesum,showaverage,hideaverage,showmin,hidemin,showmax,hidemax
        // }
      }

      this.tableconfig['headermenulist'].sort((a, b) => {
        return a['label'] < b['label'] ? -1 : 1;
      });

      let actionobj = {
        "header": header,
        "index": colindex
      }
      setTimeout(() => {
        this.contextMenuService.show.next({
          anchorElement: $ievent.target,
          // Optional - ifunspecified, all context menu components will open
          contextMenu: this.headerMenu,
          event: <any>$ievent,
          item: actionobj,
        });
        $ievent.preventDefault();
        $ievent.stopPropagation();
      }, 10);
      /////set action object forhide acion
    }
  }
  ///////after taken an action OR Hide the column using the context menu user can reset the columns

  /*******************************************************row/Cell context menu*******************/


  // setContextMenuClass() {
  //   var classname = 'ngx-custom-style' + (!this.hidecellcontextmenu ? ' ' : ' hide-context-menu ');
  // //  console.log("classname : ", classname);
  //   return classname;
  // }

  onCellContextMenu($ievent, header, item, rowindex, colindex) {
    // console.log("preventDefault", this.cellcontextmenu, this.headerMenu);

    $ievent.preventDefault();

    if (!this.tableconfig['cellcontextmenu'] || !header['menulist'].length) {
      if (this.cellcontextmenu) {
        this.contextMenuService.closeAllContextMenus({ event: null, eventType: "cancel" });
        this.cellcontextmenu.visibleMenuItems.length = 0;
      }
      this.setFreezePanesMenus($ievent, header, item, rowindex, colindex);
      return;
    };

    var datatype: string = "";
    this.cellmenulist = [];
    this.cellcontextobj = {};
    this.cellcontextobj['item'] = JSON.parse(JSON.stringify(item));
    this.cellcontextobj['header'] = JSON.parse(JSON.stringify(header));
    this.cellcontextobj['text'] = item[header['key']];

    this.cellmenulist = header['menulist'];
    var value = "";
  //  console.log("onCellContextMenu normal for loop 1");

    for (let index = 0; index < this.cellmenulist.length; index++) {
      const menu = this.cellmenulist[index];
      this.cellmenulist[index]["datatype"] = header['datatype'];
      this.cellmenulist[index]["targetcolumn"] = header['key'];
      value = header['datatype'] == "date" ? this.datepipe.transform(new Date(item[header['key']]), this.dateformat) : item[header['key']];

      if (menu['action'] == "filter" || menu['action'] == "view" || menu['showcelltext']) {
        this.cellmenulist[index]['text'] = header['datatype'] == "date" ? this.datepipe.transform(new Date(item[header['key']]), this.dateformat) : item[header['key']];
      }

      if (menu['rowlevelcolm'] != undefined && menu['rowlevelcolm'].trim().length > 0) {
  //  console.log("onCellContextMenu normal for loop 2");
        
        for (let i = 0; i < this.tableheaders.length; i++) {
          if (this.tableheaders[i]['key'] == this.cellmenulist[index]['rowlevelcolm']) {
            this.cellmenulist[index]["datatype"] = this.tableheaders[i]['datatype'];
            this.cellmenulist[index]["targetcolumn"] = this.cellmenulist[index]['rowlevelcolm'];
            this.cellmenulist[index]['text'] = (datatype == "date" ? this.datepipe.transform(new Date(item[this.cellmenulist[index]['rowlevelcolm']]), this.dateformat) : item[this.cellmenulist[index]['rowlevelcolm']]);
            value = this.cellmenulist[index]['text'];
          }
        }
      }

      if (menu['action'] == "between") {
        this.cellmenulist[index]['from'] = undefined;
        this.cellmenulist[index]['to'] = undefined;
      }
    }

    if ((header['setCellColourFlag'] && header['setCellColourFlag'] == 'cell')
      && (header['datatype'] == 'number' || header['datatype'] == 'date')) {
      if (this.cellmenulist[this.cellmenulist.length - 1]['action'] != 'setCellColourFlag')
        this.cellmenulist[this.cellmenulist.length] = { label: 'Highlights Cells', text: header['display'], action: 'setCellColourFlag', datatype: 'number', targetcolumn: header['key'] };
      this.setCellColourFlagObj['condition'] = '';
      this.setCellColourFlagObj['min'] = '';
      this.setCellColourFlagObj['max'] = '';
      this.setCellColourFlagObj['text'] = header['display'];
      this.setCellColourFlagObj['datatype'] = header['datatype'];
      // console.log(item[header['key']],this.datepipe.transform(new Date(item[header['key']]), this.dateformat));
      // console.log(this.compareTwoDates(item[header['key']], item[header['key']],'lessthan'));

    }
    // console.log('cellmenulist', this.cellmenulist);
    this.setFreezePanesMenus($ievent, header, item, rowindex, colindex)
    // }, 500);
  }


  /*********************************************End row/cell contaxt menu***************************/


  /***Set freeze Menus */

  setFreezePanesMenus($ievent, header, item, rowindex, colindex) {

    if (this.tableconfig['enablefreezepanes'] && this.freezeColmsObj['isfrozen'] && this.freezeColmsObj['details']['type'] == "panes") {
  //  console.log("setFreezePanesMenus normal for loop");

      for (let index = 0; index < this.freezeColmMenus.length; index++) {
        this.freezeColmMenus[index]['visible'] = false;
        if (this.freezeColmMenus[index]['action'] == "unfreezepanes") {
          this.freezeColmMenus[index]['visible'] = true;
        }
      }
    }
    else {
      // if((colindex + 1) <= this.tableconfig['stickycolumns']['maxlimit']) {
      this.resetFreezePanesArr();
      // }
    }
    setTimeout(() => {
      this.contextMenuService.show.next({
        anchorElement: $ievent.target,
        // Optional - ifunspecified, all context menu components will open
        contextMenu: this.cellcontextmenu,
        event: <any>$ievent,
        item: { "header": header, "item": item, "rowindex": rowindex, "colindex": colindex },
      });
      $ievent.preventDefault();
      $ievent.stopPropagation();
    }, 50);
    // this.gtPlusDataSrvc.gtplusCellmenulist = JSON.parse(JSON.stringify(this.cellmenulist))
    // console.log($ievent,'setFreezePanesMenus','cellmenulist',this.cellmenulist);
  }


  /**End  */

  /************************This method will prepare the object of selected menu and all required data forcontext menu emitter***************/

  prepareCellMenuData(evt, menuitem) {
    var header = evt['item']['header'];
    var item = evt['item']['item'];
    var rowindex = evt['item']['rowindex'];
    var colindex = evt['item']['colindex'];
    var selectedtext = item[header['key']];

    let emitobj = {};
    emitobj['menu'] = menuitem;
    emitobj['header'] = header;

    emitobj['targetcolumn'] = (menuitem['rowlevelcolm'] != undefined && menuitem['rowlevelcolm'].trim().length) ? menuitem['rowlevelcolm'] : header['key'];
    emitobj['row'] = item;
    emitobj['rowindex'] = rowindex;
    emitobj['colindex'] = colindex;
    emitobj['selectedtext'] = selectedtext;
    return emitobj;/// Returning Object including menu item
  }

  /************************************************End Context menu data preparation foremitter***************/


  /**************************Execute following code on select cell menu***********************/

  selectCellContextMenu(evt, menuitem) {
  //  console.log('selectCellContextMenu', evt, menuitem);
    if (menuitem['action'] == 'setCellColourFlag') {
      // console.log('selectCellContextMenu',menuitem);
      this.openPopupForSelectOptionForSetCellColourFlag(menuitem);
      return;
    }
    if (Object.keys(this.consolidatedetails).length && this.consolidatedetails['consolidation']) {
      if (menuitem['action'] == "filter" || menuitem['action'] == "notin" || menuitem['action'] == "between"
        || menuitem['action'] == "lessthan" || menuitem['action'] == "greaterthan") {
        this.showToastMsg("Filter does not allowed after aggregation", false);
        return;
      }
    }

    if (menuitem['action'] == "between" && !menuitem['from'] && !menuitem['to']) {
      this.showSuccOrErrMsg("Invalid Inputs", "Please give valid inputs to see an expected result.", true);
      this.contextMenuService.closeAllContextMenus({ event: null, eventType: "cancel" })
      if (this.cellcontextmenu) {
        this.cellcontextmenu.visibleMenuItems.length = 0;
      }
      return;
    }
    else if ((menuitem['action'] == "filter" || menuitem['action'] == "notin") && !menuitem['text']) {
      this.showSuccOrErrMsg("Invalid Inputs", "Please provide valid input", true);
      this.contextMenuService.closeAllContextMenus({ event: null, eventType: "cancel" })
      if (this.cellcontextmenu) {
        this.cellcontextmenu.visibleMenuItems.length = 0;
      }
      return;
    }
    else if (menuitem['action'] == "between" && menuitem['from'] != undefined && menuitem['to'] != undefined) {
      if (menuitem['from'] > menuitem['to']) {
        let isnumber = menuitem['datatype'] == "number";
        let msg = isnumber ? "Max value can not be less than or equal to the Min value, Please check it." : "Max Date can not be less than Min Date.";

        this.showSuccOrErrMsg("Invalid Inputs", msg, true);
        this.contextMenuService.closeAllContextMenus({ event: null, eventType: "cancel" });
        if (this.cellcontextmenu) {
          this.cellcontextmenu.visibleMenuItems.length = 0;
        }
        return;
      }
    }

    var header = evt['item']['header'];
    var item = evt['item']['item'];
    var rowindex = evt['item']['rowindex'];
    var colindex = evt['item']['colindex'];
    var selectedtext = item[header['key']];

    this.cellcontextobj['action'] = menuitem['action'];
    let emitobj = {};
    emitobj['menu'] = menuitem;
    emitobj['header'] = header;

    emitobj['targetcolumn'] = (menuitem['rowlevelcolm'] != undefined && menuitem['rowlevelcolm'].trim().length) ? menuitem['rowlevelcolm'] : header['key'];

    emitobj['row'] = item;
    emitobj['rowindex'] = rowindex;
    emitobj['colindex'] = colindex;
    emitobj['selectedtext'] = (menuitem['action'] == "between" ? "" : selectedtext);


    switch (menuitem['action']) {
      case "lessthan":
      case "greaterthan":
      case "notin":
      case "between":
      case "filter":

        this.dismissCellFocus();// dismiss the cell focus

        var filterobj = {};
        var filterType: string = "I";//Default is Insert and ifuser filtering by the Range filter then type will be "D".

        // if(menuitem['action'] == "filter" || menuitem['action'] == "notin" || menuitem['action'] == "between") {
        //   this.applfiltcolmsobj[emitobj['targetcolumn']] = true;
        // }

        filterobj = {
          "action": menuitem['action'],
          "targetcolumn": emitobj['targetcolumn'],
          "selectedtext": selectedtext,
          "row": item,
          "datatype": menuitem["datatype"]
        };
        if (menuitem['action'] == "between" && menuitem['from'] == undefined || menuitem['from'] == "") {
          menuitem['from'] = 0;
        }
        if (menuitem['action'] == "between" && menuitem['to'] == undefined || menuitem['to'] == "") {
          menuitem['to'] = 0;
        }
        if (menuitem['action'] == "between" && menuitem['from'] && menuitem['from'].toString().trim().length) {
          filterobj['from'] = menuitem['from'];
        }
        if (menuitem['action'] == "between" && menuitem['to'] && menuitem['to'].toString().trim().length) {
          filterobj['to'] = menuitem['to'];
        }
        if (menuitem['value']) {
          menuitem['selectedtext'] = menuitem['value'];
        }
        if (menuitem['text']) {
          menuitem['selectedtext'] = menuitem['text'];
        }
        if (menuitem['action'] == "between") {
          filterobj['selectedtext'] = (menuitem['from'] + "-" + menuitem['to']);
          menuitem['selectedtext'] = filterobj['selectedtext'];
        }

        if ((menuitem['action'] == "lessthan" || menuitem['action'] == "greaterthan" || menuitem['action'] == "notin") && menuitem['selectedtext'].toString().trim().length) {
          filterobj['value'] = menuitem['selectedtext'];
        }

        let colmexist: boolean = false;
      //  console.log("selectCellContextMenu normal for loop");

        for (let index = 0; index < this.appliedFilters.length; index++) {

          const applfilter = this.appliedFilters[index];

          if ((applfilter['targetcolumn'] as string).toLowerCase() == (emitobj['targetcolumn'] as string).toLowerCase() && menuitem['action'] == applfilter['action']) {
            colmexist = true;
            if (this.appliedFilters[index]['action'] == "between") {
              this.appliedFilters[index]['from'] = menuitem['from'];
              this.appliedFilters[index]['to'] = menuitem['to'];
              this.appliedFilters[index]['selectedtext'] = (menuitem['from'] + " To " + menuitem['to']);
            }
            else if (this.appliedFilters[index]['action'] == "lessthan" || this.appliedFilters[index]['action'] == "greaterthan" || this.appliedFilters[index]['action'] == "notin") {
              this.appliedFilters[index]['value'] = menuitem['selectedtext'];
              this.appliedFilters[index]['selectedtext'] = menuitem['selectedtext'];
            }
            break;
          }
        }

        if (!colmexist) {
          this.appliedFilters.push(filterobj);
        }
        this.contextMenuService.closeAllContextMenus(null);
        if (menuitem['action'] == "between") {

          emitobj['from'] = menuitem['from'];
          emitobj['to'] = menuitem['to'];

          filterType = "D";
        }

        this.addOrRemoveFilter(filterType);

        break;

      // freezepanes //

      case "freezepanes":

        if (this.getValidFreezeColmsCount(colindex) > this.tableconfig['stickycolumns']['maxlimit']) {
          this.showToastMsg("Only upto first " + this.tableconfig['stickycolumns']['maxlimit'] + " column" + (this.tableconfig['stickycolumns']['maxlimit'] > 1 ? "s" : "") + " can be freeze", false, 5000);
          return;
        }
        // if((colindex + 1) > this.tableconfig['stickycolumns']['maxlimit']) {
        //   this.showToastMsg("You can apply panes forinitial " + this.tableconfig['stickycolumns']['maxlimit'] + " columns", false, 5000);
        //   return;
        // }

        if (Object.keys(this.consolidatedetails).length) {
          this.showToastMsg("You can not freeze panes after aggregation, But you can freeze first column", false, 7000);
          return;
        }

        this.freezePanesOrColms("panes", evt['item']['rowindex'], evt['item']['colindex'] + 1);
        break;

      // freezepanes //

      // Unfreeze Panes //

      case "unfreezepanes":
        this.unfreezeAllColms();
        break;

      // Unfreeze Panes //
    }

    this.contextEvents.emit(emitobj);
    this.selectedcellmenu = emitobj;
    this.assignToNgModel();
    this.contextMenuService.closeAllContextMenus({ event: null, eventType: "cancel" });
    if (this.cellcontextmenu) {
      this.cellcontextmenu.visibleMenuItems.length = 0;
    }
    this.updateFreezeCSS(500);
  }
  /*******************************End cell menu selection code*********************************/


  resetFreezePanesArr() {
  //  console.log("resetFreezePanesArr normal for loop");

    for (let index = 0; index < this.freezeColmMenus.length; index++) {
      if (this.freezeColmMenus[index]['action'] == "freezepanes") {
        this.freezeColmMenus[index]['visible'] = this.tableconfig['enablefreezepanes'] ? true : false;
      }
      else if (this.freezeColmMenus[index]['action'] == "unfreezepanes") {
        this.freezeColmMenus[index]['visible'] = false;
      }
    }
    // console.log('resetFreezePanesArr','cellmenulist',this.cellmenulist);
  }

  /***Freeze Columns and Rows
   * You can freeze columns and rows
   * just pass freeze type Ex: 'panes' | 'columns'
  */

  freezePanesOrColms(freezeType: string, rowindex: number, colindex: number) {
    try {

      /**
       *sum,max,min,average,max colmfreeze,unfreezeoption, stop resize reorder maxfreeze, sequentially freeze
       *----For reference purpose----
       */

      this.showloader = true;

      let positionLeft: any = 0;
      let positionTop: number = -1;

      let stickyHTML = "";

      this.removeFreezing();

      this.freezeColmsObj.isfrozen = true;
      this.freezeColmsObj.details = {};
      this.freezeColmsObj.details = {
        "type": freezeType.toLowerCase(),
        "rowindex": rowindex,
        "colindex": colindex - 1,
        "freezecount": 0
      };

      if (this.tableconfig['stickycolumns']['initial'] || freezeType === "panes" || freezeType === "columns") {
        if (!(this.tableconfig['tableclasses'] as string).includes("dynamic-columns")) {
          this.tableconfig['tableclasses'] += " dynamic-columns";
        }
      }
      let index = 1;

      if (this.tableconfig['resizable']['rows'] || (this.tableconfig['summary'] && this.tableconfig['summary'].length)) {
        let resizeColm = document.getElementById('resize-row' + this.tableid)?.getBoundingClientRect()?.width || document.getElementById('resize-row' + this.tableid);

        if (resizeColm) {
          positionLeft = resizeColm;
          index += 1;
        }
      }
      let hrdIndx = 0;
      let topheaderno = 0;

      if (freezeType === "panes") {
  //  console.log("freezePanesOrColms normal for loop 1");

        for (; index <= colindex; index++) {
          if (!this.tableheaders[hrdIndx]['visiblecolm']) {
            hrdIndx += 1;
            continue;
          }
          // const elemWidth = document.getElementById(('hdr-' + (hrdIndx))).clientWidth;
          let id = "#thead" + this.tableid + " .dnd-moved #" + ('hdr-' + this.tableid + hrdIndx);
          const elemWidth = document.querySelector(id).getBoundingClientRect().width || document.querySelector(id).clientWidth;
          stickyHTML += `
          .dynamic-columns thead tr th:nth-child(${index}) {
            position: sticky !important;
            left: ${positionLeft}px !important;
            display: table-cell !important;
            background:#bef5da !important;
            box-shadow: 1px 0px 0px #bfbfbf !important;
            z-index: 1005 !important;
          }
          .dynamic-columns tbody tr td:nth-child(${index}) {
            position: sticky !important;
            left: ${positionLeft}px !important;
            display: table-cell !important;
              background:#bef5da !important;
              box-shadow: 1px 0px 0px #bfbfbf !important;
              z-index: 1004 !important;
              border-right:1px solid red;
            }
            tfoot tr th:nth-child(${index}) {
              position: sticky !important;
              left: ${positionLeft}px !important;
              display: table-cell !important;
              box-shadow: 1px 0px 0px #bfbfbf !important;
              z-index: 100 !important;
            }
          `;

          /**Assigning value to td with left and top value */
          positionTop = document.getElementById('thead' + this.tableid).clientHeight - 2;
        //  console.log("freezePanesOrColms normal for loop 2");
 
          for (let rindx = 0; rindx < rowindex; rindx++) {

            const elemHeight = document.getElementById(('tr' + (rindx))).clientHeight;

            stickyHTML += `
            .dynamic-columns tbody tr:nth-child(${rindx + 1}) td:not([hidden]) {
              position: sticky !important;
              top: ${positionTop}px !important;
              display: table-cell !important;
              background:#bef5da !important;
              box-shadow: 1px 0px 0px #bfbfbf !important;
              z-index: 11;
            }

            .dynamic-columns tbody tr:nth-child(${rindx + 1}) td:nth-child(${index}){
              z-index: 1005 !important;
            }
            `;
            positionTop += elemHeight;
          }
          /**End assigning top and left values */

          /****Update z-index of th of top headers */
          if (this.topheaders.length) {
            topheaderno += (this.topheaders[hrdIndx]['colspan']);
            if (topheaderno < colindex || this.topheaders[hrdIndx]['colspan'] > 1) {
              stickyHTML += `
              .dynamic-columns thead tr:nth-child(1) th:nth-child(${index}) {
                        z-index: 1004 !important;
                }`;
            }
          }


          /***End to header z-index */




          positionLeft += elemWidth + 1;
          hrdIndx += 1;
        }
      }
      else if (freezeType === "columns") {

        let isFirstTime: boolean = true;
        // positionLeft += 1;

        let finalcolindx = (this.tableconfig['resizable']['rows'] ? colindex + 1 : colindex);
        // console.log('freezePanesOrColms', this.freezeColmsObj, finalcolindx, this.freezeHeaderMenu);
      //  console.log("freezePanesOrColms normal for loop 3");

        for (; index <= finalcolindx; index++) {

          if (!this.tableheaders[hrdIndx]['visiblecolm']) {
            hrdIndx += 1;
            continue;
          }

          // const elemWidth = document.getElementById(('hdr-' + (hrdIndx))).clientWidth;
          let id = "#thead" + this.tableid + " .dnd-moved #" + ('hdr-' + this.tableid + hrdIndx);
          // const elemWidth = Math.round(document.querySelector(id).getBoundingClientRect().width || document.querySelector(id).clientWidth);
          let ele = document.getElementById('hdr-' + this.tableid + hrdIndx);
          let ple = ele.parentElement;
          const elemWidth = (ele.offsetWidth ? ele.offsetWidth : ple.offsetWidth) - 1;
          ////  console.log(this.getelementWidth('hdr-' + hrdIndx),'index '+index + " positionLeft "+ positionLeft + " isFirstTime "+ isFirstTime+ " parentElement "+parentElementPaddingLeft+" elemWidth "+elemWidth + " positionLeft + elemWidth " + (positionLeft + elemWidth));
          // console.warn('index '+index,'elemWidth '+ elemWidth, "positionLeft "+positionLeft);
          stickyHTML += `
          .dynamic-columns thead tr th:nth-child(${index}) {
                    position: sticky !important;
                    left: ${positionLeft}px !important;
                    display: table-cell !important;
                    background:#bef5da !important;
                    box-shadow: 1px 0px 0px #bfbfbf !important;
                    z-index: 1001 !important;
                    outline: 0;
            }
            tfoot tr th:nth-child(${index}) {
              position: sticky !important;
              left: ${positionLeft}px !important;
              display: table-cell !important;
              box-shadow: 1px 0px 0px #bfbfbf !important;
              z-index: 100 !important;
          }`;


          if (Object.keys(this.consolidatedetails).length && isFirstTime) {
            // console.log('in consolidatedetails');

            stickyHTML += `
              .dynamic-columns tbody tr td[rowspan]:nth-child(${index}) {
                        position: sticky !important;
                        left: ${positionLeft}px !important;
                        display: table-cell !important;
                        background:#bef5da !important;
                        box-shadow: 1px 0px 0px #bfbfbf !important;
                        z-index: 11 !important;
              }
              `;
          }
          else {
            stickyHTML += `
              .dynamic-columns tbody tr td:nth-child(${index}) {
                        position: sticky !important;
                        left: ${positionLeft}px !important;
                        display: table-cell !important;
                        background:#bef5da !important;
                        box-shadow: 1px 0px 0px #bfbfbf !important;
                        z-index: 11 !important;
              }
              `
          }


          stickyHTML += `.dynamic-columns tbody tr:nth-child(even) td:nth-child(${index}) {
          background:#ffe9e9 !important;
          }`

          stickyHTML += `.dynamic-columns tbody tr:nth-child(odd) td:nth-child(${index}) {
          background:#fbe5b2 !important;
          }`;

          /****Update z-index of th of top headers */
          if (this.topheaders.length) {
            topheaderno += (this.topheaders[hrdIndx]['colspan']);
            if (topheaderno <= finalcolindx || this.topheaders[hrdIndx]['colspan'] > 1) {
              stickyHTML += `
              .dynamic-columns thead tr:nth-child(1) th:nth-child(${index}) {
                        z-index: 1004 !important;
                }`;
            }
          }


          /***End to header z-index */


          isFirstTime = false;
          positionLeft += elemWidth;
          hrdIndx += 1;
        }
        this.freezeColmsObj.details['freezecount'] = 0;
        this.freezeColmsObj.details['freezecount'] = (hrdIndx);

      }

      if (stickyHTML.trim().length) {
        let sheet = document.createElement('style');
        sheet.id = "stickyColmsStyles";
        sheet.innerHTML = stickyHTML
        document.body.appendChild(sheet);
        stickyHTML = "";
      }
      this.setResizeAndDragDrop(false);


      setTimeout(() => {
        document.getElementById(this.tableid.toString()).scrollLeft;
        this.showloader = false;
      }, 1000);

    }
    catch (error) {
      console.error(error);
      this.showloader = false;
    }
    this.changeDetectRef.detectChanges();
  }

  /***End Freeze logic*/


  getHiddenSummaryCount(): number {
    let hiddencount = 0;
  //  console.log("getHiddenSummaryCount forEach");

    this.footerValObj.caltypes.forEach((calobj) => {
      hiddencount += (!calobj['show'] ? 1 : 0);
    });
    return hiddencount;
  }


  /**Below method will show and hide the summary */

  hideAndShowSummary(frombtn: boolean = false) {

    if (this.tableconfig['summary'] && this.tableconfig['summary'].length) {
      this.tableconfig['showsummary'] = !this.tableconfig['showsummary'];
      this.setFooterStyle(frombtn);
    }
  }

  /**End Hide and Show Summary */

  setFooterStyle(frombtn: boolean = false) {
    let stickyHTML = "";
    let bottomPosition = -1;

    const elem = document.getElementById("stickyFooterStyles");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }

    if (frombtn && this.getHiddenSummaryCount()) {
  //  console.log("setFooterStyle forEach");

      this.footerValObj['caltypes'].forEach((calobj) => {
        if ((this.tableconfig['summary'] as string[]).indexOf(calobj['value']) != -1) {
          calobj['show'] = true;
        }
      });
    }
  //  console.log("setFooterStyle normal for loop");

    for (let index = this.footerValObj['caltypes'].length; index > 0; index--) {

      if (!this.footerValObj['caltypes'][index - 1]['show']) {
        continue;
      }
      let footerElem = document.getElementById("ftr" + (index - 1).toString());
      let height;

      if (!footerElem) {
        stickyHTML = "";
        this.setStickyFooterInt();
        break;
      }
      height = footerElem.clientHeight;

      stickyHTML += `
      tfoot tr:nth-child(${index}) th {
        position: sticky !important;
        bottom: ${bottomPosition}px !important;
        display: table-cell !important;
        box-shadow: 1px 0px 0px #bfbfbf !important;
        z-index: 11 !important;
      }`;
      //background: #e8e8e8 !important;

      bottomPosition += height;
    }

    if (stickyHTML.trim().length) {

      let sheet = document.createElement('style');
      sheet.id = "stickyFooterStyles";
      sheet.innerHTML = stickyHTML
      document.body.appendChild(sheet);
      stickyHTML = "";
    }
    this.updateFreezeCSS();
    this.changeDetectRef.detectChanges();

  }

  setStickyFooterInt() {
    const stickyfooterinterval = setInterval(() => {
      this.setFooterStyle();
      clearInterval(stickyfooterinterval);
    }, 100);
  //  console.log(stickyfooterinterval);
    
  }




  validation(menu) {

    var disabled = true;

    if (!menu['from'] || !menu['to']) {
      return true;
    }
    switch (menu['datatype']) {
      case "date":
        disabled = (new Date(menu['from']) > new Date(menu['to']))
        break;
      case "number":
        disabled = (menu['from'] > menu['to']);
        break;
    }

    return disabled;
  }


  /*******************************Set ngModel Data**********************/

  assignToNgModel() {
    this.ngmodel = _.cloneDeep(this.tabledata);
    this.ngmodelChange.emit(this.ngmodel);
  }

  /*****************************************End ngModel method****************************/



  /*******************Display All hidden columns**************************/

  displayAllColms() {

    if (this.freezeColmsObj.isfrozen) {
      this.showToastMsg("Please Unfreeze columns before unhide all columns", false, 5000);
      return;
    }

    let visiblecount = 0;
  //  console.log("displayAllColms normal for loop 1");

    for (let headerindex = 0; headerindex < this.tableheaders.length; headerindex++) {
  //  console.log("displayAllColms normal for loop 2");

      for (let hiddenindex = 0; hiddenindex < this.hiddencolumns.length; hiddenindex++) {
        if (this.tableheaders[headerindex]['key'] == this.hiddencolumns[hiddenindex]['key']) {
          visiblecount += 1;
          this.tableheaders[headerindex]['visiblecolm'] = true;
        }
      }
      if (visiblecount == this.hiddencolumns.length) {
        break;
      }
    }

    // this.tableheaders = _.cloneDeep(this.tableheaderbackup);
    this.hidecolumnaction = false;
    this.hiddencolumns.length = 0;
    this.setvisibleHiddenFlagOfMenuList(false);
    this.colspancount = (this.tableconfig['actioncolmconfig'] ? (this.tableheaders.length + 1) : this.colspancount);
    // this.verifyTblConfig();
    // this.prepareBasicData();
    setTimeout(() => {
      this.updateFreezeCSS();
      this.setResizeAndDragDrop();
    }, 100);

    // this.assignToNgModel();
  }
  /************************************End Display hidden columns******************************/
  ////////below method woll be execute ifuser select a hidden colm
  selectHiddenCol() {
    try {


      if (this.freezeColmsObj.isfrozen) {
        this.showToastMsg("Please Unfreeze column(s) before unhide the column", false, 5000);
        return;
      }

      var val = document.getElementById(this.selectedcolumn)['value'];
      let hindx = -1;
  //  console.log("selectHiddenCol normal for loop");

      for (let i = 0; i < this.hiddencolumns.length; i++) {
        if (this.hiddencolumns[i]['key'] == val) {
          let indx = this.hiddencolumns[i]['indx'];
          // this.tableheaders.splice(indx, 0, this.hiddencolumns[i]);
          this.tableheaders[indx]['visiblecolm'] = true;
          hindx = i;
        }
      }
      this.hiddencolumns.splice(hindx, 1);
      this.hidecolumnaction = this.hiddencolumns.length == 0 ? false : true;
      this.displayhiddencolspopup = false;
      this.colspancount++;
      if (this.hiddencolumns.length == 0) {
        this.setvisibleHiddenFlagOfMenuList(false);
      }
    }
    catch (ex) {
      throw ex;
    }
  }

  closePopup() {
    this.displayhiddencolspopup = false;
    this.displayDeleteConfpopup = false;
    this.displayQuantityConfpopup = false;
    this.displayDetailsPopup = false;
    this.displayDetailsPopupObj = {};
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  changeCheckAll(indx, event) {
    try {
      // console.log(event, 'changeCheckAll');
    //  console.log("changeCheckAll filter");

      this.showloader = true;
      let checkedallarray: any[] = [];
      checkedallarray = this.tabledata.filter(row => {
        row['checked'] = event['currentTarget']['checked'];
        let obj = {};
        obj['row'] = row;

        this.checkedSingleRecord.emit(obj);
        this.markedCheckedRecordInDeepCopy(row);

        return row;
      })
      this.tabledata = checkedallarray;
      this.checkedrecordscount = event['currentTarget']['checked'] ? this.tabledata.length : 0
      this.showloader = false;
      this.updatedtabledata.emit(this.tabledata);
      // this.updatedtabledataSubject.next(this.tabledata)
      // this.markedCheckedRecordInDeepCopy_old();
    }
    catch (ex) {
      throw ex;
    }
  }
  markedCheckedRecordInDeepCopy_old() {
    if (this.tabledata?.length != this.tabledatabackup?.length) {
  //  console.log("markedCheckedRecordInDeepCopy_old forEach 1");

      this.tabledatabackup.forEach(row => {
  //  console.log("markedCheckedRecordInDeepCopy_old forEach 2");

        this.tabledata.forEach(item => {
          if (item['autoid'] == row['autoid']) {
            // console.log(row, 'autoid');
            row['checked'] = item['checked']

          }

        })
      })
    }
  }
  markedCheckedRecordInDeepCopy(row) {
    // row = JSON.parse(JSON.stringify(row));
    this.tabledatabackup[row['ptIndex']] = row;

    // console.log("markedCheckedRecordInDeepCopy", autoDeepIndex, row, this.tabledata, this.tabledatabackup);
  }
  //////below method will be execute ifthere is checkbox column and use checked any checkbox
  changeIndividualCheckbox(event, rowindex, colindex, opt, item, keyName, header) {
    // console.log("changeIndividualCheckbox", item);
    if (event['currentTarget']['checked']) {
      this.checkedrecordscount++;
    }
    else {
      this.checkedrecordscount--;
    }
    const obj = {};
    obj['row'] = item;

    this.checkedSingleRecord.emit(obj);
    // this.updatedtabledata.emit(this.tabledata);
    this.updatedtabledataSubject.next(this.tabledata)

    const configObject = this.tableconfig['checkedfilter'];

    if (configObject && opt == configObject['checkboxtype'] && item[configObject['conditionkey']] && !this.filterCheckObject['checked']) {
      this.appliedFilters.push(
        {
          action: configObject['action'],
          datatype: configObject['datatype'],
          obj,
          selectedtext: item[configObject['key']],
          ptIndex: item['ptIndex'],
          targetcolumn: configObject['key']
        }
      )
      this.filterCheckObject['checked'] = true;

      this.filterCheckObject['rowPtIndex'][item['ptIndex']] = true;


        this.addOrRemoveFilter('I')

      // console.log('checkboxfilter', this.appliedFilters);

    }
    if(configObject && opt == configObject['checkboxtype'] && item[configObject['conditionkey']]) {

      this.filterCheckObject['rowPtIndex'][item['ptIndex']] = event['currentTarget']['checked'];
      // console.log('checkbox');
    }
    this.markedCheckedRecordInDeepCopy(item);


    // if(this.tabledata.length==this.checkedrecordscount){

    // }
  }
  ///////////////////////Export table into PDF
  exportToPDF() {
    $("#" + this.tableid).tableHTMLExport({ type: "pdf", filename: "sample.pdf" });
  }
  ///////////////////////Export JSON.into CSV

  prapareHeaderForCSV(returnheader: boolean = false) {

    if (this.hiddencolumns.length) {
      this.displaycsvmodal = true;
      return;
    }
    this.yes_ExportToCSV(true);
  }


  no_ExportToSV(flag) {
    this.displaycsvmodal = false;
    this.yes_ExportToCSV(false)
  }

  instructionModal() {
    this.displayinstructionmodal = true;
    // console.log("test div")
  }
  closeInstruction() {
    this.displayinstructionmodal = false;
  }

  /***********************Export to Excel****************/
  yes_ExportToCSV(considerhiddencolumn: boolean = false) {
    try {
      this.showloader = true;
      let newdata: any[] = [];
      let headarr: any[] = [];
      let tablearray: any[] = JSON.parse(JSON.stringify(this.tabledata));
  //  console.log("yes_ExportToCSV normal for loop 1");

      for (let tblindx = 0; tblindx < tablearray.length; tblindx++) {
        let myobj = {};
  //  console.log("yes_ExportToCSV normal for loop 2");

        for (let hdrindx = 0; hdrindx < this.tableheaders.length; hdrindx++) {
          if ((this.tableheaders[hdrindx]["colhiddenby"] as string).toUpperCase() == "SYSTEM") {
            continue;
          }
          if (!considerhiddencolumn && (this.tableheaders[hdrindx]["colhiddenby"] as string).toUpperCase() == "USER") {
            continue;
          }
          if (this.tableheaders[hdrindx]["key"] != "") {
            if (this.tableheaders[hdrindx]["datatype"] == "date") {
              if(tablearray[tblindx][this.tableheaders[hdrindx]["key"]]){
                myobj[this.tableheaders[hdrindx]["key"]] = this.datepipe.transform(new Date(tablearray[tblindx][this.tableheaders[hdrindx]["key"]]), this.dateformat);
              }
            }
            else {
              myobj[this.tableheaders[hdrindx]["key"]] = tablearray[tblindx][this.tableheaders[hdrindx]["key"]] ?? '';
            }
          }
        }
        
        newdata.push(myobj);
      }
  //  console.log("yes_ExportToCSV normal for loop 3");

      for (let hdrindx = 0; hdrindx < this.tableheaders.length; hdrindx++) {
        if ((this.tableheaders[hdrindx]["colhiddenby"] as string).toUpperCase() == "SYSTEM") {
          continue;
        }
        if (!considerhiddencolumn && (this.tableheaders[hdrindx]["colhiddenby"] as string).toUpperCase() == "USER") {
          continue;
        }
        if (this.tableheaders[hdrindx]["key"] != "") {
          headarr.push(this.tableheaders[hdrindx]["display"]);
        }
      }
      var options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        headers: headarr
      };
      this.showloader = false;
      let filename: string = this.tableconfig['filenameprefix'] + this.datepipe.transform(new Date(), this.dateformat);
      this.displaycsvmodal = false;
    //  console.log("newdata", newdata);
      
      new Angular2Csv(newdata, filename, options);
    }
    catch (ex) {
      throw ex;
    }

  }

  /****************************Below method will asign the selected dropdown value to the user
   * This method is calling from the the singleClickRecord which is binded to <td> tag
   * ***********/
  assignDrodownValue(evt) {

    var selectedvalue = evt['dataset']['value'];
    var headerkey = this.valuebeforecommit['keyName'] + "_ddl";
    var orgheaderkey = this.valuebeforecommit['keyName'];

    // for(let index = 0; index < this.valuebeforecommit[headerkey].length; index++) {

    //   const data = this.valuebeforecommit[headerkey][index];

    //   if(data['id'] == selectedvalue) {
    //     this.tabledata[this.valuebeforecommit['actualindex']][orgheaderkey] = data['value'];
    //   }
    // }

    setTimeout(() => {
      this.ddlbtn['nativeElement'].focus()
    }, 50);
  }


  /*************************************End Dropdown value assigning************************/


  /**
   * Below method is responsible to check the difference between the previous cell value and the latest cell value
   * ifthere are difference between these values and there is permission of auto update then call api and update the cell value in the database.
   */

  checkDiffAndAutoUpdate() {


    return new Promise((resolve, reject) => {
      if (this.valuebeforecommit != "" && Object.keys(this.valuebeforecommit).length) {
      //  console.log("checkDiffAndAutoUpdate findIndex");

        let tblDataIndx = this.tabledata.findIndex(row => row['autoid'] == this.valuebeforecommit['autoid']);

        if ((this.tableconfig['apicall']['autoupdate'] || this.tableconfig['apicall']['askconfirmation']) && tblDataIndx != -1 && this.tableheaders[this.valuebeforecommit['colindex']]['editable'] && this.valuebeforecommit[this.valuebeforecommit["keyName"]].toString().toUpperCase().trim() != this.tabledata[tblDataIndx][this.valuebeforecommit["keyName"]].toString().toUpperCase().trim()) {
          // if(elemvalue.toString().trim().length > 0 && (this.tableconfig['apicall']['autoupdate'] || this.tableconfig['apicall']['askconfirmation']) && headerObj['editable'] && this.valuebeforecommit[headerkey] != elemvalue && !this.updateConfirmation({ "keyName": headerkey, "rowindex": rowindex, "colindex": colindex })) {
          //   inpevents.preventDefault();
          //   return;
          // }

          if (this.updateConfirmation({ "keyName": this.valuebeforecommit["keyName"], "rowindex": this.valuebeforecommit["rowindex"], "colindex": this.valuebeforecommit["colindex"] })) {
            let obj = {};
            obj['row'] = this.tabledata[this.valuebeforecommit['autoid']];
            obj['rowindex'] = this.valuebeforecommit['autoid'];
            obj['colindex'] = this.valuebeforecommit['colindex'];
            obj['header'] = this.tableheaders[this.valuebeforecommit['colindex']];
            obj['keyName'] = this.valuebeforecommit["keyName"];
            obj['tabledata'] = this.tabledata;
            obj['autoid'] = this.valuebeforecommit["autoid"];
            obj['isvaluechanged'] = true;
            obj['row'].rowmodifiedstatus = "M";
            this.updateCellData(obj)
              .then(succ => {
                if (!succ) {// ifsucc value is 1 then there is an error
                  // this.rowChangeOrUpdate.emit(obj);
                  this.rowChangeOrUpdateSubject.next(obj)

                }
                resolve(0)
              },
                err => {
                  reject(0)
                });
          }

        }
        else {
          resolve(0);
        }
      }
      else {
        resolve(0);
      }
    })

  }

  /**End diff method */


  /**
   * Below method is defined forcell click event
  */


  singleClickRecord(evt, item, isoddrow, isevenrow, rowindex, colindex, keyName, fromdblclick: boolean = false, headerobj) {
    // console.log('singleClickRecord');

    if (!headerobj['editable'] && headerobj['showDetailsPopupAndThreeDots']) {
      // console.log('return');
      this.showPopUpForDetails(item, headerobj);
      return;
    }
    if (this.valuebeforecommit['rowindex'] == rowindex && this.valuebeforecommit['colindex'] == colindex) {
      return;
    }
    /**
     * Editble is mandatory and ifconsolodation/aggregation is applied then make sure that the view is "Normal View" the  allowed user to click on cell and editable OR consolidation shoule nt be available.
     */
    if (evt['target']['className'] == "fa fa-compress" || evt['target']['className'] == "fa fa-expand") {
      return;
    }

    this.checkDiffAndAutoUpdate().then(
      succ => {
        // if(!succ) {
        this.singleClickSubMethod(evt, item, isoddrow, isevenrow, rowindex, colindex, keyName, fromdblclick, headerobj);
        // }
      },
      rej => {
        this.singleClickSubMethod(evt, item, isoddrow, isevenrow, rowindex, colindex, keyName, fromdblclick, headerobj);
      }
    )
  }



  /***
   * Below method will call from the singleClickRecord method
   */

  singleClickSubMethod(evt, item, isoddrow, isevenrow, rowindex, colindex, keyName, fromdblclick: boolean = false, headerobj) {
    this.currentSelectedRowIndex = rowindex;
    if (this.tableconfig['editable'] && (headerobj && headerobj['editable']) && (Object.keys(this.consolidatedetails).length && this.consolidatedetails['consolidation'])) {
      this.showToastMsg("Inline editing does not allowed in Aggregation view", false);
      return;
    }
    if (this.tableconfig['editable'] && ((Object.keys(this.consolidatedetails).length && !this.consolidatedetails['consolidation']) || !Object.keys(this.consolidatedetails).length)) {
      var classname: string = evt['target']['className'];
      /*********************When user clicked on the dropdown menu item then below condition will call assignDropdownValue() method and return*****/
      if (classname.includes("dropdown-item")) {
        this.assignDrodownValue(evt['target']);
        return;
      }

      if ((classname.includes("dropdown-toggle") || classname.includes("btn-group")) && this.valuebeforecommit['rowindex'] == rowindex && this.valuebeforecommit['colindex'] == colindex) {
        return;
      }


      this.setCellStyle(keyName, item, colindex);
      var showdtpickerorddl = (fromdblclick || classname.includes("type-text-style") || classname.includes("autosetarrow")) ? true : false;
      var showdropdown = (classname.includes("autosetarrow") && headerobj['datatype'] == "dropdown");
      this.assignData(item, keyName, rowindex, colindex, showdtpickerorddl, showdropdown);
      // updatettab
      // this.updatedtabledata.emit(this.tabledata);
      this.updatedtabledataSubject.next(this.tabledata)

      // if(headerobj['datatype'] == 'number') {
      //   var elemid = ("td" + keyName + rowindex + colindex);
      //   var input = document.getElementById(elemid);

      //   input.addEventListener("mousewheel",
      //     function (event) {
      //       this.blur()
      //     });
      // }

    }
    let obj = {};
    obj['row'] = item;
    obj['isoddrow'] = isoddrow;
    obj['isevenrow'] = isevenrow;
    obj['rowindex'] = rowindex;
    obj['colindex'] = colindex;
    obj['keyName'] = keyName;
    this.singleClick.emit(obj);
    // this.findIsBlankePoOrNot();
  }




  setCellStyle(keyname, item, colindx) {

    this.cellstyle = {
      tdkeyname: ""
    };

    var tdkeyname: string = keyname + '*' + item['actualindex'] + '*' + colindx;
    var trkeyname: string = "tr" + this.tableid + item['actualindex'];

    this.cellstyle["tdkeyname"] = tdkeyname;

    this.cellstyle[tdkeyname] = {};
    this.cellstyle[trkeyname] = { 'background': 'rgb(245 243 168)', 'position': 'relative' };

    var tdid = ('td' + tdkeyname);
    var elem = document.getElementById(tdid);
    if (!elem || (Object.keys(this.valuebeforecommit).length && this.valuebeforecommit['keyName'] == keyname)) {
      return;
    }
    this.cellstyle[tdkeyname]['height'] = elem.clientHeight + 'px';
    this.cellstyle[tdkeyname]['width'] = (elem.clientWidth + 2) + 'px';
  }



  /////below method is fordouble click event on edit and on delete action
  doubleClickRecord(evt, item, isoddrow, isevenrow, rowindex, colindex, keyName, headerobj) {


    /******************** Calling the below  method to set the cell style like single click and default open calender on double click**********/
    // if(headerobj['datatype'] == "number") {
    evt.preventDefault();
    // }
    if (this.tableconfig['editable'] && (headerobj && headerobj['editable']) && (Object.keys(this.consolidatedetails).length && this.consolidatedetails['consolidation'])) {
      // this.showToastMsg("Inline editing does not allowed in Aggregation view", false);
      return;
    }

    this.singleClickRecord(evt, item, isoddrow, isevenrow, rowindex, colindex, keyName, true, headerobj);
    /***********************************End single click calling**********************************/

    let obj = {};
    obj['row'] = item;
    obj['isoddrow'] = isoddrow;
    obj['isevenrow'] = isevenrow;
    obj['rowindex'] = rowindex;
    obj['colindex'] = colindex;
    obj['keyName'] = keyName;

    /********************************set the double click key*******************/
    if (headerobj['editable']) {
      this.valuebeforecommit['dbclickfired'] = true;
    }

    /*********************************End ******************/

    this.doubleClick.emit(obj);
  }

  print(item, rowindex) {
    let obj = {};
    obj['row'] = item;
    obj['rowindex'] = rowindex;
    this.printEvent.emit(obj);
  }
  upload(item, rowindex) {
    let obj = {};
    obj['row'] = item;
    obj['rowindex'] = rowindex;
    this.uploadEvent.emit(obj);
  }

  /*************************Data assign and emit on keyup event************/
  assignData(item, keyName, rowindex, colindex, showdtpicker: boolean, showdropdown: boolean = false) {

    if (Object.keys(this.valuebeforecommit).length) {
      /********************Emitting updated value of column to update in the db*****************/
      // this.emitRowData(this.valuebeforecommit['rowindex'], this.valuebeforecommit['colindex'], this.valuebeforecommit['keyName']);
      /**************************************End data emitting**********************************/
      if (this.tabledata.length > this.valuebeforecommit['actualindex']) {
      //  console.log("assignData findIndex");

        var indxoftbldata = this.tabledata.findIndex(item => item['actualindex'] == this.valuebeforecommit['actualindex']);
        delete this.tabledata[indxoftbldata][this.valuebeforecommit["actioncolumn"]];
      }
      this.controlsenabled = false;
      this.valuebeforecommit = {};
    }
    else {
      this.valuebeforecommit = {};
      this.valuebeforecommit = new Object(item);
      this.valuebeforecommit['rowindex'] = rowindex;
      this.valuebeforecommit['colindex'] = colindex;
      this.valuebeforecommit['keyName'] = keyName;
      this.emitRowData(this.valuebeforecommit['rowindex'], this.valuebeforecommit['colindex'], this.valuebeforecommit['keyName'], null);
    }

    /*********************Comment by jagdish,because cell is not clickable after applied filter**********/
    item[keyName + "ctrl"] = true;
    item["actioncolumn"] = keyName + "ctrl";
    // this.tabledata[item['actualindex']][keyName + "ctrl"] = true;
    // this.tabledata[item['actualindex']]["actioncolumn"] = keyName + "ctrl";

    /******************************************End original Code*****************************/
    this.controlsenabled = true;

    //Comment by jagdish on 3 dec 2020 forissue/this.valuebeforecommit = JSON.parse(JSON.stringify(this.tabledata[item['actualindex']]));
    this.valuebeforecommit = JSON.parse(JSON.stringify(item));
    this.valuebeforecommit['rowindex'] = rowindex;
    this.valuebeforecommit['colindex'] = colindex;
    this.valuebeforecommit['keyName'] = keyName;
    this.valuebeforecommit['datatype'] = this.tableheaders[colindex]['datatype'];

    /************************Below ifcondition is written forNon-Editable Column/cell. Cell will not accept/any character because cell/column is not editable***************/
    // Prev Condition: this.tableconfig['controlconfig']['controlsfor'] == undefined || this.tableconfig['controlconfig']['controlsfor'][keyName] == undefined

    if (this.tableheaders[colindex]['editable']) {
      this.valuebeforecommit['ctrldisabled'] = true;
    }

    if (this.tableheaders[colindex]['datatype'] == "date" && !this.valuebeforecommit['isOpened'] && this.tableheaders[colindex]['editable']) {

      this.tabledata[item['actualindex']][keyName] = new Date(item[keyName]);/// new Date() is must forbsdatepicker. Without new Date() bsdatepicker will not show the propper date, It will show an "invalid date" message in the textbox.
      this.tabledata[item['actualindex']][keyName + "_min"] = this.tabledata[item['actualindex']][keyName + "_min"] ? new Date(this.tabledata[item['actualindex']][keyName + "_min"]) : null;
      this.tabledata[item['actualindex']][keyName + "_max"] = this.tabledata[item['actualindex']][keyName + "_max"] ? new Date(this.tabledata[item['actualindex']][keyName + "_max"]) : null;
      this.valuebeforecommit['isOpened'] = true;

      setTimeout(() => {
        this.datepicker['isOpen'] = showdtpicker;
        /**
         * {
         * "dob":'10-10-1995',
         * "dob_min":'01-01-1980',
         * "dob_max":'16-09-2020'// user will set the current date here....
         * }
         **/
        // this.datepicker['_bsValue'] = new Date().toISOString();
        // this.datepicker['value'] = new Date().toISOString();
      }, 50);
    }
    if (showdropdown) {
      setTimeout(() => {
        // this.valuebeforecommit['hideinput'] = true;
        if (this.ddlbtn) {
          this.ddlbtn['nativeElement']['autofocus'] = true;
          // this.ddlbtn['nativeElement']['hidden'] = false;
          this.ddlbtn['nativeElement'].click();
          // this.valuebeforecommit['']
        }
      }, 50);
      // }
    }

    // if()
  }

  changeDllOption() {
  }


  dateChanged(changeddate) {
  }

  /************************** */
  /************************
   * This method will return the column index to show the next/previous cell
   *
   */

  getColumnIndex(currentindx, currentrowindx, IsAscending: boolean) {
    var newcolindex;
    if (IsAscending) {

      return;
    }

    if (currentindx == 0) {
      if (currentrowindx == 0) {
        return {
          "colindx": currentindx,
          "rowindx": currentrowindx
        };
      }
    }
  //  console.log("getColumnIndex normal for loop");

    for (let index = currentindx; index >= 0; index++) {
      const element = this.tableheaders[index];
      if (element['visiblecolm']) {
        newcolindex = index;
        break;
      }
    }
    return;
  }
  getValidColm(colmindx) {
  //  console.log("getValidColm normal for loop");

    let finalIndx = -1;
    for (let index = colmindx; index < this.tableheaders.length; index++) {
      const element = this.tableheaders[index];
      if (element['visiblecolm'] && element['datatype'] != "button" && element['datatype'] != "checkbox" && element['datatype'] != "radio") {
        return index;
        // finalIndx = index;
        // break;
      }
    }

    // if(finalIndx != -1) {
    //   return finalIndx;
    // }
    // else if(finalIndx == -1) {
    //   this.getValidColm(0)
    // }
    return 0;
  }

  // scrollShiftLeft() {
  //   if(this.freezeColmsObj['isfrozen']){
  //     let freezeColId = `hdr-${this.freezeColmsObj['details']['colindex']}`;
  //     let freezeColIdXaxis=document.getElementById(freezeColId).getBoundingClientRect().width;
  //     // console.log('freezeColIdXaxis',freezeColIdXaxis,typeof freezeColIdXaxis);
  //     console.warn(document.getElementById('div2'+this.tableid).scrollLeft,document.getElementById(freezeColId).getBoundingClientRect());
  //     let shifted=document.getElementById('div2'+this.tableid).scrollLeft=freezeColIdXaxis;
  //   //  console.log('shifted',shifted);

  //   }
  //   // let div2 = document.getElementById('div2'+this.tableid).scrollLeft-=shiftPX;
  //   // let div2=$('#div2'+this.tableid).scrollLeft();
  // }
  shiftLeft(id) {
    //   let tableDivId='tbl-cntr'+this.tableid;
    //   let leftPos = $('#'+tableDivId).scrollLeft();
    // //  console.log(leftPos);
    //   $('#'+tableDivId).animate({ scrollLeft: leftPos + 30 }, 100);
    // console.log(id);
    let element = document.getElementById('div2' + this.tableid);
    // element.scrollIntoView(true);
    // document.getElementById('div2'+this.tableid).scrollTo(element.getBoundingClientRect().left-40,0);
    // console.log('scrollLeft',document.getElementById('div2'+this.tableid).scrollLeft);
    const scrollLeftPos = element.scrollLeft;
    if (scrollLeftPos) {
      element.scrollTo(scrollLeftPos - 60, 0)
      // console.log('scrollLeft', scrollLeftPos, scrollLeftPos - 60);
    }

    // window.scrollTo(document.getElementById(id).getBoundingClientRect().left - 40,0);

  }

  isActiveElementBehindTneFreezeColumn(colindex) {
    // console.log(this.freezeColmsObj);
    // let tdkeyname: string ='';
    let freezeColId = `hdr-`;
    let currentColId = `hdr-${colindex}`;
    let freezeColIdXaxis = 0;
    let currentColIdXaxis = document.getElementById(currentColId).getBoundingClientRect().x;
    // console.log('currentColIdXaxis',currentColIdXaxis,typeof currentColIdXaxis);
    if (this.freezeColmsObj['isfrozen']) {
      freezeColId = `hdr-${this.freezeColmsObj['details']['colindex'] + 1}`;
      freezeColIdXaxis = document.getElementById(freezeColId).getBoundingClientRect().x + 0.0000001;
      // console.log('freezeColIdXaxis',freezeColIdXaxis,typeof freezeColIdXaxis);
      // console.warn(document.getElementById(freezeColId).getBoundingClientRect());
      // console.warn(document.getElementById(currentColId).getBoundingClientRect());

    }
    // console.log(freezeColId,currentColId,freezeColId==currentColId,+freezeColIdXaxis>+currentColIdXaxis);

    return +freezeColIdXaxis > +currentColIdXaxis;
  }
  eventByDirective(inpevents: Event, item, headerkey, headerObj) {
    // console.log(inpevents, item, headerkey, headerObj);


    if (this.showloader) {
      inpevents.preventDefault();
      return;
    }
    if (!headerObj['editable'] || headerObj['datatype'] == "date") {
      inpevents.preventDefault();
    }

    if (this.valuebeforecommit['datatype'] == "number" && inpevents['keyCode'] === 38 || inpevents['keyCode'] === 40) {
      inpevents.target['blur']();
      inpevents.preventDefault();
    }

    /*************************ifuser doucle clicked on the controller then we will are not allowing the arrowleft,arrowright code execution***************/
    if ((inpevents['code'] != 'Escape' && inpevents['code'] != 'Enter' && inpevents['code'] != 'Blur') && this.valuebeforecommit['dbclickfired']) {
      return;
    }
    /*******************************************End double click scenario********************************************/

    if (inpevents['code'] == 'ArrowUp' || inpevents['code'] == 'ArrowRight' || inpevents['code'] == 'ArrowDown' || inpevents['code'] == 'ArrowLeft' || inpevents['code'] == 'Tab' || inpevents['code'] == 'Escape' || inpevents['code'] == 'Enter' || inpevents['code'] == 'Blur') {

      let elemid: string = inpevents['srcElement']['id'];
      let splittedarr: any[] = elemid.split("*");
      let headerkey: string = splittedarr[0];/// header key of column
      let rowindex = parseInt(splittedarr[1]);///
      let colindex = parseInt(splittedarr[2]);///
      let elemvalue = inpevents['srcElement']['value'];

      // "apicall": {
      //   "autoupdate": true
      // }
      if (elemvalue.toString().trim().length > 0 && (this.tableconfig['apicall']['autoupdate'] || this.tableconfig['apicall']['askconfirmation']) && headerObj['editable'] && this.valuebeforecommit[headerkey] != elemvalue && !this.updateConfirmation({ "keyName": headerkey, "rowindex": rowindex, "colindex": colindex })) {
        inpevents.preventDefault();
        return;
      }

      // console.log("inpevents ", inpevents['code']);

      switch (inpevents['code']) {
        case "ArrowUp":
          this.emitRowData(rowindex, colindex, headerkey, inpevents['code']);
          if (rowindex > 0) {
            rowindex--;
            if (this.tabledata[rowindex] == undefined) return;
            this.setCellStyle(headerkey, this.tabledata[rowindex], colindex);
            this.assignData(this.tabledata[rowindex], headerkey, rowindex, colindex, false);
          }
          break;

        case "ArrowLeft":

          let newcol = 0;
          this.emitRowData(rowindex, colindex, headerkey, inpevents['code']);

          if (colindex == 0) {
            if (rowindex > 0) {
              rowindex -= 1;
  //  console.log("eventByDirective normal for loop 1");

              for (let index = this.tableheaders.length - 1; index >= 0; index--) {
                const element = this.tableheaders[index];
                if (element['visiblecolm']) {
                  newcol = index;
                  break;
                }
              }
            }
          }
          else {
            newcol = -1;
            this.tabledata[rowindex][headerkey + 'ctrl'] = false;
            // newcol = colindex - 1;
  //  console.log("eventByDirective normal for loop 2");

            for (let index = (colindex - 1); index >= 0; index--) {
              const element = this.tableheaders[index];
              if (element['visiblecolm'] && element['datatype'] != "button" && element['datatype'] != "checkbox" && element['datatype'] != "radio") {
                newcol = index;
                break;
              }
            }
            if (newcol == -1) {
              // newcol = this.tableheaders.length - 1;
  //  console.log("eventByDirective normal for loop 3");

              for (let index = (this.tableheaders.length - 1); index >= 0; index--) {
                const element = this.tableheaders[index];
                if (element['visiblecolm'] && element['datatype'] != "button" && element['datatype'] != "checkbox" && element['datatype'] != "radio") {
                  newcol = index;
                  rowindex = (rowindex > 0) ? (rowindex - 1) : rowindex;
                  break;
                }
              }
            }
          }
          // }
          // this.getColumnIndex(colindex, false);
          // let NewCol = this.getColumnIndex(colindex, rowindex, false);
          headerkey = this.tableheaders[newcol]['key'];
          if (this.tabledata[rowindex] == undefined) return;
          // let shiftFlag=this.isActiveElementBehindTneFreezeColumn(newcol);

          if (true) {
            // this.scrollShiftLeft();
            // const rowIndexHeader = this.tableheaders[newcol+1]['key'];
            // const cellId= 'td'+rowIndexHeader+'*'+ rowindex +'*'+(newcol+1);
            this.shiftLeft('');
            // console.log("shiftLeft",shiftFlag,cellId);

          }
          this.setCellStyle(headerkey, this.tabledata[rowindex], newcol);
          this.assignData(this.tabledata[rowindex], headerkey, rowindex, newcol, false);
          // }

          break;

        case "ArrowDown":
          this.emitRowData(rowindex, colindex, headerkey, inpevents['code']);

          // if((rowindex + 1) < this.rowsperpage && (rowindex + 1) < this.tabledata.length) {
          // this.tabledata[rowindex][headerkey + 'ctrl'] = false;
          rowindex++;
          if (this.tabledata[rowindex] == undefined) return;
          this.setCellStyle(headerkey, this.tabledata[rowindex], colindex);
          this.assignData(this.tabledata[rowindex], headerkey, rowindex, colindex, false);
          // }
          break;

        case "Tab":
        case "ArrowRight":
        case "Enter":
          this.emitRowData(rowindex, colindex, headerkey, inpevents['code']);

          let NewColIndx = colindex + 1;

          if (NewColIndx > this.tableheaders.length - 1) {
            rowindex += 1;
  //  console.log("eventByDirective normal for loop 4");

            for (let index = 0; index < this.tableheaders.length; index++) {
              const element = this.tableheaders[index];
              if (element['visiblecolm'] && element['datatype'] != "button" && element['datatype'] != "checkbox" && element['datatype'] != "radio") {
                NewColIndx = index;
                break;
              }
            }
          }

          let finalcolmindx = this.getValidColm(NewColIndx);

          headerkey = this.tableheaders[finalcolmindx]['key'];

          if (this.tabledata[rowindex] == undefined) return;

          this.setCellStyle(headerkey, this.tabledata[rowindex], finalcolmindx);
          this.assignData(this.tabledata[rowindex], headerkey, rowindex, finalcolmindx, false);
          break;

        case "Escape":
          this.controlsenabled = false;
          this.valuebeforecommit['iseditable'] = false;
          // this.cellstyle[this.cellstyle.tdkeyname] = {};
          // this.cellstyle.tdkeyname = "";
          // this.tabledata[rowindex][headerkey + 'ctrl'] = false;
          // setTimeout(() => {
          this.tabledata[rowindex][headerkey] = this.valuebeforecommit[headerkey];
          // this.valuebeforecommit = {};
          // this.tabledata[rowindex][headerkey] = 120;
          // }, 30);
          break;
        case "Blur":
          // console.log('Blur event');
          this.emitRowData(rowindex, colindex, headerkey, inpevents['code']);
          break;
      }
    }
    else {
      /************************* cell will not be an editable until user type something in that cell********/
      if (!this.valuebeforecommit['iseditable'] && headerObj['editable'] && this.valuebeforecommit['datatype'] != "date") {
        this.valuebeforecommit['iseditable'] = true;
        // setTimeout(() => {
        this.tabledata[this.valuebeforecommit['actualindex']][this.valuebeforecommit['keyName']] = "";
        // }, 10);
        this.controlsenabled = true;
        return;
      }
    }
    // this.updatedtabledata.emit(this.tabledata);
    this.updatedtabledataSubject.next(this.tabledata)

    this.calcFooterVals()

  }

  /****************************************Process formula*******************/

  evalFormula(formula: string, data) {

    var formula: string = formula;
    var operatios = /[+\-\*\%\/\(\)]/g;
    var keys = formula.split(operatios);
  //  console.log("evalFormula forEach");

    keys.forEach(function (k) {
      if (k && (/[a-zA-Z0-9]/gi).test(k)) {
        if (data[k] != undefined) {
          data[k] = !(data[k] as string).toString().trim().length ? 0 : data[k];
          formula = formula.replace(k, data[k]);
        }
      }
    });
  //  console.log("evalFormula eval");

    return eval(formula);
  }

  changeNumberVal(value, item, headerkey, rowindex, colindex) {
    var tempItem = JSON.parse(JSON.stringify(item));
    tempItem[headerkey] = (tempItem[headerkey] == '') ? 0 : tempItem[headerkey];
    item[headerkey] = (item[headerkey] == '') ? 0 : item[headerkey];
    // console.log(tempItem);

    if (this.formulacolms.length) {
      //tempItem[headerkey] != value
      clearTimeout(this.formulainterval);
      setTimeout(() => {
        tempItem[headerkey] = value ? value : 0;
        colindex;
        let index = 0;
        let fomulaAllowColmns: any[] = [];
        if (this.tableconfig['calculationtype'] && (this.tableconfig['calculationtype'] as string).toLowerCase().trim() == "forwardonly") {
    //  console.log("changeNumberVal filter");

          fomulaAllowColmns = this.formulacolms
            .filter(
              colm => {
                if (colm['colindex'] >= colindex) {
                  return true;
                }
                return false;
              }
            )
        }
        else {
          fomulaAllowColmns = this.formulacolms;
        }
  //  console.log("changeNumberVal normal for loop");

        for (index; index < fomulaAllowColmns.length; index++) {
          const colm = fomulaAllowColmns[index];
          if (colm['key'] != headerkey) {
            item[colm['key']] = 0;
            tempItem[colm['key']] = 0;
            var result = parseFloat((this.evalFormula(colm['formula'], tempItem)).toFixed(colm['decimaldigit'])).toFixed(colm['decimaldigit']);
            item[colm['key']] = (+result < 0 ? (colm['shownegativevalue'] ? result : 0) : result);
            tempItem[colm['key']] = item[colm['key']];
            tempItem = item
            // console.log("tempItem", tempItem, item);

          }
        }
        // emitRowData(rowindex, colindex, keyName) {
        // this.emitRowData(rowindex, colindex, headerkey)
      }, 200);
    }
  }


  /***************************************End formula processing******************/

  checkValidation() {
  }

  mousewheelEvt(event) {
    event.preventDefault();
  }

  /*******************
    * emitting the updated row data by the "rowChangeOrUpdate" event
    * @param rowindex : This will be the actual index of row
    * @param colindex : This may be use in some specific scenarios
  *****************/

  /*******************
     * emitting the updated row data by the "rowChangeOrUpdate" event
     * @param rowindex : This will be the actual index of row
     * @param colindex : This may be use in some specific scenarios
     *
     */
  emitRowData(rowindex, colindex, keyName, eventName, fromConfirmation: boolean = false) {


    if (this.tableheaders[colindex]['editable']) {
      let obj = {};
      obj['row'] = this.tabledata[rowindex];
      obj['eventName'] = eventName;
      obj['rowindex'] = rowindex;
      obj['colindex'] = colindex;
      obj['header'] = this.tableheaders[colindex];
      obj['keyName'] = keyName;
      obj['tabledata'] = this.tabledata;
      obj['isvaluechanged'] = false;
      obj['autoid'] = this.valuebeforecommit["autoid"];
      obj['row'][keyName] = (obj['row'][keyName] ? obj['row'][keyName] : (this.tableheaders[colindex]['datatype'] == 'number' ? 0 : ''));

      if (this.valuebeforecommit[keyName] != undefined && this.valuebeforecommit[keyName].toString().trim() !== obj['row'][keyName].toString().trim()) {
        obj['isvaluechanged'] = true;
        obj['oldvalue'] = this.valuebeforecommit[keyName];
        this.valuebeforecommit[keyName] = obj['row'][keyName];//actualindex
        if (this.tabledatabackup[this.valuebeforecommit['ptIndex']]) {
          // this.tabledatabackup[this.valuebeforecommit['ptIndex']][obj['keyName']] = obj['row'][obj['keyName']];
          this.tabledatabackup[this.valuebeforecommit['ptIndex']] = obj['row'];
        }
        if (this.tableconfig['apicall'] && (this.tableconfig['apicall']['autoupdate'] || this.tableconfig['apicall']['askconfirmation'])) {
          this.updateCellData(obj);
        }
      }
      if (this.searchtext.trim().length) {
        // console.log("ptIndex ", obj['row']['ptIndex']);

        this.tabledatabackup[obj['row']['ptIndex']] = obj['row'];
      }
      obj['row'].rowmodifiedstatus = "M";
      // this.rowChangeOrUpdate.emit(obj);
      this.rowChangeOrUpdateSubject.next(obj)

      const configObject = this.tableconfig['checkedfilter'];
    //  console.log(obj);

      if(configObject && configObject['checkOnKey'] && keyName == configObject['checkOnKey'] && eventName == 'Blur'){
        // console.log("in editrow" );
        obj['row']['checked'] = true;
        this.changeIndividualCheckbox({ currentTarget: { checked: true } }, obj['row']['actualindex'], null, configObject['checkboxtype'], obj['row'], '', {})


      }

    }


  }

  openOrCloseDDl(item, headerkey) {

    if (!item[headerkey + '_ddl']) {
      item[headerkey + '_ddl'] = true;
      return
    }
    // else {
    delete item[headerkey + '_ddl'];
    // }

  }


  view(item, rowindex) {
    let obj = {};
    obj['row'] = item;
    obj['rowindex'] = rowindex;
    this.viewEvent.emit(obj);
  }

  edit(item, rowindex) {
    let obj = {};
    obj['row'] = item;
    // if(rowindex < (this.tabledata.length - 1)) {
    //   obj['nextRow'] = this.tabledata[rowindex + 1]/*  */;
    //   obj['nextRowIndex'] = rowindex + 1/*  */;
    // }
    // else {
    //   obj['nextRow'] = "noMoreRecord"/*  */;
    //   obj['nextRowIndex'] = -1/*  */;
    // }
    obj['rowindex'] = rowindex;
    this.editEvent.emit(obj);
  }

  delete(item, rowindex) {
    let obj = {};
    obj['row'] = item;
    obj['rowindex'] = rowindex;
    // this.deleteEvent.emit(obj);
    this.deleteObj = obj;
    this.displayDeleteConfpopup = true;

  }
  showDeleteConfirmationYes() {
    this.displayDeleteConfpopup = false;
    this.deleteEvent.emit(this.deleteObj);
  }
  /////Show Checked Rows
  showCheckedRows() {
    this.showloader = true;
    let checkedrecordsarr: any[] = [];
  //  console.log("showCheckedRows filter");

    checkedrecordsarr = this.tabledata.filter(row => {
      return row['checked'];
    });
    this.tabledata = checkedrecordsarr;
    this.showcheckedrecords = true;
    this.showloader = false;
  }


  restoreTableData() {
    this.tabledata = this.tabledatabackup;
    this.showcheckedrecords = false;
  }

  ////////////////////////change radio button
  changeRadioButton(inputevent, item) {
    this.changeRadio.emit(item);
  }

  trackByActualData(index, item) {
    return index; // or item.id
  }
  //  Customized adding two buttons starts here

  customizedFirstBtnEvent() {
    this.customizedFirstBtnEv.emit();
  }
  customizedSecondBtnEvent() {
    this.customizedSecondBtnEv.emit();
  }

  customizedThirdBtnEvent() {
    this.customizedThirdBtnEv.emit();
  }

  customizedFourthBtnEvent() {
    this.customizedFourthBtnEv.emit();
  }

  customizedFifthBtnEvent() {
    this.customizedFifthBtnEv.emit();
  }
  //  Customized adding two buttons ends here

  public addRrow(rowData: any) {
    // this.tablejson["tabledata"].push(rowData);
    this.tabledata.push(rowData);

  }

  changeDisplaySearchPannel(event) {
    let obj = {};
    obj['checkedsearchpannel'] = this.searchpannel
    this.changeSearchPannel.emit(obj);
  }

  /*****************************This method will remove the applied filter****************/

  removeFilter(filter) {

    var spliceindx = -1;
    var action = filter['operation'] ? filter['operation'] : filter['action'];
    const configObject = this.tableconfig['checkedfilter'];

  //  console.log("removeFilter normal for loop");
    // console.log({filter, configObject});
    for (let index = 0; index < this.appliedFilters.length; index++) {

      const applfilter = this.appliedFilters[index];

      if ((applfilter['targetcolumn'] as string).toLowerCase() == (filter['targetcolumn'] as string).toLowerCase()) {

        spliceindx = index;
        this.applfiltcolmsobj[applfilter['targetcolumn']] = false;
        this.appliedFilters.splice(index, 1);
        if(configObject && configObject['key'] && filter['targetcolumn'] == configObject['key']){
          this.removeCheckedFilter(filter);
        }
        break;
      }
    }

    if (!this.appliedFilters.length) {
      this.filteroncell = false;
  //  console.log("removeFilter forEach");
      
      Object.keys(this.applfiltcolmsobj).forEach(key => {
        this.applfiltcolmsobj[key] = false;
      })
    }

    this.addOrRemoveFilter((action as string).toUpperCase());

    this.updateFreezeCSS(500);
  }

  removeCheckedFilter(item) {
    const configObject = this.tableconfig['checkedfilter'];


    if (item['targetcolumn'] == configObject['key']) {
    //  console.log("removeCheckedFilter forEach");

      this.tabledata.forEach((ele, i)=>{
        if(ele[configObject['key']] == item['selectedtext']){
          ele['checked'] = false;
          ele['outquantity'] = 0;
        }
      })
    //  console.log("removeCheckedFilter normal for loop");
      
      for(let ptIndex in this.filterCheckObject['rowPtIndex']){
        if(this.filterCheckObject['rowPtIndex'][ptIndex]){
          this.tabledatabackup[ptIndex]['checked'] = false;
          this.tabledatabackup[ptIndex]['outquantity'] = 0;
        }
      }
      // this.changeIndividualCheckbox({ currentTarget: { checked: false } }, item['actualindex'], null, configObject['checkboxtype'], item['obj']['row'], '', {})
      this.filterCheckObject['checked'] = false;
      this.checkedrecordscount = 0;
    }
    // console.log("Filter", this.filterCheckObject);

  }


  /**********************************End Remove filter method**********************/

  /*****************************Dropdown item selection event********************/


  selDrodownValue(rowindex, colindex, opt, item, headerkey, header) {

    delete item[headerkey + '_ddl'];

    item[headerkey + "id"] = opt['id'];
    item[headerkey] = opt['value'];

    var ddlObj = {};

    ddlObj['option'] = opt;
    ddlObj['row'] = item;
    ddlObj['keyName'] = headerkey;
    ddlObj['header'] = header;
    ddlObj['rowindex'] = rowindex;
    ddlObj['colindex'] = colindex;
    ddlObj['tabledata'] = this.tabledata;
    ddlObj['isvaluechanged'] = false;
    ddlObj['autoid'] = this.valuebeforecommit['autoid'];//false;
    if (this.valuebeforecommit[headerkey].toString().toUpperCase().trim() !== opt['value']) {
      ddlObj['isvaluechanged'] = true;
      ddlObj['oldvalue'] = this.valuebeforecommit[headerkey];
    }
    this.dropdownchange.emit(ddlObj);

    if (this.tableconfig['apicall'] && (this.tableconfig['apicall']['autoupdate'] || this.tableconfig['apicall']['askconfirmation']) && !this.updateConfirmation({ "keyName": headerkey, "rowindex": rowindex, "colindex": colindex }) && ddlObj['isvaluechanged']) {
      this.confMsgBox['tempevtdata'] = ddlObj;
    }
    else {
    //  console.log("selDrodownValue findIndex");

      let bakindex = this.tabledatabackup.findIndex(row => row['autoid'] == this.valuebeforecommit['autoid']);
      if (bakindex != -1) {
        this.tabledatabackup[bakindex][ddlObj['keyName']] = ddlObj['row'][ddlObj['keyName']];
      }
      this.valuebeforecommit[headerkey] = opt['value'];
      this.updateCellData(ddlObj);
    }
    if (!header['cascadingref']['child']) {
      return;
    };
    /////Clear dependency values////
    this.clearDepValues(header['cascadingref']['child'], item);
  }
  /***************************************End Drodown selection event**********************/

  /**********************************Clearing the dependent values while changed drodown selected value*****************/
  clearDepValues(child, item) {
    if (!child) {
      return;
    }
    item[child] = "";
  //  console.log("clearDepValues normal for loop");

    for (let index = 0; index < this.tableheaders.length; index++) {
      const headerelem = this.tableheaders[index];
      if (headerelem['key'] == child) {
        this.clearDepValues(headerelem['cascadingref']['child'], item);
        break;
      }
    }
  }

  updateConfirmation(evt): boolean {
    if (!this.tblsessiondata.allowedallcolms) {
      if (this.tblsessiondata.allowedcolms.indexOf(evt['keyName']) == -1) {
        this.confMsgBox.msg = "Are you sure?";
        this.confMsgBox.nobtntxt = "No";
        this.confMsgBox.yestbtntxt = "Yes";
        this.confMsgBox['tempevtdata'] = {}
        this.confMsgBox['tempevtdata'] = evt;
        this.confMsgBox.show = true;
        return false;
      }
      return true;
    }
    return this.tblsessiondata.allowedallcolms;
  }


  /*****************************Yes and No Button */
  noBtnEvt() {
    this.tabledata[this.valuebeforecommit['actualindex']][this.valuebeforecommit['keyName']] = this.valuebeforecommit[this.valuebeforecommit['keyName']];
    this.confMsgBox.show = false;
    this.confMsgBox.selectedVal = "";
  }

  yesBtnEvt() {
    this.tblsessiondata.allowedallcolms = (this.confMsgBox.selectedVal == "all" ? true : false);
    this.confMsgBox.show = false;
    if (this.confMsgBox.selectedVal.trim() !== "" || this.tblsessiondata.allowedallcolms) {
      this.tblsessiondata.allowedcolms.push(this.confMsgBox['tempevtdata']['keyName']);
      sessionStorage.setItem(this.tableid.toString(), JSON.stringify(this.tblsessiondata));
    }
    this.confMsgBox.selectedVal = "";
    this.emitRowData(this.confMsgBox['tempevtdata']['rowindex'], this.confMsgBox['tempevtdata']['colindex'], this.confMsgBox['tempevtdata']['keyName'], null, true);
  }
  /*******************************Update Table Cell Data to backend*******************/

  showToastMsg(msg, issuccess, timeout: number = 3000) {
    this.toastMsg.issuccess = issuccess;
    this.toastMsg.msg = msg
    this.toastMsg.show = true;

    setTimeout(() => {
      this.toastMsg.show = false;
    }, timeout);
  }



  updateCellData(evt) {


    return new Promise((resolve, reject) => {
      try {
        if (this.tableconfig['apicall']['autoupdate'] && evt['isvaluechanged']) {
          this.showloader = true;
          var body = {
            "tblname": "",
            "updatecolname": "",
            "updatecolval": "",
            "updaterefkey": "",
            "updaterefkeyval": ""
          };

          if (evt['header']['updaterefkey']) {
            body.tblname = evt['header']['tblname'];
            body.updaterefkey = evt['header']['updaterefkey'];
            body.updaterefkeyval = evt['row'][evt['header']['updaterefkey']];
            body.updatecolname = evt['keyName'];
            body.updatecolval = evt['row'][evt['keyName']];
          }
          this.httpClient.post(this.serveripaddress + "Customer/tblInLineEdit", body)
            .subscribe(
              succ => {
                this.showloader = false;
                var elemid = ('td' + evt['keyName'] + '*' + evt['rowindex'] + '*' + evt['colindex']).toString();
                if (succ['errorCode'] == 0) {
                //  console.log("updateCellData findIndex");

                  let bakindx = this.tabledatabackup.findIndex(row => row['autoid'] == evt['autoid']);
                  if (bakindx != -1) {
                    this.tabledatabackup[bakindx][evt['keyName']] = evt['row'][evt['keyName']];
                  }
                  // this.toastMsg.issuccess = true;
                  // this.toastMsg.msg = evt['header']['display'] + ' updated successfully';
                  this.showToast(true, evt['header']['display'] + ' updated successfully', 5000);
                  this.highlightCell(elemid, true);
                  // setTimeout(() => {
                  //   this.toastMsg.show = false;
                  // }, 2000);
                  resolve(0);
                }
                else {
                  // this.tabledata[evt['rowindex']][evt['keyName']] = evt['oldvalue'];
                  // this.valuebeforecommit[this.valuebeforecommit['keyName']] = evt['oldvalue'];
                  this.updateOldCellValue(evt['rowindex'], evt['keyName'], evt['oldvalue']);
                  // this.toastMsg.issuccess = false;
                  // this.toastMsg.msg = succ['errorDescription'];
                  this.highlightCell(elemid, false);
                  ///display error message.
                  //   setTimeout(() => {
                  //     this.toastMsg.show = false;
                  //   }, 4000);
                  // }
                  // this.toastMsg.show = true;
                  this.showToast(false, succ['errorDescription'], 5000);
                  resolve(1)
                }

              },
              err => {
                reject(err);
                this.updateOldCellValue(evt['rowindex'], evt['keyName'], evt['oldvalue']);
                this.showloader = false;
                this.showToast(false, "Error occured", 5000);
              }
            );
        }
      }
      catch (error) {
        reject(error);
        this.updateOldCellValue(evt['rowindex'], evt['keyName'], evt['oldvalue']);
        this.showloader = false;
        this.showToast(false, "Exception occured", 5000);
      }
    })

  }

  updateOldCellValue(dataindex, keyName, value) {
    this.tabledata[dataindex][keyName] = value;
    this.valuebeforecommit[keyName] = value;
  }




  /**Show Toast Message */

  showToast(issuccess, message, timeout = 3000) {
    this.toastMsg.issuccess = issuccess;
    this.toastMsg.msg = message;
    this.toastMsg.show = true;
    setTimeout(() => {
      this.toastMsg.show = false;
    }, timeout);
  }




  /****Highlight Cell background */

  highlightCell(elemid, issuccess: boolean = true) {
    document.getElementById(elemid).style.backgroundColor = issuccess ? '#99ff99' : '#ff9999';
    document.getElementById(elemid).style.transitionTimingFunction = 'ease-out';
    document.getElementById(elemid).style.transitionProperty = '6s';
    setTimeout(() => {
      document.getElementById(elemid).style.backgroundColor = '';
    }, 1000);
  }
  /*******************End cell highlight color*********************/



  /** Custom action buttons event */

  customActionButtons(item, button) {

    this.customActionButtonEvent.emit({
      "buttonObj": button,
      "row": item
    })
  }

  /**End custom action buttons event */
  /* Table tr td css*/
  tableRowLevelCss(header, item) {
    return `${header['tdalignment']} ${item['groupClass'] ? item['groupClass'] : ''}`;
  }

  setCellBackgroundColour(header, item, rowindex, colindex) {
    let flag = false;
    let cssBgClass = '1';
    // console.log(header['setCellColourFlag'],(header['setCellColourFlag'] && header['setCellColourFlag']=='cell'));

    if ((header['setCellColourFlag'] && header['setCellColourFlag'] == 'cell') && item[header['key']] != null
      && header['setCellColourCriteria'] && (header['datatype'] == 'number' || header['datatype'] == 'date')) {
      if (header['datatype'] == 'number') {
        switch (header['setCellColourCriteria']['condition']) {
          case 'lessthan':
            if (item[header['key']] < header['setCellColourCriteria']['0']) {
              flag = true;
              cssBgClass = `bg-red`;
              this.setRowBackgroundColour(rowindex);
            } else {
              flag = false;
              cssBgClass = `bg-green`;
            }
            break;
          case 'greaterthan':
            if (item[header['key']] > header['setCellColourCriteria']['0']) {
              flag = false;
              cssBgClass = `bg-green`;
              this.setRowBackgroundColour(rowindex);
            } else {
              flag = true;
              cssBgClass = `bg-red`;
            }
            break;
          case 'between':
            if (item[header['key']] >= header['setCellColourCriteria']['0'] && item[header['key']] <= header['setCellColourCriteria']['1']) {
              flag = true;
              this.setRowBackgroundColour(rowindex);
            }
            break;

          default:
            break;
        }
      }
      if (header['datatype'] == 'date') {
        switch (header['setCellColourCriteria']['condition']) {
          case 'lessthan':
            if (this.compareTwoDates(item[header['key']], header['setCellColourCriteria']['0'], 'lessthan')) {
              flag = true;
              cssBgClass = `bg-red`;
              this.setRowBackgroundColour(rowindex);
            } else {
              flag = false;
              cssBgClass = `bg-green`;
              this.setRowBackgroundColour(rowindex, false);
            }
            break;
          case 'greaterthan':
            if (this.compareTwoDates(item[header['key']], header['setCellColourCriteria']['0'], 'greaterthan')) {
              flag = false;
              cssBgClass = `bg-green`;
              this.setRowBackgroundColour(rowindex);
            } else {
              flag = true;
              cssBgClass = `bg-red`;
              this.setRowBackgroundColour(rowindex, false);
            }
            break;
          case 'between':
            if (this.compareTwoDates(item[header['key']], header['setCellColourCriteria']['0'], 'greaterthan') && this.compareTwoDates(item[header['key']], header['setCellColourCriteria']['1'], 'lessthan')) {
              flag = true;
              this.setRowBackgroundColour(rowindex);
            }
            break;

          default:
            break;
        }
      }
      // return item[header['key']] > 30 ? 'bg-red' : '';
      return flag ? 'bg-red' : 'bg-green';
    }
    return flag ? 'bg-red' : '';

  }

  compareTwoDates(fromDate, Todate, Criteria) {
    let flag = false;
    let d1 = new Date(fromDate);
    let d2 = new Date(Todate);
    switch (Criteria) {
      case 'lessthan':
        flag = d1.getTime() < d2.getTime();
        break;
      case 'greaterthan':
        flag = d1.getTime() > d2.getTime();
        break;
      case 'between':
        flag = (d1.getTime() <= d2.getTime() && d1.getTime() >= d2.getTime());
        break;

      default:
        flag = false;
        break;
    }

    // return this.datepipe.transform(new Date(date), this.dateformat);
    return flag;

  }
  setRowBackgroundColour(rowindex, flag = true) {
    const element = document.getElementById('tr' + this.tableid + rowindex);
    if (flag) {
      element.style.backgroundColor = "rosybrown";
    } else {
      element.style.backgroundColor = "";
      element.removeAttribute("style")
    }
  }
  openPopupForSelectOptionForSetCellColourFlag(menuitem: any) {
    this.setCellColourFlagPopup = true;
    this.setCellColourFlagObj.targetcolumn = menuitem['targetcolumn'];
    this.setCellColourFlagObj['condition'] = '';
    this.setCellColourFlagObj['min'] = '';
    this.setCellColourFlagObj['max'] = '';


  }

  setBackupOfSetCellColourFlag() {
  //  console.log("setBackupOfSetCellColourFlag normal for loop");

    for (let index = 0; index < this.tableheaders.length; index++) {
      const element = this.tableheaders[index];
      if (element['setCellColourCriteria'] && element['setCellColourFlag']) {
        this.setCellColourFlagObj.setCellColourCriteriaBackup = element['setCellColourCriteria'];
        this.setCellColourFlagObj.setCellColourFlagBackup = element['setCellColourFlag'];
        break;
      }
    }
  }

  onSelectOptionForSetCellColourFlag(menuitem: any) {
    ////  console.log(menuitem);
    this.setCellColourFlagPopup = false;
    if (menuitem.condition) {
  //  console.log("onSelectOptionForSetCellColourFlag normal for loop");

      for (let index = 0; index < this.tableheaders.length; index++) {
        if (menuitem['targetcolumn'] == this.tableheaders[index]['key']) {
          if (this.tableheaders[index]['datatype'] == 'number' || this.tableheaders[index]['datatype'] == 'date') {
            if (this.tableheaders[index]['setCellColourCriteria']) {
              this.tableheaders[index]['setCellColourCriteria']['0'] = menuitem['min'];
              this.tableheaders[index]['setCellColourCriteria']['condition'] = menuitem['condition'];
              if (menuitem['max'])
                this.tableheaders[index]['setCellColourCriteria']['1'] = menuitem['max'];
            } else {
              this.tableheaders[index]['setCellColourCriteria'] = { '0': 0, 'condition': '', '1': 0 };
              this.tableheaders[index]['setCellColourCriteria']['0'] = menuitem['min'];
              this.tableheaders[index]['setCellColourCriteria']['condition'] = menuitem['condition'];
              if (menuitem['max'])
                this.tableheaders[index]['setCellColourCriteria']['1'] = menuitem['max'];
            }
          }
          if (this.tableheaders[index]['datatype'] == 'date') {
            ////  console.log('date', menuitem);
          }
          // console.log('targetcolumn',this.tableheaders[index]);


          this.setHighlight(menuitem);
          this.contextMenuService.closeAllContextMenus({ event: null, eventType: "cancel" })
          break;
        }
      }
    }

    // console.log('targetcolumn',this.tableheaders);
  }
  removeHighlight(highlight) {
    ////  console.log(highlight);
  }
  setHighlight(item) {
    let flag = false;
  //  console.log("setHighlight normal for loop");

    for (let i = 0; i < this.highlightsCellsArray.length; i++) {
      let obj = this.highlightsCellsArray[i];
      if (obj['targetcolumn'] == item['targetcolumn']) {
        flag = true;
        let a = { condition: item['condition'], min: item['min'], max: item['max'], text: item['text'], targetcolumn: item['targetcolumn'] };
        this.highlightsCellsArray[i] = a;

      }
    }

    if (!flag) {
      let a = { condition: item['condition'], min: item['min'], max: item['max'], text: item['text'], targetcolumn: item['targetcolumn'] };
      this.highlightsCellsArray.push(a);
    };
    ////  console.log(this.highlightsCellsArray);
  }
  /**
   * generate pdf start
  */
  @Input() pageObj: any = {};
  @Input() pageType: String = "";
  @Input() pdfData: any = {};
  generatePDF() {

    let pdfdata: any = {
      "printsettings": {
        "printheader": "Y",
        "printfooter": "N",
        "headermargin": 1.8,
        "footermargin": 0.5,
        "printheadermsg": "Y",
        "printfootermsg": "Y",
        "printfooterData": [],
        "printpagesize": "A4",
        "headerdata": [
          {
            "headerline1": "Shree Metal Products",
            "headerline2": "Works - Gat No 394, At Post Nimbhore, Taluka Phaltan, Distr. Satara, 415523",
            "headerline3": "Ofce - Flat No2/3, Simran Apts, Chintamani CHS, Karve Nagar, Pune 411052",
            "headermsg1": "",
            "headermsg2": ""
          }
        ],
        "footerdata": {
          "footerline1": "",
          "footerline2": "",
          "footerline3": "",
          "footermsg1": "",
          "footermsg2": ""
        },
        "styles": {
          "footerCenterWithSize8": {
            "fontSize": 8,
            "bold": true,
            "alignment": "center"
          },
          "footerCenterWithSize6": {
            "fontSize": 6,
            "bold": true,
            "alignment": "center"
          }
        },
        "tableColumnWidth": ["30%", "10%", "25%", "15%", "20%"],
        "tableHeight": 200,
        "tableHeaderRows": 1,
        "tablealignment": "center",
        "tableFontSize": 10,
        "pdfoptions": {},
        "showSrNo" : "Y"
        // "content":[
        //   {
        //       "columns": [
        //           { "text": "Branch", "alignment": "center", "widths": "20%" },
        //           { "text": "Category", "alignment": "center", "widths": "20%" },
        //           { "text": "Salesman Name ", "alignment": "center", "widths": "30%" },
        //           { "text": "Section", "alignment": "center", "widths": "20%" },
        //           { "text": "Date ", "alignment": "center", "widths": "10%" }
        //       ],
        //       "columnGap": 10
        //   },
        //   {
        //       "columns": [


        //           { "text": "Branch", "alignment": "center", "widths": "20%" },
        //           { "text": "Category", "alignment": "center", "widths": "20%" },
        //           { "text": "Salesman Name", "alignment": "center", "widths": "30%" },
        //           { "text": "Section", "alignment": "center", "widths": "20%" },
        //           { "text": "Date ", "alignment": "center", "widths": "10%" }
        //       ],
        //       "columnGap": 10
        //   },
        //   { "text": "", "margin": [5, 5, 10, 5] }
        // ]
      },
      "branchdata": {
        "pan": "",
        "gstn": "",
        "mailid": "",
        "branchcode": "",
        "branchname": "",
        "webaddress": ""
      },
      "pagedata": {
        "pdftitle": this.pageObj['pdfname'],
        "pdftype": this.pageType,
        "fromdate": this.pageObj['fromdate'] ? this.pageObj['fromdate'] : new Date(),
        "todate":  this.pageObj['todate'] ? this.pageObj['todate'] : new Date(),
        "columnwidth":  this.pageObj['pdfcolwidth'] ? this.pageObj['pdfcolwidth'] : '*',
        "headerdata":  this.pageObj['headerdata'] ? this.pageObj['headerdata'] : {}
      }
    };
    if (this.pdfData['printsettings']) {
      pdfdata = this.pdfData
      // pdfdata.pagedata = { pdftitle: this.pageName }
    }
    ////  console.log('pdfdata', pdfdata);

    let pdftabledata: any[] = JSON.parse(JSON.stringify(this.tabledata));
    let pdftableheader: any[] = JSON.parse(JSON.stringify(this.tableheaders));
    pdfdata.pdftabledata = pdftabledata;
    pdfdata.pdftableheader = pdftableheader;
    this.pdfService.generatePdf(pdfdata, 'open');
  }
  /**
   * generate pdf end
  */
  /*
  only forsdc project. sales day end stock entry.
  */
  //start sdc and sales day end stock entry
  checkIsCellEditable(item, key, index, colindex) {
    let flag = false;

    if ((key == 'wtbysal' || key == 'platinumwtbysal' || key == 'goldwtbysal') && item['salesbytag'] && item['salesbytag'] == 1) {
      // let currentId = key + '*' + index + '*' + colindex;
      // let eleman =document.getElementById(currentId);
      // eleman.setAttribute("editable", 'false');
      flag = true;
      // console.log(key == 'wtbysal', item['salesbytag'], item['salesbytag'] == 1);

    }
    return flag;
  }
  mismatchRowObj = {
    isMismatch: false
  }
  showOnlyMismatchRowOrAllRow() {
    let menuitem = { label: 'Filter On', action: 'filter', datatype: 'number', targetcolumn: 'qtybydb', text: 65 };
    // this.addOrRemoveFilter('I')
    let DataArrTofilter = [];
    let criteriastr;
    this.mismatchRowObj['isMismatch'] = !this.mismatchRowObj['isMismatch'];
    if (this.mismatchRowObj['isMismatch']) {
      // console.log('isMismatch', this.mismatchRowObj['isMismatch']);

      DataArrTofilter = _.cloneDeep(this.tabledata);
      criteriastr = `item.qtybydb && item.qtybydb.toString().trim().toUpperCase().replace("'", '').includes('122')`;
      // criteriastr = `item.qtymatchresult && item.qtymatchresult.toString().trim().toUpperCase().replace("'", '').includes('0')`;
      this.setActualIndex(DataArrTofilter);
  //  console.log("showOnlyMismatchRowOrAllRow filter");

      let filtreddata = DataArrTofilter.filter((item, index) => {
        let flag = false
        // console.log(this.tableheaders[index],item,this.tableheaders[index]?.key, this.tableheaders[index]?.visiblecolm);

        // console.log(item.qtymatchresult, item.qtymatchresult.toString().trim().toUpperCase().includes('0'), item.qtymatchresult.toString().trim().toUpperCase().includes('0') == '0', item);
        if ((item?.qtymatchresult == 0) && (item?.wtmatchresult == 0) || (item?.wtmatchresult == 0 && item?.qtybydbIsVisiblecolm == false)) {
          flag = false;
        } else {
          flag = true;//eval(criteriastr)
        }
        /*if((this.tableheaders[index]['key'] == 'qtybydb' && this.tableheaders[index]['visiblecolm'] == false) && item?.qtymatchresult == 0) {
          flag = false;
        } else {
          // flag
        }

        if((this.tableheaders[index]['key'] == 'wtbydb' && this.tableheaders[index]['visiblecolm'] == false) && item?.wtmatchresult == 0) {
          flag = false;
        } else {
          // flag=true;
        }*/
        return flag;
      });
      this.tabledata = [];
      // this.tabledata = filtreddata;
      this.setActualIndex(filtreddata);
    } else {
      ////  console.log('isMismatch', this.mismatchRowObj['isMismatch']);
      DataArrTofilter = _.cloneDeep(this.tabledatabackup);
      this.setActualIndex(DataArrTofilter);
    }
    // "item." + menu['targetcolumn'] + ".toString().trim().toUpperCase()." + "replace(" + '"' + "'" + '"' + ", '').includes('" + findval + "') && ";



  }
  misMatchCellMakeRed(header, item, rowindex, colindex, key) {
    let flag = false;
    if (this.tableconfig['showMismatchRecord']) {//&& this.mismatchRowObj['isMismatch']
      // console.warn('misMatchCellMakeRed', key, item['qtymatchresult'], item);
      // if(item['qtymatchresult']==0){

      // }
      // console.log('misMatchCellMakeRed',!item['descripencyremark'],!!item['descripencyremark']);
      if (item['qty'] != item['actualqty']) {
        flag = true;
        document.getElementById('tr' + this.tableid + rowindex).style.background = 'antiquewhite'
      } else {
        document.getElementById('tr' + this.tableid + rowindex).style.background = ''
      }
    }
    if (this.tableconfig['showMismatchOutQty']) {
      if (item['quantity'] != item['outquantity']) {
        flag = true;
        document.getElementById('tr' + this.tableid + rowindex).style.background = 'antiquewhite'
      } else {
        document.getElementById('tr' + this.tableid + rowindex).style.background = ''
      }
    }
    return ''
    // return flag ? 'bg-red' : '';
  }

  //end sdc, sales day end stock entry
  showPopUpForDetails(item, header,) {
    this.displayDetailsPopupObj.details = item[header['key']];
    this.displayDetailsPopupObj.name = header['display'];
    this.displayDetailsPopup = true;
  }
  setDecimalDigit(value, decimaldigit) {
    if (value) {
      value = +(value);
      const number = value.toFixed(decimaldigit);
      // console.log(number, value, typeof number);
      return number;
    } else {
      // console.log('de', value);

      return value
    }

  }
  checknumberIsPositiveOrNot(event, header, item) {
    if (header['positive']) {
      // console.log(event);

      // return event.charCode >= 48;
      if ((event?.charCode != 8 && event?.charCode == 0 || (event?.charCode >= 48 && event?.charCode <= 57))) {
        return true;
      } else {
        item[header['key']] = 0;
        return false

      }
    } else {
      return true
    }

  }
  checknumberIsPositiveOrNot1(header) {
    return header['positive'] ? `(validity.valid||(value='')` : `value`;
  }

  RowHighlight(rowindex, color) {
    const id = 'tr' + this.tableid + rowindex

    const doc = document.getElementById(id);

    if (doc?.style) {
      doc.style.backgroundColor = color;
    } else {
      // console.log("Not found", id);

    }
  }

  findIsDeletedAndHighlight() {
  //  console.log("findIsDeletedAndHighlight forEach");
    
    this.tabledata.forEach((item, i) => {
      if (item[this.tableconfig['checkDeletedFlagKey']] == 1 || item[this.tableconfig['checkDeletedFlagKey']] == "N" || item[this.tableconfig['isclosed']] == "CLOSE") {
        this.RowHighlight(i, '#fcaeac');
      }
      if (item[this.tableconfig['checkTotalSummaryRow']] == 'Total :-'){
        this.RowHighlight(i, 'antiquewhite');
      }
    })
  }


  findIsBlankePoOrNot() {
  //  console.log("findIsBlankePoOrNot forEach");

    this.tabledata.forEach((item, i) => {
      if (item[this.tableconfig['checkNonBlankePo']] == 0) {
        const color = this.currentSelectedRowIndex == i ? '#7de4f0' : '#faf087';
        this.poRowHighlight(i, color);
      }
      if (item[this.tableconfig['isclosed']] == 'CLOSE') {
        const color = this.currentSelectedRowIndex == i ? '#fcaeac' : '#faf087';
        this.poRowHighlight(i, color);
      }
    })
  }

  poRowHighlight(rowindex, color) {
    const id = 'tr' + this.tableid + rowindex

    const doc = document.getElementById(id);

    if (doc?.style) {
      doc.style.backgroundColor = color;
    } else {
      // console.log("Not found", id);

    }
  }

  eventForCleckOnBody() {
    //   const body = document.body;

    //   const actionbtninput = document.getElementById('actionbtninput');
    // //  console.log('actionbtninput',actionbtninput);
    //   if(actionbtninput){
    //   body.addEventListener('click', function(){
    //     console.warn('actionbtninput',actionbtninput);
    //     actionbtninput.removeAttribute('checked')
    //     actionbtninput.classList.add('dd-menu-hide')
    //   });
    // }

    $(document).ready(function () {
      $(".dropdown-button").click(function () {

        const docClick = function (ev) {
          if (!$(ev.target).hasClass('dropdown-button')) {
            closeDropdown();
          }
        }

        const closeDropdown = function () {
          $(".dropdown-menu").removeClass("show-menu");
          $(document).unbind('click', docClick);
        }

        if ($(".dropdown-menu").hasClass("show-menu")) {
          closeDropdown();
        } else {
          $(document).bind('click', docClick)
          $(".dropdown-menu").addClass("show-menu");
        }

        $(".dropdown-menu > li").click(function () {
          closeDropdown();
        });
        $(".dropdown-menu.dropdown-select > li").click(function () {
          // $(".dropdown-button").html($(this).html());
        });
      });
    });
  }

  optionChange(evn) {
    ////  console.log(evn);

    switch (evn) {
      case 'exporttopdf':
        this.generatePDF();
        break;
      case 'exporttoexcel':
        this.prapareHeaderForCSV()
        break;
      case 'hidecolumnaction':
        this.displayAllColms();
        break;
      case 'summary':
      //  console.log("hideAndShowSummary call");
        
        this.hideAndShowSummary(true);
        break;
      case 'consolidation':
        this.expandOrShinkAll();
        break;
      case 'showMismatch':
        this.showOnlyMismatchRowOrAllRow();
        break;
      case 'groupedData':
        this.toggleView();
        break;
      case 'checkedrecordscount':
        this.showCheckedRows();
        break;
      case 'showcheckedrecords':
        this.restoreTableData();
        break;
      case 'checkedCSV':
        this.getCheckedRecords(true);
        break;

      default:
        break;
    }

  }

  actionOptionPraper() {
    if (this.tableconfig['exporttopdf'])
      this.optionActionItems.push({ id: 'exporttopdf', value: 'exporttopdf', text: 'Export to PDF' });

    if (this.tableconfig['exporttoexcel'])
      this.optionActionItems.push({ id: 'exporttoexcel', value: 'exporttoexcel', text: 'Export to Excel' });

    if (this.hidecolumnaction)
      this.optionActionItems.push({ id: 'hidecolumnaction', value: 'hidecolumnaction', text: 'Display Hidden Columns(' + this.hiddencolumns.length + ')' });

    if (this.tableconfig['summary'] && this.tableconfig['summary'].length)
      this.optionActionItems.push({ id: 'summary', value: 'summary', text: `${this.tableconfig['showsummary'] ? "Hide " : "Show "} Summary` });

    if (this.consolidatedetails['consolidation'])
      this.optionActionItems.push({ id: 'consolidation', value: 'consolidation', text: `${this.consolidatedetails['isexpand'] ? 'Shrink All' : 'Expand All'}` });

    if (this.tableconfig['showMismatch'])
      this.optionActionItems.push({ id: 'showMismatch', value: 'showMismatch', text: `${!this.mismatchRowObj['isMismatch'] ? 'Show only mismatch records' : 'Show all records'}` });

    if (this.consolidatedetails['groupedData'])
      this.optionActionItems.push({ id: 'groupedData', value: 'groupedData', text: `${this.consolidatedetails['consolidation'] ? "Normal View" : "Aggregation View"}` });

    if (this.checkedrecordscount)
      this.optionActionItems.push({ id: 'checkedrecordscount', value: 'checkedrecordscount', text: `Show Checked` });

    if (this.showcheckedrecords)
      this.optionActionItems.push({ id: 'showcheckedrecords', value: 'showcheckedrecords', text: `Refresh Table Data` });

    if (this.tableconfig['exporttoexcel'] && this.checkedrecordscount)
      this.optionActionItems.push({ id: 'checkedCSV', value: 'checkedCSV', text: `checked CSV` });

  }



}
